import * as Yup from "yup";
import {yupRequiredPositiveNumber, yupRequiredPositiveOrZeroNumber, yupRequiredString} from "../yup/Validators";

export const CreatePurchaseOrderSchema = Yup.object().shape({
  supplierId: yupRequiredString
})

export const CreatePurchaseBookingSchema = Yup.object().shape({
  serviceId: yupRequiredString,
  orderItemId: yupRequiredString,
  quantity: yupRequiredPositiveNumber,
  price: yupRequiredPositiveOrZeroNumber,
})
