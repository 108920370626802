import {PROFILE} from "../interfaces/UserInterfaces";
import {CustomerIdentity} from "../interfaces/CustomerInterfaces";

const cleanSession = () : void => {
  localStorage.removeItem("token")
  localStorage.removeItem("profile")
  localStorage.removeItem("rulesAccepted")
  localStorage.removeItem("userFirstName")
  localStorage.removeItem("userLastName")
  localStorage.removeItem("userId")
  localStorage.removeItem("profileValidated")
  localStorage.removeItem("customersRepresentedByUser")
  localStorage.removeItem("activeCustomerForUser")
}

const getToken = (): string => {
  return localStorage.getItem("token")
}

const getProfile = (): PROFILE => {
  return localStorage.getItem("profile") as PROFILE;
}

const isConnected = (): boolean => {
  return !!localStorage.getItem("token") && !!localStorage.getItem("profile")
}

const areRulesAccepted = (): boolean => {
  return !!localStorage.getItem("rulesAccepted");
}

const getUserFirstName = (): string => {
  return localStorage.getItem("userFirstName");
}

const getUserLastName = (): string => {
  return localStorage.getItem("userLastName");
}

const getUserId = (): string => {
  return localStorage.getItem("userId");
}

const getCustomersRepresentedByUser = (): CustomerIdentity[] => {
  return JSON.parse(localStorage.getItem("customersRepresentedByUser")) as CustomerIdentity[] || [];
}

const isProfileValidated = (): string => {
  return localStorage.getItem("profileValidated");
}

export const authUtils = Object.freeze({
  cleanSession,
  isProfileValidated,
  getToken,
  getProfile,
  isConnected,
  areRulesAccepted,
  getUserFirstName,
  getUserLastName,
  getCustomersRepresentedByUser,
  getUserId,
})
