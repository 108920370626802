import {useState} from "react";

interface useListCheckboxSelectorHook {
  selected: string[],
  handleSelect: (id: string) => void,
  handleSelectAll: (possibleValues: string[]) => void,
}

function useListCheckboxSelector(): useListCheckboxSelectorHook {
  const [selected, setSelected] = useState<string[]>([])

  const handleSelectAll = (possibleValues: string[]) => {
    if (selected.length > 0) {
      setSelected([])
    } else {
      setSelected([...possibleValues])
    }
  }

  const handleSelect = (value: string) => {
    const index = selected.findIndex(id => id === value)

    if (index !== -1) {
      const updated = [...selected]
      updated.splice(index, 1)
      setSelected(updated)
    } else {
      setSelected([...selected, value])
    }
  }

  return {
    selected,
    handleSelect,
    handleSelectAll,
  };
}

export default useListCheckboxSelector;
