import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DropdownItem, Label } from "reactstrap";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import { ColorType } from "../../../types/bootstrap/BootstrapType";
import { dateUtils } from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import { Invoice, InvoiceState, InvoiceStateMap } from "../../../interfaces/InvoiceInterfaces";
import { fileUtils } from "../../../utils/fileUtils";
import { invoiceService } from "../../../services/InvoiceService";
import PageTitle from "./PageTitle";
import { CREDIT_NOTES_PATH, INVOICES_PATH } from "../../../constants/routes/RoutePaths";
import { Link, useNavigate } from "react-router-dom";
import ModalCreditNoteConfirm from "../modal/ModalCreditNoteConfirm";
import ButtonGroup from "../button/ButtonGroup";

export interface InvoiceHeaderProps {
  className?: string,
  invoice: Invoice,
  status: InvoiceState,
  onUpdateStatus: (invoice: Invoice) => void
  onClickAdd: () => void,
  onClickContactEdit: () => void,
  displayStatus?: boolean,
  isCustomer?: boolean,
}

const InvoiceHeader: FunctionComponent<InvoiceHeaderProps> = ({
  className = "",
  invoice,
  status,
  onClickAdd,
  onClickContactEdit,
  onUpdateStatus,
  displayStatus = false,
  isCustomer = false,
}) => {
  const intl = useIntl()
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onClickDownload = () => fileUtils.downloadFile(invoiceService.downloadInvoices(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);
  const onClickValidate = () => invoiceService.manageTransition(invoice.id, InvoiceState.BILLED).then(onUpdateStatus);
  const onDelete = () => invoiceService.deleteInvoice(invoice.id).then(() => navigate(INVOICES_PATH));

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <PageTitle
          pageTitle={invoice?.invoiceNumber || "FC" + invoice.date.split("-")[0].split("20")[1] + "-XXXXX"}
          className="me-3"
          breadcrumbItems={[{
            title: intl.formatMessage({ id: "invoice_header_title" }),
            to: INVOICES_PATH
          }]}
        />

        {displayStatus && (
          <div>
            <Label className="me-1"><FormattedMessage id="order_status" /> :</Label>
            <Badge color={InvoiceStateMap[status].color} pill>{intl.formatMessage({ id: InvoiceStateMap[status].label })}</Badge>
          </div>
        )}

        <div>
          <Label className="me-1 ms-3"><FormattedMessage id="invoice_name_order" /> :</Label>
          <b>{invoice.customerOrderName || "-"}</b>
        </div>

        <div className="d-flex ms-auto">
          <ButtonGroup title="Actions" className="me-2">
            <>
              {invoice?.status !== InvoiceState.BILLED &&
                <>
                <DropdownItem color={ColorType.PRIMARY} className="epow-button d-flex align-items-center" onClick={onClickAdd}>
                  <Icon name="Plus" className="ms-0 me-2" />
                  <div>{intl.formatMessage({ id: "invoice_billing_item_title_add" })}</div>
                </DropdownItem>
                  <DropdownItem color={ColorType.PRIMARY} className="epow-button d-flex align-items-center" onClick={onClickContactEdit}>
                    <Icon name="User" className="ms-0 me-2" />
                    <div>{intl.formatMessage({ id: "invoice_billing_item_edit_contact" })}</div>
                  </DropdownItem>
                </>
              }
              <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={onClickDownload}>
                <Icon name="Download" className="ms-0 me-2" />
                <div>{intl.formatMessage({ id: "invoice_download" })}</div>
              </DropdownItem>
              {!invoice?.linkedInvoiceId && !isCustomer &&
                <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={() => setOpenModal(true)}>
                  <Icon name="File" className="ms-0 me-2" />
                  <FormattedMessage id="invoice_credit_note" />
                </DropdownItem>
              }
            </>
          </ButtonGroup>

          {invoice?.status === InvoiceState.DRAFT &&
            <>
              <Button className="me-2" color={ColorType.DANGER} onClick={onDelete}>
                <FormattedMessage id="invoice_delete" />
                <Icon name="Trash" className="ms-2" />
              </Button>
              <Button color={ColorType.PRIMARY} onClick={onClickValidate}>
                <FormattedMessage id="invoice_validate" />
                <Icon name="Download" className="ms-2" />
              </Button>
            </>
          }
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <Label className="me-1"><FormattedMessage id="purchase_order_creation" /></Label>
          {dateUtils.formatDateToLocalDateTime(dateUtils.parseDate(invoice?.date), false)}
        </div>

        {invoice?.linkedInvoiceId &&
          <div className="me-2">
            <Link to={`${CREDIT_NOTES_PATH}/${invoice.linkedInvoiceId}`}><FormattedMessage
              id="go_to_credit_note" /></Link>
          </div>
        }
      </div>

      <ModalCreditNoteConfirm
        open={openModal}
        onOpen={() => setOpenModal(true)}
        onClose={() => setOpenModal(false)}
        invoiceNumber={invoice.invoiceNumber}
        invoice={invoice} />

    </div>
  )
}

export default InvoiceHeader
