import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import CreateInvoiceOperationCustomerForm from "../../form/CreateInvoiceOperationCustomerForm";
import {CUSTOMERS_PATH} from "../../../../constants/routes/RoutePaths";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import {Customer} from "../../../../interfaces/CustomerInterfaces";
import {Col, Row} from "reactstrap";
import Title from "components/atoms/Title";
import {optionUtils} from "../../../../utils/optionUtils";
import {PUBLIC_TYPE_CUSTOMER_OPTIONS, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {
  CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS,
  CUSTOMER_SORT_INVOICE_OPTIONS
} from "../../../../constants/CustomerConstants";
import TextLink from "../../../atoms/TextLink";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import FieldLabel from "../../../atoms/FieldLabel";

interface SettingInvoicingCustomerCardProps {
  className?: string,
  customer?: Customer
  setCustomer?: (customer: Customer) => void,
}

const SettingInvoicingCustomerCard: FunctionComponent<SettingInvoicingCustomerCardProps> = ({
  className= "",
  customer,
  setCustomer
}) => {
  const intl = useIntl()
  const [customerOptions, setCustomerOptions] = useState<Option<string>[]>([])
  const [editInfo, setEditInfo] = useState<boolean>(false)

  useEffect(() => {
    const options = customer.billingServicesAsCustomers.map(billingService => ({
      label: `${billingService.code} - ${billingService.name}`,
      value: billingService.id
    }))
    setCustomerOptions(options)
  }, [])

  const renderBillingServices = () => (
    <div className="d-flex flex-column">
      {customer?.billingServicesAsCustomers?.map(billingService =>
        customer.id === billingService.id ? (
          <span key={billingService.id} className="epow-info">{billingService.name}</span>
        ) : (
          <TextLink key={billingService.id} to={`${CUSTOMERS_PATH}/${billingService.id}`}><span className="epow-info">{billingService.name}</span></TextLink>
        )
      )}
    </div>
  )

  return (
    <Col className={`d-flex flex-column ${className}`}>
      {editInfo ? (
        <>
          <CreateInvoiceOperationCustomerForm
            id="invoiceOperationCustomerForm"
            className={className}
            customerOptions={customerOptions}
            setCustomerOptions={setCustomerOptions}
            customer={customer}
            setEditInfo={setEditInfo}
            setCustomer={setCustomer}
          />
          <div>
            <Button color={ColorType.SECONDARY} onClick={() => setEditInfo(false)} className="extended me-2">
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button form={"invoiceOperationCustomerForm"} type="submit" className="extended">
              <FormattedMessage id="save_button"/>
            </Button>
          </div>
        </>
      ) : (
        <>
          <Row className="mb-4">
            <Col xs={24} md={16}>
              <div className="mb-3">
                <Title title={intl.formatMessage({id: "invoicing" })} size={"h5"}/>
              </div>
              <Row>
                <Col xs={24} md={12}>
                  <div className="d-flex flex-column gap-3 mb-3">
                    <FieldLabel
                        verticalDisplay
                        label={intl.formatMessage({id : "customer_individual_field"})}
                        value={optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.individual)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_geographical_zone_field"})}
                      value={optionUtils.translateOption(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS, customer?.geographicalZone)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_number_intra_vat_field"})}
                      value={customer?.numberIntraVAT}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_invoice_field"})}
                      value={optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.invoiceForCustomer)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_billing_services_field"})}
                      value={renderBillingServices()}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_default_billing_service_field"})}
                      href={customer.id !== customer.defaultBillingServiceAsCustomer?.id && `${CUSTOMERS_PATH}/${customer.defaultBillingServiceAsCustomer?.id}`}
                      value={customer.defaultBillingServiceAsCustomer?.name}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="d-flex flex-column gap-3 mb-3">
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_voucher_management_field"})}
                      value={optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.isVoucherManagement)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_disbursement_field"})}
                      value={optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.disbursement)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_main_sort_invoice_field"})}
                      value={optionUtils.translateOption(intl, CUSTOMER_SORT_INVOICE_OPTIONS, customer?.mainSortInvoice)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id : "customer_secondary_sort_invoice_field"})}
                      value={optionUtils.translateOption(intl, CUSTOMER_SORT_INVOICE_OPTIONS, customer?.secondarySortInvoice)}
                    />
                    <FieldLabel
                      verticalDisplay
                      label={intl.formatMessage({id: "customer_payment_condition"})}
                      value={customer?.paymentCondition ? intl.formatMessage({id: `payment_condition_${customer?.paymentCondition}`}) : ""}
                    />
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={8}>
              <div className="mb-3">
                <Title title={intl.formatMessage({id: "operation" })} size={"h5"}/>
              </div>
              <div className="d-flex flex-column gap-3 mb-3">
                  <div className="mb-2 d-flex flex-column">
                    <span className="text-muted"><FormattedMessage id="customer_public_type"/></span>
                    <span className="epow-info">{optionUtils.translateOption(intl, PUBLIC_TYPE_CUSTOMER_OPTIONS, customer?.publicType) ?? "-"}</span>
                  </div>
                  <div className="mb-2 d-flex flex-column">
                    <span className="text-muted"><FormattedMessage id="customer_reservation_management_field"/></span>
                    <span className="epow-info">{optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.isReservationManagement) ?? "-"}</span>
                  </div>
                  <div className="mb-2 d-flex flex-column">
                    <span className="text-muted"><FormattedMessage id="customer_number_weeks_viewed_field"/></span>
                    <span className="epow-info">{customer?.numberWeeksViewed ?? "-"}</span>
                  </div>
                  <div className="mb-2 d-flex flex-column">
                    <span className="text-muted"><FormattedMessage id="customer_notification_activity_field"/></span>
                    <span className="epow-info">{optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.notificationForActivity) ?? "-"}</span>
                  </div>
                  <div className="mb-2 d-flex flex-column">
                    <span className="text-muted"><FormattedMessage id="customer_notification_replacement_field"/></span>
                    <span className="epow-info">{optionUtils.translateOption(intl, YES_NO_OPTIONS, customer?.notificationForReplacement) ?? "-"}</span>
                  </div>
              </div>
            </Col>
          </Row>
          <div>
            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended">
              <FormattedMessage id="edit_button"/>
            </Button>
          </div>
        </>
      )}
    </Col>
  )
}

export default SettingInvoicingCustomerCard
