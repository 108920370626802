import React, {FunctionComponent} from "react";
import {Cell} from "react-table";
import CellAvailabilityCalendar from "./CellAvailabilityCalendar";
import {CalendarTableAvailabilityBody, CalendarRowCell} from "../../../../interfaces/CalendarInterface";
import {StaffingCalendarSearchFilter} from "../../staffingcalendar/StaffingCalendar";

interface CellCalendarProps {
    cell: Cell<CalendarRowCell>,
    accessor: string
    nightly?: boolean,
    searchObject: StaffingCalendarSearchFilter,
    onReload: () => void,
}

const CellResourceCalendar: FunctionComponent<CellCalendarProps> = ({
    cell,
    nightly = false,
    accessor,
    searchObject,
    onReload,
}) => {

    const originalElement = cell.row.original[accessor] as CalendarTableAvailabilityBody;

    const commonProps = {
        date: accessor,
        resourceId: cell.row.original.id,
        searchObject,
        onReload,
    }

    return (
        <div className="ms-1">
            <CellAvailabilityCalendar className="mb-2" availabilityType="AM" availability={originalElement?.amAvailibility} {...commonProps} />
            <CellAvailabilityCalendar availabilityType="PM" availability={originalElement?.pmAvailibility} {...commonProps} />
            {nightly && <CellAvailabilityCalendar className="mt-2" availabilityType="NIGHT" availability={originalElement?.nocturalAvailibility} {...commonProps} />}
        </div>);
}

export default CellResourceCalendar;
