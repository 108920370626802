import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {BookingFields} from "../../../interfaces/PurchaseInterfaces";
import {BookingTableDto} from "../../../interfaces/BookingInterfaces";

interface ModalBookingsToDoProps {
  className?: string,
  title?: string,
  open: boolean,
  setOpen?: (open: boolean) => void,
  bookingAsForms?: BookingFields[],
  bookings?: BookingTableDto[],
  action?: (bookingId?: string) => void,
  isSelectionModal?: boolean
  modalBody?: string
}

const ModalBookingsToDo: FunctionComponent<ModalBookingsToDoProps> = ({
  className= "",
  title= "",
  open = false,
  setOpen,
  bookingAsForms,
  bookings,
  action = () => null,
  isSelectionModal = false,
  modalBody = ""
}) => {
  const intl = useIntl();

  return (
    <GenericModal
      className={className}
      title={title}
      open={open}
      onOpen={setOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: action
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => setOpen(false)
        }
      }}
    >
      <div className="d-flex flex-column">
        <FormattedMessage id={modalBody}/>
        {isSelectionModal &&
          <ul>
            {bookingAsForms.map(bookingAsForm => {
              return bookingAsForm.isSelected && (
                <li key={bookingAsForm.bookingId}>
                  {bookings.find(booking => booking.id === bookingAsForm.bookingId)?.serviceName}
                </li>
              )
            })}
          </ul>
        }
      </div>
    </GenericModal>
  )
}

export default ModalBookingsToDo;
