import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import * as Yup from "yup";
import {toastUtils} from "../../../utils/toastUtils";
import FormInput from "../input/FormInput";
import {dayByDayClientService} from "../../../services/DayByDayClientService";

interface UpdateDayByDayClientFormProps {
  id?: string,
  dayByDayClientId: string,
  className?: string,
  customerTourName: string,
  onValidate?: () => void
}

const UpdateDayByDayClientForm: FunctionComponent<UpdateDayByDayClientFormProps> = ({
  id,
  dayByDayClientId,
  className = "",
  customerTourName,
  onValidate = () => null
}) => {
  const intl = useIntl();

  const updateDayByDayClient = ({ customerTourName }) => {
    dayByDayClientService.updateDayByDayClient(dayByDayClientId, customerTourName)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "season_update_day_by_day_client_success"}))
        onValidate()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "season_update_day_by_day_client_failure"}))
      })
  }

  return (
    <Formik
      initialValues={{customerTourName: customerTourName}}
      validationSchema={Yup.object().shape({customerTourName: Yup.string().required()})}
      onSubmit={value => updateDayByDayClient(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormInput
            id="customerTourName"
            label="season_update_tour_customer_name"
            value={values?.customerTourName}
            onChange={(e) => setFieldValue("customerTourName", e.target.value)}
            required
            error={errors.customerTourName}
            touched={touched.customerTourName}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default UpdateDayByDayClientForm
