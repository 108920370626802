import React, {FunctionComponent, useContext} from "react";
import TableCheckbox from "../../atoms/TableCheckbox";
import {useIntl} from "react-intl";
import Circle from "../../atoms/Circle";
import {ResourceAvailabilityContext, ResourceAvailabilityDispatchContext} from "../../../context/ResourceAvailabilityContext";
import {ResourceAvailabilityDispatchType, ResourceAvailabilityDto} from "../../../interfaces/ResourceAvailabilityInterfaces";
import {resourceAvailabilityService} from "../../../services/ResourceAvailabilityService";
import {toastUtils} from "../../../utils/toastUtils";
import {AVAILABILITY_STATUS_TYPE, AVAILABILITY_TYPE, AvailabilityEnum, AvailabilityEnumType, AvailabilityStatusEnum} from "../../../constants/ResourceAvailabilityContants";

interface ResourceAvailabilityIndicatorProps {
  className?: string;
  type: AvailabilityEnumType;
  resourceAvailability: ResourceAvailabilityDto;
  reload: () => void;
  resourceId: string;
}

const ResourceAvailabilityIndicator: FunctionComponent<ResourceAvailabilityIndicatorProps> = ({
  className,
  type,
  resourceAvailability,
  reload,
  resourceId
}) => {
  const intl = useIntl()
  const selectedAvailabilities = useContext(ResourceAvailabilityContext);
  const {dispatch} = useContext(ResourceAvailabilityDispatchContext);

  const getIsSelected = () => {
    let relevantList = [];

    if (type === AvailabilityEnum.AM) {
      relevantList = selectedAvailabilities.amList
    } else if (type === AvailabilityEnum.PM) {
      relevantList = selectedAvailabilities.pmList
    } else {
      relevantList = selectedAvailabilities.nightList
    }

    return relevantList.some(d => d === resourceAvailability.availabilityDate);
  }

  const getIsAvailable = () => {
    if (type === AvailabilityEnum.AM) {
      return resourceAvailability.availableAm
    } else if (type === AvailabilityEnum.PM) {
      return resourceAvailability.availablePm
    }
    return resourceAvailability.availableNight
  }

  const getIsStaffed = () => {
    if (type === AvailabilityEnum.AM) {
      return resourceAvailability.staffedAm
    } else if (type === AvailabilityEnum.PM) {
      return resourceAvailability.staffedPm
    }
    return resourceAvailability.staffedNight
  }

  const getStatus = () => {
    if (getIsStaffed()) {
      return AVAILABILITY_STATUS_TYPE[AvailabilityStatusEnum.STAFFED]
    } else if (getIsAvailable()) {
      return AVAILABILITY_STATUS_TYPE[AvailabilityStatusEnum.AVAILABLE]
    }
    return AVAILABILITY_STATUS_TYPE[AvailabilityStatusEnum.UNAVAILABLE]
  }
  const status = getStatus();

  const selectAvailable = () => dispatch({
    type: getIsSelected() ? ResourceAvailabilityDispatchType.REMOVE_ONE : ResourceAvailabilityDispatchType.ADD_ONE,
    amDate: type === AvailabilityEnum.AM ? resourceAvailability.availabilityDate : null,
    pmDate: type === AvailabilityEnum.PM ? resourceAvailability.availabilityDate : null,
    nightDate: type === AvailabilityEnum.NIGHT ? resourceAvailability.availabilityDate : null,
  })

  const changeAvailability = () => {
    const request: ResourceAvailabilityDto = {
      ...resourceAvailability,
      availableAm: type === AvailabilityEnum.AM ? !resourceAvailability?.availableAm : resourceAvailability?.availableAm,
      availablePm: type === AvailabilityEnum.PM ? !resourceAvailability?.availablePm : resourceAvailability?.availablePm,
      availableNight: type === AvailabilityEnum.NIGHT ? !resourceAvailability?.availableNight : resourceAvailability?.availableNight,
    }
    const promise = request.id != null ?
      resourceAvailabilityService.updateResourceAvailability(request.id, request, resourceId)
      : resourceAvailabilityService.createResourceAvailability(request, resourceId)

    promise.then(() => {
      reload()
      toastUtils.successToast(intl.formatMessage({id: "success_update_resource_availability"}))
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_update_resource_availability"}))
    })
  }

  return status && (
    <div className={`d-flex align-items-center gap-2 my-1 ${className ?? ""}`}>
      <TableCheckbox className={(getIsAvailable() && !getIsStaffed()) ? "" : "invisible"} checked={getIsSelected()} onChange={() => selectAvailable()} />

      <div className="bold col-2">
        {intl.formatMessage({id: AVAILABILITY_TYPE[type].label})}
      </div>

      <div className="col-1">
        <Circle color={status.color} />
      </div>

      <div className="col-6">
        <span>{intl.formatMessage({id: status.label})}</span>
      </div>

      <div className="d-flex col-12 epow-text-link cursor-pointer" onClick={() => changeAvailability()}>
        {!getIsStaffed() && (
          <span className="ms-auto me-2">{intl.formatMessage({id: status.setterLabel})}</span>
        )}
      </div>

    </div>
  )
}

export default ResourceAvailabilityIndicator;
