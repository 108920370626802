import React, {createContext, useReducer} from "react";
import {ResourceAvailabilityDispatchType, ResourceAvailabilityDispatcher} from "../interfaces/ResourceAvailabilityInterfaces";

export interface ResourceAvailabilityContextInterface {
  amList: string[],
  pmList: string[],
  nightList: string[],
  hasNight: boolean,
}

export interface ResourceAvailabilityDispatchContextInterface {
  dispatch: React.Dispatch<ResourceAvailabilityDispatcher>,
}

export const ResourceAvailabilityContext = createContext<ResourceAvailabilityContextInterface>(null);
export const ResourceAvailabilityDispatchContext = createContext<ResourceAvailabilityDispatchContextInterface>(null);

interface ResourceAvailabilityProviderProps {
  children?: React.ReactNode,
  isNocturnal: boolean,
}

const ResourceAvailabilityProvider: React.FunctionComponent<ResourceAvailabilityProviderProps> = ({children, isNocturnal}) => {

  function availabilitiesReducer(state: ResourceAvailabilityContextInterface, action: ResourceAvailabilityDispatcher) {
    switch (action.type) {
      case ResourceAvailabilityDispatchType.ADD_ALL: {
        return {...state, amList: action.allDates, pmList: action.allDates, nightList: state.hasNight ? action.allDates: []}
      }
      case ResourceAvailabilityDispatchType.REMOVE_ALL: {
        return {...state, amList: [], pmList: [], nightList: []}
      }
      case ResourceAvailabilityDispatchType.ADD_ONE: {
        const updatedAmList = action.amDate !== null ? [...state.amList, action.amDate] : state.amList;
        const updatedPmList = action.pmDate !== null ? [...state.pmList, action.pmDate] : state.pmList;
        const updatedNightList = action.nightDate !== null ? [...state.nightList, action.nightDate] : state.nightList;

        return ({...state, amList: updatedAmList, pmList: updatedPmList, updatedNightList: updatedNightList})
      }
      case ResourceAvailabilityDispatchType.REMOVE_ONE: {
        const updatedAmList = action.amDate !== null ? state.amList.filter(d => d !== action.amDate) : state.amList;
        const updatedPmList = action.pmDate !== null ? state.pmList.filter(d => d !== action.pmDate) : state.pmList;
        const updatedNightList = action.nightDate !== null ? state.nightList.filter(d => d !== action.nightDate) : state.nightList;

        return ({...state, amList: updatedAmList, pmList: updatedPmList, updatedNightList: updatedNightList})
      }
      default: {
        throw Error("Unknown action")
      }
    }
  }

  const [selectedAvailabilities, dispatch] = useReducer(availabilitiesReducer, {amList: [], pmList: [], nightList:[], hasNight: isNocturnal})

  return (
    <ResourceAvailabilityContext.Provider value={selectedAvailabilities}>
      <ResourceAvailabilityDispatchContext.Provider value={{dispatch}}>
        {children}
      </ResourceAvailabilityDispatchContext.Provider>
    </ResourceAvailabilityContext.Provider>
  )
}

export default ResourceAvailabilityProvider;
