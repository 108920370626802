import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {EditUserProfileSchema} from "../../../../constants/validation/UserValidationSchema";
import {PROFILE, User, UserSelfProfile} from "../../../../interfaces/UserInterfaces";
import {userService} from "../../../../services/UserService";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {authUtils} from "../../../../utils/authUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";
import UserEditProfileFormFragment from "./UserEditProfileFormFragment";

interface EditUserProfileFormProps {
  className?: string,
  user: UserSelfProfile,
  id: string,
  onEdit?: (user: User) => void,
  setEditing: (editing: boolean) => void,
}

const EditUserProfileForm: FunctionComponent<EditUserProfileFormProps> = ({
  className = "",
  user,
  id,
  onEdit = () => null,
  setEditing,
}) => {

  const intl = useIntl()
  const profilesWithOtherPhoneNumber = [PROFILE.CLIENT, PROFILE.RESOURCE, PROFILE.TOUR_LEADER]

  const handleSubmitUser = (submittedUser: UserSelfProfile) => {
    userService.updateSelfUser(submittedUser)
      .then((user: User) => {
        onEdit(user);
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_user"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_user"}))
      })
  }

  return (
    <div className={className}>

      <Formik
        initialValues={user}
        validationSchema={EditUserProfileSchema}
        onSubmit={(values) => handleSubmitUser(values)}
      >
        {({errors, touched, values, setFieldValue, submitForm}) => (
          <EpowForm id={id} className={className}>
            <UserEditProfileFormFragment
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />

            {profilesWithOtherPhoneNumber.some(profile => profile === authUtils.getProfile()) && (
              <FormInput
                id="otherPhoneNumber"
                label="user_other_phoneNumber"
                value={values?.otherPhoneNumber}
                onChange={(e) => setFieldValue("otherPhoneNumber", e.target.value)}
                required
                error={errors.otherPhoneNumber}
                touched={touched.otherPhoneNumber}
              />
            )}

            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditing(false)} className="extended">
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button size={SizeType.MD} color={ColorType.PRIMARY} onClick={() => submitForm()} className="ms-2 extended">
              <FormattedMessage id="save_button" />
            </Button>
          </EpowForm>
        )}
      </Formik>
    </div>
  )
}

export default EditUserProfileForm;
