import React, {FunctionComponent} from "react";
import {Row, TableInstance} from "react-table";
import {SortOptions} from "../../../interfaces/api/PaginationInterface";
import SortButton from "./SortButton";

interface TableBodyProps {
  className?: string,
  rowProps?: (row: Row) => Object,
  sortOptions?: Array<SortOptions>,
  setSortOptions?: (sortOptions: Array<SortOptions>) => void;
  tableInstance: TableInstance,
  hideHeader?: boolean
}

const TableBody: FunctionComponent<TableBodyProps> = ({
  className = "",
  rowProps = () => ({}),
  sortOptions= [],
  setSortOptions = () => null,
  tableInstance,
  hideHeader= false
}) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance

  return (
    <table className={`epow-table ${className}`} {...getTableProps()}>
      <thead>
        {hideHeader === false && headerGroups.map((headerGroup, indexHeaderGroup) => (
          <tr className="epow-table-header" key={indexHeaderGroup} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, indexHeaderGroupHeaders) => {
              return (
                <th
                  style={{width: column.width, maxWidth: column.maxWidth}}
                  key={indexHeaderGroupHeaders}
                  {...column.getHeaderProps()}
                >
                  <div className="d-flex">
                    <span>{column.render("Header")}</span>
                    {sortOptions[0] && <SortButton column={column} sortOptions={sortOptions[0]} onSortOptions={sortOptions => setSortOptions([sortOptions])} />}
                  </div>
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        <>
          {rows.map((row, rowIndex) => {
            prepareRow(row)

            return (
              <tr key={rowIndex} className="epow-table-row" {...row.getRowProps(rowProps(row))}>
                {row.cells.map((cell, key) => {
                  return (
                    <td key={key}>
                      <span>
                        {cell.render("Cell")}
                      </span>
                    </td>
                  )
                })
                }
              </tr>
            )
          })}
        </>
      </tbody>
    </table>
  )
}

export default TableBody;
