import * as Yup from "yup";
import {ORDER_ITEM_TYPE, OrderItemResourceDTO} from "../../interfaces/OrderItemInterfaces";
import {
  getRequiredBooleanForCondition,
  getRequiredNumberForCondition,
  yupRequiredBoolean,
  yupRequiredNumber,
  yupRequiredString
} from "../yup/Validators";
import {AnyObject} from "yup/es/types";


const isActivity = (type: string) => type === ORDER_ITEM_TYPE.ACTIVITY.toString();

export const OrderItemCreationFirstStepSchema = Yup.object().shape({
  type: yupRequiredString,
  name: yupRequiredString,
  date: yupRequiredString,
  hour: yupRequiredString,
  place: yupRequiredString,
  optional: getRequiredBooleanForCondition("type", isActivity),
  isStaffingManagement: getRequiredBooleanForCondition("type", isActivity),
  isBookingManagement: yupRequiredBoolean,
});



export function CreateOrderItemSchema (orderItemResources: OrderItemResourceDTO[]): Yup.ObjectSchema<AnyObject> {
  let schema: Yup.ObjectSchema<AnyObject> = OrderItemCreationFirstStepSchema.shape({
    type: yupRequiredString,
    name: yupRequiredString,
    date: yupRequiredString,
    hour: yupRequiredString,
    place: yupRequiredString,
    paxPrice: yupRequiredNumber,
    optional: getRequiredBooleanForCondition("type", isActivity),
    isStaffingManagement: getRequiredBooleanForCondition("type", isActivity),
    isBookingManagement: yupRequiredBoolean,
    showActivityTourLeader: getRequiredBooleanForCondition("type", isActivity),
    unitType: yupRequiredString,
    pax: yupRequiredNumber,
    isPaxConfirmed: yupRequiredBoolean,
    quantity: yupRequiredNumber,
    sellPrice: yupRequiredNumber,
    billingService: yupRequiredString,
    sellPriceTVA: yupRequiredNumber,
    disbursements: yupRequiredBoolean,
  });

  orderItemResources?.map((orderItemResource, index) => {
    schema = schema.shape({
      [`numberOf${index}`]: getRequiredNumberForCondition("isStaffingManagement", (isBookingManagement) => isBookingManagement === "true"),
    })
  })

  return schema;
}

