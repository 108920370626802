import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {ACTIVITY_SERVICES_INITIAL_VALUES} from "../../../constants/ActivityConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {CreateActivityServicesSchema} from "../../../constants/validation/ActivityValidationSchemas";
import {Activity, ActivityFormFields} from "../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../services/ActivityService";
import EpowForm from "./EpowForm";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {serviceService} from "../../../services/ServiceService";

interface CreateActivityServicesFormProps {
  id?: string,
  className?: string,
  activity?: Activity,
  onValidate?: () => void
}

const CreateActivityServicesForm: FunctionComponent<CreateActivityServicesFormProps> = ({
  id,
  className = "",
  activity,
  onValidate = () => null,
}) => {
  const intl = useIntl()

  const handleSubmitActivityServices = (activityFields: ActivityFormFields) => {
    if (activity?.id) {
      activityService.addActivityService(activity?.id, activityFields.activityServicesId.map(item => item.value))
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_activity"}))
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_activity"}))
        })
    }
  }

  return (
    <Formik initialValues={ACTIVITY_SERVICES_INITIAL_VALUES} validationSchema={CreateActivityServicesSchema} onSubmit={handleSubmitActivityServices}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormAutocompleteMultiselect
            id="activityServicesId"
            label={intl.formatMessage({id: "services_in_activity"})}
            values={values?.activityServicesId}
            onChange={(option?: Option<string>[]) => setFieldValue("activityServicesId", option)}
            fetchData={(inputValue) => serviceService.getServicesPage({page: 0, pageSize: 40}, {name: inputValue, currentState: WorkflowStatesEnum.ACTIVE})}
            filterFieldName="name"
            required
            error={errors.activityServicesId}
            touched={touched.activityServicesId}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivityServicesForm
