import {API_RESOURCE_AVAILABILITY_PATH} from "../constants/routes/RoutePaths";
import {ResourceAvailabilityDto} from "../interfaces/ResourceAvailabilityInterfaces";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";

function getResourceAvailabilitiesForCurrentUser(params: PaginationQueryParams, filterParams: SearchFilter): Promise<ResourceAvailabilityDto[]> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams);
  return httpService.get(`${API_RESOURCE_AVAILABILITY_PATH}/resource${queryParams}`)
}

function createResourceAvailability(request: ResourceAvailabilityDto, resourceId: string): Promise<ResourceAvailabilityDto> {
  return httpService.post(`${API_RESOURCE_AVAILABILITY_PATH}/resource/${resourceId}`, request)
}

function updateResourceAvailability(id: string, request: ResourceAvailabilityDto, resourceId: string): Promise<ResourceAvailabilityDto> {
  return httpService.put(`${API_RESOURCE_AVAILABILITY_PATH}/resource/${resourceId}/${id}`, request)
}

function bulkUpdateResourceAvailability(request: ResourceAvailabilityDto[], resourceId: string): Promise<ResourceAvailabilityDto> {
  return httpService.put(`${API_RESOURCE_AVAILABILITY_PATH}/bulk/resource/${resourceId}`, request)
}

export const resourceAvailabilityService = Object.freeze({
  getResourceAvailabilitiesForCurrentUser,
  createResourceAvailability,
  updateResourceAvailability,
  bulkUpdateResourceAvailability,
})
