import React, { FunctionComponent } from "react";

interface CardFileFieldProps {
  className?: string;
  label?: string;
  value?: string;
  onClick: () => void;
}

const CardFileField: FunctionComponent<CardFileFieldProps> = ({
  className = "",
  label,
  value,
  onClick
}) => {

  return (
    <div className={`d-flex flex-column ${className}`}>
      <span className="text-muted">{label}</span>
        <span className={`epow-info ${value ? "epow-text-link cursor-pointer" : ""}`} onClick={onClick}>
          {value ? value : "-"}
        </span>
    </div>
  )
}

export default CardFileField;