import React, {FunctionComponent, useMemo} from "react";
import CustomerBillingList from "../../components/molecules/list/billing/CustomerBillingList";
import Layout from "../../components/layout/Layout";
import useSearchFilter from "../../hooks/useSearchFilter";
import {InvoiceSearchObject, InvoiceState} from "../../interfaces/InvoiceInterfaces";
import PageTitle from "../../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";

const CustomerBillingListView: FunctionComponent = () => {

  const initialSearchState: InvoiceSearchObject = useMemo(() => ({
    invoiceNumber: "",
    status: InvoiceState.BILLED,
    startDate: "",
    endDate: "",
    customerIds: []
  }), [])

  const intl = useIntl()
  const {searchObject, updateSearchField, onReset} = useSearchFilter<InvoiceSearchObject>(initialSearchState)

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-4">
          <PageTitle pageTitle={intl.formatMessage({id: "billing_tab_invoices"})}></PageTitle>
        </div>
        <CustomerBillingList searchObject={searchObject} updateSearchField={updateSearchField} onReset={() => onReset(initialSearchState)} />
      </div>
    </Layout>
  )
}

export default CustomerBillingListView;
