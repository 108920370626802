import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import React, {FunctionComponent} from "react";
import ErrorField from "./ErrorField";
import FormRadioLight, {FormRadioLightProps} from "./FormRadioLight";

interface FormRadioProps extends FormRadioLightProps{
  error?: FormikError,
  touched?: FormikTouchedType,
}

const FormRadio: FunctionComponent<FormRadioProps> = ({
  error,
  touched,
  ...rest
}) => (
  <FormRadioLight {...rest}>
    <ErrorField error={error} touched={touched}/>
  </FormRadioLight>
)

export default FormRadio
