import * as Yup from "yup";
import {yupRequiredDate, yupRequiredNumber, yupRequiredString, yupRequireStringMaxLength} from "../yup/Validators";

export const CreateTourSchema = Yup.object().shape({
  tourName: yupRequiredString,
  tourCode: yupRequireStringMaxLength(20),
  numberPax: yupRequiredNumber
})

export const CreateTourActivitySchema = Yup.object().shape({
  activityId: yupRequiredString,
  dayAfterArrival: yupRequiredNumber,
  hour: yupRequiredString,
  optional: yupRequiredString,
  meetingPlace: yupRequiredString,
  description: yupRequiredString,
})

export const CreateTourCustomerSchema = Yup.object().shape({
  customerId: yupRequiredString,
})

export const CreateTourActivityCustomerSchema = Yup.object().shape({
  billingClientId: yupRequiredString,
  clientBillingActivityName: yupRequiredString,
  dayAfterArrival: yupRequiredNumber,
  hour: yupRequiredString,
  optional: yupRequiredString,
  description: yupRequiredString,
})

export const CreateSeasonSchema = Yup.object().shape({
  name: yupRequiredString,
  startDate: yupRequiredDate
})
