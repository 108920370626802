import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {orderItemService} from "../../../../services/OrderItemService";
import {toastUtils} from "../../../../utils/toastUtils";
import StaffingForm from "./StaffingForm";
import {ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";

interface MultipleStaffingFormProps {
  className?: string,
  setFormOpen: (open: boolean) => void,
  orderItemIds: string[],
  onStaffing?: () => void,
}

const MultipleStaffingForm: FunctionComponent<MultipleStaffingFormProps> = ({
  className = "",
  setFormOpen,
  orderItemIds,
  onStaffing = () => null,
}) => {
  const intl = useIntl()

  const sendRequest = (resourceRequest: ResourceStaffingRequest[]) => {
    orderItemService.sendStaffingSelectionRequestForMultipleOrderItems(orderItemIds, resourceRequest)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_request"}))
        onStaffing()
      })
      .catch(() =>
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      )
      .finally(() =>
        onStaffing()
      )
  }

  return (
    <StaffingForm
      className={className}
      setFormOpen={setFormOpen}
      sendRequest={(isDirectStaffing, selectedResources, resourceRequest) => sendRequest(resourceRequest)}
      hasStaffing={false}
      hasDirectStaffing={false}
      hasFilters={false}
    />
  )
}

export default MultipleStaffingForm;
