import React, {ChangeEventHandler, FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {FormGroup, Label} from "reactstrap";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import ErrorField from "./ErrorField";
import CustomEditor from "./editor/CustomEditor";

interface RichTextInputProps {
  id?: string,
  className?: string,
  label?: string,
  value?: string,
  required?: boolean,
  error?: FormikError
  touched?: FormikTouchedType,
  onChange?: ChangeEventHandler<HTMLInputElement>,
}

const RichTextInput: FunctionComponent<RichTextInputProps> = ({
  id,
  className = "",
  label,
  value,
  required,
  error,
  touched,
  onChange,
}) => {
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            <FormattedMessage id={label} />
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
      </div>
      <CustomEditor value={value} onChange={onChange} />
      <ErrorField error={error} touched={touched} />
    </FormGroup>
  )
}

export default RichTextInput;
