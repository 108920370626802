import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {BOOKINGS_PATH, ORDERS_PATH, STAFFING_PATH} from "../../../../constants/routes/RoutePaths";
import {AdminConfigurationDto} from "../../../../interfaces/AdminConfigurationInterfaces";
import {BookingStatus} from "../../../../interfaces/BookingInterfaces";
import {DashboardTasksCounts} from "../../../../interfaces/DashboardInterfaces";
import {OrderState} from "../../../../interfaces/OrderInterfaces";
import {OrderItemStaffingStatus} from "../../../../interfaces/OrderItemInterfaces";
import {activityStaffingService} from "../../../../services/ActivityStaffingService";
import {adminConfigurationService} from "../../../../services/AdminConfigurationService";
import {bookingService} from "../../../../services/BookingService";
import {orderItemService} from "../../../../services/OrderItemService";
import {orderService} from "../../../../services/OrderService";
import {dateUtils} from "../../../../utils/dateUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import NextTasks from "../../dashboard/NextTasks";

interface DashboardNextTasksCardProps {
  className?: string,
  calendarDate: Date,
}

const DashboardNextTasksCard: FunctionComponent<DashboardNextTasksCardProps> = ({
  className = "",
  calendarDate= new Date(),
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [counters, setCounters] = React.useState<DashboardTasksCounts>({
    ordersToConfirm: "0",
    bookingsToCreateInXDays: "0",
    bookingsToConfirmInXDays: "0",
    orderItemsToStaffInXDays: "0",
    staffedResourcesToday: "0",
  })
  const [settings, setSettings] = React.useState<AdminConfigurationDto>({})

  const loadAdminSettings = async (): Promise<AdminConfigurationDto> => {
    return await adminConfigurationService.getAdminConfiguration()
      .then(response => response)
      .catch(() => null)
  }

  const getOrdersToConfirm = async (): Promise<string> => {
    return await orderService.getOrdersPage({pageSize: 1}, {status: OrderState.DRAFT})
      .then(response => response.totalElements)
      .catch(() => null)
  }

  const buildFilterPeriod = (days: number) => {
    const today = new Date()
    const end = new Date()
    end.setDate(today.getDate() + days)
    return {
      startDate: dateUtils.formatDateYYYYMMDD(today), endDate: dateUtils.formatDateYYYYMMDD(end)
    }
  }

  const getBookingsToCreateInXDays = async (days: number): Promise<string> => {
    return await bookingService.getBookingsPage({pageSize: 1}, {...buildFilterPeriod(days), status: [BookingStatus.TO_DO, BookingStatus.CANCELLED]})
      .then(response => response.totalElements)
      .catch(() => null)
  }

  const getBookingsToConfirmInXDays = async (days: number): Promise<string> => {
    return await bookingService.getBookingsPage({pageSize: 1}, {...buildFilterPeriod(days), status: [BookingStatus.PRE_BOOKED, BookingStatus.TO_UPDATE, BookingStatus.TO_CANCEL]})
      .then(response => response.totalElements)
      .catch(() => null)
  }

  const getOrderItemsToStaffInXDays = async (days: number): Promise<string> => {
    const request = {...buildFilterPeriod(days), isStaffingManagement: true, staffingStatus: OrderItemStaffingStatus.TO_STAFF}
    return await orderItemService.getOrderItemsStaffingPage({pageSize: 1}, request)
      .then(response => response.totalElements)
      .catch(() => null)
  }

  const getStaffedResourcesOnDate = async (date: Date): Promise<string> => {
    const staffingDate = dateUtils.formatDateYYYYMMDD(date);
    return await activityStaffingService.getStaffedOnDay({pageSize: 1}, {startDate: staffingDate, endDate: staffingDate})
      .then(response => response.totalElements)
      .catch(() => null)
  }

  useMemo(() => {
    const loadData = async () => {
      const adminSettings = await loadAdminSettings()
      setSettings(adminSettings)
      setCounters({
        ordersToConfirm: await getOrdersToConfirm() ?? "0",
        bookingsToCreateInXDays: await getBookingsToCreateInXDays(adminSettings?.dashboardBookingTodoWindow) ?? "0",
        bookingsToConfirmInXDays: await getBookingsToConfirmInXDays(adminSettings?.dashboardBookingPendingWindow) ?? "0",
        orderItemsToStaffInXDays: await getOrderItemsToStaffInXDays(adminSettings?.dashboardStaffingWindow) ?? "0",
        staffedResourcesToday: await getStaffedResourcesOnDate(calendarDate) ?? "0",
      })
    }

    loadData().catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_save_admin_config"}))
    })
  }, [calendarDate])

  const redirectToOrders = () => {
    navigate(`${ORDERS_PATH}?status=${OrderState.DRAFT}`)
  }

  const redirectToBookingsToCreate = () => {
    const {startDate, endDate} = buildFilterPeriod(settings?.dashboardBookingTodoWindow)
    navigate(`${BOOKINGS_PATH}?startDate=${startDate}&endDate=${endDate}&status=${BookingStatus.TO_DO},${BookingStatus.CANCELLED}`)
  }

  const redirectToBookingsToConfirm = () => {
    const {startDate, endDate} = buildFilterPeriod(settings?.dashboardBookingPendingWindow)
    const statuses = [BookingStatus.PRE_BOOKED, BookingStatus.TO_UPDATE, BookingStatus.TO_CANCEL].join(",")
    navigate(`${BOOKINGS_PATH}?startDate=${startDate}&endDate=${endDate}&status=${statuses}`)
  }

  const redirectToStaffing = () => {
    const {startDate, endDate} = buildFilterPeriod(settings?.dashboardStaffingWindow)
    navigate(`${STAFFING_PATH}?startDate=${startDate}&endDate=${endDate}`)
  }

  const redirectToResources = () => {
    const date = dateUtils.formatDateYYYYMMDD(calendarDate);
    navigate(`${STAFFING_PATH}?startDate=${date}&endDate=${date}&staffingStatus=${OrderItemStaffingStatus.STAFFED}`)
  }

  return (
    <div className={className}>
      <NextTasks tasks={[
        {title: intl.formatMessage({id: "dashboard_tasks_orders"}), icon: "Search", number: counters.ordersToConfirm, onClick: redirectToOrders},
        {title: intl.formatMessage({id: "dashboard_tasks_booking_todo"}), icon: "Search", number: counters.bookingsToCreateInXDays, onClick: redirectToBookingsToCreate},
        {title: intl.formatMessage({id: "dashboard_tasks_booking_pending"}), icon: "Search", number: counters.bookingsToConfirmInXDays, onClick: redirectToBookingsToConfirm},
        {title: intl.formatMessage({id: "dashboard_tasks_to_staff"}), icon: "Search", number: counters.orderItemsToStaffInXDays, onClick: redirectToStaffing},
        {title: intl.formatMessage({id: "dashboard_tasks_staffed"}), icon: "Search", number: counters.staffedResourcesToday, onClick: redirectToResources},
      ]} />
    </div>
  )
}

export default DashboardNextTasksCard;
