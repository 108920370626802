import React, { FunctionComponent } from "react";
import {authUtils} from "../../utils/authUtils";
import {RouteProps} from "react-router-dom";
import {PROFILE_INTERFACE} from "../../constants/user/UserConstants";


const ProfileDispatcher : FunctionComponent<RouteProps> = () => {
  const component = PROFILE_INTERFACE[authUtils.getProfile()]
 return !!component ? component : <div>Not found</div>
}


export default ProfileDispatcher;
