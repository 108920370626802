import React, {FunctionComponent, useEffect, useState} from "react";
import ButtonGroup from "../../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {fileUtils} from "../../../../utils/fileUtils";
import {orderService} from "../../../../services/OrderService";
import useLoading from "../../../../hooks/useLoading";
import { Order } from "interfaces/OrderInterfaces";
import { toastUtils } from "utils/toastUtils";

interface OrderActionsDropdownProps {
  className?: string,
  orderId: string,
}

const OrderActionsDropdown: FunctionComponent<OrderActionsDropdownProps> = ({
  className = "",
  orderId,
}) => {
  const intl = useIntl()
  const {loading, startLoading, stopLoading} = useLoading()
  const [order, setOrder] = useState<Order>()

  useEffect(() => {
    orderService.getOrderById(orderId)
      .then((order) => {
        setOrder(order)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_get"}))
      })
  }, [orderId])

  const exportTravelBook = () => {
    startLoading();
    fileUtils.downloadFile(orderService.getTravelBook(orderId), {name: "Roadbook_" + order.orderNumber, extension: "pdf"}, intl, () => stopLoading());
  }


  return (<>
    <ButtonGroup title="Actions" className={className} loading={loading}>
      <DropdownItem color={ColorType.PRIMARY} className="epow-button me-2"
        onClick={() => exportTravelBook()}>
        <div>
          {intl.formatMessage({id: "order_travel_book_button"})}
        </div>
      </DropdownItem>
    </ButtonGroup>
    </>
  )
}

export default OrderActionsDropdown;
