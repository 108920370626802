import React, {FunctionComponent, useState} from "react";
import {Contact, EntityContactRequest, HasContact} from "../../../../interfaces/ContactInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import Panel from "../../panel/Panel";
import ModalUpdateAccess from "../../modal/contacts/ModalUpdateAccess";
import ContactForm from "../../form/contact/ContactForm";
import ModalMainContactUpdate from "../../modal/contacts/ModalMainContactUpdate";
import ContactTable from "../../table/contact/ContactTable";
import ModalDeleteContact from "../../modal/contacts/ModalDeleteContact";

interface ContactListProps {
  className?: string,
  entity: HasContact,
  contacts: Contact[],
  onUpdate: (updatedContact: Contact) => void,
  refreshData: () => void,
  createContactRequest: (request: EntityContactRequest, entityId: string) => Promise<unknown>,
  updateMainContactRequest: (id: string, entityId: string, request: EntityContactRequest) => Promise<unknown>,
}

const ContactList: FunctionComponent<ContactListProps> = ({
  className = "",
  entity,
  contacts,
  onUpdate,
  refreshData,
  createContactRequest,
  updateMainContactRequest,
}) => {
  contacts = contacts.sort((a, b) => Number(b.isMainContact) - Number(a.isMainContact))
  const intl = useIntl();
  const [lateralPanelContact, setLateralPanelContact] = useState<boolean>(false);
  const [contactInitial, setContactInitial] = useState<Contact>({});
  const [openModalUpdateAccess, setOpenModalUpdateAccess] = useState<boolean>(false);
  const [openModalMainContact, setOpenModalMainContact] = useState<boolean>(false);
  const [openModalDeleteContact, setOpenModalDeleteContact] = useState<boolean>(false);

  const openNodeWithContact = (contact: Contact, setOpen: (open: boolean) => void) => {
    setContactInitial(contact);
    setOpen(true)
  }

  const handleUpdateAccess = (contact?: Contact) => openNodeWithContact(contact, setOpenModalUpdateAccess);
  const handleUpdateMainContact = (contact?: Contact) => openNodeWithContact(contact, setOpenModalMainContact);
  const handleEditContact = (contact?: Contact) => openNodeWithContact(contact, setLateralPanelContact);
  const handleDeleteContact = (contact?: Contact) => openNodeWithContact(contact, setOpenModalDeleteContact);

  const handleValidate = () => {
    setLateralPanelContact(false);
    refreshData();
  };

  const handleAddContact = () => {
    setLateralPanelContact(true);
    setContactInitial({});
  };

  const onUpdateMainContact = (mainContact: Contact) => {
    onUpdate(mainContact);
    refreshData();
  }

  return (
    <div className={className}>
      <ContactTable
        data={contacts}
        handleUpdateAccess={handleUpdateAccess}
        handleUpdateMainContact={handleUpdateMainContact}
        handleEditContact={handleEditContact}
        handleDeleteContact={handleDeleteContact}
      />
      <Button color={ColorType.SECONDARY} onClick={handleAddContact}>
        <FormattedMessage id="add_button" />
      </Button>
      {lateralPanelContact && (
        <Panel
          formId="createSupplierContactForm"
          title="create_new_contact"
          open={lateralPanelContact}
          onCancel={() => setLateralPanelContact(false)}
        >
          <ContactForm
            id="createSupplierContactForm"
            entity={entity}
            isMainContact={contacts.length === 0}
            contactInitial={contactInitial}
            onValidate={handleValidate}
            createContactRequest={createContactRequest}
          />
        </Panel>
      )}
      <ModalUpdateAccess
        title={intl.formatMessage({id: "modal_title_update_access_platform"})}
        openModalUpdateAccess={openModalUpdateAccess}
        setOpenModalUpdateAccess={setOpenModalUpdateAccess}
        getContacts={refreshData}
        contactInitial={contactInitial}
      />
      <ModalMainContactUpdate
        title={intl.formatMessage({id: "modal_title_update_main_contact"})}
        openModalMainContact={openModalMainContact}
        setOpenModalMainContact={setOpenModalMainContact}
        onUpdate={onUpdateMainContact}
        contactInitial={contactInitial}
        entity={entity}
        updateRequest={updateMainContactRequest}
      />
      <ModalDeleteContact
        modalOpen={openModalDeleteContact}
        setModalOpen={setOpenModalDeleteContact}
        getContacts={refreshData}
        contact={contactInitial}
      />
    </div>
  )
}

export default ContactList;
