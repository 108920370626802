import React, {FunctionComponent} from "react";
import {Button as ReactStrapButton, ButtonProps as ReactStrapButtonProps} from "reactstrap";
import {ColorType, ColorTypeValues, SizeType, SizeTypeValues} from "../../types/bootstrap/BootstrapType";


export interface ButtonProps extends ReactStrapButtonProps {
  className?: string,
  size?: SizeTypeValues,
  color?: ColorTypeValues,
  onClick?: (event) => void,
  form?: string,
  disabled?: boolean,
  loading?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({
  className = "",
  children,
  size = SizeType.MD,
  color = ColorType.PRIMARY,
  onClick,
  form,
  disabled = false,
  loading,
  ...rest
}) => (
  <ReactStrapButton
    {...rest}
    form={form}
    className={`epow-button ${className} ${(disabled || loading) ? "disabled" : ""} ${loading ? "loading" : ""}`}
    color={color}
    size={size}
    onClick={onClick}>
    <div className="d-flex align-items-center justify-content-center">
      {loading && <span className="loader"/>}
      {children}
    </div>
  </ReactStrapButton>
)


export default Button;
