import React, {FunctionComponent} from "react";
import {Col, Row} from "reactstrap";
import {fileUtils} from "../../../../utils/fileUtils";
import {useIntl} from "react-intl";
import {fileService} from "../../../../services/FileService";
import {DocumentDTO} from "../../../../interfaces/FileInterfaces";

interface DocumentTableRowExternalProps {
  className?: string,
  document: DocumentDTO,
}

const DocumentTableRowExternal: FunctionComponent<DocumentTableRowExternalProps> = ({
  className = "",
  document,
}) => {
  const intl = useIntl()
  const fullName = fileUtils.getCompleteFileName({name: document.name, extension: document.extension});

  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  return (
    <Row className={`document-table-row ${className ?? ""}`}>
      <Col>
        <span className="epow-text-link cursor-pointer" onClick={() => downloadDocument(document.fileId, document.name, document.extension)}>{fullName}</span>
      </Col>

      <Col>
        {document.activities?.map(activity => <div key={activity.id}>{activity.name}</div>) ?? "-"}
      </Col>
    </Row>
  )
}

export default DocumentTableRowExternal;
