import {API_ADMIN_CONFIG_PATH} from "../constants/routes/RoutePaths";
import {AdminConfigurationDto} from "../interfaces/AdminConfigurationInterfaces";
import {httpService} from "./HttpService"

const getAdminConfiguration = (): Promise<AdminConfigurationDto> => {
  return httpService.get<AdminConfigurationDto>(API_ADMIN_CONFIG_PATH)
}

const updateAdminConfiguration = (updatedConfiguration: AdminConfigurationDto): Promise<AdminConfigurationDto> => {
  return httpService.put<AdminConfigurationDto>(`${API_ADMIN_CONFIG_PATH}`, updatedConfiguration);
}

export const adminConfigurationService = Object.freeze({
  getAdminConfiguration,
  updateAdminConfiguration,
})
