import React, {FunctionComponent} from "react";
import ButtonGroup from "../../button/ButtonGroup";
import {DropdownItem} from "reactstrap";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {fileUtils} from "../../../../utils/fileUtils";
import {quoteService} from "../../../../services/QuoteService";
import useLoading from "../../../../hooks/useLoading";
import { Quote } from "interfaces/QuoteInterfaces";

interface QuoteActionsDropdownProps {
  className?: string,
  quote: Quote,
}

const QuoteActionsDropdown: FunctionComponent<QuoteActionsDropdownProps> = ({
    className = "",
    quote,
  }) => {
  const intl = useIntl()
  const {loading, startLoading, stopLoading} = useLoading()

  const exportTravelBook = () => {
    startLoading()
    fileUtils.downloadFile(quoteService.getTravelBook(quote?.id), {name: "Carnet_Voyage_Devis_" + quote?.quoteNumber, extension: "pdf"}, intl, stopLoading)
  }

  return (
    <ButtonGroup title="Actions" className={className} loading={loading}>
      <DropdownItem color={ColorType.PRIMARY} className="epow-button me-2"
                    onClick={() => exportTravelBook()}>
        <div>
          {intl.formatMessage({id: "quote_travel_book_button"})}
        </div>
      </DropdownItem>
    </ButtonGroup>
  )
}

export default QuoteActionsDropdown;
