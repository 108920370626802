import React, { ChangeEvent, FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { FormGroup, Input, Label } from "reactstrap";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import { toastUtils } from "../../../utils/toastUtils";
import { InputType } from "reactstrap/types/lib/Input";
import { FormikError, FormikTouchedType } from "../../../interfaces/ErrorInterfaces";

interface FormFileUploadProps {
  id?: string,
  className?: string,
  files?: File[],
  fileName?: string,
  label?: string,
  required?: boolean,
  accept?: string[]
  error?: FormikError
  touched?: FormikTouchedType,
  type?: InputType,
  popover?: string,
  onChange?: (files: File[]) => void
  locked?: boolean
}

const FormMultipleFileUpload: FunctionComponent<FormFileUploadProps> = ({
  id,
  className = "",
  files,
  label,
  required,
  error,
  touched,
  type = "file",
  popover = "",
  accept = [".jpg", ".jpeg", ".pdf", ".docx", ".xlsx", ".xls", ".pptx", ".png"],
  onChange,
  locked = false
}) => {
  const intl = useIntl()

  const checkFileExtension = (files: FileList): boolean => {
    return Object.values(files).every(file => {
      const fileNameParts = file.name.split(".")
      if (fileNameParts.length < 2) return false
      return accept.includes(`.${fileNameParts[fileNameParts.length - 1]}`)
    })
  }

  const triggerOnChange = (files: FileList): void => {
    if (!checkFileExtension(files)) {
      toastUtils.errorToast(intl.formatMessage({ id: "upload_bad_file_type" }))
    } else {
      onChange(Object.values(files));
    }
  }

  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            {intl.formatMessage({ id: label })}
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover} />
          </div>
        )}
      </div>
      <Input
        id={id}
        className={`mb-2 ${!!error ? "epow-input-error" : ""}${locked ? "d-none" : ""}`}
        type={type}
        file={files}
        multiple
        accept={accept.join(", ")}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          triggerOnChange(e.target.files)
        }}
      />
      {files.map(file =>
        <Input
          key={file.name}
          id={`${id}Name`}
          className="mb-2"
          value={file.name}
          disabled
        />
      )}
      <ErrorField error={error} touched={touched} isFileInput/>
    </FormGroup>
  )
}

export default FormMultipleFileUpload
