import {Purchase} from "../../../../interfaces/PurchaseInterfaces";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import React, {FunctionComponent, useMemo} from "react";
import PurchaseTableRow from "./PurchaseTableRow";
import PurchaseTableRowDetails from "./PurchaseTableRowDetails";
import {CustomTableColumn, CustomTableType} from "../../../../interfaces/TableInterfaces";
import CustomTable, {CustomTableRef} from "../CustomTable";

interface PurchaseTableProps {
  className?: string,
  purchases: Purchase[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void
}

const PurchaseTable: FunctionComponent<PurchaseTableProps> = ({
  className = "",
  purchases,
  sortOptions,
  onSortOptions
}) => {
  const ref = React.useRef<CustomTableRef>();

  const columns: CustomTableColumn[] = useMemo( () => [
    { title: "purchase_order_code", accessor: "purchaseNumber", sortable: true },
    { title: "purchase_order_date", accessor: "purchaseDate", sortable: true },
    { title: "purchase_order_supplier", accessor: "supplier.name", sortable: true },
    { title: "number_booking_validated", accessor: "numberBookingValidated", sortable: false },
    { title: "number_order_document", accessor: "numberOrderDocument", sortable: false },
    { title: "status", accessor: "status", sortable: false },
    { title: "table_actions", accessor: "actions", sortable: false }
  ], [])

  return (
    <div className={`epow-purchase-table ${className ?? ""}`}>

      <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className}`}
        type={CustomTableType.DETAILS_TABLE}
        datas={purchases}
        columns={columns}
        RowComponent={({data, ...rest}) => {
          const purchase = data as Purchase;
          return (
            <div>
              <PurchaseTableRow purchase={purchase} {...rest} />
              {/*A priori, cette partie n'est plus nécessaire, on préfère la garder au cas où on nous la demande à un moment*/}
              {false && (
                purchase?.bookings && purchase.bookings.map(booking => (
                  <div key={booking?.id}>
                    <PurchaseTableRowDetails purchaseBooking={booking}/>
                  </div>
                ))
              )}
            </div>);
        }}
      />
    </div>
  )
}

export default PurchaseTable
