import {Table} from "../interfaces/TableInterfaces";
import {Column} from "react-table";


function getColumns<T>(table: Table<T>): Column[] {
  const tableArray = []
  Object.values(table).forEach((column) => {
    tableArray.push(column)
  })
  return tableArray
}


export const tableUtils = Object.freeze({
  getColumns
})
