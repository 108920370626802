import * as Yup from "yup";
import {
  getRequiredBooleanForCondition,
  getRequiredPositiveNumberForCondition,
  getRequiredStringForCondition,
  yupRequiredString
} from "../yup/Validators";

export const CreateInvoiceSchema = Yup.object().shape({
  billingCustomerId: yupRequiredString,
  date: yupRequiredString,
})

export const CreateInvoiceBillingItemSchema = Yup.object().shape({
  orderItemId: getRequiredStringForCondition(["disbursement", "quantity", "unitPriceHT", "name"], (disbursement, quantity, unitPriceHT, name) => !disbursement && !quantity && !unitPriceHT && !name),
  name: getRequiredStringForCondition("orderItemId", (orderItemId) => !orderItemId),
  quantity: getRequiredPositiveNumberForCondition("orderItemId", (orderItemId) => !orderItemId),
  unitPriceHT: getRequiredPositiveNumberForCondition("orderItemId", (orderItemId) => !orderItemId),
  disbursement: getRequiredBooleanForCondition("orderItemId", (orderItemId) => !orderItemId),
}, [
  ["orderItemId", "name"],
  ["orderItemId", "quantity"],
  ["orderItemId", "unitPriceHT"],
  ["orderItemId", "disbursement"]
])
