import React, { FunctionComponent } from "react";
import {Col} from "reactstrap";
import {LOGIN_IMAGE} from "../constants/Assets";
import {RouteProps} from "react-router-dom";


const LoginLayout: FunctionComponent<RouteProps> = ({children}) =>
  (<div className="epow-login d-flex flex-row justify-content-center w-100 mt-5 mt-lg-0">

    {children}

    <Col className="d-none d-lg-block">
      <img src={LOGIN_IMAGE} className="vh-100 w-100" alt={"Image login"}/>
    </Col>
  </div>)


export default LoginLayout;
