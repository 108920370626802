import React, {FunctionComponent, useEffect} from "react";
import {useIntl} from "react-intl";
import {Order} from "../../../interfaces/OrderInterfaces";
import {LogoSizeType, TitleSizeType} from "../../../types/bootstrap/BootstrapType";
import FieldLabel from "../../atoms/FieldLabel";
import {dateUtils} from "../../../utils/dateUtils";
import PageTitle from "./PageTitle";
import {ORDERS_PATH} from "../../../constants/routes/RoutePaths";
import {orderUtils} from "../../../utils/orderUtils";
import useLoading from "../../../hooks/useLoading";
import {stringUtils} from "../../../utils/stringUtils";
import Logo from "../../atoms/logo/Logo";
import Button from "../../atoms/Button";
import Title from "../../atoms/Title";
import {PictureType} from "../../../constants/FileConstants";
import {fileService} from "../../../services/FileService";
import {fileUtils} from "../../../utils/fileUtils";
import {orderService} from "../../../services/OrderService";

interface RoadBookHeaderProps {
  className?: string,
  title: string,
  order: Order,
  path?: string
}

const RoadBookHeader: FunctionComponent<RoadBookHeaderProps> =
  ({
    className = "",
    order,
    title = "orders_title",
    path = ORDERS_PATH
  }) => {
  const intl = useIntl()

  const {loading, startLoading, stopLoading} = useLoading()
  const [image, setImage] = React.useState<string>();
  
  const exportTravelBook = () => {
      startLoading();
      fileUtils.downloadFile(orderService.getTravelBook(order.id), {name: "Carnet_Voyage_Cde_" + order.orderNumber, extension: "pdf"}, intl, () => stopLoading())
  }
  const buildBreadcrumb = () => {
    const tourDate = dateUtils.formatDateToLocalDateTime(dateUtils.convertStringToObjectDate(order.beginTourDate), false)
    const orderRef = orderUtils.getOrderRefDisplay(order.orderNumber, order.customerReference, order.customer?.name)
    return `${orderRef} - ${tourDate}`
  }

  const buildTitle = () => {
      return intl.formatMessage({id: "road_book_title"});
  }

  const buildPax = () => {
      return ` PAX : ${(order.defaultPax || "-").toString()}${order.paxConfirmed ? "" : "(TBC)"}`;
  }

    useEffect(() => {
      if (order != undefined) {
        fileService.getPictureById(PictureType.CUSTOMER_LOGO, order.customer.id).then((image) => {
          setImage(URL.createObjectURL(image))
        }).catch(() => {
          // it's fine if there's no image
        })
      }
    }, []);

  return (
    <div className={`order-header ${className}`}>
      <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between mb-5">
        <PageTitle
          pageTitle={buildBreadcrumb()}
          className="me-4"
          breadcrumbItems={[{
            title: intl.formatMessage({id: title}),
            to: path
          }]}
        />
        <Button onClick={exportTravelBook} className="btn-header btn btn-secondary mt-5 mt-sm-0">{intl.formatMessage({id: "road_book_download_pdf"})}{loading && <span className="loader loader-button-group ms-1"/>}</Button>
      </div>
      <div className="text-sm-start text-center mb-2">
        <Title title={buildTitle()} size={TitleSizeType.H5}></Title>
        <Title className="epow-secondary ms-2" title={buildPax()} size={TitleSizeType.H6}></Title>
      </div>
      <div className="d-flex flex-column flex-sm-row">
        <div className="me-2">
          <FieldLabel
            className="me-3"
            label={intl.formatMessage({id: "road_book_from"})}
            value={stringUtils.capitalize(dateUtils.dateToLocaleDate(dateUtils.convertStringToObjectDate(order.beginTourDate)))}
          />
        </div>
        <div className="me-2">
          <FieldLabel
            className="me-3"
            label={intl.formatMessage({id: "road_book_to"})}
            value={stringUtils.capitalize(dateUtils.dateToLocaleDate(dateUtils.getMaxDate(order.items.map(item => new Date(item.rendezVousDate)))))}
          />
        </div>
        <div className="m-auto m-sm-0 ms-sm-auto order-first order-sm-last">
          <Logo imageSource={image} width={LogoSizeType.SM} alt="SOFTOURING" className="mb-3" />
        </div>
      </div>
    </div>
  )
}

export default RoadBookHeader;
