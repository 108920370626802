import React, {FunctionComponent} from "react";
import {Resource} from "../../../../interfaces/ResourceInterfaces";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import IconLabelCard from "../../../atoms/IconLabelCard";
import {useIntl} from "react-intl";
import CalendarOrderItemCard from "../../card/staffing/calendar/CalendarOrderItemCard";

interface AvailableCalendarStaffingPanelBodyProps {
  className?: string,
  date: string,
  availabilityType?: string,
  resource: Resource
  items: OrderItemStaffingPageItemDto[]
  staffings: OrderItemStaffingPageItemDto[]
  staffingsFiltered: OrderItemStaffingPageItemDto[]
  refresh: () => void
}

const AvailableCalendarStaffingPanelBody: FunctionComponent<AvailableCalendarStaffingPanelBodyProps> = ({
  className = "",
  date,
  availabilityType,
  resource,
  items,
  staffings,
  staffingsFiltered,
  refresh,
}) => {
  const intl = useIntl();

  const getCalendarLabel = () => {
    const availabilityLabel = availabilityType ? `| ${availabilityType}` : "" 
    return `${date} ${availabilityLabel}`
  }

  return (
    <div className={`w-100 ${className}`}>
      <div className="d-flex gap-2">
        <IconLabelCard iconName="Calendar" label={getCalendarLabel()} hasBackground />
        <IconLabelCard iconName="User" label={`${resource.identity.firstName} ${resource.identity.lastName}`} hasBackground />
      </div>

      {(staffingsFiltered?.length === 0 && staffings?.length === 0 && items?.length === 0) && (
        <div className="mt-2">
          {intl.formatMessage({id: "calendar_day_panel_no_section"})}
        </div>
      )}

      {staffingsFiltered?.length > 0 && (
        <div>
          <div className="d-flex align-items-center w-100">
            <hr className="flex-grow-1" />
            <span className="bold">{intl.formatMessage({id: "calendar_day_panel_section_available_filtered"})}</span>
            <hr className="flex-grow-1" />
          </div>
          {staffingsFiltered.map(staffing => (
            <div key={staffing.id}>
              <CalendarOrderItemCard key={staffing.id} refresh={refresh} resource={resource} orderItem={staffing} isResourceAvailable />
            </div>
          ))}
        </div>
      )}

      {items?.length > 0 && (
        <div>
          <div className="d-flex align-items-center w-100">
            <hr className="flex-grow-1" />
            <span className="bold">{intl.formatMessage({id: "calendar_day_panel_section_staffable"})}</span>
            <hr className="flex-grow-1" />
          </div>
          {items.map(item => (
            <CalendarOrderItemCard key={item.id} refresh={refresh} resource={resource} orderItem={item} />
          ))}
        </div>
      )}

      {staffings?.length > 0 && (
        <div>
          <div className="d-flex align-items-center w-100">
            <hr className="flex-grow-1" />
            <span className="bold">{intl.formatMessage({id: "calendar_day_panel_section_available"})}</span>
            <hr className="flex-grow-1" />
          </div>
          {staffings.map(staffing => (
            <div key={staffing.id}>
              <CalendarOrderItemCard key={staffing.id} refresh={refresh} resource={resource} orderItem={staffing} isResourceAvailable />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default AvailableCalendarStaffingPanelBody;
