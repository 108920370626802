import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {Customer} from "../../../../interfaces/CustomerInterfaces";
import GeneralInfoCustomerCard from "./GeneralInfoCustomerCard";
import MainCustomerContactCard from "./MainCustomerContactCard";
import CommentCard from "../comment/CommentCard";
import ImageCard from "../image/ImageCard";
import {Col} from "reactstrap";
import {Comment, CommentRequest} from "../../../../interfaces/CommentInterface";
import {toastUtils} from "../../../../utils/toastUtils";
import {customerService} from "../../../../services/CustomerService";
import {PictureType} from "../../../../constants/FileConstants";

interface DetailsCustomerCardProps {
  className?: string;
  customer?: Customer;
  setCustomer?: (customer: Customer) => void;
}

const DetailsCustomerCard: FunctionComponent<DetailsCustomerCardProps> = ({
  className = "",
  customer,
  setCustomer,
}) => {
  const intl = useIntl();
  const [comment, setComment] = useState<Comment>(customer.comment);

  const createComment = async (commentRequest: CommentRequest): Promise<void> => {
    return customerService
      .createCustomerComment(customer.id, commentRequest)
      .then((createdComment: Comment) => {
        setComment(createdComment);
        setCustomer({...customer, comment: createdComment});
        toastUtils.successToast(intl.formatMessage({id: "comment_toast_success_creation"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_creation"}));
      });
  };

  const updateComment = async (commentRequest: CommentRequest): Promise<void> => {
    return customerService.updateCustomerComment(customer.id, commentRequest)
      .then((createdComment: Comment) => {
        setComment(createdComment);
        setCustomer({...customer, comment: createdComment});
        toastUtils.successToast(intl.formatMessage({id: "comment_toast_success_update"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_update"}));
      });
  };

  const deleteComment = () => {
    customerService
      .deleteCommentForCustomer(customer.id)
      .then(() => {
        setComment(null)
        const updatedCustomer = {...customer, comment: null}
        setCustomer(updatedCustomer);
        toastUtils.infoToast(intl.formatMessage({id: "comment_toast_success_deletion"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_deletion"}));
      });
  };

  return (
    <div className={`d-flex flex-row flex-wrap gap-3 ${className}`}>
      <GeneralInfoCustomerCard
        customer={customer}
        setCustomer={setCustomer}
        title={intl.formatMessage({id: "customer_general_info"})}
      />
      <MainCustomerContactCard
        customer={customer}
        setCustomer={setCustomer}
        title={intl.formatMessage({id: "main_contact"})}
      />
      <Col>
        <ImageCard
          className="mb-4"
          title={intl.formatMessage({id: "picture_title"})}
          entityId={customer.id}
          fileType={PictureType.CUSTOMER_LOGO}
        />
        <CommentCard
          comment={comment}
          createComment={createComment}
          updateComment={updateComment}deleteComment={deleteComment}
          title={intl.formatMessage({id: "customer_comment"})}
        />
      </Col>
    </div>
  );
};

export default DetailsCustomerCard;
