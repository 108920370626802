import React, {FunctionComponent, useState} from "react";
import SupplierServiceCatalogList from "../../list/SupplierServiceCatalogList";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import Panel from "../../panel/Panel";
import {SupplierServiceCatalog} from "../../../../interfaces/SupplierInterfaces";
import CreateSupplierServiceCatalogForm from "../../form/CreateSupplierServiceCatalogForm";

interface SupplierServiceCatalogCardProps {
  className?: string,
  supplierServiceId?: string,
  supplierServiceCatalogs?: SupplierServiceCatalog[],
  onValidate?: () => void
}

const SupplierServiceCatalogCard: FunctionComponent<SupplierServiceCatalogCardProps> = ({
  className = "",
  supplierServiceId,
  supplierServiceCatalogs,
  onValidate = () => null
}) => {
  const [lateralPanelSupplierServiceCatalog, setLateralPanelSupplierServiceCatalog] = useState<boolean>(false)
  const [initialSupplierServiceCatalog, setInitialSupplierServiceCatalog] = useState<SupplierServiceCatalog>(null)

  const handleValidate = () => {
    setLateralPanelSupplierServiceCatalog(false)
    onValidate()
  }

  return (
    <div className={className}>
      <SupplierServiceCatalogList
        supplierServiceCatalogs={supplierServiceCatalogs}
        setLateralPanelSupplierServiceCatalog={setLateralPanelSupplierServiceCatalog}
        setInitialSupplierServiceCatalog={setInitialSupplierServiceCatalog}
        onValidate={onValidate}
      />
      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        className="extended"
        onClick={() => {
          setLateralPanelSupplierServiceCatalog(true)
          setInitialSupplierServiceCatalog(null)
        }}
      >
        <FormattedMessage id="add_button"/>
      </Button>
      {lateralPanelSupplierServiceCatalog && (
        <Panel
          formId="createSupplierServiceCatalogForm"
          title="add_supplier_service_catalog"
          open={lateralPanelSupplierServiceCatalog}
          onCancel={() => setLateralPanelSupplierServiceCatalog(false)}
        >
          <CreateSupplierServiceCatalogForm
            id="createSupplierServiceCatalogForm"
            supplierServiceId={supplierServiceId}
            initialSupplierServiceCatalog={initialSupplierServiceCatalog}
            onValidate={handleValidate}
          />
        </Panel>
      )}
    </div>
  )
};

export default SupplierServiceCatalogCard
