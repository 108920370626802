import {WorkflowActionInterface} from "../../interfaces/workflow/WorkflowInterfaces";
import {ColorType} from "../../types/bootstrap/BootstrapType";
import {StateMap} from "./StateConstants";

export const enum WorkflowStatesEnum {
  CREATED = "CREATED",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const WorkflowStateMap: StateMap = {
  CREATED: {
    id: WorkflowStatesEnum.CREATED,
    label: "workflow_created",
    color: ColorType.SECONDARY,
  },
  ACTIVE: {
    id: WorkflowStatesEnum.ACTIVE,
    label: "workflow_active",
    color: ColorType.PRIMARY,
  },
  INACTIVE: {
    id: WorkflowStatesEnum.INACTIVE,
    label: "workflow_inactive",
    color: ColorType.TERTIARY,
  }
}

export const WorkflowActionsEnum = {
  DELETE: "DELETE",
  ACTIVATE: "ACTIVATE",
  ACTIVATE_SELF: "ACTIVATE_SELF",
  DEACTIVATE: "DEACTIVATE",
  REACTIVATE: "REACTIVATE"
} as const

export type WorkflowActionsType = typeof WorkflowActionsEnum[keyof typeof WorkflowActionsEnum]


export const WorkflowActions: Record<WorkflowActionsType, WorkflowActionInterface> = {
  [WorkflowActionsEnum.DELETE]: {
    key: WorkflowActionsEnum.DELETE,
    baseLabelKey: "workflow_delete",
    color: ColorType.SECONDARY,
    allowedStates: [
      WorkflowStatesEnum.CREATED
    ]
  },
  [WorkflowActionsEnum.ACTIVATE]: {
    key: WorkflowActionsEnum.ACTIVATE,
    baseLabelKey: "workflow_validate",
    color: ColorType.PRIMARY,
    allowedStates: [
      WorkflowStatesEnum.CREATED,
    ]
  },
  [WorkflowActionsEnum.ACTIVATE_SELF]: {
    key: WorkflowActionsEnum.ACTIVATE_SELF,
    baseLabelKey: "workflow_validate_self",
    color: ColorType.PRIMARY,
    allowedStates: [
      WorkflowStatesEnum.CREATED,
    ]
  },
  [WorkflowActionsEnum.DEACTIVATE]: {
    key: WorkflowActionsEnum.DEACTIVATE,
    baseLabelKey: "workflow_deactivate",
    color: ColorType.SECONDARY,
    allowedStates: [
      WorkflowStatesEnum.ACTIVE
    ]
  },
  [WorkflowActionsEnum.REACTIVATE]: {
    key: WorkflowActionsEnum.REACTIVATE,
    baseLabelKey: "workflow_reactivate",
    color: ColorType.PRIMARY,
    allowedStates: [
      WorkflowStatesEnum.INACTIVE
    ]
  }
}
