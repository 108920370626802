import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ColorType, SizeType} from "../../../../../types/bootstrap/BootstrapType";
import Button from "../../../../atoms/Button";
import Panel from "../../../panel/Panel";
import SingleStaffingForm from "../../../form/staffing/SingleStaffingForm";

interface StaffingTableStaffingSelectorButtonProps {
  className?: string,
  orderItemId: string,
  maxResources: number,
  onStaffing: () => void,
  hasStaffing?: boolean,
  initialResourceTypes?: string[];
}

const StaffingTableStaffingSelectorButton: FunctionComponent<StaffingTableStaffingSelectorButtonProps> = ({
  className = "",
  orderItemId,
  maxResources,
  onStaffing,
  hasStaffing = false,
  initialResourceTypes
}) => {
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [formOrderItemId, setFormOrderItemId] = useState<string>(null)

  const openForm = (orderItemId) => {
    setFormOpen(true)
    setFormOrderItemId(orderItemId)
  }

  return (
    <div className={className}>

      <Button onClick={() => openForm(orderItemId)} size={SizeType.XS} color={ColorType.SECONDARY}>
        <FormattedMessage id="staffing_staff" />
      </Button>

      {formOpen && (
        <Panel
          title="staffing_title"
          open={formOpen}
          onCancel={() => setFormOpen(false)}
          displayButton={false}
        >
          <SingleStaffingForm
            className="w-100"
            hasStaffing={hasStaffing}
            onStaffing={onStaffing}
            maxResources={maxResources}
            orderItemId={formOrderItemId}
            setFormOpen={setFormOpen}
            initialResourceTypes={initialResourceTypes}
          />
        </Panel>
      )}
    </div>
  )
}

export default StaffingTableStaffingSelectorButton;
