import React, {FunctionComponent} from "react";
import {FormGroup, Label} from "reactstrap";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import AsyncAutocompleteMultiselect, {AsyncAutocompleteMultiselectProps} from "../filters/AsyncAutocompleteMultiselect";
import {FormikErrors, FormikTouched} from "formik";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";

interface FormAutocompleteMultiselectProps extends AsyncAutocompleteMultiselectProps {
  error?: string | string[] | FormikErrors<Option<string>>[],
  touched?: FormikTouched<Option<string>>[],
  label?: string,
  required?: boolean,
  popover?: string,
  closeMenuOnSelect?: boolean,
}

const FormAutocompleteMultiselect: FunctionComponent<FormAutocompleteMultiselectProps> = ({
  className = "",
  id,
  error,
  touched,
  placeholder,
  values,
  onChange,
  fetchData,
  filterFieldName,
  label,
  required,
  popover,
  closeMenuOnSelect = true,
}) => {
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            {label}
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover} />
          </div>
        )}
      </div>
      <AsyncAutocompleteMultiselect
        id={id}
        className={`${!!error ? "epow-select-error" : ""}`}
        values={values}
        onChange={onChange}
        placeholder={placeholder ? placeholder : ""}
        fetchData={fetchData}
        filterFieldName={filterFieldName}
        closeMenuOnSelect={closeMenuOnSelect}
      />
      <ErrorField touched={touched} error={error} />
    </FormGroup>
  )
}

export default FormAutocompleteMultiselect;
