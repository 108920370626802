import React from "react";
import Icon from "../../../icon/Icon";
import useOuterClick from "../../../../hooks/useOuterClick";
import {ColorOption} from "../../../../interfaces/EditorInterfaces";

interface ColorDropdownProps {
  onChange: (selected: number) => void,
  selected: number,
  items: ColorOption[],
  title: string,
}

export function ColorDropdown({items, onChange, ...inputProps}: ColorDropdownProps) {
  const {ref, isComponentVisible, setIsComponentVisible} = useOuterClick(false);

  const preventDefault = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsComponentVisible(true)
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>, index) => {
    e.stopPropagation()
    e.preventDefault()
    onChange(index)
    setIsComponentVisible(false)
  }

  return (
    <div ref={ref} {...inputProps} className="color-picker-wrapper">
      <div className="color-picker-select" onMouseDown={preventDefault}>
        <Icon name="Droplet" />
      </div>

      {isComponentVisible && (
        <div className="rsw-dd color-picker">
          <div hidden>{inputProps.title}</div>
          {items.map((item, index) => (
            <div className="color-picker-option" key={item.colorName} onMouseDown={(e) => handleClick(e, index)}>
              <div className={"square"} style={{backgroundColor: item.colorValue}} />
            </div>
          ))}
        </div>
      )}

    </div>

  );
}


export default ColorDropdown;
