import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Label} from "reactstrap";
import AutocompleteFilterPageable, {AutocompleteFilterPageableProps} from "../../filters/AutocompleteFilterPageable";

interface OrderImportCardSelectProps extends AutocompleteFilterPageableProps {
  id?: string,
  error: string,
  placeholder?: string,
}

const OrderImportCardSelect: FunctionComponent<OrderImportCardSelectProps> = ({
  className = "",
  id,
  error,
  value,
  placeholder,
  onChange,
  fetchData,
  filterFieldName,
  filterFieldSearch,
}) => {
  const intl = useIntl()

  return (
    <div className={`${className}`}>
      <AutocompleteFilterPageable
        id={id}
        className={`${!!error ? "epow-select-error" : ""}`}
        onChange={onChange}
        value={value}
        placeholder={placeholder ? intl.formatMessage({id: placeholder}) : ""}
        fetchData={fetchData}
        filterFieldName={filterFieldName}
        filterFieldSearch={filterFieldSearch}
      />

      <div className="epow-error">
        {error && <Label><FormattedMessage id={error} /></Label>}
      </div>
    </div>
  )
}

export default OrderImportCardSelect;
