import {ORDER_ITEM_TYPE} from "../interfaces/OrderItemInterfaces";
import {QuoteItemRequest} from "../interfaces/QuoteItemInterfaces";

export const QUOTE_ITEM_INITIAL_VALUES: QuoteItemRequest = {
  disbursements: false,
  paxPrice: 0,
  quantity: 0,
  unitType: "",
  id: "",
  name: "",
  rendezVousDate: "",
  rendezVousHour: "",
  description: "",
  duration: 0,
  type: ORDER_ITEM_TYPE.ACTIVITY,
  pax: 0,
  optional: false,
  paxConfirmed: false,
  rendezVousPlace: "",
  sellPriceTTC: 0,
  sellPriceTVA: 0,
  sellPrice: 0,
}
