import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Quote, QuoteConvertRequest, QuoteState, QuoteStateMap} from "../../../interfaces/QuoteInterfaces";
import {quoteService} from "../../../services/QuoteService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../utils/toastUtils";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import FieldLabel from "../../atoms/FieldLabel";
import CreateQuoteForm from "../form/CreateQuoteForm";
import Panel from "../panel/Panel";
import {dateUtils} from "../../../utils/dateUtils";
import {QuoteItem} from "../../../interfaces/QuoteItemInterfaces";
import PageTitle from "./PageTitle";
import {ORDERS_PATH, QUOTES_PATH} from "../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import GenericModal from "../modal/GenericModal";
import useLoading from "../../../hooks/useLoading";
import GenericModalSendQuote from "../modal/GenericModalSendQuote";
import {fileUtils} from "../../../utils/fileUtils";
import GenericModalConvertQuote from "../modal/GenericModalConvertQuote";
import {optionUtils} from "../../../utils/optionUtils";
import {CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS} from "../../../constants/CustomerConstants";
import QuoteActionsDropdown from "../input/quote/QuoteActionsDropdown";

interface QuoteHeaderProps {
    className?: string,
    quote: Quote,
    onChangeQuote: (quote: Quote) => void
    onChangeQuoteItems: (quoteItems: QuoteItem[]) => void,
    onDelete: () => void,
}

const QuoteHeader: FunctionComponent<QuoteHeaderProps> = ({
    className = "",
    quote,
    onChangeQuote,
    onChangeQuoteItems,
}) => {
    const intl = useIntl()
    const navigate = useNavigate()

    const {loading, startLoading, stopLoading} = useLoading();

    const [lateralPanelQuoteOpen, setlateralPanelQuoteOpen] = useState<boolean>(false)
    const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false)
    const [modalSendOpen, setModalSendOpen] = useState<boolean>(false)
    const [modalConvertOpen, setModalConvertOpen] = useState<boolean>(false)

    const deleteQuote = () => {
        quoteService.deleteQuote(quote?.id)
            .then(() => {
                toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_generic"}))
                navigate(`${QUOTES_PATH}`)
            })
            .catch(() => {
                toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_update"}))
            }).finally(() => {
                setModalDeleteOpen(false)
            })
    }

    const handleQuoteUpdate = (quote: Quote) => {
        setlateralPanelQuoteOpen(false)
        onChangeQuote(quote)
        onChangeQuoteItems(quote.quoteItems)
    }

    const onChangeStatusTo = (toStatut: string, formData?: FormData) => {
        let usedFormData: FormData = formData;
        if (!usedFormData) {
            usedFormData = new FormData()
            usedFormData.append("request", new Blob([JSON.stringify({})], {type: "application/json"}))
        }
        quoteService.transitionTo(quote.id, toStatut, usedFormData).then((quote) => {
            onChangeQuote(quote)
            setModalSendOpen(false)
        }).catch(() => {
            toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_update"}))
        })
    }

    const sendQuote = (template: string, attachments?: File[]) => {
        startLoading()
        const formData = new FormData();
        attachments?.forEach((file: File) => {
            formData.append("attachments", file);
        })
        formData.append("request", new Blob([JSON.stringify({template})], {type: "application/json"}))
        onChangeStatusTo(QuoteState.SENT, formData)
        stopLoading()
    }

    const convertQuote = (quoteConvertRequest: QuoteConvertRequest) => {
        startLoading()
        quoteService.transitionToOrder(quote.id, quoteConvertRequest).then((quote) => {
            onChangeQuote(quote)
            setModalConvertOpen(false)
            navigate(`${ORDERS_PATH}/${quote.orderId}`);
        }).catch(() => {
            toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_update"}))
        }).finally(() => {
            stopLoading()
        })
    }

    return (
        <div className={`quote-header ${className}`}>

            <div className="d-flex align-items-center">
                <PageTitle
                    pageTitle={quote.quoteNumber}
                    className="me-4"
                    breadcrumbItems={[{
                        title: intl.formatMessage({id: "quotes_title"}),
                        to: QUOTES_PATH
                    }]}
                />

                <div>
                    <span className="me-1 text-muted"><FormattedMessage id="quote_status" /> :</span>
                    <Badge pill color={QuoteStateMap[quote.status]?.color}>
                        {intl.formatMessage({id: QuoteStateMap[quote.status]?.label})}
                    </Badge>
                </div>

              <div className="ms-auto d-flex gap-2">
                <QuoteActionsDropdown quote={quote} />
                {quote.status === QuoteState.DRAFT &&
                        <>
                            <Button
                                color={ColorType.DANGER}
                                className="extended"
                                onClick={() => setModalDeleteOpen(true)}
                            >
                                <FormattedMessage id="delete_button" />
                            </Button>

                            <Button
                                color={ColorType.SECONDARY}
                                className="ms-2 extended"
                                onClick={() => setlateralPanelQuoteOpen(true)}
                            >
                                <FormattedMessage id="edit_button" />
                            </Button>
                        </>
                    }

                    <>
                        <Button className="ms-2 extended"
                            color={ColorType.TERTIARY}
                            onClick={() => {
                                quoteService.duplicateQuote(quote.id).then((newQuote) => {
                                    toastUtils.successToast(intl.formatMessage({id: "success_toast_duplicate_quote"}))
                                    onChangeQuote(newQuote)
                                }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_duplicate_quote"})))
                            }}
                        >
                            <FormattedMessage id="quote_duplicate" />
                        </Button>
                        <Button loading={loading} className="ms-2 extended" onClick={() => setModalSendOpen(true)}>
                            <FormattedMessage id="quote_to_sent" />
                        </Button>
                    </>


                    {quote.status !== QuoteState.DRAFT &&
                        <Button className="ms-2 extended"
                            color={ColorType.SECONDARY}
                            onClick={() => fileUtils.downloadFile(quoteService.download(quote.id), {
                                name: quote.quoteNumber,
                                extension: "pdf"
                            }, intl)}>
                            <FormattedMessage id="quote_download" />
                        </Button>
                    }

                    {quote.status === QuoteState.SENT &&
                        <>
                            <Button
                                color={ColorType.SECONDARY}
                                className="ms-2 extended"
                                onClick={() => onChangeStatusTo(QuoteState.LOOSE)}
                            >
                                <FormattedMessage id="quote_to_loose" />
                            </Button>
                            <Button loading={loading} className="ms-2 extended" onClick={() => setModalConvertOpen(true)}>
                                <FormattedMessage id="quote_to_order" />
                            </Button>
                        </>
                    }

                    {quote.status === QuoteState.LOOSE &&
                        <Button className="ms-2 extended" onClick={() => onChangeStatusTo(QuoteState.DRAFT)}>
                            <FormattedMessage id="quote_to_draft" />
                        </Button>
                    }

                </div>

            </div>

            <div className="d-flex">
                <div className="me-2">
                    <FieldLabel
                        className="me-3"
                        label={intl.formatMessage({id: "quote_creation_date"})}
                        value={dateUtils.formatDateToLocalDateTime(dateUtils.convertStringToObjectDate(quote.creationDate), false)}
                    />
                </div>

                <div className="me-2">
                    <FieldLabel
                        className="me-3"
                        label={intl.formatMessage({id: "quote_demand_date"})}
                        value={dateUtils.formatDateToLocalDateTime(dateUtils.convertStringToObjectDate(quote.quoteDate), false)}
                    />
                </div>

                <div>
                    <FieldLabel
                        className="me-3"
                        label={intl.formatMessage({id: "quote_date_end_of_validity"})}
                        value={dateUtils.formatDateToLocalDateTime(dateUtils.convertStringToObjectDate(quote.endOfValidityDate), false)}
                    />
                </div>
                <div>
                    <FieldLabel
                        label={intl.formatMessage({id: "customer_geographical_zone_field"})}
                        value={optionUtils.translateOption(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS, quote?.geographicalZone)}
                    />
                </div>
            </div>

            {lateralPanelQuoteOpen && (
                <Panel formId="editQuoteForm" title="quote_edit" open={lateralPanelQuoteOpen}
                    onCancel={() => setlateralPanelQuoteOpen(false)}>
                    <CreateQuoteForm
                        id="editQuoteForm"
                        quote={quote}
                        startLoading={startLoading}
                        onValidate={handleQuoteUpdate}
                        stopLoading={stopLoading}
                    />
                </Panel>
            )}

            <GenericModalSendQuote
                quote={quote}
                onClose={() => setModalSendOpen(false)}
                open={modalSendOpen}
                sendQuote={sendQuote}
                onOpen={() => setModalSendOpen(true)}
            />

            <GenericModalConvertQuote
                quote={quote}
                onClose={() => setModalConvertOpen(false)}
                open={modalConvertOpen}
                convertQuote={convertQuote}
                onOpen={() => setModalConvertOpen(true)}
            />

            <GenericModal
                title={intl.formatMessage({id: "modal_body_delete_quote"})}
                open={modalDeleteOpen}
                closable
                onOpen={setModalDeleteOpen}
                footer={{
                    primaryAction: {
                        buttonLabel: intl.formatMessage({id: "validate_button"}),
                        action: () => {
                            deleteQuote()
                        },
                    },
                    secondaryAction: {
                        buttonLabel: intl.formatMessage({id: "cancel_button"}),
                        action: () => {
                            setModalDeleteOpen(false)
                        }
                    }
                }}
            >
                <div className="d-flex flex-column">
                    {intl.formatMessage({id: "modal_body_delete_quote"})}
                </div>
            </GenericModal>
        </div>
    )
}

export default QuoteHeader;
