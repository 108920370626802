import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import {toastUtils} from "utils/toastUtils";
import {Resource} from "interfaces/ResourceInterfaces";
import {resourceService} from "services/ResourceService";
import {useIntl} from "react-intl";
import {WorkflowActionsEnum} from "../constants/workflow/WorkflowStates";
import useResourceWorkflow from "../hooks/resource/useResourceWorkflow";
import ResourceViewTemplate from "./ResourceViewTemplate";
import {bankAccountService} from "../services/BankAccountService";


const ResourceView: FunctionComponent<RouteProps> = () => {
  const {id} = useParams();
  const intl = useIntl()

  const [resource, setResource] = useState<Resource>({})

  const {
    handleDeleteButton,
    handleReactivateButton,
    handleDeactivateButton,
    handleActivateButton
  } = useResourceWorkflow(resource, setResource)

  useEffect(() => {
    resourceService.getResource(id)
      .then(setResource)
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_resource"}))
      })
  }, [id])

  const fetchBankAccount = () => bankAccountService
    .getResourceBankAccounts(resource.id);

  return <ResourceViewTemplate
    onChangeResource={setResource}
    resource={resource}
    fetchBankAccount={fetchBankAccount}
    workflowActions={{
      [WorkflowActionsEnum.DELETE]: handleDeleteButton,
      [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
      [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
      [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton
    }}/>
}

export default ResourceView
