import Title from "components/atoms/Title";
import {Resource, ResourceContactInfo} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent} from "react"
import {Col} from "reactstrap";
import {contactService} from "services/ContactService";
import ContactCard from "../ContactCard";
import {resourceService} from "../../../../services/ResourceService";
import {toastUtils} from "../../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {getBoolean} from "../../../../utils/booleanUtils";

interface MainResourceContactCardProps {
  className?: string;
  resourceId: string,
  resourceContactInfo?: ResourceContactInfo;
  onChangeResource?: (resource: Resource) => void;
  title?: string;
}

const MainResourceContactCard: FunctionComponent<MainResourceContactCardProps> = ({
  className = "",
  resourceContactInfo,
  resourceId,
  onChangeResource,
  title
}) => {

  const intl = useIntl();

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title title={title} size="h5"/>
      </div>
      {resourceContactInfo && (
        <ContactCard
          buttonCallback={() => resourceService.invite(resourceId)
            .then((resource) => {
              onChangeResource(resource)
              toastUtils.successToast(intl.formatMessage({id: "contact_invited"}))
            })
            .catch(() => {
              toastUtils.errorToast(intl.formatMessage({id: "error_inviting_contact"}))
            })
        }
          firstName={resourceContactInfo?.firstName}
          lastName={resourceContactInfo?.lastName}
          hasAccount={getBoolean(resourceContactInfo?.hasAccessPlatform) || resourceContactInfo?.isUserActive}
          lineContact={contactService.buildLineContact({
            email: resourceContactInfo.email,
            phoneNumber: resourceContactInfo.phoneNumber
          })}
        />
      )}
    </Col>
  )
}

export default MainResourceContactCard;
