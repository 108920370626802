import React, { FunctionComponent } from "react"
import { Link } from "react-router-dom"

interface LogoProps {
  path?: string;
  imageSource: string;
  className?: string;
  alt?: string;
  width?: number;
  height?: number;
  isExternal?: boolean;
}

const Logo: FunctionComponent<LogoProps> = ({
  path,
  imageSource,
  className = "",
  alt,
  width,
  height,
  isExternal = false
}) => {
  return path ? (
    isExternal ? (
      <a href={path} target="_blank" rel="noopener noreferrer">
        <img src={imageSource} height={height || "auto"} width={width || "auto"} alt={alt} className={className} />
      </a>
    ) : (
      <Link to={path}>
        <img src={imageSource} height={height || "auto"} width={width || "auto"} alt={alt} className={className} />
      </Link>
    )
  ) : (
    <img src={imageSource} height={height || "auto"} width={width || "auto"} alt={alt} className={className} />
  )
}

export default Logo
