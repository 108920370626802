import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {FormGroup, Label} from "reactstrap";
import Popover from "../../atoms/Popover";
import Radio from "../../atoms/input/Radio";
import {RouteProps} from "react-router-dom";

export interface FormRadioLightProps  extends RouteProps {
  id?: string,
  className?: string,
  label?: string,
  value?: Option<string>,
  options: Option<string>[],
  onChange: (selected: Option<string>) => void,
  required?: boolean,
  popover?: string,
  bold?: boolean,
  readonly?: boolean
}

const FormRadioLight: FunctionComponent<FormRadioLightProps> = ({
  id,
  className = "",
  label,
  value,
  onChange,
  options,
  required,
  popover,
  bold,
  children
}) => {
  const intl = useIntl()

  const isChecked = (index: number) => { return value && options.indexOf(value) === index; }

  const handleChange = (index: number) => {
      onChange(options[index]);
  };

  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id} className={bold ? "bold" : ""}>
            {intl.formatMessage({id: label})}
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>
      <div className="epow-radio-list m-2">
        {options.map((option, index: number) => (
          <Radio
            key={index}
            id={`${id}-${option.label}`}
            value={option.value}
            onChange={() => handleChange(index)}
            checked={isChecked(index)}
          >
            <Label className="epow-radio-label" htmlFor={`${id}-${option.label}`}>
              {intl.formatMessage({id: option.label})}
            </Label>
          </Radio>
        ))}
      </div>
      {children}
    </FormGroup>
  )
}

export default FormRadioLight
