import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {FormGroup, Label} from "reactstrap";
import Popover from "../../atoms/Popover";
import Select from "../../atoms/input/Select";
import ErrorField from "./ErrorField";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import {SelectProps} from "../../../interfaces/inputs/SelectInterfaces";

interface FormSelectProps extends SelectProps {
  id?: string,
  label?: string,
  placeholder?: string,
  required?: boolean,
  error?: FormikError
  touched?: FormikTouchedType
  popover?: string
}

const FormSelect: FunctionComponent<FormSelectProps> = ({
  id,
  className = "",
  label,
  value,
  placeholder,
  onChange,
  options,
  required,
  isSearchable = false,
  isClearable = false,
  isDisabled = false,
  error,
  touched,
  popover
}) => {
  const intl = useIntl()
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            <FormattedMessage id={label}/>
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>
      <Select
        id={id}
        className={`${!!error && !!touched ? "epow-select-error" : ""}`}
        value={value}
        placeholder={placeholder ? intl.formatMessage({id: placeholder}) : ""}
        onChange={onChange}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
      />
      <ErrorField error={error} touched={touched}/>
    </FormGroup>
  )
}

export default FormSelect
