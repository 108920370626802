import {
  CustomerCatalog,
  Tour,
  TourActivity,
  TourActivityCustomer,
  TourTable,
  TourSearchObject
} from "../interfaces/TourInterfaces";
import {httpService} from "./HttpService";
import {
  API_ACTIVITY_IN_TOUR_CUSTOMER, API_SEASONS_PATH,
  API_TOUR_ACTIVITIES_PATH,
  API_TOUR_CUSTOMER_ACTIVITIES_PATH,
  API_TOURS_PATH
} from "../constants/routes/RoutePaths";
import {queryUtils} from "../utils/queryUtils";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";

function getToursPage (params: PaginationQueryParams, filters: TourSearchObject): Promise<PageResponse<TourTable>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<TourTable>>(`${API_TOURS_PATH}/page${queryParams}`)
}

function getTour (id: string): Promise<Tour> {
  return httpService.get<Tour>(`${API_TOURS_PATH}/${id}`)
}

function createTour (tourRequest: Tour): Promise<Tour> {
  return httpService.post<Tour>(API_TOURS_PATH, tourRequest)
}

function updateTour (id: string, tourRequest: Tour): Promise<Tour> {
  return httpService.put<Tour>(`${API_TOURS_PATH}/${id}`, tourRequest)
}

function createTourActivity (tourActivity?: TourActivity): Promise<TourActivity> {
  return httpService.post<TourActivity>(API_TOUR_ACTIVITIES_PATH, tourActivity)
}

function getActivitiesForCustomersByTourBySeason (tourId: string, seasonId: string): Promise<CustomerCatalog[]> {
  return httpService.get<CustomerCatalog[]>(`${API_TOURS_PATH}/${tourId}${API_SEASONS_PATH}/${seasonId}${API_ACTIVITY_IN_TOUR_CUSTOMER}`)
}

function updateTourActivity (id: string, tourActivity: TourActivity): Promise<TourActivity> {
  return httpService.put<TourActivity>(`${API_TOUR_ACTIVITIES_PATH}/${id}`, tourActivity)
}

function updateTourActivityCustomer (id: string, tourActivityCustomer?: TourActivityCustomer): Promise<TourActivityCustomer> {
  return httpService.put<TourActivityCustomer>(`${API_TOUR_CUSTOMER_ACTIVITIES_PATH}/${id}`, tourActivityCustomer)
}

function deleteTour (id: string): Promise<Response> {
  return httpService.delete(`${API_TOURS_PATH}/${id}`)
}

function deleteTourActivity (id: string): Promise<Response> {
  return httpService.delete(`${API_TOUR_ACTIVITIES_PATH}/${id}`)
}

function deleteTourActivityCustomer (id: string): Promise<Response> {
  return httpService.delete(`${API_TOUR_CUSTOMER_ACTIVITIES_PATH}/${id}`)
}

export const tourService = {
  getToursPage,
  getTour,
  createTour,
  updateTour,
  createTourActivity,
  getActivitiesForCustomersByTourBySeason,
  updateTourActivity,
  updateTourActivityCustomer,
  deleteTour,
  deleteTourActivity,
  deleteTourActivityCustomer
}
