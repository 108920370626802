import {Contact, ContactFields} from "../interfaces/ContactInterfaces";
import {ResourceDisplayOption} from "../constants/ResourceDisplayOptionConstant";

function buildContact(contactFields: ContactFields): Contact {
  return {
    hasAccessToPlatform: contactFields.hasAccessToPlatform,
    identity: {
      firstName: contactFields.firstName,
      lastName: contactFields.lastName,
      job: contactFields.job,
      email: contactFields.email,
      phoneNumber: contactFields.phoneNumber,
      otherPhoneNumber: contactFields.otherPhoneNumber,
    },
    resourceDisplayOption: contactFields.resourceDisplayOption
  };
}

function buildContactFields(contact: Contact): ContactFields {
  return {
    lastName: contact?.identity?.lastName,
    firstName: contact?.identity?.firstName,
    job: contact?.identity?.job,
    phoneNumber: contact?.identity?.phoneNumber,
    otherPhoneNumber: contact?.identity?.otherPhoneNumber,
    email: contact?.identity?.email,
    hasAccessToPlatform: contact?.hasAccessToPlatform ? "true" : "false",
    resourceDisplayOption: contact?.resourceDisplayOption ?? ResourceDisplayOption.DISPLAY_NAME
  };
}

export const contactUtils = {
  buildContact,
  buildContactFields,
};
