import { capitalize, Dictionary, groupBy } from "lodash";
import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Label } from "reactstrap";
import { Quote, QuoteState } from "../../../../interfaces/QuoteInterfaces";
import { QuoteItem } from "../../../../interfaces/QuoteItemInterfaces";
import { ColorType, SizeType } from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Icon from "../../../icon/Icon";
import { dateUtils } from "../../../../utils/dateUtils";
import FieldLabel from "../../../atoms/FieldLabel";
import { profileUtils } from "../../../../utils/profileUtils";
import QuoteItemPreviewCard from "../../table/quote/QuoteItemPreviewCard";
import Panel from "../../panel/Panel";
import CreateQuoteItemForm from "../../form/quote/CreateQuoteItemForm";
import { Link } from "react-router-dom";
import { ORDERS_PATH } from "../../../../constants/routes/RoutePaths";

interface QuoteInfosCardProps {
  className?: string,
  quote: Quote,
  quoteItems: QuoteItem[],
  refeshQuoteItems: () => void
}

const QuoteInfosCard: FunctionComponent<QuoteInfosCardProps> = ({
  className = "",
  quote,
  quoteItems,
  refeshQuoteItems,
}) => {

  const intl = useIntl()
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [editedQuoteItem, setEditedQuoteItem] = useState<QuoteItem>(null)
  const quotesByDate: Dictionary<QuoteItem[]> = groupBy(quoteItems, "rendezVousDate")

  const buildDate = (date: string) => {
    const localized = dateUtils.dateToLocaleDate(dateUtils.parseDate(date))
    return capitalize(localized)
  }

  const editQuoteItem = (quoteItem: QuoteItem) => {
    setEditedQuoteItem(quoteItem)
    setFormOpen(true)
  }

  const handleQuoteItemChange = () => {
    refeshQuoteItems()
    setEditedQuoteItem(null)
  }

  const handleFormvalidation = () => {
    setFormOpen(false)
    refeshQuoteItems()
    setEditedQuoteItem(null)
  }

  const displayQuoteItems = () => {
    const sortedQuotesByDate = Object.entries(quotesByDate) ?? [];
    sortedQuotesByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)

    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-baseline ">
            <FieldLabel valueBold className="me-3" label={intl.formatMessage({ id: "quote_customer" })} value={quote.customerName ?? "-"} />
            <FieldLabel valueBold className="me-3" label={intl.formatMessage({ id: "quote_reference_client" })} value={quote.customerRef ?? "-"} />
            <FieldLabel
              valueBold
              className="me-3"
              label={intl.formatMessage({ id: "quote_day_zero" })}
              value={dateUtils.formatDateToLocalDateTime(dateUtils.convertStringToObjectDate(quote.beginTourDate), false)}
            />
            <FieldLabel valueBold className="me-3" label={intl.formatMessage({ id: "tour_code" })} value={quote.tourCode ?? "-"} />
            {quote?.orderId &&
              <div className="me-2">
                <Link to={`${ORDERS_PATH}/${quote?.orderId}`}><FormattedMessage
                  id="go_to_order" /></Link>
              </div>
            }
            <FieldLabel valueBold className="me-3" label={intl.formatMessage({ id: "tour_number_pax" })} value={quote.pax ?? "-"} />
          </div>
          {profileUtils.isInterne() &&
            <div>
              {quote.status === QuoteState.DRAFT && (
                <Button size={SizeType.XS} color={ColorType.SECONDARY} onClick={() => editQuoteItem(null)}>
                  <FormattedMessage id="quote_add_quote_item" />
                  <Icon className="ms-2" name="Plus" />
                </Button>
              )}
            </div>
          }
        </div>

        <div className="ms-3">
          {sortedQuotesByDate?.map(([date, quoteitemsByDate]) => {
            quoteitemsByDate.sort((a, b) => a.rendezVousHour < b.rendezVousHour ? -1 : 1)
            return (
              <div key={date} className="mt-3">
                <div className="d-flex align-items-center">
                  <span className="bold me-3">{buildDate(date)}</span>
                  <hr className="flex-grow-1" />
                </div>
                {quoteitemsByDate?.map(quoteItem => (
                  <QuoteItemPreviewCard
                    className="mb-2"
                    key={quoteItem.id}
                    quoteItem={quoteItem}
                    editQuoteItem={editQuoteItem}
                    onQuoteItemChange={handleQuoteItemChange}
                    isEditable={quote.status === QuoteState.DRAFT}
                  />
                ))}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={className}>
      {quoteItems?.length > 0 ?
        displayQuoteItems()
        :
        (
          <div className="d-flex flex-column align-items-center mt-5">
            <Label><FormattedMessage id="quote_no_quote_item" /></Label>
            <Button
              color={ColorType.SECONDARY}
              onClick={() => setFormOpen(true)}
              className="bquote bquote-dark" >
              <FormattedMessage id="quote_add_quote_item" />
              <Icon className="ms-2" name="Plus" />
            </Button>
          </div>
        )}

      {formOpen && (
        <Panel
          title="create_new_quote_item"
          open={formOpen}
          onCancel={() => {
            setFormOpen(false)
            setEditedQuoteItem(null)
          }}
          displayButton={false}
        >
          <CreateQuoteItemForm id="createQuoteItemForm" onCancel={() => setFormOpen(false)} onValidate={handleFormvalidation} quote={quote} quoteItem={editedQuoteItem} />
        </Panel>
      )}
    </div>
  )
}

export default QuoteInfosCard;
