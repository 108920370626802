import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {QuoteItem} from "../../../../interfaces/QuoteItemInterfaces";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import FieldLabel from "../../../atoms/FieldLabel";
import Icon from "../../../icon/Icon";
import {dateUtils} from "../../../../utils/dateUtils";
import GenericModal from "../../modal/GenericModal";
import {quoteItemService} from "../../../../services/QuoteItemService";

interface QuoteItemPreviewCardHeaderProps {
  className?: string,
  quoteItem: QuoteItem,
  isEditable?: boolean,
  orderStatus?: string,
  editQuoteItem: (quoteItem: QuoteItem) => void,
  onDelete: () => void,

}

const QuoteItemPreviewCardHeader: FunctionComponent<QuoteItemPreviewCardHeaderProps> = ({
  className = "",
  quoteItem,
  isEditable = false,
  editQuoteItem,
  onDelete,
}) => {
  const intl = useIntl()
  const [modalDeleteOpen, setModalDeleteOpen] = useState<boolean>(false)

  const deleteQuoteItem = () => {
    quoteItemService.deleteQuoteItem(quoteItem?.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_generic"}))
        onDelete()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_item_update"}))
      }).finally(() => {
        setModalDeleteOpen(false)
      })
  }

  return (
    <div className={`d-flex w-100 border-bottom pb-2 px-2 mb-2 align-items-center ${className}`}>
      <div className="col-10">
        <span className="bold">{quoteItem?.name}</span><span> - {quoteItem?.rendezVousHour}</span>
        {quoteItem?.optional && (
          <span className="ms-2 color-warning">{intl.formatMessage({id: "order_item_optional"})}</span>
        )}
      </div>

      <FieldLabel
        className="col-3"
        label={intl.formatMessage({id: "order_item_duration"})}
        value={quoteItem?.duration ? `${dateUtils.formatDuration(quoteItem?.duration)}` : ""}
      />

      <div className="col-3 d-flex align-items-center">
        <FieldLabel label={intl.formatMessage({id: "order_item_pax"})} value={quoteItem?.pax?.toString()} />
        {quoteItem?.paxConfirmed && (
          <Icon
            name="Check"
            size={IconSizeType.XS}
            className="ms-2"
            color={ColorType.PRIMARY}
          />
        )}
      </div>

      <FieldLabel
        className="col-3"
        label={intl.formatMessage({id: "order_item_type"})}
        value={quoteItem?.type ? intl.formatMessage({id: quoteItem?.type?.toString()}) : ""}
      />

      <div className="col-4">
        <div className="float-end">
          {isEditable && (
            <>
              <Icon name="Pen" size={IconSizeType.XS} className="cursor-pointer px-1 mx-1" onClick={() => editQuoteItem(quoteItem)} />
              <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer px-1 me-1" onClick={() => setModalDeleteOpen(true)} />
            </>
          )}
        </div>
      </div>

      <GenericModal
        title={intl.formatMessage({id: "modal_body_delete_quote_item"})}
        open={modalDeleteOpen}
        closable
        onOpen={setModalDeleteOpen}
        footer={{
          primaryAction: {
            buttonLabel: intl.formatMessage({id: "validate_button"}),
            action: () => deleteQuoteItem(),
          },
          secondaryAction: {
            buttonLabel: intl.formatMessage({id: "cancel_button"}),
            action: () => {
              setModalDeleteOpen(false)
            }
          }
        }}
      >
        <div className="d-flex flex-column">
          {intl.formatMessage({id: "modal_body_delete_quote_item"})}
        </div>
      </GenericModal>
    </div>
  )
}

export default QuoteItemPreviewCardHeader;
