import {STATUS_NOT_FOUND} from "constants/HttpStatusConstants";
import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {toastUtils} from "utils/toastUtils";
import {blackDateService} from "../../../../services/BlackDateService";
import {BlackDate, CreateBlackDate, ReccurenceOption} from "../../../../interfaces/BlackDateInterface";
import {Col, Input, Label, Row} from "reactstrap";
import Select from "../../../atoms/input/Select";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import FormInput from "../../input/FormInput";
import Button from "../../../atoms/Button";
import FormSelect from "../../input/FormSelect";
import {RECURRENCE_OPTIONS} from "../../../../constants/BlackDateConstants";
import {useIntlOptions} from "../../../../hooks/useIntlOptions";
import {Column} from "react-table";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Table from "../../table/Table";
import {stringUtils} from "../../../../utils/stringUtils";

interface SupplierBankAccountsCardProps {
    supplierId?: string;
}


const BlackDateCard: FunctionComponent<SupplierBankAccountsCardProps> = ({supplierId}) => {
    const intl = useIntl();
    const [blackDates, setBlackDates] = useState<BlackDate[]>([]);
    const [year, setYear] = useState<Option<string>>({
        value: new Date().getFullYear().toString(),
        label: new Date().getFullYear().toString(),
    });
    const [oneDate, setOneDate] = useState<string | undefined>(undefined);
    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);
    const [recurrence, setRecurrence] = useState<ReccurenceOption | undefined>(undefined);
    const [selectedNumber, setSelectedNumber] = useState<number>(0);
    const [switchAllValue, setSwitchAllValue] = useState<boolean>(false);


    const getBlackDates = (year = new Date().getFullYear().toString()) => {
        blackDateService
            .findAll({pageSize: 366, page: 0}, {supplierIds: [supplierId], year})
            .then((dates) => {
                setBlackDates(dates.content);
            })
            .catch((error: Response) => {
                if (error.status !== STATUS_NOT_FOUND) {
                    toastUtils.errorToast(
                        intl.formatMessage({id: "error_get_black_date_accounts"})
                    );
                }
            });
    };

    const createBlackDates = (params: CreateBlackDate) => {
        blackDateService.create({supplierId, ...params})
            .then(() => {
                toastUtils.successToast(intl.formatMessage({id: "black_date_created"}))
                getBlackDates(year.value)
            })
            .catch(() => toastUtils.errorToast(intl.formatMessage({id: "black_date_error_create"})))
    }

    const deleteActivities = (blackDateIds: string[]) => {
        blackDateService.delete(blackDateIds)
            .then(() => {
                setBlackDates([...blackDates.filter(blackDate => !blackDateIds.includes(blackDate.id))])
                setSelectedNumber(selectedNumber - blackDateIds.length)
            })
            .catch(() => intl.formatMessage({id: "resource_activities_delete_error"}))
    }

    const deleteSelection = () => {
        deleteActivities(blackDates.filter(blackDate => blackDate.selected).map(blackDate => blackDate.id))
        setSwitchAllValue(false)
    }

    const switchLine = (blackDate: BlackDate) => {
        const currentArray = blackDates;
        const index = currentArray.findIndex(item => item.id === blackDate.id);
        const newSelectedValue = !blackDate.selected;
        currentArray[index] = {...blackDate, selected: newSelectedValue}
        setBlackDates([...currentArray])
        setSelectedNumber(newSelectedValue ? selectedNumber + 1 : selectedNumber - 1)
    }

    const switchAll = (checked: boolean) => {
        setSwitchAllValue(checked);
        const newArray = blackDates.map(blackDate => {
            return {...blackDate, selected: checked}
        });
        setBlackDates([...newArray])
        setSelectedNumber(checked ? blackDates.length : 0)
    }

    const columns: Column[] = [
        {
            accessor: "check",
            width: "10%",
            Header: () => (
                <Input
                    className="me-2"
                    type="checkbox"
                    checked={switchAllValue}
                    onChange={(value) => switchAll(value.target.checked)}
                />
            ),
            Cell: ({row}: { row: { original: BlackDate } }) => {
                return <div className="d-flex align-items-center" onClick={() => switchLine(row.original)}>
                    <Input
                        className="me-2"
                        type="checkbox"
                        checked={row.original.selected}
                        onChange={() => switchLine(row.original)}
                    />
                </div>
            }
        },
        {
            accessor: "name",
            Cell: ({row}: { row: { original: BlackDate } }) => {
                return <div onClick={() => switchLine(row.original)}>
                    <div key={row.original.id}>{stringUtils.capitalize(intl.formatDate(row.original.date, {
                        day: "2-digit",
                        month: "long",
                        weekday: "long",
                        year: "numeric"
                    }))}</div>
                </div>
            },
        },
        {
            accessor: "actions",
            width: "15%",
            Cell: ({row}: { row: { original: BlackDate } }) => {
                return <div className="float-end">
                    <Icon name="Trash" size={IconSizeType.XS} className="pe-2 cursor-pointer"
                          onClick={() => deleteActivities([row.original.id])}/>
                </div>
            },
        }
    ]


    const onChangeYear = (value: Option<string>) => {
        getBlackDates(value.value);
        setYear(value);
    }
    useEffect(() => {
        getBlackDates();
    }, []);

    const getYearsOptions = (): Option<number>[] => {
        const startYear = new Date().getFullYear(); // current year
        const yearsToDisplay = 3
        return Array.from(
            {length: yearsToDisplay},
            (_, index) => ({value: startYear + index, label: `${startYear + index}`})
        );
    }

    const recurrenceOptions = useIntlOptions(intl, RECURRENCE_OPTIONS);
    return (
        <Row>
            <Col>
                <Label className="epow-label" htmlFor="year">
                    <FormattedMessage id="year"/>
                </Label>
                <Row>
                    <Select
                        id="year"
                        value={year}
                        onChange={(value: Option<string>) => onChangeYear(value)}
                        options={getYearsOptions()}
                        className="mb-5 col-md-8"
                    />
                </Row>
                <div className="mb-3">
                    <Label className="epow-label" htmlFor="oneDate">
                        <FormattedMessage id="black_date_one_date"/>
                    </Label>
                    <Row>
                        <FormInput
                            id="oneDate"
                            type="date"
                            value={oneDate}
                            className="col-md-8"
                            onChange={(e) => setOneDate(e.target.value)}
                        />

                        <Button
                            className="col-md-6 mb-3"
                            color="secondary"
                            onClick={() => createBlackDates({startDate: oneDate, year: parseInt(year.value)})}
                        >
                            <FormattedMessage id="add_button"/>
                        </Button>
                    </Row>

                </div>

                <div className="mb-3">
                    <Label className="epow-label" htmlFor="startDate">
                        <FormattedMessage id="invoice_period"/>
                    </Label>
                    <Row>
                        <FormInput
                            id="startDate"
                            type="date"
                            className="col-md-8"
                            value={startDate}
                            placeholder={"filter_from"}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                        <FormInput
                            id="endDate"
                            type="date"
                            className="col-md-8"
                            placeholder={"filter_to"}
                            value={endDate}
                            minValue={startDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />

                        <Button
                            className="mb-3 col-md-6"
                            color="secondary"
                            onClick={() => createBlackDates({startDate, endDate, year: parseInt(year.value)})}
                        >
                            <FormattedMessage id="add_button"/>
                        </Button>
                    </Row>
                </div>

                <div className="mb-3">
                    <Label className="epow-label" htmlFor="recurrence">
                        <FormattedMessage id="black_date_recurrence"/>
                    </Label>
                    <Row>
                        <FormSelect
                            id="recurrence"
                            className="col-lg-8"
                            placeholder="black_date_recurrence"
                            value={recurrenceOptions.find((option) => option?.value === recurrence?.value)}
                            onChange={(option?: ReccurenceOption) => setRecurrence(option)}
                            options={recurrenceOptions}
                        />

                        <Button
                            className="mb-3 col-lg-6"
                            color="secondary"
                            onClick={() => createBlackDates({
                                reccurence: recurrence.type,
                                temporalValues: recurrence.temporalValues,
                                year: parseInt(year.value)
                            })}
                        >
                            <FormattedMessage id="add_button"/>
                        </Button>
                    </Row>

                </div>

            </Col>

            <Col>
                {
                    (blackDates && blackDates.length > 0) && (
                        <>
                            <Table
                                columns={columns}
                                data={blackDates}
                                className="table resource-activities-table mb-2"
                            />
                            {
                                selectedNumber > 0 && (
                                    <div className="d-flex justify-content-end align-items-center">
                                        <div className="me-2 italic">
                                            <FormattedMessage id="autocomplete_more_than_one_selected"
                                                              values={{size: selectedNumber}}/>
                                        </div>
                                        <Button size={SizeType.MD} className="extended" onClick={() => deleteSelection()}
                                                color={ColorType.DANGER}>
                                            <FormattedMessage id="resource_activities_table_delete_selection"/>
                                        </Button>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </Col>
        </Row>)
};

export default BlackDateCard;
