import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {TOUR_ACTIVITY_INITIAL_VALUES} from "../../../constants/TourConstants";
import {CreateTourActivitySchema} from "../../../constants/validation/TourValidationSchemas";
import {Col} from "reactstrap";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {TourActivity} from "../../../interfaces/TourInterfaces";
import {tourService} from "../../../services/TourService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {WorkflowStatesEnum} from "constants/workflow/WorkflowStates";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {activityService} from "../../../services/ActivityService";
import {dateUtils} from "../../../utils/dateUtils";
import EpowForm from "./EpowForm";

interface CreateTourActivityFormProps {
  id?: string,
  className?: string,
  seasonId?: string,
  tourActivityInitial?: TourActivity,
  onValidate?: () => void,
  departureTimeMinuteDelta?: number,
}

const CreateTourActivityForm: FunctionComponent<CreateTourActivityFormProps> = ({
  id,
  className = "",
  seasonId,
  tourActivityInitial,
  onValidate = () => null,
  departureTimeMinuteDelta,
}) => {
  const intl = useIntl()

  const handleSubmitTourActivity = (tourActivity: TourActivity) => {
    const tourActivityRequest: TourActivity = {...tourActivity, seasonId: seasonId}
    if (tourActivityInitial && tourActivity?.id) {
      tourService.updateTourActivity(tourActivity.id, tourActivityRequest)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_tour_activity"}))
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_tour_activity"}))
        })
    } else {
      tourService.createTourActivity(tourActivityRequest)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_tour_activity"}))
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_tour_activity"}))
        })
    }
  }

  const onActivitySelection = (activityId: string, setFieldValue: (field: string, value: string | number) => void) => {
    setFieldValue("activityId", activityId)

    activityService.getActivity(activityId)
      .then(activity => {
        setFieldValue("description", activity.description ?? "")

      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_activity"}))
      })
  }

  const onHourChange = (e: React.ChangeEvent<HTMLInputElement>, values: TourActivity, setFieldValue: (field: string, value: string | number) => void) => {
    if (values?.departureHour.length <= 0) {
      setFieldValue("departureHour", dateUtils.convertSecondsToTime(dateUtils.convertTimeToSeconds(e.target.value) + (departureTimeMinuteDelta * 60)))
    }
  }

  return (
    <Formik initialValues={tourActivityInitial || TOUR_ACTIVITY_INITIAL_VALUES} validationSchema={CreateTourActivitySchema} onSubmit={value => {handleSubmitTourActivity(value)}}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`m-4 ${className}`}>
          <FormAutocompleteSelectPageable
            id="activityId"
            label={intl.formatMessage({id: "tour_activity_field"})}
            value={values?.activityId}
            onChange={value => onActivitySelection(value, setFieldValue)}
            required
            error={errors?.activityId}
            touched={touched?.activityId}
            fetchData={(page, filters) => activityService.getActivitiesPage(page, {...filters, status: WorkflowStatesEnum.ACTIVE})}
            filterFieldName="name"
          />
          <Col xs={13}>
            <FormInput
              id="dayAfterArrival"
              label="tour_activity_day_after_arrival_field"
              type="number"
              minValue={0}
              value={values?.dayAfterArrival}
              onChange={(e) => setFieldValue("dayAfterArrival", e.target.value)}
              required
              error={errors.dayAfterArrival}
              touched={touched.dayAfterArrival}
            />
          </Col>
          <Col xs={11}>
            <FormInput
              id="hour"
              label="tour_activity_hour_field"
              type="time"
              step="60"
              value={dateUtils.getTimeWithoutSeconds(values?.hour)}
              onChange={(e) => setFieldValue("hour", e.target.value)}
              onBlur={(e) => onHourChange(e, values,  setFieldValue)}
              required
              error={errors.hour}
              touched={touched.hour}
            />
          </Col>
          <Col xs={11}>
            <FormInput
              id="departureHour"
              label="tour_activity_departure_hour_field"
              type="time"
              step="60"
              value={dateUtils.getTimeWithoutSeconds(values?.departureHour)}
              onChange={(e) => setFieldValue("departureHour", e.target.value)}
              error={errors.departureHour}
              touched={touched.departureHour}
            />
          </Col>
          <Col xs={13}>
            <FormInput
              id="meetingPlace"
              label="tour_activity_meeting_place_field"
              value={values?.meetingPlace}
              onChange={(e) => setFieldValue("meetingPlace", e.target.value)}
              required
              error={errors.meetingPlace}
              touched={touched.meetingPlace}
            />
          </Col>
          <Col>
            <FormRadio
              id="optional"
              label="tour_activity_optional_field"
              value={YES_NO_OPTIONS.find((option) => option?.value === values?.optional)}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) => setFieldValue("optional", option?.value)}
              required
              error={errors.optional}
              touched={touched.optional}
            />
          </Col>
          <FormInput
            id="description"
            label="tour_activity_description_field"
            type="textarea"
            value={values?.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            required
            error={errors.description}
            touched={touched.description}
            rows="5"
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateTourActivityForm
