import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ResourceJobInfo} from "../../../../interfaces/ResourceInterfaces";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import FieldLabel from "../../../atoms/FieldLabel";
import {optionUtils} from "../../../../utils/optionUtils";
import {YES_NO_OPTIONS} from "../../../../constants/OptionConstants";

interface JobInfoResourceCardProps {
  className?: string;
  resourceJobInfo?: ResourceJobInfo;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
}

const JobInfoResourceCard: FunctionComponent<JobInfoResourceCardProps> = ({
  className = "",
  resourceJobInfo,
  editInfo,
  setEditInfo = () => null
}) => {
  const intl = useIntl()
  const renderActivityZones = () => (
    <div className="d-flex flex-column">
      {resourceJobInfo?.activityAreas?.map((area) => (
        <span key={area.id} className="epow-info">{area.label}</span>
      ))}
    </div>
  )

  const renderLanguages = () => (
    <div className="d-flex flex-column">
      {resourceJobInfo?.spokenLanguages?.map((language) => (
        <span key={language.id} className="epow-info">{language.label}</span>
      ))}
    </div>
  )

  const renderPublicType = () => (
    <div className="d-flex flex-column">
      {resourceJobInfo?.publicTypes?.map((publicType) => (
        <div key={publicType}>{intl.formatMessage({id: `public_type_${publicType.toLowerCase()}`})}</div>
      ))}
    </div>
  )

  return (
    <div className={className}>
      <div className="d-flex flex-column mb-3">
        <div className="mb-2 d-flex flex-column gap-3">
          <FieldLabel
            verticalDisplay
            label={intl.formatMessage({id: "resource_is_nocturnal"})}
            value={optionUtils.translateOption(intl, YES_NO_OPTIONS, resourceJobInfo?.isNocturnal)}
          />
          <FieldLabel
            verticalDisplay
            label={intl.formatMessage({id: "resource_public_type"})}
            value={renderPublicType()}
          />
          <FieldLabel
            verticalDisplay
            label={intl.formatMessage({id: "activity_zone"})}
            value={renderActivityZones()}
          />
          <FieldLabel
            verticalDisplay
            label={intl.formatMessage({id: "spoken_languages"})}
            value={renderLanguages()}
          />
        </div>
      </div>
      <div>
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setEditInfo(!editInfo)}
          className="extended"
        >
          <FormattedMessage id="edit_button"/>
        </Button>
      </div>
    </div>
  );
};

export default JobInfoResourceCard;
