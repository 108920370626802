import {CircleColor} from "./CircleConstants"

export const AvailabilityEnum = {
  AM:"AM",
  PM:"PM",
  NIGHT: "NIGHT"
} as const

export type AvailabilityEnumType = typeof AvailabilityEnum[keyof typeof AvailabilityEnum]

export const AVAILABILITY_TYPE: Record<AvailabilityEnumType, {label: string}> = {
  AM: {label: "resource_availability_am"},
  PM: {label: "resource_availability_pm"},
  NIGHT: {label: "resource_availability_night"},
}


export const AvailabilityStatusEnum = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE",
  STAFFED: "STAFFED",
}

export type AvailabilityStatusEnumType = typeof AvailabilityStatusEnum[keyof typeof AvailabilityStatusEnum]

export const AVAILABILITY_STATUS_TYPE: Record<AvailabilityStatusEnumType, {label:string, setterLabel: string, color: CircleColor}> = {
  AVAILABLE: {label: "resource_availability_available", setterLabel: "resource_availability_set_unavailable", color: CircleColor.GREEN},
  UNAVAILABLE: {label: "resource_availability_unavailable", setterLabel: "resource_availability_set_available", color: CircleColor.RED},
  STAFFED: {label: "resource_availability_staffed", setterLabel: "", color: CircleColor.ORANGE},
}
