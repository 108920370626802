import React, {FunctionComponent} from "react";
import {Cell} from "react-table";
import {FormattedMessage} from "react-intl";
import {ResourceAvailabilityCalendar} from "../../../../constants/ResourceConstants";
import {CalendarTableResourceBody} from "../../../../interfaces/CalendarInterface";
import {RESOURCES_PATH, getProfilePicture} from "../../../../constants/routes/RoutePaths";
import ResourceCircle from "../../circle/ResourceCircle";
import Avatar from "../../images/Avatar";

interface CellCalendarProps {
  cell: Cell<CalendarTableResourceBody>,
  hideAvailability?: boolean
}

const HeaderResourceCalendar: FunctionComponent<CellCalendarProps> = ({cell, hideAvailability = false}) => {

  const openResourceTab = () => {
    window.open(`${RESOURCES_PATH}/${cell.row.original.id}`)
  }

  return (
    <div className={`d-flex justify-content-center flex-column staffing-calendar__cell cell-info lg h-100 position-relative mb-1${!hideAvailability ? " pt-4" : " p-3"}`} >
      {!hideAvailability &&
        <>
          <ResourceCircle className="absolute-resource-circle" resourceType={cell.row.original.resourceType} />
          <div className={`availability epow-text-${ResourceAvailabilityCalendar[cell.row.original.availability]?.color}`}>
            <FormattedMessage id="availability_calendar" />
            <FormattedMessage id={ResourceAvailabilityCalendar[cell.row.original.availability]?.label} />
          </div>
        </>
      }
      <div className="d-flex justify-content-between">
        <Avatar url={getProfilePicture(cell.row.original.id)}  width={35} height={35} className="m-0 me-2"/>
        <span onClick={() => openResourceTab()} className="bold mb-1 cursor-pointer">{cell.row.original.name}</span>
        { hideAvailability && <ResourceCircle resourceType={cell.row.original.resourceType} /> }
      </div>
      <div><FormattedMessage id="work_on_current_year" />: {cell.row.original.currentYearHourCount ?? 0} h</div>
      <div><FormattedMessage id="work_on_last_year" />: {cell.row.original.previousYearHourCount ?? 0} h</div>
    </div>
  )
}


export default HeaderResourceCalendar;
