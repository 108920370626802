import React, {FunctionComponent} from "react";
import Layout from "../../components/layout/Layout";
import {useIntl} from "react-intl";
import {Invoice, InvoiceType} from "../../interfaces/InvoiceInterfaces";
import ContactCard from "../../components/molecules/card/ContactCard";
import {contactService} from "../../services/ContactService";
import {InvoiceHeaderProps} from "../../components/molecules/header/InvoiceHeader";
import {Col, Row} from "reactstrap";
import Panel from "../../components/molecules/panel/Panel";
import CreateBillingItemForm from "../../components/molecules/form/CreateInvoiceBillingItemForm";
import CommentCard from "../../components/molecules/card/comment/CommentCard";
import useBilling from "./useBIlling";
import BillingInvoiceOrderDetails from "./billing-details/BillingInvoiceOrderDetails";
import BillingInvoiceAdditionalCharges from "./billing-details/BillingInvoiceAdditionalCharges";
import BillingInvoiceSummaryDetails from "./billing-details/BillingInvoiceSummaryDetails";
import EditInvoiceContactForm from "../../components/molecules/form/EditInvoiceContactForm";

interface TemplateBillingViewProps {
  fetchInvoice: () => Promise<Invoice>
  HeaderComponent: FunctionComponent<InvoiceHeaderProps>,
}

const TemplateBillingView: FunctionComponent<TemplateBillingViewProps> = ({
  fetchInvoice: fetchInvoiceProps,
  HeaderComponent,
}) => {
  const intl = useIntl();
  const {
    invoice,
    fetchInvoice,
    onChangeInvoice,
    open,
    onOpenUpdateModal,
    selectedBillingItem,
    onDeleteBillingItem,
    deleteComment,
    createComment,
    onOpenModal,
    onExitModal,
    onOpenCustomerEditModal,
    openEditModal,
    onExitCustomerEditModal
  } = useBilling({fetchInvoice: fetchInvoiceProps})

  return (
    <Layout> {invoice &&
      <div className="epow-content-body epow-purchase-card d-flex flex-column">
        <HeaderComponent
          onClickAdd={onOpenModal}
          onClickContactEdit={onOpenCustomerEditModal}
          onUpdateStatus={onChangeInvoice}
          invoice={invoice}
          status={invoice.status} />

        <Row>
          <Col xs={24} xl={16}>
            <div>
              {invoice.invoiceOrderDetails?.map((invoiceOrder) => (
                <BillingInvoiceOrderDetails
                  key={invoiceOrder.orderId}
                  invoice={invoice}
                  invoiceOrder={invoiceOrder}
                  onDeleteBillingItem={onDeleteBillingItem}
                  onOpenUpdateModal={onOpenUpdateModal}
                />
              ))}
            </div>
            {invoice.additionnalCharges?.length > 0 && (
              <BillingInvoiceAdditionalCharges
                invoice={invoice}
                onDeleteBillingItem={onDeleteBillingItem}
                onOpenUpdateModal={onOpenUpdateModal}
              />
            )}
            {(invoice.invoiceOrderDetails?.length > 0 || invoice.additionnalCharges?.length > 0) &&
              <BillingInvoiceSummaryDetails invoice={invoice} />
            }
          </Col>

          <Col xs={24} xl={8}>
            <ContactCard
              firstName={invoice.billingCustomer.customerName}
              lineContact={contactService.buildCustomerLineContact(invoice.billingCustomer)}
            />
            <CommentCard
              className="mt-2"
              comment={invoice.comment}
              createComment={createComment}
              deleteComment={deleteComment}
              title={intl.formatMessage({id: "customer_comment"})}
            />
          </Col>
        </Row>
      </div>
    }

      {open && <Panel
        title={intl.formatMessage({id: "invoice_billing_item_title_add"})}
        formId="invoiceBillingItemForm"
        open={open}
        onCancel={onExitModal}>
        <CreateBillingItemForm
          billingItem={selectedBillingItem}
          id="invoiceBillingItemForm"
          onSubmitSuccess={() => {
            fetchInvoice()
            onExitModal()
          }}
          invoiceId={invoice.id}
          isCreditNote={invoice.type === InvoiceType.CREDIT_NOTE}
          billingCustomerId={invoice.billingCustomer.customerId}
        />
      </Panel>}

      {openEditModal &&
        <Panel
            formId="change-contact"
          open={openEditModal}
          onCancel={onExitCustomerEditModal}
        >
          <EditInvoiceContactForm
            id="change-contact"
            invoice={invoice}
            onSubmitSuccess={() => {
              fetchInvoice()
              onExitCustomerEditModal()
            }}
          />
        </Panel>
      }
    </Layout>
  );
}


export default TemplateBillingView;
