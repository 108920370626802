import React, {FunctionComponent} from "react";
import GenericModal from "../GenericModal";
import {useIntl} from "react-intl";
import {Contact} from "../../../../interfaces/ContactInterfaces";
import {contactService} from "../../../../services/ContactService";
import {toastUtils} from "../../../../utils/toastUtils";

interface ModalUpdateAccessProps {
  className?: string,
  title?: string,
  openModalUpdateAccess?: boolean,
  setOpenModalUpdateAccess?: (open: boolean) => void,
  getContacts?: () => void,
  contactInitial?: Contact
}

const ModalUpdateAccess: FunctionComponent<ModalUpdateAccessProps> = ({
  className = "",
  title,
  openModalUpdateAccess,
  setOpenModalUpdateAccess,
  getContacts = () => null,
  contactInitial
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const updateAccessContact = (contactInitial?: Contact) => {
    if (contactInitial?.id) {
      contactService.updateContact(contactInitial?.id, {...contactInitial, hasAccessToPlatform: "true"})
        .then(() => {
          getContacts();
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_contact"}))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_contact"}))
        })
      setOpenModalUpdateAccess(false)
    }
  }

  return (
    <GenericModal
      className={className}
      title={title}
      open={openModalUpdateAccess}
      onOpen={setOpenModalUpdateAccess}
      closable
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => updateAccessContact(contactInitial)
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: () => setOpenModalUpdateAccess(false)
        }
      }}
    >
      {intl.formatMessage({id: "modal_body_update_access_platform"}, {lastName: contactInitial?.identity?.lastName, firstName: contactInitial?.identity?.firstName})}
    </GenericModal>
  )
}

export default ModalUpdateAccess
