import React, {FunctionComponent} from "react";
import {DocumentDTO} from "../../../../interfaces/FileInterfaces";
import {Col, Row} from "reactstrap";
import {fileUtils} from "../../../../utils/fileUtils";
import {fileService} from "../../../../services/FileService";
import {FormattedMessage, useIntl} from "react-intl";
import {USER_PROFILE} from "../../../../constants/user/UserConstants";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";

interface DocumentTableRowAdminProps {
  className?: string,
  document: DocumentDTO,
  editDocument: (document: DocumentDTO) => void,
  deleteDocument: (documentId: string) => void,
}

const DocumentTableRowAdmin: FunctionComponent<DocumentTableRowAdminProps> = ({
  className = "",
  document,
  editDocument,
  deleteDocument,
}) => {
  const intl = useIntl()
  const fullName = fileUtils.getCompleteFileName({name: document.name, extension: document.extension});
  const profileList: PROFILE[] = document.profiles?.split(",") as PROFILE[] ?? []

  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  return (
    <Row className={`document-table-row ${className ?? ""}`}>
      <Col>
        <span className="epow-text-link cursor-pointer" onClick={() => downloadDocument(document.fileId, document.name, document.extension)}>{fullName}</span>
      </Col>

      <Col>
        {document.activities?.map(activity => <div key={activity.id}>{activity.name}</div>) ?? "-"}
      </Col>

      <Col>
        {(profileList.length === Object.keys(USER_PROFILE).length) ? (
          <FormattedMessage id="profile_all" />
        ) : (
          profileList.map((p) => intl.formatMessage({id: USER_PROFILE[p]?.label})).join(", ") ?? "-"
        )}
      </Col>

      <Col xs={2} className="d-flex justify-content-end">
        <div>
          <Icon name="Pen" size={IconSizeType.XS} className="cursor-pointer me-3" onClick={() => editDocument(document)} />
          <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer me-3" onClick={() => deleteDocument(document.fileId)} />
        </div>
      </Col>
    </Row>
  )
}

export default DocumentTableRowAdmin;
