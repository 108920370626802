import React, {ChangeEventHandler, FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {FormGroup, Label} from "reactstrap";
import TextInput, {TextInputProps} from "../../atoms/input/TextInput";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import {InputType} from "reactstrap/types/lib/Input";
import {IconName} from "../../icon/IconsMap";
import {IconPlacement} from "../../../types/bootstrap/BootstrapType";

export interface FormInputProps extends TextInputProps {
  id?: string,
  className?: string,
  label?: string,
  labelValues?: Record<string, string>
  placeholder?: string,
  icon?: IconName,
  value?: string|number,
  required?: boolean,
  error?: FormikError
  touched?: FormikTouchedType,
  type?: InputType,
  step?: string;
  popover?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  onBlur?: ChangeEventHandler<HTMLInputElement>,
  disabled?: boolean,
  minValue?: number|string,
  maxValue?: number|string,
  iconPlacement?: IconPlacement,
  onIconClick?: () => void,
}

const FormInput: FunctionComponent<FormInputProps> = ({
  id,
  className = "",
  label ,
  labelValues,
  placeholder = "",
  icon,
  value,
  required,
  error,
  touched,
  type = "text",
  step = "any",
  popover = "",
  onChange,
  onBlur,
  disabled,
  minValue,
  maxValue,
  rows,
  iconPlacement = IconPlacement.LEFT,
  onIconClick = null,
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            <FormattedMessage id={label} values={labelValues}/>
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>
      <TextInput
        id={id}
        className={`${!!error && !!touched ? "epow-input-error" : ""}`}
        icon={icon}
        value={value}
        type={type}
        step={step}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        minValue={minValue}
        maxValue={maxValue}
        rows={rows}
        iconPlacement={iconPlacement}
        onIconClick={onIconClick}
        {...rest}
      />
      <ErrorField error={error} touched={touched}/>
    </FormGroup>
  )
}

export default FormInput
