import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";
import {StaffedResource} from "../../../../interfaces/ActivityStaffingInterface";
import {ResourceCalendarColumn, ResourceCalendarResourceStatus, ResourceCalendarRow} from "../../../../interfaces/ResourceCalendarInterfaces";
import {ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import {toastUtils} from "../../../../utils/toastUtils";
import Panel from "../Panel";
import ResourceCalendarCellPanelBody from "./ResourceCalendarCellPanelBody";
import StaffingFormStepTwo from "../../form/staffing/StaffingFormStepTwo";
import Button from "../../../atoms/Button";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";

interface ResourceCalendarCellPanelProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  activity: ResourceCalendarRow,
  resource: ResourceCalendarColumn,
  status: ResourceCalendarResourceStatus,
  onReload: () => void,
}

const ResourceCalendarCellPanel: FunctionComponent<ResourceCalendarCellPanelProps> = ({
  open,
  setOpen,
  activity,
  resource,
  status,
  onReload,
}) => {
  const intl = useIntl()
  const [staffings, setStaffings] = React.useState<StaffedResource[]>([])
  const [step, setStep] = React.useState<number>(0)
  const [staffingRequest, setStaffingRequest] = React.useState<ResourceStaffingRequest[]>([{resourceId: resource.id, message: ""}])

  const getResourcesStaffed = () => {
    orderItemService.getOrderItemStaffing(activity.orderItemId)
      .then(response => setStaffings(response))
      .catch(() => intl.formatMessage({id: "error_toast_calendar"}))
  }

  React.useEffect(() => {
    getResourcesStaffed()
  }, [])

  const onUpdate = () => {
    onReload()
    setOpen(false)
    toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_update"}))
  }

  const onError = () => {
    toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_update"}))
  }

  const sentStaffing = (status : ActivityStaffingStatus) => {
    const request: ResourceStaffingRequest = {
      resourceId: resource.id,
      status: status
    }

    const relevantStaffing = staffings.find(s => s.resourceId === resource.id)
    orderItemService.updateStaffingForOrderItem(activity.orderItemId, relevantStaffing.staffingId, request)
      .then(onUpdate)
      .catch(onError)
  }


  const directStaffing = () => {
    orderItemService.sendDirectStaffingForOrderItem(activity.orderItemId, [resource.id])
      .then(onUpdate)
      .catch(onError)
  }

  const sendStaffing = () => {
    orderItemService.sendStaffingRequestForOrderItem(activity.orderItemId, staffingRequest)
      .then(onUpdate)
      .catch(onError)
  }

  return (
    <Panel
      title={`Staffing ${resource.name}`}
      cancelButtonLabel="close"
      displayValidateButton={false}
      open={open}
      onCancel={() => setOpen(false)}>

      {step === 0 && (
        <ResourceCalendarCellPanelBody
          activity={activity}
          resource={resource}
          staffings={staffings}
          status={status}
          setStep={setStep}
          sentStaffing={sentStaffing}
          directStaffing={directStaffing}
        />
      )}

      {step === 1 && (
        <div className="d-flex flex-column w-100">
          <StaffingFormStepTwo
            selectedResources={[{id: resource.id, firstName: resource.name, lastName: ""}]}
            resourceRequests={staffingRequest}
            setResourceRequests={setStaffingRequest}
          />
          <div className="ms-auto">
            <Button className="py-0 me-2" color={ColorType.SECONDARY} onClick={() => setStep(0)} >
              <span>{intl.formatMessage({id: "cancel_button"})}</span>
            </Button>
            <Button className="py-0" onClick={() => sendStaffing()} >
              <span>{intl.formatMessage({id: "confirm_button"})}</span>
            </Button>
          </div>
        </div>
      )}

    </Panel>
  )
}

export default ResourceCalendarCellPanel;
