import React, {FunctionComponent} from "react";
import {SupplierServiceCatalog} from "../../../interfaces/SupplierInterfaces";
import {Formik} from "formik";
import {useIntl} from "react-intl";
import {dateUtils} from "../../../utils/dateUtils";
import {toastUtils} from "../../../utils/toastUtils";
import {supplierServiceService} from "../../../services/SupplierServiceService";
import {SUPPLIER_SERVICE_CATALOG_INITIAL_VALUES} from "../../../constants/ServiceConstants";
import {CreateSupplierServiceCatalogSchema} from "../../../constants/validation/ServiceValidationSchemas";
import EpowForm from "./EpowForm";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import PriceInput from "../input/PriceInput";

interface CreateSupplierServiceCatalogFormProps {
  id?: string,
  className?: string,
  supplierServiceId?: string,
  initialSupplierServiceCatalog?: SupplierServiceCatalog,
  onValidate?: () => void
}

const CreateSupplierServiceCatalogForm: FunctionComponent<CreateSupplierServiceCatalogFormProps> = ({
  id,
  className ="",
  supplierServiceId,
  initialSupplierServiceCatalog,
  onValidate = () => null
}) => {
  const intl = useIntl()
  const supplierServiceCatalog = initialSupplierServiceCatalog ? {
    ...initialSupplierServiceCatalog,
    startDateValidity: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(initialSupplierServiceCatalog?.startDateValidity))
  } : null

  const handleSubmitSupplierServiceCatalog = (supplierServiceCatalog: SupplierServiceCatalog) => {
    if (initialSupplierServiceCatalog?.id) {
      supplierServiceService.updateSupplierServiceCatalog(
        initialSupplierServiceCatalog.id,
        {...supplierServiceCatalog, id: supplierServiceId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_supplier_service_catalog"}))
        onValidate && onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_supplier_service_catalog"}))
      })
    } else {
      supplierServiceService.createSupplierServiceCatalog(
        {...supplierServiceCatalog, id: supplierServiceId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_supplier_service_catalog"}))
        onValidate && onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_supplier_service_catalog"}))
      })
    }
  }

  return (
    <Formik
      initialValues={supplierServiceCatalog || SUPPLIER_SERVICE_CATALOG_INITIAL_VALUES}
      validationSchema={CreateSupplierServiceCatalogSchema}
      onSubmit={handleSubmitSupplierServiceCatalog}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="startDateValidity"
            label="activity_start_date_validity"
            type="date"
            required
            value={values?.startDateValidity}
            onChange={(e) => setFieldValue("startDateValidity", e.target?.value)}
            error={errors?.startDateValidity}
            touched={touched?.startDateValidity}
          />
          <FormRadio
            id="unitType"
            label="service_unit_type"
            value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
            options={UNIT_TYPE_OPTIONS}
            onChange={(e) => setFieldValue("unitType", e.value)}
            required
            error={errors.unitType}
            touched={touched.unitType}
          />
          <FormInput
            id="paxMax"
            label="service_number_pax"
            type="number"
            value={values?.paxMax}
            onChange={(e) => setFieldValue("paxMax", e.target.value)}
            required
            error={errors?.paxMax}
            touched={touched?.paxMax}
          />
          <PriceInput
            id="unitPrice"
            label="service_unit_price"
            type="number"
            value={values?.unitPrice}
            onChange={(e) => setFieldValue("unitPrice", e.target.value)}
            required
            error={errors?.unitPrice}
            touched={touched?.unitPrice}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateSupplierServiceCatalogForm
