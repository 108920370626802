import {FormikErrors, FormikTouched} from "formik";
import React, {FunctionComponent} from "react";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import {User} from "../../../../interfaces/UserInterfaces";
import FormInput from "../../input/FormInput";

interface UserEditProfileFormFragmentProps {
  className?: string,
  values: User,
  errors: FormikErrors<User>,
  touched: FormikTouched<User>,
  setFieldValue: FormikFieldSetter,
}

const UserEditProfileFormFragment: FunctionComponent<UserEditProfileFormFragmentProps> = ({
  className = "",
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  return (
    <div className={className}>
      <FormInput
        id="firstName"
        label="user_first_name"
        value={values?.firstName}
        onChange={(e) => setFieldValue("firstName", e.target.value)}
        required
        error={errors.firstName}
        touched={touched.firstName}
      />
      <FormInput
        id="lastName"
        label="user_last_name"
        value={values?.lastName}
        onChange={(e) => setFieldValue("lastName", e.target.value)}
        required
        error={errors.lastName}
        touched={touched.lastName}
      />
      <FormInput
        id="email"
        label="user_email"
        value={values?.email}
        onChange={(e) => setFieldValue("email", e.target.value)}
        required
        error={errors.email}
        touched={touched.email}
      />
      <FormInput
        id="phoneNumber"
        label="user_phoneNumber"
        value={values?.phoneNumber}
        onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
        error={errors.phoneNumber}
        touched={touched.phoneNumber}
      />
    </div>
  )
}

export default UserEditProfileFormFragment;
