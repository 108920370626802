import Button from "components/atoms/Button";
import Title from "components/atoms/Title";
import EditResourceGeneralInfoForm from "components/molecules/form/resource/EditResourceGeneralInfoForm";
import {ResourceGeneralInfo, ResourceGeneralInfoFields} from "interfaces/ResourceInterfaces";

import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Col} from "reactstrap";
import {ColorType, SizeType} from "types/bootstrap/BootstrapType";
import ResourceCard from "./ResourceCard";

interface GeneralInfoResourceCardProps {
  className?: string;
  resourceGeneralInfoFields?: ResourceGeneralInfoFields;
  setResourceAfterUpdate?: (resourceGeneralInfo: ResourceGeneralInfo) => void;
  title?: string;
  self: boolean;
}

const GeneralInfoResourceCard: FunctionComponent<GeneralInfoResourceCardProps> = ({
  className = "",
  resourceGeneralInfoFields,
  setResourceAfterUpdate,
  title,
  self
}) => {
  const [editInfo, setEditInfo] = useState<boolean>(false);

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title title={title} size={"h5"}/>
      </div>
      {editInfo ? (
        <>
          <EditResourceGeneralInfoForm
            self={self}
            id="editResourceForm"
            initialResourceFields={resourceGeneralInfoFields}
            setResourceAfterUpdate={setResourceAfterUpdate}
            setEditInfo={setEditInfo}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form={"editResourceForm"}
              type="submit"
              size={SizeType.MD}
              className="extended"
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <ResourceCard
          resourceGeneralInfoFields={resourceGeneralInfoFields}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
        />
      )}
    </Col>
  );
};

export default GeneralInfoResourceCard;
