import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import TourList from "../components/molecules/list/TourList";
import Layout from "../components/layout/Layout";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import useSearchFilter from "../hooks/useSearchFilter";
import {TourSearchObject} from "../interfaces/TourInterfaces";
import Panel from "../components/molecules/panel/Panel";
import CreateTourForm from "../components/molecules/form/CreateTourForm";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const TourListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const {searchObject, onReset, updateSearchField} = useSearchFilter<TourSearchObject>({
    tourCode: "",
    tourName: "",
    activityId: "",
    customerId: "",
    orderStatus: [],
    currentState: WorkflowStatesEnum.ACTIVE
  })
  const [creationPanelOpen, setCreationPanelOpen] = useState<boolean>(false)
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <Layout>
      <div className="d-flex flex-column epow-content-body">
        <CommonEntityHeader
          pageTitle={intl.formatMessage({id: "tour_title"})}
          updateSearchField={updateSearchField}
          setCreationPanelOpen={setCreationPanelOpen}
          creationButtonLabel={intl.formatMessage({id: "create_new_tour"})}
        />

        <TourList
          searchObject={searchObject}
          onReset={() => onReset({currentState: searchObject.currentState})}
          updateSearchField={updateSearchField}
        />

        {creationPanelOpen && (
          <Panel loading={loading} formId="createTourForm" title="create_new_tour" open={creationPanelOpen} onCancel={() => setCreationPanelOpen(false)}>
            <CreateTourForm id="createTourForm" stopLoading={stopLoading} startLoading={startLoading} />
          </Panel>
        )}
      </div>
    </Layout>
  )
}

export default TourListView
