import React, {FunctionComponent, ReactElement} from "react";
import TextLink from "./TextLink";

interface FieldLabelProps {
  className?: string,
  verticalDisplay?: boolean,
  label: string,
  labelBold?: boolean
  value?: string|number|ReactElement,
  valueBold?: boolean
  href?: string,
  external?: boolean,
  displayZero?: boolean,
  defaultDisplayClassName?: string,
}

const FieldLabel: FunctionComponent<FieldLabelProps> = ({
  className = "",
  verticalDisplay= false,
  label,
  value,
  valueBold = verticalDisplay,
  labelBold= !valueBold,
  href,
  external= false,
  displayZero = false,
  defaultDisplayClassName = ""
}) => {

  const displayedValue = value || (value === 0 && displayZero) ? value : "-";

  return (
    <div className={className}>
      {verticalDisplay ? (
        <>
          <div className="d-flex flex-column">
            <span className="text-muted">{label}</span>
            {(href && value) ? (
              <TextLink className={`${valueBold ? "epow-info" : ""}`} to={href} external={external}>
                {value}
              </TextLink>
            ) : (
              <span className={`${valueBold ? "epow-info" : ""}`}>{displayedValue}</span>
            )}
          </div>
        </>
      ) : (
        <>
          <span className={`${defaultDisplayClassName} ${labelBold ? "bold" : ""}`}>{`${label} : `}</span>
          {(href && value) ? (
            <TextLink className={valueBold ? "bold" : ""} to={href}>
              {value}
            </TextLink>
          ) : (
            <span className={valueBold ? "bold" : ""}>{displayedValue}</span>
          )}
        </>
      )}
    </div>
  )
}

export default FieldLabel;
