import React, {FunctionComponent} from "react";
import Popover from "../../atoms/Popover";

interface CardFieldProps {
  className?: string,
  label: string,
  popover?: string,
  value?: string|number,
  values?: string[];
  children?: React.ReactElement;
}

const CardField: FunctionComponent<CardFieldProps> = ({
  className = "",
  label,
  popover,
  value,
  values,
  children
}) => {

  const getValue = () => {
    return value && value !== "" ? value : "-";
  }

  return (
    <div className={`mb-3 d-flex flex-column ${className}`}>
      <div className="d-flex flex-row">
        <span className="text-muted">{label}</span>
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>

      {values ? (
        values?.map((value, id) =>
          <span key={id} className="epow-info">{value ?? "-"}</span>)
      ) : (
        <span className="epow-info">
          {children ?? getValue()}
        </span>
      )}
    </div>
  )
}

export default CardField;