import React, {FunctionComponent} from "react";
import {BillingItem, Invoice, InvoiceOrder, InvoiceState} from "../../../interfaces/InvoiceInterfaces";
import {FormattedMessage} from "react-intl";
import {Row, Col} from "reactstrap";
import ActionButtonBilling from "../../../components/molecules/billing/ActionButtonBilling";
import {dateUtils} from "../../../utils/dateUtils";
import {moneyUtils} from "../../../utils/moneyUtils";
import Badge from "../../../components/atoms/Badge";
import {OrderItemState, OrderItemStateMap} from "../../../interfaces/OrderItemInterfaces";

interface BillingInvoiceOrderDetailsProps {
  className?: string,
  invoiceOrder: InvoiceOrder,
  invoice: Invoice,
  onDeleteBillingItem: (item: BillingItem) => void,
  onOpenUpdateModal: (item: BillingItem) => void,
}

const BillingInvoiceOrderDetails: FunctionComponent<BillingInvoiceOrderDetailsProps> = ({
  className = "",
  invoiceOrder,
  invoice,
  onDeleteBillingItem,
  onOpenUpdateModal,
}) => {
  return (
    <div className={`${className} p-2`}>
      <div className="d-flex flex-row align-items-center mb-2">
        <h6>Code Tour: {invoiceOrder.refCustomer || invoiceOrder.orderNumber}</h6>
      </div>
      <div className="card p-3 mb-3">
        <Row className="fw-bold">
          <Col><FormattedMessage id="invoice_billing_item_header_date" /></Col>
          <Col xs={8}><FormattedMessage id="invoice_billing_item_header_service" /></Col>
          <Col className="text-end"></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_title" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_unit_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_price" /></Col>
          <Col className="text-end"><FormattedMessage id="invoice_billing_item_header_priceTTC" /></Col>
          {invoice.status === InvoiceState.DRAFT && <Col xs={2} />}
        </Row>
        {invoiceOrder.billingItemDetails.map((billingItem) => (
          <>
            <Row className={`${billingItem.disbursement ? "bg-danger" : ""} pt-2`}
              key={billingItem.id}>
              <Col>{dateUtils.formatToLocalDateString(dateUtils.parseDate(billingItem.date))}</Col>
              <Col xs={8}>{billingItem.name}</Col>
              <Col className="text-end">
                { billingItem.status === OrderItemState.CANCELLED && <Badge pill color={OrderItemStateMap[billingItem.status]?.color}>
                  <FormattedMessage id={OrderItemStateMap[billingItem.status]?.label}/>
                </Badge>}
              </Col>
              <Col className="text-end">{billingItem.quantity}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.unitPriceHT)}</Col>
              <Col
                className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.amountHT)}</Col>
              <Col className="text-end">{moneyUtils.formatNumberToCurrency(billingItem.amountTTC)}</Col>
              {invoice.status === InvoiceState.DRAFT && <Col xs={2} className="d-flex justify-content-center">
                <div>
                  <ActionButtonBilling
                    onClickDelete={() => onDeleteBillingItem(billingItem)}
                    onClickUpdate={() => onOpenUpdateModal(billingItem)} />
                </div>
              </Col>}
            </Row>

            {billingItem.billingSubItemDetails.map((billingSubItems) => (
              <Row className="pt-2" key={billingSubItems.id}>
                <Col></Col>
                <Col xs={8}><span
                  className={`${billingItem.disbursement ? "color-secondary" : ""} text-end ms-2`}>{billingSubItems.name}</span></Col>
                <Col className="text-end">{billingSubItems.quantity}</Col>
                <Col
                  className="text-end">{moneyUtils.formatNumberToCurrency(billingSubItems.unitPriceHT)}</Col>
                <Col
                  className="text-end">{moneyUtils.formatNumberToCurrency(billingSubItems.amountHT)}</Col>
                <Col
                  className={`${billingItem.disbursement ? "color-secondary" : ""} text-end`}>{moneyUtils.formatNumberToCurrency(billingSubItems.amountTTC)}</Col>
                {invoice.status === InvoiceState.DRAFT &&
                  <Col xs={2} className="d-flex justify-content-center">
                    <div>
                      <ActionButtonBilling
                        onClickDelete={() => onDeleteBillingItem(billingSubItems)}
                        onClickUpdate={() => onOpenUpdateModal({...billingSubItems, orderItemId: billingItem.orderItemId})} />
                    </div>
                  </Col>}
              </Row>))}
          </>
        ))}
      </div>
    </div>
  )
}

export default BillingInvoiceOrderDetails;
