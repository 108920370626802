import {API_ACTIVITY_AREA_PATH} from "../constants/routes/RoutePaths"
import {ActivityArea, ActivityAreaSearchObject} from "../interfaces/ActivityInterfaces"
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface"
import {queryUtils} from "../utils/queryUtils"
import {httpService} from "./HttpService"

function getActivityAreas(): Promise<ActivityArea[]> {
  return httpService.get<ActivityArea[]>(API_ACTIVITY_AREA_PATH)
}

function getActivityAreasPage(params: PaginationQueryParams, filters: ActivityAreaSearchObject): Promise<PageResponse<ActivityArea>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<ActivityArea>>(`${API_ACTIVITY_AREA_PATH}/page${queryParams}`)
}

function createActivityArea(request: ActivityArea): Promise<ActivityArea> {
  return httpService.post(API_ACTIVITY_AREA_PATH, request)
}

function updateActivityArea(id: string, request: ActivityArea): Promise<ActivityArea> {
  return httpService.put(`${API_ACTIVITY_AREA_PATH}/${id}`, request)
}

function deleteActivityArea(id: string): Promise<Response> {
  return httpService.delete(`${API_ACTIVITY_AREA_PATH}/${id}`)
}

export const activityAreaService = Object.freeze({
    getActivityAreas,
    getActivityAreasPage,
    createActivityArea,
    updateActivityArea,
    deleteActivityArea,
})
