import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {toastUtils} from "utils/toastUtils";
import {Activity} from "interfaces/ActivityInterfaces";
import {activityService} from "services/ActivityService";
import {FormattedMessage, useIntl} from "react-intl";
import {ACTIVITIES_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsEnum, WorkflowStateMap, WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import TabList from "../components/molecules/tab/TabList";
import {activityTab} from "../constants/tabs/TabListConstants";
import PageTitle from "../components/molecules/header/PageTitle";


const ActivityView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {id} = useParams();

  const [activity, setActivity] = useState<Activity>({})

  const getActivity = () => {
    activityService.getActivity(id)
      .then((activity) => {
        setActivity(activity)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_activity"}))
      })
  }

  const handleDeleteButton = () => {
    activityService.deleteActivity(activity.id).then(() => {
      toastUtils.infoToast(intl.formatMessage({id: "workflow_delete_info"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
      navigate(ACTIVITIES_PATH)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_delete_failure"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
    })
  }

  const handleActivateButton = () => {
    activityService.updateActivity(activity.id, {...activity, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Activity) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
        setActivity(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
    })
  }

  const handleDeactivateButton = () => {
    activityService.updateActivity(activity.id, {...activity, currentState: WorkflowStatesEnum.INACTIVE})
      .then((response: Activity) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_deactivate_info"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
        setActivity(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_deactivate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
    })
  }

  const handleReactivateButton = () => {
    activityService.updateActivity(activity.id, {...activity, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Activity) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_reactivate_info"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
        setActivity(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_reactivate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_activity"})}))
    })
  }

  useEffect(() => {
    getActivity()
  }, [])

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <PageTitle
              pageTitle={activity.name}
              className="me-4"
              breadcrumbItems={[{
                title: intl.formatMessage({id: "activity_title"}),
                to: ACTIVITIES_PATH
              }]}
            />
            <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
            <Badge pill color={WorkflowStateMap[activity.currentState]?.color} className="ms-2">
              {intl.formatMessage({id: WorkflowStateMap[activity.currentState]?.label || "STATUS_DEFAULT"})}
            </Badge>
          </div>
          <WorkflowButtonList
            currentState={activity?.currentState}
            entityLabel={intl.formatMessage({id: "workflow_entity_activity"})}
            actions={{
              [WorkflowActionsEnum.DELETE]: handleDeleteButton,
              [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
              [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
              [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton
            }}
          />
        </div>
        <TabList items={activityTab(activity, setActivity)}/>
      </div>
    </Layout>
  )
}

export default ActivityView
