import React, {FunctionComponent, useState} from "react";

import CellHeaderAvailability from "./CellHeaderAvailability";
import {AvailabilityCalendar} from "../../../../../interfaces/CalendarInterface";
import ActivityPanel from "../../../card/activity/ActivityPanel";
import {authUtils} from "../../../../../utils/authUtils";
import Portal from "../../../../atoms/Portal";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";

interface CellCalendarProps {
    availabilityType: string
    availability: AvailabilityCalendar
}

const CellStaffCalendar: FunctionComponent<CellCalendarProps> = ({availabilityType, availability}) => {
    const [panelOpen, setPanelOpen] = useState<boolean>(false)

    return (
        <div>
            <div className="staffing-calendar__cell lg bg-gray cursor-pointer" onClick={() => setPanelOpen(true)}>
                <CellHeaderAvailability availabilityType={availabilityType} arrowColor={ColorType.SECONDARY} />
                <div className="d-flex flex-column">
                    <span className="fw-bolder staff-cell-name">
                        {availability.staffedActivity.activityName}
                    </span>
                    <span className="color-gray">
                        {availability.staffedActivity.customerRef} | {availability.staffedActivity.customerName}
                    </span>
                </div>
            </div>

            {panelOpen && (
                <Portal>
                    <ActivityPanel
                        open={panelOpen}
                        setOpen={setPanelOpen}
                        profile={authUtils.getProfile()}
                        orderItemId={availability.staffedActivity.orderItemId}
                    />
                </Portal>
            )}

        </div>);
}

export default CellStaffCalendar;
