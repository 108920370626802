import { BankAccount } from "interfaces/BankAccountInterface";

export const BANK_ACCOUNT_INITIAL_VALUES: BankAccount = {
  accountName: "",
  bankName: "",
  bankCode: "",
  counterCode: "",
  accountNumber: "",
  ribKey: "",
  ibanCode: "",
  bicCode: "",
};
