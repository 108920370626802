import React, {FunctionComponent} from "react";
import {Badge as ReactStrapBadge, BadgeProps as ReactStrapBadgeProps} from "reactstrap";
import {ColorType, ColorTypeValues} from "../../types/bootstrap/BootstrapType";

interface BadgeProps extends ReactStrapBadgeProps {
  color?: ColorTypeValues
  className?: string
  ellipsis?: boolean
  pill?: boolean
}

const Badge: FunctionComponent<BadgeProps> = ({
  color = ColorType.PRIMARY,
  className = "",
  children,
  ellipsis= false,
  pill = false
}) => (
  <ReactStrapBadge pill={pill} className={`epow-badge ${className} ${ellipsis ? "ellipsis" : ""}`} color={color}>
    {children}
  </ReactStrapBadge>
)


export default Badge;
