import React, {FunctionComponent, useState} from "react";
import GenericModal from "../GenericModal";
import {FormattedMessage, useIntl} from "react-intl";
import {ModalButtonModalProps} from "../../button/ModalButton";
import {orderItemService} from "../../../../services/OrderItemService";
import {toastUtils} from "../../../../utils/toastUtils";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import ModalBulkStaffingFilters from "../../filters/staffing/ModalBulkStaffingFilters";
import {ResourceStaffingSearchParams} from "../../../../interfaces/ResourceStaffingInterfaces";
import {ResourceAvailability} from "../../../../constants/ResourceConstants";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";

interface ModalBulkStaffingProps extends ModalButtonModalProps {
  className?: string,
  orderItems: OrderItemStaffingPageItemDto[],
  onStaffing: () => void,
  selectedStaffing: string[]
  selectedResourceTypes?: ResourceType[],
}

const ModalBulkStaffing: FunctionComponent<ModalBulkStaffingProps> = ({
  className = "",
  modalOpen,
  setModalOpen,
  orderItems,
  onStaffing,
  selectedStaffing,
  selectedResourceTypes
}) => {
  const intl = useIntl()
  const initialSearchObject: ResourceStaffingSearchParams = {
    filterOnMatchingActivities: true,
    filterOnKids: true,
    filterOnPublicType: true,
    availability: ResourceAvailability.HIGH,
  }
  const [searchObject, setSearchObject] = useState<ResourceStaffingSearchParams>(initialSearchObject)


  const handleBulkStaffingRequest = () => {
    orderItemService.sendStaffingRequestForMultipleOrderItems(selectedStaffing, {...searchObject, resourceTypes: selectedResourceTypes?.map((type) => type.id)})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_request"}))
        onStaffing()
      })
      .catch(() =>
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      )
      .finally(() =>
        setModalOpen(false)
      )
  }

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({id: "staffing_send_request"})}
      open={modalOpen}
      onOpen={setModalOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: handleBulkStaffingRequest
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => setModalOpen(false)
        }
      }}
    >
      <div className="d-flex flex-column">

        <ModalBulkStaffingFilters searchObject={searchObject} setSearchObject={setSearchObject} selectedResourceTypes={selectedResourceTypes}/>

        <FormattedMessage id="staffing_request_modal_body" />
        <ul>
          {selectedStaffing.map(selected => (
            <li key={selected}>
              {orderItems.find(order => order.id === selected)?.name}
            </li>
          ))}
        </ul>
        <FormattedMessage id="staffing_request_modal_body_confirm" />
      </div>
    </GenericModal>
  )
}

export default ModalBulkStaffing;
