
function formatNumberToCurrency(value: number) {
  if(value === undefined || value == null) {
    return "-";
  }
  return Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(value);
}

export const moneyUtils = Object.freeze({
  formatNumberToCurrency

})
