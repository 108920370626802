import {TourActivity, TourActivityCustomer} from "../../../../interfaces/TourInterfaces";
import React, {FunctionComponent, useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Customer} from "../../../../interfaces/CustomerInterfaces";
import Accordion from "../../accordion/Accordion";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import Panel from "../../panel/Panel";
import CreateActivityInTourForCustomerForm from "../../form/CreateActivityInTourForCustomerForm";
import CreateTourActivityForm from "../../form/CreateTourActivityForm";
import {optionUtils} from "../../../../utils/optionUtils";
import {YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {ACTIVITIES_PATH, CUSTOMERS_PATH} from "../../../../constants/routes/RoutePaths";
import {dateUtils} from "../../../../utils/dateUtils";
import FieldLabel from "../../../atoms/FieldLabel";
import GenericModalDelete from "../../modal/GenericModalDelete";
import {tourService} from "../../../../services/TourService";
import { toastUtils } from "utils/toastUtils";
import { adminConfigurationService } from "services/AdminConfigurationService";

interface ActivityInTourCardProps {
  className?: string,
  tourActivityCustomer?: TourActivityCustomer | TourActivity,
  activityName?: string,
  activityId?: string,
  customer?: Customer,
  onValidate?: () => void,
  openAll?: boolean
}

const ActivityInTourCard: FunctionComponent<ActivityInTourCardProps> = ({
  className = "",
  tourActivityCustomer,
  activityName,
  activityId,
  customer,
  onValidate = () => null,
  openAll = false
}) => {
  const intl = useIntl()
  const [lateralPanelTourActivity, setLateralPanelTourActivity] = useState<boolean>(false)
  const [lateralPanelActivityInTourForCustomer, setLateralPanelActivityInTourForCustomer] = useState<boolean>(false)
  const [departureTimeMinuteDelta, setDepartureTimeMinuteDelta] = useState<number>(0);
  const [openModalDeleteActivity, setOpenModalDeleteActivity] = useState<boolean>(false)
  const [openModalDeleteActivityForCustomer, setOpenModalDeleteActivityForCustomer] = useState<boolean>(false)

  const handleValidateTourActivity = () => {
    setLateralPanelTourActivity(false)
    onValidate()
  }

  const handleValidateActivityInTourForCustomer = () => {
    setLateralPanelActivityInTourForCustomer(false)
    onValidate()
  }

  const handleModalDeleteActivity = () => {
    setOpenModalDeleteActivity(false)
    setOpenModalDeleteActivityForCustomer(false)
    onValidate()
  }

  const getAdminConfiguration = () => {
    adminConfigurationService.getAdminConfiguration()
    .then((config) => {
      setDepartureTimeMinuteDelta(config.departureTimeMinuteDelta);
    })
    .catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"}));
    })
  }

  useEffect(() => {
    getAdminConfiguration()
  }, [])

  return (
    <div className={className}>
      <Accordion
        startOpen={openAll}
        className="mb-2 primary"
        title={activityName}
        titleHref={`${ACTIVITIES_PATH}/${activityId}`}
      >
        <div className="d-flex flex-row justify-content-between gap-5">
          <div className="d-flex flex-column">
            <div>
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "tour_activity_billing_client_activity_name" })}
                value={"billingClientId" in tourActivityCustomer && tourActivityCustomer.clientBillingActivityName}
              />
            </div>
            <div className="d-flex flex-row flex-1 flex-wrap">
              {customer && (
                <FieldLabel
                  verticalDisplay
                  className="me-5 mb-2"
                  label={intl.formatMessage({ id: "billed_by_default" })}
                  value={"billingClientId" in tourActivityCustomer && tourActivityCustomer.billingClientName}
                  href={"billingClientId" in tourActivityCustomer &&`${CUSTOMERS_PATH}/${tourActivityCustomer.billingClientId}`}
                />
              )}
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "day" })}
                value={tourActivityCustomer?.dayAfterArrival}
                displayZero
              />
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "hour" })}
                value={dateUtils.formatLocalTime(tourActivityCustomer?.hour)}
              />
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "departureHour" })}
                value={dateUtils.formatLocalTime(tourActivityCustomer?.departureHour)}
              />
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "tour_activity_meeting_place_field" })}
                value={tourActivityCustomer?.meetingPlace}
              />
              <FieldLabel
                verticalDisplay
                className="me-5 mb-2"
                label={intl.formatMessage({ id: "tour_activity_optional_field" })}
                value={optionUtils.translateOption(intl, YES_NO_OPTIONS, tourActivityCustomer?.optional)}
              />
            </div>
            <div>
              <FieldLabel
                verticalDisplay
                className="me-5 white-space-pre-wrap"
                label={intl.formatMessage({ id: "tour_activity_description_field" })}
                value={tourActivityCustomer?.description}
              />
            </div>
          </div>

          {customer ? (
            <>
              <div className="mb-2 d-flex flex-column">
                <span className="text-muted"><FormattedMessage id="action_button"/></span>
                <div className="d-flex flex-row">
                  <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => setOpenModalDeleteActivityForCustomer(true)}/>
                  <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => setLateralPanelActivityInTourForCustomer(true)}/>
                </div>
              </div>
              {lateralPanelActivityInTourForCustomer && (
                <Panel formId="createActivityInTourForCustomerForm" title={intl.formatMessage({id: "add_activity_in_tour_for_customer"}, {customer: customer?.name, activity: activityName})} open={lateralPanelActivityInTourForCustomer} onCancel={() => setLateralPanelActivityInTourForCustomer(false)}>
                  <CreateActivityInTourForCustomerForm
                    id="createActivityInTourForCustomerForm"
                    tourActivityCustomer={{...tourActivityCustomer, optional: tourActivityCustomer?.optional ? "true": "false"}}
                    customerId={customer?.id}
                    onValidate={handleValidateActivityInTourForCustomer}
                  />
                </Panel>
              )}
            </>
          ) : (
            <div className="mb-2 d-flex flex-column">
              <span className="text-muted"><FormattedMessage id="action_button"/></span>
              <div className="d-flex flex-row">
                <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => setOpenModalDeleteActivity(true)}/>
                <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => setLateralPanelTourActivity(true)}/>
              </div>
              <div className={`${lateralPanelTourActivity ? "backdrop": "d-none"}`}></div>
              {lateralPanelTourActivity && (
                <Panel formId="editTourActivityForm" title="edit_tour_activity" open={lateralPanelTourActivity} onCancel={() => setLateralPanelTourActivity(false)}>
                  <CreateTourActivityForm
                    id="editTourActivityForm"
                    seasonId={tourActivityCustomer?.season?.id}
                    tourActivityInitial={{
                      ...tourActivityCustomer,
                      activityId: tourActivityCustomer?.activityId,
                      optional: tourActivityCustomer?.optional ? "true" : "false"
                    }}
                    onValidate={handleValidateTourActivity}
                    departureTimeMinuteDelta={departureTimeMinuteDelta}
                  />
                </Panel>
              )}
            </div>
          )}
          <GenericModalDelete
            titleId="modal_title_delete_activity"
            open={openModalDeleteActivity}
            onValidate={handleModalDeleteActivity}
            setOpen={setOpenModalDeleteActivity}
            idEntity={tourActivityCustomer?.id}
            onDelete={tourService.deleteTourActivity}
            successToastMessageId="success_toast_delete_tour_activity"
            errorToastMessageId="error_toast_delete_tour_activity"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_activity"}, {activity: tourActivityCustomer?.activityName})}
          />

          <GenericModalDelete
            titleId="modal_title_delete_activity"
            open={openModalDeleteActivityForCustomer}
            onValidate={handleModalDeleteActivity}
            setOpen={setOpenModalDeleteActivityForCustomer}
            idEntity={tourActivityCustomer?.id}
            onDelete={tourService.deleteTourActivityCustomer}
            successToastMessageId="success_toast_delete_tour_activity"
            errorToastMessageId="error_toast_delete_tour_activity"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_activity"}, {activity: tourActivityCustomer?.activityName})}
          />

        </div>
      </Accordion>
    </div>
  )
}

export default ActivityInTourCard
