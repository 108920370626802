import {IntlShape} from "react-intl";
import {Option} from "../interfaces/inputs/OptionInterfaces";
import {useEffect, useState} from "react";
import {optionUtils} from "../utils/optionUtils";

export function useIntlOptions (intl: IntlShape, options: Option<string>[]): Option<string>[] {
  const [intlOptions, setIntlOptions] = useState<Option<string>[]>([])
  useEffect(() => {
    setIntlOptions(optionUtils.formatOptions(intl, options))
  }, [options])
  return intlOptions
}