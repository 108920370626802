import React, {FunctionComponent} from "react";
import TextLink from "../../atoms/TextLink";
import Title from "../../atoms/Title";
import {TitleSizeType} from "../../../types/bootstrap/BootstrapType";

interface BreadcrumbItem {
  title: string
  to: string
}

interface PageTitleProps {
  className?: string,
  pageTitle: string,
  breadcrumbItems?: BreadcrumbItem[]
}

const PageTitle: FunctionComponent<PageTitleProps> = ({
  className= "",
  pageTitle,
  breadcrumbItems= []
}) => {

  return (
    <div className={`page-title d-flex align-items-center ${className}`}>
      {breadcrumbItems.map(breadcrumbItem => (
        <React.Fragment key={breadcrumbItem.title}>
          <TextLink to={breadcrumbItem.to}><>{breadcrumbItem.title}</></TextLink>
          <span className="mx-2"> {">"} </span>
        </React.Fragment>
      ))}
      <Title size={TitleSizeType.H4} title={pageTitle}/>
    </div>
  )
}

export default PageTitle