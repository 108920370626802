import {
  ActivityResourceCatalog,
  ActivitySellingPriceCatalog,
  ActivityFormData, ActivityFormFields
} from "../interfaces/ActivityInterfaces";
import {UNIT_TYPE} from "../interfaces/OrderItemInterfaces";

export const ACTIVITY_INITIAL_VALUES: ActivityFormData = {
  name: "",
  reference: "",
  billingName: "",
  duration: undefined,
  numberPaxMax: undefined,
  activityAreaId: "",
  staffingManagement: true,
  price: undefined,
  forKids: false,
  showActivityTourLeader: true,
  unitType: UNIT_TYPE.PAX,
}

export const ACTIVITY_SERVICES_INITIAL_VALUES: ActivityFormFields = {
  activityServicesId: []
}

export const ACTIVITY_RESOURCE_CUSTOMER_INITIAL_VALUES: ActivityResourceCatalog = {
  startDateValidity: null,
  juniorEmployeeRate: null,
  juniorIndependentRate: null,
  seniorEmployeeRate: null,
  seniorIndependentRate: null,
}

export const ACTIVITY_SELLING_PRICE_CUSTOMER_INITIAL_VALUES: ActivitySellingPriceCatalog = {
  unitType: "",
  startDateValidity: null,
  sellingPrice: null,
  description: "",
}
