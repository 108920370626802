import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {Supplier} from "../../../../interfaces/SupplierInterfaces";
import FieldLabel from "../../../atoms/FieldLabel";
import TextLink from "../../../atoms/TextLink";
import {SUPPLIERS_PATH} from "../../../../constants/routes/RoutePaths";
import {optionUtils} from "../../../../utils/optionUtils";
import {UNIT_TYPE_OPTIONS} from "../../../../constants/OptionConstants";
import {numberUtils} from "../../../../utils/numberUtils";

interface ServiceCardProps {
  className?: string,
  service: Service,
}

const ServiceCard: FunctionComponent<ServiceCardProps> = ({
  className = "",
  service,
}) => {
  const intl = useIntl()

  const renderSuppliers = () => (
    <div className="d-flex flex-column">
      {service?.suppliersForService?.map((supplier: Supplier) => (
        <TextLink key={supplier.id} to={`${SUPPLIERS_PATH}/${supplier.id}`}>
          <>{supplier.name}</>
        </TextLink>
      ))}
    </div>
  )

  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-3">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_name"})}
          value={service?.name}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_reference"})}
          value={service?.reference}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_description"})}
          value={service?.description}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_number_pax"})}
          value={service?.numberPaxMax}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_default_unit"})}
          value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, service?.defaultUnitType)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_default_price"})}
          value={numberUtils.formatPrice(service?.defaultPrice)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_default_sell_unit"})}
          value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, service?.defaultSellUnitType)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_default_sell_price"})}
          value={numberUtils.formatPrice(service?.defaultSellPrice)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "suppliers_for_service"})}
          value={renderSuppliers()}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "service_vat_rate"})}
          value={numberUtils.numberToPercent(service?.vatRate?.rate)}
        />
      </div>
    </div>
  )
}

export default ServiceCard;
