import React, {FunctionComponent, useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {ColorType, ColorTypeValues, SizeType} from "../../../types/bootstrap/BootstrapType";

interface ButtonGroupProps {
  title: string,
  color?: ColorTypeValues
  children: React.ReactNode
  className?: string
  loading?: boolean
}

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  color = ColorType.SECONDARY,
  title,
  children,
  className,
  loading
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className={className}>
      <DropdownToggle color={color} size={SizeType.MD} className="epow-button rounded-1" caret>
        {title}
        {loading && <span className="loader loader-button-group ms-1"/>}
      </DropdownToggle>
      <DropdownMenu>
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}


export default ButtonGroup;
