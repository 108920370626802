import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {activityService} from "../../../../services/ActivityService";
import {toastUtils} from "../../../../utils/toastUtils";
import GenericModal from "../GenericModal";

interface ModalActivityDeleteServiceProps {
  className?: string,
  modalOpen: boolean,
  setModalOpen: (open: boolean) => void,
  onDelete: () => void,
  activityId: string,
  serviceId: string
}

const ModalActivityDeleteService: FunctionComponent<ModalActivityDeleteServiceProps> = ({
  className = "",
  modalOpen,
  setModalOpen,
  onDelete,
  activityId,
  serviceId,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const deleteActivityService = () => {
    activityService.deleteActivityService(activityId, serviceId)
      .then(() => {
        onDelete();
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_activity_service"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_activity_service"}))
      })
      .finally(() => {
        setModalOpen(false)
      })
  }

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({id: "modal_title_delete_activity_service"})}
      open={modalOpen}
      onOpen={setModalOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => deleteActivityService()
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: () => setModalOpen(false)
        }
      }}
    >
      {intl.formatMessage({id: "modal_body_delete_activity_service"})}
    </GenericModal>
  )
}

export default ModalActivityDeleteService;
