import {API_BILLING_SERVICE_PATH} from "../constants/routes/RoutePaths"
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface"
import {BillingServiceSearchObject, Customer} from "../interfaces/CustomerInterfaces"
import {queryUtils} from "../utils/queryUtils"
import {httpService} from "./HttpService"


function getBillingServicePage(params: PaginationQueryParams, filter: BillingServiceSearchObject): Promise<PageResponse<Customer>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<Customer>>(`${API_BILLING_SERVICE_PATH}/page${queryParams}`)
}

export const customerBillingServiceService = Object.freeze({
  getBillingServicePage,
})