import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import SupplierList from "components/molecules/list/SupplierList";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import useSearchFilter from "../hooks/useSearchFilter";
import {CustomerSearchObject} from "../interfaces/CustomerInterfaces";
import Panel from "../components/molecules/panel/Panel";
import CreateSupplierForm from "../components/molecules/form/CreateSupplierForm";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const SupplierListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const {searchObject, onReset, updateSearchField} = useSearchFilter<CustomerSearchObject>({
    name: "",
    currentState: WorkflowStatesEnum.ACTIVE
  });
  const [lateralPanelSupplier, setLateralPanelSupplier] = useState<boolean>(false)
  const {loading, startLoading, stopLoading} = useLoading()

  return (
      <Layout>
          <div className="d-flex flex-column epow-content-body">
            <CommonEntityHeader
              pageTitle={intl.formatMessage({id: "supplier_title"})}
              updateSearchField={updateSearchField}
              setCreationPanelOpen={setLateralPanelSupplier}
              creationButtonLabel={intl.formatMessage({id: "create_new_supplier"})}
            />

            <SupplierList
              searchObject={searchObject}
              onReset={() => onReset({currentState: searchObject.currentState})}
              updateSearchField={updateSearchField}
            />

            {lateralPanelSupplier && (
              <Panel loading={loading} formId="createSupplierForm" title="create_new_supplier" open={lateralPanelSupplier}
                     onCancel={() => setLateralPanelSupplier(false)}>
                <CreateSupplierForm startLoading={startLoading} stopLoading={stopLoading} id="createSupplierForm"/>
              </Panel>
            )}

          </div>
      </Layout>
  )
}

export default SupplierListView
