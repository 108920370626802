import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {FileType} from "../../../../constants/FileConstants";
import {ResourceLegalInfo} from "../../../../interfaces/ResourceInterfaces";
import {resourceService} from "../../../../services/ResourceService";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import Button from "../../../atoms/Button";
import CardFileField from "../CardFileField";
import FieldLabel from "../../../atoms/FieldLabel";

interface LegalInfoResourceCardProps {
  className?: string;
  resourceLegalInfo?: ResourceLegalInfo;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
}

const LegalInfoResourceCard: FunctionComponent<LegalInfoResourceCardProps> = ({
  className = "",
  resourceLegalInfo,
  editInfo,
  setEditInfo
}) => {
  const intl = useIntl();

  const downloadLiabilityCard = () => {
    fileUtils.downloadFile(
      resourceService.getCard(resourceLegalInfo.id, FileType.LIABILITY_CARD),
      resourceLegalInfo.liabilityCard,
      intl
    );
  }

  const downloadUrssafCard = () => {
    fileUtils.downloadFile(
      resourceService.getCard(resourceLegalInfo.id, FileType.URSSAF_CARD),
      resourceLegalInfo.urssafCard,
      intl
    );
  }

  return (
    <div className={className}>
      <Row>
        <Col xs={24} md={8}>
          <div className="d-flex flex-column gap-3 mb-3">
            <CardFileField
              label={intl.formatMessage({ id: "resource_public_liability_card" })}
              value={fileUtils.getCompleteFileName(resourceLegalInfo?.liabilityCard)}
              onClick={downloadLiabilityCard}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_rcp_cover_start_date" })}
              value={dateUtils.formatDateToLocalDateTime(
                dateUtils.parseDate(resourceLegalInfo?.rcpCoverStartDate),
                false
              )}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_rcp_validity_date" })}
              value={dateUtils.formatDateToLocalDateTime(
                dateUtils.parseDate(resourceLegalInfo?.rcpValidityDate),
                false
              )}
            />
          </div>
        </Col>

        <Col xs={24} md={8}>
          <div className="d-flex flex-column gap-3 mb-3">
            <CardFileField
              label={intl.formatMessage({ id: "resource_urssaf_card" })}
              value={fileUtils.getCompleteFileName(resourceLegalInfo?.urssafCard)}
              onClick={downloadUrssafCard}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({ id: "resource_urssaf_situation_date" })}
              value={dateUtils.formatDateToLocalDateTime(
                dateUtils.parseDate(resourceLegalInfo?.urssafSituationDate),
                false
              )}
            />
          </div>
        </Col>
      </Row>

      <div>
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setEditInfo(!editInfo)}
          className="extended"
        >
          <FormattedMessage id="edit_button" />
        </Button>
      </div>
    </div>
  )
}

export default LegalInfoResourceCard;
