import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import {toastUtils} from "utils/toastUtils";
import {tourLeaderService} from "services/TourLeaderService";
import {TourLeader} from "../interfaces/TourLeaderInterfaces";
import {WorkflowActionsEnum} from "../constants/workflow/WorkflowStates";
import useTourLeaderWorkflow from "../hooks/resource/useTourLeaderWorkflow";
import TourLeaderTemplateView from "./TourLeaderViewTemplate";
import {ApiError} from "../interfaces/ErrorInterfaces";


const TourLeaderView: FunctionComponent<RouteProps> = () => {
  const {id} = useParams();

  const [tourLeader, setTourLeader] = useState<TourLeader>(null)

  const getTourLeader = () => {
    tourLeaderService.getTourLeader(id)
      .then((tourLeader) => {
        setTourLeader(tourLeader)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  const {
    handleDeleteButton,
    handleReactivateButton,
    handleDeactivateButton,
    handleActivateButton
  } = useTourLeaderWorkflow(tourLeader, (tl) => {
    setTourLeader(tl)
    getTourLeader()
  })


  useEffect(() => {
    getTourLeader()
  }, [])

  return (
    <TourLeaderTemplateView onChangeTourLeader={setTourLeader}
                            tourLeader={tourLeader}
                            onRefresh={getTourLeader}
                            workflowActions={{
                              [WorkflowActionsEnum.DELETE]: handleDeleteButton,
                              [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
                              [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
                              [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton,
                            }}/>
  )
}

export default TourLeaderView
