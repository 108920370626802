import {FormikErrors, FormikTouched} from "formik";
import React, {FunctionComponent, useCallback, useEffect} from "react";
import {UNIT_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import {Order} from "../../../../interfaces/OrderInterfaces";
import {
  ORDER_ITEM_TYPE,
  OrderItemRequest,
  OrderItemResourceDTO,
  UNIT_TYPE
} from "../../../../interfaces/OrderItemInterfaces";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import FormInput from "../../input/FormInput";
import FormRadio from "../../input/FormRadio";
import {numberUtils} from "../../../../utils/numberUtils";
import {customerBillingServiceService} from "../../../../services/CustomerBillingServiceService";
import FormAutocompleteSelectPageable from "../../input/FormAutocompleteSelectPageable";
import {useIntl} from "react-intl";
import PriceInput from "../../input/PriceInput";
import {Col, Row} from "reactstrap";
import {catalogActivityCustomerService} from "../../../../services/CatalogActivityCustomerService";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import {toastUtils} from "../../../../utils/toastUtils";
import {catalogCustomerServiceService} from "../../../../services/CatalogCustomerServiceService";

interface CreateOrderItemFormStepTwoProps {
  className?: string,
  errors: FormikErrors<OrderItemRequest>,
  touched: FormikTouched<OrderItemRequest>,
  values: OrderItemRequest,
  setFieldValue: FormikFieldSetter,
  order?: Order,
  selectedActivity: Activity,
  selectedService: Service,
  initialOrderItemResources: OrderItemResourceDTO[];
}

const CreateOrderItemFormStepTwo: FunctionComponent<CreateOrderItemFormStepTwoProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  order,
  selectedActivity,
  selectedService,
  initialOrderItemResources
}) => {
  const intl = useIntl();
  const paxMax = values.type === ORDER_ITEM_TYPE.ACTIVITY ? selectedActivity?.numberPaxMax : selectedService?.numberPaxMax

  const onChangeSellPrice = (value: number) => {
    setFieldValue("sellPrice", value)
    setFieldValue("sellPriceTVA", value * 1.20)
  }

  const onChangePaxPrice = (value: number) => {
    setFieldValue("paxPrice", value)
    onChangeSellPrice(values?.quantity * value)
  }

  const onChangeQuantity = (value: number) => {
    setFieldValue("quantity", value)
    onChangeSellPrice(value * values?.paxPrice)
  }

  const updateNumberOfResource = (pax: number, orderItemResources: OrderItemResourceDTO[]) => {
    const isActivity = values.type === ORDER_ITEM_TYPE.ACTIVITY;
    const requiredLotNumber = paxMax ? Math.ceil(pax / (paxMax)) : 0;

    if (values.isStaffingManagement?.toString() === "true" && isActivity) {
      orderItemResources?.map((orderItemResource, index) => {
        setFieldValue(`orderItemResources.${index}.quantity`, orderItemResource.quantity * requiredLotNumber)
      })
    }

    return requiredLotNumber
  }

  const updatePaxRelatedFields = (value: number, orderItemResources: OrderItemResourceDTO[]) => {
    const requiredLotNumber = updateNumberOfResource(value, orderItemResources);
    if (values.unitType === UNIT_TYPE.PAX) {
      onChangeQuantity(value)
    } else {
      onChangeQuantity(requiredLotNumber)
    }
  }

  const onChangePax = (value: number, orderItemResources: OrderItemResourceDTO[]) => {
    setFieldValue("pax", value)
    updatePaxRelatedFields(value, orderItemResources)
  }

  const onChangeUnitTypePrice = (value: string) => {
    if (value === UNIT_TYPE.PAX && values?.unitType === UNIT_TYPE.FIXED_RATE) {
      const newQuantity = (paxMax ?? values?.pax) * values?.quantity
      setFieldValue("quantity", newQuantity)
      onChangeSellPrice(newQuantity * values?.paxPrice)

    } else if (values?.unitType === UNIT_TYPE.PAX && value === UNIT_TYPE.FIXED_RATE) {
      const quantity = Math.ceil(values?.quantity / (paxMax ?? values?.pax));
      setFieldValue("quantity", quantity)
      onChangeSellPrice(quantity * values?.paxPrice)
    }

    setFieldValue("unitType", value)
  }

  const getPriceForActivity = (activity: Activity, customerId: string) => {
    catalogActivityCustomerService.getInfosForActivityCustomer(activity.id, customerId || null, order.beginTourDate)
      .then(sellInfos => setFieldValue("paxPrice", sellInfos.sellingPrice))
      .catch((error: ApiError) => toastUtils.errorToast(error.message))
  }

  const getPriceForService = (service: Service, customerId: string) => {
    catalogCustomerServiceService.getPriceForCustomerService(service.id, customerId, order.beginTourDate)
      .then(price => setFieldValue("paxPrice", price))
      .catch((error: ApiError) => toastUtils.errorToast(error.message))
  }

  const onChangeBillingService = (billingServiceId: string) => {
    setFieldValue("billingService", billingServiceId)
    if (billingServiceId) {
      if (values.type === ORDER_ITEM_TYPE.ACTIVITY) {
        getPriceForActivity(selectedActivity, billingServiceId)
      } else {
        getPriceForService(selectedService, billingServiceId)
      }
    }
  }

  // use useCallback to avoid re-rendering the form when component update
  const fetchBillingServices = useCallback((page, filter) => {
    return customerBillingServiceService.getBillingServicePage(page, {...filter, customerId: order.customer.id})
  }, [order.customer.id]);

  useEffect(() => {
    onChangePax((values.pax || paxMax), values.id ? [] : initialOrderItemResources)
  }, [])

  return (
    <div className={className}>

      <Row>
        <Col xs={24} sm={12}>
          <FormInput
            id="pax"
            label="order_item_pax"
            type="number"
            value={values?.pax}
            onChange={(e) => onChangePax(Number(e.target.value), initialOrderItemResources)}
            required
            error={errors.pax}
            touched={touched.pax}
          />
        </Col>
        <Col xs={24} sm={12}>
          <FormRadio
            id="isPaxConfirmed"
            label="order_item_pax_confirmed"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.isPaxConfirmed?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("isPaxConfirmed", e.value)}
            required
            error={errors.isPaxConfirmed}
            touched={touched.isPaxConfirmed}
          />
        </Col>
      </Row>

      {values.isStaffingManagement?.toString() === "true" && (
        values.orderItemResources?.map((orderItemResource, index) => (
          <FormInput
            id={`numberOf${index}`}
            key={`numberOf${index}`}
            label="order_item_number_of_resources"
            labelValues={{label: orderItemResource.resourceType?.label?.toLowerCase()}}
            type="number"
            value={orderItemResource?.quantity}
            onChange={(e) => setFieldValue(`orderItemResources.${index}.quantity`, e.target.value)}
            required
            error={errors[`numberOf${index}`]}
            touched={touched[`numberOf${index}`]}
          />
        ))
      )}

      <FormRadio
        id="unitType"
        label="order_item_unit_type"
        value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
        options={UNIT_TYPE_OPTIONS}
        onChange={(e) => onChangeUnitTypePrice(e.value)}
        required
        error={errors.unitType}
        touched={touched.unitType}
      />

      <Row>
        <Col xs={24} sm={12}>
          <FormInput
            id="quantity"
            label="order_item_quantity"
            type="number"
            value={values?.quantity}
            onChange={(e) => onChangeQuantity(Number(e.target.value))}
            required
            error={errors.quantity}
            touched={touched.quantity}
          />
        </Col>
        <Col xs={24} sm={12}>
          <PriceInput
            id="paxPrice"
            label="order_item_pax_price"
            value={values?.paxPrice}
            onChange={(e) => onChangePaxPrice(Number(e.target.value))}
            error={errors.paxPrice}
            touched={touched.paxPrice}
            required
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} sm={8}>
          <FormInput
            id="sellPrice"
            label="order_item_sell_price"
            type="number"
            value={numberUtils.formatPrice(values?.quantity * values?.paxPrice)}
            onChange={(e) => onChangeSellPrice(Number(e.target?.value))}
            error={errors.sellPrice}
            touched={touched.sellPrice}
            required
            disabled
          />
        </Col>
        <Col xs={24} sm={8}>
          <FormInput
            id="sellPriceTVA"
            label="order_item_sell_price_tva"
            type="number"
            value={numberUtils.formatPrice(values?.quantity * values?.paxPrice * 0.20)}
            disabled
          />
        </Col>
        <Col xs={24} sm={8}>
          <FormInput
            id="total"
            label="order_item_sell_price_total"
            type="number"
            value={numberUtils.formatPrice(values?.quantity * values?.paxPrice * 1.20)}
            disabled
          />
        </Col>
      </Row>

      <FormAutocompleteSelectPageable
        id="billingService"
        label={intl.formatMessage({id: "order_item_billing_service"})}
        placeholder={intl.formatMessage({id: "order_item_billing_service"})}
        value={values?.billingService}
        onChange={(value) => onChangeBillingService(value)}
        error={errors?.billingService}
        touched={touched?.billingService}
        required
        fetchData={fetchBillingServices}
        filterFieldName="name"
      />

      <FormRadio
        id="disbursements"
        label="order_item_disbursements"
        value={YES_NO_OPTIONS.find((option) => option?.value === values?.disbursements?.toString())}
        options={YES_NO_OPTIONS}
        onChange={(e) => setFieldValue("disbursements", e.value)}
        required
        error={errors.disbursements}
        touched={touched.disbursements}
      />
    </div>
  )
}

export default CreateOrderItemFormStepTwo;
