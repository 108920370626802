/**
 * File listing every route of the application
 */
import React from "react";
import HomeView from "../../views/HomeView";
import {
  ACTIVITIES_PATH,
  ACTIVITY_PATH, INVOICE_PATH,
  INVOICES_PATH,
  BOOKINGS_PATH,
  ORDER_PATH,
  CUSTOMER_PATH,
  CUSTOMERS_PATH,
  FORGOTTEN_PASSWORD_PATH,
  HOME_PATH,
  LOGIN_PATH,
  ORDERS_IMPORT_PATH,
  ORDERS_PATH,
  PURCHASE_PATH,
  PURCHASES_PATH,
  RESET_PASSWORD_PATH,
  RESOURCE_PATH,
  RESOURCES_PATH,
  SERVICE_PATH,
  SERVICES_PATH,
  STAFFING_PATH,
  STAFFING_VALIDATION_PATH,
  SUPPLIER_PATH,
  SUPPLIERS_PATH,
  TOUR_LEADER_PATH,
  TOUR_LEADERS_PATH,
  TOUR_PATH,
  TOURS_PATH,
  ADMIN_PATH,
  BILLING_PATH,
  DOCUMENTARY_BASE_PATH,
  STAFF_REQUEST_PATH,
  ACTIVITIES_BILLING_PATH,
  SELF_PROFILE_PATH,
  PROFILE_PATH,
  CREDIT_NOTE_PATH,
  BILLING_OVERVIEW_PATH,
  SUPPLIER_PURCHASE_PATH,
  ORDERS_FORM_PATH,
  QUOTES_PATH,
  QUOTE_PATH,
  RESOURCE_AVAILABILITY_PATH,
  MY_TOURS_PATH,
  MY_TOUR_PATH, STAFFING_CALENDAR_PATH, SELF_BILLING_PATH,
} from "./RoutePaths";
import {
  AdminProfileGuard,
  AuthGuard,
  CustomerProfileGuard, InterneOrCustomerProfileGuard, InterneProfileGuard, ProfileGuard,
  ResourceValidatedProfileGuard, RessourceProfileGuard,
  TourProfileGuard
} from "./Guards";
import {AppRouteInterface} from "../../interfaces/routes/RouteInterfaces";
import LoginView from "../../views/LoginView";
import CustomerListView from "../../views/CustomerListView";
import TourListView from "../../views/TourListView";
import TourView from "../../views/TourView";
import ActivityListView from "../../views/ActivityListView";
import ActivityView from "../../views/ActivityView";
import ServiceListView from "../../views/ServiceListView";
import ServiceView from "../../views/ServiceView";
import SupplierListView from "../../views/SupplierListView";
import SupplierView from "../../views/SupplierView";
import TourLeaderListView from "../../views/TourLeaderListView";
import TourLeaderView from "../../views/TourLeaderView";
import ResourceView from "../../views/ResourceView";
import ResourceListView from "../../views/ResourceListView";
import CustomerView from "../../views/CustomerView";
import OrderView from "../../views/OrderView";
import OrderListView from "../../views/OrderListView";
import OrderCSVView from "../../views/OrderCSVView";
import StaffingView from "../../views/staffing/StaffingView";
import ForgottenPasswordView from "../../views/ForgottenPasswordView";
import ResetPasswordView from "../../views/ResetPasswordView";
import StaffingValidationView from "../../views/staffing/StaffingValidationView";
import PreBillingListView from "../../views/billing/PreBillingListView";
import BookingListView from "../../views/BookingListView";
import PurchaseListView from "../../views/PurchaseListView";
import PurchaseView from "../../views/PurchaseView";
import AdminView from "../../views/AdminView";
import BillingView from "../../views/billing/BillingView";
import BillingListView from "../../views/billing/BillingListView";
import DocumentaryBaseView from "../../views/DocumentaryBaseView";
import StaffingRequestView from "../../views/staffing/StaffingRequestView";
import ActivitiesBillingView from "../../views/ActivitiesBillingView";
import SelfProfileView from "../../views/SelfProfileView";
import ProfileDispatcher from "../../views/profile/ProfileDispatcher";
import BillingOverviewView from "../../views/billing/BillingOverviewView";
import CreditNoteView from "../../views/billing/CreditNoteView";
import SupplierPurchaseListView from "../../views/SupplierPurchaseListView";
import OrderFormListView from "../../views/OrderFormListView";
import QuoteListView from "../../views/quote/QuoteListView";
import QuoteView from "../../views/quote/QuoteView";
import ResourceAvailabilityView from "../../views/ResourceAvailabilityView";
import StaffingCalendarView from "../../views/StaffingCalendarView";
import CustomerBillingListView from "../../views/billing/CustomerBillingListView";
import RoadBookView from "../../views/RoadBookView";

export const AppRoutes: AppRouteInterface[] = [
  {
    paths: [LOGIN_PATH],
    guards: [],
    element: <LoginView />,
  },
  {
    paths: [PROFILE_PATH],
    guards: [ProfileGuard],
    element: <ProfileDispatcher />,
  },
  {
    paths: [SELF_PROFILE_PATH],
    guards: [AuthGuard],
    element: <SelfProfileView />,
  },
  {
    paths: [FORGOTTEN_PASSWORD_PATH],
    guards: [],
    element: <ForgottenPasswordView />,
  },
  {
    paths: [RESET_PASSWORD_PATH],
    guards: [],
    element: <ResetPasswordView />,
  },
  {
    paths: [HOME_PATH],
    guards: [AuthGuard],
    element: <HomeView />,
  },
  {
    paths: [STAFFING_CALENDAR_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <StaffingCalendarView />,
  },
  {
    paths: [CUSTOMERS_PATH],
    guards: [AuthGuard, CustomerProfileGuard],
    element: <CustomerListView />
  },
  {
    paths: [BILLING_PATH],
    guards: [InterneProfileGuard],
    element: <PreBillingListView />
  },
  {
    paths: [SELF_BILLING_PATH],
    guards: [AuthGuard, CustomerProfileGuard],
    element: <CustomerBillingListView />
  },
  {
    paths: [INVOICES_PATH],
    guards: [InterneProfileGuard],
    element: <BillingListView />
  },
  {
    paths: [INVOICE_PATH],
    guards: [InterneOrCustomerProfileGuard],
    element: <BillingView />
  },
  {
    paths: [CREDIT_NOTE_PATH],
    guards: [InterneProfileGuard],
    element: <CreditNoteView />
  },
  {
    paths: [TOURS_PATH],
    guards: [AuthGuard, TourProfileGuard],
    element: <TourListView />
  },
  {
    paths: [TOUR_PATH],
    guards: [AuthGuard, TourProfileGuard],
    element: <TourView />
  },
  {
    paths: [ACTIVITIES_PATH],
    guards: [AuthGuard],
    element: <ActivityListView />
  },
  {
    paths: [ACTIVITY_PATH],
    guards: [AuthGuard],
    element: <ActivityView />
  },
  {
    paths: [SERVICES_PATH],
    guards: [AuthGuard],
    element: <ServiceListView />
  },
  {
    paths: [SERVICE_PATH],
    guards: [AuthGuard],
    element: <ServiceView />
  },
  {
    paths: [SUPPLIERS_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <SupplierListView />
  },
  {
    paths: [SUPPLIER_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <SupplierView />
  },
  {
    paths: [TOUR_LEADERS_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <TourLeaderListView />
  },
  {
    paths: [TOUR_LEADER_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <TourLeaderView />
  },
  {
    paths: [ACTIVITIES_BILLING_PATH],
    guards: [AuthGuard, ResourceValidatedProfileGuard],
    element: <ActivitiesBillingView />
  },
  {
    paths: [RESOURCE_AVAILABILITY_PATH],
    guards: [AuthGuard, ResourceValidatedProfileGuard],
    element: <ResourceAvailabilityView />
  },
  {
    paths: [RESOURCES_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <ResourceListView />
  },
  {
    paths: [RESOURCE_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <ResourceView />
  },
  {
    paths: [CUSTOMER_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <CustomerView />
  },
  {
    paths: [QUOTES_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <QuoteListView />
  },
  {
    paths: [QUOTE_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <QuoteView />
  },
  {
    paths: [ORDER_PATH],
    guards: [AuthGuard],
    element: <OrderView />
  },
  {
    paths: [MY_TOUR_PATH],
    guards: [AuthGuard],
    element: <RoadBookView title="menu_order_tour" path={MY_TOURS_PATH} />
  },
  {
    paths: [ORDERS_FORM_PATH],
    guards: [AuthGuard],
    element: <OrderFormListView />
  },
  {
    paths: [ORDERS_PATH],
    guards: [AuthGuard],
    element: <OrderListView />
  },
  {
    paths: [MY_TOURS_PATH],
    guards: [AuthGuard],
    element: <OrderListView title="menu_order_tour" itemPrefixPath={MY_TOURS_PATH}/>
  },
  {
    paths: [ORDERS_IMPORT_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <OrderCSVView />
  },
  {
    paths: [STAFFING_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <StaffingView />
  },
  {
    paths: [BILLING_OVERVIEW_PATH],
    guards: [AuthGuard, InterneProfileGuard],
    element: <BillingOverviewView />
  },
  {
    paths: [STAFFING_VALIDATION_PATH],
    guards: [],
    element: <StaffingValidationView />
  },
  {
    paths: [BOOKINGS_PATH],
    guards: [AuthGuard],
    element: <BookingListView />
  },
  {
    paths: [PURCHASES_PATH],
    guards: [AuthGuard],
    element: <PurchaseListView />
  },
  {
    paths: [SUPPLIER_PURCHASE_PATH],
    guards: [AuthGuard],
    element: <SupplierPurchaseListView />
  },
  {
    paths: [STAFF_REQUEST_PATH],
    guards: [RessourceProfileGuard, ResourceValidatedProfileGuard],
    element: <StaffingRequestView />
  },
  {
    paths: [PURCHASE_PATH],
    guards: [AuthGuard],
    element: <PurchaseView />
  },
  {
    paths: [ADMIN_PATH],
    guards: [AuthGuard, AdminProfileGuard],
    element: <AdminView />
  },
  {
    paths: [DOCUMENTARY_BASE_PATH],
    guards: [AuthGuard, ResourceValidatedProfileGuard],
    element: <DocumentaryBaseView />
  },
];
