import React, {FunctionComponent, useMemo} from "react";
import {fileService} from "../../../../services/FileService";
import CustomTable, {CustomTableRef} from "../CustomTable";
import {CustomTableColumn, CustomTableType} from "../../../../interfaces/TableInterfaces";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {DocumentDTO, DocumentSearchObject, DocumentsByTheme} from "../../../../interfaces/FileInterfaces";
import Pagination from "../../pagination/Pagination";
import _, {Dictionary} from "lodash";
import DocumentTableRowTheme from "./DocumentTableRowTheme";

interface DocumentTableProps {
  className?: string,
  isAdmin?: boolean,
  searchObject: DocumentSearchObject,
  DocumentRowComponent: FunctionComponent<{document: DocumentDTO}>,
}

const DocumentTable: FunctionComponent<DocumentTableProps> = ({
  className = "",
  isAdmin = false,
  searchObject,
  DocumentRowComponent,
}) => {
  const ref = React.useRef<CustomTableRef>();

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total
  } = usePaginatedData(fileService.getMyDocuments, searchObject)

  const columns: CustomTableColumn[] = useMemo(() => isAdmin ?
    [
      {title: "documentary_base_table_column_name", accessor: "name", sortable: true},
      {title: "documentary_base_table_column_activities", accessor: "activities", sortable: true},
      {title: "documentary_base_table_column_profiles", accessor: "profiles", sortable: true},
      {title: "table_actions", accessor: "actions", sortable: false, className: "col-2"},
    ] : [
      {title: "documentary_base_table_column_name", accessor: "name", sortable: true},
      {title: "documentary_base_table_column_activities", accessor: "activities", sortable: true},
    ], [])

  const groupedData: Dictionary<DocumentDTO[]> = _.groupBy(data, "theme");
  const groupedDataList: DocumentsByTheme[] = Object.keys(groupedData).map(key => ({id: key, documents: groupedData[key]}))

  return (
    <div className={className}>
      <CustomTable
        ref={ref}
        sortOptions={sortOptions}
        onSortOptions={setSortOptions}
        className={`${className}`}
        type={CustomTableType.DOCUMENTS_TABLE}
        datas={groupedDataList}
        columns={columns}
        RowComponent={({data, open, ...rest}) => {
          const documentsByTheme = data as DocumentsByTheme;
          return (
            <>
              <DocumentTableRowTheme theme={documentsByTheme.id} open={!open} {...rest} />
              {(!open && documentsByTheme.documents.length > 0) && documentsByTheme.documents.map((document) =>
                <DocumentRowComponent key={`${documentsByTheme.id}-${document.id}`} document={document} />
              )}
            </>);
        }}
      />

      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default DocumentTable;
