import {Formik} from "formik";
import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../../utils/toastUtils";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";
import { ResourceType } from "interfaces/ResourceTypeInterfaces";
import { resourceTypeService } from "services/ResourceTypeService";
import {HexColorPicker} from "react-colorful";
import {ResourceTypeValidationSchema} from "../../../../constants/validation/ResourceTypeValidationSchema";

interface ResourceTypeFormProps {
  className?: string,
  id?: string,
  color?: string;
  initialResourceType?: ResourceType;
  onCreateResourceType?: () => void;
}

const ResourceTypeForm: FunctionComponent<ResourceTypeFormProps> = ({
  className = "",
  id,
  initialResourceType = null,
  onCreateResourceType = () => null,
}) => {
  const intl = useIntl();

  const [color, setColor] = useState<string>(initialResourceType?.color ?? "#ffffff")

  const initialValues: ResourceType = {
    id: initialResourceType?.id ?? null,
    label: initialResourceType?.label ?? "",
    color: initialResourceType?.color ?? "",
  }

  const handleEditResourceType = (type: ResourceType) => {
    resourceTypeService.updateResourceType(type.id, {...type, color})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_edit_resource_type"}))
        onCreateResourceType()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_edit_resource_type"}))
      })
  }

  const handleCreateResourceType = (newType: ResourceType) => {
    resourceTypeService.createResourceType({...newType, color})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_resource_type"}))
        onCreateResourceType()
      })
      .catch((e: Error) => {
        toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_create_resource_type"})} : ${e.message}`)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ResourceTypeValidationSchema}
      onSubmit={(values) => initialResourceType ? handleEditResourceType(values) : handleCreateResourceType(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="label"
            label="resource_type_label"
            value={values?.label}
            onChange={(e) => setFieldValue("label", e.target.value)}
            required
            error={errors.label}
            touched={touched.label}
            rows="5"
          />
          <FormInput
            id="color"
            label="resource_type_color"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
          <HexColorPicker className="w-100" onChange={setColor} color={color} />
        </EpowForm>
      )}
    </Formik>
  )
}

export default ResourceTypeForm;
