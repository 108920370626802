import React, { FunctionComponent } from "react";
import {TitleSizeType, TitleSizeTypeValues} from "../../types/bootstrap/BootstrapType";

interface TitleProps {
  title: string,
  className?: string,
  size?: TitleSizeTypeValues,
  isCapitalized?: boolean
}

const Title: FunctionComponent<TitleProps> = ({
  title,
  className = "",
  size = TitleSizeType.H2,
  isCapitalized = false
}) => (
  <span className={`epow-title ${className} ${size}`}>
    {isCapitalized ? title.toUpperCase() : title}
  </span>
)


export default Title;
