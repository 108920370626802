import {API_HOME_COMMENT_PATH} from "../constants/routes/RoutePaths"
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface"
import {HomeComment, HomeCommentRequest} from "../interfaces/HomeCommentInterfaces"
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService"

function getHomeComments(params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<HomeComment>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams);
  return httpService.get(`${API_HOME_COMMENT_PATH}${queryParams}`)
}

function getHomeCommentForDate(date: string): Promise<HomeComment> {
  return httpService.get(`${API_HOME_COMMENT_PATH}/${date}`)
}

function createHomeComment(commentRequest: HomeCommentRequest): Promise<HomeComment> {
  return httpService.post(API_HOME_COMMENT_PATH, commentRequest)
}

function updateHomeComment(id: string, commentRequest: HomeCommentRequest): Promise<HomeComment> {
  return httpService.put(`${API_HOME_COMMENT_PATH}/${id}`, commentRequest)
}

export const homeCommentService = Object.freeze({
  getHomeComments,
  getHomeCommentForDate,
  createHomeComment,
  updateHomeComment,
})
