import React, { FunctionComponent, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Col } from "reactstrap";
import { ResourceJobInfo } from "../../../../interfaces/ResourceInterfaces";
import { SizeType, ColorType } from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Title from "../../../atoms/Title";
import EditResourceJobInfoForm from "../../form/resource/EditResourceJobInfoForm";
import JobInfoResourceCard from "./JobInfoResourceCard";

interface JobInfoResourceContainerProps {
  className?: string;
  resourceJobInfo?: ResourceJobInfo;
  setResourceAfterUpdate?: (resourceJobInfo: ResourceJobInfo) => void;
  self: boolean
}

const JobInfoResourceContainer: FunctionComponent<JobInfoResourceContainerProps> = ({
  className = "",
  resourceJobInfo,
  setResourceAfterUpdate = () => null,
  self
}) => {
  const intl = useIntl();
  const [editInfo, setEditInfo] = useState<boolean>(false);

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title title={intl.formatMessage({ id: "resource_job_info" })} size={"h5"} />
      </div>
      {editInfo ? (
        <>
          <EditResourceJobInfoForm
            self={self}
            id="editResourceJobInfoForm"
            initialResourceJobInfo={resourceJobInfo}
            setResourceAfterUpdate={setResourceAfterUpdate}
            setEditInfo={setEditInfo}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form={"editResourceJobInfoForm"}
              type="submit"
              size={SizeType.MD}
              className="extended"
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <JobInfoResourceCard
          resourceJobInfo={resourceJobInfo}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
        />
      )}
    </Col>
  );
};

export default JobInfoResourceContainer;
