import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";
import {moneyUtils} from "../../../utils/moneyUtils";
import {Invoice} from "../../../interfaces/InvoiceInterfaces";

interface BillingInvoiceSummaryDetailsProps {
  className?: string,
  invoice: Invoice,
}

const BillingInvoiceSummaryDetails: FunctionComponent<BillingInvoiceSummaryDetailsProps> = ({
  className = "",
  invoice,
}) => {
  return (
    <div className={className}>
      <Col xs={{offset: 16, size: 8}}>
        <div className="card m-2 p-2">
          <Row className="text-end">
            <Col xs={12} className="fw-bold"><FormattedMessage id="invoice_header_total_ht" /></Col>
            <Col xs={12}>{moneyUtils.formatNumberToCurrency(invoice.amountHT)}</Col>
          </Row>
          <Row className="text-end">
            <Col xs={12} className="fw-bold"><FormattedMessage id="invoice_header_total_tva" /></Col>
            <Col xs={12}>{moneyUtils.formatNumberToCurrency(invoice.amountVAT)}</Col>
          </Row>
          <Row className="text-end">
            <Col xs={12} className="fw-bold"><FormattedMessage id="invoice_header_total_ttc" /></Col>
            <Col xs={12}>{moneyUtils.formatNumberToCurrency(invoice.amountTTC)}</Col>
          </Row>
        </div>
      </Col>
      {invoice.amountDisbursement > 0 &&
        <Col xs={{offset: 16, size: 8}}>
          <div className="card m-2 p-2">
            <Row className="text-end">
              <Col xs={12} className="fw-bold"><FormattedMessage id="invoice_header_total_disbursement" /></Col>
              <Col xs={12}
                className="color-secondary">{moneyUtils.formatNumberToCurrency(invoice.amountDisbursement)}</Col>
            </Row>
            <Row className="text-end">
              <Col xs={12} className="fw-bold"><FormattedMessage id="invoice_header_total" /></Col>
              <Col
                xs={12}>{moneyUtils.formatNumberToCurrency(invoice.amountTTC + invoice.amountDisbursement)}</Col>
            </Row>
          </div>
        </Col>}
    </div>
  )
}

export default BillingInvoiceSummaryDetails;
