import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {PROFILE_OPTIONS, User, UserSearchObject} from "../../../interfaces/UserInterfaces";
import {userService} from "../../../services/UserService";
import {toastUtils} from "../../../utils/toastUtils";
import {tableUtils} from "../../../utils/tableUtils";
import {USER_TABLE} from "../../../constants/Table";
import {FormattedMessage, useIntl} from "react-intl";
import Panel from "../panel/Panel";
import CreateUserForm from "../form/user/CreateUserForm";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import TextFilter from "../filters/TextFilter";
import FilterGroup from "../filters/FilterGroup";
import useSearchFilter from "../../../hooks/useSearchFilter";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import GenericModal from "../modal/GenericModal";
import {stringUtils} from "../../../utils/stringUtils";
import {USER_INITIAL_VALUES} from "../../../constants/user/UserConstants";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import SelectFilter from "../filters/SelectFilter";
import useLoading from "../../../hooks/useLoading";

interface UserListProps {
  className?: string
}

const UserList: FunctionComponent<UserListProps> = ({
  className = ""
}) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl()
  const [lateralPanel, setLateralPanel] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>(null);
  const [modalUser, setModalUser] = useState<User>(null);
  const {loading, stopLoading, startLoading} = useLoading()

  const [toggleModal, setToggleModal] = useState<boolean>(false);

  const {searchObject, onReset ,updateSearchField} = useSearchFilter<UserSearchObject>({
    email: "",
    lastName: "",
    firstName: "",
    profile: null
  })

  const onClickActivated = (user: User) => {
    setModalUser(user)
    setToggleModal(true)
  }

  const onConfirmUserActivation = () => {
    userService.toggleUserActivation(modalUser.id, !modalUser.validated)
      .then((isActivated: boolean) => {
        const toastMessage = isActivated ? "success_toast_activate_user" : "success_toast_deactivate_user"
        toastUtils.successToast(intl.formatMessage({id: toastMessage}))
        ref.current.refresh()
        setToggleModal(false)
        setModalUser(null)
      })
      .catch(() => {
        const toastMessage = !modalUser.validated ? "error_toast_activate_user" : "error_toast_deactivate_user"
        toastUtils.errorToast(intl.formatMessage({id: toastMessage}))
      })
  }
  const onUserEditionSelection = (user: User) => {
    setLateralPanel(true);
    setSelectedUser(user);
  }

  const onEdit = () => {
    setLateralPanel(false);
    ref.current.refresh()
  }

  const columns = useMemo(() => tableUtils.getColumns(USER_TABLE(intl, onClickActivated, onUserEditionSelection)), [intl])
  const fullName = useMemo(() => stringUtils.capitalize(`${modalUser?.firstName} ${modalUser?.lastName}`), [modalUser]);

  const options = YES_NO_OPTIONS.map(opt => ({value: opt.value, label: intl.formatMessage({id: opt.label})}));
  const optionsProfile = PROFILE_OPTIONS.map(opt => ({value: opt.value, label: intl.formatMessage({id: opt.label})}));
  return (
    <>
      <div className="d-flex flex-row-reverse align-items-end flex-wrap">
        <Button onClick={() => {
          setSelectedUser(USER_INITIAL_VALUES)
          setLateralPanel(true)
        }}>{intl.formatMessage({id: "header_menu_add_user"})}</Button>
        <FilterGroup className="gap-2 me-auto">
          <TextFilter
            className="main-filter"
            value={searchObject?.lastName}
            onChange={(value) => updateSearchField("lastName", value)}
            title={intl.formatMessage({id: "resource_last_name_field"})}
          />
          <TextFilter
            className="main-filter"
            value={searchObject?.firstName}
            onChange={(value) => updateSearchField("firstName", value)}
            title={intl.formatMessage({id: "resource_first_name_field"})}
          />
          <TextFilter
            className="main-filter"
            value={searchObject?.email}
            onChange={(value) => updateSearchField("email", value)}
            title={intl.formatMessage({id: "email"})}
          />

          <SelectFilter
            title={intl.formatMessage({id:"filter_validated"})}
            value={searchObject?.validated}
            onChange={(value) => updateSearchField("validated",value)}
            options={options}
            isClearable
          />

          <SelectFilter
            title={intl.formatMessage({id:"filter_profile"})}
            value={searchObject?.profile}
            onChange={(value) => updateSearchField("profile",value)}
            options={optionsProfile}
            isClearable
          />

          <div>
            <Button className="me-2" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
          </div>

        </FilterGroup>
      </div>

      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="customer_no_result"
        className={`${className} table`}
        fetchData={(params) => userService.getUsers(params, searchObject)}
        filters={{}}
        defaultSortBy={[{sortBy: "id"}]}
      />

      {toggleModal && (
        <GenericModal
          open={toggleModal}
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: onConfirmUserActivation
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => setToggleModal(false)
            }
          }}
          title={intl.formatMessage({id: modalUser.validated ? "user_deactivate_modal_title" : "user_activate_modal_title"})}
        >
          {modalUser.validated ? <FormattedMessage id={"user_deactivate_modal_message"} values={{fullName}}/> :
            <FormattedMessage id={"user_activate_modal_message"} values={{fullName}}/>}
        </GenericModal>
      )
      }
      {lateralPanel && (
        <Panel
          formId="editUserForm"
          title={selectedUser.id ? "edit_user" : "create_new_user"}
          open={lateralPanel}
          loading={loading}
          onCancel={() => setLateralPanel(false)}
        >
          <CreateUserForm
            id="editUserForm"
            user={selectedUser}
            onEdit={onEdit}
            stopLoading={stopLoading}
            startLoading={startLoading}
          />
        </Panel>
      )}
    </>
  )
}

export default UserList
