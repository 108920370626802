import React, {FunctionComponent, useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import FormAutocompleteSelectPageable from "../../../input/FormAutocompleteSelectPageable";
import FormInput from "../../../input/FormInput";
import FormRadio from "../../../input/FormRadio";
import CreateOrderItemRendezVousForm from "../shared/CreateOrderItemRendezVousForm";
import {FormikErrors, FormikTouched} from "formik";
import {YES_NO_OPTIONS} from "../../../../../constants/OptionConstants";
import {WorkflowStatesEnum} from "../../../../../constants/workflow/WorkflowStates";
import {FormikFieldSetter} from "../../../../../interfaces/FormikInterfaces";
import {Order} from "../../../../../interfaces/OrderInterfaces";
import {OrderItemRequest} from "../../../../../interfaces/OrderItemInterfaces";
import {Service} from "../../../../../interfaces/ServiceInterfaces";
import {serviceService} from "../../../../../services/ServiceService";
import {Activity} from "../../../../../interfaces/ActivityInterfaces";
import {toastUtils} from "../../../../../utils/toastUtils";
import {ApiError} from "../../../../../interfaces/ErrorInterfaces";
import {catalogCustomerServiceService} from "../../../../../services/CatalogCustomerServiceService";

interface CreateOrderItemServiceFormStepOneProps {
  className?: string,
  errors: FormikErrors<OrderItemRequest>,
  touched: FormikTouched<OrderItemRequest>,
  values: OrderItemRequest,
  setFieldValue: FormikFieldSetter,
  order?: Order,
  setSelectedActivity: (activity: Activity) => void,
  setSelectedService: (service: Service) => void,
  setValues: (values: OrderItemRequest) => void,
  departureTimeMinuteDelta: number,
  isEditable: boolean,
}

const CreateOrderItemServiceFormStepOne: FunctionComponent<CreateOrderItemServiceFormStepOneProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  setSelectedActivity,
  setSelectedService,
  setValues,
  order,
  departureTimeMinuteDelta,
  isEditable
}) => {
  const intl = useIntl()

  const updateSelectedService = async (serviceId): Promise<Service> => {
    return serviceService.getService(serviceId, order.id)
      .then(service => {
        setSelectedService(service)
        setFieldValue("title", service?.name)
        return service
      }).catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
        return {}
      })
  }

  const getPriceForService = (service: Service) => {
    catalogCustomerServiceService.getPriceForCustomerService(service.id, values.billingService, order.beginTourDate)
      .then(price => setFieldValue("paxPrice", price))
      .catch((error: ApiError) => toastUtils.errorToast(error.message))
  }

  const updateServicePaxPrice = (service: Service) => {
    setFieldValue("unitType", service?.defaultUnitType)
    getPriceForService(service)
  }

  useMemo(() => {
    const fetchData = async () => {
      if (values?.id && values?.service) {
        const service = await updateSelectedService(values?.service);
        updateServicePaxPrice(service)
      }
    }

    fetchData()
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }, [values?.id])

  const onChangeService = async (serviceId: string) => {
    const service = await updateSelectedService(serviceId)

    setSelectedActivity(null)
    setSelectedService(service)

    setValues({
      ...values,
      service: service?.id,
      name: service?.name,
      title: service?.name,
      description: service?.description,
      isStaffingManagement: false,
    })

    updateServicePaxPrice(service)
  }

  // useCallback to avoid useless calls when component update
  const fetchServices = useCallback((page, filter) => {
    return serviceService.getServicesPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})
  }, []);

  return (
    <div className={className}>
      <FormAutocompleteSelectPageable
        id="service"
        label={intl.formatMessage({id: "order_item_service"})}
        placeholder={isEditable ? values?.title : intl.formatMessage({id: "order_item_type_service"})}
        value={values?.service}
        onChange={(value) => onChangeService(value)}
        error={errors?.service}
        touched={touched?.service}
        required
        fetchData={fetchServices}
        readOnly={isEditable}
        filterFieldName="name"
      />

      <FormInput
        id="name"
        label="order_item_name"
        value={values?.name}
        onChange={(e) => setFieldValue("name", e.target?.value)}
        required
        error={errors.name}
        touched={touched.name}
      />

      <FormRadio
        id="isBookingManagement"
        label="order_item_is_booking_management"
        value={YES_NO_OPTIONS.find((option) => option?.value === values?.isBookingManagement?.toString())}
        options={YES_NO_OPTIONS}
        onChange={(e) => setFieldValue("isBookingManagement", e.value)}
        required
        error={errors.isBookingManagement}
        touched={touched.isBookingManagement}
      />

      <FormInput
        id="description"
        label="order_item_description"
        value={values?.description ?? ""}
        onChange={(e) => setFieldValue("description", e.target?.value)}
        error={errors.description}
        touched={touched.description}
      />

      <CreateOrderItemRendezVousForm
        values={values}
        errors={errors}
        touched={touched}
        departureTimeMinuteDelta={departureTimeMinuteDelta}
        setFieldValue={setFieldValue}
      />

    </div>
  )
}

export default CreateOrderItemServiceFormStepOne;
