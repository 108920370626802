import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {FormGroup, Label} from "reactstrap";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import MultiSelect from "../../atoms/input/MultiSelect";

interface FormMultiSelectProps {
  id?: string,
  className?: string,
  label?: string,
  values?: Array<Option<string>>,
  onChange: (selected: Option<string>[]) => void,
  options: Option<string>[],
  required?: boolean,
  isSearchable?: boolean,
  isClearable?: boolean,
  error?: FormikError
  touched?: FormikTouchedType,
  popover?: string
}

const FormMultiSelect: FunctionComponent<FormMultiSelectProps> = ({
  id,
  className = "",
  label,
  values,
  onChange,
  options,
  required,
  isSearchable,
  isClearable,
  error,
  touched,
  popover
}) => {
  const intl = useIntl()
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            {intl.formatMessage({id: label})}
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>
      <MultiSelect
        values={values}
        onChange={onChange}
        options={options}
        isSearchable={isSearchable}
        isClearable={isClearable}
        error={error}
        touched={touched}
      />
      <ErrorField error={error} touched={touched}/>
    </FormGroup>
  )
}

export default FormMultiSelect
