import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {ACTIVITY_RESOURCE_CUSTOMER_INITIAL_VALUES} from "../../../constants/ActivityConstants";
import {CreateActivityResourceCustomerSchema} from "../../../constants/validation/ActivityValidationSchemas";
import {Col} from "reactstrap";
import FormInput from "../input/FormInput";
import {useIntl} from "react-intl";
import {ActivityResourceCatalog} from "../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../services/ActivityService";
import {toastUtils} from "../../../utils/toastUtils";
import {dateUtils} from "../../../utils/dateUtils";
import PriceInput from "../input/PriceInput";
import EpowForm from "./EpowForm";

interface CreateActivityResourceCustomerFormProps {
  id?: string,
  className?: string,
  activityCustomerId?: string,
  initialActivityResourceRate?: ActivityResourceCatalog
  onValidate?: () => void
}

const CreateActivityResourceCustomerForm: FunctionComponent<CreateActivityResourceCustomerFormProps> = ({
  id,
  className = "",
  activityCustomerId,
  initialActivityResourceRate,
  onValidate
}) => {
  const [noValueError, setNoValueError] = React.useState<string>("");

  const intl = useIntl()
  const activityResourceRate = initialActivityResourceRate ? {
    ...initialActivityResourceRate,
    startDateValidity: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(initialActivityResourceRate?.startDateValidity))
  } : null

  const isOneRateFilled = (value: ActivityResourceCatalog) => {
    return (value.juniorEmployeeRate > 0 || value.juniorIndependentRate > 0 || value.seniorEmployeeRate > 0 || value.seniorIndependentRate > 0)
  }

  const handleSubmitActivityResourceCustomer = (activityResourceCatalog: ActivityResourceCatalog) => {
    if(!isOneRateFilled(activityResourceCatalog)) {
      setNoValueError("activity_need_rate")
      return;
    }

    if (initialActivityResourceRate?.id) {
      activityService.updateActivityResourceCatalog(
        initialActivityResourceRate.id,
        {...activityResourceCatalog, activityCustomerId: activityCustomerId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_activity_resource"}))
        onValidate && onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_activity_resource"}))
      })
    } else {
      activityService.createActivityResourceCatalog(
        {...activityResourceCatalog, activityCustomerId: activityCustomerId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_activity_resource"}))
        onValidate && onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_activity_resource"}))
      })
    }

  }

  return (
    <Formik
      initialValues={activityResourceRate || ACTIVITY_RESOURCE_CUSTOMER_INITIAL_VALUES}
      validationSchema={CreateActivityResourceCustomerSchema}
      onSubmit={handleSubmitActivityResourceCustomer}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="startDateValidity"
            label="activity_start_date_validity"
            type="date"
            required
            value={values?.startDateValidity}
            onChange={(e) => setFieldValue("startDateValidity", e.target?.value)}
            error={errors?.startDateValidity}
            touched={touched?.startDateValidity}
          />
          <Col>
            <PriceInput
              id="juniorEmployeeRate"
              label="activity_junior_employee_rate"
              value={values?.juniorEmployeeRate}
              onChange={(e) => setFieldValue("juniorEmployeeRate", e.target.value)}
              required
              error={errors?.juniorEmployeeRate || noValueError}
              touched={touched?.juniorEmployeeRate}
            />
            <PriceInput
              id="seniorEmployeeRate"
              label="activity_senior_employee_rate"
              value={values?.seniorEmployeeRate}
              onChange={(e) => setFieldValue("seniorEmployeeRate", e.target.value)}
              required
              error={errors?.seniorEmployeeRate || noValueError}
              touched={touched?.seniorEmployeeRate}
            />
          </Col>
          <Col>
            <PriceInput
              id="juniorIndependentRate"
              label="activity_junior_independent_rate"
              value={values?.juniorIndependentRate}
              onChange={(e) => setFieldValue("juniorIndependentRate", e.target.value)}
              required
              error={errors?.juniorIndependentRate || noValueError}
              touched={touched?.juniorIndependentRate}
            />
            <PriceInput
              id="seniorIndependentRate"
              label="activity_senior_independent_rate"
              value={values?.seniorIndependentRate}
              onChange={(e) => setFieldValue("seniorIndependentRate", e.target.value)}
              required
              error={errors?.seniorIndependentRate || noValueError}
              touched={touched?.seniorIndependentRate}
            />
          </Col>
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivityResourceCustomerForm
