import React, {FunctionComponent, useMemo, useState} from "react";
import {fileService} from "../../../services/FileService";
import {DocumentDTO, FileSearchObject} from "../../../interfaces/FileInterfaces";
import {Invoice} from "../../../interfaces/InvoiceInterfaces";
import {FileType} from "../../../constants/FileConstants";
import Card from "../../../components/atoms/Card";
import {CardBody, CardTitle} from "reactstrap";
import {fileUtils} from "../../../utils/fileUtils";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../utils/toastUtils";

interface BillingInvoiceFilesDetailsProps {
  className?: string,
  invoice: Invoice,
  fileType: FileType,
  title: string,
}

const BillingInvoiceFilesDetails: FunctionComponent<BillingInvoiceFilesDetailsProps> = ({
  className = "",
  invoice,
  fileType,
  title,
}) => {
  const intl = useIntl()
  const [files, setFiles] = useState<DocumentDTO[]>([])

  useMemo(() => {
    const filter: FileSearchObject = {
      fileType,
      orderItemIds: invoice.invoiceOrderDetails.flatMap(o => o.billingItemDetails.map(i => i.orderItemId))
    }
    fileService.findFiles(filter)
      .then((response) => {
        setFiles(response)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "invoice_files_error"}))
      })
  }, [invoice.id])

  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }
  return (
    <div className={className}>
      <Card className="epow-contact-card">
        <CardBody>
          <CardTitle>
            <div className="d-flex flex-row align-items-center">
              <span className="fs-5 bold">{title}</span>
            </div>
          </CardTitle>

          {files.map(file => (
            <div key={file.fileId} className="d-flex justify-content-between align-items-center mb-2">
              <div>
                <span
                  className="epow-text-link cursor-pointer me-3"
                  onClick={() => downloadDocument(file.fileId, file.name, file.extension)}>
                  {`${file.name}.${file.extension}`}
                </span>
              </div>
            </div>
          ))}
        </CardBody>
      </Card>
    </div>
  )
}

export default BillingInvoiceFilesDetails;
