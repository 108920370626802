import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {CreateHomeCommentSchema} from "../../../../constants/validation/HomeCommentValidationSchema";
import {HomeComment, HomeCommentRequest} from "../../../../interfaces/HomeCommentInterfaces";
import {homeCommentService} from "../../../../services/HomeCommentService";
import {toastUtils} from "../../../../utils/toastUtils";
import FormInput from "../../input/FormInput";
import RichTextInput from "../../input/RichTextInput";
import EpowForm from "../EpowForm";

interface CreateHomeCommentFormProps {
  className?: string,
  id?: string;
  initialComment?: HomeComment;
  initialDate?: string;
  onCreateComment?: (note: HomeComment) => void;
  dateReadOnly?: boolean;
}

const CreateHomeCommentForm: FunctionComponent<CreateHomeCommentFormProps> = ({
  className = "",
  dateReadOnly = false,
  id,
  initialComment = null,
  initialDate = "",
  onCreateComment = () => null,
}) => {
  const intl = useIntl();

  const initialValues: HomeCommentRequest = {
    id: initialComment?.id ?? null,
    date: initialComment?.date ?? initialDate,
    content: initialComment?.content ?? "",
  }

  const handleEditComment = (comment: HomeCommentRequest) => {
    homeCommentService.updateHomeComment(comment.id, comment)
      .then((res) => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_edit_home_comment"}))
        onCreateComment(res)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_edit_home_comment"}))
      })
  }

  const handleCreateComment = (newComment: HomeCommentRequest) => {
    homeCommentService.createHomeComment(newComment)
      .then((res) => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_home_comment"}))
        onCreateComment(res)
      })
      .catch((e: Error) => {
        toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_create_home_comment"})} : ${e.message}`)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreateHomeCommentSchema}
      onSubmit={(values) => initialComment?.date ? handleEditComment(values) : handleCreateComment(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="date"
            label="home_comment_date"
            type="date"
            value={values?.date}
            onChange={(e) => setFieldValue("date", e.target.value)}
            required
            readOnly={dateReadOnly}
            error={errors.date}
            touched={touched.date}
            rows="5"
          />

          <RichTextInput
            id="content"
            label="home_comment_content"
            value={values.content}
            onChange={(e) => setFieldValue("content", e.target.value)}
            required
            error={errors.content}
            touched={touched.content}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateHomeCommentForm;
