import {IconName} from "./IconsMap";
import React, {FunctionComponent, ReactNode} from "react";
import Icon from "./Icon";
import {IconSizeType} from "../../types/bootstrap/BootstrapType";
import Badge from "../atoms/Badge";

interface BadgedIconProps {
  name: IconName
  badge?: boolean
  children: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  defaultColor?: boolean
}

const BadgedIcon: FunctionComponent<BadgedIconProps> = ({
                                                          name,
                                                          badge = false,
                                                          children,
                                                          className = "",
                                                          onClick,
                                                          defaultColor
                                                        }) => {
  return (
    <span className="position-relative">
      {badge ?
        <>
          <Icon name={name} size={IconSizeType.XS} className={`${className} me-1`} onClick={onClick}/>
          <Badge className="badged-icon badge-rounded">
            {children}
          </Badge>
        </> :
        <Icon name={name} size={IconSizeType.XS} className={`${className} ${defaultColor ? "color-gray" : ""} me-1`} onClick={onClick}/>
      }
    </span>
  )
}

export default BadgedIcon
