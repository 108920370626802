import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import CustomerList from "../components/molecules/list/CustomerList";
import Layout from "../components/layout/Layout";
import {useIntl} from "react-intl";
import Panel from "../components/molecules/panel/Panel";
import CreateCustomerForm from "../components/molecules/form/CreateCustomerForm";
import {CustomerSearchObject} from "../interfaces/CustomerInterfaces";
import useSearchFilter from "../hooks/useSearchFilter";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const CustomerListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const [customerCreationPanelOpen, setCustomerCreationPanelOpen] = useState<boolean>(false)
  const {searchObject, onReset, updateSearchField} = useSearchFilter<CustomerSearchObject>({
    name: "",
    currentState: WorkflowStatesEnum.ACTIVE
  });

  const {loading, stopLoading, startLoading} = useLoading()

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <CommonEntityHeader
          pageTitle={intl.formatMessage({id: "customer_title"})}
          updateSearchField={updateSearchField}
          setCreationPanelOpen={setCustomerCreationPanelOpen}
          creationButtonLabel={intl.formatMessage({id: "create_new_customer"})}
        />

        <CustomerList
          searchObject={searchObject}
          onReset={() => onReset({currentState: searchObject.currentState})}
          updateSearchField={updateSearchField}
        />

      </div>

      {customerCreationPanelOpen && (
        <Panel
          formId="createCustomerForm"
          title="create_new_customer"
          open={customerCreationPanelOpen}
          loading={loading}
          onCancel={() => setCustomerCreationPanelOpen(false)}
        >
          <CreateCustomerForm id="createCustomerForm" startLoading={startLoading} stopLoading={stopLoading}/>
        </Panel>
      )}
    </Layout>
  )
}

export default CustomerListView
