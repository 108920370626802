import React, {FunctionComponent} from "react";
import Card from "../../atoms/Card";
import {dateUtils} from "../../../utils/dateUtils";
import {orderUtils} from "../../../utils/orderUtils";
import {DashboardActivity} from "../../../interfaces/ActivityInterfaces";

interface ActivityProps {
  activity: DashboardActivity,
  className?: string,
  onClickActivity?: (activity: DashboardActivity) => void
}

const Activity: FunctionComponent<ActivityProps> = ({activity, onClickActivity = () => null, className= ""}) => (
  <Card
    key={`${activity.activityName}-${activity.rendezVousDate}-${activity.rendezVousTime}`}
    className={`next-task me-3 mt-2 mb-3 cursor-pointer ${className}`}
    onClick={() => onClickActivity(activity)}>
    <span
      className="d-block fs-10">{activity.rendezVousPlace} <span
      className="fs-20">|</span> {dateUtils.formatToLocalDateString(dateUtils.parseDate(activity.rendezVousDate))} - {dateUtils.formatLocalTime(activity.rendezVousTime)}</span>
    <span>
    <span
      className="fs-10 color-gray">{orderUtils.getOrderRefDisplay(activity.orderRef, activity.clientRef, activity.clientName)} </span>
    <span className="fs-12 fw-bold">{activity.activityName}</span>
    </span>
  </Card>)


export default Activity;
