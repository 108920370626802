import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Col} from "reactstrap";
import {CUSTOMERS_PATH, QUOTES_PATH} from "../../../../constants/routes/RoutePaths";
import {Quote, QuoteStateMap} from "../../../../interfaces/QuoteInterfaces";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Badge from "../../../atoms/Badge";
import Icon from "../../../icon/Icon";
import TextLink from "../../../atoms/TextLink";
import {moneyUtils} from "../../../../utils/moneyUtils";

interface QuoteTableRowProps {
  className?: string,
  quote: Quote,
  onRowClick?: (quoteId: string) => void,
  isCustomer?: boolean,
}

const QuoteTableRow: FunctionComponent<QuoteTableRowProps> = ({
                                                                className = "",
                                                                quote,
                                                                onRowClick,
                                                              }) => {
  const intl = useIntl()

  return (
    <div onClick={() => onRowClick(quote.id)} className={`epow-custom-table-row-main ${className ?? ""}`}>

      <Col>
        {quote?.quoteNumber}
      </Col>

      <Col>
        {quote?.customerRef ?? "-"}
      </Col>

      <Col>
        {quote?.customerId ?
          <TextLink to={`${CUSTOMERS_PATH}/${quote?.customerId}`}>
            <>{quote?.customerName ?? "-"}</>
          </TextLink> : "-"}
      </Col>

      <Col>
        {quote.numberOfQuoteItems}
      </Col>

      <Col>
        {moneyUtils.formatNumberToCurrency(quote.totalHt)}
      </Col>

      <>
        <Col>
          <Badge pill color={QuoteStateMap[quote.status]?.color}>
            {intl.formatMessage({id: QuoteStateMap[quote.status]?.label})}
          </Badge>
        </Col>

        <Col onClick={(event) => event.stopPropagation()}>
          <div className="float-end">
            <Link className="table-action-link" to={`${QUOTES_PATH}/${quote.id}`}>
              <Icon
                name="Search"
                size={IconSizeType.XS}
                className={"pe-2"}/>
            </Link>
          </div>
        </Col>
      </>

    </div>
  )
}

export default QuoteTableRow;
