import React, {FunctionComponent} from "react";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import {MultiSelect as MultiSelectComponent} from "react-multi-select-component";
interface MultiSelectProps {
    values?:  Option<string>[],
    onChange: (selected: Option<string>[]) => void,
    options: Option<string>[],
    isSearchable?: boolean,
    isClearable?: boolean,
    error?: FormikError
    touched?: FormikTouchedType,
}

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
                                                              values,
                                                              onChange,
                                                              options,
                                                              isSearchable,
                                                              isClearable,
                                                              error,
                                                              touched,
                                                          }) => (
    <MultiSelectComponent
        className={`epow-select ${!!error && !!touched ? "epow-select-error" : ""}`}
        value={values || []}
        onChange={onChange}
        options={options || []}
        labelledBy="Select"
        disableSearch={!isSearchable}
        ClearSelectedIcon={isClearable ? (<Icon size={IconSizeType.SM} name="Cross"/>) : null}
    />)


export default MultiSelect;
