import React, { ReactNode ,FunctionComponent } from "react";
import {Card as CardReactstrap, CardProps as CardReactstrapProps} from "reactstrap";
interface CardProps  extends CardReactstrapProps {
    className?: string,
    children: ReactNode
}

const Card : FunctionComponent<CardProps> = ({className = "", children, ...rest}) =>
    (<CardReactstrap {...rest} className={`epow-card ${className}`}>
        {children}
    </CardReactstrap>)


export default Card;
