import React, {FunctionComponent, useMemo, useState} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import BillingList from "../../components/molecules/list/BillingList";
import PageTitle from "../../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../../components/molecules/header/HeaderButtonGroup";
import useSearchFilter from "../../hooks/useSearchFilter";
import {useIntl} from "react-intl";
import {InvoiceSearchObject, InvoiceState} from "../../interfaces/InvoiceInterfaces";
import Button from "../../components/atoms/Button";
import CreateInvoiceForm from "../../components/molecules/form/CreateInvoiceForm";
import Panel from "../../components/molecules/panel/Panel";
import useLoading from "../../hooks/useLoading";
import {ColorType} from "../../types/bootstrap/BootstrapType";
import ModalButton from "components/molecules/button/ModalButton";
import BillingExportVatOnMarginModal from "components/molecules/modal/billing/BillingExportVatOnMarginModal";

const BillingListView: FunctionComponent<RouteProps> = () => {

  const initialSearchState: InvoiceSearchObject = useMemo(() => ({
    invoiceNumber: "",
    status: "",
    startDate: "",
    endDate: "",
    customerIds: []
  }), [])

  const intl = useIntl()
  const [open, setOpen] = useState(false);
  const {loading, startLoading, stopLoading} = useLoading();
  const {searchObject, updateSearchField, onReset} = useSearchFilter<InvoiceSearchObject>(initialSearchState)

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [{
    title: intl.formatMessage({id: "invoice_filter_all"}),
    onclick: () => updateSearchField("status", undefined),
    active: true
  },
  {
    title: intl.formatMessage({id: "invoice_filter_draft"}),
    onclick: () => updateSearchField("status", InvoiceState.DRAFT),
    active: false
  },
  {
    title: intl.formatMessage({id: "invoice_filter_billed"}),
    onclick: () => updateSearchField("status", InvoiceState.BILLED),
    active: false
  }], [intl])

  //  “Client facturé“, “Date de début” et “Date de fin” sont renseignés (infos nécessaires et indispensable pour l’export)
  // Décision de cacher la feature le 10/11/2023
  const canGenerateExport = (): boolean => {
    return false
    // return (searchObject.customerIds?.length > 0) && !!searchObject.startDate && !!searchObject.endDate
  }

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "billing_tab_invoices"})}></PageTitle>
          <HeaderButtonGroup buttonList={stateFilterButtons} />
          <div className="d-flex">
            <Button className="btn-header me-2" onClick={() => setOpen(true)}>
              {intl.formatMessage({id: "billing_invoices_create"})}
            </Button>
            {canGenerateExport() && (
              <ModalButton color={ColorType.SECONDARY} title={intl.formatMessage({id: "billing_invoices_export"})} >
                {({modalOpen, setModalOpen}) => (
                  <BillingExportVatOnMarginModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    searchObject={searchObject}
                  />
                )}
              </ModalButton>
            )}
          </div>
        </div>
        <BillingList searchObject={searchObject} updateSearchField={updateSearchField} onReset={() => onReset({status: searchObject.status})} />
      </div>

      {open && (
        <Panel
          formId="createInvoiceForm"
          title="create_new_invoice"
          loading={loading}
          open={open}
          onCancel={() => setOpen(false)}
        >
          <CreateInvoiceForm id="createInvoiceForm" stopLoading={stopLoading} startLoading={startLoading} />
        </Panel>
      )}
    </Layout>
  );
}

export default BillingListView
