import {CustomerServiceInfoCatalog} from "../../../interfaces/ServiceInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import {optionUtils} from "../../../utils/optionUtils";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import {numberUtils} from "../../../utils/numberUtils";
import FieldLabel from "../../atoms/FieldLabel";
import {Col, Row} from "reactstrap";
import {serviceService} from "../../../services/ServiceService";
import GenericModalDelete from "../modal/GenericModalDelete";

interface CustomerServiceInfoCatalogListProps {
  className?: string,
  customerServiceInfoCatalogs?: CustomerServiceInfoCatalog[],
  setLateralPanelCustomerServiceInfoCatalog?: (open?: boolean) => void,
  setInitialCustomerServiceInfoCatalog?: (customerServiceInfoCatalog?: CustomerServiceInfoCatalog) => void,
  onValidate?: () => void
}

const CustomerServiceInfoCatalogList: FunctionComponent<CustomerServiceInfoCatalogListProps> = ({
  className = "",
  customerServiceInfoCatalogs,
  setLateralPanelCustomerServiceInfoCatalog,
  setInitialCustomerServiceInfoCatalog,
  onValidate
}) => {
  const intl = useIntl()
  const [openModalDeleteInfoCatalog, setOpenModalDeleteInfoCatalog] = useState<boolean>(false)

  const handleModalDeleteInfoCatalog = () => {
    setOpenModalDeleteInfoCatalog(false)
    onValidate && onValidate()
  }

  return (
    <div className={className}>
      {customerServiceInfoCatalogs?.length > 0 && customerServiceInfoCatalogs.map((customerServiceInfoCatalog, key) =>
        <div key={key} className="d-flex flex-row justify-content-between mb-2">
          <Row className="w-100">
            <Col xs={24} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_start_date_validity"})}
                value={dateUtils.formatToLocalDateString(dateUtils.convertStringToObjectDate(customerServiceInfoCatalog?.startDateValidity))}
              />
            </Col>
            <Col xs={24} sm={8} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_type"})}
                value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, customerServiceInfoCatalog?.unitType)}
              />
            </Col>
            <Col xs={24} sm={8} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_group_size"})}
                value={customerServiceInfoCatalog?.groupSize}
              />
            </Col>
            <Col xs={24} sm={8} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_price_excluding_tax"})}
                value={numberUtils.formatPrice(customerServiceInfoCatalog?.unitPriceExcludingTax)}
              />
            </Col>
            <Col xs={24} sm={8} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_sell_type"})}
                value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, customerServiceInfoCatalog?.sellUnitType)}
              />
            </Col>
            <Col xs={24} sm={8} md={3}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_sell_price_excluding_tax"})}
                value={numberUtils.formatPrice(customerServiceInfoCatalog?.sellPriceExcludingTax)}
              />
            </Col>
          </Row>
          <div className="mb-2 d-flex flex-column">
            <span className="text-muted"><FormattedMessage id="action_button"/></span>
            <div className="d-flex flex-row">
              <Icon
                name="Pen"
                size={IconSizeType.XS}
                className="pe-2"
                onClick={() => {
                  setLateralPanelCustomerServiceInfoCatalog(true)
                  setInitialCustomerServiceInfoCatalog(customerServiceInfoCatalog)
                }}
              />
              <Icon
                name="Trash"
                size={IconSizeType.XS}
                className="pe-2"
                onClick={() => setOpenModalDeleteInfoCatalog(true)}
              />
            </div>
          </div>
          <GenericModalDelete
            titleId="modal_title_delete_customer_service_info_catalog"
            open={openModalDeleteInfoCatalog}
            onValidate={handleModalDeleteInfoCatalog}
            setOpen={setOpenModalDeleteInfoCatalog}
            idEntity={customerServiceInfoCatalog?.id}
            onDelete={serviceService.deleteCustomerServiceCatalog}
            successToastMessageId="success_toast_delete_customer_service_info_catalog"
            errorToastMessageId="error_toast_delete_customer_service_info_catalog"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_customer_service_info_catalog"})}
          />
        </div>
      )}
    </div>
  )
}

export default CustomerServiceInfoCatalogList
