import React, {FunctionComponent} from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import Button from "../../atoms/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {ColorType} from "../../../types/bootstrap/BootstrapType";


export interface ActionProps {
  buttonLabel: string,
  action?: () => void,
  disabled?: boolean,
  form?: string
}

interface GenericModalProps {
  className?: string,
  open?: boolean,
  onOpen? : (open: boolean) => void,
  children?: React.ReactNode,
  closable?: boolean,
  enableClickOutside?: boolean,
  title? : string,
  subTitle? : string,
  footer?: {
    primaryAction: ActionProps
    secondaryAction?: ActionProps
  }
  onClosed?: () => void

}

const GenericModal: FunctionComponent<GenericModalProps> = ({
  className = "",
  children,
  open,
  onOpen,
  closable,
  enableClickOutside,
  title,
  subTitle,
  footer,
  onClosed,
}) => {

  const toggle = () => {
    onOpen(!open)
  }

  const toggleOutsideModal = () => {
    if (enableClickOutside) {
      toggle()
    }
  }

  return (<Modal isOpen={open} toggle={toggleOutsideModal} onClosed={onClosed} className={`${className} d-flex flex-column align-content-center justify-content-center w-100`}>
    <ModalHeader toggle={toggle} close={<></>} className="d-flex flex-column align-content-center justify-content-center border-0 w-100">
      <div className="position-absolute top-0 end-0 m-3 cursor-pointer">{closable && <FontAwesomeIcon icon={faTimesCircle} onClick={toggle}/>}</div>
      <div className="d-flex flex-column align-content-center justify-content-center">
        <span style={{fontWeight: "normal"}} className="h4 m-0">
          {title}
        </span>
        <span style={{fontWeight: "normal"}} className="font-weight-normal opacity-50 h6 d-flex align-content-center justify-content-center" >
          {subTitle}
        </span>
      </div>
    </ModalHeader>
    <ModalBody className="d-flex align-content-center justify-content-center">
      {children}
    </ModalBody>
    {footer && (
      <ModalFooter className="border-0 d-flex justify-content-evenly">
        <Button className="border-primary flex-grow-1" color={ColorType.SECONDARY} onClick={footer.secondaryAction.action} disabled={footer.secondaryAction.disabled}>
          {footer.secondaryAction.buttonLabel}
        </Button>
        <Button form={footer.primaryAction.form} className="flex-grow-1" onClick={footer.primaryAction.action} disabled={footer.primaryAction.disabled}>
          {footer.primaryAction.buttonLabel}
        </Button>
    </ModalFooter>
    )}
  </Modal>)
}

export default GenericModal
