import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {stringUtils} from "../../../utils/stringUtils";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {Popover} from "reactstrap";
import Calendar from "react-calendar"
import Button from "../../atoms/Button";

interface WelcomeProps {
  firstname: string;
  date?: Date;
  onChangeCalendarDate?: (date: Date) => void;
  calendarDate?: Date;
}

const Welcome: FunctionComponent<WelcomeProps> = ({
  firstname,
  date = new Date(),
  onChangeCalendarDate,
  calendarDate
}) => {
  const intl = useIntl();

  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (openCalendar) {
        setOpenCalendar(false)
      }
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (openCalendar) {
          setOpenCalendar(false)
        }
      });
    }
  }, [])

  return (
    <div className="epow-welcome d-flex justify-content-between align-items-end py-3 px-3 px-lg-5">
      <h1 className="fw-bold">
        <FormattedMessage id="dashboard_welcome" values={{firstname}}/>
        <span className="fw-normal">{stringUtils.capitalize(intl.formatDate(date, {
          day: "2-digit",
          month: "long",
          weekday: "long"
        }))}</span>
      </h1>
      <div
        className="d-flex d-lg-none bg-theme-dark-gray border-1 rounded-circle align-items-center justify-content-center">
        <Button className="bg-transparent border-0"
                type="button"
                id="calendar-popover"
                onClick={() => setOpenCalendar(prev => !prev)}
                style={{width: 42, height: 42}}>
          <Icon className="bg-light color-light m-0 border-1 rounded-1" size={IconSizeType.SM} name="Calendar"/>
        </Button>
        <Popover
          className="rounded"
          placement="bottom"
          isOpen={openCalendar}
          target="calendar-popover"
          toggle={() => setOpenCalendar(prev => !prev)}>
          <Calendar value={calendarDate} onChange={(value: Date) => onChangeCalendarDate(value)}/>
        </Popover>
      </div>
    </div>
  );
};


export default Welcome;
