import React, {FunctionComponent, useState} from "react";
import Card from "../../../atoms/Card";
import {BookingItem} from "../../../../interfaces/BookingInterfaces";
import Icon from "../../../icon/Icon";
import {FormattedMessage, useIntl} from "react-intl";
import {STATUS_BADGE} from "../../../../constants/booking/Booking";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import BadgedIcon from "../../../icon/BadgedIcon";
import Panel from "../../panel/Panel";
import BookingDocumentForm from "../../form/booking/BookingDocumentForm";
import BookingItemInformation from "../../booking/BookingItemInformation";
import {orderUtils} from "../../../../utils/orderUtils";
import Badge from "../../../atoms/Badge";
import {dateUtils} from "../../../../utils/dateUtils";
import BackgroundIcon from "../../../icon/BackgroundIcon";
import {useNavigate} from "react-router-dom";
import {PURCHASES_PATH} from "../../../../constants/routes/RoutePaths";
import {bookingService} from "../../../../services/BookingService";
import GenericModalDelete from "../../modal/GenericModalDelete";
import {toastUtils} from "../../../../utils/toastUtils";

interface BookingItemCardProps {
  className?: string;
  bookingItem: BookingItem;
  statusBadge?: boolean
  warningLabel?: string
  completionIndicators?: boolean
  getBookingsByStatus?: () => void
  defaultPendingBookingToOpenId?: string
}

const BookingItemCard: FunctionComponent<BookingItemCardProps> = ({
  className = "",
  bookingItem,
  statusBadge = false,
  warningLabel = "",
  completionIndicators = false,
  getBookingsByStatus,
  defaultPendingBookingToOpenId = ""
}) => {
  const statusClassName: string = statusBadge ? STATUS_BADGE[bookingItem.status] : "";
  const [formOpen, setFormOpen] = useState<boolean>(bookingItem?.id === defaultPendingBookingToOpenId);
  const navigate = useNavigate();
  const intl = useIntl();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()

  const updateBookings = () => {
    getBookingsByStatus && getBookingsByStatus();
  }

  const handleValidate = () => {
    setFormOpen(false);
    updateBookings();
  }

  const handleNavigate = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
    event.stopPropagation();
    navigate(`${PURCHASES_PATH}/${id}`);
  }

  const deleteDocument = (documentId: string) => {
    setSelectedDocumentId(documentId);
    setOpenDeleteModal(true);
    setFormOpen(false);
  }

  const doAfterDelete = () => {
    setOpenDeleteModal(false);
    handleValidate();
    setFormOpen(true);
  }

  const handleCancel = () => {
    if (bookingItem.id) {
      bookingService.cancelBooking(bookingItem.id)
        .then(() => {
          setFormOpen(false);
          updateBookings();
          toastUtils.successToast(intl.formatMessage({id: "success_toast_booking_cancelled"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_cancel_booking_document"}))
        })
    }
  }

  const handleKeep = () => {
    if (bookingItem.id) {
      bookingService.keepBooking(bookingItem.id)
        .then(() => {
          setFormOpen(false);
          updateBookings();
          toastUtils.successToast(intl.formatMessage({id: "success_toast_booking_keep"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_keep_booking_document"}))
        })
    }
  }

  const bookingDocumentData = () => {
    return({
      isBilled: bookingItem?.isBilled,
      isPaid: bookingItem?.isPaid,
      invoiceDate: bookingItem?.invoiceDate,
      paymentDate: bookingItem?.paymentDate,
      reference: bookingItem?.reference,
      id: bookingItem?.id,
      orderCode: bookingItem?.orderCode,
      orderRef: bookingItem?.orderRef,
      orderCustomerName: bookingItem?.orderCustomerName,
      date: bookingItem?.date,
      hour: bookingItem?.hour,
      pax: bookingItem?.pax,
      quantity: bookingItem?.quantity,
      purchaseNumber: bookingItem?.purchaseNumber,
      defaultSupplierName: bookingItem?.defaultSupplierName,
      serviceName: bookingItem?.serviceName,
      status: bookingItem?.status,
      supportingDocuments: bookingItem?.supportingDocuments,
      billDocuments: bookingItem?.billDocuments,
      paymentDocuments: bookingItem?.paymentDocuments,
      documentCount: bookingItem?.documentCount,
    })
  }

  return (
    <div>
      <Card
        className={`epow-card-booking-item ${className}`}
        onClick={() => setFormOpen(true)}>
        <div className="d-flex justify-content-between gap-1 mb-2">
          <div className="d-flex flex-column">
            <span className="text-muted">{bookingItem.defaultSupplierName}</span>
            <span className="fw-bold mt-1 booking-info-service">{bookingItem.serviceName}</span>
            <span className="text-muted">{orderUtils.getOrderRefDisplay(bookingItem.orderCode, bookingItem.orderRef, bookingItem.orderCustomerName)}</span>
          </div>
          <div
            className="purchase-icon d-flex align-item-center rounded-circle cursor-pointer h-100"
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleNavigate(e, bookingItem.purchaseId)}>
            <BackgroundIcon
              iconName="ArrowUpRightFromSquare"
              size={35}
              iconSize={IconSizeType.XS}/>
          </div>
        </div>
        {statusBadge &&
            <div className={`booking-item-card-status ${statusClassName} fw-bold mb-2`}>
                <FormattedMessage id={bookingItem.status}/>
            </div>
        }
        {bookingItem.bookingWarning?.warning &&
            <div className="d-flex justify-content-start align-items-center color-danger mb-2">
                <Badge className="icon-warning badge-rounded bg-alert me-1">
                    <Icon name="Alarm" size={IconSizeType.XS}/>
                </Badge>
                <FormattedMessage id={warningLabel} values={{days: bookingItem.bookingWarning?.warningDelay}}/>
            </div>
        }
        <div className="d-flex justify-content-between mb-2">
          <span>{intl.formatMessage({id: "purchase_booking_quantity"})} : <b>{bookingItem.quantity}</b></span>
          {completionIndicators &&
            <div className="d-flex justify-content-end align-items-center">
              <BadgedIcon name="PaperClip" badge={bookingItem.documentCount > 0} defaultColor>{bookingItem.documentCount}</BadgedIcon>
              <BadgedIcon name="Invoice" badge={bookingItem.isBilled} defaultColor><Icon name="Check" /></BadgedIcon>
              <BadgedIcon name="Database" badge={bookingItem.isPaid} defaultColor><Icon name="Check" /></BadgedIcon>
            </div>
          }
        </div>
        <BookingItemInformation date={dateUtils.parseDate(bookingItem?.date)} hour={bookingItem?.hour} pax={bookingItem.pax}/>
      </Card>

      {formOpen &&
        <Panel
            formId="bookingDocumentForm"
            className="border border-info"
            title={bookingItem.serviceName}
            open={formOpen}
            onCancel={() => setFormOpen(false)}>
          <BookingDocumentForm
              id="bookingDocumentForm"
              bookingDocumentData={bookingDocumentData()}
              onValidate={handleValidate}
              onChangedBookingFile={updateBookings}
              onDeleteDocument={deleteDocument}
              onKeep={handleKeep}
              onCancel={handleCancel}/>
        </Panel>
      }
      <GenericModalDelete
        titleId="modal_title_delete_document"
        open={openDeleteModal}
        onValidate={doAfterDelete}
        setOpen={setOpenDeleteModal}
        idEntity={selectedDocumentId}
        onDelete={bookingService.deleteDocumentById}
        onClosed={() => setFormOpen(true)}
        successToastMessageId="success_delete_document"
        errorToastMessageId="error_toast_delete_document"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_document"})}
      />
    </div>
  )
}

export default BookingItemCard;
