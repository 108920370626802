import {
  ACTIVITIES_PATH,
  INVOICES_PATH, BOOKINGS_PATH,
  CUSTOMERS_PATH,
  HOME_PATH,
  ORDERS_PATH,
  PURCHASES_PATH,
  RESOURCES_PATH,
  SERVICES_PATH,
  STAFFING_PATH,
  SUPPLIERS_PATH,
  TOUR_LEADERS_PATH,
  TOURS_PATH,
  ADMIN_PATH,
  BILLING_PATH,
  DOCUMENTARY_BASE_PATH,
  SALE_PATH,
  PURCHASING_PATH,
  STAFF_PATH,
  CATALOG_PATH,
  STAFF_REQUEST_PATH,
  ACTIVITIES_BILLING_PATH,
  BILLING_OVERVIEW_PATH,
  SUPPLIER_PURCHASE_PATH,
  QUOTES_PATH,
  RESOURCE_AVAILABILITY_PATH,
  MY_TOURS_PATH, STAFFING_CALENDAR_PATH, SELF_BILLING_PATH,
} from "../routes/RoutePaths";
import {PROFILE} from "../../interfaces/UserInterfaces";
import {IconName} from "../../components/icon/IconsMap";

interface Menu {
  icon?: IconName,
  collaspe?: boolean,
  title: string,
  to?: string,
  roles: Array<string>,
  subMenus: Array<SubMenu>
}

export interface SubMenu {
  icon?: IconName,
  label: string,
  to: string,
  roles: Array<string>
}

const DASHBOARD: Menu = {
  icon: "Home",
  title: "menu_dashboard",
  to: HOME_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
    PROFILE.RESOURCE,
    PROFILE.CLIENT,
    PROFILE.TOUR_LEADER,
    PROFILE.SUPPLIER
  ],
  subMenus: []
}

const PRE_BILLING: SubMenu = {
  label: "menu_invoice_list",
  to: INVOICES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const BILLING: SubMenu = {
  label: "menu_invoice",
  to: BILLING_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ]
}

const CUSTOMER: SubMenu = {
  label: "menu_customer",
  to: CUSTOMERS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ]
}

const ORDER: SubMenu = {
  label: "menu_order",
  to: ORDERS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const CUSTOMER_SELF_ORDERS: Menu = {
  title: "menu_self_order",
  to: ORDERS_PATH,
  roles: [
    PROFILE.CLIENT,
  ],
  subMenus: []
}

const CUSTOMER_SELF_BILLS: Menu = {
  title: "menu_self_bills",
  to: SELF_BILLING_PATH,
  roles: [
    PROFILE.CLIENT,
  ],
  subMenus: []
}


const MY_TOUR: Menu = {
  title: "menu_order_tour",
  to: MY_TOURS_PATH,
  roles: [
    PROFILE.TOUR_LEADER,
  ],
  subMenus: []
}

const TOUR_LEADER: SubMenu = {
  label: "menu_tour_leader",
  to: TOUR_LEADERS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const QUOTES: SubMenu = {
  label: "menu_quotes",
  to: QUOTES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const SALES: Menu = {
  icon: "CashRegister",
  to: SALE_PATH,
  title: "menu_sales",
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ],
  subMenus: [CUSTOMER, ORDER, QUOTES, PRE_BILLING, BILLING, TOUR_LEADER]
}

const SUPPLIER: SubMenu = {
  label: "menu_supplier",
  to: SUPPLIERS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const BOOKING: SubMenu = {
  label: "menu_booking",
  to: BOOKINGS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ]
}

const PURCHASE_ORDER: SubMenu = {
  label: "menu_purchase_order",
  to: PURCHASES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ]
}

const SUPPLIER_PURCHASE_ORDER: SubMenu = {
  label: "menu_purchase_order",
  to: SUPPLIER_PURCHASE_PATH,
  roles: [
    PROFILE.SUPPLIER,
  ]
}

const PURCHASE: Menu = {
  icon: "AddToCart",
  to: PURCHASING_PATH,
  title: "menu_purchase",
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
    PROFILE.SUPPLIER,
  ],
  subMenus: [SUPPLIER, BOOKING, PURCHASE_ORDER, SUPPLIER_PURCHASE_ORDER]
}

const RESOURCE: SubMenu = {
  label: "menu_resource",
  to: RESOURCES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const STAFFING_LIST: SubMenu = {
  label: "menu_staffing_list",
  to: STAFFING_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const STAFFING_CALENDAR: SubMenu = {
  label: "menu_staffing_calendar",
  to: STAFFING_CALENDAR_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const BILLING_OVERVIEW: SubMenu = {
  label: "menu_billing_overview",
  to: BILLING_OVERVIEW_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const STAFFING_REQUEST: Menu = {
  to: STAFF_REQUEST_PATH,
  title: "menu_self_staffing",
  roles: [
    PROFILE.RESOURCE
  ],
  subMenus: []
}

const ACTIVITIES_BILLING: Menu = {
  title: "menu_activity_billing",
  to: ACTIVITIES_BILLING_PATH,
  roles: [
    PROFILE.RESOURCE,
  ],
  subMenus: []
}

const RESOURCE_AVAILABILITY: Menu = {
  title: "menu_resource_availability",
  to: RESOURCE_AVAILABILITY_PATH,
  roles: [
    PROFILE.RESOURCE,
  ],
  subMenus: []
}

const STAFFING: Menu = {
  icon: "Staffing",
  to: STAFF_PATH,
  title: "menu_staffing",
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ],
  subMenus: [RESOURCE, STAFFING_LIST, BILLING_OVERVIEW, STAFFING_CALENDAR]
}

const TOUR: SubMenu = {
  label: "menu_tour",
  to: TOURS_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const ACTIVITY: SubMenu = {
  label: "menu_activity",
  to: ACTIVITIES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ]
}

const SERVICE: SubMenu = {
  label: "menu_service",
  to: SERVICES_PATH,
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN,
  ]
}

const CATALOG: Menu = {
  icon: "Book",
  to: CATALOG_PATH,
  title: "menu_catalog",
  roles: [
    PROFILE.ADMIN,
    PROFILE.INTERN
  ],
  subMenus: [TOUR, ACTIVITY, SERVICE]
}

const DOCUMENTARY_BASE: Menu = {
  title: "menu_documentary_base",
  to: DOCUMENTARY_BASE_PATH,
  roles: [
    PROFILE.RESOURCE,
    PROFILE.CLIENT,
    PROFILE.SUPPLIER,
    PROFILE.TOUR_LEADER
  ],
  subMenus: []
}

const ADMIN: Menu = {
  icon: "Tools",
  title: "menu_admin",
  to: ADMIN_PATH,
  roles: [
    PROFILE.ADMIN
  ],
  subMenus: []
}


export const SIDE_MENU_ITEMS = [
  DASHBOARD,
  MY_TOUR,
  CUSTOMER_SELF_ORDERS,
  CUSTOMER_SELF_BILLS,
  STAFFING_REQUEST,
  SALES,
  PURCHASE,
  ACTIVITIES_BILLING,
  RESOURCE_AVAILABILITY,
  STAFFING,
  CATALOG,
  DOCUMENTARY_BASE]
export const SIDE_MENU_BOTTOM_ITEMS = [ADMIN]
