import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import ServiceList from "components/molecules/list/ServiceList";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import useSearchFilter from "../hooks/useSearchFilter";
import {ServiceSearchObject} from "../interfaces/ServiceInterfaces";
import Panel from "../components/molecules/panel/Panel";
import CreateServiceForm from "../components/molecules/form/CreateServiceForm";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const ServiceListView: FunctionComponent<RouteProps> = () => {
  const {searchObject, onReset, updateSearchField} = useSearchFilter<ServiceSearchObject>({
    name: "",
    supplierId: "",
    activityId: "",
    currentState: WorkflowStatesEnum.ACTIVE
  })
  const [lateralPanelOpen, setLateralPanelOpen] = useState<boolean>(false)
  const intl = useIntl()
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <Layout>
      <div className="epow-content-body">
        <CommonEntityHeader
          pageTitle={intl.formatMessage({id: "service_title"})}
          updateSearchField={updateSearchField}
          setCreationPanelOpen={setLateralPanelOpen}
          creationButtonLabel={intl.formatMessage({id: "create_new_service"})}
        />

        <ServiceList
          searchObject={searchObject}
          onReset={() => onReset({currentState: searchObject.currentState})}
          updateSearchField={updateSearchField}
        />

        {lateralPanelOpen && (
          <Panel formId="createServiceForm" title="create_new_service" open={lateralPanelOpen}
                 loading={loading}
                 onCancel={() => setLateralPanelOpen(false)}>
            <CreateServiceForm id="createServiceForm" stopLoading={stopLoading} startLoading={startLoading}/>
          </Panel>
        )}

      </div>
    </Layout>
  )
}

export default ServiceListView
