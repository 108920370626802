import React, {FunctionComponent, useContext} from "react";
import {ResourceAvailabilityDispatchType, ResourceAvailabilityDto} from "../../../interfaces/ResourceAvailabilityInterfaces";
import {ResourceAvailabilityContext, ResourceAvailabilityDispatchContext} from "../../../context/ResourceAvailabilityContext";
import TableCheckbox from "../../atoms/TableCheckbox";
import ResourceAvailablityCard from "../card/resourceAvailability/ResourceAvailablityCard";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {resourceAvailabilityService} from "../../../services/ResourceAvailabilityService";
import {toastUtils} from "../../../utils/toastUtils";

interface ResourceAvailabilityCardListProps {
  resourceAvailabilities: ResourceAvailabilityDto[];
  reload: () => void;
  resourceId: string;
}

const ResourceAvailabilityCardList: FunctionComponent<ResourceAvailabilityCardListProps> = ({
  resourceAvailabilities,
  reload,
  resourceId
}) => {
  const intl = useIntl()
  const selectedAvailabilities = useContext(ResourceAvailabilityContext);
  const {dispatch} = useContext(ResourceAvailabilityDispatchContext);

  const buildUpdatedAvailabilities = () => {
    const result = [];
    resourceAvailabilities.forEach(a => {
      const hasAm = selectedAvailabilities.amList.some(d => d === a.availabilityDate);
      const hasPm = selectedAvailabilities.pmList.some(d => d === a.availabilityDate);
      const hasNight = selectedAvailabilities.nightList.some(d => d === a.availabilityDate);
      if (hasAm || hasPm || hasNight) {
        result.push({
          ...a,
          availableAm: !hasAm ?? a.availableAm,
          availablePm: !hasPm ?? a.availablePm,
          availableNight: !hasNight ?? a.availableNight,
        })
      }
    })
    return result
  }

  const bulkChangeAvailability = () => {
    const availabilities = buildUpdatedAvailabilities();
    return resourceAvailabilityService.bulkUpdateResourceAvailability(availabilities, resourceId)
      .then(() => {
        reload()
        toastUtils.successToast(intl.formatMessage({id: "success_update_resource_availability"}))
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_update_resource_availability"}))
      })
  }

  const isSelected = [...selectedAvailabilities.amList, ...selectedAvailabilities.amList, ...selectedAvailabilities.nightList].length !== 0;

  return (
    <div>
      <div className="mt-2 ms-2">
        <TableCheckbox
          checked={isSelected}
          onChange={() => dispatch({
            type: isSelected ? ResourceAvailabilityDispatchType.REMOVE_ALL : ResourceAvailabilityDispatchType.ADD_ALL,
            allDates: resourceAvailabilities.map(a => a.availabilityDate)
          })}
        />
        <span className="ms-1">{intl.formatMessage({id: "resource_availability_select_all"})}</span>
      </div>

      {resourceAvailabilities.map((resourceAvailability, index) => (
        <ResourceAvailablityCard key={index} resourceAvailability={resourceAvailability} reload={reload} resourceId={resourceId} />
      ))}

      <div className="btn-resource-bulk-unavailable">
        <Button onClick={() => bulkChangeAvailability()} color={ColorType.PRIMARY}>
          {intl.formatMessage({id: "resource_availability_mass_unavailable"})}
        </Button>
      </div>
    </div>
  )
}

export default ResourceAvailabilityCardList;
