import React, {FunctionComponent, useCallback, useState} from "react";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {Data} from "../../../interfaces/TableInterfaces";
import FilterLabel from "./FilterLabel";
import AsyncSelect from "react-select/async";

export interface AsyncAutocompleteMultiselectProps {
  className?: string,
  title?: string;
  placeholder?: string;
  value?: Option<string>;
  onChange: (values: Option<string>) => void;
  fetchData: (inputValue?: string) => Promise<PageResponse<Data>>,
  filterFieldName: string,
  loadOnFocus?: boolean,
  id?: string,
  closeMenuOnSelect?: boolean,
}

const AsyncAutocompleteMultiselect: FunctionComponent<AsyncAutocompleteMultiselectProps> = ({
  className,
  title,
  placeholder,
  value,
  onChange,
  fetchData,
  filterFieldName,
  id,
  loadOnFocus = true,
  closeMenuOnSelect = true,
}) => {

  const [currentInput, setCurrentInput] = useState<string>("")
  const [defaultOptions, setDefaultOptions] = useState<Option<string>[]>([])
  const [touched, setTouched] = useState<boolean>(false)

  const onFirstFocus = () => {
    if(loadOnFocus && !touched) {
      fetchData()
        .then((response: PageResponse<Data>) => {
          const options = response?.content?.map(data => ({label: data[filterFieldName], value: data.id}))
          setDefaultOptions(options)
        }).catch(console.error)
      setTouched(true)
    }
  }

  const loadOptions =  useCallback ((inputValue) => fetchData(inputValue)
    .then((response: PageResponse<Data>) => response?.content?.map(data => ({label: data[filterFieldName], value: data.id}))),
    [fetchData, filterFieldName])

  const relayOnChange = (newValue: Option<string>) => {
    const inputValueBeforeChange = currentInput;
    onChange(newValue);
    if (!closeMenuOnSelect) {
      setCurrentInput(inputValueBeforeChange)
    }
  }

  const updateInput = (inputValue: string) => {
    setCurrentInput(inputValue)
  }

  return (
    <div className={`epow-async-select ${className}`}>
      {title && <FilterLabel text={title} />}

      <AsyncSelect
        isMulti={false}
        id={id ? id : `autocomplete-${title}`}
        placeholder={placeholder ?? ""}
        value={value}
        onFocus={onFirstFocus}
        onChange={relayOnChange}
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPlacement="auto"
        onInputChange={updateInput}
        inputValue={currentInput}
      />
    </div>
  )
}

export default AsyncAutocompleteMultiselect;
