import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import {toastUtils} from "../utils/toastUtils";
import { Order } from "interfaces/OrderInterfaces";
import { orderService } from "services/OrderService";
import {OrderItem} from "../interfaces/OrderItemInterfaces";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import RoadBookHeader from "../components/molecules/header/RoadBookHeader";
import OrderRoadBookCard from "../components/molecules/card/order/OrderRoadBookCard";


interface RoadBookViewProps extends RouteProps {
  title?: string
  path?: string
}

const RoadBookView: FunctionComponent<RoadBookViewProps> = ({title, path}) => {
  const params = useParams();
  const intl = useIntl()
  const [order, setOrder] = useState<Order>(null)
  const [orderItems, setOrderItems] = useState<OrderItem[]>(order?.items ?? [])

  const getOrderById = () => {
    orderService.getOrderById(params.id)
      .then((order) => {
        setOrder(order)
        setOrderItems(order?.items)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_get"}))
      })
  }

  useEffect(() => {
    getOrderById()
  }, [])

  return (
      <Layout>
        {order && (
          <div className="epow-content-body">
            <RoadBookHeader
              className="mb-2"
              order={order}
              title={title}
              path={path}/>
            <OrderRoadBookCard
              order={order}
              orderItems={orderItems}
            />
          </div>
        )}
      </Layout>
    )
}

export default RoadBookView
