import {
  API_CUSTOMER_CONTACTS_PATH,
  API_CUSTOMERS_PATH,
  API_CONTACTS_PATH,
} from "constants/routes/RoutePaths";
import {
  CustomerContact,
  Contact,
  EntityContactRequest, ContactOption,
} from "interfaces/ContactInterfaces";
import { httpService } from "./HttpService";

function createCustomerContact(request: EntityContactRequest, entityId: string): Promise<CustomerContact> {
  const customerContact: CustomerContact = {
    ...request,
    customerId: entityId,
  }
  return httpService.post<CustomerContact>(API_CUSTOMER_CONTACTS_PATH, customerContact);
}

function updateCustomerContact(id: string,  entityId: string, request: EntityContactRequest): Promise<CustomerContact> {
  const customerContact: CustomerContact = {
    ...request,
    customerId: entityId,
  }
  return httpService.put<CustomerContact>(`${API_CUSTOMER_CONTACTS_PATH}/${id}`, customerContact);
}

function getAllContactsByCustomerId(customerId: string, filter?: { filter: string }): Promise<Contact[]> {
  return httpService.get<Contact[]>(`${API_CUSTOMERS_PATH}/${customerId}${API_CONTACTS_PATH}`, filter);
}

function getAllContactOptionsByCustomerId(customerId: string): Promise<ContactOption[]> {
  return httpService.get<Contact[]>(`${API_CUSTOMERS_PATH}/${customerId}${API_CONTACTS_PATH}/options`);
}

function inviteContactCustomer(id: string): Promise<Contact> {
  return httpService.post<Contact>(`${API_CUSTOMER_CONTACTS_PATH}/${id}/invite`, {});
}

export const customerContactService = {
  createCustomerContact,
  updateCustomerContact,
  getAllContactsByCustomerId,
  getAllContactOptionsByCustomerId,
  inviteContactCustomer
};
