import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {toastUtils} from "utils/toastUtils";
import {Supplier} from "interfaces/SupplierInterfaces";
import {supplierService} from "services/SupplierService";
import {FormattedMessage, useIntl} from "react-intl";
import {SUPPLIERS_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsEnum, WorkflowStateMap, WorkflowStatesEnum,} from "../constants/workflow/WorkflowStates";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import TabList from "components/molecules/tab/TabList";
import {supplierTab} from "constants/tabs/TabListConstants";
import PageTitle from "../components/molecules/header/PageTitle";

const SupplierView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { id } = useParams();

  const [supplier, setSupplier] = useState<Supplier>({});

  const getSupplier = () => {
    supplierService
      .getSupplier(id)
      .then((supplier: Supplier) => {
        setSupplier(supplier);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "error_toast_get_one_supplier" })
        );
      });
  };

  const handleDeleteButton = () => {
    supplierService
      .deleteSupplier(supplier.id)
      .then(() => {
        toastUtils.infoToast(
          intl.formatMessage(
            { id: "workflow_delete_info" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
        navigate(SUPPLIERS_PATH);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            { id: "workflow_delete_failure" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
      });
  };

  const handleActivateButton = () => {
    supplierService
      .updateSupplier(supplier.id, {
        ...supplier,
        currentState: WorkflowStatesEnum.ACTIVE,
      })
      .then((response: Supplier) => {
        toastUtils.infoToast(
          intl.formatMessage(
            { id: "workflow_validate_info" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
        setSupplier(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            { id: "workflow_validate_failure" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
      });
  };

  const handleDeactivateButton = () => {
    supplierService
      .updateSupplier(supplier.id, {
        ...supplier,
        currentState: WorkflowStatesEnum.INACTIVE,
      })
      .then((response: Supplier) => {
        toastUtils.infoToast(
          intl.formatMessage(
            { id: "workflow_deactivate_info" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
        setSupplier(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            { id: "workflow_deactivate_failure" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
      });
  };

  const handleReactivateButton = () => {
    supplierService
      .updateSupplier(supplier.id, {
        ...supplier,
        currentState: WorkflowStatesEnum.ACTIVE,
      })
      .then((response: Supplier) => {
        toastUtils.infoToast(
          intl.formatMessage(
            { id: "workflow_reactivate_info" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
        setSupplier(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            { id: "workflow_reactivate_failure" },
            { entity: intl.formatMessage({ id: "workflow_entity_supplier" }) }
          )
        );
      });
  };

  useEffect(() => {
    getSupplier();
  }, []);

  return (
    <Layout>
      {supplier && (
        <div className="epow-content-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <PageTitle
                pageTitle={supplier.name}
                className="me-4"
                breadcrumbItems={[{
                  title: intl.formatMessage({id: "supplier_title"}),
                  to: SUPPLIERS_PATH
                }]}
              />
              <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
              <Badge pill color={WorkflowStateMap[supplier.currentState]?.color} >
                {intl.formatMessage({id: WorkflowStateMap[supplier.currentState]?.label || "STATUS_DEFAULT"})}
              </Badge>
            </div>
            <WorkflowButtonList
              currentState={supplier.currentState}
              entityLabel={intl.formatMessage({
                id: "workflow_entity_supplier",
              })}
              actions={{
                [WorkflowActionsEnum.DELETE]: handleDeleteButton,
                [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
                [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
                [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton,
              }}
            />
          </div>
          <TabList items={supplierTab(supplier, setSupplier)} />
        </div>
      )}
    </Layout>
  );
};

export default SupplierView;
