import {Col, Row} from "reactstrap";
import {dateUtils} from "../../../../utils/dateUtils";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import React, {FunctionComponent} from "react";
import {OrderItem} from "../../../../interfaces/OrderItemInterfaces";
import ActivityPanel from "../activity/ActivityPanel";
import {authUtils} from "../../../../utils/authUtils";
import {useIntl} from "react-intl";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";
import ResourceCircle from "../../circle/ResourceCircle";
import TextLink from "../../../atoms/TextLink";

interface OrderPreviewRoadBookProps {
  className?: string,
  orderItem: OrderItem,
}

const OrderPreviewRoadBook: FunctionComponent<OrderPreviewRoadBookProps> = (
  {
    orderItem,
  }) => {
  const [panelOpen, setPanelOpen] = React.useState<boolean>(false);
  const [orderItemId, setOrderItemId] = React.useState<string>();
  const intl = useIntl();

  const showActivityPanel = (orderItemId: string) => {
    setOrderItemId(orderItemId)
    setPanelOpen(true);
  }

  return (
    <>
      <Row className="mb-5">
        <Col className="border-start border-2 border-gray" xs={24} sm={16} md={14}>
          <Row className="mb-2">
            <Col xs="auto"><span
              className="bold">{dateUtils.formatLocalTime(orderItem.rendezVousHour)}</span><span> - {orderItem.name}</span></Col>
          </Row>
          <Row className="mb-2">
            <Col xs="auto"><Icon className="me-1" name={"ClockRegular"} color={ColorType.SECONDARY}/><span className="me-3 epow-secondary">{dateUtils.formatDuration(orderItem.duration)}</span></Col>
            <Col xs="auto"><Icon className="me-1" name={"UserRegular"} color={ColorType.SECONDARY}/><span className="me-3 epow-secondary">{orderItem.pax}</span></Col>
            <Col xs="auto"><Icon className="me-1" name={"LocationDot"} color={ColorType.SECONDARY}/><span className="epow-secondary">{orderItem.rendezVousPlace}</span></Col>
          </Row>
        </Col>
        <Col xs={24} sm={8} md={6} className="border-sm-left border-2 border-gray">
          {orderItem.resourceStaffing.filter((resourceStaffing) => resourceStaffing.status === ActivityStaffingStatus.CONFIRMED).map((resource) => {
            return (
              <div key={resource.resourceId}>
                <Row className="mb-2">
                  <Col xs={"auto"}>
                    <div className={"d-flex align-items-center"}>
                      <ResourceCircle resourceType={resource.resourceType} className="me-2" />
                      <Icon className="me-2" name={"UserCircle"} color={"gray"} size={IconSizeType.SM}/>
                      <span className="me-1">{resource.firstName}</span>
                      <span className="me-2">{resource.lastName.charAt(0)}</span>
                      <TextLink to={`tel:${resource.phoneNumber}`} external>{resource.phoneNumber}</TextLink>
                    </div>
                  </Col>
                </Row>
              </div>
            )
          })}
        </Col>
        <Col xs={24} md={4} className="align-self-end text-end">
          <p className="color-primary text-underline cursor-pointer" onClick={() => showActivityPanel(orderItem.id)}>{intl.formatMessage({id: "road_book_modal_ore_details"})}</p>
        </Col>
      </Row>
      <ActivityPanel open={panelOpen} setOpen={setPanelOpen} profile={authUtils.getProfile()} orderItemId={orderItemId} />

    </>
    )
}
export default OrderPreviewRoadBook
