import {Formik, FormikHelpers, FormikValues} from "formik";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Label} from "reactstrap";
import {FORGOTTEN_PASSWORD_PATH} from "../../../constants/routes/RoutePaths";
import {yupRequiredEmail, yupRequiredPassword} from "../../../constants/yup/Validators";
import {IconPlacement, LogoSizeType, SizeType} from "../../../types/bootstrap/BootstrapType";
import TextLink from "../../atoms/TextLink";
import Title from "../../atoms/Title";
import Icon from "../../icon/Icon";
import FormInput from "../input/FormInput";
import {object as YupObject} from "yup";
import Button from "../../atoms/Button";
import EpowForm from "../form/EpowForm";
import Logo from "../../atoms/logo/Logo";
import {LOGO_DARK} from "../../../constants/Assets";

interface LoginField {
  email: string,
  password: string
}

interface LoginFormProps {
  error: string,
  onLogin: (values: LoginField, {resetForm}: FormikHelpers<FormikValues>) => void,
}

const LoginForm: FunctionComponent<LoginFormProps> = ({
  error,
  onLogin
}) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const loginSchema = YupObject().shape({
    email: yupRequiredEmail(3, 50),
    password: yupRequiredPassword,
  })

  const initialValues = {
    email: "",
    password: ""
  }

  const onIconClick = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Col className="d-flex flex-column justify-content-center align-items-center p-5">
      <Col xl={16} lg={20}>
        <div className="d-flex flex-column justify-content-start text-start mb-5">
          <Logo imageSource={LOGO_DARK} width={LogoSizeType.XXL} alt="SOFTOURING" className="m-auto mb-5" />
          <Title title={intl.formatMessage({id: "login_title"})} className="mb-1 text-start" />
          <Label className="epow-label epow-light-label">
            <FormattedMessage id="login_subtitle" />
          </Label>
        </div>
        {error && (
          <Label className="epow-error-login text-center">
            <Icon name="InfoCircle" className="me-2" />
            <FormattedMessage id={error} />
          </Label>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={onLogin}>
          {({errors, touched, values, setFieldValue}) => (
            <EpowForm className="d-flex flex-column justify-content-center">
              <FormInput
                id="email"
                label="form_login_email"
                placeholder={intl.formatMessage({id: "email_placeholder"})}
                value={values?.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                required
                error={errors.email}
                touched={touched.email}
              />
              <FormInput
                id="password"
                label="form_login_password"
                type={showPassword ? "text" : "password"}
                placeholder={intl.formatMessage({id: "password_placeholder"})}
                value={values?.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
                required
                error={errors.password}
                touched={touched.password}
                icon="Eye"
                iconPlacement={IconPlacement.RIGHT}
                onIconClick={() => onIconClick()}
              />
              <TextLink to={FORGOTTEN_PASSWORD_PATH} className="d-flex justify-content-end epow-text-link" >
                <FormattedMessage id="login_reset_password" />
              </TextLink>
              <Button type="submit" size={SizeType.MD} className="main-gradient-button mt-3">
                <FormattedMessage id="login_submit" />
              </Button>
            </EpowForm>
          )}
        </Formik>
      </Col>
    </Col>
  )
}

export default LoginForm;
