import {
  yupRequiredString,
  yupRequiredStringIban,
} from "constants/yup/Validators";
import * as Yup from "yup";

export const CreateBankAccountSchema = Yup.object().shape({
  accountName: yupRequiredString,
  bankName: yupRequiredString,
  bankCode: yupRequiredString,
  counterCode: yupRequiredString,
  accountNumber: yupRequiredString,
  ribKey: yupRequiredString,
  ibanCode: yupRequiredStringIban,
  bicCode: yupRequiredString,
});
