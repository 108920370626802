import {useEffect, useState} from "react";
import {Data} from "../../../interfaces/TableInterfaces";
import {PageResponse, PaginationQueryParams, SortOptions} from "../../../interfaces/api/PaginationInterface";
import {toastUtils} from "../../../utils/toastUtils";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {ApiError} from "../../../interfaces/ErrorInterfaces";
import {useIntl} from "react-intl";

interface UsePagedTableInstance {
    data: Data[],
    page: number,
    sortOptions: Array<SortOptions>,
    total: number,
    onChangePage: (page: number) => void,
    onChangeSortOptions: (sortOptions: Array<SortOptions>) => void,
    refresh: () => void
}

interface UsePagedTableOptions {
    defaultSortBy?: Array<SortOptions>,
    mandatorySortBy?: Array<SortOptions>,
    pageSize: number,
    filters: Object,
}

interface UsePagedTableProps {
    fetchData: (params: PaginationQueryParams, filterParams?: SearchFilter) => Promise<PageResponse<Data>>
    options: UsePagedTableOptions
    afterFetch?: (pageResponse: PageResponse<Data>) => void
}

function usePagedTable({ fetchData, options, afterFetch }: UsePagedTableProps ): UsePagedTableInstance {
    const intl = useIntl()
    const [data, setData] = useState<Data[]>([])
    const [page, setPage] = useState<number>(0)
    const [sortOptions, setSortOptions] = useState<Array<SortOptions>>(options?.defaultSortBy.map(item => ({sortBy: item.sortBy, descending: item.descending, ignoreCase: item.ignoreCase})))
    const [total, setTotal] = useState<number>(0)


    const updateData = (pageableQueryParams: PaginationQueryParams, filters) => {
        const formattedQueryParams = {...pageableQueryParams, sortOptions: [...options.mandatorySortBy, ...pageableQueryParams.sortOptions]};
        fetchData && fetchData(formattedQueryParams, filters)
            .then((pageResponse) => {
                setData(pageResponse?.content ?? [])
                setPage(pageableQueryParams.page)
                setTotal(pageResponse?.totalPages)
                afterFetch && afterFetch(pageResponse)
            })
            .catch((error: ApiError) => {
                toastUtils.errorToast(error.message ?? intl.formatMessage({id: "error_toast_sorting"}))
            })
    }

    const refresh = () => {
        const pageableQueryParams = {page, pageSize: options?.pageSize, sortOptions}
        updateData(pageableQueryParams, options?.filters)
    }

    useEffect(() => {
        const pageableQueryParams = {page, pageSize: options?.pageSize, sortOptions}
        updateData(pageableQueryParams, options?.filters)
    }, [page, sortOptions, options.pageSize]);

    useEffect(() => {
        const pageableQueryParams = {page: 0, pageSize: options?.pageSize, sortOptions}
        updateData(pageableQueryParams, options?.filters)
    }, [options?.filters]);

    return {
        data,
        page,
        refresh,
        total,
        sortOptions,
        onChangePage: (page) => setPage(page),
        onChangeSortOptions: (sortOption: Array<SortOptions>) => setSortOptions(sortOption),
    }
}

export default usePagedTable;
