import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Label, Card, CardBody, CardTitle} from "reactstrap";
import {PROFILE, User} from "../../../interfaces/UserInterfaces";
import Title from "../../atoms/Title";
import Icon from "../../icon/Icon";

interface LoginProfileSelectionFormProps {
  user: User,
  onSubmitProfile: (profile: PROFILE) => void,
  setStep: (step: number) => void,
}

const LoginProfileSelectionForm: FunctionComponent<LoginProfileSelectionFormProps> = ({
  user,
  onSubmitProfile,
  setStep,
}) => {
  const intl = useIntl()

  return (
    <Col className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center p-4">
        <Title title={intl.formatMessage({id: "profile_title"}) + user.firstName + ","} />
        <Label className="opacity-50 h6"><FormattedMessage id="profile_subtitle" /></Label>
      </div>
      <div className="d-flex flex-column justify-content-center align-content-center">
        {user.profile.map((profile, key) => (
          <Card key={key} role={"button"} onClick={() => onSubmitProfile(profile)} className="d-flex align-content-center justify-content-center w-100 m-2">
            <CardBody className="d-flex flex-column justify-content-center align-content-center w-100 m-0">
              <CardTitle tag="h6" className="d-flex flex-row align-content-center justify-content-between m-0">
                <Label role="button" className="d-flex flex-row align-content-center justify-content-center m-0">
                  <FormattedMessage id="access_platform" />
                  <FormattedMessage id={profile} />
                </Label>
                <Icon name="ChevronRight" className="me-1" />
              </CardTitle>
            </CardBody>
          </Card>))
        }
      </div>
      <div className="d-flex flex-row justify-content-end align-content-center p-3">
        <Label role="button" className="h6 text-underline" onClick={() => setStep(0)}>
          <Icon name="ChevronLeft" className="me-1" />
          <FormattedMessage id="back" />
        </Label>
      </div>
    </Col>
  )
}

export default LoginProfileSelectionForm;
