import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import usePaginatedData from "../../../hooks/usePaginatedData";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ActivityBillingSearchObject} from "../../../interfaces/ActivityBllingInterface";
import {Resource} from "../../../interfaces/ResourceInterfaces";
import {activityBillingService} from "../../../services/ActivityBillingService";
import {resourceService} from "../../../services/ResourceService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Pagination from "../pagination/Pagination";
import ActivityBillingOverviewTable from "../table/activityBilling/ActivityBillingOverviewTable";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import HeaderButtonGroup, {HeaderStateButton} from "../header/HeaderButtonGroup";
import PageTitle from "../header/PageTitle";

interface ActivityBillingOverviewListProps {
  className?: string,
}

const ActivityBillingOverviewList: FunctionComponent<ActivityBillingOverviewListProps> = ({
  className = "",
}) => {
  const intl = useIntl()
  const initialSearchObject: ActivityBillingSearchObject = {
    resourceId: "",
    number: "",
    startDate: "",
    endDate: "",
    paid: false
  }
  const {searchObject, updateSearchField, onReset} = useSearchFilter<ActivityBillingSearchObject>(initialSearchObject)
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(activityBillingService.getActivityBillingPage, searchObject)

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [
    {
      title: intl.formatMessage({id: "activity_bill_filter_header_unpaid"}),
      onclick: () => updateSearchField("paid", false),
      active: true
    },
    {
      title: intl.formatMessage({id: "activity_bill_filter_header_paid"}),
      onclick: () => updateSearchField("paid", true),
      active: false
    }], [intl])

  return (
    <div className={className}>
      <div className="d-flex mb-5">
        <PageTitle pageTitle={intl.formatMessage({id: "billing_overview_title"})}></PageTitle>
        <div className="d-flex justify-content-center flex-1">
          <HeaderButtonGroup buttonList={stateFilterButtons} />
        </div>
      </div>

      <FilterGroup className="d-flex gap-2 mb-3">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "billing_overview_number"})}
          value={searchObject.number}
          onChange={(value) => updateSearchField("number", value)}
          placeholder={intl.formatMessage({id: "billing_overview_number_placeholder"})}
        />

        <AutocompleteFilterPageable
          title={intl.formatMessage({id: "billing_overview_resource"})}
          value={searchObject?.resourceId}
          onChange={(value) => updateSearchField("resourceId", value)}
          fetchData={resourceService.getResourcesPage}
          filterFieldName="nameSurname"
          manageOptions={(response: Resource[]) => response.map(option =>
            ({value: option.id, label: `${option.identity?.firstName} - ${option.identity?.lastName}`})
          )}
          optionFromValue={(response: Resource[], value: string) => {
            const option = response?.find((resource) => resource.id === value);
            return option ? {
              label: `${option.identity?.firstName} - ${option.identity?.lastName}`,
              value: option?.id
            } : null;
          }}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_start"})}
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
        />

        <DateFilter
          title={intl.formatMessage({id: "billing_overview_period_end"})}
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
        />

        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <ActivityBillingOverviewTable
        refresh={refresh}
        activityBillings={data}
        sortOptions={sortOptions}
        onSortOptions={setSortOptions}
        disableSelect={searchObject.paid === true}
      />
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default ActivityBillingOverviewList;
