import React, {FunctionComponent, useEffect, useState} from "react";
import Panel from "../Panel";
import {resourceService} from "../../../../services/ResourceService";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {Resource} from "../../../../interfaces/ResourceInterfaces";
import {Loader} from "react-bootstrap-typeahead";
import AvailableCalendarStaffingPanelBody from "./AvailableCalendarStaffingPanelBody";
import {staffingCalendarService} from "../../../../services/StaffingCalendarService";
import {StaffingCalendarSearchFilter} from "../../staffingcalendar/StaffingCalendar";
import {ResourceDayActivitiesSearchParameters} from "../../../../interfaces/CalendarInterface";

interface AvailableCalendarStaffingPanelProps {
  className?: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  date: string,
  resourceId: string,
  availabilityType?: string,
  searchObject: StaffingCalendarSearchFilter,
  onReload: () => void,
}

const AvailableCalendarStaffingPanel: FunctionComponent<AvailableCalendarStaffingPanelProps> = ({
  className = "",
  open,
  setOpen,
  date,
  resourceId,
  availabilityType,
  searchObject,
  onReload,
}) => {
  const [resource, setResource] = useState<Resource>({})
  const [staffings, setStaffings] = useState<OrderItemStaffingPageItemDto[]>([])
  const [staffingsFiltered, setStaffingsFiltered] = useState<OrderItemStaffingPageItemDto[]>([])
  const [items, setItems] = useState<OrderItemStaffingPageItemDto[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const getAllInfos = () => {
    const filters: ResourceDayActivitiesSearchParameters = {
      date,
      period: availabilityType,
      activityId: searchObject.activityId,
      activityAreaId: searchObject.activityAreaId,
      customerId: searchObject.customerId,
      orderId: searchObject.orderId,
      tourId: searchObject.tourId,
    }

    // need to get the resource
    const getResource = resourceService.getResource(resourceId);
    // need to get activity staffings on that day for this resource, with filters => Disponibilité confirmée
    const getActivityStaffingsFiltered = staffingCalendarService.getStaffingsForResourceOnDay(resourceId, filters, {page: 0, pageSize: 20})
    // need to get activity staffings on that day for this resource => Disponibilité confirmée (hors filtre)
    const getActivityStaffings = staffingCalendarService.getStaffingsForResourceOnDay(resourceId, {date, period: availabilityType}, {page: 0, pageSize: 20})
    // need to get any order item that can be staffed on this day and available to the selected resource, with filters => Prestation à staffer
    const getStaffableOrderItems = staffingCalendarService.getStaffableOrderitemsOnDay(resourceId, filters, {page: 0, pageSize: 20})

    Promise.all([getResource, getActivityStaffingsFiltered, getActivityStaffings, getStaffableOrderItems])
      .then(([resource, staffingsFiltered, staffings, orderItems]) => {
        setResource(resource)
        setStaffingsFiltered(staffingsFiltered.content)

        const staffingWithoutDuplicates = staffings.content?.filter(s => !staffingsFiltered.content?.some(sf => sf.id === s.id)) 
        setStaffings(staffingWithoutDuplicates)

        const staffableWithoutDuplicates = orderItems.content?.filter(o => !staffings.content?.some(s => s.id === o.id))
        setItems(staffableWithoutDuplicates)

        if(resource) {
          setLoading(false)
        }
      })
      .catch(console.error)
  }

  useEffect(() => {
    getAllInfos()
  }, [])

  const onStaffingChange = () => {
    getAllInfos()
    onReload()
  }

  return (
    <div className={className}>
      <Panel
        title={`Staffing ${resource?.identity?.firstName} ${resource?.identity?.lastName}`}
        cancelButtonLabel="close"
        displayValidateButton={false}
        open={open}
        onCancel={() => setOpen(false)}>

        {loading ? (
          <Loader />
        ) : (
          <AvailableCalendarStaffingPanelBody
            date={date}
            availabilityType={availabilityType}
            resource={resource}
            staffings={staffings}
            staffingsFiltered={staffingsFiltered}
            items={items}
            refresh={onStaffingChange}
          />
        )}

      </Panel>
    </div>
  )
}

export default AvailableCalendarStaffingPanel;
