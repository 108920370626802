import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Col, Input} from "reactstrap";
import {CUSTOMERS_PATH, ORDERS_PATH, TOUR_LEADERS_PATH} from "../../../../constants/routes/RoutePaths";
import {Identity} from "../../../../interfaces/ContactInterfaces";
import {OrderList, OrderStateMap} from "../../../../interfaces/OrderInterfaces";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Badge from "../../../atoms/Badge";
import Icon from "../../../icon/Icon";
import TextLink from "../../../atoms/TextLink";
import {dateUtils} from "../../../../utils/dateUtils";
import {numberUtils} from "../../../../utils/numberUtils";

interface OrderTableRowProps {
  className?: string,
  order: OrderList,
  onRowClick?: (orderId: string) => void,
  onSelectOrder?: (order: OrderList) => void,
  isCustomer?: boolean,
  canEdit?: boolean,
  hasCheckbox?: boolean,
  orderIsToModify?: boolean,
  toogleOrderToModify?: (order: OrderList) => void,
  itemPrefixPath: string,
}

const OrderTableRow: FunctionComponent<OrderTableRowProps> = ({
  className = "",
  order,
  onRowClick,
  isCustomer,
  onSelectOrder,
  canEdit,
  hasCheckbox,
  orderIsToModify,
  toogleOrderToModify,
  itemPrefixPath = ORDERS_PATH,
}) => {
  const intl = useIntl()
  const tourLeaderIdentity: Identity = order.tourLeaderIdentity

  return (
    <div onClick={() => onRowClick(order.id)} className={`epow-custom-table-row-main sticky-main-row ${className ?? ""}`}>
      {hasCheckbox && (
        <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
          <Input
            className="ms-2 me-2"
            type="checkbox"
            checked={orderIsToModify}
            onChange={() => toogleOrderToModify(order)}
          />
        </div>
      )}

      {
        !isCustomer && (
          <Col>
            <TextLink to={`${CUSTOMERS_PATH}/${order?.customer?.id}`}>
              <>{order?.customer?.name}</>
            </TextLink>
          </Col>
        )
      }

      <Col>
        {order?.customerReference}
      </Col>

      <Col>
        {dateUtils.formatToLocalDateString(dateUtils.parseDate(order?.beginTourDate))}
      </Col>

      <Col>
        <TextLink to={`${TOUR_LEADERS_PATH}/${order?.tourLeaderId}`}>
          <>{tourLeaderIdentity?.firstName} {tourLeaderIdentity?.lastName}</>
        </TextLink>
      </Col>

      <Col>
          {order.items?.length}
      </Col>

        {isCustomer &&
            <Col>
                <div>
                    {numberUtils.formatPrice(order.items.reduce((acc, item) => acc + item.sellPrice, 0))} €
                </div>
            </Col>
        }

      <Col>
        <div className={isCustomer ? "float-end" : ""}>
          {order?.orderNumber}
        </div>
      </Col>

      {!isCustomer && (
        <>
          <Col>
            <Badge pill color={OrderStateMap[order.orderStatus]?.color}>
              {intl.formatMessage({id: OrderStateMap[order.orderStatus]?.label})}
            </Badge>
          </Col>
          <Col onClick={(event) => event.stopPropagation()}>
            <div className="float-end">
              {canEdit &&
                <Icon name="Pen" onClick={() => onSelectOrder(order)} size={IconSizeType.XS} color="secondary"
                  className="pe-2 cursor-pointer" />}
              <Link className="table-action-link" to={`${itemPrefixPath}/${order.id}`}>
                <Icon name="Search"
                  size={IconSizeType.XS}
                  className={"pe-2"} /></Link>
            </div>
          </Col>
        </>
      )}

    </div>
  )
}

export default OrderTableRow;
