import {API_CATALOG_ACTIVITY_CUSTOMERS_PATH} from "../constants/routes/RoutePaths"
import {dateUtils} from "../utils/dateUtils"
import {httpService} from "./HttpService"
import {SellInformations} from "../interfaces/CatalogActivityCustomerInterfaces";

function getInfosForActivityCustomer(activityId: string, customerId: string, date: string): Promise<SellInformations> {
  return httpService.get<SellInformations>(`${API_CATALOG_ACTIVITY_CUSTOMERS_PATH}/${activityId}/${customerId}/informations?date=${dateUtils.formatDateYYYYMMDD(dateUtils.parseDate(date))}`)
}

export const catalogActivityCustomerService = Object.freeze({
  getInfosForActivityCustomer,
})
