import React, {FunctionComponent} from "react";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {orderService} from "../../../../services/OrderService";
import OrderTable from "../../table/order/OrderTable";
import {OrderSearchObject} from "../../../../interfaces/OrderInterfaces";
import {MY_TOURS_PATH} from "../../../../constants/routes/RoutePaths";

interface TourLeaderDashboardOrderListProps {
  className?: string,
  searchObject?: OrderSearchObject,
  updateSearchField?: (field: string, value: string) => void
  onReset?: () => void,
}

const TourLeaderDashboardOrderList: FunctionComponent<TourLeaderDashboardOrderListProps> = ({
  className = "",
  searchObject,
}) => {

  const {
    data,
    sortOptions,
    setSortOptions,
    refresh,
    totalElements,
  } = usePaginatedData(orderService.getOrdersPage, searchObject, "", 5)

  return (
    <div className={className}>
      <OrderTable
        orders={data}
        total={totalElements}
        sortOptions={sortOptions}
        refresh={refresh}
        onSortOptions={setSortOptions}
        itemPrefixPath={MY_TOURS_PATH}
      />
    </div>
  )
}

export default TourLeaderDashboardOrderList;
