import {User, UserSearchObject, UserSelfProfile} from "../interfaces/UserInterfaces";
import {httpService} from "./HttpService";
import {API_USER_PATH, API_USER_RESOURCE_PATH} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import { Resource } from "interfaces/ResourceInterfaces";

function getUsers(params: PaginationQueryParams, filterParams: UserSearchObject): Promise<PageResponse<User>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<User>>(`${API_USER_PATH}${queryParams}`)
}

function getMe(activeCustomerId?: string): Promise<UserSelfProfile> {
  return httpService.get<UserSelfProfile>(`${API_USER_PATH}/me${activeCustomerId ? `?activeCustomerId=${activeCustomerId}` : ""}`);
}

function toggleUserActivation(id: string, isActivated: boolean): Promise<boolean> {
  return httpService.put<boolean>(`${API_USER_PATH}/${id}/toggle-activation`, isActivated);
}

function updateUser(id: string, user: User): Promise<User> {
  return httpService.put<User>(`${API_USER_PATH}/${id}`, user);
}

function getResource(id: string): Promise<Resource> {
  return httpService.get<Resource>(`${API_USER_RESOURCE_PATH}/${id}`);
}

function updateSelfUser(user: User): Promise<User> {
  return httpService.put<User>(`${API_USER_PATH}/me`, user);
}

function createUser(user: User): Promise<User> {
  return httpService.post<User>(`${API_USER_PATH}`, user);
}

export const userService = {
  getUsers,
  getMe,
  createUser,
  getResource,
  toggleUserActivation,
  updateUser,
  updateSelfUser,
}
