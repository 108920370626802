import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import GeneralInfoActivityCard from "./GeneralInfoActivityCard";
import ActivityServicesCard from "./ActivityServicesCard";
import {Col, Row} from "reactstrap";
import ImageCard from "../image/ImageCard";
import {PictureType, PictureTypeProperties} from "../../../../constants/FileConstants";
import {activityResourceRequirementService} from "../../../../services/ActivityResourceRequirementService";
import {toastUtils} from "../../../../utils/toastUtils";
import {ActivityResourceRequirement} from "../../../../interfaces/ActivityResourceRequirementInterfaces";
import Title from "../../../atoms/Title";

interface DetailsActivityCardProps {
  className?: string,
  activity: Activity,
  setActivity?: (activity: Activity) => void
}

const DetailsActivityCard: FunctionComponent<DetailsActivityCardProps> = ({
  className = "",
  activity,
  setActivity
}) => {
  const intl = useIntl()
  const [resourceRequirements, setResourceRequirements] = useState<ActivityResourceRequirement[]>([])

  useEffect(() => {
    if (activity?.id) {
      activityResourceRequirementService.getRequirementsForActivity(activity.id)
        .then((requirements) => {
          setResourceRequirements(requirements);
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_activity_resource_requirements"}));
        });
    }
  }, [activity])

  return (
    <Row className={className}>
      <Col xs={24} md={16}>
        <GeneralInfoActivityCard className="d-flex flex-column" title={intl.formatMessage({id: "customer_general_info"})} activity={activity} setActivity={setActivity}/>
      </Col>
      <Col xs={24} md={8}>
        <ImageCard
          className="mb-4"
          title={intl.formatMessage({id: "picture_title"})}
          entityId={activity.id}
          fileType={PictureType.ACTIVITY_PICTURE}
          aspect={PictureTypeProperties.ACTIVITY_PICTURE.pictureWidth/PictureTypeProperties.ACTIVITY_PICTURE.pictureHeight}
        />
        <ActivityServicesCard className="d-flex flex-column" activity={activity} title={intl.formatMessage({id: "service_title"})}/>
        {
          resourceRequirements?.length > 0 && (
            <div className="mt-4">
              <div className="mb-3">
                <Title title={intl.formatMessage({id: "required_resources_tab"})} size="h5" />
              </div>
              {
                resourceRequirements.map((requirement) => (
                  <div className="mb-1" key={requirement.id}>{`${requirement.resourceType?.label} : ${requirement.quantity}`}</div>
                ))
              }
            </div>
          )
        }
      </Col>
    </Row>
  )
}

export default DetailsActivityCard;
