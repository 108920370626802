import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GenericModal from "../GenericModal";

interface ModalStaffingCancelProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  onDelete: () => void,
  setVisible: (visible: boolean) => void,
}

const ModalStaffingCancel: FunctionComponent<ModalStaffingCancelProps> = ({
  open,
  setOpen,
  onDelete,
  setVisible,
}) => {
  const intl = useIntl()
  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_title_cancel_staffing_item"})}
      open={open}
      closable
      onOpen={setOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: () => {
            onDelete()
            setVisible(false)
          },
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            setOpen(false)
          }
        }
      }}
    >
      <div className="d-flex flex-column">
        {intl.formatMessage({id: "modal_body_cancel_staffing_item"})}
      </div>
    </GenericModal>
  )
}

export default ModalStaffingCancel;
