import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Link} from "react-router-dom";
import {Col} from "reactstrap";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Badge from "../../../atoms/Badge";
import Icon from "../../../icon/Icon";
import {
  CreditNoteStateMap,
  InvoiceItemResponse,
  InvoiceStateMap,
  InvoiceType
} from "../../../../interfaces/InvoiceInterfaces";
import {
  CREDIT_NOTES_PATH,
  CUSTOMERS_PATH,
  INVOICES_PATH
} from "../../../../constants/routes/RoutePaths";
import {fileUtils} from "../../../../utils/fileUtils";
import {invoiceService} from "../../../../services/InvoiceService";
import {dateUtils} from "../../../../utils/dateUtils";
import {moneyUtils} from "../../../../utils/moneyUtils";
import TextLink from "../../../atoms/TextLink";
import {creditNoteService} from "../../../../services/CreditNoteService";

interface OrderTableRowProps {
  className?: string,
  invoice: InvoiceItemResponse,
  onRowClick?: (orderId: string) => void,
}

const BillingTableRow: FunctionComponent<OrderTableRowProps> = ({
  className = "",
  invoice,
  onRowClick = () => null,
}) => {
  const intl = useIntl()

  const onClickDownload = () => fileUtils.downloadFile(invoice.type === InvoiceType.INVOICE ? invoiceService.downloadInvoices(invoice.id) : creditNoteService.downloadCreditNote(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);

  return (
    <div onClick={() => onRowClick(invoice.id)} className={`epow-custom-table-row-main ${className ?? ""}`}>
      <Col className="col-3">
        {invoice?.invoiceNumber}
      </Col>

      <Col className="col-3">
        <TextLink to={`${CUSTOMERS_PATH}/${invoice.billingCustomerId}`}>
          {invoice?.billingCustomerName}
        </TextLink>
      </Col>

      <Col className="col-3">
        { invoice.customerOrderName  ?
          <TextLink to={`${CUSTOMERS_PATH}/${invoice.customerOrderName}`}>
          {invoice.customerOrderName}
        </TextLink> : "-"
        }
      </Col>

      <Col className="col-3">
        {invoice?.firstOrderItemDate && invoice?.lastOrderItemDate ? `${dateUtils.formatToLocalDateString(dateUtils.parseDate(invoice?.firstOrderItemDate))} - ${dateUtils.formatToLocalDateString(dateUtils.parseDate(invoice?.lastOrderItemDate))}` : "-"}
      </Col>

      <Col className="col-3">
        {dateUtils.formatToLocalDateString(dateUtils.parseDate(invoice?.creationDate))}
      </Col>

      <Col className="col-2">
        {moneyUtils.formatNumberToCurrency(invoice?.amountHT)}
      </Col>

      <Col className="col-2">
        {moneyUtils.formatNumberToCurrency(invoice?.amountTTC)}
      </Col>

      <Col className="col-3">
        <Badge pill color={InvoiceStateMap[invoice.status]?.color}>
          {intl.formatMessage({id: invoice.type === InvoiceType.INVOICE ? InvoiceStateMap[invoice.status]?.label : CreditNoteStateMap[invoice.status]?.label})}
        </Badge>
      </Col>

      <Col className="col-2" onClick={(event) => event.stopPropagation()}>
        <div className="float-end">
          <div className="d-flex flex-row">
            <Link className="table-action-link" to={`${invoice.type === InvoiceType.INVOICE ? INVOICES_PATH : CREDIT_NOTES_PATH}/${invoice.id}`}>
              <Icon name="Search"
                size={IconSizeType.XS}
                className={"pe-2"} />
            </Link>
            <div className="table-action-link cursor-pointer"
              onClick={onClickDownload}
            >
              <Icon name="Download" size={IconSizeType.XS} className={"pe-2"} />
            </div>
          </div>
        </div>
      </Col>
    </div>
  )
}

export default BillingTableRow;
