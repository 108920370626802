import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {OrderItemResponse} from "../../../interfaces/OrderItemInterfaces";

interface ModalGenerateInvoiceProps {
  open: boolean,
  onCancel: () => void,
  onValidate: () => void,
  datas: Array<OrderItemResponse>
}

const ModalGenerateInvoice: FunctionComponent<ModalGenerateInvoiceProps> = ({
  open,
  onValidate,
  onCancel,
  datas,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  return (
    <GenericModal open={open}
                  className="p-3"
                  title={intl.formatMessage({id:"billing_generate_preinvoice"})}
                  footer={{
                    primaryAction: {
                      buttonLabel: validateButton,
                      action: onValidate
                    },
                    secondaryAction: {
                      buttonLabel: cancelButton,
                      action: onCancel
                    }
                  }}
    >
      <div>
        <p><FormattedMessage id="billing_generate_invoice_modal_info"/></p>
        <p><FormattedMessage id="billing_generate_invoice_modal_summary"/></p>
        <ul>
          {[...new Set(datas.map(data => data.billingServiceName))].map((name, index) => (
            <li key={index}>{name}</li>
          ))}
        </ul>
      </div>
    </GenericModal>
  )
}

export default ModalGenerateInvoice
