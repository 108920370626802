import {InputProps, InputType} from "reactstrap/types/lib/Input";
import React, {ChangeEventHandler, FunctionComponent} from "react";
import {Input, InputGroup, InputGroupText} from "reactstrap";
import {IconPlacement, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import {IconName} from "../../icon/IconsMap";


export interface TextInputProps extends InputProps {
  className?: string,
  type?: InputType,
  step?: string,
  value?: string | number,
  placeholder?: string
  onChange?: ChangeEventHandler<HTMLInputElement>,
  onBlur?: ChangeEventHandler<HTMLInputElement>,
  icon?: IconName,
  iconSize?: IconSizeType,
  iconPlacement?: IconPlacement,
  invalid?: boolean,
  disabled?: boolean,
  minValue?: number | string
  maxValue?: number | string
  onIconClick?: () => void,
}

const TextInput: FunctionComponent<TextInputProps> = ({
  className = "",
  id,
  type = "text",
  step = "1",
  value,
  placeholder = "",
  iconSize = IconSizeType.XS,
  iconPlacement = IconPlacement.LEFT,
  onChange,
  onBlur,
  checked,
  icon,
  invalid = false,
  disabled = false,
  minValue,
  maxValue,
  rows,
  onIconClick = null,
  ...rest
}) => {

  const displayIcon = () => {
    const iconPlacementClass = iconPlacement === IconPlacement.LEFT ? "icon-left" : "icon-right";
    const iconCursorClass = onIconClick ? "cursor-pointer" : "";

    return (
      <InputGroupText
        onClick={() => onIconClick?.()}
        className={`input-icon-container ${iconPlacementClass} ${iconCursorClass}`}>
        <Icon size={iconSize} name={icon} />
      </InputGroupText>
    )
  }

  return (
    <InputGroup>
      {(icon && iconPlacement == IconPlacement.LEFT) &&
        displayIcon()
      }
      <Input
        id={id}
        type={type}
        step={step}
        placeholder={placeholder}
        min={minValue}
        max={maxValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`epow-input ${icon && "input-with-icon"} ${className}`}
        checked={checked}
        invalid={invalid}
        disabled={disabled}
        rows={rows}
        onIconClick={onIconClick}
        {...rest}
      />
      {(icon && iconPlacement == IconPlacement.RIGHT) &&
        displayIcon()
      }
    </InputGroup>
  )
}


export default TextInput;
