import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {TOUR_ACTIVITY_CUSTOMER_INITIAL_VALUES} from "../../../constants/TourConstants";
import {CreateTourActivityCustomerSchema} from "../../../constants/validation/TourValidationSchemas";
import {Col} from "reactstrap";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {TourActivityCustomer} from "../../../interfaces/TourInterfaces";
import {useIntl} from "react-intl";
import {tourService} from "../../../services/TourService";
import {toastUtils} from "../../../utils/toastUtils";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {customerBillingServiceService} from "../../../services/CustomerBillingServiceService";
import {dateUtils} from "../../../utils/dateUtils";
import EpowForm from "./EpowForm";

interface CreateActivityInTourForCustomerFormProps {
  id?: string,
  className?: string,
  customerId?: string,
  tourActivityCustomer?: TourActivityCustomer,
  onValidate?: () => void
}

const CreateActivityInTourForCustomerForm: FunctionComponent<CreateActivityInTourForCustomerFormProps> = ({
  id,
  className = "",
  customerId,
  tourActivityCustomer,
  onValidate
}) => {
  const intl = useIntl()

  const handleSubmitActivityInTourForCustomer = (tourActivityCustomer: TourActivityCustomer) => {
    if (tourActivityCustomer?.id) {
      tourService.updateTourActivityCustomer(tourActivityCustomer?.id, tourActivityCustomer)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_tour_activity_customer"}))
          onValidate && onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_tour_activity_customer"}))
        })
    }
  }

  return (
    <Formik initialValues={tourActivityCustomer || TOUR_ACTIVITY_CUSTOMER_INITIAL_VALUES} validationSchema={CreateTourActivityCustomerSchema} onSubmit={value => {handleSubmitActivityInTourForCustomer(value)}}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`m-4 ${className}`}>
          <FormAutocompleteSelectPageable
            id="billingClientId"
            label={intl.formatMessage({id: "tour_customer_field"})}
            value={values?.billingClientId}
            onChange={(value) => setFieldValue("billingClientId", value)}
            required
            error={errors?.billingClientId}
            touched={touched?.billingClientId}
            fetchData={(page, filters) => customerBillingServiceService.getBillingServicePage(page, {...filters, customerId})}
            filterFieldName="name"
          />
          <FormInput
            id="clientBillingActivityName"
            label="tour_activity_billing_client_activity_name"
            value={values?.clientBillingActivityName}
            onChange={(e) => setFieldValue("clientBillingActivityName", e.target.value)}
            error={errors.clientBillingActivityName}
            touched={touched.clientBillingActivityName}
            required
          />
          <Col xs={13}>
            <FormInput
              id="dayAfterArrival"
              label="tour_activity_day_after_arrival_field"
              type="number"
              minValue={0}
              value={values?.dayAfterArrival}
              onChange={(e) => setFieldValue("dayAfterArrival", e.target.value)}
              required
              error={errors.dayAfterArrival}
              touched={touched.dayAfterArrival}
            />
          </Col>
          <Col xs={11}>
            <FormInput
              id="hour"
              label="tour_activity_hour_field"
              type="time"
              step="60"
              value={dateUtils.getTimeWithoutSeconds(values?.hour)}
              onChange={(e) => setFieldValue("hour", e.target.value)}
              required
              error={errors.hour}
              touched={touched.hour}
            />
          </Col>
          <Col xs={11}>
            <FormInput
              id="departureHour"
              label="tour_activity_departure_hour_field"
              type="time"
              step="60"
              value={dateUtils.getTimeWithoutSeconds(values?.departureHour)}
              onChange={(e) => setFieldValue("departureHour", e.target.value)}
              error={errors.departureHour}
              touched={touched.departureHour}
            />
          </Col>
          <Col xs={13}>
            <FormInput
              id="meetingPlace"
              label="tour_activity_meeting_place_field"
              value={values?.meetingPlace}
              onChange={(e) => setFieldValue("meetingPlace", e.target.value)}
              error={errors.meetingPlace}
              touched={touched.meetingPlace}
            />
          </Col>
          <Col>
            <FormRadio
              id="optional"
              label="tour_activity_optional_field"
              value={YES_NO_OPTIONS.find((option) => option?.value === values?.optional)}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) => setFieldValue("optional", option?.value)}
              required
              error={errors.optional}
              touched={touched.optional}
            />
          </Col>
          <FormInput
            id="description"
            label="tour_activity_description_field"
            type="textarea"
            value={values?.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            required
            error={errors.description}
            touched={touched.description}
            rows="5"
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivityInTourForCustomerForm
