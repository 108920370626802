import Button from "components/atoms/Button";
import Title from "components/atoms/Title";
import CreateCommentForm from "components/molecules/form/CreateCommentForm";
import {Comment, CommentRequest} from "interfaces/CommentInterface";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col} from "reactstrap";
import {ColorType, SizeType} from "types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";

interface CommentCardProps {
  className?: string;
  comment?: Comment;
  createComment?: (commentRequest: CommentRequest) => Promise<void>;
  updateComment?: (commentRequest: CommentRequest) => Promise<void>;
  deleteComment?: () => void;
  title?: string;
}

const CommentCard: FunctionComponent<CommentCardProps> = ({
  className = "",
  comment,
  createComment,
  updateComment = null,
  deleteComment = () => null,
  title,
}) => {
  const intl = useIntl();
  const [editInfo, setEditInfo] = useState<boolean>(false);

  const onCreate = (commentRequest: CommentRequest) => {
    createComment(commentRequest)
      .then(() => {
        setEditInfo(false);
      })
      .catch(() => {
        // Nothing to do
      })
  };

  const onUpdate = (commentRequest: CommentRequest) => {
    const updateCommentRequest = {
      ...commentRequest,
      id: comment.id
    }
    updateComment(updateCommentRequest)
      .then(() => {
        setEditInfo(false);
      })
      .catch(() => {
        // Nothing to do
      })
  };

  const onDelete = () => {
    deleteComment()
  };

  const displayCommentIfExists = () => {
    return comment?.id ? (
      <>
        <span className="mb-2 fs-6 white-space-pre-wrap">{comment.content}</span>
        {comment.creationDate && comment.author && (
          <span className="mb-3 text-muted">
            {intl.formatMessage(
              {id: "comment_added_by"},
              {
                date: dateUtils.formatDateToLocalDateTime(comment.creationDate),
                firstName: comment.author.firstName,
                lastName: comment.author.lastName,
              }
            )}
          </span>
        )}
        <div>
          <Button
            color={ColorType.SECONDARY}
            onClick={onDelete}
            className="extended"
          >
            <FormattedMessage id="delete_button" />
          </Button>
          {updateComment && (
            <Button
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(!editInfo)}
              className="ms-2 extended"
            >
              <FormattedMessage id="edit_button" />
            </Button>
          )}
        </div>
      </>
    ) : (
      <div>
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setEditInfo(!editInfo)}
          className="extended"
        >
          <FormattedMessage id="add_button" />
        </Button>
      </div>
    );
  };

  return (
    <Col className={`d-flex flex-column ${className}`}>
      <div className="mb-3">
        <Title title={title} size="h5" />
      </div>
      {editInfo ? (
        <>
          <CreateCommentForm
            formId={"commentForm"}
            initialValue={comment?.content}
            submitCallback={comment?.id ? onUpdate : onCreate}
          />
        </>
      ) : (
        displayCommentIfExists()
      )}
    </Col>
  );
};

export default CommentCard;
