import React, {FunctionComponent, useEffect, useState} from "react";
import {supplierServiceService} from "../../../../services/SupplierServiceService";
import {SupplierService} from "../../../../interfaces/SupplierInterfaces";
import {toastUtils} from "../../../../utils/toastUtils";
import {useIntl} from "react-intl";
import SupplierServiceList from "../../list/SupplierServiceList";

interface SupplierServiceCardProps {
  className?: string,
  serviceId?: string
}

const SupplierServiceCard: FunctionComponent<SupplierServiceCardProps> = ({
  className = "",
  serviceId
}) => {
  const [supplierServiceCatalogs, setSupplierServiceCatalogs] = useState<SupplierService[]>([]);
  const intl = useIntl();

  const getSupplierCatalog = () => {
    if (serviceId) {
      supplierServiceService.getSuppliersByService(serviceId)
        .then((suppliers) => {
          setSupplierServiceCatalogs(suppliers);
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_suppliers_service"}))
        });
    }
  };

  const handleValidate = () => {
    getSupplierCatalog()
  }

  useEffect(() => {
    getSupplierCatalog();
  }, [serviceId]);

  return (
    <div className={className}>
      <SupplierServiceList
        className="mb-3"
        supplierServiceList={supplierServiceCatalogs}
        onValidate={handleValidate}
      />
    </div>
  )
};

export default SupplierServiceCard
