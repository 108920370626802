import React, {FunctionComponent} from "react";

interface FilterGroupProps {
  className?: string,
  children: React.ReactElement[] | React.ReactElement,
}

const FilterGroup: FunctionComponent<FilterGroupProps> = ({
  className = "",
  children,
}) => {
  return (
    <div className={`filter-group d-flex align-items-end ${className}`}>
      {children}
    </div>
  )
}

export default FilterGroup;