import React, {FunctionComponent, useEffect, useState} from "react";
import {Label} from "reactstrap";
import {OrderCsvItem} from "../../../../interfaces/OrderInterfaces";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import {format} from "date-fns";
import {validateYupSchema} from "formik";
import {ImportOrderSchema} from "../../../../constants/validation/OrderValidationSchemas";
import {ValidationError} from "yup";
import OrderImportCardField from "./OrderImportCardField";
import {dateUtils} from "../../../../utils/dateUtils";
import OrderImportCardSelect from "./OrderImportCardSelect";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {customerService} from "../../../../services/CustomerService";
import {tourService} from "../../../../services/TourService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";

interface OrderImportCardProps {
  className?: string,
  item: OrderCsvItem,
  itemList: OrderCsvItem[],
  setItemList: (values: OrderCsvItem[]) => void,
  customerOptions: Option<string>[],
  tourOptionsForCustomer: Map<string, Option<string>[]>,
}

const OrderImportCard: FunctionComponent<OrderImportCardProps> = ({
  className = "",
  item,
  itemList,
  setItemList,
  customerOptions,
  tourOptionsForCustomer,
}) => {

  const [errors, setErrors] = useState<ValidationError[]>([]);

  const updateOrderCsvItem = (lineNumber: number, field: string, value: string | Date) => {
    const indexItem = itemList.findIndex(value => value.lineNumber === lineNumber)
    let item = itemList.at(indexItem);
    item = {...item, [field]: value}

    const updatedList = [...itemList]
    updatedList.splice(indexItem, 1, item)
    setItemList(updatedList)
  }

  const removeLine = (lineNumber: number) => {
    const newList = [...itemList].filter(order => order.lineNumber !== lineNumber)
    setItemList(newList)
  }

  const getError = (field: string) => {
    return errors.filter(e => e.path === field)?.at(0)?.message ?? null
  }

  useEffect(() => {
    try {
      void validateYupSchema(item, ImportOrderSchema(customerOptions, tourOptionsForCustomer), true)
      setErrors([])
    } catch (error) {
      if (error instanceof ValidationError) {
        setErrors(error?.inner)
      }
    }
  }, [item])

  return (
    <div className={`d-flex flex-row w-100  m-1 ${className}`}>
      <div className="col-1">
        <Label className="col-1 mt-2">{item.lineNumber + 1}</Label>
      </div>
      <div className="col-5">
        <OrderImportCardSelect
          className="mx-2"
          value={item.client}
          onChange={(value) => updateOrderCsvItem(item.lineNumber, "client", value)}
          error={getError("client")}
          fetchData={customerService.getCustomersPage}
          filterFieldName="name"
        />
      </div>
      <div className="col-5">
        <OrderImportCardSelect
          className="mx-2"
          value={item.tour}
          onChange={(value) => updateOrderCsvItem(item.lineNumber, "tour", value)}
          error={getError("tour")}
          fetchData={(page, filter) => tourService.getToursPage(page, {
            ...filter,
            customerId: item?.client,
            currentState: WorkflowStatesEnum.ACTIVE
          })}
          filterFieldName="fullName"
          filterFieldSearch="tourName"
        />
      </div>
      <div className="col-4">
        <OrderImportCardField
          className="mx-2"
          value={item.referenceClient}
          error={getError("referenceClient")}
          onChange={(e) => updateOrderCsvItem(item.lineNumber, "referenceClient", e.target.value)}
        />
      </div>
      <div className="col-4">
        <OrderImportCardField
          className="mx-2"
          type="date"
          value={item.dayZeroTour ? format(new Date(item.dayZeroTour), "yyyy-MM-dd") : ""}
          error={getError("dayZeroTour")}
          onChange={e => updateOrderCsvItem(item.lineNumber, "dayZeroTour", dateUtils.parseDate(e.target.value))}
        />
      </div>
      <div className="col-4">
        <OrderImportCardField
          className="mx-2"
          type="date"
          value={item.date ? format(new Date(item.date), "yyyy-MM-dd") : ""}
          error={getError("date")}
          onChange={e => updateOrderCsvItem(item.lineNumber, "date", dateUtils.parseDate(e.target.value))}
        />
      </div>

      <div className="col-1">
        <Icon className="cursor-pointer mt-2" name="Trash" size={IconSizeType.XS} onClick={() => removeLine(item.lineNumber)} />
      </div>

    </div>
  )
}

export default OrderImportCard;
