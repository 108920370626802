import React, {FunctionComponent, useContext} from "react";
import {ResourceAvailabilityDispatchType, ResourceAvailabilityDto} from "../../../../interfaces/ResourceAvailabilityInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import ResourceAvailabilityIndicator from "../../resourceAvailability/ResourceAvailabilityIndicator";
import {stringUtils} from "../../../../utils/stringUtils";
import TableCheckbox from "../../../atoms/TableCheckbox";
import {ResourceAvailabilityContext, ResourceAvailabilityDispatchContext} from "../../../../context/ResourceAvailabilityContext";
import {AvailabilityEnum} from "../../../../constants/ResourceAvailabilityContants";

interface ResourceAvailablityCardProps {
  className?: string;
  resourceAvailability: ResourceAvailabilityDto;
  reload: () => void;
  resourceId: string;
}

const ResourceAvailablityCard: FunctionComponent<ResourceAvailablityCardProps> = ({
  className,
  resourceAvailability = {},
  reload,
  resourceId
}) => {
  const selectedAvailabilities = useContext(ResourceAvailabilityContext);
  const {dispatch} = useContext(ResourceAvailabilityDispatchContext);

  const isSelected = [...selectedAvailabilities.amList, ...selectedAvailabilities.pmList]?.some(d => d === resourceAvailability?.availabilityDate)

  return (
    <div className={`resource-availability-card ${className ?? ""}`}>

      <div className="bold">
        <TableCheckbox
          checked={isSelected}
          className="me-2"
          onChange={() => dispatch({
            type: isSelected ? ResourceAvailabilityDispatchType.REMOVE_ONE : ResourceAvailabilityDispatchType.ADD_ONE,
            amDate: resourceAvailability.availabilityDate,
            pmDate: resourceAvailability.availabilityDate,
          })}
        />
        {stringUtils.capitalize(dateUtils.dateToLocaleDate(dateUtils.parseDate(resourceAvailability.availabilityDate)))}
      </div>

      <hr className="my-1" />

      <ResourceAvailabilityIndicator resourceAvailability={resourceAvailability} type={AvailabilityEnum.AM} reload={reload} resourceId={resourceId} />
      <ResourceAvailabilityIndicator resourceAvailability={resourceAvailability} type={AvailabilityEnum.PM} reload={reload} resourceId={resourceId} />

      {selectedAvailabilities.hasNight && (
        <ResourceAvailabilityIndicator resourceAvailability={resourceAvailability} type={AvailabilityEnum.NIGHT} reload={reload} resourceId={resourceId} />
      )}
    </div>
  )
}

export default ResourceAvailablityCard;
