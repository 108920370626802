function joinNotEmpty(list: (string | number | undefined | null)[] = [], separator = " "): string {
  return list.filter(s => s).join(separator)
}

function capitalize(str: string): string {
  if(!str) {
    return ""
  }

  return str.split(" ").map((word) => {

    return word.charAt(0).toUpperCase() + word.slice(1);

  }).join(" ");
}

export const stringUtils = Object.freeze({
  joinNotEmpty,
  capitalize
})
