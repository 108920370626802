import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import {ActivityBillingPageData} from "../../../../interfaces/ActivityBllingInterface";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {CustomTableColumn, CustomTableType} from "../../../../interfaces/TableInterfaces";
import CustomTable, {CustomTableRef} from "../CustomTable";
import ActivityBillingOverviewTableRow from "./ActivityBillingOverviewTableRow";
import ActivityBillingOverviewTableRowDetails from "./ActivityBillingOverviewTableRowDetails";
import ActivityBillingOverviewTableRowDetailsHeader from "./ActivityBillingOverviewTableRowDetailsHeader";
import {Input} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import ModalItemsToPay from "../../modal/ModalItemsToPay";
import {activityBillingService} from "../../../../services/ActivityBillingService";
import {toastUtils} from "../../../../utils/toastUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";

interface ActivityBillingOverviewTableProps {
  className?: string,
  activityBillings: ActivityBillingPageData[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  disableSelect: boolean,
  refresh: () => void
}

const ActivityBillingOverviewTable: FunctionComponent<ActivityBillingOverviewTableProps> = ({
  className = "",
  activityBillings,
  sortOptions,
  onSortOptions,
  disableSelect,
  refresh
}) => {
  const intl = useIntl()
  const [selectedLines, setSelectedLines] = useState<string[]>([])
  const [switchAllValue, setSwitchAllValue] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const ref = React.useRef<CustomTableRef>();

  const switchAll = (checked: boolean) => {
    setSwitchAllValue(checked)
    setSelectedLines(checked ? [...activityBillings.map(line => line.id)] : [])
  }

  const selectLine = (billId: string) => {
    const index = selectedLines.indexOf(billId)
    let newArray: string[]

    if (index > -1) {
      newArray = selectedLines.filter(line => line !== billId)
    } else {
      newArray = selectedLines.concat([billId])
    }

    setSwitchAllValue(newArray.length === activityBillings.length)
    setSelectedLines(newArray)
  }

  useEffect(() => {
    setSelectedLines([])
    setSwitchAllValue(false)
  }, [activityBillings])

  const columns: CustomTableColumn[] = useMemo(() =>
    [
      {
        accessor: "id", title: "booking_service",
        className: `col-1 ${disableSelect ? "d-none" : "p-2"}`,
        Cell: (
          <>
            <Input
              className="me-2"
              type="checkbox"
              checked={switchAllValue}
              onChange={(value) => switchAll(value.target.checked)}
            />
          </>
        )
      },
      {title: "billing_overview_name", accessor: "resourceFirstName", sortable: true, className:"col-3 ms-2"},
      {title: "billing_overview_number", accessor: "number", sortable: true, className:"col-3"},
      {title: "billing_overview_date", accessor: "date", sortable: true, className:"col-3"},
      {title: "billing_overview_period", accessor: "firstStaffingDate", sortable: false, className:"col-4"},
      {title: "billing_overview_amount_bill", accessor: "billAmount", sortable: false, className:"col-3"},
      {title: "billing_overview_amount_activities", accessor: "activitiesAmount", sortable: false, className:"col-3"},
      {title: "billing_overview_payment_date", accessor: "paymentDate", sortable: true, className: `${disableSelect ? "col-3" : "d-none"}`,},
      {title: "billing_overview_download", accessor: "id", sortable: false, className:"col-2"},
    ], [switchAllValue, disableSelect, activityBillings])

  const generateSepaFile = () => {
    activityBillingService.generateSepaFile(selectedLines)
      .then((file) => {
        toastUtils.successToast(intl.formatMessage({id: "activity_bill_pay_modal_action_success"}))
        fileUtils.saveFile(file, "resource_sepa.xml")
        refresh()
        setModalOpened(false)
      }).catch((error: ApiError) => {
        if (error && error.message) {
          toastUtils.errorToast(error.message)
        } else {
          toastUtils.errorToast(intl.formatMessage({id: "activity_bill_pay_modal_action_failure"}))
        }
      })
  }

  return (
    <div className={className}>
      <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className}`}
        type={CustomTableType.ACTIVITY_BILLING_OVERVIEW}
        datas={activityBillings}
        columns={columns}
        RowComponent={({data, open, ...rest}) => {
          const activityBilling = data as ActivityBillingPageData;
          return (
            <div>
              <ActivityBillingOverviewTableRow
                className="activity-billing-overview-row"
                activityBilling={activityBilling}
                open={open}
                selected={selectedLines.includes(activityBilling.id)}
                selectLine={selectLine}
                disableSelect={disableSelect}
                {...rest}
              />
              {open && (
                <div className="epow-custom-table-row-details pt-2">
                  <ActivityBillingOverviewTableRowDetailsHeader showFirstColumn={!disableSelect} />
                  {activityBilling.activityStaffing?.map(activityStaffing =>
                    <ActivityBillingOverviewTableRowDetails
                      key={activityStaffing.id}
                      showFirstColumn={!disableSelect}
                      activityStaffing={activityStaffing}
                      className="detail-row"
                    />
                  )}
                </div>
              )}
            </div>);
        }}
      />
      {
        selectedLines.length > 0 && (
          <div className="d-flex justify-content-end align-items-center mb-3">
            <div className="me-2 italic">
              <FormattedMessage id="autocomplete_more_than_one_selected" values={{size: selectedLines.length}} />
            </div>
            <Button size={SizeType.MD} className="extended" onClick={() => setModalOpened(true)} color={ColorType.SECONDARY}>
              <FormattedMessage id="activity_bill_pay_button" />
            </Button>
          </div>
        )
      }

      <ModalItemsToPay
        opened={modalOpened}
        setOpen={setModalOpened}
        action={generateSepaFile}
        title={intl.formatMessage({id: "activity_bill_pay_modal_tile"})}
        textContent={intl.formatMessage({id: "activity_bill_pay_modal_body"}, { count: selectedLines.length })}
      />
    </div>
  )
}

export default ActivityBillingOverviewTable;
