import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import {Purchase} from "../interfaces/PurchaseInterfaces";
import {purchaseService} from "../services/PurchaseService";
import {toastUtils} from "../utils/toastUtils";
import Layout from "../components/layout/Layout";
import {useIntl} from "react-intl";
import PurchaseHeader from "../components/molecules/header/PurchaseHeader";
import PurchaseInfosCard from "../components/molecules/card/purchase/PurchaseInfosCard";
import {Col, Row} from "reactstrap";
import ContactCard from "../components/molecules/card/ContactCard";
import CommentCard from "../components/molecules/card/comment/CommentCard";
import {Comment, CommentRequest} from "../interfaces/CommentInterface";
import {supplierUtils} from "../utils/supplierUtils";
import ModalSendPurchase from "../components/molecules/modal/ModalSendPurchase";
import ModalItemsToPay from "../components/molecules/modal/ModalItemsToPay";
import {bookingService} from "../services/BookingService";
import {fileUtils} from "../utils/fileUtils";
import {ApiError} from "../interfaces/ErrorInterfaces";
import PurchaseUploadCard from "components/molecules/card/purchase/PurchaseUploadCard";

const PurchaseView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const params = useParams()
  const [purchase, setPurchase] = useState<Purchase>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [payModalOpened, setPayModalOpened] = useState<boolean>(false)
  const [comment, setComment] = useState<Comment>(purchase?.comment);

  const getPurchaseById = () => {
    purchaseService.getPurchaseById(params.id)
      .then((purchase) => {
        setPurchase(purchase)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_purchase"}))
      })
  }

  const createComment = async (
    commentRequest: CommentRequest
  ): Promise<void> => {
    return purchaseService.createPurchaseComment(purchase.id, commentRequest)
      .then((createdComment: Comment) => {
        setComment(createdComment);
        toastUtils.successToast(
          intl.formatMessage({ id: "comment_toast_success_creation" })
        )
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "comment_toast_error_creation" })
        )
      })
  }

  const deleteComment = () => {
    purchaseService.deleteCommentForPurchase(purchase.id)
      .then(() => {
        setComment(null)
        toastUtils.infoToast(
          intl.formatMessage({ id: "comment_toast_success_deletion" })
        );
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "comment_toast_error_deletion" })
        );
      });
  };

  const onChangePurchaseAttachedFile = (files: FileList) => {
    purchaseService?.addFiles(purchase.id, files)
        .then(() => {
            toastUtils.successToast(intl.formatMessage({ id: "success_toast_add_purchase_attached_file" }));
            getPurchaseById();
        })
        .catch(() => {
            toastUtils.errorToast(intl.formatMessage({ id: "error_toast_update_purchase_attached_file" }))
        });
};

  useEffect(() => {
    getPurchaseById()
  }, [])

  const payBookings = () => {
    bookingService.generateSepaFile(purchase.bookings?.map(item => item.id))
      .then((file) => {
        toastUtils.successToast(intl.formatMessage({id: "booking_pay_modal_success"}))
        fileUtils.saveFile(file, "bookings_sepa.xml")
        setPayModalOpened(false)
        getPurchaseById()
      }).catch((error: ApiError) => {
      if (error && error.message) {
        toastUtils.errorToast(error.message)
      } else {
        toastUtils.errorToast(intl.formatMessage({id: "booking_pay_modal_failure"}))
      }
    })
  }

  return (
    <Layout>
      {purchase && (
        <div className="epow-content-body epow-purchase-card">
          <PurchaseHeader purchase={purchase} onClickSent={() => setOpenModal(true)} openPayModal={() => setPayModalOpened(true)}/>
          <Row>
            <Col xs={24} xl={18}>
              <PurchaseInfosCard purchase={purchase} getPurchase={getPurchaseById}/>
            </Col>
            <Col xs={24} xl={6}>
              {purchase?.supplier && (
                <div>
                  <ContactCard
                    firstName={purchase?.supplier?.name}
                    lineContact={supplierUtils.buildSupplierLineContact(purchase?.supplier)}
                  />
                  <ModalSendPurchase
                    onSubmit={(purchase) => {
                      setOpenModal(false)
                      setPurchase(purchase)
                    }}
                    purchase={purchase}
                    supplierOption={{
                      id: purchase?.supplier?.id,
                      email: purchase?.supplier?.entityEmail,
                      name: purchase?.supplier?.name,
                    }}
                    open={openModal}
                    onOpen={(open) => setOpenModal(open)}
                    onClose={() => setOpenModal(false)}/>
                </div>
              )}
              <CommentCard
                className="d-flex flex-column mt-4 ms-3"
                comment={comment}
                createComment={createComment}
                deleteComment={deleteComment}
                title={intl.formatMessage({ id: "purchase_order_comment" })}
              /> 
              <PurchaseUploadCard
                purchase={purchase}
                updateFiles={getPurchaseById}
                uploadFiles={onChangePurchaseAttachedFile}
                title="purchase_attached_file_title"
                noDocumentFound="purchase_no_attached_file"
                onDelete={purchaseService.deleteDocumentById}
              />
            </Col>
          </Row>
        </div>
      )}
      <ModalItemsToPay
        opened={payModalOpened}
        setOpen={setPayModalOpened}
        action={payBookings}
        title={intl.formatMessage({id: "booking_pay_modal_title"})}
        textContent={intl.formatMessage({id: "booking_pay_modal_body"})}
      />
    </Layout>
  )
}

export default PurchaseView
