import {Identity} from "../../../../interfaces/ContactInterfaces";
import {TourLeader} from "../../../../interfaces/TourLeaderInterfaces";
import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import Title from "../../../atoms/Title";
import ContactCard from "../ContactCard";
import {contactService} from "../../../../services/ContactService";
import {toastUtils} from "../../../../utils/toastUtils";
import {tourLeaderService} from "../../../../services/TourLeaderService";
import {useIntl} from "react-intl";
import {getBoolean} from "../../../../utils/booleanUtils";

interface MainTourLeaderContactCardProps {
  className?: string,
  title: string,
  tourLeader?: TourLeader,
  onChangeTourLeader: (tourLeader: TourLeader) => void
}

const MainTourLeaderContactCard: FunctionComponent<MainTourLeaderContactCardProps> =({
  className = "",
  title,
  tourLeader,
  onChangeTourLeader
}) => {
  const identity : Identity = tourLeader?.identity
  const intl = useIntl();
  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size="h5" className="mb-3"/>
      {identity && (
        <ContactCard
          buttonCallback={() => tourLeaderService.invite(tourLeader.id)
            .then((tourLeader) => {
              onChangeTourLeader(tourLeader)
              toastUtils.successToast(intl.formatMessage({id: "contact_invited"}))
            })
            .catch(() => {
              toastUtils.errorToast(intl.formatMessage({id: "error_inviting_contact"}))
            })
          }
          firstName={identity?.firstName}
          lastName={identity?.lastName}
          hasAccount={getBoolean(tourLeader?.hasAccessPlatform) || tourLeader?.isUserActive}
          lineContact={contactService.buildLineContact(identity)}
        />
      )}
    </Col>
  )
}

export default MainTourLeaderContactCard
