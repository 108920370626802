import React, {
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import {useIntl} from "react-intl";
import TableHeader from "./order/TableHeader";
import {SortOptions} from "../../../interfaces/api/PaginationInterface";
import {CustomTableColumn, CustomTableEnum, Data} from "../../../interfaces/TableInterfaces";

interface CustomTableProps {
  onSortOptions?: (sortOptions: SortOptions) => void,
  sortOptions?: SortOptions,
  datas: Data[],
  minWidth?: string,
  columns: CustomTableColumn[]
  className?: string,
  type?: CustomTableEnum,
  RowComponent: FunctionComponent<{ data: Data, open: boolean, onRowClick: (id: string) => void, index: number }>,
}

export interface CustomTableRef {
  toggleAllRows: (checked: boolean) => void,
}


const CustomTable: ForwardRefRenderFunction<CustomTableRef,CustomTableProps> = ({
  sortOptions,
  onSortOptions,
  className = "",
  datas,
  columns,
  type = "",
  RowComponent,
}, ref) => {

  const intl = useIntl()
  const [rowOpen, setRowOpen] = useState<{ [key: string]: boolean }>({})

  const headerProps = useMemo(() => ({
    sortOptions,
    onSortOptions,
    className: `epow-custom-table-header ${type}`,
  }), [sortOptions])

  const handleOpenRow = (id: string) => {
    const isRowCurrentlyOpen = rowOpen[id] ?? false

    setRowOpen({
      ...rowOpen,
      [id]: !isRowCurrentlyOpen,
    })
  }

  const toggleAllRows = (checked: boolean) => {
    const newRowOpen = {}
    datas?.map(data => {
      newRowOpen[data.id] = checked
    })

    setRowOpen(newRowOpen)
  }

  useImperativeHandle(ref, () => ({
    toggleAllRows
  }))

  const classNameFormatted = `${className ?? ""} ${type ?? ""}`;

  return (
    <div className={`epow-custom-table col ${classNameFormatted}`}>
      <div className={`epow-custom-table-header-row ${type}`}>
        {columns.map(column => (
          <TableHeader
            key={column.accessor || column.title}
            {...column}
            sortOptions={sortOptions}
            onSortOptions={onSortOptions}
            title={intl.formatMessage({id: column.title})}
            {...headerProps}
            className={`${headerProps.className ?? ""} ${column.className ?? ""}`}
            />
        ))}
      </div>
      {datas.map((data, index) => (
        <div key={data.id} className={`epow-custom-table-row ${type}`}>
          {<RowComponent
            data={data}
            open={rowOpen[data.id]}
            onRowClick={handleOpenRow}
            index={index}/>}
        </div>
      ))}
    </div>
  )
}

export default forwardRef(CustomTable);
