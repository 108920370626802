import React, {FunctionComponent} from "react";
import StaffingFormGeneralMessage from "./messages/StaffingFormGeneralMessage";
import StaffingFormMessage from "./messages/StaffingFormMessage";
import {ResourceForStaffingDto, ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";

interface StaffingFormStepTwoProps {
  className?: string,
  selectedResources: ResourceForStaffingDto[],
  resourceRequests: ResourceStaffingRequest[],
  setResourceRequests: (request: ResourceStaffingRequest[]) => void,
}

const StaffingFormStepTwo: FunctionComponent<StaffingFormStepTwoProps> = ({
  className = "",
  selectedResources,
  resourceRequests,
  setResourceRequests,
}) => {
  const getMessageForResource = (resourceId: string) => {
    return resourceRequests.find(r => r.resourceId === resourceId)?.message ?? "";
  }

  const handleEditGeneralMessage = (newMessage: string) => {
    const requestsWithMessage = selectedResources.map(resource => ({resourceId: resource.id, message: newMessage}))
    setResourceRequests(requestsWithMessage)
  }

  const onChangeMessage = (message, resourceId: string) => {
    const existingRequestIndex = resourceRequests.findIndex(r => r.resourceId === resourceId)
    const newRequest = {resourceId, message}

    const updatedRequests = [...resourceRequests]
    updatedRequests.splice(existingRequestIndex, 1, newRequest)
    setResourceRequests(updatedRequests)
  }

  return (
    <div className={className}>
      <StaffingFormGeneralMessage className="mb-2" onEditGeneralMessage={handleEditGeneralMessage} />

      {selectedResources.map(resource => (
        <StaffingFormMessage key={resource.id} resource={resource} value={getMessageForResource(resource.id)} onChangeMessage={onChangeMessage} />
      ))}
    </div>
  )
}

export default StaffingFormStepTwo;
