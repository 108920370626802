import React, {ForwardRefRenderFunction, useImperativeHandle, useRef} from "react";
import {Column, useBlockLayout, useTable} from "react-table";
import {FixedSizeList} from "react-window"
import InfiniteLoader from "react-window-infinite-loader";
import {useSticky} from "react-table-sticky";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

interface CalendarTableProps {
  columns: Column[]
  datas: TableData[];
  className?: string,
  hasNextPage: boolean,
  isNextPageLoading: boolean,
  loadNextPage: () => void
  nightly?: boolean
  itemSize?: number
  height?: number
}

export interface TableData {
  id?: string;
}

export interface CalendarTableRef {
  goTo: (item: number) => void
}

const CalendarTable: ForwardRefRenderFunction<CalendarTableRef, CalendarTableProps> = ({
  datas,
  columns,
  className,
  hasNextPage,
  isNextPageLoading,
  loadNextPage,
  itemSize = 176,
  height,
}, ref) => {

  const { height: windowHeight } = useWindowDimensions();
  const calendarHeight = height ?? windowHeight - 100;

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    totalColumnsWidth,
    headerGroups,
  } = useTable({
      columns: columns,
      data: datas,
    },
    useBlockLayout,
    useSticky)

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? datas.length + 1 : datas.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {
    // Do nothing
  } : loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < datas.length;
  const refVirtualRow = useRef<FixedSizeList>();

  const goTo = (item: number) => {
    refVirtualRow?.current?.scrollTo(item);
  }

  useImperativeHandle(ref, () => ({
    goTo
  }));

  const renderRow = React.useCallback(({index, style}) => {

    if (!isItemLoaded(index)) {
      return <div className="w-100"></div>;
    }

    const row = rows[index as number]
    prepareRow(row)
    const {style: rowStyle, ...restRow} = row.getRowProps({style});

    return (
      <div {...restRow} style={{...rowStyle, width: totalColumnsWidth}} className="tr">
        {row.cells.map(cell => {
          return (
            <div className="td ms-1" key={row.id} {...cell.getCellProps()}>{cell.render("Cell")}</div>
          )
        })}
      </div>
    )
  }, [prepareRow, rows])

  return (
    <div className={`${className} table sticky`} {...getTableProps()}>
      <div style={{position: "relative", flex: 1, zIndex: 0}}>
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          loadMoreItems={loadMoreItems}
        >
          {({onItemsRendered, ref}) => (
            <FixedSizeList
              ref={ref}
              height={calendarHeight}
              onItemsRendered={onItemsRendered}
              itemCount={itemCount}
              itemSize={itemSize}
              width="100%"
              innerElementType={({children, style, ...rest}) => (
                <>
                  <div className="header">
                    <div style={{width: totalColumnsWidth}}>
                      {headerGroups.map(headerGroup => (
                        <div key={headerGroup.id} className="tr" {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <div className="th ms-1"
                                 key={column.id} {...column.getHeaderProps()}>
                              {column.render("Header")}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="body position-relative" style={{height: calendarHeight - 200}}>
                    <div {...getTableBodyProps()} {...rest} style={style}>
                      {children}
                    </div>
                  </div>
                </>
              )}
            >
              {renderRow}
            </FixedSizeList>

          )}
        </InfiniteLoader>
      </div>
    </div>)
}

export default React.forwardRef(CalendarTable);
