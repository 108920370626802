import React, {FunctionComponent, memo} from "react";
import {FormGroup, Label} from "reactstrap";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import AutocompleteFilterPageable, {AutocompleteFilterPageableProps} from "../filters/AutocompleteFilterPageable";

interface FormAutocompleteSelectProps extends AutocompleteFilterPageableProps {
  id?: string,
  error: string,
  touched: boolean,
  placeholder?: string,
  label?: string,
  required?: boolean,
  popover?: string,
  initialFilter?: SearchFilter,
  readOnly?: boolean
}

const FormAutocompleteSelectPageable: FunctionComponent<FormAutocompleteSelectProps> = ({
  className = "",
  id,
  error,
  touched,
  value,
  placeholder,
  onChange,
  fetchData,
  filterFieldName,
  filterFieldSearch,
  label,
  required,
  popover,
  onBlur,
  initialFilter,
  manageOptions,
  optionFromValue,
  readOnly,
  ...rest
}) => {
  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            {label}
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover} />
          </div>
        )}
      </div>
      <AutocompleteFilterPageable
        {...rest}
        id={id}
        readOnly={readOnly}
        className={`${!!error ? "epow-select-error" : ""}`}
        onChange={onChange}
        manageOptions={manageOptions}
        onBlur={onBlur}
        value={value}
        initialFilter={initialFilter}
        optionFromValue={optionFromValue}
        placeholder={placeholder ? placeholder : ""}
        fetchData={fetchData}
        filterFieldName={filterFieldName}
        filterFieldSearch={filterFieldSearch}
      />
      <ErrorField touched={touched} error={error} />
    </FormGroup>
  )
}

export default memo(FormAutocompleteSelectPageable);
