import {Resource} from "../interfaces/ResourceInterfaces";

export const RESOURCE_INITIAL_VALUES : Resource = {
  identity: {
    lastName: "",
    firstName: "",
    email: "",
  },
  availability: undefined,
  statusResource: undefined,
  level: undefined,
  dateFirstMission: undefined,
  resourceType: {
    id: undefined,
    label: undefined,
    color: undefined
  }
}

export const enum StatusResource {
  EMPLOYEE = "EMPLOYEE",
  INDEPENDANT = "INDEPENDENT"
}

export const enum ResourceLevel {
  JUNIOR = "JUNIOR",
  SENIOR = "SENIOR"
}

export const enum PublicType {
  GROUP = "GROUP",
  PRIVATE = "PRIVATE",
  KIDS_FRIENDLY = "KIDS_FRIENDLY",
}

export const ResourceAvailability = {
  ZONE: "ZONE",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH"
} as const

export type ResourceAvailabilityType = typeof ResourceAvailability[keyof typeof ResourceAvailability]


export type ResourceAvailabilityLevel = Exclude<ResourceAvailabilityType, "ZONE">;
export const ResourceAvailabilityCalendar: Record<ResourceAvailabilityLevel, { color: string, label: string }> = {
  LOW: {
    color: "red",
    label: "level_low_availability_calendar"
  },
  MEDIUM: {
    color: "orange",
    label: "level_medium_availability_calendar"
  },
  HIGH: {
    color: "green",
    label: "level_high_availability_calendar"
  }
}
