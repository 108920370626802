import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Column, TableInstance, useTable, Row} from "react-table";
import {Data} from "../../../interfaces/TableInterfaces";
import TableBody from "./TableBody";

interface TableProps<T> {
  columns: Array<Column>,
  data: Array<T>,
  isSortable?: boolean,
  className?: string,
  labelNoResult?: string,
  rowProps?: (row: Row) => Object,
  hideHeader?: boolean
}

const Table: FunctionComponent<TableProps<Data>> = ({
  columns,
  data,
  className = "",
  labelNoResult = "no_row",
  rowProps = () => ({}),
  hideHeader = false
}) => {
  const tableInstance: TableInstance = useTable({
    columns,
    data,
  })

  if (data.length === 0) {
    return (<div><FormattedMessage id={labelNoResult} /></div>)
  }

  return (
    <TableBody tableInstance={tableInstance} className={className} rowProps={rowProps} hideHeader={hideHeader}/>
  )
}

export default Table;
