import {Season, SeasonCreationRequest} from "../interfaces/SeasonInterfaces";
import {httpService} from "./HttpService";
import {API_SEASONS_FOR_TOUR_PATH, API_SEASONS_PATH} from "../constants/routes/RoutePaths";

const getSeasonsForTour = (tourId: string): Promise<Season[]> => {
    return httpService.get<Season[]>(API_SEASONS_FOR_TOUR_PATH.replace(":tourId", tourId))
}

const createSeasonForTour = (tourId: string, request: SeasonCreationRequest): Promise<Season> => {
    return httpService.post<Season>(API_SEASONS_PATH, request)
}

const deleteSeason = (id: string): Promise<Response> => {
    return httpService.delete(`${API_SEASONS_PATH}/${id}`)
}

export const seasonService = Object.freeze({
    getSeasonsForTour,
    createSeasonForTour,
    deleteSeason
})
