import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import ReactCrop, {Crop, PixelCrop} from "react-image-crop";
import {useIntl} from "react-intl";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {canvasPreview, canvasToFile, centerAspectCrop} from "../../../utils/cropUtils";
import {toastUtils} from "../../../utils/toastUtils";
import GenericModal from "../modal/GenericModal";
import Button from "../../atoms/Button";


interface CropperProps {
  setImageFile: (file: File) => void
  title: string
  aspect?: number
  isButton?: boolean
}

const Cropper: FunctionComponent<CropperProps> = ({
  setImageFile,
  title,
  aspect = 0,  // 0 = libre, 1 = un carré
  isButton,
}) => {
  const [imgSrc, setImgSrc] = useState("")
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [imageName, setImageName] = useState<string>("")
  const [modalOpen, setModalOpen] = useState<boolean>()

  const intl = useIntl()

  /**
   * Once the user has selected an image, update the state with it's name and decoded data
   * @param e the file selection event
   */
  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageName(e.target.files[0]?.name)

      const reader = new FileReader()
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      )
      reader.readAsDataURL(e.target.files[0])
      setModalOpen(true)
    }
  }

  /**
   * Crop take the size of the img
   * @param width
   * @param height
   */
  const defaultCrop = (width: number, height: number): PixelCrop => {
    return {
      x: 0,
      y: 0,
      width: width,
      height: height,
      unit: "px"
    }
  }


  /**
   * Once the image has been loaded inside the DOM, set the cropping square at the center of it only if there is an aspect ratio
   * @param e the image loaded event
   */
  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {

    const {width, height} = e.currentTarget
    const initialCrop = aspect ? centerAspectCrop(width, height, aspect) : defaultCrop(width, height);

      setCrop(initialCrop)

      // if previewCanvasRef is already set (file modified before),
      // call canvasPreview to update it
      if(previewCanvasRef.current) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          initialCrop
        )

    }
  }

  /**
   * Every time the crop selection is set, update the canvas
   * Use a debounce to prevent performance impacts on initial renders
   */
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop
        )
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [completedCrop])

  const setCroppedImage = () => {
    canvasToFile(previewCanvasRef.current, imageName).then(image => {
      setModalOpen(false)
      setImageFile(image)
    }).catch(() =>
      toastUtils.errorToast(intl.formatMessage({id: "error"}))
    )
  }

  return (
    <>
      {isButton ? (
        <Button color={ColorType.SECONDARY} onClick={() => fileInputRef?.current?.click()}>
          {title}
        </Button>
      ) : (
        <label htmlFor="cropper-file-input" className="epow-button cursor-pointer ms-2 text-underline">{title}</label>
      )}

      <input ref={fileInputRef} id="cropper-file-input" type="file" accept="image/*" className="d-none" onChange={onSelectFile} />

      <div>
        <GenericModal open={modalOpen} onOpen={(open) => setModalOpen(open)} title={intl.formatMessage({id: "crop_modal_title"})}>
          <div className="d-flex flex-column align-items-center">

            {completedCrop && (
              <canvas ref={previewCanvasRef} style={{display: "none"}} />
            )}

            {imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(cropSelection) => setCompletedCrop(cropSelection)}
                aspect={aspect}
              >
                <img ref={imgRef} alt="image+crop" src={imgSrc} onLoad={onImageLoad} />
              </ReactCrop>
            )}

            <div className="d-flex mt-2 gap-2">
              <Button color={ColorType.SECONDARY} onClick={() => setModalOpen(false)}>
                {intl.formatMessage({id: "cancel_button"})}
              </Button>
              <Button onClick={setCroppedImage} >
                {intl.formatMessage({id: "crop_image"})}
              </Button>
            </div>

          </div>
        </GenericModal>
      </div>

    </>
  )
}

export default Cropper
