import {YES_NO_OPTIONS} from "constants/OptionConstants";
import {CreateSupplierSettingsSchema} from "constants/validation/SupplierSettingsValidationSchema";
import {Formik} from "formik";
import {Supplier, SupplierSettings} from "interfaces/SupplierInterfaces";
import React, {FunctionComponent} from "react";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {supplierService} from "services/SupplierService";
import {toastUtils} from "utils/toastUtils";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";

interface CreateSupplierSettingsFormProps {
  formId?: string;
  className?: string;
  initialSettings?: SupplierSettings;
  setSupplier?: (supplier: Supplier) => void;
  setEditInfo?: (editInfo: boolean) => void;
}

const CreateSupplierSettingsForm: FunctionComponent<CreateSupplierSettingsFormProps> = ({
  formId,
  className = "",
  initialSettings,
  setSupplier,
  setEditInfo
}) => {
  const intl = useIntl();

  const handleSubmitSupplierSettings = (supplierSettings: SupplierSettings) => {
    supplierService
      .updateSupplierSettings(supplierSettings.supplierId, supplierSettings)
      .then((supplier: Supplier) => {
        toastUtils.successToast(
          intl.formatMessage({id: "success_update_supplier_settings"})
        );
        setEditInfo(false);
        setSupplier(supplier);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({id: "error_update_supplier_settings"})
        );
      });
  };
  return (
    <Formik
      initialValues={initialSettings}
      validationSchema={CreateSupplierSettingsSchema}
      onSubmit={(values) => handleSubmitSupplierSettings(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={formId} className={className}>
          <FormInput
            id="preBookingDelay"
            label="supplier_pre_booking_delay"
            value={values?.preBookingDelay}
            type="number"
            minValue={0}
            onChange={(e) =>
              setFieldValue("preBookingDelay", Number(e.target.value))
            }
            required
            error={errors.preBookingDelay}
            touched={touched.preBookingDelay}
          />
          <FormInput
            id="paymentDelay"
            label="supplier_payment_delay"
            value={values?.paymentDelay}
            type="number"
            minValue={0}
            onChange={(e) =>
              setFieldValue("paymentDelay", Number(e.target.value))
            }
            required
            error={errors.paymentDelay}
            touched={touched.paymentDelay}
          />
          <FormRadio
            id="hasVoucher"
            label="supplier_has_voucher"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.hasVoucher?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(option?: Option<string>) => setFieldValue("hasVoucher", option?.value)}
            required
            error={errors.hasVoucher}
            touched={touched.hasVoucher}
          />
          <FormRadio
            id="applyVat"
            label="supplier_apply_vat"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.applyVat?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(option?: Option<string>) => setFieldValue("applyVat", option?.value)}
            required
            error={errors.applyVat}
            touched={touched.applyVat}
          />
          <FormInput
            id="comment"
            type="textarea"
            label={intl.formatMessage({id: "supplier_comment"})}
            value={values.comment}
            onChange={(e) => setFieldValue("comment", e.target?.value)}
            error={errors.comment}
            touched={touched.comment}
            rows="5"
          />
        </EpowForm>
      )}
    </Formik>
  );
};

export default CreateSupplierSettingsForm;
