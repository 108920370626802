import {UNIT_TYPE, OrderItemRequest, ORDER_ITEM_TYPE} from "../interfaces/OrderItemInterfaces";

export const ORDER_ITEM_INITIAL_VALUES: OrderItemRequest = {
  id: "",
  type: ORDER_ITEM_TYPE.ACTIVITY.toString(),
  activity: null,
  service: null,
  name: "",
  place: "",
  date: "",
  hour: "",
  pax: 0,
  quantity: 0,
  optional: false,
  isStaffingManagement: true,
  unitType: UNIT_TYPE.PAX.toString(),
  paxPrice: 0,
  sellPrice: 0,
  isBookingManagement: false,
  description: "",
  sellPriceTVA: 0,
  billingService: null,
  disbursements: false,
  showActivityTourLeader: true
}
