import * as Yup from "yup";
import {yupNullableEmail, yupNullableString, yupRequiredString} from "../yup/Validators";

export const CreateSupplierSchema = Yup.object().shape({
    name: yupRequiredString,
    mainLabel: yupRequiredString,
    zipCode: yupRequiredString,
    city: yupRequiredString,
    country: yupRequiredString,
    entityEmail: yupNullableEmail,
    entityPhone: yupNullableString
})
