import React, { FunctionComponent } from "react"

import {IconName, ICONS_MAP} from "./IconsMap"
import {ColorTypeValues, IconSizeTypeValues} from "../../types/bootstrap/BootstrapType";

interface IconProps {
  name: IconName
  className?: string
  size?: IconSizeTypeValues,
  color?: ColorTypeValues,
  onMouseDown?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  onMouseUp?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
  loading?: boolean
  loadingClassName?: string
}

const Icon: FunctionComponent<IconProps> = ({
  name,
  className = "",
  size,
  color,
  onMouseDown,
  onMouseUp,
  onClick,
  loading = false,
  loadingClassName
}) => {

  if (!ICONS_MAP[name]) {
    return null;
  }

  const classSize = size ? `icon-size--${size}` : ""
  const classColor = color ? `icon-color--${color}` : ""
  const classNameFormatted = className ? `${className}` : ""

  return (
    <>
      {loading ? (
        <div className={`loader me-2 ${loadingClassName}`} />
      ) : (
        <span
          className={`epow-icon ${classSize} ${classColor} ${classNameFormatted}`}
          onClick={onClick}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
        >
        {ICONS_MAP[name]}
      </span>
      )}
    </>
  );
};

export default Icon

