import React, {FunctionComponent, useEffect, useState} from "react";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage, useIntl} from "react-intl";
import Panel from "../../panel/Panel";
import CreateTourActivityForm from "../../form/CreateTourActivityForm";
import {toastUtils} from "../../../../utils/toastUtils";
import TourActivityList from "../../list/TourActivityList";
import {STATUS_NOT_FOUND} from "../../../../constants/HttpStatusConstants";
import {Season} from "../../../../interfaces/SeasonInterfaces";
import {seasonService} from "../../../../services/SeasonService";
import CreateSeasonForm from "../../form/CreateSeasonForm";
import GenericModalDelete from "../../modal/GenericModalDelete";
import SeasonItem from "./SeasonItem";
import { adminConfigurationService } from "services/AdminConfigurationService";

interface TourActivityCardProps {
  className?: string,
  tourId?: string,
}

const TourActivityCard: FunctionComponent<TourActivityCardProps> = ({
  className = "",
  tourId,
}) => {
  const intl = useIntl()
  const [lateralPanelTourActivity, setLateralPanelTourActivity] = useState<boolean>(false)
  const [lateralPanelSeason, setLateralPanelSeason] = useState<boolean>(false)
  const [openModalDeleteSeason, setOpenModalDeleteSeason] = useState<boolean>(false)
  const [departureTimeMinuteDelta, setDepartureTimeMinuteDelta] = useState<number>(0);
  const [selectedSeason, setSelectedSeason] = useState<Season>(undefined)
  const [seasonToDelete, setSeasonToDelete] = useState<Season>(undefined)
  const [seasons, setSeasons] = useState<Season[]>([])

  const getSeasons = () => {
    seasonService.getSeasonsForTour(tourId)
      .then((seasons) => {
        setSeasons(seasons)
        if (seasons && seasons.length > 0) {
          if (!selectedSeason) {
            setSelectedSeason(seasons[0])
          } else {
            setSelectedSeason(seasons.find(season => season.id === selectedSeason.id))
          }
        }
      })
      .catch((error: Response) => {
        if (error?.status !== STATUS_NOT_FOUND) {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_tour_activities"}))
        }
      })
  }

  const handleValidate = () => {
    setLateralPanelTourActivity(false)
    setLateralPanelSeason(false)
    setOpenModalDeleteSeason(false)
    getSeasons()
  }

  const getAdminConfiguration = () => {
    adminConfigurationService.getAdminConfiguration()
    .then((config) => {
      setDepartureTimeMinuteDelta(config.departureTimeMinuteDelta);
    })
    .catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"}));
    })
  }

  useEffect(() => {
    getSeasons(),
    getAdminConfiguration()
  }, [])

  const handleClickTrashIcon = (season: Season) => {
    setSeasonToDelete(season)
    setOpenModalDeleteSeason(true)
  }

  return (
    <div className={`${className} row`}>
      <div className="col-8">
        <div className="nav flex-column nav-pills" id="season-nav" role="tablist" aria-orientation="vertical">
          {
            seasons?.map(season =>
              <SeasonItem
                season={season}
                handleSelectSeason={setSelectedSeason}
                handleDeleteSeason={handleClickTrashIcon}
                key={season.id}
                isSelected={selectedSeason?.id === season.id}
              />
            )
          }
        </div>
        <div className="mt-3">
          <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setLateralPanelSeason(true)} className="extended">
            <FormattedMessage id="season_add_to_tour"/>
          </Button>
        </div>
      </div>
      <div className="col-16">
        {
          seasons?.length > 0 && (
            <>
              <TourActivityList tourActivities={selectedSeason?.tourActivities || []} className="mb-3" onValidate={() => getSeasons()}/>
              <div>
                <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setLateralPanelTourActivity(true)} className="extended">
                  <FormattedMessage id="add_activity_to_tour"/>
                </Button>
              </div>
            </>
          )
        }
        <div className={`${lateralPanelTourActivity ? "backdrop": "d-none"}`}></div>
        {lateralPanelTourActivity && (
          <Panel formId="createTourActivityForm" title="create_new_tour_activity" open={lateralPanelTourActivity} onCancel={() => setLateralPanelTourActivity(false)}>
            <CreateTourActivityForm id="createTourActivityForm" seasonId={selectedSeason?.id} onValidate={handleValidate} departureTimeMinuteDelta={departureTimeMinuteDelta}/>
          </Panel>
        )}
        {lateralPanelSeason && (
          <Panel formId="createSeasonForm" title="season_add_to_tour" open={lateralPanelSeason} onCancel={() => setLateralPanelSeason(false)}>
            <CreateSeasonForm id="createSeasonForm" tourId={tourId} onValidate={handleValidate}/>
          </Panel>
        )}
      </div>
      <GenericModalDelete
        titleId="season_delete_title"
        open={openModalDeleteSeason}
        onValidate={handleValidate}
        setOpen={setOpenModalDeleteSeason}
        idEntity={seasonToDelete?.id}
        onDelete={seasonService.deleteSeason}
        successToastMessageId="season_delete_success"
        errorToastMessageId="season_delete_failure"
        bodyDeleteMessage={intl.formatMessage({id: "season_delete_message"}, {season: seasonToDelete?.name})}
      />
    </div>
  )
}

export default TourActivityCard
