import * as Yup from "yup";
import {getRequiredStringForCondition, yupRequiredPositiveNumber, yupRequiredString} from "../yup/Validators";

export const BookingForPurchaseCreationValidationSchema = Yup.object().shape({
  quantity: yupRequiredPositiveNumber,
  supplierId: yupRequiredString,
})

const supportingFileUploaded = (file) => !!file;
const editDate = (editDate) => {
  return !!editDate;
}

export const BookingDocumentFormValidationSchema = Yup.object().shape({
  invoiceDate: getRequiredStringForCondition("editInvoiceDate", editDate),
  paymentDate: getRequiredStringForCondition("editPaymentDate", editDate),
  supportingFileType: getRequiredStringForCondition("supportingFile", supportingFileUploaded)
})
