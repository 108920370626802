import {
  CustomerFields,
  InvoiceOperationCustomerFields
} from "../interfaces/CustomerInterfaces";
import {Option} from "../interfaces/inputs/OptionInterfaces";

export const CUSTOMER_INITIAL_VALUES : CustomerFields = {
  name: "",
  code: "",
  mainLabel: "",
  additionalInformation: "",
  zipCode: "",
  city: "",
  country: "",
  street: "",
  houseNumber: "",
  latitude: 0,
  longitude: 0,
  paymentCondition: null,
}

export const INVOICE_OPERATION_CUSTOMER_INITIAL_VALUES : InvoiceOperationCustomerFields = {
  geographicalZone: "",
  numberIntraVAT: "",
  invoiceForCustomer: "",
  billingServices: [],
  defaultBillingServiceId: "",
  disbursement: "",
  isVoucherManagement: "",
  mainSortInvoice: "",
  secondarySortInvoice: "",
  isReservationManagement: "",
  numberWeeksViewed: 0,
  notificationForActivity: "",
  notificationForReplacement: ""
}

export const EU_CUSTOMER = "EU_CUSTOMER"
export const OUTSIDE_EU_CUSTOMER = "OUTSIDE_EU_CUSTOMER"
export const FRENCH_CUSTOMER = "FRENCH_CUSTOMER"

export const CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS : Option<string>[] = [
  { value: EU_CUSTOMER, label: "eu_customer" },
  { value: OUTSIDE_EU_CUSTOMER, label: "outside_eu_customer" },
  { value: FRENCH_CUSTOMER, label: "french_customer" },
]

export const ACTIVITY_DATE = "ACTIVITY_DATE"
export const ORDER_CODE = "ORDER_CODE"
export const ACTIVITY_NAME = "ACTIVITY_NAME"
export const ACTIVITY_PRICE = "ACTIVITY_PRICE"

export const CUSTOMER_SORT_INVOICE_OPTIONS : Option<string>[] = [
  { value: ACTIVITY_DATE, label: "activity_date" },
  { value: ORDER_CODE, label: "order_code" },
  { value: ACTIVITY_NAME, label: "activity_name" },
  { value: ACTIVITY_PRICE, label: "customer_activity_price" },
]