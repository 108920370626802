import React, {FunctionComponent, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {HomeComment} from "../../../interfaces/HomeCommentInterfaces";
import {homeCommentService} from "../../../services/HomeCommentService";
import {toastUtils} from "../../../utils/toastUtils";
import Button from "../../atoms/Button";
import Panel from "../panel/Panel";
import CreateHomeCommentForm from "../form/admin/CreateHomeCommentForm";
interface NoteProps {
  date: string;
  className: string;
}

const Note: FunctionComponent<NoteProps> = ({
  date,
  className
}) => {
  const intl = useIntl();
  const [note, setNote] = useState<HomeComment>(null)
  const [open, setOpen] = useState<boolean>(false)

  useMemo(() => {
    homeCommentService.getHomeCommentForDate(date)
      .then((note) => {
        setNote(note)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_home_comment"}))
      })
  }, [date])

  return (<div className={`epow-note ${className}`}>
    {note?.id ? (<>
      <p className="fw-bold mb-1 fs-16"><FormattedMessage id="dashboard_note"/></p>
      <p className="mb-1 fs-14"><div dangerouslySetInnerHTML={{__html:note.content}}></div></p>
      <p className="color-gray fs-10"><FormattedMessage id="dashboard_note_added_by" values={{
        date,
        createdBy: `${note.userFirstName} ${note.userLastName}`
      }}/></p>
    </>) :  <div className="mb-1 fs-16"><FormattedMessage id="dashboard_no_note"/></div>}

    <Button onClick={() => setOpen(true)}>
      {intl.formatMessage({id: note?.date ? "edit_button" : "home_comment_create" })}
    </Button>
    {open && (
      <Panel
        formId="createHomeCommentForm"
        title={note?.date ? "home_comment_edit" : "home_comment_create"}
        open={open}
        onCancel={() => {
          setOpen(false)
        }}
      >
        <CreateHomeCommentForm
          id="createHomeCommentForm"
          dateReadOnly={!!note?.date}
          initialComment={note}
          initialDate={date}
          onCreateComment={(note) => {
            setOpen(false)
            setNote(note)
          }}
        />
      </Panel>
    )}
  </div>)
}


export default Note;
