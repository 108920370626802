import React, {FunctionComponent} from "react";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import SortButton from "../SortButton";

interface TableHeaderProps {
  className?: string,
  title: string,
  accessor?: string,
  sortable?: boolean,
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  Cell?: JSX.Element,
  minWidth?: string,
}

const TableHeader: FunctionComponent<TableHeaderProps> = ({
  className = "",
  title,
  accessor,
  sortable = false,
  sortOptions,
  onSortOptions,
  Cell = <span>{title}</span>,
  minWidth = "0%"
}) => {

  const column = {
    id: accessor,
    sortable,
  }

  return (
    <div className={className} style={{minWidth}} >
      {Cell}
      <SortButton column={column} sortOptions={sortOptions} onSortOptions={onSortOptions} />
    </div>
  )
}

export default TableHeader;
