export const MONTHS_ABV = {
  JANUARY: "label_january_abv",
  FEBRUARY: "label_february_abv",
  MARCH: "label_march_abv",
  APRIL: "label_april_abv",
  MAY: "label_may_abv",
  JUNE: "label_june_abv",
  JULY: "label_july_abv",
  AUGUST: "label_august_abv",
  SEPTEMBER: "label_september_abv",
  OCTOBER: "label_october_abv",
  NOVEMBER: "label_november_abv",
  DECEMBER: "label_december_abv",
} as const

export const MONTHS_NUMBERS = {
  0: "JANUARY",
  1: "FEBRUARY",
  2: "MARCH",
  3: "APRIL",
  4: "MAY",
  5: "JUNE",
  6: "JULY",
  7: "AUGUST",
  8: "SEPTEMBER",
  9: "OCTOBER",
  10: "NOVEMBER",
  11: "DECEMBER",
} as const
