import {SupplierService} from "../../../interfaces/SupplierInterfaces";
import React, {FunctionComponent} from "react";
import Accordion from "../accordion/Accordion";
import {Label} from "reactstrap";
import Icon from "../../icon/Icon";
import {FormattedMessage} from "react-intl";
import SupplierServiceCatalogCard from "../card/service/SupplierServiceCatalogCard";

interface SupplierServiceListProps {
  className?: string,
  supplierServiceList?: SupplierService[],
  onValidate?: () => void
}

const SupplierServiceList: FunctionComponent<SupplierServiceListProps> = ({
  className = "",
  supplierServiceList,
  onValidate = () => null
}) => {

  return (
    <div className={className}>
      {supplierServiceList?.length > 0 ? supplierServiceList.map((supplierService, key) =>
        <Accordion
          className="secondary"
          title={supplierService?.simpleSupplier?.name}
          startOpen
          key={key}>
          <SupplierServiceCatalogCard
            supplierServiceId={supplierService.id}
            supplierServiceCatalogs={supplierService.catalogSupplierServiceList}
            onValidate={onValidate}
          />
        </Accordion>
      ) :
        <Label>
          <Icon name="InfoCircle" className="me-2"/>
          <FormattedMessage id="customer_service_not_found"/>
        </Label>
      }
    </div>

  )
};

export default SupplierServiceList
