import React, {FunctionComponent} from "react";
import {TabPaneItem} from "../../interfaces/TabInterfaces";
import {useIntl} from "react-intl";

interface TabPaneProps {
  tab?: TabPaneItem,
  active?: boolean,
  onClick?: () => void,
}

const TabPane: FunctionComponent<TabPaneProps> = ({
  tab,
  active,
  onClick,
}) => {
  const intl = useIntl()
  return (
    <div onClick={onClick} className={`epow-tab-pane cursor-pointer ${active ? "active" : ""} ${tab.className}`}>
      <span>{intl.formatMessage({id: tab.label})}</span>
    </div>
  )
}


export default TabPane;
