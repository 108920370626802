import { CreateStructureFields } from "interfaces/StructureInterface";

export const STRUCTURE_INITIAL_VALUES: CreateStructureFields = {
  name: "",
  mainLabel: "",
  additionalInformation: "",
  zipCode: "",
  city: "",
  country: "",
  phoneNumber: "",
  email: ""
}