import {CustomerServiceCatalog} from "../../../interfaces/ServiceInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Accordion from "../accordion/Accordion";
import Icon from "../../icon/Icon";
import {Label} from "reactstrap";
import CustomerServiceInfoCatalogCard from "../card/service/CustomerServiceInfoCatalogCard";

interface CustomerServiceListProps {
  className?: string,
  customerServiceCatalogs?: CustomerServiceCatalog[],
  onValidate?: () => void
}

const CustomerServiceList: FunctionComponent<CustomerServiceListProps> = ({
  className = "",
  customerServiceCatalogs,
  onValidate
}) => {

  return (
    <div className={className}>
      {customerServiceCatalogs?.length > 0 ? customerServiceCatalogs.map((customerServiceCatalog, key) =>
        <Accordion
          className="secondary"
          title={customerServiceCatalog?.customer?.name}
          startOpen
          key={key}
        >
          <CustomerServiceInfoCatalogCard
            customerServiceId={customerServiceCatalog?.customerServiceId}
            customerServiceInfoCatalogs={customerServiceCatalog?.customerServiceCatalogs}
            onValidate={onValidate}
          />
        </Accordion>
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2"/>
          <FormattedMessage id="customer_service_not_found"/>
        </Label>
      )}
    </div>
  )
}

export default CustomerServiceList
