import React, { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { Col, Input, Row } from "reactstrap";
import { ActivityBillingPageData } from "../../../../interfaces/ActivityBllingInterface";
import { fileService } from "../../../../services/FileService";
import { IconSizeType } from "../../../../types/bootstrap/BootstrapType";
import { dateUtils } from "../../../../utils/dateUtils";
import { fileUtils } from "../../../../utils/fileUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import Icon from "../../../icon/Icon";

interface ActivityBillingOverviewTableRowProps {
  activityBilling: ActivityBillingPageData,
  onRowClick: (orderId: string) => void,
  selectLine: (billId: string) => void,
  open: boolean,
  selected: boolean
  className?: string,
  disableSelect: boolean
}

const ActivityBillingOverviewTableRow: FunctionComponent<ActivityBillingOverviewTableRowProps> = ({
  activityBilling,
  onRowClick,
  selectLine,
  open,
  className = "",
  selected,
  disableSelect
}) => {
  const intl = useIntl()

  const [fileName, setFileName] = useState<string>()

  const downloadBill = (fileId: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileService.getDocumentInfoById(fileId).then((response) => setFileName(response.name)).catch(() => toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"})));

    fileUtils.downloadFile(filePromise, { name: fileName ?? "Facture", extension: "pdf" }, intl);
  }

  const getPeriod = () => {
    const start = dateUtils.formatToLocalDateString(dateUtils.parseDate(activityBilling.firstStaffingDate)) ?? "";
    const end = dateUtils.formatToLocalDateString(dateUtils.parseDate(activityBilling.lastStaffingDate)) ?? "";

    return `${start} - ${end}`
  }

  return (
    <Row onClick={() => onRowClick(activityBilling.id)} className={`gx-0 py-3 ${className}`}>
      <Col className={disableSelect ? "d-none" : "p-2"} xs={1} onClick={(event) => { event.stopPropagation(); selectLine(activityBilling.id) }}>
        <Input
          className="me-2"
          type="checkbox"
          checked={selected}
          onChange={() => selectLine(activityBilling.id)}
        />
      </Col>
      <Col xs={3} className="ms-2 px-0">
        <Icon className="m-0 me-2" name={open ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS} />
        {`${activityBilling.resourceFirstName ?? ""} ${activityBilling.resourceLastName ?? ""}`}
      </Col>
      <Col xs={3} className="px-0">
        {activityBilling.number ?? "-"}
      </Col>
      <Col xs={3} className="px-0">
        {dateUtils.formatToLocalDateString(dateUtils.parseDate(activityBilling.date)) ?? "-"}
      </Col>
      <Col xs={4} className="px-0">
        {getPeriod()}
      </Col>
      <Col xs={3} className="px-0">
        {`${activityBilling.billAmount ?? "-"}€`}
      </Col>
      <Col xs={3} className="px-0">
        {`${activityBilling.activitiesAmount ?? "-"}€`}
      </Col>
      <Col xs={2} className={disableSelect ? "px-5 ms-4" : "d-none"}>
        {dateUtils.formatToLocalDateString(dateUtils.parseDate(activityBilling.paymentDate)) ?? "-"}
      </Col>
      <Col xs={2} onClick={(event) => event.stopPropagation()} className="px-0">
        <Icon name="Download" size={IconSizeType.XS} onClick={() => downloadBill(activityBilling.fileId)} />
      </Col>
    </Row>
  )
}

export default ActivityBillingOverviewTableRow;
