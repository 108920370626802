import React, {FunctionComponent} from "react";
import {PurchaseStateMap, PurchaseStatus, SupplierPurchaseDto} from "../../../../interfaces/PurchaseInterfaces";
import {useIntl} from "react-intl";
import {Col} from "reactstrap";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import Icon from "../../../icon/Icon";
import Badge from "../../../atoms/Badge";

interface SupplierPurchaseTableRowProps {
  className?: string,
  purchase: SupplierPurchaseDto,
}

const SupplierPurchaseTableRow: FunctionComponent<SupplierPurchaseTableRowProps> = ({
  className = "",
  purchase,
}) => {
  const intl = useIntl()
  return (
    <div className={`epow-custom-table-row-main ${className ?? ""}`}>

      <Col>
        <b>{purchase?.purchaseNumber}</b>
      </Col>

      <Col>
        {dateUtils.formatDateToLocalDateTime(dateUtils.parseDate(purchase?.purchaseDate), false)}
      </Col>

      <Col>
        {purchase?.numberBookingValidated} / {purchase?.bookings}
      </Col>

      <Col>
        {purchase?.numberOrderDocument}
        <Icon name="PaperClip" size={IconSizeType.XS} className={"ps-2 pe-2"} />
      </Col>

      <Col>
        <Badge pill color={PurchaseStateMap[purchase?.status]?.color} >
          {intl.formatMessage({id: PurchaseStateMap[purchase?.status]?.label ?? PurchaseStatus.OPEN})}
        </Badge>
      </Col>
    </div>
  )
}

export default SupplierPurchaseTableRow;
