import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Contact, EntityContactRequest, HasContact} from "../../../../interfaces/ContactInterfaces";
import {toastUtils} from "../../../../utils/toastUtils";
import GenericModal from "../GenericModal";

interface ModalMainContactUpdateProps {
  className?: string;
  title?: string;
  openModalMainContact?: boolean;
  setOpenModalMainContact?: (open: boolean) => void;
  onUpdate?: (mainContact: Contact) => void;
  contactInitial?: Contact;
  entity?: HasContact;
  updateRequest: (id: string, entityId: string, request: EntityContactRequest) => Promise<unknown>
}

const ModalMainContactUpdate: FunctionComponent<ModalMainContactUpdateProps> = ({
  className = "",
  title,
  openModalMainContact,
  setOpenModalMainContact,
  onUpdate,
  contactInitial,
  entity,
  updateRequest,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"});
  const cancelButton = intl.formatMessage({id: "cancel_button"});

  const updateMainContact = (contactInitial?: Contact) => {
    if (contactInitial?.id) {
      updateRequest(contactInitial?.id, entity?.id, {isMainContact: true, contact: contactInitial})
        .then((contact) => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_contact"}));
          onUpdate(contact);
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_contact"}));
        });
      setOpenModalMainContact(false);
    }
  };

  return (
    <GenericModal
      className={className}
      title={title}
      open={openModalMainContact}
      onOpen={setOpenModalMainContact}
      closable
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => updateMainContact(contactInitial),
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: () => setOpenModalMainContact(false),
        },
      }}
    >
      {intl.formatMessage(
        {id: "modal_body_update_main_contact"},
        {
          lastName: contactInitial?.identity?.lastName,
          firstName: contactInitial?.identity?.firstName,
        }
      )}
    </GenericModal>
  );
};
export default ModalMainContactUpdate;
