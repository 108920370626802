import React, {createContext, FunctionComponent, useState} from "react";
import {CustomerIdentity} from "../interfaces/CustomerInterfaces";


const ActiveCustomerContext = createContext<CustomerIdentity>(undefined)
const ActiveCustomerDispatchContext = createContext<React.Dispatch<CustomerIdentity>>(undefined)

const ActiveCustomerProvider: FunctionComponent<{children: React.ReactNode}> = ({children}) => {
  const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem("activeCustomerForUser")));
  const setActiveCustomer = (customer: CustomerIdentity) => {
    localStorage.setItem("activeCustomerForUser", JSON.stringify(customer));
    setCustomer(customer)
  }

  return (
    <ActiveCustomerContext.Provider value={customer}>
      <ActiveCustomerDispatchContext.Provider value={setActiveCustomer}>
        {children}
      </ActiveCustomerDispatchContext.Provider>
    </ActiveCustomerContext.Provider>
  )
}

export {ActiveCustomerProvider, ActiveCustomerContext, ActiveCustomerDispatchContext}
