import {API_USER_RULES_PATH} from "../constants/routes/RoutePaths";
import {UserRule} from "../interfaces/RuleInterface";
import {PROFILE} from "../interfaces/UserInterfaces";
import {httpService} from "./HttpService";

const getRulesForUserWithProfile = (userId: string, profile: PROFILE): Promise<UserRule[]> => {
  return httpService.get<UserRule[]>(`${API_USER_RULES_PATH}/${userId}/${profile}`);
}

export const userRulesService = Object.freeze({
  getRulesForUserWithProfile,
});
