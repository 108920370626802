import React, {FunctionComponent, useMemo} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {useIntl} from "react-intl";
import PageTitle from "../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import useSearchFilter from "../hooks/useSearchFilter";
import {PurchaseSearchObject, PurchaseStatus} from "../interfaces/PurchaseInterfaces";
import SupplierPurchaseList from "../components/molecules/list/SupplierPurchaseList";

const SupplierPurchaseListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  const initialSearchState: PurchaseSearchObject = {
    customerOrPurchase: "",
    customerId: "",
    startDate: "",
    endDate: "",
    status: ""
  }
  const {searchObject, onReset, updateSearchField} = useSearchFilter<PurchaseSearchObject>(initialSearchState)

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => [{
    title: intl.formatMessage({id: "activity_bill_filter_all"}),
    onclick: () => updateSearchField("status", undefined),
    active: true
  },
  {
    title: intl.formatMessage({id: "workflow_purchase_open"}),
    onclick: () => updateSearchField("status", PurchaseStatus.OPEN),
    active: false
  },
  {
    title: intl.formatMessage({id: "workflow_purchase_sent"}),
    onclick: () => updateSearchField("status", PurchaseStatus.SENT),
    active: false
  },
  {
    title: intl.formatMessage({id: "workflow_purchase_closed"}),
    onclick: () => updateSearchField("status", PurchaseStatus.CLOSED),
    active: false
  }], [intl])

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "purchases_title"})}></PageTitle>
          <HeaderButtonGroup buttonList={stateFilterButtons} />
        </div>

        <SupplierPurchaseList searchObject={searchObject} onReset={onReset} updateSearchField={updateSearchField} />
      </div>
    </Layout>
  )
}

export default SupplierPurchaseListView
