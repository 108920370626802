import React, {FunctionComponent} from "react";
import {customerService} from "../../../services/CustomerService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import {CreateActivityCustomerSchema} from "../../../constants/validation/ActivityValidationSchemas";
import {ActivityCustomer} from "../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../services/ActivityService";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";

interface CreateActivityCustomerFormProps {
  id?: string,
  className?: string,
  activityId?: string,
  activityCustomerId?: string,
  customerId?: string,
  onValidate?: () => void
  numberPaxMax?: number
  bookTicketTourLeader?: boolean
  billingName?: string
  existingCustomerIds?: string[]
}

const CreateActivityCustomerForm: FunctionComponent<CreateActivityCustomerFormProps> = ({
  id,
  className = "",
  activityId,
  activityCustomerId,
  customerId = "",
  onValidate = () => null,
  numberPaxMax = 0,
  billingName,
  existingCustomerIds = [],
  bookTicketTourLeader
}) => {
  const intl = useIntl()

  const handleSubmitActivityCustomer = (activityCustomer: ActivityCustomer) => {
    if (!activityCustomerId) {
      activityService.createActivityCustomer({...activityCustomer, activityId: activityId})
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_activity_customer"}))
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_activity_customer"}))
        })
    } else {
      activityService.updateActivityCustomer(activityCustomerId, activityCustomer.paxMaxCustomer, activityCustomer.billingName, activityCustomer.bookTicketTourLeader)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_activity_customer"}))
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_activity_customer"}))
        })
    }
  }

  return (
    <Formik
      initialValues={{customerId, paxMaxCustomer: numberPaxMax, billingName, bookTicketTourLeader}}
      validationSchema={CreateActivityCustomerSchema}
      onSubmit={handleSubmitActivityCustomer}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          {!activityCustomerId &&
            <FormAutocompleteSelectPageable
              id="customerId"
              label={intl.formatMessage({id: "customer_field"})}
              placeholder={intl.formatMessage({id: "customer_placeholder"})}
              onChange={(value) => setFieldValue("customerId", value)}
              value={values?.customerId}
              fetchData={(page, filter) => customerService.getCustomersPage(page, {
                ...filter,
                currentState: WorkflowStatesEnum.ACTIVE,
                excludedIds: existingCustomerIds
              })}
              filterFieldName="name"
              required
              error={errors?.customerId}
              touched={touched?.customerId}
            />
          }
          <FormInput
            id="paxMaxCustomer"
            label="activity_customer_pax_max_customer"
            type="number"
            value={values?.paxMaxCustomer}
            minValue={0}
            onChange={(e) => setFieldValue("paxMaxCustomer", e.target.value)}
            required
            error={errors.paxMaxCustomer}
            touched={touched.paxMaxCustomer}
          />
          <FormInput
            id="billingName"
            label="activity_customer_billing_name"
            value={values?.billingName}
            minValue={0}
            onChange={(e) => setFieldValue("billingName", e.target.value)}
            required
            error={errors.billingName}
            touched={touched.billingName}
          />

          <FormRadio
              id="bookTicketTourLeader"
              label="activity_ticket_tour_leader"
              value={YES_NO_OPTIONS.find((option) => option?.value === values?.bookTicketTourLeader?.toString())}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) => setFieldValue("bookTicketTourLeader", option?.value)}
              required
              error={errors.bookTicketTourLeader}
              touched={touched.bookTicketTourLeader}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivityCustomerForm
