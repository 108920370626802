import React, {FunctionComponent} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import PreBillingList from "../../components/molecules/list/PreBillingList";
import {useIntl} from "react-intl";
import PageTitle from "../../components/molecules/header/PageTitle";

const PreBillingListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "billing_title"})}></PageTitle>
        </div>
        <PreBillingList/>
      </div>
    </Layout>
  )
}

export default PreBillingListView
