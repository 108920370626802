import React, {FunctionComponent, useEffect, useState} from "react";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage, useIntl} from "react-intl";
import Panel from "../../panel/Panel";
import {toastUtils} from "../../../../utils/toastUtils";
import CreateTourCustomerForm from "../../form/CreateTourCustomerForm";
import TourCustomerList from "../../list/TourCustomerList";
import {tourService} from "../../../../services/TourService";
import {CustomerCatalog} from "../../../../interfaces/TourInterfaces";
import {Season} from "../../../../interfaces/SeasonInterfaces";
import {seasonService} from "../../../../services/SeasonService";
import {STATUS_NOT_FOUND} from "../../../../constants/HttpStatusConstants";
import SeasonItem from "./SeasonItem";
import Toggle from "../../../atoms/Toggle";

interface TourActivityCustomerCardProps {
  className?: string,
  tourId?: string
}

const TourActivityCustomerCard: FunctionComponent<TourActivityCustomerCardProps> = ({
  className = "",
  tourId
}) => {
  const intl = useIntl()
  const [lateralPanelTourCustomer, setLateralPanelTourCustomer] = useState<boolean>(false)
  const [customersCatalog, setCustomersCatalog] = useState<CustomerCatalog[]>([])
  const [selectedSeason, setSelectedSeason] = useState<Season>(undefined)
  const [seasons, setSeasons] = useState<Season[]>([])

  const getSeasons = () => {
    seasonService.getSeasonsForTour(tourId)
      .then((seasons) => {
        setSeasons(seasons)
        if (seasons && seasons.length > 0) {
          if (!selectedSeason) {
            setSelectedSeason(seasons[0])
          } else {
            setSelectedSeason(seasons.find(season => season.id === selectedSeason.id))
          }
        }
    })
    .catch((error: Response) => {
      if (error?.status !== STATUS_NOT_FOUND) {
        toastUtils.errorToast(intl.formatMessage({id: "season_fetch_failure"}))
      }
    })
}

  const getActivitiesForCustomersByTour = () => {
    tourService.getActivitiesForCustomersByTourBySeason(tourId, selectedSeason?.id)
      .then((customersCatalog) => {
        setCustomersCatalog(customersCatalog)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_tour_customer_activities"}))
      })
  }

  const handleValidate = () => {
    setLateralPanelTourCustomer(false)
    getSeasons()
  }

  const [openAll, setOpenAll] = useState<boolean>(false);

  useEffect(() => {
    if (selectedSeason) {
      getActivitiesForCustomersByTour()
    }
  }, [selectedSeason])

  useEffect(() => {
    getSeasons()
  }, [])

  return (
    <div className={`${className} row`}>
      <div className="d-flex align-items-center bold justify-content-end mb-3">
        <FormattedMessage id="activity_tour_expand_all" />
        <Toggle className="ms-2" id="activity_tour_expand_all" onChange={(checked) => setOpenAll(checked)} />
      </div>
      <div className="col-8">
        <div className="nav flex-column nav-pills" id="season-nav" role="tablist" aria-orientation="vertical">
          {
            seasons?.map(season =>
              <SeasonItem
                season={season}
                handleSelectSeason={setSelectedSeason}
                key={season.id}
                isSelected={selectedSeason?.id === season.id}
              />
            )
          }
        </div>
        <div className="mt-3">
          <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setLateralPanelTourCustomer(true)} className="extended">
            <FormattedMessage id="add_customer"/>
          </Button>
        </div>
      </div>
      <div className="col-16">
        <TourCustomerList className="mb-3" customersCatalog={customersCatalog} openAll={openAll} onValidate={() => getActivitiesForCustomersByTour()}/>
        <div className={`${lateralPanelTourCustomer ? "backdrop": "d-none"}`}></div>
        {lateralPanelTourCustomer && (
          <Panel formId="createTourCustomerForm" title="add_customer" open={lateralPanelTourCustomer} onCancel={() => setLateralPanelTourCustomer(false)}>
            <CreateTourCustomerForm id="createTourCustomerForm" tourId={tourId} onValidate={handleValidate} existingCustomerIds={customersCatalog.map(c => c?.customer?.id)} />
          </Panel>
        )}
      </div>
    </div>
  )
}

export default TourActivityCustomerCard
