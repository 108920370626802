import {ActivitySellingPriceCatalog} from "../../../../interfaces/ActivityInterfaces";
import React, {FunctionComponent, useState} from "react";
import Accordion from "../../accordion/Accordion";
import {FormattedMessage} from "react-intl";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Panel from "../../panel/Panel";
import CreateActivitySellingPriceCustomerForm from "../../form/CreateActivitySellingPriceCustomerForm";
import ActivitySellingPriceList from "../../list/ActivitySellingPriceList";

interface ActivitySellingPriceCardProps {
  className?: string,
  activityCustomerId?: string,
  activitiesSellingPriceCatalog?: ActivitySellingPriceCatalog[],
  title: string,
  onValidate?: () => void
  activityDescription?: string;
}

const ActivitySellingPriceCard: FunctionComponent<ActivitySellingPriceCardProps> = ({
  className = "",
  activityCustomerId,
  activitiesSellingPriceCatalog,
  title,
  onValidate,
  activityDescription
}) => {
  const [lateralPanelActivitySellingPrice, setLateralPanelActivitySellingPrice] = useState<boolean>(false)
  const [initialActivitySellingPrice, setInitialActivitySellingPrice] = useState<ActivitySellingPriceCatalog>(null)

  const handleValidate = () => {
    onValidate && onValidate()
    setLateralPanelActivitySellingPrice(false)
  }

  return (
    <div className={className}>
      <Accordion
        className="primary"
        title={title}
        startOpen
      >
        <ActivitySellingPriceList
          activitiesSellingPriceCatalog={activitiesSellingPriceCatalog}
          setLateralPanelActivitySellingPrice={setLateralPanelActivitySellingPrice}
          setInitialActivitySellingPrice={setInitialActivitySellingPrice}
          onValidate={onValidate}
        />
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          className="extended"
          onClick={() => {
            setLateralPanelActivitySellingPrice(true)
            setInitialActivitySellingPrice(null)
          }}
        >
          <FormattedMessage id="add_button"/>
        </Button>
        {lateralPanelActivitySellingPrice && (
          <Panel
            formId="createActivitySellingPriceForm"
            title="add_selling_price"
            open={lateralPanelActivitySellingPrice}
            onCancel={() => setLateralPanelActivitySellingPrice(false)}
          >
            <CreateActivitySellingPriceCustomerForm
              id="createActivitySellingPriceForm"
              activityCustomerId={activityCustomerId}
              initialActivitySellingPrice={initialActivitySellingPrice}
              onValidate={handleValidate}
              activityDescription={activityDescription}
            />
          </Panel>
        )}
      </Accordion>
    </div>
  )
}

export default ActivitySellingPriceCard
