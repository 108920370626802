import React, {FunctionComponent, useContext, useEffect} from "react";
import {InvoiceSearchObject} from "../../../../interfaces/InvoiceInterfaces";
import {useIntl} from "react-intl";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {invoiceService} from "../../../../services/InvoiceService";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import DateFilter from "../../filters/DateFilter";
import FilterGroup from "../../filters/FilterGroup";
import TextFilter from "../../filters/TextFilter";
import BillingTable from "../../table/invoice/BillingTable";
import Pagination from "../../pagination/Pagination";
import Button from "../../../atoms/Button";
import {ActiveCustomerContext} from "../../../../context/ActiveCustomerContext";

interface CustomerBillingListProps {
  className?: string,
  searchObject?: InvoiceSearchObject,
  updateSearchField?: (field: string, value: string | string[]) => void
  onReset?: () => void,
}

const CustomerBillingList: FunctionComponent<CustomerBillingListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl();
  const activeCustomer = useContext(ActiveCustomerContext);

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
  } = usePaginatedData(invoiceService.getSelfInvoices, searchObject)

  useEffect(() => {
    updateSearchField("customerIds", [activeCustomer.id])
  }, [activeCustomer])

  return (
    <div className={`mb-5 ${className ?? ""}`}>
      <FilterGroup className="d-flex mb-2" >
        <div className="d-flex gap-2">
          <TextFilter
            title={intl.formatMessage({id: "invoice_filter_number"})}
            onChange={(value) => updateSearchField("invoiceNumberOrClient", value)}
            value={searchObject?.invoiceNumber}
            placeholder={intl.formatMessage({id: "invoice_filter_number_placeholder"})}
          />

          <DateFilter onChangeDate={(value) => updateSearchField("startDate", value)} value={searchObject?.startDate} title={intl.formatMessage({id: "order_filter_period_start"})} />
          <DateFilter onChangeDate={(value) => updateSearchField("endDate", value)} value={searchObject?.endDate} title={intl.formatMessage({id: "order_filter_period_end"})} />
        </div>
        <div className="ms-2">
          <Button onClick={onReset} color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <BillingTable invoices={data} sortOptions={sortOptions} onSortOptions={setSortOptions} />
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default CustomerBillingList;
