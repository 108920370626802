/**
 * File listing every translated label in English
 */

const EnglishMessages = {
  server_check: "Server communication state : ",
  logo_alt: "Hippo Logo",
  footer_link_cgu: "Confidentiality policies - Terms of use",
  close: "Close",

  // GUARDS
  guards_auth_error: "You have to be logged in to access this route!",
  guards_profile_restriction_error: "You don't have the permissions to access this route!",
  guards_profile_restriction_error_resource: "You don't  success_toast_update_quote_item: \"Ligne de devis mise à jour avec succès\",\n" +
    "  success_toast_create_quote_item: \"Ligne de devis créée avec succès\", have the permissions to access this route yet! You must validate your profile!",
  guards_rules_error: "You have to accept the rules in order to access the website",

  // FORMS
  required: "This field is mandatory",
  login_invalid_length: "The login must be sized between 3 and 50 characters",
  password_invalid: "The password does not respect rules",
  editable_field: "Editable field",

  // DASHBOARD
  dashboard_welcome: "Welcome {firstname} | ",
  dashboard_note: "Note :",
  dashboard_no_note: "No note",
  dashboard_note_added_by: "Added by {date} - {createdBy} ",
  dashboard_resolve: "Resolve",
  dashboard_notification_close: "Close",
  dashboard_priority_title: "To consult",
  dashboard_next_tour_leader_orders: "My next tours",
  dashboard_next_activities: "Next activities",
  dashboard_no_next_activities: "No incoming activities",
  dashboard_next_services: "Next services",
  dashboard_orders_today: "Orders today",
  dashboard_next_no_activities: "No incoming activities",
  dashboard_calendar: "Calendar",
  dashboard_taken_order: "Order taken",
  dashboard_forecast: "Forecast",
  dashboard_order: "Orders",
  dashboard_panel_staffing_colleague: "Your colleagues",
  dashboard_panel_activity_comment: "Comment",
  dashboard_panel_activity_rendez_vous_place: "Meeting place",
  dashboard_panel_activity_departure_hour: "Departure hour",
  dashboard_panel_activity_rendez_vous_date_hour: "Meeting date and hour",
  dashboard_panel_activity_rendez_vous_duration: "Duration",
  dashboard_panel_activity_pax: "PAX",
  dashboard_panel_activity_tour_leader: "Tour leader",
  dashboard_panel_activity_staffing: "Staff",
  dashboard_panel_activity_description: "Summary",
  dashboard_panel_activity_documents: "Documents",
  dashboard_panel_activity_additional_infos: "Additionel informations",
  dashboard_panel_activity_download_infos: "Download all files",
  dashboard_for_your_information: "For your information",
  dashboard_error_message: "Impossible to load the dashboard",
  last_month: "Last month",
  hide: "Hide",
  dashboard_next_tasks: "Next tasks",
  dashboard_tasks_orders: "Orders to handle",
  dashboard_tasks_to_staff: "Activities to staff",
  dashboard_tasks_booking_todo: "Bookings to create",
  dashboard_tasks_booking_pending: "Bookings to confirm",
  dashboard_tasks_staffed: "Resources staffed today",
  dashboard_customer_select_customer: "Select customer : ",

  // CONTACTS
  contact_title: "Contacts",
  contact_no_result: "There are no contacts.",
  email: "Email",
  phoneNumber: "Phone number",
  address: "Address",
  has_no_account: "No account",
  has_account: "Has access to the platform",
  invite: "Invite",
  contact_customer_field: "Customer",
  contact_last_name_field: "Last Name",
  contact_first_name_field: "First Name",
  contact_job_field: "Job",
  contact_phone_number_field: "Phone Number",
  contact_other_phone_number_field: "Other Phone Number",
  contact_email_field: "Email",
  contact_has_access_platform_field: "Access to the platform",
  contact_search_placeholder: "Search for a contact...",
  create_new_contact: "Create a new contact",
  create_new_user: "Create a new user",
  contact_table_name: "Name",
  contact_table_job: "Role / Department",
  contact_table_phone: "Phone number",
  contact_table_email: "Email",
  contact_table_platform_access: "Access to platform",
  main_contact_email: "Email",
  error_inviting_contact: "An error occured while inviting the contact",
  contact_invited: "Contact invited",

  // SUPPLIERS
  supplier_title: "Suppliers",
  create_new_supplier: "Create a new supplier",
  supplier_no_result: "There are no suppliers.",
  supplier_placeholder: "Search for a placeholder",
  supplier_table_search_placeholder: "Supplier name",
  supplier_table_header_name: "Name",
  supplier_table_header_contact: "Main contact",
  supplier_table_header_contact_email: "Contact email",
  supplier_table_header_services: "Services",
  supplier_table_header_bookings: "Pending bookings",
  supplier_table_service_number: "{serviceCount} service(s)",
  supplier_table_booking_number: "{bookingCount} booking(s)",
  dropdown_header_supplier: "Supplier",
  supplier_name_field: "Name",
  supplier_name: "Name of the supplier",
  supplier_address: "Address",
  supplier_general_info: "General Information",
  supplier_main_contact: "Main contact",
  contact_supplier_field: "Supplier",
  supplier_settings_info: "Settings",
  supplier_pre_booking_delay: "Pre-booking delay (in days)",
  supplier_payment_delay: "Payment delay (in days)",
  supplier_apply_vat: "Apply VAT",
  supplier_has_voucher: "Voucher",
  supplier_email: "Enterprise email",
  supplier_phone: "Enterprise phone",
  supplier_comment: "Comment",
  supplier_current_bookings_number: "Total current bookings",
  supplier_banking_info: "Banking data",
  supplier_bank_account_id: "Identifier/Internal code",
  add_contact_to_supplier: "Add a contact to the supplier",
  supplier_catalog: "Supplier catalog",

  // BANK ACCOUNTS
  bank_account_not_found: "No bank account found",
  add_bank_account: "Add a bank account",
  bank_account_name: "Account name",
  bank_name: "Bank name",
  bank_code: "Bank code",
  bank_counter_code: "Counter code",
  bank_account_number: "Bank account number",
  bank_rib_key: "RIB Key",
  bank_iban_code: "IBAN code",
  bank_bic_code: "BIC code",
  modify_bank_account: "Modify a bank account",

  // CUSTOMERS
  customer_title: "Customers",
  customer_no_result: "There are no customers.",
  create_new_customer: "Create a new customer",
  customer_list_search_label: "Search for a client",
  customer_list_search_placeholder: "Name, code",
  customer_table_header_name: "Name",
  customer_table_header_contact: "Main contact",
  customer_table_header_tours: "Tours",
  customer_table_header_orders: "Orders",
  customer_table_customer_number: "{tourNumber} tour(s)",
  customer_table_order_number: "{orderNumber} order(s)",
  customer_placeholder: "Search for a client",
  dropdown_header_customer: "Customer",
  customer_name_field: "Customer's name",
  customer_code_field: "Client code",
  customer_intra_vat_field: "Intracommunity VAT",
  customer_general_info: "General Information",
  main_contact: "Main contact",
  customer_comment: "Comment",
  company_name: "Name of the company",
  customer_code: "Client code",
  customer_catalog: "Customer catalog",
  add_customer: "Add a customer",
  update_customer: "Update a customer",
  customer_field: "Customer",
  customer_tour_not_found: "No customers for this tour",
  customer_payment_condition: "Payment conditions",
  payment_condition_AT_RECEPTION: "At reception",
  payment_condition_THIRTY_DAYS_AFTER_RECEPTION: "30 days after reception",

  // BILLING
  billed_by_default: "Billed by default at ",
  order_item_order_number: "Order number or customer reference",
  dropdown_header_order_customer: "Customer",
  dropdown_header_billing_customer: "Billing Customer",
  billing_from: "Start date",
  billing_to: "End date",
  billing_no_result: "Nothing to bill in the chosen criteria",
  menu_billing: "Billing",
  billing_done: "In billing",
  billing_title: "Sales billing",
  billing_table_header_name: "Activity / Service",
  billing_table_header_order: "Order",
  billing_table_header_billing_service: "Billing customer",
  billing_table_header_date: "Activity date",
  billing_create_invoice: "Create pre-invoices",
  billing_download_invoice: "Download invoice",
  billing_item_auto_complete_info: "The billing item is automatically completed if the field below are not filled",
  billing_item_name: "Billing name",
  billing_item_quantity: "Quantity",
  billing_item_unit_price: "Unit price",
  billing_item_toast_success_deletion: "The billing item has been deleted",
  billing_item_toast_success_update: "The billing item has been updated",
  billing_item_toast_error_deletion: "An error occured while deleting the billing item",
  billing_field_contact: "Contact",
  billing_field_contact_placeholder: "Find a contact",
  // TOUR LEADERS
  tour_leader_title: "Tour Leaders",
  tour_leader_no_result: "There are no tour leaders.",
  create_new_tour_leader: "Create a new tour leader",
  tour_leader_table_header_name: "Nom",
  tour_leader_table_header_email: "Email",
  tour_leader_table_header_phone: "N° téléphone",
  tour_leader_table_header_customers: "Nb clients",
  tour_leader_table_header_orders: "Nb commandes",
  tour_leader_table_customer_number: "{customerNumber} clients",
  tour_leader_table_order_number: "{orderNumber} commandes",
  tour_leader_first_name_field: "First name",
  tour_leader_last_name_field: "Last name",
  tour_leader_email_field: "Email",
  dropdown_header_tour_leader: "Tour Leader",
  tour_leader_general_info: "General information",
  customers_represented_by_tour_leader: "Customers represented",
  customer_represented_by_tour_leader_field: "Customer(s) represented",
  customer_represented_by_tour_leader_no_result: "No customers represented by this tour leader",
  tour_leader_search_placeholder: "First name, last name",
  tour_leader_search_title: "Search for a tour leader",

  tour_leader_order_email_title: "Send email",
  tour_leader_order_email_greetings: "Hello {fullName}",
  tour_leader_order_email_body: "Please find below the names(s) of your resources and timings for the following tour code :",
  tour_leader_order_email_body2: "Could you please double check the below information and let me know if all is fine on your side.",
  tour_leader_order_email_bye: "Kind regards",
  tour_leader_order_email_meeting: "Meeting {rendezVousHour}",
  tour_leader_order_email_departure: "Departure {departureHour}",
  tour_leader_order_email_departure_place: "Meeting place :  {rendezVousPlace}",
  tour_leader_order_email_comment: "Comment: {description}",
  tour_leader_order_email_ending: "Find all the informations needed on your dedicated connection on {softouringLink} or send us an email at contact@parisontheway.fr.",
  tour_leader_order_email_order_pax: "Tour PAX : {pax}",
  tour_leader_order_email_order_item_pax: "Service PAX : {pax}",

  // TOURS
  tour_title: "Tours",
  tour_no_result: "There are no tours.",
  dropdown_header_tour: "Tour",
  tour_name_search: "Search for a tour",
  tour_table_search_placeholder: "Name, tour code",
  tour_placeholder: "Search for a tour",
  create_new_tour: "Create new tour",
  tour_table_header_code: "Tour code",
  tour_table_header_name: "Name",
  tour_table_header_customers: "Customers",
  tour_table_header_activities: "Activities",
  tour_table_header_orders: "Orders",
  tour_table_customer_number: "{customerCount} customer(s)",
  tour_table_activity_number: "{activityCount} activity(ies)",
  tour_table_order_number: "{orderCount} orders(s)",
  tour_tour_name_field: "Tour name",
  tour_tour_code_field: "Tour code",
  tour_name: "Name",
  tour_code: "Tour Code",
  tour_number_pax: "PAX",
  tour_general_info: "General Information",
  create_new_tour_activity: "Add a service to the tour",
  tour_activity_day_after_arrival_field: "Number of days after arrival",
  tour_activity_hour_field: "Time of activity",
  tour_activity_departure_hour_field: "Time of departure",
  tour_activity_optional_field: "Optional",
  tour_activity_description_field: "Description",
  tour_activity_meeting_place_field: "Meeting place",
  tour_activity_billing_client_activity_name: "Billing client activity name",
  tour_activity_resource_number_field: "Number of resources",
  tour_activity_field: "Activity",
  tour_activity_no_result: "No activity for this tour",
  tour_customer_field: "Customer billed by default",
  edit_tour_activity: "Edit a tour activity",

  // SERVICE
  supplier_reference_field: "Reference",
  supplier_description_field: "Description",
  dropdown_header_service: "Service",
  service_title: "Services",
  service_placeholder: "Search for a service",
  service_table_search_placeholder: "Name",
  service_no_result: "There are no service",
  create_new_service: "Service",
  service_table_header_name: "Name",
  service_table_header_supplier: "Default supplier",
  service_table_header_bookings: "current bookings",
  service_table_booking_number: "{bookingCount} booking(s)",
  service_general_info: "General Information",
  service_name: "Name",
  service_reference: "Reference",
  service_description: "Description",
  suppliers_for_service: "Suppliers of service",
  service_default_supplier: "Default supplier",
  service_change_default_supplier: "Change",
  service_activity: "Activity",
  number_of_services: "Number of services",
  customer_service_not_found: "No customers for this service",
  service_unit_type: "Unit",
  service_unit_sell_type: "Sell unit",
  service_group_size: "Group size",
  service_unit_price_excluding_tax: "Buying Unit price without tax (€)",
  service_sell_price_excluding_tax: "Sell price without tax (€)",
  service_start_date_validity: "Start of validity",
  add_customer_service_info_catalog: "Add information from the service's customer catalog",
  add_supplier_service_catalog: "Add information from the service's supplier catalog",
  service_number_pax: "Pax max",
  service_default_unit: "Default unit",
  service_default_sell_unit: "Default sell unit",
  service_default_price: "Default buying price (€)",
  service_default_sell_price: "Default selling price (€)",
  service_unit_price: "Unit price",
  service_vat_rate: "VAT Applicable (%)",

  // RESOURCES
  create_new_resource: "Create a new resource",
  resource_title: "Resources",
  resource_no_result: "There are no resources of this type",
  resource_table_header_name: "Name",
  resource_table_header_email: "Email",
  resource_table_header_phone: "Phone number",
  resource_table_header_area: "Area",
  resource_table_header_availability: "Availability",
  resource_table_header_activity_number: "Number of activities staffed",
  resource_table_activity_number: "{activityCount} activity(ies)",
  resource_gender_field: "Gender",
  resource_last_name_field: "Last name",
  resource_first_name_field: "First name",
  resource_birth_name_field: "Birth name",
  resource_nationality_field: "Nationality",
  resource_email_field: "Email",
  resource_phone_number_field: "Phone number",
  resource_other_phone_number_field: "Other phone number",
  resource_search_placeholder: "First name, last name, email",
  resource_create: "Create a new resource",
  dropdown_header_resource: "Resource",
  resource_name_filter: "Search for a resource",
  resource_filter_criteria: "Filter criterias :",
  resource_filter_staffing_to_type: "Sent to :",
  resource_filter_staffing_to_type_all: "Everyone",
  resource_filter_on_activity: "Activity",
  resource_filter_on_kids: "Kids",
  resource_filter_on_public_type: "Public Type",
  resource_general_info: "General information",
  resource_comment: "Comment",
  resource_professional_situation: "Professional situation",
  resource_status_field: "Status",
  resource_level_field: "Level",
  resource_date_first_mission_field: "First mission date",
  resource_seniority_field: "Seniority",
  resource_seniority_value: "{years} years and {months} months",
  resource_availability_field: "Availability criteria",
  resource_structure_field: "Attached structure",
  resource_structure_field_popover: "If the attached structure is incorrect, please contact your administrator.",
  resource_card_field: "Professional card",
  resource_job_info: "Job",
  spoken_languages: "Spoken languages",
  resource_legal_info: "Legal information",
  resource_public_liability_card: "Public liability",
  resource_rcp_cover_start_date: "RCP cover start date",
  resource_rcp_validity_date: "RCP validity date",
  resource_urssaf_card: "URSSAF situation",
  resource_urssaf_situation_date: "URSSAF situation date",
  resource_public_liabiliy_card_field: "Public liability card",
  resource_turnover_popover: "Indicate your turnover from last year",
  resource_public_type: "Public type",
  resource_display_options_field: "Resource display rules",
  resource_display_option_none: "Display nothing",
  resource_display_option_name: "Name",
  resource_display_option_name_phone: "Name and phone",
  resource_toast_picture_error: "Problem retrieving the photo",


  resource_activities_handling: "Manage resource activities",
  resource_activities_search: "Search for activities to add...",
  resource_activities_add: "Add",
  resource_activities_to_add: "Activities to select",
  resource_activities_to_add_sublabel: "Choose below mastered activities",
  resource_activities_known: "Activities known",
  resource_activities_known_sublabel: "List of mastered activities",
  resource_activities_known_mobile_label: "Show the {count} activities declared as mastered",
  resource_activities_add_success: "The activities have benn added",
  resource_activities_add_error: "An error occurred whilst adding the selected activities to the resource",
  resource_activities_get_error: "An error occurred whilst fetching the resource activities",
  resource_activities_delete_error: "An error occurred whilst removing the selected activity from the resource",
  resource_activities_table_column_name: "Associated activity name",
  resource_activities_table_delete_selection: "Delete selected items",
  resource_profile_picture: "Profile picture",
  resource_validate_profile_help_label: "Please fill the 5 tabs \"{tab1}\", \"{tab2}\", \"{tab3}\", \"{tab4}\" et \"{tab5}\" and confirm you profile.\r\nYou won't be able to be staffed until this validation.",

  // STRUCTURES
  create_new_structure: "Create a new structure",

  // USERS
  filter_validated: "Validated",
  filter_profile: "Profile",
  user_title: "Users",
  user_activate_modal_title: "Enable an user",
  user_deactivate_modal_title: "Disable an user",
  user_activate_modal_message: "Are you sure you want to enable the user {fullName} ?",
  user_deactivate_modal_message: "Are you sure you want to disable the user {fullName} ?",
  user_no_result: "There are no users.",
  users_table_header_name: "Name",
  users_table_header_email: "Email",
  users_table_header_profiles: "Profile(s)",
  users_table_header_active: "Active",
  users_table_header_created_on: "Created on",
  users_table_header_last_connection: "Last connection",
  edit_user: "Edit user",
  user_first_name: "First name",
  user_last_name: "Last name",
  user_email: "Email",
  user_profiles: "Profile(s)",
  user_phoneNumber: "Phone number",
  user_other_phoneNumber: "Other phone number",
  user_job: "Job",
  dropdown_header_user: "User",

  // ORDERS
  dropdown_header_order: "Order",
  order_title: "Order",
  order_number: "Order number",
  order_default_pax:"Default Pax",
  order_customer: "Customer",
  order_customer_ref: "Customer reference",
  orders_tour: "Tour",
  order_referenceClient: "Customer reference",
  order_dayZero: "Start date of the tour",
  order_date: "Date order",
  create_orders: "Create orders",
  create_order: "Create order",
  order_csv_title: "Import list of orders",
  order_csv_button: "Import CSV",
  order_travel_book_button: "Export the travel book",
  orders_title: "Orders",
  orders_form_title: "Modify orders",
  order_csv_success: "Orders were successfully created",
  order_csv_import_error: "Unexpected error when importing csv",
  order_csv_error: "Fix remaining errors in order to import orders",
  order_import_contains_errors: "The order list contains errors",
  order_no_result: "There are no orders",
  order_status: "Status",
  order_comment: "Comment",
  order_no_order_item: "Your order contains no activities",
  order_add_order_item: "Add an activity",
  order_item_email_send: "Send order",
  order_item_email_sent: "Order sent",
  order_creation_date: "Created on",
  order_tour_leader: "Tour leader",
  order_client_does_not_exist: "The client is unknown or inactive",
  already_exists_customer_ref: "Reference already exists",
  order_tour_does_not_exist: "The tour is unknown or inactive",
  order_tour_does_not_exist_with_client: "The tour is not available with this client",
  order_day_zero_before_today: "The start date must be after the current date",
  order_day_zero_before_order_date: "The start date must be after the order date",
  order_activities: "Activities",
  order_price: "Total excl. taxes",
  order_filter_done: "Completed",
  order_filter_coming: "Coming",
  order_filter_all_requests: "Pending",
  order_filter_open: "Open",
  order_filter_to_handle: "Draft",
  order_filter_to_handled: "Handled",
  order_filter_to_completed: "Completed",
  order_filter_code_title: "Search an order",
  order_filter_code_placeholder: "Client reference, N° order",
  order_filter_period_start: "From",
  order_filter_period_end: "To",
  order_filter_customer: "Customer",
  order_state_draft: "Draft",
  order_state_pending: "Pending",
  order_state_handled: "Handled",
  order_state_completed: "Completed",
  order_state_cancelled: "Cancelled",
  order_status_default: "Unknown",
  order_status_cancelled: "Cancelled",
  order_total_number: "{total} orders",
  order_booking_to_do: "Bookings to do",
  order_csv_template: "Download the template",
  order_booking_create: "Create bookings",
  order_send_email: "Send email",

  // ORDER ITEMS
  create_new_order_item: "Ajouter une ligne",
  order_item_unit_type_pax: "PAX",
  order_item_unit_type_fixed_rate: "Forfait",
  order_item_type_activity: "Prestation",
  order_item_type_service: "Service",
  order_item_type: "Type",
  order_item_activity: "Activity",
  order_item_service: "Service",
  order_item_name: "Name",
  order_item_reference: "Reference",
  order_item_optional: "Optional",
  order_item_is_staffing_management: "Staffing management",
  order_item_is_booking_management: "Booking management",
  order_item_activity_ops: "Operational activity",
  order_item_description: "Description",
  order_item_place: "Place",
  order_item_date: "Date",
  order_item_cancel_date: "Cancellation date",
  order_item_hour: "Rendez-vous hour",
  order_item_departure_hour: "Departure hour",
  order_item_pax: "PAX",
  order_item_pax_confirmed: "PAX confirmed",
  order_item_duration: "Duration",
  order_item_quantity: "Selled quantity",
  order_item_number_of_resources: "Number of : {label}",
  order_item_unit_type: "Unit",
  order_item_pax_price: "Price per person",
  order_item_sell_price: "Sell price",
  order_item_sell_price_tva: "20% Tax",
  order_item_sell_price_read: "Sell price",
  order_item_sell_price_tva_read: "VAT",
  order_item_sell_price_total: "Sell price (VAT included)",
  order_item_billing_service: "Billing service",
  order_item_disbursements: "Disbursement",
  order_item_resources_to_staff: "Resources to staff",
  order_item_resources_staffed: "Number of staffed resources",
  order_item_documents: "Document(s)",
  order_item_bookings: "Réservation(s)",
  order_item_state_draft: "Draft",
  order_item_state_pending: "To complete",
  order_item_state_validated: "Completed",
  order_item_fetch_error: "Impossible to fetch order item",
  order_item_state_cancelled: "Cancelled",
  order_item_filter_code_placeholder: "Search for an order item",
  order_item_add_file: "Add a file",
  order_item_add_voucher: "Add a voucher",
  order_item_add_ticket: "Add a ticker",
  order_item_no_booking: "No booking",
  order_item_no_documents: "No documents",
  order_item_needs_tickets: "Waiting for client's tickets",
  order_item_update_success: "Order item successfully updated",
  order_item_create_success: "Order item successfully created",
  ACTIVITY: "Activity",
  SERVICE: "Service",
  order_update_tour_leader: "Update tour leader",
  order_update_tour_leader_success: "Tour leader updated",
  order_update_tour_leader_error: "Error when updating tour leader",
  order_tour_leader_mail_pj: "Attachments (5 max)",
  order_tour_leader_mail_cc: "Email address to be CC'd",
  order_form_search: "Search",
  order_form_confirm: "Apply modifications",
  order_form_applied: "Modifications applied",
  order_filter_order_number: "Order number",
  order_mass_update_success: "Order(s) updated",

  // ACTIVITY
  activity_title: "Activities",
  dropdown_header_activity: "Activity",
  activity_placeholder: "Search for an activity",
  activity_table_search_title: "Search for an activity",
  activity_table_search_placeholder: "Name",
  activity_no_result: "No activity",
  activity_table_header_name: "Name",
  activity_table_header_reference: "Reference",
  activity_table_header_duration: "Duration",
  activity_table_header_maxpax: "Max PAX",
  activity_table_header_tours: "Proposed in",
  activity_table_tour_number: "{tourCount} tour(s)",
  add_activity_to_tour: "Add an activity to the season",
  add_config_activity_for_customer: "Configure the activity for the client",
  day: "Day",
  hour: "Rendez-vous hour",
  departureHour: "Departure hour",
  add_activity_in_tour_for_customer: "Customer {customer} - Activity {activity}",
  setting_by_default: "Default settings :",
  activity_tour_not_found: "No activity for this season",
  activity_tour_expand_all: "Expand all",
  create_new_activity: "Create a new activity",
  activity_name_field: "Name",
  activity_reference_field: "Reference",
  activity_billing_name: "Billing name",
  activity_duration: "Duration",
  activity_description: "Description",
  activity_number_pax_max: "Maximum PAX",
  activity_zone: "Activity zone",
  activity_zone_placeholder: "Search for a geographical zone",
  activity_is_staffing_management: "Staffing management",
  activity_is_for_kids: "For kids",
  activity_price: "Sell price (€)",
  activity_informations_title: "Sell informations",
  activity_resource_price: "Resource price (€)",
  add_services_to_activity: "Adding services to the activity",
  services_in_activity: "Services",
  activity_resource_price_title: "Resource price",
  activity_start_date_validity: "Date of validity",
  activity_tariff: "Selling price (€)",
  activity_senior_employee: "Senior employee (€)",
  activity_junior_employee: "Junior employee (€)",
  activity_senior_independent: "Senior independent (€)",
  activity_junior_independent: "Junior independant (€)",
  activity_senior_employee_rate: "Senior employee rate",
  activity_junior_employee_rate: "Junior employee rate",
  activity_senior_independent_rate: "Senior independent rate",
  activity_junior_independent_rate: "Junior independant rate",
  activity_need_rate: "At least one rate must be filled",
  activity_customer_not_found: "No customers for this activity",
  add_selling_price: "Add a selling price",
  add_resource_rate: "Add a resource rate",
  activity_list: "Activities list",
  activity_hide_staffing: "Hide resources",
  activity_show_staffing: "Show resources",
  activity_customer_pax_max_customer: "PAX Max. Customer",
  activity_customer_billing_name: "Billing name",
  activity_linked_activities_title: "Activities linked to the current activity",
  activity_linked_activities_elements_mobile_label: "Show the {count} activities linked",
  activity_linked_activities_suppliers_title: "Activities to link",
  activity_linked_activities_elements_title: "Activities linked",
  activity_more_info: "More information",
  activity_customer_delete_title: "Customer catalog deletion",
  activity_customer_delete_body: "Are you certain you want to delete the whole catalog for client {client} ?",
  activity_customer_delete_success: "Catalog has been deleted successfully",
  activity_customer_delete_failure: "An error occurred while attempting to delete customer catalog",
  activity_ticket_tour_leader: "Booking ticket for the tour leader",

  // PROFILE
  profile: "Profile",
  profile_all: "All",

  // MENU
  menu_dashboard: "Dashboard",
  menu_sales: "Sales",
  menu_purchase: "Purchase",
  menu_staffing: "Staffing",
  menu_staffing_request: "My requests",
  menu_catalog: "Catalog",
  menu_documentary_base: "Documents",
  menu_customer: "Customer",
  menu_invoice: "Billing",
  menu_invoice_list: "Invoices",
  menu_tour: "Tour",
  menu_tour_leader: "Tour Leader",
  menu_quotes: "Quotes",
  menu_admin: "Administration",
  menu_activity: "Prestation",
  menu_service: "Service",
  menu_resource: "Resource",
  menu_supplier: "Supplier",
  menu_order: "Order",
  menu_order_form: "Modify Orders",
  menu_order_tour: "My tours",
  menu_staffing_list: "Staffing management",
  menu_billing_overview: "Bills overview",
  menu_purchase_order: "Purchase orders",
  menu_booking: "Booking management",
  menu_self_staffing: "My activities",
  menu_self_order: "My orders",

  // ERRORS
  server_error: "Une server error has occurred",
  no_row: "No results",
  mandatory_field: "Mandatory field",
  Forbidden: "The user account has been deactivated",
  Unauthorized: "No user account matches the email and password provided",
  login_error: "Unexpected error while logging in",
  upload_bad_file_type: "The file does not have the right extension",
  profile_not_selected: "No profile selected",
  field_email_invalid: "This email is invalid",
  field_number_positive_or_zero: "The value must be above or equal to 0",
  field_number_positive: "The value must be above 0",
  error_toast_create_customer: "Error while creating a customer",
  error_toast_create_supplier: "Error while creating a supplier",
  error_toast_get_users: "Error while retrieving the list of users",
  error_toast_get_user: "Error while retrieving the user",
  error_toast_get_contacts: "Error while retrieving the list of contacts",
  error_toast_get_activity_resource_requirements: "Error while retrieving activity resource requirements",
  error_toast_get_customers: "Error while retrieving the list of customers",
  error_toast_get_customer: "Error while retrieving the customer",
  error_toast_get_resources: "Error while retrieving the list of resources",
  error_toast_get_tours: "Error while retrieving the list of tours",
  error_toast_get_one_customer: "Error while retrieving a customer",
  max_length_error_field: "Maximum number of characters is 20",
  invalid_iban_field: "Invalid IBAN format",
  error_toast_create_contact: "Error while creating a contact",
  field_phone_number_invalid: "This phone number is invalid",
  error_toast_get_main_contact: "Error when retrieving the main contact",
  error_toast_update_customer: "Error when editing a client",
  error_toast_update_supplier: "Error when editing a supplier",
  error_toast_update_contact: "Error when editing the contact",
  error_toast_delete_contact: "Error when deleting contact",
  error_toast_create_tour: "Error when creating a tour",
  error_toast_update_tour: "Error when editing a tour",
  error_toast_get_activities: "Error when retrieving the list of activities",
  error_toast_create_tour_activity: "Error when adding an activity to the tour",
  error_toast_get_tour_activities: "Error while retrieving the list of activities of a tour",
  error_toast_create_tour_customer: "Error when adding a customer to the tour",
  error_toast_get_customers_by_tour: "Error when retrieving customers by tour",
  error_toast_get_one_activity: "Error when retrieving an activity",
  error_toast_create_tour_activity_customer: "Error when creating all season catalogs for a customer",
  error_toast_get_tour_customer_activities: "Error when retrieving specific activities of a tour for a customer",
  error_toast_delete_tour_activity: "Error when deleting the activity of a tour",
  error_toast_update_tour_activity: "Error when editing the activity of a tour",
  error_toast_update_tour_activity_customer: "Error when editing a specific activity in a tour for a customer",
  error_toast_delete_tour_activity_customer: "Error when deleting the activity of tour for a customer",
  error_toast_create_tour_leader: "Error when creating a leader tour",
  error_toast_create_activity: "Error when creating the activity",
  error_toast_get_one_tour: "Error when retrieving a tour",
  error_toast_get_one_service: "Error while retrieving a service",
  error_toast_get_one_resource: "Error when retrieving a resource",
  error_toast_get_one_supplier: "Error when retrieving a supplier",
  error_toast_get_one_reference: "Error when retrieving the reference",
  error_update_supplier_settings: "Error when editing the supplier's settings",
  error_get_bank_accounts: "Error while retrieving the list of bank accounts",
  error_get_black_date_accounts: "Error while retrieving the list of black dates",
  error_create_bank_account: "Error when creating a bank account",
  error_delete_bank_account: "Error when deleting the bank account",
  error_update_bank_account: "Error while updating the bank account",
  error_toast_update_tour_leader: "Error when editing a leader tour",
  error_toast_update_activity: "Error when editing the activity",
  error_toast_get_services: "Error while retrieving services",
  error_toast_staffing_request: "Error while sending the staffing request",
  error_create_resource: "Error while creating the resource",
  error_update_resource: "Error while editing the resource",
  error_get_structures: "Error while retrieving the list of structures",
  error_create_structure: "Error when creating a structure",
  error_toast_send_mail_reset_password: "Error when sending the email to reset the password",
  error_toast_reset_password: "Error while resetting your password",
  error_toast_get_activity_area: "Error while fetching the activity areas",
  error_toast_create_activity_customer: "Error when adding the customer to the activity",
  error_toast_create_activity_selling_price: "Error when adding the selling price to the customer of the activity",
  error_toast_create_activity_resource: "Error when adding the resource rate to the customer of the activity",
  error_toast_update_activity_selling_price: "Error when editing the selling price",
  error_toast_update_activity_resource: "Error when editing the resource rate",
  error_toast_delete_activity_selling_price: "Error when deleting the selling price",
  error_toast_delete_activity_resource_rate: "Error when deleting the resource rate",
  error_toast_delete_customer_service_info_catalog: "Error when deleting customer catalog information",
  error_toast_update_customer_service_info_catalog: "Error when editing customer catalog information",
  error_toast_create_customer_service_info_catalog: "Error when creating customer catalog information",
  error_toast_create_customer_service: "Error while adding the customer to the service",
  error_get_languages: "Error while retrieving languages",
  error_toast_staffing_validation: "Error while accepting the staffing request",
  error_toast_staffing_update: "Error while updating staffing",
  error_toast_get_one_purchase: "Error when retrieving a purchase order",
  error_toast_delete_booking: "Error when deleting the booking",
  error_toast_get_suppliers: "Error when retrieving the list of suppliers",
  error_toast_create_purchase_order: "Error when creating a purchase order",
  error_toast_create_booking: "Error when creating a booking",
  error_toast_update_booking: "Error when editing a booking",
  error_toast_update_purchase: "Error when editing a purchase order",
  error_retrieving_file: "Error while retrieving file",
  error_toast_purchases_created: "Error while creating purchases",
  error_toast_activate_user: "Error while activating user",
  error_toast_deactivate_user: "Error while deactivating user",
  error_toast_update_user: "Error while updating the user",
  error_toast_creating_user: "Error while creating the user",
  error_toast_get_rules: "Error while retrieving the list of usage rules",
  error_toast_update_rule:" Error while updating the usage rule",
  error_toast_create_rule: "Error while creating a usage rule",
  error_toast_activate_rule: "Error while activating the rule",
  error_toast_deactivate_rule: "Error while deactivating the rule",
  error_toast_upload_file: "Error while uploading the file",
  error_toast_service_search: "Error while loading services",
  error_toast_order_get: "Error while loading order",
  error_toast_order_update: "Error while loading order",
  error_toast_order_item_search: "Error while loading order items",
  error_toast_order_item_update: "Error while updating order items",
  error_toast_fetch_my_documents: "Error while fetching documents",
  error_toast_admin_config: "Error while fetching settings",
  error_toast_save_admin_config: "Error while saving settings",
  error_toast_delete_document: "Error when deleting the document",
  error_toast_validate_order: "Error while validating order",
  error_toast_validate_orders: "Error while validating orders",
  error_toast_edit_home_comment: "Error while creating home comment",
  error_toast_create_home_comment: "Error while updating home comment",
  error_toast_get_home_comment: "Error while getting home comment",
  error_toast_update_booking_document: "Error while updating booking",
  error_toast_cancel_booking_document: "Error while cancelling booking",
  error_toast_keep_booking_document: "Error while maintaining booking",
  error_toast_add_order_item_file: "Error while adding file",
  error_toast_delete_staffing: "Error while deleting staffing",
  error_toast_send_order_mail: "Error when sending email",
  error_toast_sorting: "Error while sorting",
  error_toast_get_suppliers_service: "Error when retrieving service suppliers",
  error_toast_delete_supplier_service_catalog: "Error when deleting supplier catalog information",
  error_toast_update_supplier_service_catalog: "Error when editing supplier catalog information",
  error_toast_create_supplier_service_catalog: "Error when creating supplier catalog information",
  error_profile_picture: "Error while uploading the profile picture",
  error_toast_delete_activity_service: "Error while deleting service",
  error_toast_calendar: "Error while loading calendar",

  // SUCCESS
  success_toast_update_customer: "Successful customer modification",
  success_toast_create_tour_activity: "The activity was successfully added to the tour. Be aware that this one is also added to all the clients linked to this tour.",
  success_toast_create_tour_customer: "The customer has been successfully added to the tour.",
  success_create_contact: "You have created a new contact",
  success_create_customer: "You have created a new customer",
  success_create_supplier: "You have created a new supplier",
  success_update_customer: "You have successfully modified the customer",
  success_update_supplier: "You have successfully modified the supplier",
  success_toast_update_contact: "You have successfully updated the contact",
  success_toast_delete_contact: "You have successfully deleted the contact",
  success_toast_create_tour_activity_customer: "Successfully created all season catalogs for a client",
  success_toast_delete_tour_activity: "You have successfully removed the activity of a tour",
  success_toast_update_tour_activity: "You have successfully modified the activity of a tour",
  success_toast_update_tour_activity_customer: "You have modified a specific activity in a tour for a customer",
  success_toast_delete_tour_activity_customer: "You have successfully removed the activity of a tour for a customer",
  success_toast_create_tour_leader: "You have created a new leader tour",
  success_toast_create_activity: "You have created a new activity",
  success_toast_update_tour_leader: "You have successfully updated the leader tour",
  success_toast_update_self_tour_leader: "You have sucessfully updated your information",
  success_toast_update_activity: "You have successfully updated the activity",
  success_update_supplier_settings: "You have successfully modified the supplier's settings",
  success_create_resource: "You have successfully created a resource",
  success_update_resource: "You have successfully updated the resource",
  success_create_structure: "You have created a new structure",
  success_create_bank_account: "You have successfully created a bank account",
  success_delete_bank_account: "You have successfully removed the bank account",
  success_update_bank_account: "You have successfully modified the bank account",
  sucess_toast_staffing_request: "The staffing request was successfully sent",
  success_toast_send_mail_reset_password: "You have sent an email to reset the password",
  success_toast_reset_password: "You have successfully reset your password",
  success_toast_updated_activity_customer: "The customer was successfully updated to the activity",
  success_toast_create_activity_customer: "The customer was successfully added to the activity",
  success_toast_create_activity_selling_price: "The selling price was added to the customer for this activity",
  success_toast_create_activity_resource: "The resource rate was added to the customer for this activity",
  success_toast_update_activity_selling_price: "You have successfully updated the selling price",
  success_toast_update_activity_resource: "You have successfully updated the resource rate",
  success_toast_delete_activity_selling_price: "You have successfully removed the selling price",
  success_toast_delete_activity_resource_rate: "You have successfully removed the resource rate",
  success_toast_delete_customer_service_info_catalog: "You have deleted the customer catalog information of the service",
  success_toast_update_customer_service_info_catalog: "You have successfully updated the service's customer catalog information",
  success_toast_create_customer_service_info_catalog: "You have added new information to the customer catalog of the service",
  success_toast_create_customer_service: "The customer has been successfully added to the service",
  success_toast_validate_order: "The order is validated",
  success_toast_validate_orders: "Orders validated",
  success_toast_staffing_request: "The staffing request was successfully sent",
  success_toast_staffing_validation: "The staffing request was successfully accepted",
  success_toast_staffing_update: "The staffing request was successfully updated",
  success_toast_delete_booking: "You have successfully deleted the booking",
  success_toast_create_purchase_order: "You have successfully created a new purchase order",
  success_toast_create_booking: "You have successfully created a new booking",
  success_toast_update_booking: "You have successfully updated the booking",
  success_toast_update_purchase: "You have successfully updated the purchase",
  success_toast_purchases_created: "Purchases have successfully been created",
  success_toast_activate_user: "You have successfully activated the user",
  success_toast_deactivate_user: "You have successfully deactivated the user",
  success_toast_update_user: "You have successfully updated the user",
  success_toast_create_user: "You have successfully created the user",
  success_toast_delete_generic: "Element successfully deleted",
  success_toast_update_rule: "You have successfully updated the usage rule",
  success_toast_create_rule: "You have successfully created a usage rule",
  success_toast_activate_rule: "You have successfully activated the rule",
  success_toast_update_admin_settings: "Settings successfully updated",
  succes_toast_deactivate_rule: "You have successfully deactivated the rule",
  success_delete_document: "You have successfully removed the document",
  success_toast_cancel_order_item: "Order item successfully cancelled",
  success_toast_edit_home_comment: "Home comment successfully created",
  success_toast_create_home_comment: "Home comment successfully modified",
  success_toast_create_booking_from_order_item: "Bookings successfully created",
  success_toast_create_booking_from_order_item_none: "No bookings to create",
  success_toast_update_booking_document: "Booking successfully updated",
  success_toast_booking_closed: "Booking successfully updated and closed",
  success_toast_booking_cancelled: "Booking successfully cancelled",
  success_toast_booking_keep: "Booking successfully maintained",
  success_toast_add_order_item_file: "File successfully added",
  success_toast_add_booking_file: "File successfully added",
  success_toast_update_order: "Order successfully updated",
  success_toast_delete_staffing: "Staffing successfully deleted",
  success_toast_send_order_mail: "Email successfully sent",
  success_toast_delete_bookings_to_do: "Bookings were successfully removed",
  success_toast_delete_supplier_service_catalog: "You have successfully deleted the service's supplier catalog information",
  success_toast_update_supplier_service_catalog: "You have successfully updated the service's supplier catalog information",
  success_toast_create_supplier_service_catalog: "You have added new information to the service's supplier catalog information",
  success_toast_delete_activity_service: "Service removed from activity",

  // TESTS
  popover: "Popover",
  fruits: "Fruits",
  radio: "Form Radio",
  upload: "Upload a file",

  // HEADER MENU
  header_menu_placeholder: "Customer, resource, order...",
  header_menu_search_button: "Search",
  header_menu_clear_search: "Clear",
  header_menu_add_user: "Add user",
  header_menu_create_button: "Create",
  header_menu_user_button: "User",
  header_profile_ADMIN: "Admin",
  header_profile_intern: "Intern",
  header_profile_resource: "Resource",
  header_profile_customer: "Customer",
  header_profile_tour_leader: "Tour Leader",
  header_profile_supplier: "Supplier",

  // LOGIN
  login_title: "Nice to see tou again !",
  login_subtitle: "Please enter your credentials below.",
  email_placeholder: "Enter your email address",
  form_login_email: "E-mail address",
  form_login_password: "Password",
  form_login_confirm_password: "Password confirmation",
  password_placeholder: "Enter your password",
  login_submit: "Connect me",
  profile_title: "Hello ",
  profile_subtitle: "On which platform do you want to connect ?",
  back: "Back",
  access_platform: "Accéder à la plateforme ",
  TOUR_LEADER: "Tour Leader",
  ADMIN: "Admin",
  INTERN: "Employee",
  SUPPLIER: "Supplier",
  RESOURCE: "Resource",
  CLIENT: "Customer",
  BILLING_SERVICE: "Billing service",
  dropdown_header_logout: "Logout",
  dropdown_header_profile: "My profile",
  login_reset_password: "I forgot my password",
  send_mail_reset_password: "Send the reset email",
  reset_password: "Reset your password",
  login_rule_download: "Download",
  login_rule_accept: "I have read and accepted the rules",
  login_rule_title: "User rules",
  login_rule_subtitle: "Please accept the following rules before proceeding",

  // MOT DE PASSE OUBLIE
  forgotten_password_title: "Password recovery",
  forgotten_password_subtitle: "Please enter your email address so we can send you a reset link.",
  new_password_title: "Reset your password",
  new_password_subtitle: "Please enter your new password.\n" +
    "It musts respect the following rules :\n" +
    "- at least 8 characters, \n" +
    "- both lower and uppercase letters, \n" +
    "- at least 1 number, \n" +
    "- at least 1 special character within #?!@$%^&*-.",
  token_expired: "Then link doesn't exist or is expired, please do forgotten password process",

  // OPTIONS
  yes_option: "Yes",
  no_option: "No",
  female_option: "Female",
  male_option: "Male",
  EMPLOYEE: "Employee",
  INDEPENDENT: "Independent",
  JUNIOR: "Junior",
  SENIOR: "Senior",
  ALL: "All",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  ZONE: "Zone",
  public_type_group: "Group",
  public_type_private: "Private",
  public_type_kids_friendly: "Kids friendly",

  // IDENTITY
  name_field: "Name",
  phone_number_field: "Phone number",
  email_field: "Email",

  // ADDRESS
  address_data_gouv_error: "No address matching your search was found",
  search_text: "Search in progress",
  address_not_found: "I can't find my address",
  address_placeholder: "Search for an address",
  country_placeholder: "Search for a country",
  address_field: "Address",
  additional_address_field: "Additional address",
  service_field: "Service",
  zip_code_field: "Postal code",
  city_field: "City",
  country_field: "Country",


  // TABS
  info_tab: "Information",
  details_tab: "Details",
  activity_monitoring_tab: "Activity monitoring",
  contact_tab: "Contacts",
  documents_tab: "Documents",
  setting_invoicing_tab: "Settings and invoicing",
  activity_tab: "Activities",
  supplier_tab: "Suppliers",
  service_tab: "Services",
  professional_info_tab: "Professional information",
  bank_accounts_tab: "Banking data",
  legal_info_tab: "Legal information",
  activities_tab: "Activities",
  linked_activities_tab: "Linked activities",
  required_resources_tab: "Required resources",

  // BUTTONS
  save_button: "Save",
  cancel_button: "Cancel",
  validate_button: "Validate",
  send_button: "Send",
  confirm_button: "Confirm",
  create_contact_button: "Create a contact",
  add_button: "Add",
  edit_button: "Edit",
  delete_button: "Delete",
  revoke_button: "Revoke",
  action_button: "Action",
  next_button: "Next",
  previous_button: "Previous",
  choose_button: "Choose",
  export_button: "Export",

  // INFORMATION MESSAGE
  info_message_init_password: "An email with a link to initialize the account password will be sent to the user",

  //WORKFLOW
  workflow_entity_tour: "tour",
  workflow_entity_activity: "activity",
  workflow_entity_service: "service",
  workflow_entity_resource: "resource",
  workflow_entity_supplier: "supplier",
  workflow_entity_tour_leader: "tour leader",
  workflow_entity_client: "customer",
  workflow_delete: "Delete {entity} record",
  workflow_validate: "Validate {entity} record",
  workflow_validate_self: "Validate your record",
  workflow_deactivate: "Deactivate {entity} record",
  workflow_reactivate: "Reactivate {entity} record",
  workflow_delete_info: "{entity} record has been deleted",
  workflow_validate_info: "{entity} record has been activated",
  workflow_validate_info_self: "Your record has been activated",
  workflow_deactivate_info: "{entity} record has been deactivated",
  workflow_reactivate_info: "{entity} record has been reactivated",
  workflow_delete_failure: "{entity} record could not be deleted",
  workflow_validate_failure: "{entity} record could not be activated. Verify that all required fields have been completed",
  workflow_validate_failure_self: "Your record could not be activated. Verify that all required fields have been completed",
  workflow_deactivate_failure: "{entity} record could not be deactivated",
  workflow_reactivate_failure: "{entity} record could not be reactivated. Verify that all required fields have been completed",
  workflow_created: "Created",
  workflow_active: "Actif",
  workflow_inactive: "Inactif",
  status: "Status",
  state_filter_all: "All states",
  state_filter_active: "Active",
  state_filter_inactive: "Inactive",
  state_filter_created: "Created",

  // INVOICE
  invoicing: "Invoicing",
  customer_individual_field: "Individual",
  customer_geographical_zone_field: "Customer's geographical area",
  eu_customer: "EU Customer",
  outside_eu_customer: "Customer outside EU",
  french_customer: "French Customer",
  customer_number_intra_vat_field: "Intracommunity VAT number",
  customer_invoice_field: "Invoice for tour operator",
  customer_billing_services_field: "Billing services",
  customer_default_billing_service_field: "Default billing service",
  customer_disbursement_field: "Disbursement",
  customer_voucher_management_field: "Customer voucher follow-up management",
  customer_main_sort_invoice_field: "Main sorting on invoices",
  customer_secondary_sort_invoice_field: "Secondary sorting on invoices",
  activity_date: "Date of activity",
  order_code: "Order code",
  order_button_bill: "Billing",
  activity_name: "Name of activity",
  customer_activity_price: "Price of activity",
  create_new_invoice: "Invoice",
  invoice_customer:"Billing customer",
  invoice_date: "Invoice date",
  success_toast_create_invoice:"Invoice created",
  success_toast_updated_invoice:"Invoice updated",
  error_toast_create_invoice:"An error occurred during the creation of the invoice",
  error_toast_update_invoice:"An error occurred during the update of the invoice",
  error_toast_delete_bookings_to_do:"An error occurred during the removal of the bookings",
  dropdown_header_invoice: "Invoice",
  invoice_billing_item_order_item: "Order item",
  invoice_voucher: "Vouchers",
  invoice_tickets: "Billets",
  invoice_files_error: "Error while loading files",
  invoice_name_order: "Contractor",

  // CREDIT NOTES
  modal_title_credit_note: "Generate a credit note",
  modal_body_credit_note: "Would you like to generate a credit note for invoice {invoiceNumber} ?",
  modal_body_credit_note_billing_items: "Billings items to include:",
  credit_note_header_title: "Credit note",
  go_to_invoice: "See invoice",
  go_to_order: "See associated order",
  go_to_credit_note: "See credit note",
  credit_note_download: "Download credit note",
  credit_note_validate: "Validate credit note",
  credit_note_billing_item_title_add: "Add a credit note item",
  credit_note_state_billed: "Validated",
  invoice_credit_note: "Generate a credit note",
  success_toast_update_credit_note:"Credit note updated",
  success_toast_create_credit_note:"Credit note created",
  error_toast_create_credit_note:"An error occurred during the creation of the credit note",
  error_toast_update_credit_note:"An error occurred during the update of the credit note",


  // OPERATION
  operation: "Operations",
  customer_reservation_management_field: "Reservation management",
  customer_public_type: "Public type",
  customer_number_weeks_viewed_field: "Number of weeks viewed",
  customer_notification_activity_field: "Inform about other services",
  customer_notification_replacement_field: "Notification of replacements",

  // MODALS
  modal_title_update_access_platform: "Update the access to the platform",
  modal_body_update_access_platform: "Would you like to give the access to the platform to contact {lastName} {firstName}?",
  modal_title_delete_access_platform: "Delete contact",
  modal_body_delete_access_platform: "Would you like to delete contact {lastName} {firstName}?",
  modal_title_update_main_contact: "Update the main contact",
  modal_body_update_main_contact: "Do you want the contact {lastName} {firstName} to be the main contact?",
  modal_title_delete_activity: "Delete an activity of the tour",
  modal_body_delete_activity: "Do you want to remove the activity {activity} of tour?",
  modal_title_delete_bank_account: "Delete a bank account",
  modal_body_delete_bank_account: "Do you want to remove the bank account {accountName}?",
  modal_title_update_default_bank_account: "Update the default bank account",
  modal_body_update_default_bank_account: "Do you want the bank account {name} to be the default bank account?",
  modal_title_delete_selling_price: "Delete a selling price",
  modal_body_delete_selling_price: "Do you want to remove the selling price?",
  modal_title_delete_resource_rate: "Delete a resource rate",
  modal_body_delete_resource_rate: "Do you want to remove the resource rate ?",
  modal_title_delete_customer_service_info_catalog: "Delete this information from the customer catalog",
  modal_body_delete_customer_service_info_catalog: "Would you like to delete the customer catalog information for the service?",
  modal_title_delete_booking: "Delete a booking",
  modal_body_delete_booking: "Would you like to delete the booking {serviceName}?",
  modal_title_delete_document: "Delete a document",
  modal_body_delete_document: "Do you want to remove the document?",
  modal_body_delete_order_item: "Do you want to delete this order item?",
  modal_body_delete_quote_item: "Do you want to delete this quote item?",
  modal_body_delete_quote_item_title: "Delete a quote item",
  modal_body_cancel_order_item: "Do you want to cancel or delete this order item?",
  modal_body_cancel_order_item_cannot_delete: "You cannot delete this order item, at least one staffing or booking is ongoing",
  modal_body_delete_order: "Do you want to delete this order?",
  modal_body_cancel_order: "Do you want to cancel this order?",
  modal_title_delete_staffing: "Delete staffing",
  modal_body_delete_staffing: "Do you want to delete this staffing?",
  modal_title_delete_supplier_service_catalog: "Delete this information from the supplier catalog",
  modal_body_delete_supplier_service_catalog: "Would you like to delete the supplier catalog information for the service?",
  modal_title_delete_activity_service: "Delete this service",
  modal_body_delete_activity_service: "Do you want to delete this service for this activity?",
  modal_title_validate_orders: "Validate orders",
  modal_body_validate_orders: "Do you want to validate those orders?",
  modal_title_cancel_staffing_item: "Are you sure you want to cancel this staffing?",
  modal_body_cancel_staffing_item: "Guides who have been contacted will receive an email canceling the request.",

  // COMMENTS
  comment_added_by: "From {firstName} {lastName} on {date}",
  comment_toast_success_creation: "The comment has been successfully added",
  comment_toast_success_update: "The comment has been successfully updated",
  comment_toast_error_creation: "An error occurred while adding the comment",
  comment_toast_success_deletion: "The comment has been successfully deleted",
  comment_toast_error_deletion: "An error occurred while deleting the comment",
  comment_toast_error_update: "An error occurred while updating the comment",

  // TABLES
  table_actions: "Actions",
  table_size: "Number of results",
  table_see_all_details: "Detailed list :",

  // STAFFING
  staffing_title: "Staffing",
  staffing_staff: "Staff",
  staffing_order: "Order",
  staffing_order_name: "Order name",
  staffing_activity: "Activities",
  staffing_date: "Date",
  staffing_date_hour: "Date - hour",
  staffing_hour: "Hour",
  staffing_place: "Rendez-vous place",
  staffing_resources: "Required resources",
  staffing_pending: "Pending",
  staffing_in_progress: "In progress",
  staffing_in_done: "Confirmed",
  staffing_search_order: "Search an order",
  staffing_search_order_placeholder: "Customer order",
  staffing_order_item: "Activity",
  staffing_resource: "Resource",
  staffing_tour: "Tour",
  staffing_activity_area: "Activity area",
  staffing_duration: "Duration",
  staffing_customer_reference: "Customer reference",
  staffing_supplier: "Supplier",
  staffing_service: "Service",
  staffing_customer: "Customer",
  staffing_from: "Order date from",
  staffing_to: "Order date to",
  staffing_availability: "Availability",
  staffing_resource_name: "Full name",
  staffing_add_message: "Add a message",
  staffing_edit_message: "Edit the message",
  staffing_edit_general_message: "Add a shared message",
  staffing_general_message: "Shared message",
  staffing_message_placeholder: "Input your message here...",
  staffing_confirm: "Confirm",
  staffing_available: "Available",
  staffing_available_confirmed: "{count} confirmed availabilities",
  staffing_not_available: "Not available",
  staffing_refuse: "Refuse",
  staffing_selected_number: "{selectedNumber} selected",
  staffing_send_request: "Send the staffing request",
  staffing_send_request_multiple: "Manual staffing request",
  staffing_request_title: "My activities",
  staffing_request_modal_body: "A staffing request will be sent for the following activities:",
  staffing_request_modal_body_confirm: "Do you confirm the operation?",
  staffing_request_validated: "Your availability for the {name} activity was taken into account",
  staffing_request_cancelled: "This activity was cancelled, you cannot accept it",
  staffing_request_direct: "Validated resource(s)",
  staffing_request_renew: "Renew staffing request",
  staffing_request_cancel: "Cancel staffing request",
  staffing_request_cancel_button: "Cancel staffing request",
  staffing_request_back_button: "Back",
  staffing_request_modal_cancel_body: "Are you sure you want to cancel this staffing? \nGuides who have been contacted will receive an email canceling the request",
  staffing_renew: "Renew",
  staffing_staffed: "Staffing state",
  staffing_request_to_replace: "Resource to replace",
  staffing_request_replacement: "New resource",
  staffing_sent: "Sent",
  staffing_confirmed: "Confirmed",
  staffing_cancelled: "Archived request",
  staffing_request_new_request_title: "Availability resquests ({count})",
  staffing_request_awaiting_request_title: "Awaiting Activity requests ({count})",
  staffing_request_confirmed_request_title: "Confirmed requests ({count})",
  staffing_request_number_available: "{count} answer(s)",
  staffing_ongoing_staffing: "Staffing is already ongoing for this activity",
  staffing_no_results: "No resource available with current filters",
  staffing_notification: "You are about to resend an email, are you sure ?",

  // BILLING OVERVIEW
  billing_overview_title: "Billing overview",
  billing_overview_number: "Bill number",
  billing_overview_number_placeholder: "Number...",
  billing_overview_resource: "Resource",
  billing_overview_period_start: "From",
  billing_overview_period_end: "To",
  billing_overview_name: "Resource name",
  billing_overview_date: "Date",
  billing_overview_payment_date: "Payment date",
  billing_overview_period: "Period",
  billing_overview_amount_bill: "Bill amount",
  billing_overview_amount_activities: "Activities amount",
  billing_overview_download: "Download",
  billing_overview_detail_tour_code: "Tour code",
  billing_overview_detail_activity: "Activity",
  billing_overview_detail_date_hour: "Date - hour",
  billing_overview_detail_price: "Price",

  // FILTERS
  autocomplete_results_size: "{total} more...",
  autocomplete_more_than_one_selected: "{size} items selected",
  filter_from: "From",
  filter_to: "To",
  filter_all: "Select All {selected}",

  // BOOKING
  bookings_title: "Bookings",
  booking_filter_supplier: "Supplier",
  booking_filter_service: "Service",
  booking_filter_order: "Order references",
  booking_filter_order_placeholder: "Order number ou customer ref",
  booking_filter_customer: "Customer",
  booking_order_reference: "Order reference",
  booking_default_supplier: "Default supplier",
  booking_date_hour: "Date",
  booking_pax: "booking PAX",
  booking_order_pax: "order PAX",
  booking_quantity: "Qty to book",
  booking_only_quantity: "Quantity",
  booking_supplier: "Supplier",
  booking_comment: "Comment",
  booking_price: "Price",
  booking_service: "Service",
  booking_customer: "Customer",
  booking_order_number: "Order number",
  booking_date: "Booking date",
  booking_hour: "Booking time",
  booking_create_purchases: "Create purchases",
  booking_request_modal_body: "The following purchases will be created:",
  booking_to_do: "To do",
  booking_add_bill_file: "Add bill file",
  booking_add_payment_file: "Add payment file",
  booking_add_confirmation_file: "Add confirmation file",
  booking_add_ticket_file: "Add ticket file",
  booking_add_other_file: "Add other file",
  booking_pending: "Pending",
  booking_actions: "Actions requises",
  booking_open: "Ouvertes",
  booking_waiting: "En attente confirmation",
  booking_confirmed: "Confirmé",
  booking_to_pay: "A régler",
  booking_warning: "Warning",
  booking_delete_to_do: "Delete bookings",
  booking_body_single_delete_to_do: "This booking will be deleted",
  booking_body_multiple_delete_to_do: "The following bookings will be deleted:",
  booking_pay_modal_title: "Booking payment",
  booking_pay_modal_body: "Do you wish to set this (these) bookings as paid ?\n\n Once done, an XML SEPA file will be generated and those bookings will be considered as paid.",
  booking_pay_modal_success: "The bookings were successfully paid",
  booking_pay_modal_failure: "An error occurred during the bookings payment",
  booking_reference: "Booking number",
  workflow_booking_to_do: "To book",
  workflow_booking_open: "To send",
  workflow_booking_pre_booked: "Pre-booked",
  workflow_booking_document_received: "Confirmed",
  workflow_booking_closed: "Closed",
  workflow_booking_to_update: "To update",
  workflow_booking_to_cancel: "To cancel",
  workflow_booking_cancelled: "Cancelled",
  booking_validation_status_change: "Confirm booking update",


  // PURCHASE
  purchases_title: "Purchase orders",
  purchase_booking_filter_title: "Search for a booking",
  purchase_filter_customer_order_placeholder: "purchase code",
  purchase_header_close: "Close",
  purchase_order_code: "Number",
  purchase_order_date: "Date",
  purchase_order_creation: "Créée le",
  purchase_order_supplier: "Supplier",
  number_booking_validated: "Number of bookings",
  number_order_document: "Number of documents",
  purchase_booking: "Booking",
  purchase_booking_quantity: "Quantity",
  purchase_booking_price: "Total",
  purchase_booking_status_unknown: "Unknown",
  purchase_booking_billed: "Billed",
  purchase_booking_paid: "Paid",
  purchase_order_number: "Purchase order N°",
  purchase_list_attachments: "List of attached documents",
  purchase_download: "Download XLSX",
  purchase_send_order: "Send order",
  purchase_delete_order: "Delete order",
  purchase_pay_bookings: "Pay unpaid bookings",
  purchase_order_booking_empty: "Your purchase order doesn't contain any bookings",
  purchase_booking_service: "Service",
  purchase_booking_price_net: "Net purchase amount",
  purchase_booking_total: "Total",
  purchase_booking_vat: "VAT",
  purchase_booking_total_TTC: "Total incl. VAT",
  purchase_booking_total_HT: "Total excl. VAT",
  purchase_booking_action: "Actions",
  purchase_booking_order_number: "Order",
  purchase_booking_pax: "PAX",
  purchase_booking_comment: "Comment",
  purchase_booking_status: "Status",
  purchase_booking_disbursement: "To be invoiced",
  purchase_booking_number: "N° booking",
  purchase_booking_confirmation_date: "Date de confirmation",
  purchase_booking_payment_alert_date: "Date of alert for payment",
  purchase_booking_invoice_date: "Invoice date",
  purchase_booking_order: "Order",
  purchase_booking_order_item: "Order item",
  purchase_booking_add: "Add a booking",
  purchase_booking_update: "Update a booking",
  purchase_order_comment: "Comments",
  purchase_attached_file_title: "Document(s)",
  purchase_no_attached_file: "No documents",
  purchase_delete_modal_title: "Delete purchase order",
  purchase_delete_modal_send_mail: "Send an email to supplier",
  purchase_delete_modal_message_label: "Message :",
  purchase_delete_modal_info: "Are you sure you want to delete the purchase order {number} ?",
  purchase_send_mail: "Send an email to supplier",
  modal_send_purchase_title: "Send purchase order",
  modal_send_purchase_contact: "Contact",
  success_toast_add_purchase_attached_file: "File successfully added",
  error_toast_update_purchase_attached_file: "Error adding file",
  success_toast_delete_purchase: "Purchase order {number} deleted successfully",
  error_toast_delete_purchase: "An error occurred while deleting the purchase order {number}",
  new_purchase_order: "New purchase order",
  create_purchase_order: "Create purchase order",
  button_selected_number: "{selectedNumber} select",
  workflow_purchase_open: "Open",
  workflow_purchase_sent: "Sent",
  workflow_purchase_closed: "Closed",

  // INVOICES
  invoice_filter_all: "All invoices",
  invoice_filter_draft: "Draft",
  invoice_filter_billed: "Billed",
  invoice_filter_number: "Find by invoice number",
  invoice_filter_number_placeholder: "Invoice number, customer",
  invoice_filter_type: "Type",
  INVOICE: "Invoice",
  CREDIT_NOTE: "Credit note",
  invoice_number: "Invoice Number",
  invoice_customer_name: "Billing customer",
  invoice_period: "Period",
  invoice_created_date: "Creation date",
  invoice_price_ttc: "All taxes included price (€)",
  invoice_price_ht: "Price (€)",
  invoice_tva: "VAT (%)",
  invoice_statut: "Status",
  invoice_title: "Invoices",
  invoice_header_title: "Invoices",
  invoice_state_draft: "Draft",
  invoice_state_billed: "Billed",
  invoice_validate: "Validate invoice",
  invoice_delete: "Delete invoice",
  billing_tab_invoices_to_create: "Sales Invoicing",
  billing_tab_invoices: "Invoices",
  billing_invoices_create: "Create invoice",
  billing_invoices_export: "VAT on margin",
  billing_invoices_export_body: "The VAT on margin will be exported for customer {client} on the period from {from} to {to}",
  billing_invoices_export_show_details: "Show activity details:",
  billing_generate_preinvoice: "Pre-invoice summary",
  billing_generate_preinvoice_success: "Pre-invoice summary generated",
  billing_generate_preinvoice_error: "Error while generation pre-invoices summary",
  billing_generate_invoice_modal_info: "You are about to generate invoices based on selected order item",
  billing_generate_invoice_modal_summary: "You are about to generate invoices based on customers:",
  invoice_billing_item_title_add: "Add a billing item",
  invoice_billing_item_edit_contact: "Edit contact",
  invoice_billing_item_header_date: "Date",
  invoice_billing_item_header_service: "Service",
  invoice_billing_item_header_title: "Quantity",
  invoice_billing_item_header_unit_price: "Unit price",
  invoice_billing_item_header_price: "Price",
  invoice_billing_item_header_priceTTC: "AIT Price",
  invoice_header_total_ht: "Total price",
  invoice_header_total_tva: "VAT",
  invoice_header_total_disbursement: "Total disbursement",
  invoice_header_total_ttc: "AIT total price",
  invoice_header_total: "Total to pay",
  invoice_download: "Download PDF",
  billing_item_additionnal_charge: "Additional charge",

  // STATUS
  TO_DO: "To do",
  OPEN: "Open",
  PRE_BOOKED: "Pre-booked",
  PENDING_RECEIPT: "Pending receipt",
  DOCUMENT_RECEIVED: "Confirmed",
  STATUS_DEFAULT: "Unknown",
  TO_UPDATE: "To update",
  TO_CANCEL: "To cancel",
  CANCELLED: "Cancelled",
  CLOSED: "Closed",

  // ADMINISTRATION
  admin_title: "Administration",

  // ADMIN CONFIGURATION
  config_tab: "Settings",
  admin_config_dashboard_booking_todo: "Nb of days for the bookings to do period",
  admin_config_dashboard_booking_todo_tooltip: "Period (from today until the days added) in which will be counted the bookings to send on the dashboard",
  admin_config_dashboard_booking_pending: "Nb of days for the bookings to confirm period",
  admin_config_dashboard_booking_pending_tooltip: "Period (from today until the days added) in which will be counted the bookings to confirm on the dashboard",
  admin_config_dashboard_staffing: "Nb of days for the activities to staff period",
  admin_config_dashboard_staffing_tooltip: "\"Period (from today until the days added) in which will be counted the staffings to do on the dashboard\"",
  admin_config_booking_prebooking_delay: "Supplier pre_booking delay",
  admin_config_booking_prebooking_delay_tooltip: "Delay from which is displayed the alert \"To send before X days\" on the Pending booking page, based on supplier delay",
  admin_config_booking_service_delay: "Nb of days before service",
  admin_config_booking_delta_minutes: "Update delta minutes",
  admin_config_booking_delta_minutes_tooltip: "Default time (minutes) between meeting time and departure time",
  admin_config_booking_service_delay_tooltip: "Delay from which is displayed the alert \"The service is in X days\" on the Pending booking page",
  admin_config_mail_signature: "Mail signature",
  admin_config_mail_signature_tooltip: "Global mail signature applied to the end of each mail",

  // HOME COMMENTS
  home_comment_config_tab: "Home comments",
  home_comment_no_result: "No comment found",
  home_comment_date: "Date",
  home_comment_content: "Content",
  home_comment_user: "Creator",
  home_comment_create: "Add a comment",
  home_comment_edit: "Edit a comment",

  // RULES
  rules_tab: "Usage rules",
  rules_table_header_profiles: "Profiles",
  rules_table_header_description: "Rule",
  rules_table_header_status: "Status",
  rules_no_result: "There are no usage rules",
  rules_table_all_profiles: "There are no usage rules",
  rules_table_status_active: "Active",
  rules_table_status_inactive: "Inactive",
  edit_rule: "Edit a usage rule",
  create_rule: "Create a usage rule",
  rule_profiles: "Profile(s)",
  rule_description: "Description",
  rule_attachment: "Associated document",
  PROFILE_ADMIN: "Administrator",
  PROFILE_INTERN: "Intern",
  PROFILE_RESOURCE: "Resource",
  PROFILE_CUSTOMER: "Customer",
  PROFILE_TOUR_LEADER: "Tour leader",
  PROFILE_SUPPLIER: "Supplier",

  // DOCUMENTARY BASE
  documentary_base_title: "Documentary base",
  documentary_base_table_column_name: "File name",
  documentary_base_table_column_activities: "Associated activities",
  documentary_base_table_column_profiles: "Profiles",
  documentary_base_filter_name: "File name",
  documentary_base_filter_name_placeholder: "File name...",
  documentary_base_filter_activity: "Activity",
  documentary_base_filter_profile: "Profile",
  documentary_base_filter_theme: "Theme",
  documentary_base_no_result: "There are no documents",
  documentary_base_add_document: "Add a document",
  documentary_base_form_profiles: "Display for profiles",
  documentary_base_form_theme: "Theme",
  documentary_base_form_activities: "Activities",
  documentary_base_checkbox_activities: "Linked to activities",
  documentary_base_checkbox_activityArea: "Linked to an activity area",
  documentary_base_checkbox_customer: "Linked to a customer",
  documentary_base_form_add_title: "Adding a document",
  documentary_base_form_edit_title: "Editing a document",
  documentary_base_form_create_success: "The new document was successfully created",
  documentary_base_form_create_failure: "Error while creating a new document",
  documentary_base_form_edit_success: "The document was successfully edited",
  documentary_base_form_edit_failure: "Error while editing the document",
  FILE_THEME_OPERATIONS: "Operations",
  FILE_THEME_GUIDED_TOUR: "Guided tour",
  FILE_THEME_POW_CUSTOMER_DEMANDS: "Paris on the Way Customer Demands",
  FILE_THEME_BILLING_ADMIN_IT_PROCESSES: "Billing, Administration, IT, Processes",
  FILE_THEME_CONSULTING_FEEDBACK_SECURITY: "Consulting / Feedback and security",
  FILE_THEME_MISCELLANEOUS: "Miscellaneous",
  FILE_THEME_NONE: "No theme",


  // BOOKING WARNINGS
  booking_open_warning: "To send before {days} days",
  booking_pre_booked_warning: "The service takes place in {days} days",
  booking_confirmed_warning: "Missing documents",
  booking_to_pay_warning: "To pay before {days} days",
  booking_created_after_order_item_update: "Created after order item update",

  // NOTIFICATION
  notification_staffing_assigned: "{count, plural, one {# new demand} other {# new demands}}",
  notification_staffing_assigned_title: "Assignation",
  notification_order_item_no_supplier: "No available supplier for one or more services in order item {name}",
  notification_order_item_no_supplier_title: "No available supplier",
  notification_profile_title: "Profile incomplete",
  notification_profile: "Your profile is not complete",
  notification_activity_updated_title: "Activity updated",
  notification_activity_updated: "{code} | {date} | {name}",
  notification_activity_schedule_changed: "Hours changed",
  notification_delete_error: "An error occured while deleting the notification",
  notification_order_item_bookings_to_update_title: "Bookings to update",
  notification_order_item_bookings_to_update: "Bookings from order item {name} to update",
  notification_booking_payment_alert_title: "Booking to pay",
  notification_booking_payment_alert: "Booking {name} to pay before {bookingDate}",
  notification_booking_todo_alert_title: "Booking to do",
  notification_booking_todo_alert: "Booking {name} to do before {bookingDate}",
  notification_order_item_incomplete_alert_title: "Activity emergency",
  notification_order_item_incomplete_alert: "The activity {name} scheduled on {rendezVousDate} is incomplete",
  notification_order_item_bookings_to_cancel_alert_title: "Bookings to cancel",
  notification_order_item_bookings_to_cancel_alert: "The activity {name} from the order {customerReference} has been cancelled",

  // BOOKING FORM
  booking_form_client_order: "Client order",
  booking_form_purchase_order: "Purchase order",
  booking_form_supporting: "Supporting documents",
  booking_form_bill: "Bill",
  booking_form_payment: "Payment",
  booking_form_supporting_field: "Invoice (PDF)",
  booking_form_button_supporting: "Add supporting document",
  booking_form_button_supplier_bill: "Add supplier bill",
  booking_form_button_payment: "Carry out payment",
  booking_form_supplier_bill_field: "Billing date",
  booking_form_supplier_payment_field: "Payment date",
  booking_form_send_update_email_field: "Send an update request email",
  booking_form_update_email_content_field: "Update email content",
  booking_form_cancel: "Cancel booking",
  booking_form_keep: "Maintain booking",

  // FILE TYPES
  file_type: "File type",
  file_ticket: "Ticket",
  file_confirmation: "Confirmation",
  file_other: "Other",
  TICKET: "Ticket",
  CONFIRMATION: "Confirmation",
  OTHER: "Other",
  BILL: "Bill",
  VOUCHER: "Voucher",
  PAYMENT: "Payment",
  ORDER_ITEM_FILE: "Activity document",
  max_files: "Maximum 5 files",
  max_files_count: "Maximum {max} files",
  file_too_large: "File is too large (3 mo max)",

  // ACTIVITY BILLING
  menu_activity_billing: "Billing",
  activities_billing_title: "Billing",
  activities_billing_filter_from: "From",
  activities_billing_filter_to: "To",
  activities_billing_no_result: "No activity matches the filters",
  activity_bill_date: "Date",
  activity_bill_name: "Activity name",
  activity_bill_customer: "Customer",
  activity_bill_reference: "Customer reference",
  activity_bill_activity_duration: "Duration",
  activity_bill_price: "Price",
  activity_bill_bill_state: "Billed",
  activity_bill_bill_number: "Bill ref",
  activity_bill_bill_date: "Bill date",
  activity_billing_not_billed: "Not billed",
  activity_billing_billed: "Billed",
  activity_bill_get_error: "Error while fetching bills",
  activity_bill_create_error: "Error while creating bills",
  activity_bill_results_number: "Number of results",
  activity_bill_total_price: "Sum of bills",
  activity_bill_filter_all: "All",
  activity_bill_filter_billed: "Billed",
  activity_bill_filter_not_billed: "Not Billed",
  activity_bill_create: "Add bill",
  activity_bill_amount: "Amount",
  activity_bill_filter_header_paid: "Paid",
  activity_bill_pay_button: "Pay the bills",
  activity_bill_pay_modal_tile: "Bill payment",
  activity_bill_pay_modal_body: "Do you wish to pay {count} bills ?",
  activity_bill_pay_modal_action_success: "Bills were successfully paid",
  activity_bill_pay_modal_action_failure: "An error occurred during the bills payment",
  activity_bill_download_failure: "An error occurred during downloading the file",
  activity_bill_filter_header_unpaid: "To pay",
  activity_bill_export: "Export to Excel",
  activity_billing_create: "Load an invoice",

  // ACTIVITY AREA
  activity_area_tab_title: "Activity areas",
  activity_area_label: "Label",
  activity_area_no_result: "No activity areas found",
  activity_area_create: "Create an activity area",
  activity_area_edit: "Modify an activity area",
  success_toast_edit_activity_area: "Activity area modified",
  error_toast_edit_activity_area: "Error while modifying activity area",
  success_toast_create_activity_area: "Activity area created",
  error_toast_create_activity_area: "Error while creating activity area",
  success_toast_delete_activity_area: "Activity area deleted",
  error_toast_delete_activity_area: "Error while deleting activity area",

  // RESSOURCE TYPE
  resource_type: "Resource type",
  resource_type_short: "Type",
  resource_type_tab_title: "Resource",
  resource_type_label: "Name",
  resource_type_color: "Colors",
  resource_type_no_result: "No resource types found",
  resource_type_create: "Create an resource type",
  resource_type_edit: "Modify an resource type",
  success_toast_edit_resource_type: "Resource type modified",
  error_toast_edit_resource_type: "Error while modifying resource type",
  success_toast_create_resource_type: "Resource type created",
  error_toast_create_resource_type: "Error while creating resource type",
  success_toast_delete_resource_type: "Resource type deleted",
  error_toast_delete_resource_type: "Error while deleting resource type",
  error_toast_fetch_resource_types: "Error while fetching resource types",


  // EXPORTS
  exports_tab_title: "Exports",
  exports_tab_summary: "Export activity summary",
  exports_tab_summary_csv: "Export to csv",
  exports_tab_summary_pdf: "Export to pdf",

  // MONTHS
  month_january: "January",
  month_february: "February",
  month_march: "March",
  month_april: "April",
  month_may: "May",
  month_june: "June",
  month_july: "July",
  month_august: "August",
  month_september: "September",
  month_october: "October",
  month_november: "November",
  month_december: "December",
  label_january_abv: "Jan.",
  label_february_abv: "Feb.",
  label_march_abv: "March",
  label_april_abv: "April",
  label_may_abv: "May",
  label_june_abv: "June",
  label_july_abv: "July",
  label_august_abv: "Aug.",
  label_september_abv: "Sep.",
  label_october_abv: "Oct.",
  label_november_abv: "Nov.",
  label_december_abv: "Dec.",

  // QUOTE
  quote_travel_book_button: "Export the travel book",
  quote_edit: "Quote edition",
  dropdown_header_quote: "Quote",
  create_new_quote: "Create quote",
  create_quote: "Create quote",
  quote_customer: "Customer",
  quote_contact: "Email contact",
  quote_tour: "Tour",
  quote_reference_lient: "Customer référence",
  quote_rendez_vous_date: "Start tour date",
  quote_date: "Quote date",
  quote_pax: "PAX",
  quote_end_of_validity: "End of validity",
  success_toast_update_quote: "Quote updated",
  success_toast_create_quote: "Quote created",
  success_toast_update_quote_item: "Quote item successfully updated",
  success_toast_create_quote_item: "Quote item successfully created",
  quote_customer_ref: "Customer ref",
  quote_code: "Quote number",
  quote_nb_items: "Nb activities",
  quote_total_ht: "Total HT",
  quote_status: "Status",
  quote_state_draft: "Draft",
  quote_state_sent: "Sent",
  quote_state_win: "Win",
  quote_state_loose: "Loose",
  quote_filter_all_requests: "All quotes",
  quote_filter_open: "Draft",
  quote_filter_to_handle: "Win",
  quote_filter_to_win: "Win",
  quote_filter_to_loose: "Loose",
  quotes_title: "Quote",
  quote_total_number: "{total} quotes",
  quote_to_sent: "Send quote",
  quote_duplicate: "Duplicate",
  quote_to_loose: "Quote lost",
  quote_to_win: "Quote won",
  quote_to_draft: "To draft",
  quote_download: "Download quote",


  quote_no_quote_item: "No activity",
  quote_add_quote_item: "Add activity",
  quote_filter_code_title: "Find a quote",
  error_toast_quote_update: "Error while updating quote",
  quote_creation_date: "Creation date",
  quote_demand_date: "Demand date",
  quote_date_end_of_validity: "End of validity",
  modal_body_delete_quote: "Are you sure you want to delete this quote ?",
  quote_day_zero: "Start tour date",
  quote_reference_client: "Customer reference",
  quote_send_mail: "Send email to customer with quote as PDF",
  quote_send_hello: "Hello {name}",
  quote_send_body_1: "Following your request for a quote, please see attached a detailed proposal of the services you wish us to provide.",
  quote_send_body_2: "By way of additional information, you will also find a detailed presentation as well as the Paris on the Way brochure",
  quote_send_body_3: "To approve this quote, please return a copy by email. This copy must be signed, dated and include the hand-written words “bon pour accord” (agreed). Companies are required to stamp the document with the company stamp.",
  quote_send_body_4: "Please note that this quote is valid for {endOdfValidity} days.",
  quote_send_body_5: "We thank you for getting in touch with Paris on the Way and remain entirely at your disposal for any further information.",
  quote_send_greetings: "Regards",
  quote_send_pj: "Attachment(s) (2 max)",
  modal_body_send_title: "Send quote",
  modal_body_send_quote: "The quote will be sent to the customer by email. Do you want to continue ?",
  success_toast_duplicate_quote: "Quote duplicated",
  error_toast_duplicate_quote: "An error occurred while duplicating quote",

  // RESOURCE AVAILABILITY
  menu_resource_availability: "Resource availability",
  resource_availability_title: "Availabilities",
  error_get_resource_availability: "Error while getting availabilities",
  error_update_resource_availability: "Error while updating availabilities",
  success_update_resource_availability:"Availabilities updated",
  resource_availability_pm: "PM",
  resource_availability_am: "AM",
  resource_availability_night: "Night",
  resource_availability_unavailable: "Unavailable",
  resource_availability_available: "Available",
  resource_availability_staffed: "Staffed",
  resource_availability_filter_start: "From",
  resource_availability_filter_end: "To",
  resource_availability_mass_unavailable: "Set as unavailable",
  resource_availability_set_available:"Set as available",
  resource_availability_set_unavailable:"Set as unavailable",
  resource_availability_select_all: "Select all",
  resource_availability_search: "Search",
  resource_is_nocturnal: "Nocturnal",

  resource_calendar_SENT:"Availability request sent",
  resource_calendar_AVAILABLE:"Availability confirmed",
  resource_calendar_CONFIRMED:"Staffed",
  resource_calendar_ALREADY_STAFFED:"Already staffed",
  resource_calendar_UNAVAILABLE:"Unavailable",
  resource_calendar_FREE:"Potential availability",
  resource_calendar_filter_activity_size:"Number of activities",
  resource_calendar_filter_resource_size:"Number of resources",

  view_calendar: "View calendar",
  view_reporting: "View reporting",
  view_resource_calendar: "Staffing calendar",
  menu_staffing_calendar: "Staffing calendar",
  display_nightly: "Display nightly",
  work_on_current_year: "Total current year",
  work_on_last_year: "Total last year",
  availability_calendar: "Available ",
  header_count_activity: "activities",
  header_count_staffing: "staffing",
  level_low_availability_calendar: "low",
  level_medium_availability_calendar: "medium",
  level_high_availability_calendar: "High",
  

  calendar_day_panel_section_available_filtered: "Confirmed availabilities",
  calendar_day_panel_section_available: "Confirmed availabilities (filters excluded)",
  calendar_day_panel_section_staffable: "Activities to staff",
  calendar_day_panel_no_section: "No activity available at this time for this resource",
  calendar_day_panel_to_staff: "To staff x{number}",
  calendar_day_panel_staffing_confirm: "Confirm",
  calendar_day_panel_staffing_ask_availability: "Ask for availability",
  calendar_day_panel_staffing_staff: "Staff",
  calendar_day_panel_to_staff_tooltip_no_available: "No resource available",
  calendar_day_panel_staffing_send_availability: "Send an email",
  year: "Year",

  // SEASONS
  season_add_to_tour: "Add a season for this tour",
  season_form_name: "Season name",
  season_form_start_date: "Validity start date",
  season_creation_success: "Successfully created the new season",
  season_fetch_failure: "An error occurred fetching seasons",
  season_creation_failure: "An error occurred attempting to create a new season",
  season_delete_title: "Delete a season",
  season_delete_message: "Are you sure you want to delete the season {season} ? A season will not be deletable if it is used in any client catalog entry.",
  season_delete_success: "Successfully deleted season",
  season_delete_failure: "An error occurred attempting to delete the season",
  season_for_catalog_tab_title: "Day by Day Client",
  season_start_validity_date: "Start validity date",
  season_customer_tour_name: "Tour name",
  season_update_day_by_day_form_title: "Customer {customer} - {season}",
  season_update_tour_customer_name: "Tour name for this customer",
  season_update_day_by_day_client_success: "Successfully updated the day by day client entry",
  season_update_day_by_day_client_failure: "An error occurred attempting to update the day by day client entry",
  season_delete_day_by_day_client_success: "Successfuly deleted the Day by Day Client",
  season_delete_day_by_day_client_failure: "An error occurred attempting to delete the Day By Day Client",
  season_delete_day_by_day_client_title: "Deleting a Day By Day Client",
  season_delete_day_by_day_client_body: "Are you sure you want to delete the Day by Day for client {client} and season {season} ?",

  // CROPPER
  crop_modal_title: "Crop an image",
  crop_image: "Confirm",

  // PICTURES
  picture_title: "Picture",
  picture_modal_title: "Delete the picture",
  picture_modal_body_tour: "Do you want to delete the tour's picture?",
  picture_modal_body_client: "Do you want to delete the customer's logo?",
  picture_modal_body_activity: "Do you want to delete the activity's picture?",
  picture_modify_tour: "Please add a picture with dimentions 400x200 for better results. Allowed formats are png, jpg, jpeg, gif, svg",
  picture_modify_client: "Allowed formats are png, jpg, jpeg, gif, svg",
  picture_modify_activity: "Please add a picture with dimentions 4000x200 for better results. Allowed formats are png, jpg, jpeg, gif, svg",
  picture_creation_success:"Picture successfully added",
  picture_deletion_success:"Picture successfully removed",

  // BLACK DATES
  black_date_error_create: "An error occurred while creating the black date",
  black_date_created: "The dates have been added",
  black_date_one_date: "One day",
  black_date_recurrence: "Recurrence",
  black_date_tab: "Black dates",
  each_monday: "Each Monday",
  each_tuesday: "Each Tuesday",
  each_wednesday: "Each Wednesday",
  each_thursday: "Each Thursday",
  each_friday: "Each Friday",
  each_saturday: "Each Saturday",
  each_sunday: "Each Sunday",
  no_supplier_available: "No supplier available for service at this date",

  // ACTIVITY RESOURCE REQUIREMENTS
  activity_resource_requirement_no_result: "This activity does not require any resource",
  activity_resource_requirement_quantity: "Required resource number",
  activity_resource_requirement_unit_price: "Unit price",
  activity_resource_requirement_create: "Add a required resource type",
  activity_resource_requirement_edit: "Edit a required resource type",
  activity_resource_requirement_edit_success: "Required resource type successfully updated",
  activity_resource_requirement_edit_error: "Error while updating the required resource type",
  activity_resource_requirement_create_success: "Required resource type successfully created",
  activity_resource_requirement_create_error: "Error while creating the required resource type",
  activity_resource_requirement_delete_body: "Are you sure you want to delete the required resource type ?",
  activity_resource_requirement_delete_success: "Required resource type successfully deleted",
  activity_resource_requirement_delete_error: "Error while deleting the required resource type",

  //ROAD BOOK
  road_book_download_pdf: "Export the travel book",
  road_book_title: "Daily itinerary",
  road_book_from: "From",
  road_book_to: "To",
  road_book_pax_tbc: "PAX : {pax} (TBC)",
  road_book_item_title: "Day {index} -",
  road_book_modal_ore_details: "More details >",

  // VAT
  vat_tab_title: "VAT Rate",
  vat_create: "Create VAT",
  vat_edit: "Edit TVA",
  vat_rate: "Rate (%)",
  vat_rate_table: "Rate",
  vat_name: "Name",
  vat_rate_no_result: "There is no VAT",
  vat_rate_toast_create_success: "VAT rate has been created",
  vat_rate_toast_create_error: "Error during VAT rate creation",
  vat_rate_toast_edit_success: "VAT rate has been modified",
  vat_rate_toast_edit_error: "Error during VAT rate modification",
  vat_rate_toast_delete_success: "VAT rate has been deleted",
  vat_rate_toast_delete_error: "Error during deletion, this VAT rate is used by a service.",
  vat_rate_toast_read_error: "Error when retrieving VAT rates."
};

export default EnglishMessages;
