import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from "react";
import {RouteProps, useSearchParams} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import OrderList from "components/molecules/list/OrderList";
import PageTitle from "../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import useSearchFilter from "../hooks/useSearchFilter";
import {ORDER_STATUS_PENDING, OrderSearchObject, OrderState, OrderStateView} from "../interfaces/OrderInterfaces";
import CustomerOrderList from "../components/molecules/list/CustomerOrderList";
import {authUtils} from "../utils/authUtils";
import {PROFILE, User} from "../interfaces/UserInterfaces";
import {userService} from "../services/UserService";
import {toastUtils} from "../utils/toastUtils";
import {dateUtils} from "../utils/dateUtils";
import OrderListActionsDropdown from "../components/molecules/input/order/OrderListActionsDropdown";
import useLoading from "../hooks/useLoading";
import Button from "../components/atoms/Button";
import {ActiveCustomerContext} from "../context/ActiveCustomerContext";
import Panel from "../components/molecules/panel/Panel";
import CreateOrderForm from "../components/molecules/form/CreateOrderForm";

interface OrderListViewProps extends RouteProps {
  title?: string
  itemPrefixPath?: string,
}

const OrderListView: FunctionComponent<OrderListViewProps> = ({title = "orders_title", itemPrefixPath}) => {
  const intl = useIntl()
  const [params] = useSearchParams()
  const {loading, startLoading, stopLoading} = useLoading()
  const isCustomer = authUtils.getProfile() === PROFILE.CLIENT;
  const customerContext = useContext(ActiveCustomerContext);

  const initialSearch: OrderSearchObject = {
    customerOrCode: "",
    customerId: isCustomer ? customerContext?.id : "",
    startDate: isCustomer ? dateUtils.formatDateYYYYMMDD(new Date()) : "",
    endDate: isCustomer ? dateUtils.formatDateYYYYMMDD(dateUtils.datePlusDays(new Date(), (customerContext.numberWeeksViewed * 7))) : "",
    status: isCustomer ? [OrderState.HANDLED, OrderState.PENDING, OrderState.COMPLETED] : ORDER_STATUS_PENDING,
    sort: isCustomer ? "beginTourDate" : "",
    view: OrderStateView.HOME,
  }

  const {searchObject, updateSearchField, onReset} = useSearchFilter<OrderSearchObject>(initialSearch)
  const [user, setUser] = useState<User>({})
  const [formOpen, setFormOpen] = useState<boolean>(false)

  const getYesterday = () => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return yesterday
  }

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => {
    const viewParam = params.get("view");

    if (authUtils.getProfile() === PROFILE.CLIENT) {
      return [
        {
          title: intl.formatMessage({id: "order_filter_coming"}),
          onclick: () => {
            updateSearchField("startDate", dateUtils.formatDateYYYYMMDD(new Date()))
            updateSearchField("endDate", isCustomer ? dateUtils.formatDateYYYYMMDD(dateUtils.datePlusDays(new Date(), (customerContext.numberWeeksViewed * 7))) : null)
            updateSearchField("view", OrderStateView.HOME);
            updateSearchField("status", [OrderState.HANDLED, OrderState.PENDING, OrderState.COMPLETED]);
          },
          active: viewParam === OrderStateView.HOME
        },
        {
          title: intl.formatMessage({id: "order_filter_done"}),
          onclick: () => {
            updateSearchField("startDate", null)
            updateSearchField("endDate", dateUtils.formatDateYYYYMMDD(getYesterday()))
            updateSearchField("view", OrderStateView.COMPLETED);
            updateSearchField("status", [OrderState.HANDLED, OrderState.PENDING, OrderState.COMPLETED]);
          },
          active: viewParam === OrderStateView.COMPLETED
        },
        {
          title: intl.formatMessage({id: "order_status_cancelled"}),
          onclick: () => {
            updateSearchField("startDate", null)
            updateSearchField("endDate", null)
            updateSearchField("view", OrderStateView.CANCELLED);
            updateSearchField("status", [OrderState.CANCELLED]);
          },
          active: viewParam === OrderStateView.CANCELLED
        },
      ]
    }

    if (authUtils.getProfile() === PROFILE.TOUR_LEADER) {
      return [{
        title: intl.formatMessage({id: "order_filter_all_requests"}),
        onclick: () => {
          updateSearchField("view", OrderStateView.HOME)
          updateSearchField("status", ORDER_STATUS_PENDING)
        },
        active: viewParam === OrderStateView.HOME
      },
      {
        title: intl.formatMessage({id: "order_filter_to_completed"}),
        onclick: () => {
          updateSearchField("view", OrderStateView.COMPLETED)
          updateSearchField("status", [OrderState.COMPLETED, OrderState.CANCELLED])
        },
        active: viewParam === OrderStateView.COMPLETED
      }]
    }

    return [{
      title: intl.formatMessage({id: "order_filter_all_requests"}),
      onclick: () => {
        updateSearchField("view", OrderStateView.HOME)
        updateSearchField("status", ORDER_STATUS_PENDING)
      },
      active: viewParam === OrderStateView.HOME
    },
    {
      title: intl.formatMessage({id: "order_filter_open"}),
      onclick: () => {
        updateSearchField("view", OrderStateView.DRAFT)
        updateSearchField("status", OrderState.DRAFT)
      },
      active: viewParam === OrderStateView.DRAFT
    },
    {
      title: intl.formatMessage({id: "order_filter_to_handle"}),
      onclick: () => {
        updateSearchField("view", OrderStateView.PENDING)
        updateSearchField("status", OrderState.PENDING)
      },
      active: viewParam === OrderStateView.PENDING
    },
    {
      title: intl.formatMessage({id: "order_filter_to_handled"}),
      onclick: () => {
        updateSearchField("view", OrderStateView.HANDLED)
        updateSearchField("status", OrderState.HANDLED)
      },
      active: viewParam === OrderStateView.HANDLED
    },
    {
      title: intl.formatMessage({id: "order_filter_to_completed"}),
      onclick: () =>  { 
        updateSearchField("view", OrderStateView.COMPLETED)
        updateSearchField("status", [OrderState.COMPLETED, OrderState.CANCELLED])
      },
      active: viewParam === OrderStateView.COMPLETED
    }]}, [intl, params, customerContext, isCustomer])

  useEffect(() => {
    userService.getMe(customerContext?.id)
      .then((me) => {
        setUser(me)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_user"}))
      })
    if (customerContext?.id) {
      updateSearchField("customerId", customerContext?.id)
      updateSearchField("endDate", isCustomer ? dateUtils.formatDateYYYYMMDD(dateUtils.datePlusDays(new Date(), (customerContext.numberWeeksViewed * 7))) : null)
    }
  }, [customerContext])

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: title})}></PageTitle>
          <HeaderButtonGroup buttonList={stateFilterButtons} />
          <div className="d-flex flex-row">
            {(authUtils.getProfile() === PROFILE.INTERN || authUtils.getProfile() === PROFILE.ADMIN) && (
              <>
                <OrderListActionsDropdown className="me-2" />
                <Button className="btn-header" onClick={() => setFormOpen(true)}>
                  {intl.formatMessage({id: "create_order"})}
                </Button>
              </>
            )}
          </div>
        </div>

        {authUtils.getProfile() === PROFILE.CLIENT ? user?.id && (
          <CustomerOrderList
            searchObject={searchObject}
            updateSearchField={updateSearchField}
            resourceDisplayOption={user?.resourceDisplayOption}
            onReset={() => onReset({status: searchObject.status, customerId: customerContext?.id})} />
        ) : (
          <OrderList
            itemPrefixPath={itemPrefixPath}
            searchObject={searchObject}
            updateSearchField={updateSearchField}
            onReset={() => onReset()} />
        )}

        {formOpen && (
          <Panel formId="createOrderForm" title="create_order" open={formOpen} loading={loading} onCancel={() => {
            setFormOpen(false)
            stopLoading()
          }}>
            <CreateOrderForm
              id="createOrderForm"
              startLoading={startLoading}
              stopLoading={stopLoading} />
          </Panel>
        )}
      </div>
    </Layout>
  )
}

export default OrderListView
