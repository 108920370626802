import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {ACTIVITY_SELLING_PRICE_CUSTOMER_INITIAL_VALUES} from "../../../constants/ActivityConstants";
import {CreateActivitySellingPriceCustomerSchema} from "../../../constants/validation/ActivityValidationSchemas";
import FormInput from "../input/FormInput";
import {ActivitySellingPriceCatalog} from "../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../services/ActivityService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import FormRadio from "../input/FormRadio";
import {dateUtils} from "../../../utils/dateUtils";
import PriceInput from "../input/PriceInput";
import EpowForm from "./EpowForm";

interface CreateActivitySellingPriceCustomerFormProps {
  id?: string,
  className?: string,
  activityCustomerId?: string,
  initialActivitySellingPrice?: ActivitySellingPriceCatalog
  onValidate?: () => void
  activityDescription?: string;
}

const CreateActivitySellingPriceCustomerForm: FunctionComponent<CreateActivitySellingPriceCustomerFormProps> = ({
  id,
  className = "",
  activityCustomerId,
  initialActivitySellingPrice,
  onValidate,
  activityDescription
}) => {
  const intl = useIntl()
  const activitySellingPrice = initialActivitySellingPrice ? {
    ...initialActivitySellingPrice,
    startDateValidity: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(initialActivitySellingPrice?.startDateValidity))
  } : null

  const handleSubmitActivitySellingPriceCustomer = (activitySellingPriceCustomer: ActivitySellingPriceCatalog) => {
    if (initialActivitySellingPrice?.id) {
      activityService.updateActivitySellingPriceCatalog(
        initialActivitySellingPrice.id,
        {...activitySellingPriceCustomer, activityCustomerId: activityCustomerId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_activity_selling_price"}))
        onValidate && onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_activity_selling_price"}))
      })
    } else {
      activityService.createActivitySellingPriceCatalog({...activitySellingPriceCustomer, activityCustomerId: activityCustomerId})
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_activity_selling_price"}))
          onValidate && onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_activity_selling_price"}))
        })
    }
  }

  return (
    <Formik
      initialValues={activitySellingPrice || {...ACTIVITY_SELLING_PRICE_CUSTOMER_INITIAL_VALUES, description: activityDescription}}
      validationSchema={CreateActivitySellingPriceCustomerSchema}
      onSubmit={handleSubmitActivitySellingPriceCustomer}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="startDateValidity"
            label="activity_start_date_validity"
            type="date"
            required
            value={values?.startDateValidity}
            onChange={(e) => setFieldValue("startDateValidity", e.target?.value)}
            error={errors?.startDateValidity}
            touched={touched?.startDateValidity}
          />
          <FormRadio
            id="unitType"
            label="service_unit_type"
            value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
            options={UNIT_TYPE_OPTIONS}
            onChange={(e) => setFieldValue("unitType", e.value)}
            required
            error={errors.unitType}
            touched={touched.unitType}
          />
          <PriceInput
            id="sellingPrice"
            label="activity_price"
            value={values?.sellingPrice}
            onChange={(e) => setFieldValue("sellingPrice", e.target.value)}
            required
            error={errors?.sellingPrice}
            touched={touched?.sellingPrice}
          />
          <FormInput
            id="description"
            label="activity_description"
            type="textarea"
            rows="10"
            value={values?.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            error={errors.description}
            touched={touched.description}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivitySellingPriceCustomerForm
