import { API_RULE_PATH } from "../constants/routes/RoutePaths"
import {PageResponse} from "../interfaces/api/PaginationInterface";
import { FileData } from "../interfaces/ResourceInterfaces";
import { Rule } from "../interfaces/RuleInterface";
import { fileUtils } from "../utils/fileUtils";
import { httpService } from "./HttpService"

const getRules = (): Promise<PageResponse<Rule>> => {
  return httpService.get<PageResponse<Rule>>(API_RULE_PATH);
}

const createRule = (rule: Rule): Promise<Rule> => {
  return httpService.post<Rule>(API_RULE_PATH, rule);
}

const updateRule = (id: string, rule: Rule): Promise<Rule> => {
  return httpService.put<Rule>(`${API_RULE_PATH}/${id}`, rule);
}

const uploadFile = (ruleId: string, file: File): Promise<FileData> => {
  const formData = fileUtils.createFormDataWithFile(file);
  return httpService.postFormData(`${API_RULE_PATH}/${ruleId}/file`, formData);
}

const getFile = (ruleId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_RULE_PATH}/${ruleId}/file`);
}

const toggleRuleActivation = (id: string, isActivated: boolean): Promise<boolean> => {
  return httpService.put<boolean>(`${API_RULE_PATH}/${id}/toggle-activation`, isActivated);
}

export const ruleService = {
  getRules,
  createRule,
  updateRule,
  uploadFile,
  getFile,
  toggleRuleActivation
}