import React, {FunctionComponent, ReactNode} from "react";
import {EditorState, useEditorState} from "react-simple-wysiwyg";

interface ButtonFactoryProps {
  title: string,
  content: ReactNode,
  command: ((state: EditorState) => void) | string,
}

const CustomEditorButton: FunctionComponent<ButtonFactoryProps> = ({title, content, command, ...props}) => {
  const editorState = useEditorState();
  const {$el, $selection} = editorState;

  let active = false;
  if (typeof command === "string") {
    active = !!$selection && document.queryCommandState(command);
  }

  function onAction(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (document.activeElement !== $el) {
      $el.focus();
    }

    if (typeof command === "function") {
      command(editorState);
    } else {
      document.execCommand(command);
    }
  }

  if (editorState.htmlMode) {
    return null;
  }

  return (
    <button
      type="button"
      title={title}
      {...props}
      className="rsw-btn"
      onMouseDown={onAction}
      data-active={active}
    >
      {content}
    </button>
  );
}

export default CustomEditorButton;
