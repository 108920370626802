import React, {FunctionComponent} from "react";
import {Routes, Route} from "react-router-dom"
import {AppRoutes} from "./constants/routes/AppRoutes";
import {AppRouteInterface} from "./interfaces/routes/RouteInterfaces";
import "./styles/app.scss"
import GuardedRoute from "./components/routes/GuardedRoute";
import {ToastContainer} from "react-toastify";
import {ActiveCustomerProvider} from "./context/ActiveCustomerContext";

const App: FunctionComponent = () => {
  return (
    <div className="global">
      <div className="global-container d-flex align-items-start justify-content-center">
        <ToastContainer
          position="top-right"
          pauseOnHover
          pauseOnFocusLoss
          autoClose={5000}
          closeOnClick
        />
        <ActiveCustomerProvider>
          <Routes>
            {
              AppRoutes.map((route: AppRouteInterface) =>
                route.paths.map((path: string) => {
                  return (
                    <Route key={path} element={<GuardedRoute guards={route.guards} />}>
                      <Route path={path} element={route.element} />
                    </Route>
                  )
                })
              )
            }
          </Routes>
        </ActiveCustomerProvider>
      </div>
    </div>
  )
}

export default App
