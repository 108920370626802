import { SupplierGeneralInfo } from "../interfaces/SupplierInterfaces";

export const SUPPLIER_INITIAL_VALUES: SupplierGeneralInfo = {
  supplierId: "",
  name: "",
  mainLabel: "",
  additionalInformation: "",
  zipCode: "",
  city: "",
  country: "",
  entityEmail: "",
  entityPhone: "",
};
