import { Purchase, PurchaseBooking, PurchaseBookingRequest } from "../../../../interfaces/PurchaseInterfaces";
import { FunctionComponent, useEffect, useState } from "react";
import { bookingService } from "../../../../services/BookingService";
import { toastUtils } from "../../../../utils/toastUtils";
import { FormattedMessage, useIntl } from "react-intl";
import React, { Formik } from "formik";
import { PURCHASE_BOOKING_INITIAL_VALUES } from "../../../../constants/PurchaseConstants";
import { CreatePurchaseBookingSchema } from "../../../../constants/validation/PurchaseValidationSchemas";
import FormAutocompleteSelectPageable from "../../input/FormAutocompleteSelectPageable";
import { orderService } from "../../../../services/OrderService";
import { OrderOption, OrderState } from "../../../../interfaces/OrderInterfaces";
import { ColorType, SizeType } from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import FormSelect from "../../input/FormSelect";
import { Option } from "../../../../interfaces/inputs/OptionInterfaces";
import { orderItemService } from "../../../../services/OrderItemService";
import { PageResponse, PaginationQueryParams } from "../../../../interfaces/api/PaginationInterface";
import { OrderItemResponse } from "../../../../interfaces/OrderItemInterfaces";
import { serviceService } from "../../../../services/ServiceService";
import FormInput from "../../input/FormInput";
import { numberUtils } from "../../../../utils/numberUtils";
import EpowForm from "../EpowForm";
import { orderUtils } from "../../../../utils/orderUtils";
import { ApiError } from "../../../../interfaces/ErrorInterfaces";
import FormRadio from "components/molecules/input/FormRadio";
import { YES_NO_OPTIONS } from "constants/OptionConstants";
import { BookingStatus } from "interfaces/BookingInterfaces";

interface CreatePurchaseBookingFormProps {
  className?: string,
  id?: string,
  onCancel: () => void,
  onValidate: () => void,
  purchase?: Purchase,
  purchaseBooking?: PurchaseBooking
}

const CreatePurchaseBookingForm: FunctionComponent<CreatePurchaseBookingFormProps> = ({
  className = "",
  id,
  onCancel,
  onValidate = () => null,
  purchase,
  purchaseBooking
}) => {
  const intl = useIntl()
  const pageParams: PaginationQueryParams = { pageSize: 1000 }
  const [serviceOptions, setServiceOptions] = useState<Option<string>[]>([])
  const [orderItemOptions, setOrderItemOptions] = useState<Option<string>[]>([])

  const getOrderItems = (orderIds: string[]) => {
    const searchFilter = {
      isBookingManagement: true,
      orderIds
    }

    orderItemService.getOrderItemsPage(pageParams, searchFilter).then((orderItemPage: PageResponse<OrderItemResponse>) => {
      const options: Option<string>[] = orderItemPage.content.map(orderItem => (
        {
          label: orderItem.name,
          value: orderItem.id,
          orderItemPax: orderItem.pax
        }
      ))
      setOrderItemOptions(options)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({ id: "order_item_search_error_message" }));
    })
  }

  useEffect(() => {
    serviceService.getServicesPage(pageParams, { supplierId: purchase.supplier?.id }).then((servicePage) => {
      const options: Option<string>[] = servicePage.content.map(service => (
        {
          label: service.name,
          value: service.id,
          dataPrice: service.defaultPrice,
          serviceNumberPaxMax: service.numberPaxMax,
          serviceDefaultUnitType: service.defaultUnitType
        }
      ))
      setServiceOptions(options)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({ id: "service_search_error_message" }));
    })
    if (purchaseBooking) {
      if (purchaseBooking.orderItemId) {
        setOrderItemOptions([{ label: purchaseBooking.orderItemName, value: purchaseBooking.orderItemId }])
      }
      getOrderItems([purchaseBooking.orderId])
    }
  }, []);

  const onOrderChange = (value: string, setFieldValue: (field: string, value: string) => void) => {
    getOrderItems([value])
    setFieldValue("orderId", value);
  }

  const onServiceOrOrderItemChange = (serviceId: string, orderItemId: string, setFieldValue: (field: string, value: string | number) => void) => {
    setFieldValue("serviceId", serviceId);
    setFieldValue("orderItemId", orderItemId);
    if (serviceId && orderItemId) {
      serviceService.getBookingPriceForOrderItemAndSupplier(serviceId, orderItemId, purchase.supplier.id)
        .then(bookingPrice => {
          setFieldValue("price", bookingPrice.unitPrice);
          setFieldValue("quantity", bookingPrice.quantity);
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        })
    }
  }

  const onQuantityChange = (value: string, setFieldValue: (field: string, value: number) => void, price: number) => {
    const valueAsNumber = Number(value);
    setFieldValue("quantity", value && valueAsNumber)
    setFieldValue("totalExcludingVAT", price * valueAsNumber)
  }
  const onPriceChange = (value: string, setFieldValue: (field: string, value: number) => void, quantity: number) => {
    const valueAsNumber = Number(value);
    setFieldValue("price", value && Number(value))
    setFieldValue("totalExcludingVAT", quantity * valueAsNumber)
  }

  const handleSubmitBooking = (purchaseBookingRequest: PurchaseBookingRequest) => {
    if (purchaseBooking?.id) {
      bookingService.updateBooking(purchaseBooking.id, { ...purchaseBookingRequest, purchaseId: purchase?.id })
        .then(() => {
          onValidate()
          toastUtils.successToast(intl.formatMessage({ id: "success_toast_update_booking" }))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({ id: "error_toast_update_booking" }))
        })
    } else {
      bookingService.createBooking({ ...purchaseBookingRequest, purchaseId: purchase?.id })
        .then(() => {
          onValidate()
          toastUtils.successToast(intl.formatMessage({ id: "success_toast_create_booking" }))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({ id: "error_toast_create_booking" }))
        })
    }
  }

  return (
    <Formik initialValues={purchaseBooking || PURCHASE_BOOKING_INITIAL_VALUES} validationSchema={CreatePurchaseBookingSchema} onSubmit={handleSubmitBooking}>
      {({ errors, touched, values, setFieldValue }) => {
        return (
          <EpowForm id={id} className={className}>
            <FormAutocompleteSelectPageable
              id="purchase_booking_order"
              label={intl.formatMessage({ id: "order_title" })}
              onChange={(value) => onOrderChange(value, setFieldValue)}
              value={values?.orderId}
              fetchData={(page, filter) => orderService.getOrdersOptionPage(page, {
                ...filter,
                orderId: values?.orderId,
                status: OrderState.PENDING,
                isBooking: true
              })}
              manageOptions={(response: OrderOption[]) => response.map(order => ({ value: order.id, label: `${order.refCustomer || order.orderNumber} - ${order.customerName}` }))}
              optionFromValue={(response: OrderOption[], value: string) => {
                const orderOption = response?.find((order) => order.id === value);
                return orderOption ? {
                  label: orderUtils.getOrderRefDisplay(orderOption.orderNumber, orderOption.refCustomer, orderOption.customerName),
                  value: orderOption?.id
                } : null;
              }}
              filterFieldName="refCustomer"
              filterFieldSearch="customerOrCode"
              error={errors.orderId}
              touched={touched.orderId}
            />

            <FormSelect
              id="orderItemId"
              label="purchase_booking_order_item"
              value={orderItemOptions.find((option) => option?.value === values?.orderItemId)}
              onChange={(option?: Option<string>) => onServiceOrOrderItemChange(values?.serviceId, option.value, setFieldValue)}
              options={orderItemOptions}
              required
              isSearchable
              isClearable
              error={errors?.orderItemId}
              touched={touched?.orderItemId}
            />

            <FormSelect
              id="serviceId"
              label="booking_service"
              value={serviceOptions.find((option) => option?.value === values?.serviceId)}
              onChange={(option: Option<string>) => onServiceOrOrderItemChange(option.value, values?.orderItemId, setFieldValue)}
              options={serviceOptions}
              required
              isSearchable
              isClearable
              error={errors?.serviceId}
              touched={touched?.serviceId}
            />

            <FormInput
              id="quantity"
              label="purchase_booking_quantity"
              type="number"
              value={values?.quantity}
              onChange={(e) => onQuantityChange(e.target?.value, setFieldValue, values.price)}
              required
              error={errors.quantity}
              touched={touched.quantity}
            />

            <FormInput
              id="price"
              label="purchase_booking_price_net"
              type="number"
              value={values?.price}
              step="0.01"
              onChange={(e) => onPriceChange(e.target?.value, setFieldValue, values.quantity)}
              onBlur={(e) => setFieldValue("price", numberUtils.numberFormatField(e.target.value))}
              error={errors.price}
              touched={touched.price}
              required
            />

            <FormInput
              id="totalExcludingVAT"
              label="purchase_booking_total_HT"
              type="number"
              value={(values?.quantity * values?.price).toFixed(2)}
              disabled
            />
            <div>
              {purchaseBooking?.id && purchaseBooking.status === BookingStatus.TO_UPDATE &&
                <FormRadio
                  id="isSendUpdateEmail"
                  label="booking_form_send_update_email_field"
                  value={YES_NO_OPTIONS.find(
                    (option) => option?.value === String(values?.sendUpdateEmail)
                  )}
                  options={YES_NO_OPTIONS}
                  onChange={(option?: Option<string>) =>
                    setFieldValue("sendUpdateEmail", option?.value === "true")
                  }
                />
              }
              {values?.sendUpdateEmail  &&
                <FormInput
                  className="p-2"
                  id="updateEmailContent"
                  label="booking_form_update_email_content_field"
                  type="textarea"
                  value={values?.updateEmailContent}
                  onChange={(e) => setFieldValue("updateEmailContent", e.target.value)}
                />
              }
              {purchaseBooking?.status === BookingStatus.TO_UPDATE &&
                <div>
                  <input className="me-1 mb-3" type="checkbox" onClick={() => setFieldValue("confirmBookingUpdate", !values?.confirmBookingUpdate)} />
                  <FormattedMessage id="booking_validation_status_change" />
                </div>
              }
            </div>
            <div>
              <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY}
                className="extended me-2">
                <FormattedMessage id={"cancel_button"} />
              </Button>
              <Button form={id} type="submit" size={SizeType.MD} className="extended">
                <FormattedMessage id={"validate_button"} />
              </Button>
            </div>
          </EpowForm>
        );
      }}
    </Formik>
  )
}

export default CreatePurchaseBookingForm
