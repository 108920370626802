import {API_SUPPLIER_PURCHASES_PATH} from "../constants/routes/RoutePaths"
import {SupplierPurchaseDto} from "../interfaces/PurchaseInterfaces"
import {SearchFilter} from "../interfaces/api/FiltersInterface"
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface"
import {queryUtils} from "../utils/queryUtils"
import {httpService} from "./HttpService"

function getPurchases(params: PaginationQueryParams, filterParams: SearchFilter): Promise<PageResponse<SupplierPurchaseDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_SUPPLIER_PURCHASES_PATH}${queryParams}`)
}

export const supplierPurchaseService = Object.freeze({
  getPurchases,
})
