import React, {Formik, validateYupSchema} from "formik";
import {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ValidationError} from "yup";
import {ORDER_ITEM_INITIAL_VALUES} from "../../../../constants/OrderItemConstants";
import {
  CreateOrderItemSchema,
  OrderItemCreationFirstStepSchema
} from "../../../../constants/validation/OrderItemValidationSchema";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {Order} from "../../../../interfaces/OrderInterfaces";
import {
  ORDER_ITEM_TYPE,
  OrderItem,
  OrderItemRequest,
  OrderItemResourceDTO
} from "../../../../interfaces/OrderItemInterfaces";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import CreateOrderItemFormStepTwo from "./CreateOrderItemFormStepTwo";
import EpowForm from "../EpowForm";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import FormRadio from "../../input/FormRadio";
import {ORDER_ITEM_TYPE_OPTIONS} from "../../../../constants/OptionConstants";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import CreateOrderItemActivityFormStepOne from "./activity/CreateOrderItemActivityFormStepOne";
import CreateOrderItemServiceFormStepOne from "./service/CreateOrderItemServiceFormStepOne";
import * as Yup from "yup";
import {AnyObject} from "yup/es/types";
import {activityResourceRequirementService} from "../../../../services/ActivityResourceRequirementService";
import { adminConfigurationService } from "services/AdminConfigurationService";

interface CreateOrderItemFormProps {
  className?: string,
  id?: string,
  onCancel: () => void,
  onValidate: () => void,
  order?: Order,
  orderItem?: OrderItem,
  isEditable?: boolean,
}

const CreateOrderItemForm: FunctionComponent<CreateOrderItemFormProps> = ({
  className = "",
  id,
  onCancel,
  onValidate,
  order,
  orderItem,
  isEditable,
}) => {
  const intl = useIntl()

  const [step, setStep] = useState<number>(0);
  const [selectedActivity, setSelectedActivity] = useState<Activity>(null);
  const [selectedService, setSelectedService] = useState<Service>(null);
  const [departureTimeMinuteDelta, setDepartureTimeMinuteDelta] = useState<number>(0);
  const [orderItemResourcesBase, setOrderItemResourcesBase] = useState<OrderItemResourceDTO[]>([])
  const [formValidationSchema, setFormValidationSchema] = useState<Yup.ObjectSchema<AnyObject>>(undefined)

  const handleSubmitOrderItem = (newOrderItem: OrderItemRequest) => {
    if (orderItem?.id) {
      orderItemService.updateOrderItem(orderItem?.id, newOrderItem)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "order_item_update_success"}))
          onValidate()
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        })
    } else {
      orderItemService.createOrderItem(newOrderItem)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "order_item_create_success"}))
          onValidate()
        })
        .catch((error: ApiError) => {
          toastUtils.errorToast(error.message)
        })
    }
  }

  const getInitialValues = (): OrderItemRequest => {
    if (orderItem?.id) {
      return {
        id: orderItem?.id,
        order: order?.id,
        type: orderItem?.type?.toString(),
        activity: orderItem?.activityId,
        service: orderItem?.serviceId,
        name: orderItem?.name,
        isBookingManagement: orderItem?.bookingManagement,
        optional: orderItem?.optional,
        isStaffingManagement: orderItem?.staffingManagement,
        description: orderItem?.description,
        place: orderItem?.rendezVousPlace,
        date: orderItem?.rendezVousDate,
        hour: orderItem?.rendezVousHour,
        departureHour: orderItem?.departureHour,
        pax: orderItem?.pax,
        isPaxConfirmed: orderItem?.paxConfirmed,
        orderItemResources: orderItem?.orderItemResources,
        unitType: orderItem?.unitType?.toString(),
        quantity: orderItem?.quantity,
        paxPrice: orderItem?.paxPrice,
        sellPrice: orderItem?.sellPrice,
        sellPriceTVA: orderItem?.sellPriceTVA,
        billingService: orderItem?.billingServiceId,
        disbursements: orderItem?.disbursements,
        showActivityTourLeader: orderItem?.showActivityTourLeader
      }
    }

    return {
      ...ORDER_ITEM_INITIAL_VALUES,
      order: order?.id,
      isBookingManagement: order?.customer?.isReservationManagement,
      billingService: order?.customer?.defaultBillingServiceAsCustomer?.id,
      disbursements: order?.customer?.disbursement,
      pax: order.defaultPax,
      quantity: order.defaultPax,
      isPaxConfirmed: order.paxConfirmed
    }
  }

  const validateFirstStep = (values: OrderItemRequest, setErrors: (errors: unknown) => void, setTouched: (touched: unknown) => void) => {
    try {
      void validateYupSchema(values, OrderItemCreationFirstStepSchema, true)
      setStep(1)
    } catch (error) {
      if (error instanceof ValidationError) {
        let errorObject = {}
        let touchedObject = {}
        error.inner.forEach(e => {
          errorObject = {...errorObject, [e.path]: e.message}
          touchedObject = {...touchedObject, [e.path]: true}
        })

        setErrors(errorObject)
        setTouched(touchedObject)
      }
    }
  }

  const onChangeType = (type: string, setFieldValue: FormikFieldSetter) => {
    setFieldValue("isBookingManagement", type === ORDER_ITEM_TYPE.ACTIVITY ? order?.customer?.isReservationManagement : true)
    setFieldValue("type", type)
  }

  const updateValidationSchema = (orderItemResources : OrderItemResourceDTO[]) => {
    setOrderItemResourcesBase(orderItemResources)
    setFormValidationSchema(CreateOrderItemSchema(orderItemResources))
  }

  const formProps = {
    order,
    selectedActivity,
    setSelectedActivity,
    selectedService,
    setSelectedService
  }

  useEffect(() => {
    if (orderItem?.activityId) {
      activityResourceRequirementService.getRequirementsForActivity(orderItem?.activityId)
        .then((requirements) => {

          // Ajouté pour corriger BUILD-14 (mettre à jour uniquement que les ressources déjà existantes)
          const filteredRequirements = requirements.filter(requirement => {
            return orderItem.orderItemResources.some(orderItemResource => orderItemResource.resourceType.id === requirement.resourceType.id);
          });

          setOrderItemResourcesBase(filteredRequirements.map((requirement) => {
            return { quantity: requirement.quantity, resourceType: requirement.resourceType }
          }))
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_activity_resource_requirements"}));
        });
    }
    adminConfigurationService.getAdminConfiguration()
      .then((config) => {
        setDepartureTimeMinuteDelta(config.departureTimeMinuteDelta);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_admin_config"}));
      })
  }, [])

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={formValidationSchema}
      onSubmit={value => {handleSubmitOrderItem(value)}}
    >
      {({errors, touched, values, setFieldValue, setValues, setErrors, setTouched}) => (
        <EpowForm id={id} className={className}>
          {step === 0 && (
            <>
              <FormRadio
                id="type"
                label="order_item_type"
                value={ORDER_ITEM_TYPE_OPTIONS.find((option) => option?.value === values?.type?.toString())}
                options={ORDER_ITEM_TYPE_OPTIONS}
                onChange={(e) => onChangeType(e.value, setFieldValue)}
                required
                error={errors.type}
                touched={touched.type}
              />

              {values.type === ORDER_ITEM_TYPE.ACTIVITY ? (
                <CreateOrderItemActivityFormStepOne
                  errors={errors}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  order={order}
                  setValues={setValues}
                  updateValidationSchema={updateValidationSchema}
                  departureTimeMinuteDelta={departureTimeMinuteDelta}
                  isEditable={isEditable}
                  {...formProps}
                />
              ) : (
                <CreateOrderItemServiceFormStepOne
                  errors={errors}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  order={order}
                  setValues={setValues}
                  departureTimeMinuteDelta={departureTimeMinuteDelta}
                  isEditable={isEditable}
                  {...formProps}
                />
              )}

              <div className="epow-lateral-panel-footer d-flex flex-row justify-content-end">
                <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY} className="extended me-2">
                  <FormattedMessage id={"cancel_button"} />
                </Button>
                <Button size={SizeType.MD} onClick={() => validateFirstStep(values, setErrors, setTouched)} className="extended">
                  <FormattedMessage id={"next_button"} />
                </Button>
              </div>
            </>
          )}

          {step === 1 && (
            <>
              <CreateOrderItemFormStepTwo
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                values={values}
                order={order}
                selectedActivity={selectedActivity}
                selectedService={selectedService}
                initialOrderItemResources={orderItemResourcesBase}
              />

              <div className="d-flex justify-content-between">
                <div>
                  <Button size={SizeType.MD} onClick={() => setStep(0)} color={ColorType.SECONDARY} className="extended">
                    <FormattedMessage id={"previous_button"} />
                  </Button>
                </div>
                <div>
                  <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY} className="extended me-2">
                    <FormattedMessage id={"cancel_button"} />
                  </Button>
                  <Button form={id} type="submit" size={SizeType.MD} className="extended">
                    <FormattedMessage id={"validate_button"} />
                  </Button>
                </div>
              </div>
            </>
          )}
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateOrderItemForm;
