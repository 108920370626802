import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GenericModal from "../GenericModal";

interface ModalOrderItemDeleteProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  deleteOrderItem: () => void,
}

const ModalOrderItemDelete: FunctionComponent<ModalOrderItemDeleteProps> = ({
  open,
  setOpen,
  deleteOrderItem,
}) => {
  const intl = useIntl()
  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_body_delete_order_item"})}
      open={open}
      closable
      onOpen={setOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: () => {
            deleteOrderItem()
          },
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            setOpen(false)
          }
        }
      }}
    >
      <div className="d-flex flex-column">
        {intl.formatMessage({id: "modal_body_delete_order_item"})}
      </div>
    </GenericModal>
  )
}

export default ModalOrderItemDelete;
