import {Tour, TourActivity, TourActivityCustomer} from "../interfaces/TourInterfaces";
import {DayByDayClientRequest} from "../interfaces/DayByDayClientInterfaces";

export const TOUR_INITIAL_VALUES : Tour = {
    tourName: "",
    tourCode: "",
    numberPax: 0
}

export const TOUR_ACTIVITY_INITIAL_VALUES : TourActivity = {
    activityId: "",
    dayAfterArrival: 0,
    hour: "",
    departureHour: "",
    optional: "false",
    meetingPlace: "",
    description: "",
}

export const TOUR_CUSTOMER_INITIAL_VALUES : DayByDayClientRequest = {
    customerId: "",
    tourId: ""
}

export const TOUR_ACTIVITY_CUSTOMER_INITIAL_VALUES : TourActivityCustomer = {
    billingClientId: "",
    clientBillingActivityName: "",
    dayAfterArrival: 0,
    hour: "",
    departureHour: "",
    optional: "",
    meetingPlace: "",
    description: ""
}
