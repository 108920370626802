import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {OrderList} from "interfaces/OrderInterfaces";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {useIntl} from "react-intl";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";
import * as Yup from "yup";
import {orderService} from "../../../services/OrderService";
import {toastUtils} from "../../../utils/toastUtils";

interface UpdateTourLeaderOrderFormProps {
  id?: string,
  className?: string,
  order?: OrderList,
  afterSubmit?: () => void,
  onBeforeSubmit?: () => void
  onValidate?: (order: OrderList) => void
}

const UpdateTourLeaderOrderForm: FunctionComponent<UpdateTourLeaderOrderFormProps> = ({
  id,
  className = "",
  order,
  afterSubmit = () => null,
  onBeforeSubmit = () => null,
  onValidate = () => null
}) => {

  const intl = useIntl()

  const handleSubmitOrder = ({ tourLeaderId } ) => {
    onBeforeSubmit()
    orderService.updateOrderTourLeader(order.id, tourLeaderId).then((order) => {
      onValidate({
        ...order,
        tourLeaderId: order.tourLeaderId,
        tourLeaderIdentity: order.tourLeader,
        customer: order.customer,
      })
      afterSubmit()
      toastUtils.successToast(intl.formatMessage({id: "order_update_tour_leader_success"}))
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "order_update_tour_leader_error"}))
    })
  }

  return (
    <Formik initialValues={{
      tourLeaderId: order?.tourLeaderId,
    }}
            validationSchema={
      Yup.object().shape({
        tourLeaderId: Yup.string().required()
      })} onSubmit={value => handleSubmitOrder(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormAutocompleteSelectPageable
            id="tourLeader"
            label={intl.formatMessage({id: "order_tour_leader"})}
            placeholder={intl.formatMessage({id: "tour_leader_search_placeholder"})}
            onChange={(value) => setFieldValue("tourLeaderId", value)}
            value={values?.tourLeaderId}
            fetchData={(page, filter) => tourLeaderService.getTourLeaderPage(page, {
              ...filter,
              customerId: order?.customer.id,
              currentState: WorkflowStatesEnum.ACTIVE
            })}
            filterFieldName="fullName"
            filterFieldSearch="nameSurname"
            error={errors?.tourLeaderId}
            touched={touched?.tourLeaderId}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default UpdateTourLeaderOrderForm
