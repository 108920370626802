import React, {FunctionComponent, useMemo} from "react";
import Layout from "../components/layout/Layout";
import {Resource} from "interfaces/ResourceInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import {RESOURCES_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsType, WorkflowStateMap, WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import TabList from "components/molecules/tab/TabList";
import {resourceTab} from "constants/tabs/TabListConstants";
import PageTitle from "../components/molecules/header/PageTitle";
import {BankAccount} from "../interfaces/BankAccountInterface";
import {TabPaneItem} from "../interfaces/TabInterfaces";
import {profileUtils} from "../utils/profileUtils";
import ResourceAvailabilityContainer from "../components/molecules/resourceAvailability/ResourceAvailabilityContainer";


interface ResourceViewTemplateProps {
  onChangeResource: (resource: Resource) => void,
  fetchBankAccount: () => Promise<BankAccount[]>,
  resource?: Resource,
  self?: boolean,
  workflowActions?: Partial<Record<WorkflowActionsType, () => void>>

}

const ResourceViewTemplate: FunctionComponent<ResourceViewTemplateProps> = ({
  resource,
  onChangeResource,
  workflowActions,
  fetchBankAccount,
  self = false
}) => {
  const intl = useIntl()
  const tabs: TabPaneItem[] = useMemo(() => {
    const tabsToUse = resourceTab(resource, onChangeResource, fetchBankAccount, self, intl);
    if (profileUtils.isAdmin() || profileUtils.isInterne()) {
      tabsToUse.push({
        label: "menu_resource_availability",
        component: (
          <ResourceAvailabilityContainer resource={resource} />
        )
      })
    }
    return tabsToUse
  }, [intl, resource, onChangeResource, fetchBankAccount, self]);

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-none d-md-flex align-items-center justify-content-between mb-3">
          <div className="d-flex align-items-center">
            <PageTitle
              pageTitle={`${resource?.identity?.firstName} ${resource?.identity?.lastName}`}
              className="me-4"
              breadcrumbItems={self ? [] : [{
                title: intl.formatMessage({id: "resource_title"}),
                to: RESOURCES_PATH
              }]}
            />
            {!self && (
              <>
                <span className="me-1 mb-1 text-muted"><FormattedMessage id="status" /> :</span>
                <Badge pill color={WorkflowStateMap[resource.currentState]?.color} className="ms-2">
                  {intl.formatMessage({id: WorkflowStateMap[resource.currentState]?.label || "STATUS_DEFAULT"})}
                </Badge>
              </>
            )}
          </div>
          <WorkflowButtonList
            currentState={resource?.currentState}
            entityLabel={intl.formatMessage({id: "workflow_entity_resource"})}
            actions={workflowActions}
          />
        </div>
        <div className="d-md-none d-flex flex-column justify-content-between mb-3">
          <div>
            <PageTitle
              pageTitle={`${resource?.identity?.firstName} ${resource?.identity?.lastName}`}
              className="mb-1"
              breadcrumbItems={self ? [] : [{
                title: intl.formatMessage({id: "resource_title"}),
                to: RESOURCES_PATH
              }]}
            />
            {!self && (
              <>
                <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
                <Badge pill color={WorkflowStateMap[resource.currentState]?.color} className="ms-2">
                  {intl.formatMessage({id: WorkflowStateMap[resource.currentState]?.label || "STATUS_DEFAULT"})}
                </Badge>
              </>
            )}
          </div>
          <div className="ms-auto">
            <WorkflowButtonList
              currentState={resource?.currentState}
              entityLabel={intl.formatMessage({id: "workflow_entity_resource"})}
              actions={workflowActions}
            />
          </div>

        </div>
        <div>
          {(self && resource.currentState === WorkflowStatesEnum.CREATED) && (
            <span className="me-1 text-muted" style={{ whiteSpace: "pre-line" }}><FormattedMessage id="resource_validate_profile_help_label" values={{
              tab1: intl.formatMessage({id: "details_tab"}),
              tab2: intl.formatMessage({id: "professional_info_tab"}),
              tab3: intl.formatMessage({id: "legal_info_tab"}),
              tab4: intl.formatMessage({id: "bank_accounts_tab"}),
              tab5: intl.formatMessage({id: "activities_tab"}),
            }} /></span>
          )}
        </div>

        <TabList
          items={tabs}
        />
      </div>
    </Layout>
  )
}

export default ResourceViewTemplate
