import {SupplierService, SupplierServiceCatalog} from "../interfaces/SupplierInterfaces";
import {httpService} from "./HttpService";
import {API_CATALOG_SUPPLIER_SERVICE_PATH, API_SUPPLIER_SERVICE_PATH} from "../constants/routes/RoutePaths";

function getSuppliersByService(id: string): Promise<SupplierService[]> {
  return httpService.get<SupplierService[]>(`${API_SUPPLIER_SERVICE_PATH}/${id}`);
}

function createSupplierServiceCatalog(supplierServiceCatalog: SupplierServiceCatalog): Promise<SupplierServiceCatalog> {
  return httpService.post<SupplierServiceCatalog>(API_CATALOG_SUPPLIER_SERVICE_PATH, supplierServiceCatalog)
}

function updateSupplierServiceCatalog (id: string, supplierServiceCatalog: SupplierServiceCatalog): Promise<SupplierServiceCatalog> {
  return httpService.put<SupplierServiceCatalog>(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/${id}`, supplierServiceCatalog)
}

function deleteSupplierServiceCatalog(supplierServiceCatalogId: string): Promise<Response> {
  return httpService.delete(`${API_CATALOG_SUPPLIER_SERVICE_PATH}/${supplierServiceCatalogId}`)
}

export const supplierServiceService = {
  getSuppliersByService,
  createSupplierServiceCatalog,
  updateSupplierServiceCatalog,
  deleteSupplierServiceCatalog
}
