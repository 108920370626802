import React, {FunctionComponent} from "react";
import FormInput from "../../../input/FormInput";
import {dateUtils} from "../../../../../utils/dateUtils";
import {FormikErrors, FormikTouched} from "formik";
import {FormikFieldSetter} from "../../../../../interfaces/FormikInterfaces";
import {OrderItemRequest} from "../../../../../interfaces/OrderItemInterfaces";
import {Col, Row} from "reactstrap";

interface CreateOrderItemRendezVousFormProps {
  className?: string,
  errors: FormikErrors<OrderItemRequest>,
  touched: FormikTouched<OrderItemRequest>,
  values: OrderItemRequest,
  setFieldValue: FormikFieldSetter,
  departureTimeMinuteDelta: number,
}

const CreateOrderItemRendezVousForm: FunctionComponent<CreateOrderItemRendezVousFormProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  departureTimeMinuteDelta,
}) => {


  const onHourChange = (e: React.ChangeEvent<HTMLInputElement>, values: OrderItemRequest, setFieldValue: (field: string, value: string | number) => void) => {
    if (values?.departureHour === null || values?.departureHour === undefined) {
      setFieldValue("departureHour", dateUtils.convertSecondsToTime(dateUtils.convertTimeToSeconds(e.target.value) + (departureTimeMinuteDelta * 60)))
    }
  }

  return (
    <div className={className}>
      <FormInput
        id="place"
        label="order_item_place"
        value={values?.place ?? ""}
        onChange={(e) => setFieldValue("place", e.target?.value)}
        required
        error={errors.place}
        touched={touched.place}
      />

      <FormInput
        id="date"
        label="order_item_date"
        type="date"
        value={values?.date}
        onChange={(e) => setFieldValue("date", e.target.value)}
        required
        error={errors.date}
        touched={touched.date}
      />

      <Row>
        <Col xs={24} md={12}>
          <FormInput
            id="hour"
            label="order_item_hour"
            type="time"
            step="60"
            value={dateUtils.getTimeWithoutSeconds(values?.hour)}
            onChange={(e) => setFieldValue("hour", e.target.value)}
            onBlur={(e) => onHourChange(e, values,  setFieldValue)}
            required
            error={errors.hour}
            touched={touched.hour}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormInput
            id="departureHour"
            label="order_item_departure_hour"
            type="time"
            step="60"
            value={dateUtils.getTimeWithoutSeconds(values?.departureHour)}
            onChange={(e) => setFieldValue("departureHour", e.target.value)}
            error={errors.departureHour}
            touched={touched.departureHour}
          />
        </Col>
      </Row>
    </div>
  )
}

export default CreateOrderItemRendezVousForm;
