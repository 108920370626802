import React, {FunctionComponent, useEffect, useState} from "react";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import Select from "../../atoms/input/Select";
import {Label} from "reactstrap";

interface SelectFilterProps {
  className?: string;
  title?: string;
  placeholder?: string;
  options: Option<string>[];
  onChange: (value: string) => void;
  value?: string;
  isClearable?: boolean,
}

const SelectFilter: FunctionComponent<SelectFilterProps> = ({
  className = "",
  title,
  placeholder,
  options,
  onChange,
  value,
  isClearable = true,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option<string>>();

  useEffect(() => {
    const option = options?.find((option) => option?.value === value) ?? null
    setSelectedOption(option)
  }, [value])

  const handleOptionSelection = (selected: Option<string>) => {
    setSelectedOption(selected)
    onChange(selected !== null ? selected?.value : "")
  }

  return (
    <div className={`epow-filter ${className}`}>
      {title && <Label className="epow-label">{title}</Label>}

      <Select
        value={selectedOption}
        placeholder={placeholder ?? ""}
        onChange={handleOptionSelection}
        options={options}
        isClearable={isClearable}
      />
    </div>
  )
}

export default SelectFilter;
