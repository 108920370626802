import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import TourLeaderList from "components/molecules/list/TourLeaderList";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import useSearchFilter from "../hooks/useSearchFilter";
import Panel from "../components/molecules/panel/Panel";
import CreateTourLeaderForm from "../components/molecules/form/CreateTourLeaderForm";
import {TourLeaderSearchObject} from "../interfaces/TourLeaderInterfaces";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const TourLeaderListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const [tourLeaderCreationPanelOpen, setTourLeaderCreationPanelOpen] = useState<boolean>(false)
  const {searchObject, onReset, updateSearchField} = useSearchFilter<TourLeaderSearchObject>({
    customerId: "",
    email: "",
    nameSurname: "",
    currentState: WorkflowStatesEnum.ACTIVE
  });
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <Layout>
      <div className="d-flex flex-column epow-content-body">
        <CommonEntityHeader
          pageTitle={intl.formatMessage({id: "tour_leader_title"})}
          updateSearchField={updateSearchField}
          setCreationPanelOpen={setTourLeaderCreationPanelOpen}
          creationButtonLabel={intl.formatMessage({id: "create_new_tour_leader"})}
        />
        <TourLeaderList
          searchObject={searchObject}
          onReset={() => onReset({currentState: searchObject.currentState})}
          updateSearchField={updateSearchField}
        />

        {tourLeaderCreationPanelOpen && (
          <Panel
            formId="createTourLeaderForm"
            title="create_new_tour_leader"
            loading={loading}
            open={tourLeaderCreationPanelOpen}
            onCancel={() => setTourLeaderCreationPanelOpen(false)}
          >
            <CreateTourLeaderForm id="createTourLeaderForm" stopLoading={stopLoading} startLoading={startLoading}/>
          </Panel>
        )}

      </div>
    </Layout>
  )
}

export default TourLeaderListView
