import {OrderField} from "interfaces/OrderInterfaces";
import {dateUtils} from "../utils/dateUtils";


export const ORDER_INITIAL_VALUES: OrderField = {
  id:"",
  customerId: "",
  customerReference: "",
  dayZeroTour: "",
  date:  dateUtils.formatDateYYYYMMDD(),
  orderNumber: "",
  tourId: "",
  tourLeaderId: "",
  isValidated: true,
  orderComment: "",
}
