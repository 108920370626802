import React, {FunctionComponent} from "react";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import {IconName} from "../../icon/IconsMap";

interface NavItemProps {
  className?: string,
  label: string,
  icon?: IconName,
}

const NavItemContent: FunctionComponent<NavItemProps> = ({
  icon,
  label,
  className = "",
}) =>
    <div className="d-flex justify-content-start align-items-center">
          {icon && <Icon size={IconSizeType.SM} name={icon} className="me-2"/>}
          <span className={`epow-label ${className}`}><FormattedMessage id={label}/></span>
    </div>




export default NavItemContent;
