import {API_CONTACTS_PATH, API_SUPPLIER_CONTACTS_PATH, API_SUPPLIERS_PATH,} from "constants/routes/RoutePaths";
import {Contact, ContactOption, EntityContactRequest, SupplierContact,} from "interfaces/ContactInterfaces";
import {httpService} from "./HttpService";

function createSupplierContact(request: EntityContactRequest, entityId: string): Promise<SupplierContact> {
  const supplierContact : SupplierContact = {
    ...request,
    supplierId: entityId,
  }
  return httpService.post<SupplierContact>(API_SUPPLIER_CONTACTS_PATH, supplierContact);
}

function updateSupplierContact(id: string, entityId: string, request: EntityContactRequest): Promise<SupplierContact> {
  const supplierContact : SupplierContact = {
    ...request,
    supplierId: entityId,
  }
  return httpService.put<SupplierContact>(`${API_SUPPLIER_CONTACTS_PATH}/${id}`, supplierContact);
}

function getAllContactsBySupplierId(supplierId: string, filter?: {filter: string}): Promise<Contact[]> {
  return httpService.get<Contact[]>(`${API_SUPPLIERS_PATH}/${supplierId}${API_CONTACTS_PATH}`, filter);
}

function getAllContactOptionsBySupplierId(supplierId: string, filter?: {filter: string}): Promise<ContactOption[]> {
  return httpService.get<Contact[]>(`${API_SUPPLIERS_PATH}/${supplierId}${API_CONTACTS_PATH}/options`, filter);
}

function inviteContactSupplier(id: string): Promise<Contact> {
  return httpService.post<Contact>(`${API_SUPPLIER_CONTACTS_PATH}/${id}/invite`, {});
}

export const supplierContactService = {
  createSupplierContact,
  inviteContactSupplier,
  updateSupplierContact,
  getAllContactsBySupplierId,
  getAllContactOptionsBySupplierId
};
