import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import debounce from "lodash/debounce";
import {Label} from "reactstrap";
import TextInput from "../../atoms/input/TextInput";
import {IconName} from "../../icon/IconsMap";

interface TextFilterProps {
  className?: string,
  title?: string,
  icon?: IconName,
  placeholder?: string,
  onChange: (value: string) => void,
  value?: string,
}

const TextFilter: FunctionComponent<TextFilterProps> = ({
  className= "",
  title,
  icon,
  placeholder,
  onChange,
  value,
}) => {
  const [fieldValue, setFieldValue] = useState<string>("")

  useEffect(() => {
    setFieldValue(value)
  }, [value])

  /**
   * Debounce the filtering
   */
  const handleSearch = useCallback(
    debounce((value) => {
      onChange(value)
    }, 500),
    []
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = e.target.value
    setFieldValue(targetValue)
    handleSearch(targetValue)
  };

  return (
    <div className={`epow-filter ${className}`}>
      {title &&
        <div className="epow-label">
          <Label>{title}</Label>
        </div>
      }
      <TextInput icon={icon} onChange={handleChange} value={fieldValue} placeholder={placeholder ?? ""} />
    </div>
  )
}

export default TextFilter;
