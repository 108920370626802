import Messages from "../constants/messages/Messages";

const getNavigatorLanguage = (): string => {
  return navigator.language.split(/[-_]/)[0]
}

function getMessage(id: string): string {
  return Messages[getNavigatorLanguage()][id]
}

export const languageUtils = Object.freeze({
  getNavigatorLanguage,
  getMessage
})
