import {Option} from "../interfaces/inputs/OptionInterfaces";
import {IntlShape} from "react-intl";

function formatOptions (intl: IntlShape, options?: Option<string>[]): Option<string>[] {
  return (options || [])
    .map((option) => {
      const label = option.label.trim()
        ? intl.formatMessage({id: option.label })
        : option.label
      return {
        ...option,
        label,
        value: option.value
      }
    })
}

function translateOption (intl: IntlShape, options: Option<string>[], value?: string|boolean): string {
  if (value === undefined) {
    return undefined
  }
  if (typeof value === "boolean") {
    value = value.toString()
  }
  return options.filter(option => option.value === value).map(option => intl.formatMessage({id: option.label }))[0]
}

function getBoolean(value: string): boolean {
  return value?.toLowerCase() === "true"
}

export const optionUtils = Object.freeze({
  formatOptions,
  translateOption,
  getBoolean
})
