import React, {FunctionComponent} from "react";
import Button from "../../atoms/Button";
import {
  WorkflowActions,
  WorkflowActionsType,
  WorkflowStatesEnum
} from "../../../constants/workflow/WorkflowStates";
import {SizeType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {WorkflowActionsInterface} from "../../../interfaces/workflow/WorkflowInterfaces";

interface WorkflowButtonListProps {
  entityLabel: string;
  currentState: WorkflowStatesEnum;
  actions: WorkflowActionsInterface;
}

const WorkflowButtonList: FunctionComponent<WorkflowButtonListProps> = ({
  entityLabel,
  currentState,
  actions
}) => {
  const intl = useIntl();
  if (!currentState) return <div></div>;

  return (
    <div>
      {
        Object.keys(actions).map((key: WorkflowActionsType) => {
          const workflowAction = WorkflowActions[key];
          return workflowAction.allowedStates.includes(currentState) && (
              <Button key={workflowAction?.baseLabelKey} color={workflowAction.color} className="ms-2" size={SizeType.MD}
                      onClick={actions[key]}>
                {intl.formatMessage({id: workflowAction.baseLabelKey}, {entity: entityLabel})}
              </Button>
            );
          }
        )
      }
    </div>
  )
}

export default WorkflowButtonList
