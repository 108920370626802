import { API_STRUCTURES_PATH } from "constants/routes/RoutePaths";
import {
  CreateStructureRequest,
  Structure,
} from "interfaces/StructureInterface";
import { httpService } from "./HttpService";

const getStructures = (): Promise<Structure[]> => {
  return httpService.get<Structure[]>(`${API_STRUCTURES_PATH}`);
};

const createStructure = (
  structure: CreateStructureRequest
): Promise<Structure> => {
  return httpService.post<Structure>(`${API_STRUCTURES_PATH}`, structure);
};

export const structureService = {
  getStructures,
  createStructure,
};
