import React, {FunctionComponent} from "react";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../../services/ActivityService";
import {useIntl} from "react-intl";
import ActivitiesToggleContainer from "../../card/resource/ActivitiesToggleContainer";

interface LinkedActivitiesProps {
  className?: string,
  activity: Activity,
}

const LinkedActivities: FunctionComponent<LinkedActivitiesProps> = ({
  className = "",
  activity,
}) => {
  const intl = useIntl()
  return (
    <div className={className}>
      <ActivitiesToggleContainer
        title={intl.formatMessage({id: "activity_linked_activities_title"})}
        suppliersLabel={intl.formatMessage({id: "activity_linked_activities_suppliers_title"})}
        elementsLabel={intl.formatMessage({id: "activity_linked_activities_elements_title"})}
        elementsMobileLabelWithCount={count => intl.formatMessage({id: "activity_linked_activities_elements_mobile_label"}, {count: count})}
        additionalExcludedIds={[activity.id]}
        onGet={() => activityService.getLinkedActivity(activity.id)}
        onUpdate={(ids) => activityService.addLinkedActivity({activityId: activity.id, linkedActivityIds: ids})}
        onDelete={(ids) => activityService.deleteLinkedActivity({activityId: activity.id, linkedActivityIds: ids})}
      />
    </div>
  )
}

export default LinkedActivities;
