import {BillingFormData, InvoiceFormData} from "../interfaces/InvoiceInterfaces";

export const INVOICE_INITIAL_VALUES: InvoiceFormData = {
  date: "",
  billingCustomerId: undefined,
}

export const INVOICE_BILLING_INITIAL_VALUES: BillingFormData = {
  orderId: "",
  orderItemId: "",
  name: undefined,
  quantity: undefined,
  unitPriceHT: undefined,
  disbursement: undefined,
}
