import React, {FunctionComponent, useState} from "react";
import {dateUtils} from "../../../../utils/dateUtils";
import {FormattedMessage} from "react-intl";
import IconLabelCard from "../../../atoms/IconLabelCard";
import {OrderItemResponse} from "../../../../interfaces/OrderItemInterfaces";

interface OtherActivitiesProps {
  item: OrderItemResponse
}

const OtherActivities: FunctionComponent<OtherActivitiesProps> = ({
  item
}) => {

  const [hideStaffing, setHideStaffing] = useState<boolean>(true)

  return (
    <div className="d-flex flex-column mb-3" key={item.id}>
      <div className="d-flex justify-content-between">
        <span className="fw-bold">{dateUtils.formatLocalTime(item.rendezVousHour)} - {item.name}</span>
        <span className="text-muted epow-text-link cursor-pointer me-3" onClick={() => setHideStaffing(!hideStaffing)}>
          <FormattedMessage id={hideStaffing ? "activity_show_staffing" : "activity_hide_staffing"}/>
        </span>
      </div>

      {!hideStaffing && item.simpleStaffingList?.map(staffing => (
        <div key={staffing.fullName} className="d-flex align-items-center">
          <IconLabelCard iconName="User" label={staffing.fullName}/>
          <span>{staffing.phoneNumber}</span>
        </div>
      ))}
    </div>
  )
};

export default OtherActivities;
