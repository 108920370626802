import React, {FunctionComponent} from "react";
import AdminExportCsvCard from "../card/admin/export/AdminExportCsvCard";
import AdminExportPdfCard from "../card/admin/export/AdminExportPdfCard";

interface AdminExportsProps {
  className?: string,
}

const AdminExports: FunctionComponent<AdminExportsProps> = ({
  className,
}) => {
  return (
    <div className={`d-flex gap-2 ${className ?? ""}`}>
      <AdminExportCsvCard className="epow-card-admin-export" />
      <AdminExportPdfCard className="epow-card-admin-export" />
    </div>
  )
}

export default AdminExports;
