import React, {FunctionComponent} from "react";
import {Form} from "formik";
import {FormProps} from "reactstrap";

interface EpowFormProps extends FormProps {
  fullWidth?: boolean
}

const EpowForm: FunctionComponent<EpowFormProps> = ({
  fullWidth= true,
  className = "",
  children,
  ...rest
}) => (
    <Form className={`${className ?? ""} ${fullWidth && "w-100"}`} {...rest} >
      {children}
    </Form>
)

export default EpowForm