import React, { FunctionComponent, useContext, useEffect, useMemo } from "react"
import { RouteProps, useNavigate } from "react-router-dom"
import Layout from "../components/layout/Layout";
import Welcome from "../components/molecules/dashboard/Welcome";
import Note from "../components/molecules/dashboard/Note";
import DashboardPriority, { Priority } from "../components/molecules/dashboard/DashboardPriority";
import Calendar from "react-calendar"
import { FormattedMessage, useIntl } from "react-intl";
import { notificationService } from "../services/NotificationService";
import { NOTIFICATIONS } from "../constants/NotificationConstants";
import { Dashboard } from "../interfaces/ActivityInterfaces";
import { toastUtils } from "../utils/toastUtils";
import { authUtils } from "../utils/authUtils";
import { dateUtils } from "../utils/dateUtils";
import { PROFILE, User } from "../interfaces/UserInterfaces";
import DashboardNextActivitiesCard from "../components/molecules/card/dashboard/DashboardNextActivitiesCard";
import DashboardNextTasksCard from "../components/molecules/card/dashboard/DashboardNextTasksCard";
import DashboardNextServices from "../components/molecules/card/dashboard/DashboardNextServices";
import { userService } from "../services/UserService";
import { orderService } from "../services/OrderService";
import OrderTable from "../components/molecules/table/order/OrderTable";
import { OrderList, OrderSearchObject, OrderState } from "../interfaces/OrderInterfaces";
import Icon from "../components/icon/Icon";
import { IconSizeType } from "../types/bootstrap/BootstrapType";
import DashboardNextCustomerOrders from "../components/molecules/card/dashboard/DashboardNextCustomerOrders";
import DashboardNextTourLeaderOrders from "../components/molecules/card/dashboard/DashboardNextTourLeaderOrders";
import { ActiveCustomerContext } from "../context/ActiveCustomerContext";

const HomeView: FunctionComponent<RouteProps> = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [calendarDate, setCalendarDate] = React.useState<Date>(null)
  const [dashboard, setDashboard] = React.useState<Dashboard>()
  const [user, setUser] = React.useState<User>()
  const [orders, setOrders] = React.useState<OrderList[]>([])
  const activeCustomer = useContext(ActiveCustomerContext);

  useEffect(() => {
    notificationService.getDashboard()
      .then(setDashboard)
      .catch(() => toastUtils.errorToast(intl.formatMessage({ id: "dashboard_error_message" })))
  }, [activeCustomer])

  useEffect(() => {
    userService.getMe(activeCustomer?.id)
      .then((currentUser) => setUser(currentUser))
      .catch(() => toastUtils.errorToast(intl.formatMessage({ id: "dashboard_error_message" })))
  }, [activeCustomer]);

  const priorities = useMemo(() => {
    if (!dashboard?.notification) return []
    return [
      ...dashboard.notification.danger.map((item) => ({
        id: item.id,
        url: item.url,
        ...NOTIFICATIONS[item.code].build({ notification: item }),
        type: "danger"
      })),
      ...dashboard.notification.warning.map((item) => ({
        id: item.code,
        ...NOTIFICATIONS[item.code].build({ count: item.notificationsCount }),
        type: "warning"
      }))
    ]
  }, [dashboard]);

  const onRemoveWarningPriority = (priority: Priority) => {
    if (priority.url) {
      navigate(priority.url)
    }
  }

  const onRemoveDangerPriority = (priority: Priority) => {
    if (priority.removeOnResolve) {
      notificationService.deleteNotification(priority.id)
        .then(() => {
          const newDangerNotifications = [...dashboard.notification.danger]
          newDangerNotifications.splice(newDangerNotifications.findIndex(item => item.id === priority.id), 1)
          setDashboard({
            ...dashboard,
            notification: {
              ...dashboard.notification,
              danger: newDangerNotifications
            }
          })
          if (priority.url) {
            navigate(priority.url)
          }
        })
        .catch(() => toastUtils.errorToast(intl.formatMessage({ id: "notification_delete_error" })));
    } else if (priority.url) {
      navigate(priority.url)
    }
  }

  useMemo(() => {
    if (![PROFILE.ADMIN, PROFILE.INTERN].includes(authUtils.getProfile())) {
      return
    }

    const params: OrderSearchObject = {
      startDate: dateUtils.formatDateYYYYMMDD(calendarDate),
      endDate: dateUtils.formatDateYYYYMMDD(calendarDate),
      status: [OrderState.PENDING, OrderState.DRAFT, OrderState.COMPLETED, OrderState.HANDLED]
    }
    orderService.getOrdersPage({pageSize: 100}, params)
      .then((response) => {
        setOrders(response.content)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({ id: "message_id" }))
      })
  }, [calendarDate]);

  return (
    <Layout>
      <div className="epow-home">
        <Welcome
          firstname={user?.firstName}
          onChangeCalendarDate={(value) => setCalendarDate(value)}
          calendarDate={calendarDate} />

        <div
          className="dashboard-content mt-3 px-3 py-3 py-lg-0 d-flex flex-column flex-lg-row-reverse justify-content-sm-between">
          {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN || authUtils.getProfile() === PROFILE.CLIENT) && (
            <div className="epow-dashboard-right d-flex flex-column  mx-2 mx-lg-5">
              {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && (
                <Note
                  className="mb-3"
                  date={dateUtils.formatDateYYYYMMDD(calendarDate || new Date())} />
              )}
              <div className="d-none d-lg-block">
                <h6><FormattedMessage id="dashboard_calendar" /></h6>
                <Calendar
                  value={calendarDate}
                  onChange={(value: Date) => setCalendarDate(value)} />
              </div>
            </div>
          )}

          <div className="d-flex flex-column flex-basic-100">
            {priorities.length > 0 &&
              <DashboardPriority
                priorities={priorities}
                onRemoveWarningPriority={onRemoveWarningPriority}
                onRemoveDangerPriority={onRemoveDangerPriority}
                className="mb-5" />
            }

            {(authUtils.getProfile() === PROFILE.RESOURCE) && (
              <DashboardNextActivitiesCard activities={dashboard?.activities} profile={authUtils.getProfile()} />
            )}

            {(user && authUtils.getProfile() === PROFILE.SUPPLIER) && (
              <DashboardNextServices user={user} />
            )}

            {(user && (authUtils.getProfile() === PROFILE.CLIENT)) && (
              <DashboardNextCustomerOrders resourceDisplayOption={user.resourceDisplayOption} calendarDate={calendarDate} />
            )}

            {(user && (authUtils.getProfile() === PROFILE.TOUR_LEADER)) && (
              <DashboardNextTourLeaderOrders />
            )}


            {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && (
              <DashboardNextTasksCard calendarDate={calendarDate} />
            )}

            {orders.length > 0 && (
              <>
                <div className="d-flex">
                  <h3 className="fs-16 mt-2"><FormattedMessage id="dashboard_orders_today" /></h3>
                  <Icon name="Cross" className="ms-2 cursor-pointer" size={IconSizeType.XS} onClick={() => setOrders([])} />
                </div>
                <OrderTable resourceDisplayOption={user?.resourceDisplayOption} orders={orders} total={orders.length} sortOptions={{ sortBy: "" }} onSortOptions={() => null} />
              </>
            )}

          </div>

        </div>

      </div>
    </Layout>
  )
}

export default HomeView;
