import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Resource} from "../../../../interfaces/ResourceInterfaces";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import {ResourceForStaffingDto} from "../../../../interfaces/ResourceStaffingInterfaces";
import {getProfilePicture} from "../../../../constants/routes/RoutePaths";
import Avatar from "../../images/Avatar";
import ResourceCircle from "../../circle/ResourceCircle";

interface StaffingSelectorResourceProps {
  className?: string,
  maxResources?: number,
  isDirectStaffing: boolean,
  selectedResources: Resource[],
  resource: ResourceForStaffingDto,
  onSelect: (resource: Resource) => void,
  onDirectSelect: (resource: Resource) => void,
  hasDirectStaffing?: boolean,
}

const StaffingSelectorResource: FunctionComponent<StaffingSelectorResourceProps> = ({
  className = "",
  maxResources = null,
  isDirectStaffing,
  selectedResources,
  resource,
  onSelect,
  onDirectSelect,
  hasDirectStaffing = true,
}) => {
  const getButtonClassname = () => {
    const canStillStaffResources = (maxResources == null) || (selectedResources.length < maxResources)
    return (isDirectStaffing || selectedResources.length === 0) && canStillStaffResources ? "" : "invisible"
  }

  return (
    <div className={`d-flex align-items-center ${className ?? ""}`}>
      {!isDirectStaffing && (
        <input
          className="me-2"
          type="checkbox"
          checked={selectedResources?.some(r => r?.id === resource?.id)}
          onChange={() => onSelect(resource)}
        />
      )}

      <Avatar url={getProfilePicture(resource.id)} className="m-0 me-2"/>
      <ResourceCircle className="me-2" resourceType={resource.resourceType} />
      <div className="py-2">
        {`${resource?.firstName} ${resource?.lastName}`}
      </div>

      {hasDirectStaffing && (
        <Button
          onClick={() => onDirectSelect(resource)}
          size={SizeType.XS}
          color={ColorType.SECONDARY}
          className={`ms-auto py-1 ${getButtonClassname()}`}
        >
          <FormattedMessage id={"staffing_confirm"} />
        </Button>
      )}
    </div>
  )
}

export default StaffingSelectorResource;
