import {STRUCTURE_INITIAL_VALUES} from "constants/StructureConstants";
import {CreateStructureSchema} from "constants/validation/StructureValidationSchema";
import {Formik} from "formik";
import {CreateStructureFields, CreateStructureRequest, Structure} from "interfaces/StructureInterface";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {structureService} from "services/StructureService";
import {toastUtils} from "utils/toastUtils";
import FormInput from "../input/FormInput";
import {structureUtils} from "../../../utils/structureUtils";
import EpowForm from "./EpowForm";
import AddressForm from "./address/AddressForm";

interface CreateStructureFormProps {
  className?: string;
  id?: string;
  onCreateStructure?: (structure: Structure) => void;
}

const CreateStructureForm: FunctionComponent<CreateStructureFormProps> = ({
  className = "",
  id,
  onCreateStructure
}) => {
  const intl = useIntl();

  const handleSubmitStructure = (
    structureSubmitted: CreateStructureFields
  ) => {
    const createStructureRequest: CreateStructureRequest = structureUtils.buildStructureRequest(structureSubmitted);
    structureService
      .createStructure(createStructureRequest)
      .then((structure: Structure) => {
        onCreateStructure(structure);
        toastUtils.successToast(
          intl.formatMessage({ id: "success_create_structure" })
        );
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({ id: "error_create_structure" })
        );
      });
  };
  return (
    <Formik
      initialValues={STRUCTURE_INITIAL_VALUES}
      validationSchema={CreateStructureSchema}
      onSubmit={(value) => handleSubmitStructure(value)}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="name"
            label="name_field"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />
          <AddressForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
          <FormInput
            id="phoneNumber"
            label="phone_number_field"
            value={values?.phoneNumber}
            onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
            required
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
          />
          <FormInput
            id="email"
            label="email_field"
            value={values?.email}
            onChange={(e) => setFieldValue("email", e.target.value)}
            required
            error={errors.email}
            touched={touched.email}
          />
        </EpowForm>
      )}
    </Formik>
  );
};

export default CreateStructureForm;
