import React, {FunctionComponent, useEffect} from "react";
import {ToggleListElement} from "../../../../interfaces/inputs/ToggleListInterfaces";
import ToggleListColumn from "./ToggleListColumn";
import {Col, Label, Row} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import Panel from "../../panel/Panel";
import FieldLabel from "../../../atoms/FieldLabel";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import TextLink from "../../../atoms/TextLink";

interface ToggleListProps {
  className?: string
  supplierElements: ToggleListElement[]
  initialElements?: ToggleListElement[]
  onUpdate: (ids: string[]) => Promise<unknown>
  onDelete: (ids: string[]) => Promise<unknown>
  suppliersLabel?: string
  suppliersSubLabel?: string
  elementsLabel?: string
  elementsSubLabel?: string
  elementsMobileLabelWithCount?: (count: number) => string
}

const ToggleList: FunctionComponent<ToggleListProps> = ({
  className = "",
  supplierElements,
  initialElements,
  onUpdate,
  onDelete,
  suppliersLabel,
  suppliersSubLabel,
  elementsLabel,
  elementsSubLabel,
  elementsMobileLabelWithCount
}) => {
  const intl = useIntl()
  const [elements, setElements] = React.useState<ToggleListElement[]>(initialElements ?? [])
  const [filteredSupplierElements, setFilteredSupplierElements] = React.useState<ToggleListElement[]>([])
  const [elementDescriptionPanelOpen, setElementDescriptionPanelOpen] = React.useState<boolean>(false)
  const [listPanelForMobileOpen, setListPanelForMobileOpen] = React.useState<boolean>(false)
  const [elementInPanel, setElementInPanel] = React.useState<ToggleListElement>({})

  useEffect(() => {
    const newElements = [...initialElements]
    newElements.sort((a, b) => a.name.localeCompare(b.name))
    setElements(newElements)
  }, [initialElements])

  useEffect(() => {
    setFilteredSupplierElements(supplierElements)
  }, [supplierElements])

  const openPanel = (activity: ToggleListElement) => {
    setElementInPanel(activity)
    setElementDescriptionPanelOpen(true)
  }

  const addElement = (selectedElements: ToggleListElement[]) => {
    const elementsIds: string[] = []
    const unselectedElements: ToggleListElement[] = []
    selectedElements.forEach(element => {
      elementsIds.push(element.id)
      unselectedElements.push({...element, selected: false})
    })

    const newElements = [...elements, ...unselectedElements]
    newElements.sort((a, b) => a.name.localeCompare(b.name))
    setElements(newElements)

    setFilteredSupplierElements([...filteredSupplierElements].filter(supplierElement => !elementsIds.some(id => supplierElement.id === id)))
    onUpdate(elementsIds)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "resource_activities_add_success"})))
      .catch(() => toastUtils.successToast(intl.formatMessage({id: "resource_activities_add_error"})))
  }

  const removeElement = (selectedElements: ToggleListElement[]) => {
    const elementsIds: string[] = []
    const unselectedElements: ToggleListElement[] = []
    selectedElements.forEach(element => {
      elementsIds.push(element.id)
      unselectedElements.push({...element, selected: false})
    })

    setElements([...elements].filter(e => !elementsIds.some(id => e.id === id)))

    const newSupplierElements = [...filteredSupplierElements, ...unselectedElements]
    newSupplierElements.sort((a, b) => a.name.localeCompare(b.name))
    setFilteredSupplierElements(newSupplierElements)

    onDelete(elementsIds).catch(() => intl.formatMessage({id: "resource_activities_delete_error"}))
  }

  const setSelected = (
    list: ToggleListElement[],
    setlist: (elements: ToggleListElement[]) => void,
    element: ToggleListElement,
    selected: boolean
  ) => {
    const updatedElement: ToggleListElement = {...element, selected}
    const copy = [...list]
    const index = copy.findIndex(e => e.id === element.id)
    copy[index] = updatedElement;
    setlist(copy)
  }

  const selectAll = (list: ToggleListElement[], setlist: (elements: ToggleListElement[]) => void, selected: boolean) => {
    const copy = list.map(element => ({...element, selected}))
    setlist(copy)
  }

  const elementInInitialElements = elements.find(element => element === elementInPanel) !== undefined;

  return (
    <>
      <Row className={`toggle-list ${className}`}>
        <Col xs={24} className="d-md-none">
          <div className="d-flex flex-column mb-3">
            {elementsLabel && (
              <Label className="ms-2 bold epow-label">{elementsLabel}</Label>
            )}
            {elementsSubLabel && (
              <span className="ms-2">{elementsSubLabel}</span>
            )}
            {elementsMobileLabelWithCount && (
              <TextLink to="#" className="ms-2">
                <span onClick={() => setListPanelForMobileOpen(true)}>{elementsMobileLabelWithCount(elements.length)}</span>
              </TextLink>
            )}
          </div>
        </Col>
        <Col md={12} className={"pe-lg-4"}>
          <div className="d-flex flex-column mb-3">
            {suppliersLabel && (
              <Label className="ms-2 bold epow-label">{suppliersLabel}</Label>
            )}
            {suppliersSubLabel && (
              <span className="ms-2">{suppliersSubLabel}</span>
            )}
          </div>
          <ToggleListColumn
            className=""
            elements={filteredSupplierElements}
            onClick={addElement}
            onSeeMore={openPanel}
            iconName="CirclePlus"
            iconColor={ColorType.SUCCESS}
            setSelected={(element, value) => setSelected(filteredSupplierElements, setFilteredSupplierElements, element, value)}
            selectAll={(value) => selectAll(filteredSupplierElements, setFilteredSupplierElements, value)}
          />
        </Col>

        <Col md={12} className="d-none d-md-block">
          <div className="d-flex flex-column mb-3">
            {elementsLabel && (
              <Label className="ms-2 bold epow-label">{elementsLabel}</Label>
            )}
            {elementsSubLabel && (
              <span className="ms-2">{elementsSubLabel}</span>
            )}
          </div>
          <ToggleListColumn
            className=""
            elements={elements}
            onClick={removeElement}
            onSeeMore={openPanel}
            iconName="CircleMinus"
            iconColor={ColorType.DANGER}
            setSelected={(element, value) => setSelected(elements, setElements, element, value)}
            selectAll={(value) => selectAll(elements, setElements, value)}
          />
        </Col>
      </Row>
      {listPanelForMobileOpen && (
        <Panel title={elementsLabel} open={listPanelForMobileOpen} displayValidateButton={false} cancelButtonLabel="close" onCancel={() => setListPanelForMobileOpen(false)}>
          <Row className="toggle-list">
            <ToggleListColumn
              className=""
              elements={elements}
              onClick={removeElement}
              onSeeMore={openPanel}
              iconName="CircleMinus"
              iconColor={ColorType.DANGER}
              setSelected={(element, value) => setSelected(elements, setElements, element, value)}
              selectAll={(value) => selectAll(elements, setElements, value)}
            />
          </Row>
        </Panel>
      )}
      {elementDescriptionPanelOpen && (
        <Panel
          title={elementInPanel.name}
          open={elementDescriptionPanelOpen}
          displayButton={false}
        >
          <div className="d-flex flex-column justify-content-between">
            <FieldLabel
              verticalDisplay
              valueBold={false}
              label={intl.formatMessage({id: "dashboard_panel_activity_description"})}
              value={elementInPanel.description}
            />
            <div className="d-flex justify-content-between mt-5">
              <Button
                className="extended"
                onClick={() => {
                  setElementDescriptionPanelOpen(false)
                  setElementInPanel({})
                }}
                color={ColorType.SECONDARY}
              >
                <FormattedMessage id="close" />
              </Button>
              {elementInInitialElements ? (
                <Button
                  onClick={() => {
                    removeElement([elementInPanel])
                    setElementDescriptionPanelOpen(false)
                    setElementInPanel({})
                  }}
                  className="extended"
                  color={ColorType.DANGER}
                >
                  <FormattedMessage id="revoke_button" />
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    addElement([elementInPanel])
                    setElementDescriptionPanelOpen(false)
                    setElementInPanel({})
                  }}
                  className="extended"
                  color={ColorType.PRIMARY}
                >
                  <FormattedMessage id="add_button" />
                </Button>
              )}
            </div>
          </div>
        </Panel>
      )}
    </>

  )
}

export default ToggleList;
