import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {optionUtils} from "../../../../utils/optionUtils";
import {UNIT_TYPE_LABEL, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {dateUtils} from "../../../../utils/dateUtils";
import {numberUtils} from "../../../../utils/numberUtils";
import {Col, Row} from "reactstrap";
import FieldLabel from "../../../atoms/FieldLabel";
import {UNIT_TYPE} from "../../../../interfaces/OrderItemInterfaces";

interface ActivityCardProps {
  className?: string,
  activity: Activity,
}

const ActivityCard: FunctionComponent<ActivityCardProps> = ({
  className = "",
  activity,
}) => {
  const intl = useIntl();

  return (
    <div className={className}>
      <Row>
        <Col xs={24} md={12}>
          <div className="d-flex flex-column gap-3 mb-3">
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_name_field"})}
              value={activity?.name}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_reference_field"})}
              value={activity?.reference}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_billing_name"})}
              value={activity?.billingName}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_duration"})}
              value={dateUtils.formatDuration(activity?.duration)}
            />
            <FieldLabel
              verticalDisplay
              className="white-space-pre-wrap"
              label={intl.formatMessage({id: "activity_description"})}
              value={activity?.description}
            />
          </div>
        </Col>

        <Col xs={24} md={12}>
          <div className="d-flex flex-column gap-3 mb-3">
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_number_pax_max"})}
              value={activity?.numberPaxMax}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_zone"})}
              value={activity?.activityArea?.label}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "order_item_activity_ops"})}
              value={optionUtils.translateOption(intl, YES_NO_OPTIONS, activity?.showActivityTourLeader)}
            />
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id: "activity_is_for_kids"})}
              value={optionUtils.translateOption(intl, YES_NO_OPTIONS, activity?.forKids)}
            />
            <Row>
              <Col xs={24} md={12}>
                <FieldLabel
                  verticalDisplay
                  label={intl.formatMessage({id: "activity_is_staffing_management"})}
                  value={optionUtils.translateOption(intl, YES_NO_OPTIONS, activity?.staffingManagement)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <FieldLabel
                  verticalDisplay
                  label={intl.formatMessage({id: "service_unit_type"})}
                  value={intl.formatMessage({id: UNIT_TYPE_LABEL[activity?.unitType]?.label ?? UNIT_TYPE.PAX})}
                />
              </Col>
              <Col xs={24} md={12}>
                <FieldLabel
                  verticalDisplay
                  label={intl.formatMessage({id: "activity_price"})}
                  value={numberUtils.formatPrice(activity?.price)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ActivityCard;
