function formatDateYYYYMMDD(date?: Date): string {
  if (!date) {
    date = new Date();
  }
  const data = date.toLocaleDateString("fr").split("/");

  return data[2]
    .toString()
    .concat("-")
    .concat(data[1])
    .concat("-")
    .concat(data[0]);
}

const datePlusDays = (date: Date, add: number) => {
  if(date === null) {
    return null
  }
  const newDate = new Date(date)
  newDate.setDate(date.getDate() + add);
  return newDate;
};

const dateMinusDays = (date: Date, minus: number) => {
  if(date === null) {
    return null
  }
  const newDate = new Date(date)
  newDate.setDate(date.getDate() - minus);
  return newDate;
};

const dateToLocaleDate = (value?: Date): string => {
  if (!value) return null;
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return value.toLocaleDateString(userLocale, options);
};

const formatToLocalDateString = (value: Date): string => {
  if (!value) return null;
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  };
  const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

  return value?.toLocaleDateString(userLocale, options)
}

const parseDate = (value: string): Date => {
  if (!value || value?.trim() == "") {
    return null
  }

  return new Date(value);
};

function convertStringToObjectDate(date?: string): Date {
  const regexTimeUTC = /(Z|([+|-][0-9]{2}(:?[0-9]{2})?))$/;
  if (!date) {
    return new Date();
  }
  if (!regexTimeUTC.test(date)) {
    return new Date(`${date}Z`);
  }
  return new Date(date);
}

function formatDateToLocalDateTime(
  dateTime?: string | Date,
  withTime = true
): string {
  if (!dateTime) {
    return "";
  }
  if (typeof dateTime === "string") {
    dateTime = new Date(dateTime);
  }
  return dateTime.toLocaleString(undefined, {
    dateStyle: "short",
    timeStyle: withTime ? "short" : undefined,
  });
}

function formatDuration(totalSeconds?: number): string {
  if (!totalSeconds) {
    return "-";
  }
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  return [`${hours}h`, `${minutes}m`, `${seconds}s`]
    .filter((item) => item[0] !== "0")
    .join(" ");
}

function convertSecondsToTime(seconds?: number): string {
  if (!seconds) {
    return undefined;
  }
  return new Date(seconds * 1000).toISOString().substring(11, 19);
}

function convertTimeToSeconds(time?: string): number {
  if (!time) {
    return undefined;
  }
  const [hours, minutes] = time.split(":");
  return +hours * 3600 + +minutes * 60;
}

const getTimeWithoutSeconds = (time: string): string => {
  if (!time) {
    return undefined;
  }
  const [hours, minutes] = time.split(":");
  return [hours, minutes].join(":");
}

function formatLocalTime(time: string): string {
  if (!time) {
    return "";
  }
  const [hours, minutes] = time.split(":");
  return `${hours}h${minutes}`;
}

const getNumberOfMonthsBetweenTwoDates = (date1: Date, date2: Date): number => {
  const differenceInMonths = date2.getMonth() - date1.getMonth();
  const differenceInYears = date2.getFullYear() - date1.getFullYear();
  return 12 * differenceInYears + differenceInMonths;
};

const firstDayOfLastMonth = (): Date => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  date.setDate(1);
  return date;
}

const lastDayOfLastMonth = (): Date => {
  const date = new Date();
  date.setDate(1);
  date.setDate(date.getDate() - 1);
  return date;
}

const getDaysArrayBetweenDates = (startDate: string, endDate: string): Date[] => {
  const result = [];
  const start = convertStringToObjectDate(startDate);
  const end = convertStringToObjectDate(endDate);

  for(let i = start ; i <= end; i.setDate(i.getDate() + 1)) {
    result.push(new Date(i));
  }
  return result;
};

const getMaxDate = (dates: Date[]): Date => {
  return new Date(Math.max(...dates.map((date) => date.getTime())))
}

export const dateUtils = Object.freeze({
  formatDateYYYYMMDD,
  dateToLocaleDate,
  formatToLocalDateString,
  parseDate,
  convertStringToObjectDate,
  formatDateToLocalDateTime,
  formatDuration,
  convertSecondsToTime,
  convertTimeToSeconds,
  getTimeWithoutSeconds,
  formatLocalTime,
  getNumberOfMonthsBetweenTwoDates,
  firstDayOfLastMonth,
  lastDayOfLastMonth,
  getDaysArrayBetweenDates,
  datePlusDays,
  dateMinusDays,
  getMaxDate
});
