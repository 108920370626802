import React, {FunctionComponent, useEffect, useState} from "react";
import {numberUtils} from "../../../utils/numberUtils";
import FormInput, {FormInputProps} from "./FormInput";

interface PriceInputProps extends FormInputProps {
  className?: string,
}

const PriceInput: FunctionComponent<PriceInputProps> = ({
  className = "",
  id,
  label,
  value,
  onChange,
  ...rest
}) => {
  const [displayedValue, setDisplayedValue] = useState<string | number>(value ? numberUtils.numberFormatField(value?.toString()) : "")

  useEffect(() => {
    const formattedValue = value ? numberUtils.numberFormatField(value.toString()) : ""
    setDisplayedValue(formattedValue)
  }, [value])

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = numberUtils.numberFormatField(event.target.value)
    setDisplayedValue(formattedValue)
    onChange(event)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    setDisplayedValue(value)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.key
    const regex = /^[a-zA-Z!@#\$%\^\&*\)\(+=_-]{1}$/
    if (regex.test(input)) {
      event.preventDefault()
    }
  }

  return (
    <FormInput
      className={className}
      id={id}
      label={label}
      type="number"
      minValue={0}
      value={displayedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={onKeyDown}
      onWheel={(e) => (e.target as HTMLElement).blur()}
      {...rest}
    />
  )
}

export default PriceInput;
