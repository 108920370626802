import {CustomerServiceInfoCatalog} from "../../../../interfaces/ServiceInterfaces";
import React, {FunctionComponent, useState} from "react";
import CustomerServiceInfoCatalogList from "../../list/CustomerServiceInfoCatalogList";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import Panel from "../../panel/Panel";
import CreateCustomerServiceInfoCatalogForm from "../../form/CreateCustomerServiceInfoCatalogForm";

interface CustomerServiceInfoCatalogCardProps {
  className?: string,
  customerServiceId?: string,
  customerServiceInfoCatalogs?: CustomerServiceInfoCatalog[],
  onValidate?: () => void
}

const CustomerServiceInfoCatalogCard: FunctionComponent<CustomerServiceInfoCatalogCardProps> = ({
  className = "",
  customerServiceId,
  customerServiceInfoCatalogs,
  onValidate = () => null
}) => {
  const [lateralPanelCustomerServiceInfoCatalog, setLateralPanelCustomerServiceInfoCatalog] = useState<boolean>(false)
  const [initialCustomerServiceInfoCatalog, setInitialCustomerServiceInfoCatalog] = useState<CustomerServiceInfoCatalog>(null)

  const handleValidate = () => {
    setLateralPanelCustomerServiceInfoCatalog(false)
    onValidate()
  }

  return (
    <div className={className}>
      <CustomerServiceInfoCatalogList
        customerServiceInfoCatalogs={customerServiceInfoCatalogs}
        setLateralPanelCustomerServiceInfoCatalog={setLateralPanelCustomerServiceInfoCatalog}
        setInitialCustomerServiceInfoCatalog={setInitialCustomerServiceInfoCatalog}
        onValidate={onValidate}
      />
      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        className="extended"
        onClick={() => {
          setLateralPanelCustomerServiceInfoCatalog(true)
          setInitialCustomerServiceInfoCatalog(null)
        }}
      >
        <FormattedMessage id="add_button"/>
      </Button>
      {lateralPanelCustomerServiceInfoCatalog && (
        <Panel
          formId="createCustomerServiceInfoCatalogForm"
          title="add_customer_service_info_catalog"
          open={lateralPanelCustomerServiceInfoCatalog}
          onCancel={() => setLateralPanelCustomerServiceInfoCatalog(false)}
        >
          <CreateCustomerServiceInfoCatalogForm
            id="createCustomerServiceInfoCatalogForm"
            customerServiceId={customerServiceId}
            initialCustomerServiceInfoCatalog={initialCustomerServiceInfoCatalog}
            onValidate={handleValidate}
          />
        </Panel>
      )}
    </div>
  )
}

export default CustomerServiceInfoCatalogCard