import Title from "components/atoms/Title";
import { Supplier, SupplierSettings } from "interfaces/SupplierInterfaces";
import { FunctionComponent, useState } from "react";
import { Col } from "reactstrap";
import React from "react";
import SettingsCard from "./SettingsCard";
import { FormattedMessage } from "react-intl";
import { SizeType, ColorType } from "types/bootstrap/BootstrapType";
import CreateSupplierSettingsForm from "components/molecules/form/CreateSupplierSettingsForm";
import Button from "components/atoms/Button";

interface SettingsSupplierCardProps {
  className?: string;
  supplierSettings?: SupplierSettings;
  setSupplier?: (supplier: Supplier) => void;
  title?: string;
}

const SettingsSupplierCard: FunctionComponent<SettingsSupplierCardProps> = ({
  className = "",
  supplierSettings,
  setSupplier,
  title,
}) => {
  const [editInfo, setEditInfo] = useState<boolean>(false);
  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size={"h5"} className="mb-3"/>
      {editInfo ? (
        <>
          <CreateSupplierSettingsForm
            formId="editSupplierSettingsForm"
            initialSettings={supplierSettings}
            setSupplier={setSupplier}
            setEditInfo={setEditInfo}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form={"editSupplierSettingsForm"}
              type="submit"
              size={SizeType.MD}
              className="extended"
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <SettingsCard
          supplierSettings={supplierSettings}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
        />
      )}
    </Col>
  );
};

export default SettingsSupplierCard;
