import React, {FunctionComponent, useCallback, useEffect} from "react";
import ToggleList from "../../input/tooglelist/ToggleList";
import {ActivitySearchObject, ActivityShortDTO} from "../../../../interfaces/ActivityInterfaces";
import {useIntl} from "react-intl";
import {ToggleListElement} from "../../../../interfaces/inputs/ToggleListInterfaces";
import {activityService} from "../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import FilterGroup from "../../filters/FilterGroup";
import TextFilter from "../../filters/TextFilter";
import Button from "../../../atoms/Button";
import {ColorType, TitleSizeType} from "../../../../types/bootstrap/BootstrapType";
import useSearchFilter from "../../../../hooks/useSearchFilter";
import AutocompleteMultiselect from "../../filters/AutocompleteMultiselect";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import Title from "../../../atoms/Title";
import {profileUtils} from "../../../../utils/profileUtils";

interface ResourceActivitiesContainerProps {
  className?: string;
  title: string;
  suppliersLabel?: string;
  suppliersSubLabel?: string;
  elementsLabel?: string;
  elementsSubLabel?: string;
  elementsMobileLabelWithCount?: (count: number) => string;
  additionalExcludedIds?: string[];
  resourceTypeId?: string,
  showOnlyOperationalActivities?: boolean;
  resourceId?: string;
  areaIds?: string[];
  onGet: () => Promise<ActivityShortDTO[]>;
  onUpdate: (ids: string[]) => Promise<ActivityShortDTO[]>;
  onDelete: (ids: string[]) => Promise<Response>;
}

const ActivitiesToggleContainer: FunctionComponent<ResourceActivitiesContainerProps> = ({
  className = "",
  title,
  suppliersLabel,
  suppliersSubLabel,
  elementsLabel,
  elementsSubLabel,
  elementsMobileLabelWithCount,
  areaIds = [],
  additionalExcludedIds = [],
  resourceId,
  resourceTypeId,
  showOnlyOperationalActivities= false,
  onGet,
  onUpdate,
  onDelete,
}) => {
  const intl = useIntl()
  const [initialElements, setInitialElements] = React.useState<ToggleListElement[]>([])
  const [supplierElements, setSupplierElements] = React.useState<ToggleListElement[]>([])

  const {searchObject, onReset, updateSearchField} = useSearchFilter<ActivitySearchObject>({
    name: "",
    reference: "",
    serviceId: "",
    tourId: "",
    resourceTypeId: resourceTypeId,
    showOnlyOperationalActivities: showOnlyOperationalActivities,
    activityAreaIds: areaIds ?? [],
    status: WorkflowStatesEnum.ACTIVE
  })

  const parseBackDtosToFormDtos = (backDtos: ActivityShortDTO[]): ToggleListElement[] => {
    return backDtos.map((activity) => ({
      id: activity.id,
      name: activity.name,
      description: activity.description,
      selected: false
    }))
  }

  const fetchActivities = useCallback((filters) => {
    return activityService.getActivitiesPage({page: 0, pageSize: 500}, {...filters, status: WorkflowStatesEnum.ACTIVE, resourceIdForShowOnlyUnknownActivities: resourceId, excludedIds: additionalExcludedIds})
  }, [initialElements]);


  const getSupplierElements = (filters: ActivitySearchObject) => {
    fetchActivities(filters)
      .then((r) => setSupplierElements(parseBackDtosToFormDtos(r.content)))
      .catch(() => null)
  }

  const getInitialElements = () => {
    onGet()
      .then((activities) => {
        setInitialElements(parseBackDtosToFormDtos(activities))
      })
      .catch(() => intl.formatMessage({id: "resource_activities_get_error"}))
  }

  useEffect(() => {
    getInitialElements()
  }, [])

  useEffect(() => {
    getSupplierElements(searchObject)
  }, [fetchActivities, searchObject])

  return (
    <div className={className}>
      <div>
        <Title title={title} size={TitleSizeType.H5} />
      </div>
      <FilterGroup className="gap-2 mt-2 mb-4">
        <TextFilter
          icon="Search"
          className="main-filter"
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          title={intl.formatMessage({id: "activity_name_field"})}
          placeholder={intl.formatMessage({id: "activity_table_search_placeholder"})}
        />
        {profileUtils.isInterne() && (
          <AutocompleteMultiselect
            title={intl.formatMessage({id: "resource_table_header_area"})}
            placeholder={intl.formatMessage({id: "resource_table_header_area"})}
            values={searchObject.activityAreaIds ?? []}
            onChange={(values) => updateSearchField("activityAreaIds", values)}
            fetchData={(params, filter) => activityAreaService.getActivityAreasPage(params, {...filter, ids: areaIds ?? []})}
            filterFieldName="label"
            className="me-2"
            classNameLabel="mb-1 fw-bold"
          />
        )}
        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <ToggleList
        supplierElements={supplierElements}
        initialElements={initialElements}
        onUpdate={onUpdate}
        onDelete={onDelete}
        suppliersLabel={suppliersLabel}
        suppliersSubLabel={suppliersSubLabel}
        elementsLabel={elementsLabel}
        elementsSubLabel={elementsSubLabel}
        elementsMobileLabelWithCount={elementsMobileLabelWithCount}
      />
    </div>
  )
}

export default ActivitiesToggleContainer;
