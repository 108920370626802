import { Purchase, PurchaseBooking, PurchaseStatus } from "../../../../interfaces/PurchaseInterfaces";
import React, { FunctionComponent, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ColorType } from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import Button from "../../../atoms/Button";
import FieldLabel from "../../../atoms/FieldLabel";
import { numberUtils } from "../../../../utils/numberUtils";
import CreatePurchaseBookingForm from "../../form/purchase/CreatePurchaseBookingForm";
import Panel from "../../panel/Panel";
import { CustomTableColumn, CustomTableType } from "../../../../interfaces/TableInterfaces";
import CustomTable, { CustomTableRef } from "../../table/CustomTable";
import PurchaseBookingPreviewCardHeader from "./PurchaseBookingPreviewCardHeader";
import PurchaseBookingDetails from "./PurchaseBookingDetails";

interface PurchaseInfosCardProps {
  className?: string,
  purchase: Purchase,
  getPurchase: () => void
}

const PurchaseInfosCard: FunctionComponent<PurchaseInfosCardProps> = ({
  className = "",
  purchase,
  getPurchase = () => null
}) => {
  const intl = useIntl()
  const ref = React.useRef<CustomTableRef>();
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [editedPurchaseBooking, setEditedPurchaseBooking] = useState<PurchaseBooking>(null)

  const columns: CustomTableColumn[] = useMemo(() => [
    { title: "purchase_booking_service", accessor: "serviceId", sortable: false, minWidth: "25%" },
    { title: "order_referenceClient", accessor: "referenceCustomer", sortable: false, minWidth: "20%" },
    { title: "purchase_booking_price_net", accessor: "price", sortable: false },
    { title: "purchase_booking_quantity", accessor: "quantity", sortable: false },
    { title: "purchase_booking_vat", accessor: "vat", sortable: false },
    { title: "purchase_booking_total_TTC", accessor: "totalPrice", sortable: false },
    { title: "purchase_booking_paid", accessor: "paid", sortable: false },
    { title: "table_actions", accessor: "actions", sortable: false },
  ], [])


  const totalTTC = () => purchase?.bookings?.reduce((a: number, b: PurchaseBooking) => a + b.totalPrice, 0)
  const totalHT = () => purchase?.bookings?.reduce((a: number, b: PurchaseBooking) => a + b.totalExcludingVAT, 0)

  const handleValidation = () => {
    setFormOpen(false)
    getPurchase()
  }

  const editPurchaseBooking = (purchaseBooking: PurchaseBooking) => {
    setEditedPurchaseBooking(purchaseBooking)
    setFormOpen(true)
  }

  const addBooking = () => {
    setEditedPurchaseBooking(null)
    setFormOpen(true)
  }

  return (
    <div className={className}>
      {purchase?.bookings?.length > 0 ? (
        <CustomTable
          ref={ref}
          className="epow-purchase-booking-table"
          type={CustomTableType.DETAILS_TABLE}
          datas={purchase.bookings}
          columns={columns}
          RowComponent={({ data, open, ...rest }) => {
            const purchaseBooking = data as PurchaseBooking;
            return (
              <div>
                <PurchaseBookingPreviewCardHeader
                  purchaseBooking={purchaseBooking}
                  editPurchaseBooking={editPurchaseBooking}
                  onDelete={handleValidation}
                  purchaseStatus={purchase?.status}
                  {...rest}
                />
                {open && (
                  <div className="epow-custom-table-row-details">
                    <PurchaseBookingDetails purchaseBooking={purchaseBooking} />
                  </div>
                )}
              </div>);
          }
          } />
      ) : (
        <div className="d-flex flex-column align-items-center mt-5">
          <FormattedMessage id="purchase_order_booking_empty" />
        </div>
      )}
      <div className="d-flex justify-content-between m-2">
        {purchase.status === PurchaseStatus.OPEN &&
          <Button
            color={ColorType.SECONDARY}
            onClick={addBooking}
            className="border border-dark"
          >
            <FormattedMessage id="purchase_booking_add" />
            <Icon className="ms-2" name="Plus" />
          </Button>}
        {purchase?.bookings?.length > 0 && (
          <div className="d-flex flex-column">
            <FieldLabel label={intl.formatMessage({ id: "purchase_booking_total_HT" })} value={numberUtils.formatPrice(totalHT())} />
            <FieldLabel label={intl.formatMessage({ id: "purchase_booking_total_TTC" })} value={numberUtils.formatPrice(totalTTC())} />
          </div>
        )}
      </div>
      {formOpen && (
        <Panel
          title={editedPurchaseBooking?.id ? "purchase_booking_update" : "purchase_booking_add"}
          open={formOpen}
          onCancel={() => setFormOpen(false)}
          displayButton={false}
        >
          <CreatePurchaseBookingForm
            id="createPurchaseBookingForm"
            onCancel={() => setFormOpen(false)}
            onValidate={handleValidation}
            purchase={purchase}
            purchaseBooking={editedPurchaseBooking}
          />
        </Panel>
      )}
    </div>
  )
}

export default PurchaseInfosCard
