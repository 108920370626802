import React, { FunctionComponent, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column, Row } from "react-table";
import { ACTIVITY_BILLING_TABLE } from "../../../constants/Table";
import { PageResponse, PaginationQueryParams } from "../../../interfaces/api/PaginationInterface";
import { OrderItemSearchObject, OrderItemWithActivityBillingDto } from "../../../interfaces/OrderItemInterfaces";
import { FileData } from "../../../interfaces/ResourceInterfaces";
import { Data } from "../../../interfaces/TableInterfaces";
import { resourceService } from "../../../services/ResourceService";
import { ColorType } from "../../../types/bootstrap/BootstrapType";
import { authUtils } from "../../../utils/authUtils";
import { fileUtils } from "../../../utils/fileUtils";
import { tableUtils } from "../../../utils/tableUtils";
import Button from "../../atoms/Button";
import Icon from "../../icon/Icon";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import ModalBillActivities from "../modal/ModalBillActivities";
import PagedTableWithRowSelection, { PagedTableRef } from "../table/PagedTableWithRowSelection";
import { TRUE_FALSE_ENUM } from "constants/OptionConstants";
import PagedTable from "../table/PagedTable";

interface ActivityBillingListProps {
  className?: string,
  searchObject?: OrderItemSearchObject,
  updateSearchField?: (field: string, value: string) => void
  onReset?: () => void,
  isBilled?: boolean,
}

const ActivityBillingList: FunctionComponent<ActivityBillingListProps> = ({
  className = "",
  searchObject,
  isBilled,
  updateSearchField,
  onReset,
}) => {
  const intl = useIntl()
  const ref = useRef<PagedTableRef>()
  const [selectedDatas, setSelectedDatas] = useState<OrderItemWithActivityBillingDto[]>([])
  const [totalNbActivity, setTotalNbActivity] = useState<number>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const columns: Column[] = useMemo(() => tableUtils.getColumns(ACTIVITY_BILLING_TABLE(intl)), [intl])

  const getOrderItems = (pageParams: PaginationQueryParams, filter: OrderItemSearchObject) => {
    return resourceService.getOrderItemWithActivityBillingDtoPage(pageParams, filter)
  }

  const updateTotals = (pageResponse: PageResponse<OrderItemWithActivityBillingDto>) => {
    setTotalNbActivity(pageResponse.totalElements)
  }

  const onValidate = () => {
    setModalOpen(false)
    updateSearchField("billed", TRUE_FALSE_ENUM.TRUE)
  }

  const downloadExport = () => {
    const fileData: FileData = { name: "export-activity-bills", extension: "xlsx" }
    fileUtils.downloadFile(
      resourceService.getOrderItemWithActivityBillingExport(authUtils.getUserId(), searchObject),
      fileData,
      intl
    )
  }


  return (
    <div className={`${className} d-flex flex-column`}>
      <div className="d-flex justify-content-between">
        <FilterGroup className="d-flex gap-2">
          <DateFilter
            value={searchObject.startDate ?? ""}
            onChangeDate={(value) => updateSearchField("startDate", value)}
            title={intl.formatMessage({ id: "activities_billing_filter_from" })}
          />
          <DateFilter
            value={searchObject.endDate ?? ""}
            onChangeDate={(value) => updateSearchField("endDate", value)}
            title={intl.formatMessage({ id: "activities_billing_filter_to" })}
          />
          <div>
            <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({ id: "header_menu_clear_search" })}</Button>
          </div>
        </FilterGroup>

        <div className="d-flex flex-column">
          <div className="d-flex ms-auto">
            <Button color={ColorType.SECONDARY} className="border border-dark align-self-end" onClick={() => downloadExport()}>
              <FormattedMessage id="activity_bill_export" />
              <Icon name="Download" className="ms-2" />
            </Button>
          </div>
          <div>
            <span className="me-3">
              <FormattedMessage id="activity_bill_results_number" /> : <b>{totalNbActivity}</b>
            </span>
          </div>
        </div>

      </div>

      {isBilled ?

        <PagedTable
          ref={ref}
          columns={columns}
          labelNoResult="activities_billing_no_result"
          className={`${className} table`}
          filters={searchObject}
          defaultSortBy={[{ sortBy: "orderItem.rendezVousDate", descending: true }]}
          fetchData={getOrderItems}
        />
        :

        <PagedTableWithRowSelection
          ref={ref}
          columns={columns}
          labelNoResult="activities_billing_no_result"
          className={`${className} table`}
          filters={searchObject}
          initialPageSize={200}
          defaultSortBy={[{ sortBy: "orderItem.rendezVousDate", descending: true }]}
          fetchData={getOrderItems}
          afterFetch={updateTotals}
          rowSelectActionTitle="activity_billing_create"
          onRowSelectAction={(selectedRows: Array<Row<Data>>) => {
            setSelectedDatas(selectedRows.map(i => i.original as OrderItemWithActivityBillingDto))
            setModalOpen(true)
          }}
        />
      }
      
      <ModalBillActivities
        modalOpen={modalOpen}
        onOpenModal={setModalOpen}
        selectedIds={selectedDatas.map(data => data.activityStaffingId)}
        onValidate={onValidate}
      />

    </div>
  )
}

export default ActivityBillingList;
