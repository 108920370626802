import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";

interface ActivityBillingOverviewTableRowDetailsHeaderProps {
  className?: string,
  showFirstColumn?: boolean;
}

const ActivityBillingOverviewTableRowDetailsHeader: FunctionComponent<ActivityBillingOverviewTableRowDetailsHeaderProps> = ({
  className = "",
  showFirstColumn
}) => {
  return (
    <Row className={`details-header ms-0 ${className ?? ""}`}>
      {showFirstColumn && <Col xs={1} className="ps-0" />}
      <Col xs={3} className="ps-0 ms-2">
        <FormattedMessage id="billing_overview_detail_tour_code" />
      </Col>
      <Col xs={3} className="ps-0">
        <FormattedMessage id="billing_overview_detail_activity" />
      </Col>
      <Col xs={3} className="ps-0">
        <FormattedMessage id="billing_overview_detail_date_hour" />
      </Col>
      <Col xs={4} className="ps-0">
        <FormattedMessage id="billing_overview_detail_price" />
      </Col>
    </Row>
  )
}

export default ActivityBillingOverviewTableRowDetailsHeader;
