import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {PROFILE, UserSelfProfile} from "../../../../interfaces/UserInterfaces";
import {authUtils} from "../../../../utils/authUtils";
import FieldLabel from "../../../atoms/FieldLabel";

interface UserProfileCardProps {
  className?: string,
  user: UserSelfProfile
}

const UserProfileCard: FunctionComponent<UserProfileCardProps> = ({
  className = "",
  user,
}) => {
  const intl = useIntl()
  const profilesWithOtherPhoneNumber = [PROFILE.CLIENT, PROFILE.RESOURCE, PROFILE.TOUR_LEADER]


  return (
    <div className={`d-flex flex-column gap-2 ${className ?? ""}`}>
      <FieldLabel
        verticalDisplay
        label={intl.formatMessage({id: "user_first_name"})}
        value={user?.firstName}
      />
      <FieldLabel
        verticalDisplay
        label={intl.formatMessage({id: "user_last_name"})}
        value={user?.lastName}
      />
      <FieldLabel
        verticalDisplay
        label={intl.formatMessage({id: "user_email"})}
        value={user?.email}
      />
      <FieldLabel
        verticalDisplay
        label={intl.formatMessage({id: "user_phoneNumber"})}
        value={user?.phoneNumber}
      />
      {profilesWithOtherPhoneNumber.some(profile => profile === authUtils.getProfile()) && (
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id: "user_other_phoneNumber"})}
          value={user?.otherPhoneNumber}
        />
      )}
    </div>
  )
}

export default UserProfileCard;
