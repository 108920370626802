import {ResourceType} from "../../../interfaces/ResourceTypeInterfaces";
import React, {FunctionComponent} from "react";
import {SizeType} from "../../../types/bootstrap/BootstrapType";
import Circle from "../../atoms/Circle";

interface ResourceCircleProps {
  className?: string;
  resourceType: ResourceType;
}

const ResourceCircle: FunctionComponent<ResourceCircleProps> = ({
  className,
  resourceType
}) => {
  return (
    <Circle className={className} manualColor={resourceType?.color} size={SizeType.MD}>
      <span>{resourceType?.label?.substring(0, 1).toUpperCase()}</span>
    </Circle>
  )
}

export default ResourceCircle;
