import {FormDocument, PictureTypeProperty} from "../interfaces/FileInterfaces";

export const enum FileType {
  RESOURCE_CARD = "resource",
  LIABILITY_CARD = "liability",
  URSSAF_CARD = "urssaf",
  ORDER_ITEM_FILE = "ORDER_ITEM_FILE",
  VOUCHER = "VOUCHER",
  TICKET = "TICKET",
  BILL = "BILL",
  PAYMENT = "PAYMENT",
  CONFIRMATION = "CONFIRMATION",
  OTHER = "OTHER"
}

export enum FileTheme {
  OPERATIONS = "OPERATIONS",
  GUIDED_TOUR = "GUIDED_TOUR",
  POW_CUSTOMER_DEMANDS = "POW_CUSTOMER_DEMANDS",
  BILLING_ADMIN_IT_PROCESSES = "BILLING_ADMIN_IT_PROCESSES",
  CONSULTING_FEEDBACK_SECURITY = "CONSULTING_FEEDBACK_SECURITY",
  MISCELLANEOUS = "MISCELLANEOUS",
}

export const DOCUMENT_FORM_INITIAL_VALUES: FormDocument = {
  profiles: [],
  activities: [],
  theme: null,
  activityAreas: [],
  customerId: null,
}

export const enum PictureType {
  TOUR_PICTURE = "TOUR_PICTURE",
  ACTIVITY_PICTURE = "ACTIVITY_PICTURE",
  CUSTOMER_LOGO = "CUSTOMER_LOGO",
  HEADER_CUSTOMER_LOGO = "HEADER_CUSTOMER_LOGO",
}

export type PictureTypePropertiesType = {
  [key in PictureType]: PictureTypeProperty;
};

// TODO: config pictureWidth et pictureHeight une fois spécifié
export const PictureTypeProperties: PictureTypePropertiesType = {
  TOUR_PICTURE: {deleteMessage: "picture_modal_body_tour", infoMessage: "picture_modify_tour", pictureWidth: 400, pictureHeight: 200},
  ACTIVITY_PICTURE: {deleteMessage: "picture_modal_body_activity", infoMessage: "picture_modify_activity", pictureWidth: 400, pictureHeight: 200},
  CUSTOMER_LOGO: {deleteMessage: "picture_modal_body_client", infoMessage: "picture_modify_client", pictureWidth: 180, pictureHeight: undefined},
  HEADER_CUSTOMER_LOGO: {deleteMessage: "picture_modal_body_client", infoMessage: "picture_modify_client", pictureWidth: undefined, pictureHeight: 35},
} as const
