import React, {FunctionComponent, useEffect, useState} from "react";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import {useIntl} from "react-intl";
import ResourceAvailabilityContainer from "../components/molecules/resourceAvailability/ResourceAvailabilityContainer";
import {Resource} from "../interfaces/ResourceInterfaces";
import {resourceService} from "../services/ResourceService";
import {toastUtils} from "../utils/toastUtils";

const ResourceAvailabilityView: FunctionComponent = () => {
  const intl = useIntl()

  const [resource, setResource] = useState<Resource>(undefined)

  useEffect(() => {
    resourceService.getSelfResource()
      .then((resource) => setResource(resource))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_get_resource_availability"})))
  }, [])

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "resource_availability_title"})}></PageTitle>
        </div>
        <ResourceAvailabilityContainer resource={resource} />
      </div>
    </Layout>
  )
}

export default ResourceAvailabilityView;
