import {API_ACTIVITIES_BILLING_PATH} from "../constants/routes/RoutePaths";
import {ActivityBillingPageData, ActivityBillingRequest, ActivityBillingSearchObject} from "../interfaces/ActivityBllingInterface";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {fileUtils} from "../utils/fileUtils";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";
import {ContentTypeEnum} from "../constants/fileType";

function createActivityBilling(request: ActivityBillingRequest): Promise<Object> {
  const formData = fileUtils.createFormDataForActivityBillingFiles(request);
  return httpService.postFormData(`${API_ACTIVITIES_BILLING_PATH}`, formData);
}

function getActivityBillingPage(params: PaginationQueryParams, filterParams: ActivityBillingSearchObject): Promise<PageResponse<ActivityBillingPageData>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_ACTIVITIES_BILLING_PATH}${queryParams}`)
}

function generateSepaFile(activityBillingIds: string[]): Promise<Blob> {
  return httpService.postReadableStream(`${API_ACTIVITIES_BILLING_PATH}/sepa`, {entityIds: activityBillingIds}, ContentTypeEnum.JSON)
}

export const activityBillingService = Object.freeze({
  createActivityBilling,
  getActivityBillingPage,
  generateSepaFile
});
