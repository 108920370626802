import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {VAT_RATE_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import Button from "../../atoms/Button";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {VATRate} from "../../../interfaces/VATRateInterfaces";
import CreateVATRateForm from "../form/CreateVATRateForm";
import {vatRateService} from "../../../services/VATRateService";
import {toastUtils} from "../../../utils/toastUtils";

interface VATRateListProps {
  className?: string;
}
const VATRateList: FunctionComponent<VATRateListProps> = ({
                                                      className = ""
                                                    }) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const [vatRateFormOpen, setVATRateFormOpen] = useState<boolean>(false)
  const [selectedVATRate, setSelectedVATRate] = useState<VATRate>(null)

  const onVATRateEditionSelection = (vat: VATRate) => {
    vat.rate = vat.rate * 100;
    setSelectedVATRate(vat)
    setVATRateFormOpen(true)
  }

  const onVATRateDeletionSelection = (vat: VATRate) => {
    vatRateService.deleteVATRate(vat.id)
      .then(() => toastUtils.successToast(intl.formatMessage({id : "vat_rate_toast_delete_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id : "vat_rate_toast_delete_error"})))
      .finally(() => ref.current.refresh())
  }

  const columns = useMemo(() => tableUtils.getColumns(
    VAT_RATE_TABLE(onVATRateEditionSelection, onVATRateDeletionSelection, intl)
  ), [intl]);

  const closeForm = () => {
    setVATRateFormOpen(false)
    setSelectedVATRate(null)
  }

  const onCreateVATRate = () => {
    closeForm()
    ref.current.refresh()
  }

  return (
    <>
      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="vat_rate_no_result"
        className={`${className} table`}
        fetchData={vatRateService.getVATRates}
        filters={{}}
      />
      <Button onClick={() => setVATRateFormOpen(true)}>
        {intl.formatMessage({id: "vat_create"})}
      </Button>

      {vatRateFormOpen && (
        <Panel
          formId="createVATRateForm"
          title={selectedVATRate ? "vat_edit" : "vat_create"}
          open={vatRateFormOpen}
          onCancel={() => closeForm()}
        >
          <CreateVATRateForm
            id="createVATRateForm"
            onCreateVATRate={onCreateVATRate}
            initialVATRate={selectedVATRate}
          />
        </Panel>
      )}
    </>
  )
}

export default VATRateList;
