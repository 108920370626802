import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import FormInput from "../input/FormInput";
import EpowForm from "./EpowForm";
import {VAT_RATE_INITIAL_VALUES, VATRate} from "../../../interfaces/VATRateInterfaces";
import {vatRateService} from "../../../services/VATRateService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {CreateVATRateSchema} from "../../../constants/validation/VATRateValidationSchema";

interface CreateVATRateFormProps {
  className?: string;
  id?: string;
  initialVATRate?: VATRate;
  onCreateVATRate?: () => void;
}

const CreateVATRateForm: FunctionComponent<CreateVATRateFormProps> = ({
                                                                  className = "",
                                                                  id,
                                                                  initialVATRate,
                                                                  onCreateVATRate,
                                                                }) => {

  const intl = useIntl();

  const handleEditVATRate = (vatRate: VATRate) => {
    vatRate.rate = vatRate.rate / 100;

    vatRateService.updateVATRate(vatRate)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "vat_rate_toast_edit_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "vat_rate_toast_edit_error"})))
      .finally(() => onCreateVATRate());
  }

  const handleCreateVATRate = (vatRate: VATRate) => {
    vatRate.rate = vatRate.rate / 100;

    vatRateService.createVATRate(vatRate)
      .then(() => toastUtils.successToast(intl.formatMessage({id: "vat_rate_toast_create_success"})))
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "vat_rate_toast_create_error"})))
      .finally(() => onCreateVATRate());
  }

  return (
    <Formik
      initialValues={initialVATRate || VAT_RATE_INITIAL_VALUES}
      validationSchema={CreateVATRateSchema}
      onSubmit={(values) => initialVATRate ? handleEditVATRate(values) : handleCreateVATRate(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="name"
            label="vat_name"
            type="text"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />
          <FormInput
            id="rate"
            label="vat_rate"
            type="number"
            value={values?.rate}
            onChange={(e) => setFieldValue("rate", e.target.value)}
            required
            error={errors.rate}
            touched={touched.rate}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateVATRateForm;
