import React, {ForwardRefRenderFunction, useEffect} from "react";
import {TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps} from "react-table";

interface TableCheckboxProps extends TableToggleAllRowsSelectedProps, TableToggleRowsSelectedProps {
  indeterminate?: boolean;
}

const TableCheckbox: ForwardRefRenderFunction<HTMLInputElement,TableCheckboxProps> = ({
 indeterminate,
 ...rest
}, ref: React.MutableRefObject<HTMLInputElement>) =>
{
  const defaultRef = React.useRef<HTMLInputElement>()
  const resolvedRef = ref || defaultRef

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

return (<input type="checkbox" ref={resolvedRef} {...rest} />)
}
export default React.forwardRef(TableCheckbox);
