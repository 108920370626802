import React, {FunctionComponent, useMemo} from "react";
import {TOUR_LEADER_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import {tourLeaderService} from "services/TourLeaderService";
import PagedTable from "../table/PagedTable";
import {TourLeaderSearchObject} from "../../../interfaces/TourLeaderInterfaces";
import TextFilter from "../filters/TextFilter";
import {useIntl} from "react-intl";
import FilterGroup from "../filters/FilterGroup";
import Button from "../../atoms/Button";
import {customerService} from "../../../services/CustomerService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";

interface TourLeaderListProps {
  className?: string,
  searchObject?: TourLeaderSearchObject,
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const TourLeaderList: FunctionComponent<TourLeaderListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl();

  const columns = useMemo(() => tableUtils.getColumns(TOUR_LEADER_TABLE(intl)), [intl])

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          icon="Search"
          className="main-filter"
          value={searchObject?.nameSurname}
          onChange={(value) => updateSearchField("nameSurname", value)}
          title={intl.formatMessage({id: "tour_leader_search_title"})}
          placeholder={intl.formatMessage({id: "tour_leader_search_placeholder"})}
        />
        <TextFilter
          value={searchObject?.email}
          onChange={(value) => updateSearchField("email", value)}
          title={intl.formatMessage({id: "tour_leader_email_field"})}
          placeholder={intl.formatMessage({id: "tour_leader_email_field"})}
        />
        <AutocompleteFilterPageable
          id="customerId"
          title={intl.formatMessage({id: "dropdown_header_customer"})}
          placeholder={intl.formatMessage({id: "customer_placeholder"})}
          onChange={(value) => updateSearchField("customerId", value)}
          value={searchObject?.customerId}
          fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PagedTable
        columns={columns}
        defaultSortBy={[{sortBy: "identity.lastName"}]}
        labelNoResult="tour_leader_no_result"
        className={`${className} table`}
        fetchData={tourLeaderService.getTourLeaderPage}
        filters={searchObject}
      />
    </>
  )
}

export default TourLeaderList
