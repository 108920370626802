import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Column, TableInstance, useTable, Row, usePagination, useSortBy, useRowSelect } from "react-table";
import { SearchFilter } from "../../../interfaces/api/FiltersInterface";
import { PageResponse, PaginationQueryParams, SortOptions } from "../../../interfaces/api/PaginationInterface";
import { Data } from "../../../interfaces/TableInterfaces";
import Pagination from "../pagination/Pagination";
import TableBody from "./TableBody";
import TableCheckbox from "../../atoms/TableCheckbox";
import usePagedTable from "./usePagedTable";
import ButtonRowSelectAction from "./ButtonRowSelectAction";

interface PagedTableProps {
  columns: Array<Column>,
  isSortable?: boolean,
  className?: string,
  labelNoResult?: string,
  rowProps?: (row: Row) => Object,
  fetchData?: (params: PaginationQueryParams, filterParams?: SearchFilter) => Promise<PageResponse<Data>>,
  afterFetch?: (pageResponse: PageResponse<Data>) => void
  filters?: Object,
  initialPageSize?: number,
  defaultSortBy?: Array<SortOptions>,
  mandatorySortBy?: Array<SortOptions>,
  onRowSelectAction?: (selectedRows: Array<Row<Data>>) => void,
  rowSelectActionTitle?: string,
}

export interface PagedTableRef {
  refresh: () => void,
  data?: Data[]
}

const PagedTableWithRowSelection: ForwardRefRenderFunction<PagedTableRef, PagedTableProps> = ({
  columns,
  fetchData,
  afterFetch,
  className = "",
  labelNoResult = "no_row",
  rowProps = () => ({}),
  filters,
  initialPageSize = 50,
  defaultSortBy = [],
  mandatorySortBy = [],
  onRowSelectAction,
  rowSelectActionTitle
}, ref) => {

  const [pageSize, setPageSize] = useState<number>(initialPageSize)

  const {
    data,
    total,
    page,
    sortOptions,
    refresh,
    onChangePage,
    onChangeSortOptions,
  } = usePagedTable({
    fetchData,
    options: {
      defaultSortBy,
      mandatorySortBy,
      filters,
      pageSize
    },
    afterFetch
  })

  useImperativeHandle(ref, () => ({
    refresh,
    data
  }));

  const tableInstance: TableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize
      }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    hooks =>{
        hooks.visibleColumns.push(columns => [
          {
            id: "selection",
            width: "2%",
            Header: ({ getToggleAllRowsSelectedProps }) => ( 
              <TableCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <TableCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ])
      }
  )
  if (data.length === 0) {
    return (<div><FormattedMessage id={labelNoResult} /></div>)
  }

  return (
    <div className="position-relative">
      <TableBody
        tableInstance={tableInstance}
        className={className}
        rowProps={rowProps}
        sortOptions={sortOptions}
        setSortOptions={onChangeSortOptions}
      />
      {onRowSelectAction && tableInstance.selectedFlatRows?.length > 0 &&
        <ButtonRowSelectAction
          onClick={() => onRowSelectAction(tableInstance.selectedFlatRows)}
          numberOfElements={tableInstance.selectedFlatRows?.length}
          label={rowSelectActionTitle} />
      }
      <Pagination page={page} setPage={onChangePage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default forwardRef(PagedTableWithRowSelection);
