import React, {FunctionComponent, useState} from "react";
import GenericModal from "../GenericModal";
import {useIntl} from "react-intl";
import FormInput from "../../input/FormInput";
import FormRadioLight from "../../input/FormRadioLight";
import {optionUtils} from "../../../../utils/optionUtils";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {OrderItem} from "../../../../interfaces/OrderItemInterfaces";
import {BookingStatus} from "../../../../interfaces/BookingInterfaces";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";

interface ModalOrderItemCancelDeleteProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  cancelOrderItem: (cancellationDate: string) => void,
  deleteOrderItem: () => void,
  orderItem: OrderItem,
}

const ModalOrderItemCancelDelete: FunctionComponent<ModalOrderItemCancelDeleteProps> = ({
  open,
  setOpen,
  cancelOrderItem,
  deleteOrderItem,
  orderItem,
}) => {
  const intl = useIntl()
  const [cancellationDate, setCancellationDate] = useState<string>(null)
  const [isDelete, setIsDelete] = useState<boolean>(false)

  const hasBookingOrStaffing = (): boolean => {
    const hasBooking =  orderItem.bookings?.length > 0 && orderItem.bookings.some(b => b.status !== BookingStatus.TO_DO)
    const hasStaffing =  orderItem.resourceStaffing?.length > 0 && (orderItem.resourceStaffing.some(r => r.status === ActivityStaffingStatus.CONFIRMED) || orderItem.resourceStaffing.some(r => r.status === ActivityStaffingStatus.SENT))
    return hasBooking || hasStaffing
  }

  const IS_DELETE_OPTIONS: Option<string>[] = [
    {label: "cancel_button", value: "false"},
    {label: "delete_button", value: "true"},
  ]

  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_body_cancel_order_item"})}
      open={open}
      closable
      onOpen={setOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: () => {
            isDelete ? deleteOrderItem() : cancelOrderItem(cancellationDate)
            setCancellationDate(null)
          },
          disabled: (isDelete ? hasBookingOrStaffing() : !cancellationDate)
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            setOpen(false)
            setCancellationDate(null)
          }
        }
      }}
    >
      <div className="d-flex flex-column">
        {intl.formatMessage({id: "modal_body_cancel_order_item"})}
        <FormRadioLight
          bold
          id="isDelete"
          value={IS_DELETE_OPTIONS.find(option => option.value === isDelete.toString())}
          options={IS_DELETE_OPTIONS}
          onChange={(option) => setIsDelete(optionUtils.getBoolean(option.value))}
        />

        {isDelete && hasBookingOrStaffing() && (
          <div className="epow-error">
            {intl.formatMessage({id: "modal_body_cancel_order_item_cannot_delete"})}
          </div>
        )}

        {!isDelete && (
          <FormInput
            id="date"
            className="mt-3"
            label="order_item_cancel_date"
            type="date"
            value={cancellationDate}
            onChange={(e) => setCancellationDate(e.target.value)}
            required
          />
        )}
      </div>
    </GenericModal>
  )
}

export default ModalOrderItemCancelDelete;
