import {Customer} from "../../../../interfaces/CustomerInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import Title from "../../../atoms/Title";
import CreateCustomerForm from "../../form/CreateCustomerForm";
import Button from "../../../atoms/Button";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {Col} from "reactstrap";
import CustomerCard from "./CustomerCard";
import useLoading from "../../../../hooks/useLoading";

interface GeneralInfoCustomerCardProps {
  className?: string;
  customer?: Customer;
  setCustomer?: (customer: Customer) => void;
  title: string;
}

const GeneralInfoCustomerCard: FunctionComponent<
  GeneralInfoCustomerCardProps
> = ({ className = "", customer, setCustomer, title }) => {
  const [editInfo, setEditInfo] = useState<boolean>(false);
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size={"h5"} className="mb-3"/>
      {editInfo ? (
        <>
          <CreateCustomerForm
            id="editCustomerForm"
            initialCustomer={customer}
            setCustomer={setCustomer}
            setEditInfo={setEditInfo}
            stopLoading={stopLoading}
            startLoading={startLoading}
          />
          <div>
            <Button color={ColorType.SECONDARY} onClick={() => setEditInfo(false)} className="extended me-3">
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button loading={loading} form={"editCustomerForm"} type="submit" className="extended">
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <CustomerCard
          customer={customer}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
        />
      )}
    </Col>
  );
};

export default GeneralInfoCustomerCard;
