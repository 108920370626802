import {useMemo, useState} from "react";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {PageResponse, PaginationQueryParams, SortOptions} from "../interfaces/api/PaginationInterface";
import {toastUtils} from "../utils/toastUtils";
import {ApiError} from "../interfaces/ErrorInterfaces";

interface usePaginatedDataHook<T> {
  data: T[],
  setData: (data: T[]) => void,
  page: number,
  setPage: (page: number) => void,
  pageSize: number,
  setPageSize: (pageSize: number) => void,
  sortOptions: SortOptions,
  setSortOptions: (sortOption: SortOptions) => void,
  total: number,
  totalElements: number,
  refresh: () => void,
}

function usePaginatedData<T>(
  fetchData: (params: PaginationQueryParams, filters: SearchFilter) => Promise<PageResponse<T>>,
  filters: Object,
  defaultSort = "",
  defaultPageSize = 50,
  defaultDescending = null,
): usePaginatedDataHook<T> {
  const [data, setData] = useState<T[]>([])
  const [page, setPage] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(defaultPageSize)
  const [sortOptions, setSortOptions] = useState<SortOptions>({sortBy: defaultSort, descending: defaultDescending})
  const [total, setTotal] = useState<number>(0)
  const [totalElements, setTotalElements] = useState<number>(0)

  const updateData = (pageableQueryParams: PaginationQueryParams, filters) => {
    fetchData && fetchData(pageableQueryParams, filters)
      .then((pageResponse) => {
        setData(pageResponse?.content ?? [])
        setTotal(pageResponse?.totalPages)
        setTotalElements(pageResponse?.totalElements)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  const refresh = () => {
    const pageableQueryParams = {page, pageSize, sortOptions: [sortOptions]}
    updateData(pageableQueryParams, filters)
  }

  useMemo(() => {
    refresh()
  }, [page, sortOptions, filters, pageSize]);


  return {
    data,
    setData,
    page,
    setPage,
    pageSize,
    setPageSize,
    refresh,
    sortOptions,
    setSortOptions,
    total,
    totalElements,
  };
}

export default usePaginatedData;
