import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {Col} from "reactstrap";
import {numberUtils} from "../../../../utils/numberUtils";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {PurchaseBooking, PurchaseStatus} from "../../../../interfaces/PurchaseInterfaces";
import TextLink from "../../../atoms/TextLink";
import {BOOKINGS_PATH, SERVICES_PATH} from "../../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import BadgedIcon from "../../../icon/BadgedIcon";
import GenericModalDelete from "../../modal/GenericModalDelete";
import {bookingService} from "../../../../services/BookingService";
import {BookingStatus} from "../../../../interfaces/BookingInterfaces";

interface PurchaseBookingPreviewCardHeaderProps {
  className?: string,
  purchaseBooking: PurchaseBooking,
  editPurchaseBooking: (purchaseBooking: PurchaseBooking) => void,
  onDelete: () => void,
  onRowClick?: (orderId: string) => void,
  purchaseStatus?: string
}

const PurchaseBookingPreviewCardHeader: FunctionComponent<PurchaseBookingPreviewCardHeaderProps> = ({
  className = "",
  purchaseBooking,
  editPurchaseBooking,
  onDelete = () => null,
  onRowClick,
  purchaseStatus = ""
}) => {
  const intl = useIntl()
  const navigate = useNavigate();
  const [openModalDeletePurchaseBooking, setOpenModalDeletePurchaseBooking] = useState<boolean>(false)

  const handleModalDeletePurchaseBooking = () => {
    setOpenModalDeletePurchaseBooking(false)
    onDelete()
  }

  return (
    <div className={`epow-custom-table-row-main ${className}`} onClick={() => onRowClick(purchaseBooking.id)}>
      <Col style={{minWidth: "25%"}}>
        <TextLink to={`${SERVICES_PATH}/${purchaseBooking?.serviceId}`}>
          <>{purchaseBooking?.serviceName}</>
        </TextLink>
      </Col>
      <Col style={{minWidth: "20%"}}>
        {purchaseBooking?.referenceCustomer}
      </Col>
      <Col>
        {numberUtils.formatPrice(purchaseBooking?.price)}
      </Col>
      <Col>
        {purchaseBooking?.quantity}
      </Col>
      <Col>
        {numberUtils.numberToPercent(purchaseBooking?.vat) ?? "-"}
      </Col>
      <Col>
        {numberUtils.formatPrice(purchaseBooking?.totalPrice)}
      </Col>
      <Col>
        {purchaseBooking.isPaid ? "Oui" : "Non"}
      </Col>
      <Col>
        <div className="float-end">
          {purchaseStatus === PurchaseStatus.SENT &&
            <BadgedIcon
              name="PaperClip"
              className={"cursor-pointer"}
              badge={purchaseBooking?.documentCount > 0}
              onClick={() => navigate(`${BOOKINGS_PATH}?${purchaseBooking.status === BookingStatus.CLOSED ? "displayClosed" : "displayPending"}=true&bookingId=${purchaseBooking.id}`)}>
                {purchaseBooking?.documentCount}
            </BadgedIcon>
          }
          <Icon
            name="Pen"
            size={IconSizeType.XS}
            className={"cursor-pointer pe-2"}
            onClick={() => editPurchaseBooking(purchaseBooking)}
          />
          <Icon
            name="Trash"
            size={IconSizeType.XS}
            className={"cursor-pointer pe-2"}
            onClick={() => setOpenModalDeletePurchaseBooking(true)}
          />
        </div>
      </Col>
      <GenericModalDelete
        titleId="modal_title_delete_booking"
        open={openModalDeletePurchaseBooking}
        onValidate={handleModalDeletePurchaseBooking}
        setOpen={setOpenModalDeletePurchaseBooking}
        idEntity={purchaseBooking?.id}
        onDelete={bookingService.deleteBooking}
        successToastMessageId="success_toast_delete_booking"
        errorToastMessageId="error_toast_delete_booking"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_booking"}, {serviceName: purchaseBooking?.serviceName})}
      />
    </div>
  )
}

export default PurchaseBookingPreviewCardHeader
