import {Option} from "../interfaces/inputs/OptionInterfaces";


export const enum ResourceDisplayOption {
  NO_DISPLAY = "NO_DISPLAY",
  DISPLAY_NAME = "DISPLAY_NAME",
  DISPLAY_NAME_PHONE = "DISPLAY_NAME_PHONE"
}
export const RESOURCE_DISPLAY_OPTIONS : Option<string>[] = [
  { value: ResourceDisplayOption.NO_DISPLAY, label: "resource_display_option_none" },
  { value: ResourceDisplayOption.DISPLAY_NAME, label: "resource_display_option_name" },
  { value: ResourceDisplayOption.DISPLAY_NAME_PHONE, label: "resource_display_option_name_phone" },
]
