import { centerCrop, makeAspectCrop, PixelCrop } from "react-image-crop"

/**
 * Draw a cropped image from the original image and crop selection inside a canvas element
 *
 * @param image the original image DOM element
 * @param canvas the canvas DOM element
 * @param crop the crop element from react-image-crop
 */
export const canvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop
): void => {
  const ctx = canvas.getContext("2d")

  if (!ctx) {
    throw new Error("No 2d context")
  }

  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const pixelRatio = window.devicePixelRatio

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  ctx.scale(pixelRatio, pixelRatio)
  ctx.imageSmoothingQuality = "high"

  const cropX = crop.x * scaleX
  const cropY = crop.y * scaleY
  ctx.translate(-cropX, -cropY)

  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  )
}

/**
 * Create a centered cropping rectangle at the center of an element
 * @param mediaWidth width of the image
 * @param mediaHeight heigth of the image
 * @param aspect aspect ratio of the cropping element
 * @returns the centered cropping element
 */
export const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
): PixelCrop => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px",
        width: mediaWidth,
        height: mediaHeight
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  )
}

const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob> => {
  return new Promise((resolve) => {
    canvas.toBlob(resolve)
  })
}

export const canvasToFile = (image: HTMLCanvasElement, fileName: string): Promise<File> => {
  return canvasToBlob(image).then(blob => {
    const previewUrl = URL.createObjectURL(blob)
    return fetch(previewUrl)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], fileName, { type: "image/png" }))
      .catch((e) => e)
  })
}
