import {TourLeader, TourLeaderFormFields} from "../interfaces/TourLeaderInterfaces";

export const TOUR_LEADER_INITIAL_VALUES : TourLeader = {
  identity: {
    firstName: "",
    lastName: "",
    email:""
  }
}

export const TOUR_LEADER_DETAILS_VALUES : TourLeaderFormFields = {
  identity: {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    otherPhoneNumber: "",
    email:"",
  },
  customersOptions: [],
  hasAccessPlatform: ""
}