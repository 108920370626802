import {API_ACTIVITY_STAFFING_PATH} from "../constants/routes/RoutePaths"
import {ActivityStaffing, StaffedResource} from "../interfaces/ActivityStaffingInterface"
import {httpService} from "./HttpService"
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {OrderItemDayStaffingPageItemDto, OrderItemSearchObject, OrderItemStaffingRequest} from "../interfaces/OrderItemInterfaces";
import {queryUtils} from "../utils/queryUtils";

function validateStaffing (staffingToken: string): Promise<ActivityStaffing> {
  return httpService.put<ActivityStaffing>(`${API_ACTIVITY_STAFFING_PATH}/validate/${staffingToken}`, {})
}

function getOrderItemsStaffingRequest(params: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemStaffingRequest>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<OrderItemStaffingRequest>>(`${API_ACTIVITY_STAFFING_PATH}${queryParams}`)
}

function availableOrderItemsStaffingRequest(activityStaffing: string): Promise<StaffedResource> {
  return httpService.post<StaffedResource>(`${API_ACTIVITY_STAFFING_PATH}/${activityStaffing}/available`, {})
}

function refuseOrderItemsStaffingRequest(activityStaffing: string): Promise<Response> {
  return httpService.delete(`${API_ACTIVITY_STAFFING_PATH}/${activityStaffing}`)
}


function getStaffedOnDay(params: PaginationQueryParams, filterParams: OrderItemSearchObject): Promise<PageResponse<OrderItemDayStaffingPageItemDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`${API_ACTIVITY_STAFFING_PATH}/day${queryParams}`)
}

export const activityStaffingService = Object.freeze({
  validateStaffing,
  getOrderItemsStaffingRequest,
  availableOrderItemsStaffingRequest,
  refuseOrderItemsStaffingRequest,
  getStaffedOnDay,
})
