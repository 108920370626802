import React, {FunctionComponent} from "react";
import {DashboardActivity} from "../../../../interfaces/ActivityInterfaces";
import NextActivities from "../../dashboard/NextActivities";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import ActivityPanel from "../activity/ActivityPanel";

interface DashboardNextActivitiesCardProps {
  className?: string,
  activities: DashboardActivity[],
  profile: PROFILE,
}

const DashboardNextActivitiesCard: FunctionComponent<DashboardNextActivitiesCardProps> = ({
  className = "",
  activities,
  profile,
}) => {

  const [panelOpen, setPanelOpen] = React.useState<boolean>(false)
  const [orderItemId, setOrderItemId] = React.useState<string>()

  const showActivityPanel = (orderItemId: string) => {
    setOrderItemId(orderItemId)
    setPanelOpen(true);
  }

  return (
    <div className={className}>
      <NextActivities
        activities={activities}
        onClickActivity={showActivityPanel}
      />

      <ActivityPanel
        open={panelOpen} 
        setOpen={setPanelOpen}
        profile={profile}
        orderItemId={orderItemId}
      />
    </div>
  )
}

export default DashboardNextActivitiesCard;
