import {CustomerServiceInfoCatalog} from "../../../interfaces/ServiceInterfaces";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {serviceService} from "../../../services/ServiceService";
import {toastUtils} from "../../../utils/toastUtils";
import {Formik} from "formik";
import {CUSTOMER_SERVICE_INFO_CATALOG_INITIAL_VALUES} from "../../../constants/ServiceConstants";
import {CreateCustomerServiceInfoCatalogSchema} from "../../../constants/validation/ServiceValidationSchemas";
import FormInput from "../input/FormInput";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import FormRadio from "../input/FormRadio";
import {dateUtils} from "../../../utils/dateUtils";
import PriceInput from "../input/PriceInput";
import EpowForm from "./EpowForm";

interface CreateCustomerServiceInfoCatalogFormProps {
  id?: string,
  className?: string,
  customerServiceId?: string,
  initialCustomerServiceInfoCatalog?: CustomerServiceInfoCatalog,
  onValidate?: () => void
}

const CreateCustomerServiceInfoCatalogForm: FunctionComponent<CreateCustomerServiceInfoCatalogFormProps> = ({
  id,
  className = "",
  customerServiceId,
  initialCustomerServiceInfoCatalog,
  onValidate = () => null,
}) => {
  const intl = useIntl()
  const customerServiceInfoCatalog = initialCustomerServiceInfoCatalog ? {
    ...initialCustomerServiceInfoCatalog,
    startDateValidity: dateUtils.formatDateYYYYMMDD(dateUtils.convertStringToObjectDate(initialCustomerServiceInfoCatalog?.startDateValidity))
  } : null

  const handleSubmitCustomerServiceInfoCatalog = (customerServiceInfoCatalog: CustomerServiceInfoCatalog) => {
    if (initialCustomerServiceInfoCatalog?.id) {
      serviceService.updateCustomerServiceCatalog(
        initialCustomerServiceInfoCatalog.id,
        {...customerServiceInfoCatalog, customerServiceId: customerServiceId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_customer_service_info_catalog"}))
        onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_customer_service_info_catalog"}))
      })
    } else {
      serviceService.createCustomerServiceCatalog(
        {...customerServiceInfoCatalog, customerServiceId: customerServiceId}
      ).then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_customer_service_info_catalog"}))
        onValidate()
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_customer_service_info_catalog"}))
      })
    }
  }

  return (
    <Formik
      initialValues={customerServiceInfoCatalog || CUSTOMER_SERVICE_INFO_CATALOG_INITIAL_VALUES}
      validationSchema={CreateCustomerServiceInfoCatalogSchema}
      onSubmit={handleSubmitCustomerServiceInfoCatalog}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="startDateValidity"
            label="activity_start_date_validity"
            type="date"
            required
            value={values?.startDateValidity}
            onChange={(e) => setFieldValue("startDateValidity", e.target?.value)}
            error={errors?.startDateValidity}
            touched={touched?.startDateValidity}
          />
          <FormRadio
            id="unitType"
            label="service_unit_type"
            value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
            options={UNIT_TYPE_OPTIONS}
            onChange={(e) => setFieldValue("unitType", e.value)}
            required
            error={errors.unitType}
            touched={touched.unitType}
          />
          <FormInput
            id="groupSize"
            label="service_group_size"
            type="number"
            value={values?.groupSize}
            onChange={(e) => setFieldValue("groupSize", e.target.value)}
            required
            error={errors?.groupSize}
            touched={touched?.groupSize}
          />
          <PriceInput
            id="unitPriceExcludingTax"
            label="service_unit_price_excluding_tax"
            value={values?.unitPriceExcludingTax}
            onChange={(e) => setFieldValue("unitPriceExcludingTax", e.target.value)}
            error={errors?.unitPriceExcludingTax}
            touched={touched?.unitPriceExcludingTax}
          />
          <FormRadio
            id="sellUnitType"
            label="service_unit_sell_type"
            value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.sellUnitType?.toString())}
            options={UNIT_TYPE_OPTIONS}
            onChange={(e) => setFieldValue("sellUnitType", e.value)}
            required
            error={errors.sellUnitType}
            touched={touched.sellUnitType}
          />
          <PriceInput
            id="sellPriceExcludingTax"
            label="service_sell_price_excluding_tax"
            value={values?.sellPriceExcludingTax}
            onChange={(e) => setFieldValue("sellPriceExcludingTax", e.target.value)}
            required
            error={errors?.sellPriceExcludingTax}
            touched={touched?.sellPriceExcludingTax}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateCustomerServiceInfoCatalogForm
