import * as Yup from "yup";
import {yupRequiredArray, yupRequiredString} from "../yup/Validators";

export const EditUserProfileSchema = Yup.object().shape({
  email: yupRequiredString,
  firstName: yupRequiredString,
  lastName: yupRequiredString
})

export const EditUserSchema = EditUserProfileSchema.shape({
  profile: yupRequiredArray(1),
})
