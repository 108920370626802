import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {Col, Row} from "reactstrap";
import FormSelect from "../input/FormSelect";
import {
  CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS,
  CUSTOMER_SORT_INVOICE_OPTIONS,
  EU_CUSTOMER, FRENCH_CUSTOMER,
  INVOICE_OPERATION_CUSTOMER_INITIAL_VALUES
} from "../../../constants/CustomerConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormInput from "../input/FormInput";
import FormRadio from "../input/FormRadio";
import {
  PAYMENT_CONDITION_OPTIONS,
  PUBLIC_TYPE_CUSTOMER_OPTIONS,
  TRUE_FALSE_ENUM,
  YES_NO_OPTIONS
} from "../../../constants/OptionConstants";
import Title from "../../atoms/Title";
import {CreateInvoiceOperationCustomerSchema} from "../../../constants/validation/CustomerValidationSchemas";
import {useIntl} from "react-intl";
import {Customer, InvoiceOperationCustomerFields} from "../../../interfaces/CustomerInterfaces";
import {customerService} from "../../../services/CustomerService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntlOptions} from "../../../hooks/useIntlOptions";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import EpowForm from "./EpowForm";
import {FormikFieldSetter} from "../../../interfaces/FormikInterfaces";
import {PublicType} from "../../../constants/ResourceConstants";

interface CreateInvoiceOperationCustomerFormProps {
  id?: string,
  className?: string,
  customerOptions?: Option<string>[],
  setCustomerOptions?: (options: Option<string>[]) => void,
  customer?: Customer
  setEditInfo?: (editInfo: boolean) => void,
  setCustomer?: (customer: Customer) => void,
}

const CreateInvoiceOperationCustomerForm: FunctionComponent<CreateInvoiceOperationCustomerFormProps> = ({
  id,
  className = "",
  customerOptions,
  setCustomerOptions,
  customer,
  setEditInfo,
  setCustomer,
}) => {
  const intl = useIntl()
  const geographicalZoneOptions = useIntlOptions(intl, CUSTOMER_GEOGRAPHICAL_AREA_OPTIONS)
  const sortInvoiceOptions = useIntlOptions(intl, CUSTOMER_SORT_INVOICE_OPTIONS)
  const translatedPaymentOptions = PAYMENT_CONDITION_OPTIONS.map(option => ({...option, label: intl.formatMessage({id: option.label})}))

  const handleSubmitInvoiceOperationCustomer = (invoiceOperationFields: InvoiceOperationCustomerFields) => {
    const invoiceOperationRequest = customerService.buildInvoiceOperationRequest(invoiceOperationFields, customer)
    customerService.updateCustomer(customer.id, invoiceOperationRequest)
      .then((updatedCustomer) => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_customer"}))
        setEditInfo(false)
        setCustomer(updatedCustomer)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_customer"}))
      })
  }
  const initialCustomerFields = customerService.buildInvoiceOperationFields(customer);

  const handleOnChange = (selectedOptions: Option<string>[], setFieldValue: (field: string, values: Option<string>[], shouldValidate?: boolean) => void) => {
    setFieldValue("billingServices", selectedOptions)
    setCustomerOptions(selectedOptions)
  }

  const handleIndividualOnChange = (option: Option<string>, setFieldValue: FormikFieldSetter) => {
    setFieldValue("individual", option.value)
    if (option.value === TRUE_FALSE_ENUM.TRUE) {
      setFieldValue("geographicalZone", FRENCH_CUSTOMER)
      setFieldValue("numberIntraVAT", "")
      setFieldValue("publicType", PublicType.PRIVATE)
      setFieldValue("isReservationManagement", TRUE_FALSE_ENUM.TRUE)
      setFieldValue("disbursement", TRUE_FALSE_ENUM.TRUE)
    }
  }

  return (
    <Formik initialValues={initialCustomerFields || INVOICE_OPERATION_CUSTOMER_INITIAL_VALUES} validationSchema={CreateInvoiceOperationCustomerSchema} onSubmit={value => {handleSubmitInvoiceOperationCustomer(value)}}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`d-flex flex-row ${className}`}>
          <Row className="mb-4 w-100">
            <Col xs={24} md={16}>
              <div className="mb-3">
                <Title title={intl.formatMessage({id: "invoicing"})} size={"h5"} />
              </div>
              <Row>
                <Col xs={24} md={12}>
                  <FormRadio
                      id="individual"
                      label="customer_individual_field"
                      value={YES_NO_OPTIONS.find((option) => option?.value === values?.individual)}
                      options={YES_NO_OPTIONS}
                      onChange={(value) => handleIndividualOnChange(value, setFieldValue)}
                      required
                      error={errors.individual}
                      touched={touched.individual}
                  />
                  <FormSelect
                    id="geographicalZone"
                    label="customer_geographical_zone_field"
                    value={geographicalZoneOptions.find((option) => option?.value === values?.geographicalZone)}
                    onChange={(option?: Option<string>) => setFieldValue("geographicalZone", option?.value)}
                    options={geographicalZoneOptions}
                    required
                    isSearchable
                    isClearable
                    error={errors?.geographicalZone}
                    touched={touched?.geographicalZone}
                  />
                  <FormInput
                    id="numberIntraVAT"
                    label="customer_number_intra_vat_field"
                    value={values?.numberIntraVAT}
                    onChange={(e) => setFieldValue("numberIntraVAT", e.target.value)}
                    required={values.geographicalZone === EU_CUSTOMER}
                    error={errors.numberIntraVAT}
                    touched={touched.numberIntraVAT}
                  />
                  <FormRadio
                    id="invoiceForCustomer"
                    label="customer_invoice_field"
                    value={YES_NO_OPTIONS.find((option) => option?.value === values?.invoiceForCustomer)}
                    options={YES_NO_OPTIONS}
                    onChange={(option?: Option<string>) => setFieldValue("invoiceForCustomer", option?.value)}
                    required
                    error={errors.invoiceForCustomer}
                    touched={touched.invoiceForCustomer}
                  />
                  <FormAutocompleteMultiselect
                    id="billingServices"
                    label={intl.formatMessage({id: "customer_billing_services_field"})}
                    values={values.billingServices}
                    onChange={(selectedOptions) => handleOnChange(selectedOptions, setFieldValue)}
                    required
                    error={errors?.billingServices}
                    touched={touched?.billingServices}
                    fetchData={(inputValue) => customerService.getActiveCustomers({}, {name: inputValue, id: customer.id})}
                    filterFieldName="fullName"
                  />
                  <FormSelect
                    id="defaultBillingServiceId"
                    label="customer_default_billing_service_field"
                    value={customerOptions.find((option) => option?.value === values?.defaultBillingServiceId)}
                    onChange={(option?: Option<string>) => setFieldValue("defaultBillingServiceId", option?.value ?? null)}
                    options={customerOptions}
                    required
                    isSearchable
                    isClearable
                    error={errors?.defaultBillingServiceId}
                    touched={touched?.defaultBillingServiceId}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <FormRadio
                    id="isVoucherManagement"
                    label="customer_voucher_management_field"
                    value={YES_NO_OPTIONS.find((option) => option?.value === values?.isVoucherManagement)}
                    options={YES_NO_OPTIONS}
                    onChange={(option?: Option<string>) => setFieldValue("isVoucherManagement", option?.value)}
                    required
                    error={errors.isVoucherManagement}
                    touched={touched.isVoucherManagement}
                  />
                  <FormRadio
                    id="disbursement"
                    label="customer_disbursement_field"
                    value={YES_NO_OPTIONS.find((option) => option?.value === values?.disbursement)}
                    options={YES_NO_OPTIONS}
                    onChange={(option?: Option<string>) => setFieldValue("disbursement", option?.value)}
                    required
                    error={errors.disbursement}
                    touched={touched.disbursement}
                  />
                  <FormSelect
                    id="mainSortInvoice"
                    label="customer_main_sort_invoice_field"
                    value={sortInvoiceOptions.find((option) => option?.value === values?.mainSortInvoice)}
                    onChange={(option?: Option<string>) => setFieldValue("mainSortInvoice", option?.value)}
                    options={sortInvoiceOptions}
                    isSearchable
                    isClearable
                    error={errors?.mainSortInvoice}
                    touched={touched?.mainSortInvoice}
                  />
                  <FormSelect
                    id="secondarySortInvoice"
                    label="customer_secondary_sort_invoice_field"
                    value={sortInvoiceOptions.find((option) => option?.value === values?.secondarySortInvoice)}
                    onChange={(option?: Option<string>) => setFieldValue("secondarySortInvoice", option?.value)}
                    options={sortInvoiceOptions}
                    isSearchable
                    isClearable
                    error={errors?.secondarySortInvoice}
                    touched={touched?.secondarySortInvoice}
                  />
                  <FormSelect
                    id="paymentCondition"
                    label="customer_payment_condition"
                    value={translatedPaymentOptions.find((option) => option?.value === values?.paymentCondition)}
                    onChange={(option?: Option<string>) => setFieldValue("paymentCondition", option?.value)}
                    options={translatedPaymentOptions}
                    isSearchable
                    isClearable
                    error={errors?.paymentCondition}
                    touched={touched?.paymentCondition}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={8}>
              <div className="mb-3">
                <Title title={intl.formatMessage({id: "operation"})} size={"h5"} />
              </div>
              <FormRadio
                id="publicType"
                label="customer_public_type"
                value={PUBLIC_TYPE_CUSTOMER_OPTIONS.find((option) => option?.value === values?.publicType)}
                options={PUBLIC_TYPE_CUSTOMER_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("publicType", option?.value)}
                required
                error={errors.publicType}
                touched={touched.publicType}
              />
              <FormRadio
                id="isReservationManagement"
                label="customer_reservation_management_field"
                value={YES_NO_OPTIONS.find((option) => option?.value === values?.isReservationManagement)}
                options={YES_NO_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("isReservationManagement", option?.value)}
                required
                error={errors.isReservationManagement}
                touched={touched.isReservationManagement}
              />
              <FormInput
                id="numberWeeksViewed"
                label="customer_number_weeks_viewed_field"
                type="number"
                value={values?.numberWeeksViewed}
                minValue={0}
                onChange={(e) => setFieldValue("numberWeeksViewed", e.target.value)}
                required
                error={errors.numberWeeksViewed}
                touched={touched.numberWeeksViewed}
              />
              <FormRadio
                id="notificationForActivity"
                label="customer_notification_activity_field"
                value={YES_NO_OPTIONS.find((option) => option?.value === values?.notificationForActivity)}
                options={YES_NO_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("notificationForActivity", option?.value)}
                required
                error={errors.notificationForActivity}
                touched={touched.notificationForActivity}
              />
              <FormRadio
                id="notificationForReplacement"
                label="customer_notification_replacement_field"
                value={YES_NO_OPTIONS.find((option) => option?.value === values?.notificationForReplacement)}
                options={YES_NO_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("notificationForReplacement", option?.value)}
                required
                error={errors.notificationForReplacement}
                touched={touched.notificationForReplacement}
              />
            </Col>
          </Row>
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateInvoiceOperationCustomerForm
