import { COUNTRY_OPTIONS } from "constants/CountryConstants";
import { Structure } from "interfaces/StructureInterface";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import Popover from "../../../atoms/Popover";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";

interface ResourceStructureCardProps {
  className?: string;
  structure?: Structure;
  self?: boolean;
}

const ResourceStructureCard: FunctionComponent<ResourceStructureCardProps> = ({
  className = "",
  structure,
  self = false
}) => {

  const displayStructureInfo = () => {
    const country = COUNTRY_OPTIONS.find(
      (option) => option.value === structure.address.country
    );
    const address = structure.address;
    return (
      <div>
        <span>{structure.name}</span><br/>
        <span>{address.mainLabel}</span><br/>
        <span>{!!address.additionalInformation ? address.additionalInformation : "-"}</span><br/>
        <span>{`${address.zipCode} ${address.city}, ${country?.label}`}</span><br/>
        <span>{structure.phoneNumber}</span>
      </div>
    );
  };

  return (
    <div className={`${className} d-flex flex-column`}>
      <span className="text-muted d-flex">
        <span className="me-2"><FormattedMessage id="resource_structure_field" /></span>
        {self && <Popover size={IconSizeType.XS} text="resource_structure_field_popover"/>}
      </span>
      <span className="epow-info">
        {structure ? displayStructureInfo() : "-"}
      </span>
    </div>
  );
};

export default ResourceStructureCard;
