import {StaffedResource} from "../interfaces/ActivityStaffingInterface"
import {OrderItemStaffingPageItemDto} from "../interfaces/OrderItemInterfaces"
import {orderItemUtils} from "./orderItemUtils"

const getSortedResources = (orderItem: OrderItemStaffingPageItemDto): StaffedResource[] => {
  const resources = orderItem.resourceStaffing
  resources.sort(orderItemUtils.sortStaffedResourceByAvailability)
  return resources
}

export const staffingUtils = Object.freeze({
  getSortedResources,
})