import React, {FunctionComponent} from "react";
import {CircleColor} from "../../constants/CircleConstants";
import {SizeType} from "../../types/bootstrap/BootstrapType";

interface CircleProps {
  className?: string;
  color?: CircleColor;
  manualColor?: string;
  children?: React.ReactElement
  size?: SizeType
}

const Circle: FunctionComponent<CircleProps> = ({
  className,
  color= CircleColor.GREEN,
  manualColor,
  children,
  size = SizeType.SM
}) => {
  return (
    <div
      className={`circle ${manualColor ? "" : color} ${className ?? ""} ${size}`}
      style={manualColor ? {backgroundColor: manualColor} : {}}
    >
      { children && children }
    </div>
  )
}

export default Circle;
