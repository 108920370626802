import React, {FunctionComponent, useState} from "react";
import {TourActivity} from "../../../interfaces/TourInterfaces";
import {FormattedMessage} from "react-intl";
import ActivityInTourCard from "../card/activity/ActivityInTourCard";
import {Label} from "reactstrap";
import Icon from "../../icon/Icon";
import Toggle from "../../atoms/Toggle";

interface TourActivityListProps {
  className?: string,
  tourActivities?: TourActivity[],
  onValidate?: () => void
}

const TourActivityList: FunctionComponent<TourActivityListProps> = ({
  className = "",
  tourActivities,
  onValidate
}) => {
  const [openAll, setOpenAll] = useState<boolean>(false);

  return (
    <div className={className}>
      <div className="d-flex align-items-center bold justify-content-end mb-3">
        <FormattedMessage id="activity_tour_expand_all" />
        <Toggle className="ms-2" id="activity_tour_expand_all" onChange={(checked) => setOpenAll(checked)} />
      </div>
      {tourActivities.length > 0 ? tourActivities.map((tourActivity, key) =>
        <ActivityInTourCard
          key={key}
          tourActivityCustomer={tourActivity}
          activityName={tourActivity?.activityName}
          activityId={tourActivity?.activityId}
          onValidate={onValidate}
          openAll={openAll}
        />
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2"/>
          <FormattedMessage id="activity_tour_not_found"/>
        </Label>
      )}
    </div>
  )
}

export default TourActivityList
