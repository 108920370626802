import React, {FunctionComponent, useCallback, useState} from "react";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {Data} from "../../../interfaces/TableInterfaces";
import AutocompleteFilter from "./AutocompleteFilter";

export interface AutocompleteFilterPageableProps {
  className?: string,
  title?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  fetchData: (page, filter: SearchFilter) => Promise<PageResponse<Data>>,
  manageOptions?: (datas: Data[]) => Option<string>[]
  optionFromValue?: (datas: Data[], value: string) => Option<string>
  filterFieldName: string,
  filterFieldSearch?: string,
  id?: string,
  page?: number,
  pageSize?: number,
  initialFilter?: SearchFilter,
  readOnly?: boolean,
}

const AutocompleteFilterPageable: FunctionComponent<AutocompleteFilterPageableProps> = ({
  className = "",
  title,
  placeholder,
  onChange,
  onBlur,
  value,
  fetchData: fetchDataProps,
  initialFilter,
  filterFieldName,
  filterFieldSearch = filterFieldName,
  id,
  page = 0,
  pageSize = 50,
  readOnly = false,
  manageOptions,
  optionFromValue
}) => {

  const [total, setTotal] = useState<number>();

  const fetchData = useCallback((filters) => new Promise<Data[]>((resolve, reject) => {
    fetchDataProps({page, pageSize}, filters).then(response => {
      resolve(response.content)
      setTotal(response.totalElements)
    })
      .catch(reject)
  }), [fetchDataProps, page, pageSize]);

  return (<AutocompleteFilter
      onChange={onChange}
      fetchData={fetchData}
      id={id}
      totalElements={total}
      filterFieldName={filterFieldName}
      filterFieldSearch={filterFieldSearch}
      readOnly={readOnly}
      onBlur={onBlur}
      placeholder={placeholder}
      title={title}
      pageSize={pageSize}
      className={className}
      optionFromValue={optionFromValue}
      manageOptions={manageOptions}
      initialFilter={initialFilter}
      value={value}
    />
  )
}
export default AutocompleteFilterPageable;
