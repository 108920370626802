import React, {FunctionComponent} from "react";
import {UncontrolledTooltip} from "reactstrap";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";
import {ResourceCalendarColumn, ResourceCalendarResourceStatus, ResourceCalendarRow} from "../../../../interfaces/ResourceCalendarInterfaces";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../../utils/dateUtils";
import IconLabelCard from "../../../atoms/IconLabelCard";
import Icon from "../../../icon/Icon";
import {useIntl} from "react-intl";
import {StaffedResource} from "../../../../interfaces/ActivityStaffingInterface";
import Button from "../../../atoms/Button";

interface ResourceCalendarCellPanelBodyProps {
  activity: ResourceCalendarRow,
  resource: ResourceCalendarColumn,
  staffings: StaffedResource[],
  status: ResourceCalendarResourceStatus,
  setStep: (step: number) => void,
  sentStaffing: (status: ActivityStaffingStatus) => void,
  directStaffing: () => void,
}

const ResourceCalendarCellPanelBody: FunctionComponent<ResourceCalendarCellPanelBodyProps> = ({
  activity,
  resource,
  staffings,
  status,
  setStep,
  sentStaffing,
  directStaffing,
}) => {
  const intl = useIntl()

  const getHeaderText = () => {
    const place = activity.place
    const hour = dateUtils.formatLocalTime(activity.rendezVousHour)
    const endHour = dateUtils.formatLocalTime(activity.rendezVousEndHour)

    return `${place} | ${hour} - ${endHour}`
  }

  const getTooltip = () => {
    const available = staffings.filter(resource => resource.status === ActivityStaffingStatus.AVAILABLE)
    return (
      <>
        {available?.length === 0 && (
          <span>{intl.formatMessage({id: "calendar_day_panel_to_staff_tooltip_no_available"})}</span>
        )}
        {available?.map(resource => <div key={resource.resourceId}>
          <span>{`${resource.firstName} ${resource.lastName.charAt(0)}`}</span>
        </div>)}
      </>
    )
  }

  return (
    <div className="w-100 ">
      <div className="d-flex gap-2 mb-2">
        <IconLabelCard iconName="Calendar" label={dateUtils.formatToLocalDateString(dateUtils.parseDate(activity.rendezVousDate))} hasBackground />
        <IconLabelCard iconName="User" label={resource.name} hasBackground />
      </div>

      <div className={"border br-sm p-2 mb-2"}>
        <div>
          {getHeaderText()}
        </div>

        <div className="mb-2">
          <span className="color-gray me-2">{`${activity.customerName} | ${activity.orderReference}`}</span>
          <span className="bold fs-14">{activity.name}</span>
        </div>

        <div className="d-flex gap-3 mb-2">
          <div className="d-flex flex-column">
            {staffings.filter(resource => resource.status === ActivityStaffingStatus.CONFIRMED)?.map(staffing => (
              <div key={staffing.staffingId}>
                <div>
                  <Icon name="User" className="me-1" color={ColorType.GREEN} />
                  <span className="color-green">
                    {`${staffing.firstName} ${staffing.lastName[0]}`}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div id={`peopleLeftToStaff-${activity.id}`}>
            <Icon name="User" className="me-1" />
            <span >
              {intl.formatMessage({id: "calendar_day_panel_to_staff"}, {number: (activity.numberToStaff - activity.staffed)})}
            </span>
            <UncontrolledTooltip className="epow-tooltip" placement="bottom" target={`peopleLeftToStaff-${activity.id}`}>
              {getTooltip()}
            </UncontrolledTooltip>
          </div>
        </div>

        <div className="d-flex gap-2 justify-content-end">
          {ResourceCalendarResourceStatus.AVAILABLE === status && (
            <Button className="py-0" onClick={() => sentStaffing(ActivityStaffingStatus.CONFIRMED)} >
              <span>{intl.formatMessage({id: "calendar_day_panel_staffing_confirm"})}</span>
            </Button>
          )}
          {ResourceCalendarResourceStatus.SENT === status && (
            <>
              <Button className="py-0" color={ColorType.SECONDARY} onClick={() => sentStaffing(ActivityStaffingStatus.SENT)} >
                  <span>{intl.formatMessage({id: "calendar_day_panel_staffing_send_availability"})}</span>
                </Button>
              <Button color={ColorType.SECONDARY} className="py-0" onClick={() => sentStaffing(ActivityStaffingStatus.CONFIRMED)} >
                <span>{intl.formatMessage({id: "calendar_day_panel_staffing_staff"})}</span>
              </Button>
            </>
          )}
          {ResourceCalendarResourceStatus.FREE === status && (
            <>
              <Button className="py-0" color={ColorType.SECONDARY} onClick={() => setStep(1)} >
                <span>{intl.formatMessage({id: "calendar_day_panel_staffing_ask_availability"})}</span>
              </Button>
              <Button className="py-0" color={ColorType.SECONDARY} onClick={() => directStaffing()} >
                <span>{intl.formatMessage({id: "calendar_day_panel_staffing_staff"})}</span>
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResourceCalendarCellPanelBody;
