import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Accordion from "../accordion/Accordion";
import ActivityInTourCard from "../card/activity/ActivityInTourCard";
import {CustomerCatalog} from "../../../interfaces/TourInterfaces";
import {Label} from "reactstrap";
import Icon from "../../icon/Icon";
import {CUSTOMERS_PATH} from "../../../constants/routes/RoutePaths";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import FieldLabel from "../../atoms/FieldLabel";
import {dateUtils} from "../../../utils/dateUtils";
import Panel from "../panel/Panel";
import UpdateDayByDayClientForm from "../form/UpdateDayByDayClientForm";
import {dayByDayClientService} from "../../../services/DayByDayClientService";
import GenericModalDelete from "../modal/GenericModalDelete";

interface TourCustomerListProps {
  className?: string,
  customersCatalog?: CustomerCatalog[],
  openAll?: boolean,
  onValidate?: () => void
}

const TourCustomerList: FunctionComponent<TourCustomerListProps> = ({
  className = "",
  customersCatalog,
  openAll,
  onValidate
}) => {
  const intl = useIntl()

  const [catalogIdToUpdate, setCatalogIdToUpdate] = useState<string>(undefined)
  const [catalogIdToDelete, setCatalogIdToDelete] = useState<string>(undefined)

  const handleModalDeleteDayByDayClient = () => {
    setCatalogIdToDelete(undefined)
    onValidate()
  }

  const handleEditDayByDayClient= () => {
    setCatalogIdToUpdate(undefined)
    onValidate()
  }

  const setModalOpen = (value: boolean) => {
    if (!value) {
      setCatalogIdToDelete(undefined)
    }
  }

  return (
    <div className={className}>
      {customersCatalog?.length > 0 ? customersCatalog.map((customerCatalog, key) =>
        <Accordion
          className="secondary"
          title={customerCatalog?.customer?.name}
          titleHref={`${CUSTOMERS_PATH}/${customerCatalog?.customer?.id}`}
          key={key}
          startOpen={openAll}
        >
          <div className="d-flex flex-row flex-1 flex-wrap mb-2">
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <FieldLabel
                  verticalDisplay
                  className="me-5 mb-2"
                  label={intl.formatMessage({ id: "season_start_validity_date" })}
                  value={dateUtils.formatToLocalDateString(new Date(customerCatalog?.startDate))}
                  displayZero
                />
                <FieldLabel
                  verticalDisplay
                  className="me-5 mb-2"
                  label={intl.formatMessage({ id: "season_customer_tour_name" })}
                  value={customerCatalog?.customerTourName}
                />
              </div>
            </div>
            <div className="ms-auto d-flex flex-column">
              <span className="text-muted"><FormattedMessage id="action_button"/></span>
              <div className="d-flex flex-row">
                <Icon
                  className="m-0 me-2 ms-auto cursor-pointer"
                  size={IconSizeType.XS}
                  name="Trash"
                  onClick={() => setCatalogIdToDelete(customerCatalog.id)}
                />
                <Icon
                  className="m-0 me-2 ms-auto cursor-pointer"
                  size={IconSizeType.XS}
                  name="Pen"
                  onClick={() => setCatalogIdToUpdate(customerCatalog.id)}
                />
              </div>
            </div>
            {catalogIdToUpdate === customerCatalog.id && (
              <Panel formId="updateDayByDayClientForm" title={intl.formatMessage({id: "season_update_day_by_day_form_title"}, {customer: customerCatalog?.customer?.name, season: customerCatalog?.seasonName})} open={catalogIdToUpdate === customerCatalog.id} onCancel={() => setCatalogIdToUpdate(undefined)}>
                <UpdateDayByDayClientForm
                  id="updateDayByDayClientForm"
                  dayByDayClientId={customerCatalog.id}
                  customerTourName={customerCatalog.customerTourName}
                  onValidate={handleEditDayByDayClient}
                />
              </Panel>
            )}
          </div>
          <GenericModalDelete
            titleId="season_delete_day_by_day_client_title"
            open={catalogIdToDelete === customerCatalog.id}
            onValidate={handleModalDeleteDayByDayClient}
            setOpen={setModalOpen}
            idEntity={customerCatalog?.id}
            onDelete={dayByDayClientService.deleteDayByDayClient}
            successToastMessageId="season_delete_day_by_day_client_success"
            errorToastMessageId="season_delete_day_by_day_client_failure"
            bodyDeleteMessage={intl.formatMessage({id: "season_delete_day_by_day_client_body"}, {client: customerCatalog.customer.name, season: customerCatalog.seasonName})}
          />
          {customerCatalog?.activitiesInTourForCustomer && customerCatalog?.activitiesInTourForCustomer?.map((activityInTourForCustomer, innerKey) => (
            <div key={innerKey}>
              <ActivityInTourCard
                customer={customerCatalog?.customer}
                tourActivityCustomer={activityInTourForCustomer}
                activityName={activityInTourForCustomer?.activityName}
                activityId={activityInTourForCustomer?.activityId}
                onValidate={onValidate}
                openAll={openAll}/>
            </div>
          ))}
        </Accordion>
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2" />
          <FormattedMessage id="customer_tour_not_found" />
        </Label>
      )}
    </div>
  )
}

export default TourCustomerList
