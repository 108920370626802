import {ADRESSE_DATA_GOUV_LIMIT, ADRESSE_DATA_GOUV_TYPE, PAYS_FRANCE} from "../constants/AddressConstants";
import {AddressDataGouvFeatureCollection, Address} from "../interfaces/AddressInterfaces";
import {httpService} from "./HttpService";
import {stringUtils} from "../utils/stringUtils";
import {ADRESSE_DATA_GOUV_SEARCH} from "../constants/routes/ExternalRoutes";

function mapFeatureCollectionToAddress (collection: AddressDataGouvFeatureCollection): Address[] {
  return collection.features.map((feature) => {
    const sectionAddress : Address = {
      zipCode: feature.properties.postcode,
      city: feature.properties.city,
      country: PAYS_FRANCE,
      latitude: feature.geometry.coordinates[0],
      longitude: feature.geometry.coordinates[1]
    }
    if (feature.properties.type === ADRESSE_DATA_GOUV_TYPE.HOUSE_NUMBER) {
      sectionAddress.street = feature.properties.street
      sectionAddress.houseNumber = feature.properties.housenumber
      sectionAddress.mainLabel = `${sectionAddress.houseNumber} ${sectionAddress.street}`
      sectionAddress.address = `${sectionAddress.houseNumber} ${sectionAddress.street} ${sectionAddress.zipCode} ${sectionAddress.city} ${sectionAddress.country}`
    } else if (feature.properties.type === ADRESSE_DATA_GOUV_TYPE.STREET) {
      sectionAddress.street = feature.properties.name
      sectionAddress.mainLabel = sectionAddress.street
      sectionAddress.address = `${sectionAddress.street} ${sectionAddress.zipCode} ${sectionAddress.city} ${sectionAddress.country}`
    }
    return sectionAddress
  })
}

function searchAddress (q: string, type: string = ADRESSE_DATA_GOUV_TYPE.HOUSE_NUMBER): Promise<Address[]> {
  const params = httpService.encodeURIData({
    q,
    type,
    limit: ADRESSE_DATA_GOUV_LIMIT,
    autocomplete: 1
  })
  return httpService.getExternalApi<AddressDataGouvFeatureCollection>(`${ADRESSE_DATA_GOUV_SEARCH}?${params}`)
    .then(mapFeatureCollectionToAddress)
}

function buildMainLabel(address?: Address): string {
  if (!address) return ""
  return stringUtils.joinNotEmpty([
    address.houseNumber,
    address.street
  ])
}

function mapAddressToLabel(address?: Address): string {
  if (!address) return ""
  return stringUtils.joinNotEmpty([
    address.houseNumber,
    address.street,
    address.zipCode,
    address.city
  ])
}

export const addressService = {
  searchAddress,
  buildMainLabel,
  mapAddressToLabel
}
