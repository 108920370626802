import React, {FunctionComponent} from "react";
import CustomEditorButton from "./CustomEditorButton";
import Icon from "../../../icon/Icon";

const BtnHighlight: FunctionComponent = ({}) => {

  const command = () => {
    const selection = window.getSelection()

    if(selection.focusNode?.parentNode?.nodeName === "MARK"){
      document.execCommand("insertHTML", false, window.getSelection() as unknown as string);
    }

    const wrappedselection = `<mark>${(window.getSelection() as unknown as string)}</mark>`;
    document.execCommand("insertHTML", false, wrappedselection);
  };

  return (
    <CustomEditorButton title="Highlight" content={<Icon name="Highlight" />} command={command} />
  )
}

export default BtnHighlight;
