import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps} from "react-router-dom";
import {toastUtils} from "utils/toastUtils";
import {Resource} from "interfaces/ResourceInterfaces";
import {resourceService} from "services/ResourceService";
import {useIntl} from "react-intl";
import {WorkflowActionsEnum} from "../../constants/workflow/WorkflowStates";
import useResourceWorkflow from "../../hooks/resource/useResourceWorkflow";
import ResourceViewTemplate from "../ResourceViewTemplate";
import {bankAccountService} from "../../services/BankAccountService";

const ResourceSelfView: FunctionComponent<RouteProps> = () => {

  const intl = useIntl()

  const [resource, setResource] = useState<Resource>({})

  const {
    handleValidateButton
  } = useResourceWorkflow(resource, setResource)

  useEffect(() => {
    resourceService.getSelfResource()
      .then(setResource)
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_resource"}))
      })
  }, [])

  return <ResourceViewTemplate
    onChangeResource={setResource}
    resource={resource}
    fetchBankAccount={bankAccountService.getSelfResourceBankAccounts}
    self
    workflowActions={{
      [WorkflowActionsEnum.ACTIVATE_SELF]: handleValidateButton
    }}/>
}

export default ResourceSelfView
