import {FormikErrors, FormikTouched} from "formik";
import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import {ADRESSE_DATA_GOUV_TYPE} from "../../../../constants/AddressConstants";
import {COUNTRY_OPTIONS} from "../../../../constants/CountryConstants";
import {Address} from "../../../../interfaces/AddressInterfaces";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import AddressInput from "../../input/AddressInput";
import FormInput from "../../input/FormInput";
import FormSelect from "../../input/FormSelect";

interface AddressFormProps {
  className?: string,
  values: Address,
  errors: FormikErrors<Address>,
  touched: FormikTouched<Address>,
  setFieldValue: FormikFieldSetter,
}

const AddressForm: FunctionComponent<AddressFormProps> = ({
  className = "",
  values,
  errors,
  touched,
  setFieldValue,
}) => {
  return (
    <div className={className}>
      <AddressInput
        id="mainLabel"
        label="address_field"
        placeholder="address_placeholder"
        address={values?.mainLabel}
        onChange={(mainLabel) => setFieldValue("mainLabel", mainLabel)}
        onSelectAddress={(e) => {
          setFieldValue("mainLabel", e.mainLabel);
          setFieldValue("zipCode", e.zipCode);
          setFieldValue("city", e.city);
          setFieldValue("country", e.country);
          setFieldValue("latitude", e.latitude);
          setFieldValue("longitude", e.longitude);
        }}
        required
        error={errors.mainLabel}
        touched={touched.mainLabel}
        typeSearch={ADRESSE_DATA_GOUV_TYPE.HOUSE_NUMBER}
      />
      <FormInput
        id="additionalInformation"
        label="additional_address_field"
        value={values?.additionalInformation}
        onChange={(e) => setFieldValue("additionalInformation", e.target.value)}
        error={errors.additionalInformation}
        touched={touched.additionalInformation}
      />
      <FormInput
        id="service"
        label="service_field"
        value={values?.service}
        onChange={(e) => setFieldValue("service", e.target.value)}
        error={errors.service}
        touched={touched.service}
      />
      <Col>
        <FormInput
          id="zipCode"
          label="zip_code_field"
          value={values?.zipCode}
          onChange={(e) => setFieldValue("zipCode", e.target.value)}
          required
          error={errors.zipCode}
          touched={touched.zipCode}
        />
      </Col>
      <Col>
        <FormInput
          id="city"
          label="city_field"
          value={values?.city}
          onChange={(e) => setFieldValue("city", e.target.value)}
          required
          error={errors.city}
          touched={touched.city}
        />
      </Col>
      <FormSelect
        id="country"
        label="country_field"
        placeholder="country_placeholder"
        value={COUNTRY_OPTIONS.find(
          (option) => option?.value === values?.country
        )}
        onChange={(option?: Option<string>) =>
          setFieldValue("country", option?.value)
        }
        options={COUNTRY_OPTIONS}
        required
        isSearchable
        isClearable
        error={errors?.country}
        touched={touched?.country}
      />
    </div>
  )
}

export default AddressForm;
