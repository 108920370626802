import React, {FunctionComponent, useEffect, useState} from "react"
import SideMenu from "../molecules/menu/SideMenu";
import HeaderMenu from "../molecules/menu/HeaderMenu";
import Panel from "../molecules/panel/Panel";
import {MENU_CREATE_ENTRIES, MenuCreateEntry} from "../../constants/menu/Layout";
import {MOBILE_BREAKPOINT} from "../../constants/UIConstants";
import useLoading from "../../hooks/useLoading";

interface LayoutProps {
  className?: string;
  children: React.ReactNode;
}

const Layout: FunctionComponent<LayoutProps> = ({
  className = "",
  children
}) => {
  const [activeMenuEntry, setActiveMenuEntry] = useState<MenuCreateEntry>()
  const menuCreateEntry = MENU_CREATE_ENTRIES[activeMenuEntry];
  const {loading, startLoading, stopLoading} = useLoading()
  const [open, setOpen] = useState(window.innerWidth >= MOBILE_BREAKPOINT);

  const handleResize = () => {
    if(!open && window.innerWidth >= MOBILE_BREAKPOINT) {
      setOpen(true)

    } else if(open && window.innerWidth < MOBILE_BREAKPOINT) {
      setOpen(false)
    }
  }

  useEffect(() => {
    // Remove listener before recreating it
    window.removeEventListener("resize", handleResize)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [open])

  const onChangeActiveMenuEntry = (menuEntry: MenuCreateEntry) => {
    setActiveMenuEntry(menuEntry)
    stopLoading()
  }
  return (
    <>
      <main className="main d-flex flex-row">
        <SideMenu
            onClose={() => setOpen(false)}
            open={open}/>
        <div className={`app-content ${className}`}>
          <div>
            <HeaderMenu
              setActiveMenuEntry={onChangeActiveMenuEntry}
              onToggleMenu={() => setOpen(prev =>!prev)}
            />
            <div className="epow-content">
              {children}
            </div>
          </div>
          {
            activeMenuEntry && menuCreateEntry && (
              <Panel
                formId={menuCreateEntry.formId}
                title={menuCreateEntry.titleLabel}
                open={true}
                loading={loading}
                onCancel={() => setActiveMenuEntry(undefined)}
              >
                 <menuCreateEntry.formChild startLoading={startLoading} stopLoading={stopLoading} closeForm={() => setActiveMenuEntry(null)}/>
              </Panel>
            )
          }
        </div>
      </main>
    </>
  )
}

export default Layout
