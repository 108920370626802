import React, {FunctionComponent} from "react";

interface PaginationBoxProps {
  page: number,
  onClick: (page: number) => void,
  mainBox?: boolean
}


const PaginationBox: FunctionComponent<PaginationBoxProps> = ({
  page,
  onClick,
  mainBox = false,
}) => {

  return (
    <div className={`epow-pagination-item ${mainBox ? "main-box" : ""}`} onClick={() => onClick(page)}>
      {page + 1}
    </div>
  )

}

export default PaginationBox;