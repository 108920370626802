import React, {FunctionComponent, useEffect, useState} from "react";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {activityService} from "../../../../services/ActivityService";
import {toastUtils} from "../../../../utils/toastUtils";
import {Col} from "reactstrap";
import Title from "../../../atoms/Title";
import {FormattedMessage} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType, IconSizeType, SizeType, TitleSizeType} from "../../../../types/bootstrap/BootstrapType";
import Panel from "../../panel/Panel";
import CreateActivityServicesForm from "../../form/CreateActivityServicesForm";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import TextLink from "../../../atoms/TextLink";
import {SERVICES_PATH} from "../../../../constants/routes/RoutePaths";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import Icon from "../../../icon/Icon";
import ModalActivityDeleteService from "../../modal/activity/ModalActivityDeleteService";

interface ActivityServicesCardProps {
  className?: string,
  title: string,
  activity?: Activity,
}

const ActivityServicesCard: FunctionComponent<ActivityServicesCardProps> = ({
  className = "",
  title,
  activity
}) => {
  const [servicesList, setServicesList] = useState<Service[]>([])
  const [lateralPanelActivityServices, setLateralPanelActivityServices] = useState<boolean>(false)
  const [openModalDeleteService, setOpenModalDeleteService] = useState<boolean>(false)
  const [selectedService, setSelectedService] = React.useState<string>(null)

  const getActivityServices = (activityId: string) => {
    activityService.getActivityServices(activityId)
      .then(services => {
        setServicesList(services)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  useEffect(() => {
    if (activity?.id) {
      getActivityServices(activity?.id)
    }
  }, [activity?.id])

  const handleValidate = () => {
    setLateralPanelActivityServices(false)
    getActivityServices(activity?.id)
  }

  const handleDelete = () => {
    setOpenModalDeleteService(false)
    getActivityServices(activity?.id)
  }

  const openDeleteServiceModal = (serviceId: string) => {
    setSelectedService(serviceId)
    setOpenModalDeleteService(true)
  }

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title title={title} size={TitleSizeType.H5} />
      </div>
      <div className="mb-3">
        {servicesList && servicesList?.length > 0 ? (
          <div className="d-flex flex-column">
            {servicesList.map((service) => (
              <div key={service.id} className="epow-info d-flex">
                <TextLink to={`${SERVICES_PATH}/${service.id}`}><>{service.reference} - {service.name}</></TextLink>
                <Icon name="Trash" size={IconSizeType.XS} className={"m-0 ms-auto me-4 cursor-pointer"} onClick={() => openDeleteServiceModal(service.id)} />
              </div>
            ))}
          </div>
        ) : (
          <FormattedMessage id="service_no_result" />
        )}
      </div>
      <div className="d-flex gap-2">
        <Button size={SizeType.MD} color={ColorType.SECONDARY} className="extended" onClick={() => setLateralPanelActivityServices(true)}>
          <FormattedMessage id="add_button" />
        </Button>
      </div>
      {lateralPanelActivityServices && (
        <Panel
          formId="createActivityServicesForm"
          title="add_services_to_activity"
          open={lateralPanelActivityServices}
          onCancel={() => setLateralPanelActivityServices(false)}
        >
          <CreateActivityServicesForm
            id="createActivityServicesForm"
            activity={activity}
            onValidate={handleValidate}
          />
        </Panel>
      )}

      <ModalActivityDeleteService
        activityId={activity.id}
        serviceId={selectedService}
        modalOpen={openModalDeleteService}
        setModalOpen={setOpenModalDeleteService}
        onDelete={handleDelete}
      />
    </Col>
  )
}

export default ActivityServicesCard;
