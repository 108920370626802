import {API_DAY_BY_DAY_CLIENTS_PATH} from "../constants/routes/RoutePaths"
import {httpService} from "./HttpService"
import {DayByDayClient, DayByDayClientRequest} from "../interfaces/DayByDayClientInterfaces";

function createDayByDaysForClient (dayByDayRequest?: DayByDayClientRequest): Promise<void> {
    return httpService.post(API_DAY_BY_DAY_CLIENTS_PATH, dayByDayRequest)
}

function updateDayByDayClient (id: string, customerTourName: string): Promise<DayByDayClient> {
  return httpService.put<DayByDayClient>(`${API_DAY_BY_DAY_CLIENTS_PATH}/${id}`, { customerTourName })
}

function deleteDayByDayClient (id: string): Promise<Response> {
  return httpService.delete(`${API_DAY_BY_DAY_CLIENTS_PATH}/${id}`)
}

export const dayByDayClientService = Object.freeze({
  createDayByDaysForClient,
  updateDayByDayClient,
  deleteDayByDayClient
})
