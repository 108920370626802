import React, {ChangeEventHandler, FunctionComponent} from "react";
import TextInput from "./TextInput";
import {Label} from "reactstrap";

interface RadioProps {
  className?: string,
  id?: string;
  value: string,
  onChange: ChangeEventHandler<HTMLInputElement>,
  children?: React.ReactElement|string,
  checked?: boolean
}

const Radio: FunctionComponent<RadioProps> = ({
  className = "",
  id,
  value,
  onChange,
  children,
  checked = false,
}) => (
  <div className="d-flex epow-radio">
    <TextInput type="radio" id={id} onChange={onChange} value={value} checked={checked}/>
    {children && <Label className={`ms-2 me-4 mb-auto ${checked ? "checked" : ""} ${className}`}>{children}</Label>}
  </div>
)


export default Radio;
