import React, {FunctionComponent} from "react";

interface ToastContentProps {
  message: string,
  className?: string
}

const ToastContent: FunctionComponent<ToastContentProps> = ({
  message,
  className
}) =>
  (
    <div className={className}>
      <span>{message}</span>
    </div>
  )


export default ToastContent;
