import React, {FunctionComponent} from "react";

interface AvatarProps {
    url: string;
    className?: string;
    width?: number;
    height?: number;
}

const Avatar: FunctionComponent<AvatarProps> = ({
    url,
    width= 30,
    height= 30,
    className
}) =>
    (<span className={className}>
        <img src={url} width={width} className="rounded-circle" height={height} alt="avatar" loading="lazy"/>
    </span>)


export default Avatar;
