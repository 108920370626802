import {Tour} from "../../../../interfaces/TourInterfaces";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GeneralInfoTourCard from "./GeneralInfoTourCard";
import ImageCard from "../image/ImageCard";
import {PictureType, PictureTypeProperties} from "../../../../constants/FileConstants";

interface DetailsTourCardProps {
  className?: string,
  tour?: Tour,
  getTour?: () => void
}

const DetailsTourCard: FunctionComponent<DetailsTourCardProps> = ({
  className = "",
  tour,
  getTour
}) => {
  const intl = useIntl()
  return (
    <div className={`d-flex flex-row ${className}`}>
      <GeneralInfoTourCard className="d-flex flex-column w-100" tour={tour} title={intl.formatMessage({id: "tour_general_info"})} getTour={getTour}/>

      <ImageCard
        title={intl.formatMessage({id: "picture_title"})}
        entityId={tour.id}
        fileType={PictureType.TOUR_PICTURE}
        aspect={PictureTypeProperties.ACTIVITY_PICTURE.pictureWidth/PictureTypeProperties.ACTIVITY_PICTURE.pictureHeight}
      />
    </div>
  )
}

export default DetailsTourCard
