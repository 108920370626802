import {
  API_BANK_ACCOUNTS,
  API_RESOURCES_PATH,
  API_SUPPLIERS_PATH,
} from "constants/routes/RoutePaths";
import { BankAccount, CreateBankAccount } from "interfaces/BankAccountInterface";
import { httpService } from "./HttpService";

function createBankAccount(
  BankAccount: CreateBankAccount
): Promise<BankAccount> {
  return httpService.post<BankAccount>(
    `/${API_BANK_ACCOUNTS}`,
    BankAccount
  );
}

function getSupplierBankAccounts(
  supplierId: string
): Promise<BankAccount[]> {
  return httpService.get<BankAccount[]>(
    `${API_SUPPLIERS_PATH}/${supplierId}/${API_BANK_ACCOUNTS}`
  );
}

const getResourceBankAccounts = (resourceId: string): Promise<BankAccount[]> => {
  return httpService.get<BankAccount[]>(`${API_RESOURCES_PATH}/${resourceId}/${API_BANK_ACCOUNTS}`)
}

const getSelfResourceBankAccounts = (): Promise<BankAccount[]> => {
  return httpService.get<BankAccount[]>(`${API_RESOURCES_PATH}/self/${API_BANK_ACCOUNTS}`)
}

function updateBankAccount(
  id: string,
  bankAccount: BankAccount
): Promise<BankAccount> {
  return httpService.put<BankAccount>(
    `/${API_BANK_ACCOUNTS}/${id}`,
    bankAccount
  );
}

const updateSupplierDefaultBankAccount = (supplierId: string, bankAccountId: string): Promise<BankAccount> => {
  return httpService.put<BankAccount>(`${API_SUPPLIERS_PATH}/${supplierId}/${API_BANK_ACCOUNTS}/default`, bankAccountId);
}

const updateResourceDefaultBankAccount = (resourceId: string, bankAccountId: string): Promise<BankAccount> => {
  return httpService.put<BankAccount>(`${API_RESOURCES_PATH}/${resourceId}/${API_BANK_ACCOUNTS}/default`, bankAccountId);
}

function deleteBankAccount(
  bankAccountId: string
): Promise<Response> {
  return httpService.delete(`/${API_BANK_ACCOUNTS}/${bankAccountId}`);
}

export const bankAccountService = {
  createBankAccount,
  getSupplierBankAccounts,
  getResourceBankAccounts,
  updateBankAccount,
  updateSupplierDefaultBankAccount,
  updateResourceDefaultBankAccount,
  deleteBankAccount,
  getSelfResourceBankAccounts
}
