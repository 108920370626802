import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {CreateRuleSchema} from "../../../constants/validation/RuleValidationSchema";
import {Rule} from "../../../interfaces/RuleInterface";
import FormMultiSelect from "../input/FormMultiSelect";
import FormInput from "../input/FormInput";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormFileUpload from "../input/FormFileUpload";
import {fileUtils} from "../../../utils/fileUtils";
import {ruleService} from "../../../services/RuleService";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {RULE_INITIAL_VALUES} from "../../../constants/RuleConstants";
import {FileData} from "../../../interfaces/ResourceInterfaces";
import EpowForm from "./EpowForm";
import {PROFILE_OPTIONS} from "../../../interfaces/UserInterfaces";
interface CreateRuleFormProps {
  className?: string;
  id?: string;
  initialRule?: Rule;
  onCreateRule?: () => void;
}

const CreateRuleForm: FunctionComponent<CreateRuleFormProps> = ({
  className = "",
  id,
  initialRule,
  onCreateRule,
}) => {
  const intl = useIntl();
  const profileOptions = PROFILE_OPTIONS.map(option => ({label: intl.formatMessage({id: option.label}), value: option.value}))

  const getPromisesToResolve = (submittedRule: Rule) => {
    const promises = [ruleService.updateRule(submittedRule.id, submittedRule)]
    if (submittedRule?.attachmentFile) {
      promises.push(ruleService.uploadFile(submittedRule.id, submittedRule.attachmentFile))
    }

    return promises
  }

  const handleEditRule = (submittedRule: Rule) => {
    const promisesToResolve = getPromisesToResolve(submittedRule);
    Promise.all(promisesToResolve)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_update_rule"}))
        onCreateRule()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_rule"}))
      })
  }

  const handleCreateRule = (submittedRule: Rule) => {
    ruleService.createRule(submittedRule)
      .then(async (rule: Rule) => {
        const createdRule = rule;

        if (submittedRule.attachmentFile) {
          try {
            const fileData: FileData = await ruleService.uploadFile(rule.id, submittedRule.attachmentFile);
            createdRule.attachment = fileData;
          }
          catch {
            toastUtils.errorToast(intl.formatMessage({id: "error_toast_upload_file"}))
          }
        }
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_rule"}))
        onCreateRule()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_rule"}))
      })
  }

  return (
    <Formik
      initialValues={initialRule || RULE_INITIAL_VALUES}
      validationSchema={CreateRuleSchema}
      onSubmit={(values) => initialRule ? handleEditRule(values) : handleCreateRule(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormMultiSelect
            id="profiles"
            label="rule_profiles"
            options={profileOptions}
            values={profileOptions.filter(option => values?.profiles?.includes(option.value))}
            onChange={(option: Option<string>[]) => setFieldValue("profiles", option.map(o => o.value))}
            required
            isSearchable
            isClearable
            error={errors.profiles}
            touched={touched.profiles}
          />
          <FormInput
            id="description"
            label="rule_description"
            type="textarea"
            value={values?.description}
            onChange={(e) => setFieldValue("description", e.target.value)}
            required
            error={errors.description}
            touched={touched.description}
            rows="5"
          />
          <FormFileUpload
            id="attachmentFile"
            label="rule_attachment"
            file={values?.attachmentFile}
            fileName={fileUtils.getCompleteFileName(initialRule?.attachment)}
            accept={[".pdf"]}
            onChange={(file: File) => setFieldValue("attachmentFile", file)}
            error={errors.attachmentFile}
            touched={touched.attachmentFile}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateRuleForm;
