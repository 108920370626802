import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {isArray} from "lodash";

const paramNotEmpty = (param: string | string[] | boolean) => {
  return !(param === null || param === undefined || param === "" || (isArray(param) && param?.length === 0))
}

const buildPageableQueryParams = (params: PaginationQueryParams): string[] => {
  const {page, pageSize, sortOptions} = params ?? {}
  const queryParams = []

  if (paramNotEmpty(page?.toString())) {
    queryParams.push(`page=${page}`)
  }
  if (paramNotEmpty(pageSize?.toString())) {
    queryParams.push(`size=${pageSize}`)
  }

  if (sortOptions?.length > 0) {
    sortOptions.filter(item => item.sortBy !== "").map(({sortBy, descending, ignoreCase}) => {
      queryParams.push(`sort=${sortBy},${descending ? "DESC" : "ASC"}${ignoreCase ? ",ignorecase" : ""}`)
    })
  }

  return queryParams
}

const buildFilterParams = (params: SearchFilter): string[] => {
  const queryParams = []

  Object.entries(params).forEach(([key, value]) => {
    if (paramNotEmpty(value)) {
      queryParams.push(`${key}=${value?.toString()}`)
    }
  })

  return queryParams
}

const buildPageAndFilterQueryParams = (pageParams: PaginationQueryParams, filterParams?: SearchFilter): string => {
  let queryParams = buildPageableQueryParams(pageParams)

  if (filterParams) {
    queryParams = [...queryParams, ...buildFilterParams(filterParams)]
  }

  return queryParams.length > 0 ? `?${queryParams.join("&")}` : ""
}


export const queryUtils = Object.freeze({
  buildPageableQueryParams,
  buildFilterParams,
  buildPageAndFilterQueryParams
})
