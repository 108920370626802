import React, {FunctionComponent} from "react";
import Card from "../../atoms/Card";
import {FormattedMessage} from "react-intl";
import {Collapse} from "reactstrap";
import StaffingRequestActivity from "../dashboard/StaffingRequestActivity";
import {DashboardActivity} from "../../../interfaces/ActivityInterfaces";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {authUtils} from "../../../utils/authUtils";
import ActivityPanel from "../card/activity/ActivityPanel";

interface StaffingRequestCollapseProps {
  title: string;
  requests: DashboardActivity[];
  open?: boolean;
  className?: string;
  onConfirm?: (activityStaffingId: string) => void;
  onRefuse?: (activityStaffingId: string) => void;
}

const StaffingRequestCollapse: FunctionComponent<StaffingRequestCollapseProps> = ({
  requests,
  title,
  className= "",
  onRefuse,
  onConfirm,
  open = false,
}) => {
  const [requestIsOpen, setRequestIsOpen] = React.useState(open);
  const [panelOpen, setPanelOpen] = React.useState<boolean>(false);
  const [orderItemId, setOrderItemId] = React.useState<string>();

  const showActivityPanel = (orderItemId: string) => {
    setOrderItemId(orderItemId)
    setPanelOpen(true);
  }

  return (<div className={className}>
      <Card className="cursor-pointer" onClick={() => setRequestIsOpen(prev => !prev)}>
        <div className="d-flex justify-content-between">
          <FormattedMessage id={title} values={{count: requests.length}}/>
          <div>
            <Icon className="m-0 me-2" name={requestIsOpen ? "ChevronUp" : "ChevronDown"} size={IconSizeType.XS}/>
          </div>
        </div>
      </Card>
      <Collapse className="mb-3" isOpen={requestIsOpen}>
        {requests.map((activity) => (
          <StaffingRequestActivity
            onConfirm={onConfirm}
            onRefuse={onRefuse}
            activity={activity} className="mt-2"
            key={activity.orderItemId}
            onClickActivity={() => showActivityPanel(activity.orderItemId)}/>
        ))}
      </Collapse>

      <ActivityPanel open={panelOpen} setOpen={setPanelOpen} profile={authUtils.getProfile()} orderItemId={orderItemId} />
    </div>
  )
}


export default StaffingRequestCollapse;
