import React, {FunctionComponent, useMemo} from "react";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {Quote} from "../../../../interfaces/QuoteInterfaces";
import QuoteTableRow from "./QuoteTableRow";
import QuoteTableRowDetails from "./QuoteTableRowDetails";
import CustomTable, {CustomTableRef} from "../CustomTable";
import {CustomTableColumn, CustomTableType} from "../../../../interfaces/TableInterfaces";
import {FormattedMessage} from "react-intl";
import Toggle from "../../../atoms/Toggle";

interface QuoteTableProps {
  className?: string,
  quotes: Quote[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  total?: number,
}

const QuoteTable: FunctionComponent<QuoteTableProps> = ({
  className = "",
  quotes,
  sortOptions,
  onSortOptions,
  total,
}) => {

  const ref = React.useRef<CustomTableRef>();

  const columns: CustomTableColumn[] = useMemo(() => [
    {title: "quote_code", accessor: "quoteNumber", sortable: true},
    {title: "quote_customer_ref", accessor: "customerRef", sortable: true},
    {title: "quote_customer", accessor: "customer.name", sortable: true},
    {title: "quote_nb_items", accessor: "items.length", sortable: false},
    {title: "quote_total_ht", accessor: "totalHt", sortable: false},
    {title: "quote_status", accessor: "quoteStatus", sortable: true},
    {title: "table_actions", accessor: "actions", sortable: false},
  ], [])

  return (
    <>
      <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
        <div className="me-3 bold">
          <FormattedMessage id="quote_total_number" values={{total}} />
        </div>
        <div className="d-flex align-items-center bold">
          <FormattedMessage id="table_see_all_details" />
          <Toggle className="ms-2" id ="quote_all_details" onChange={(checked) => ref.current?.toggleAllRows(checked)} />
        </div>
      </div>

      <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className}`}
        type={CustomTableType.DETAILS_TABLE}
        datas={quotes}
        columns={columns}
        RowComponent={({data, open, ...rest}) => {
          const quote = data as Quote;
          return (
            <div>
              <QuoteTableRow quote={quote} {...rest}/>
              {open && (
                <div className="epow-custom-table-row-details">
                  <QuoteTableRowDetails quote={quote}  />
                </div>
              )}
            </div>);
        }
        } />
    </>
  )
}

export default QuoteTable;
