import React, {FunctionComponent} from "react";
import AutocompleteFilterPageable from "../AutocompleteFilterPageable";
import {ResourceCalendarSearchFilter} from "../../../../interfaces/ResourceCalendarInterfaces";
import {useIntl} from "react-intl";
import {orderService} from "../../../../services/OrderService";
import {OrderOption} from "../../../../interfaces/OrderInterfaces";
import {orderUtils} from "../../../../utils/orderUtils";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import {activityService} from "../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {tourService} from "../../../../services/TourService";
import {Tour} from "../../../../interfaces/TourInterfaces";
import DateFilter from "../DateFilter";
import SelectFilter from "../SelectFilter";
import {CALENDAR_SIZE_OPTIONS, RESOURCE_STAFFING_AVAILABILITY_OPTIONS} from "../../../../constants/OptionConstants";
import {resourceCalendarService} from "../../../../services/resourceCalendarService";
import AsyncMultiselectFilter from "../AsyncMultiselectFilter";
import {customerService} from "../../../../services/CustomerService";
import AutocompleteMultiselect from "../AutocompleteMultiselect";
import {resourceTypeService} from "../../../../services/ResourceTypeService";

interface ResourceCalendarFiltersProps {
  searchObject: ResourceCalendarSearchFilter,
  updateSearchField: (field: string, value: unknown) => void
  activityIds: string[]
}

const ResourceCalendarFilters: FunctionComponent<ResourceCalendarFiltersProps> = ({
  searchObject,
  updateSearchField,
  activityIds,
}) => {
  const intl = useIntl()

  const fetchResources = React.useCallback((inputValue) => {
    return resourceCalendarService.getResourcesPaged({...searchObject, resourceIds: [], resourceActivities: activityIds ?? [], ...inputValue})
  }, [searchObject, activityIds]);

  const fetchActivities = React.useCallback((filter) => {
    return activityService.getActivitiesPage(null, {...filter, status: WorkflowStatesEnum.ACTIVE})
  }, []);

  const fetchActivityAreas = React.useCallback((filter) => {
    return activityAreaService.getActivityAreasPage(null, filter)
  }, []);


  return (
    <div className="resource-calendar-filter">
      <div className="d-flex gap-2 mb-2">
        <DateFilter
          value={searchObject.from} onChangeDate={(value) => updateSearchField("from", value)}
          title={intl.formatMessage({id: "filter_from"})}
        />

        <DateFilter
          min={searchObject.from}
          value={searchObject.to} onChangeDate={(value) => updateSearchField("to", value)}
          title={intl.formatMessage({id: "filter_to"})}
        />

        <SelectFilter
          options={RESOURCE_STAFFING_AVAILABILITY_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={(value) => updateSearchField("availability", value)}
          title={intl.formatMessage({id: "staffing_availability"})}
          value={searchObject.availability}
          isClearable={false}
        />

        <AsyncMultiselectFilter
          title={intl.formatMessage({id: "staffing_resource"})}
          placeholder={intl.formatMessage({id: "staffing_resource"})}
          values={searchObject.resourceIds ?? []}
          onChange={(values) => updateSearchField("resourceIds", values)}
          fetchData={fetchResources}
          filterFieldName="resourceName"
          labelFieldName="name,lastName"
          entityFilterName="resourceFilterIds"
        />

        <SelectFilter
          options={CALENDAR_SIZE_OPTIONS}
          onChange={(value) => updateSearchField("activityLimit", value)}
          title={intl.formatMessage({id: "resource_calendar_filter_activity_size"})}
          value={searchObject.activityLimit}
          isClearable={false}
        />

        <SelectFilter
          options={CALENDAR_SIZE_OPTIONS}
          onChange={(value) => updateSearchField("resourceLimit", value)}
          title={intl.formatMessage({id: "resource_calendar_filter_resource_size"})}
          value={searchObject.resourceLimit}
          isClearable={false}
        />

      </div>

      <div className="d-flex gap-2">
        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("orderId", value)}
          title={intl.formatMessage({id: "staffing_order"})}
          value={searchObject?.orderId}
          fetchData={orderService.getOrdersOptionPage}
          manageOptions={(response: OrderOption[]) => response.map(order => ({value: order.id, label: `${order.refCustomer || order.orderNumber} - ${order.customerName}`}))}
          optionFromValue={(response: OrderOption[], value: string) => {
            const orderOption = response?.find((order) => order.id === value);
            return orderOption ? {
              label: orderUtils.getOrderRefDisplay(orderOption.orderNumber, orderOption.refCustomer, orderOption.customerName),
              value: orderOption?.id
            } : null;
          }}
          filterFieldName="refCustomer"
          filterFieldSearch="customerOrCode"
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customerId", value)}
          title={intl.formatMessage({id: "staffing_customer"})}
          value={searchObject?.customerId}
          fetchData={customerService.getCustomersPage}
          filterFieldName="name"
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("tourId", value)}
          title={intl.formatMessage({id: "staffing_tour"})}
          value={searchObject?.tourId}
          fetchData={(page, filter) => tourService.getToursPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          manageOptions={(response: Tour[]) => response.map(option =>
            ({value: option.id, label: `${option.tourCode} - ${option.tourName}`})
          )}
          optionFromValue={(response: Tour[], value: string) => {
            const option = response?.find((tour) => tour.id === value);
            return option ? {
              label: `${option.tourCode} - ${option.tourName}`,
              value: option?.id
            } : null;
          }}
          filterFieldName="tourName"
        />

        <AsyncMultiselectFilter
          title={intl.formatMessage({id: "staffing_order_item"})}
          placeholder={intl.formatMessage({id: "staffing_order_item"})}
          values={searchObject.activityIds ?? []}
          onChange={(values) => updateSearchField("activityIds", values)}
          fetchData={fetchActivities}
          filterFieldName="name"
          entityFilterName="ids"
        />

        <AsyncMultiselectFilter
          title={intl.formatMessage({id: "resource_table_header_area"})}
          placeholder={intl.formatMessage({id: "resource_table_header_area"})}
          values={searchObject.activityAreaIds ?? []}
          onChange={(values) => updateSearchField("activityAreaIds", values)}
          fetchData={fetchActivityAreas}
          filterFieldName="label"
          entityFilterName="ids"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          placeholder={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypeIds ?? []}
          onChange={(values) => updateSearchField("resourceTypeIds", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />

      </div>
    </div>
  )
}

export default ResourceCalendarFilters;
