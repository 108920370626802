import {API_DOCUMENTARY_BASE_PATH, API_FILES_PATH, API_INFO_PATH} from "../constants/routes/RoutePaths"
import {httpService} from "./HttpService"
import {DocumentDTO, DocumentSearchObject, FileSearchObject, FormDocument} from "../interfaces/FileInterfaces";
import {queryUtils} from "../utils/queryUtils";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {fileUtils} from "../utils/fileUtils";
import {PictureType} from "constants/FileConstants";

function getMyDocuments(params: PaginationQueryParams, filters: DocumentSearchObject): Promise<PageResponse<DocumentDTO>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<DocumentDTO>>(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}/${queryParams}`)
}

function findFiles(filters: FileSearchObject): Promise<DocumentDTO[]> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(null, filters)
  return httpService.get(`${API_FILES_PATH}${queryParams}`)
}

function getDocumentById(fileId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}/${fileId}`)
}

function getDocumentInfoById(fileId: string): Promise<DocumentDTO> {
  return httpService.get(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}${API_INFO_PATH}/${fileId}`)
}

function deleteDocumentById(fileId: string): Promise<Response> {
  return httpService.delete(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}/${fileId}`)
}

function editDocument(fileId: string, documentRequest: FormDocument): Promise<DocumentDTO> {
  const request = {
    ...documentRequest,
    activities: documentRequest.activities?.map((option) => option.value) || [],
    activityAreas: documentRequest.activityAreas?.map((option) => option.value) || [],
  }
  return httpService.put<DocumentDTO>(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}/${fileId}`, request)
}

function createDocument(documentRequest: FormDocument): Promise<DocumentDTO> {
  const formData = fileUtils.createFormDataWithFile(documentRequest.attachmentFile);
  const request = {
    ...documentRequest,
    activities: documentRequest.activities?.map((option) => option.value) || [],
    activityAreas: documentRequest.activityAreas?.map((option) => option.value) || [],
  }
  formData.set("document", new Blob([JSON.stringify(request)],{type: "application/json"}))
  return httpService.postFormData(`${API_FILES_PATH}${API_DOCUMENTARY_BASE_PATH}`, formData);
}

function getPictureById(type: PictureType, entityId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_FILES_PATH}/picture/${type}/${entityId}`);
}

function deletePictureById(type: PictureType, entityId: string): Promise<Response> {
  return httpService.delete(`${API_FILES_PATH}/picture/${type}/${entityId}`);
}

function uploadPicture(fileType: PictureType, formData: FormData): Promise<Blob> {
  return httpService.postFormData(`${API_FILES_PATH}/picture/${fileType}`, formData);
}

export const fileService = {
  getMyDocuments,
  findFiles,
  getDocumentById,
  getDocumentInfoById,
  deleteDocumentById,
  createDocument,
  editDocument,
  getPictureById,
  uploadPicture,
  deletePictureById,
}
