import React, {FunctionComponent} from "react";
import {Contact, ContactFields, EntityContactRequest, HasContact} from "../../../../interfaces/ContactInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import {Formik} from "formik";
import {CreateContactSchema} from "../../../../constants/validation/ContactValidationSchemas";
import EpowForm from "../EpowForm";
import FormInput from "../../input/FormInput";
import FormRadio from "../../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {contactUtils} from "../../../../utils/contactUtils";
import {contactService} from "../../../../services/ContactService";
import {toastUtils} from "../../../../utils/toastUtils";
import FormSelect from "../../input/FormSelect";
import {RESOURCE_DISPLAY_OPTIONS} from "../../../../constants/ResourceDisplayOptionConstant";
import {useIntlOptions} from "../../../../hooks/useIntlOptions";

interface ContactFormProps {
  id?: string;
  className?: string;
  entity?: HasContact;
  contactInitial?: Contact;
  createContactRequest: (request: EntityContactRequest, entityId: string) => Promise<unknown>,
  onValidate: (contact: Contact) => void;
  isMainContact?: boolean;
}

const ContactForm: FunctionComponent<ContactFormProps> = ({
  id,
  className = "",
  entity,
  contactInitial,
  createContactRequest,
  onValidate,
  isMainContact = false,
}) => {
  const intl = useIntl()
  const contactFields: ContactFields = contactUtils.buildContactFields(contactInitial);
  const resourceDisplayOptions = useIntlOptions(intl, RESOURCE_DISPLAY_OPTIONS)

  const createContact = (submittedContactFields: ContactFields) => {
    const contact: Contact = contactUtils.buildContact(submittedContactFields);

    if (contactInitial?.id) {
      contactService.updateContact(contactInitial?.id, contact)
        .then((contact: Contact) => {
          onValidate(contact);
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_contact"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_contact"}));
        });
    } else {
      createContactRequest({contact, isMainContact}, entity.id)
        .then((contact: Contact) => {
          onValidate(contact);
          toastUtils.successToast(intl.formatMessage({id: "success_create_contact"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_contact"}));
        });
    }
  };

  return (
    <Formik
      initialValues={contactFields}
      validationSchema={CreateContactSchema}
      onSubmit={(value) => {
        createContact(value);
      }}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="customer"
            label="contact_customer_field"
            value={entity?.name}
            onChange={() => null}
            required
            disabled
          />
          <FormInput
            id="lastName"
            label="contact_last_name_field"
            value={values?.lastName}
            onChange={(e) => setFieldValue("lastName", e.target.value)}
            required
            error={errors.lastName}
            touched={touched.lastName}
          />
          <FormInput
            id="firstName"
            label="contact_first_name_field"
            value={values?.firstName}
            onChange={(e) => setFieldValue("firstName", e.target.value)}
            required
            error={errors.firstName}
            touched={touched.firstName}
          />
          <FormInput
            id="job"
            label="contact_job_field"
            value={values?.job}
            onChange={(e) => setFieldValue("job", e.target.value)}
          />
          <FormInput
            id="phoneNumber"
            label="contact_phone_number_field"
            value={values?.phoneNumber}
            onChange={(e) => setFieldValue("phoneNumber", e.target.value)}
            required
            error={errors.phoneNumber}
            touched={touched.phoneNumber}
          />
          <FormInput
            id="otherPhoneNumber"
            label="contact_other_phone_number_field"
            value={values?.otherPhoneNumber}
            onChange={(e) => setFieldValue("otherPhoneNumber", e.target.value)}
            error={errors.otherPhoneNumber}
            touched={touched.otherPhoneNumber}
          />
          <FormInput
            id="email"
            label="contact_email_field"
            value={values?.email}
            onChange={(e) => setFieldValue("email", e.target.value)}
            required
            error={errors.email}
            touched={touched.email}
          />
          <FormSelect
            id="resourceDisplayOptions"
            value={resourceDisplayOptions.find((option) => option?.value === values?.resourceDisplayOption)}
            onChange={(option?: Option<string>) => setFieldValue("resourceDisplayOption", option?.value)}
            label={"resource_display_options_field"}
            options={resourceDisplayOptions}
            required
          />
          <FormRadio
            id="hasAccessToPlatform"
            label="contact_has_access_platform_field"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.hasAccessToPlatform)}
            options={YES_NO_OPTIONS}
            onChange={(option?: Option<string>) => setFieldValue("hasAccessToPlatform", option?.value)}
            required
            error={errors.hasAccessToPlatform}
            touched={touched.hasAccessToPlatform}
          />
          {values?.hasAccessToPlatform === "true" && (
            <FormattedMessage id="info_message_init_password" />
          )}
        </EpowForm>
      )}
    </Formik>
  );
}

export default ContactForm;
