import { CreateStructureFields, CreateStructureRequest } from "../interfaces/StructureInterface";

const buildStructureRequest = (
  structure: CreateStructureFields
): CreateStructureRequest => {
  if (!structure) return undefined;
  return {
    name: structure?.name,
    address: {
      mainLabel: structure?.mainLabel,
      additionalInformation: structure?.additionalInformation,
      service: structure?.service,
      zipCode: structure?.zipCode,
      city: structure?.city,
      country: structure?.country,
    },
    phoneNumber: structure?.phoneNumber,
    email: structure?.email,
  };
};

export const structureUtils = {
  buildStructureRequest
}