import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {orderService} from "services/OrderService";
import usePaginatedData from "../../../hooks/usePaginatedData";
import {OrderSearchObject, OrderState} from "../../../interfaces/OrderInterfaces";
import {customerService} from "../../../services/CustomerService";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Pagination from "../pagination/Pagination";
import OrderTable from "../table/order/OrderTable";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import {profileUtils} from "../../../utils/profileUtils";

interface OrderListProps {
  className?: string
  searchObject?: OrderSearchObject,
  updateSearchField?: (field: string, value: string) => void
  onReset?: () => void,
  itemPrefixPath?: string,
}

const OrderList: FunctionComponent<OrderListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset,
  itemPrefixPath
}) => {
  const intl = useIntl();

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    refresh,
    total,
    totalElements,
  } = usePaginatedData(orderService.getOrdersPage, searchObject)

  return (
    <div className={`mb-5 ${className ?? ""}`}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "order_filter_code_title"})}
          value={searchObject.customerOrCode}
          onChange={(value) => updateSearchField("customerOrCode", value)}
          placeholder={intl.formatMessage({id: "order_filter_code_placeholder"})}
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customerId", value)}
          className="order_filter_client"
          title={intl.formatMessage({id: "order_filter_customer"})}
          value={searchObject?.customerId}
          fetchData={customerService.getCustomersPage}
          filterFieldName="name"
        />

        <DateFilter
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "order_filter_period_start"})}
        />

        <DateFilter
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "order_filter_period_end"})}
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <OrderTable
        orders={data}
        total={totalElements}
        sortOptions={sortOptions}
        canEdit={profileUtils.isAdmin() || profileUtils.isInterne()}
        refresh={refresh}
        onSortOptions={setSortOptions}
        itemPrefixPath={itemPrefixPath}
        isSelectable={OrderState.DRAFT === searchObject.status}
      />
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default OrderList
