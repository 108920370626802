import React, {FunctionComponent} from "react";
import {RouteProps, useLocation, useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Label} from "reactstrap"
import Title from "../components/atoms/Title";
import {Formik} from "formik";
import {object as YupObject} from "yup";
import FormInput from "../components/molecules/input/FormInput";
import {IconPlacement, LogoSizeType, SizeType} from "../types/bootstrap/BootstrapType";
import Button from "../components/atoms/Button";
import {authentificationService} from "../services/AuthentificationService";
import {toastUtils} from "../utils/toastUtils";
import {yupRequiredValidPassword} from "../constants/yup/Validators";
import {FORGOTTEN_PASSWORD_PATH, LOGIN_PATH} from "../constants/routes/RoutePaths";
import {PasswordTokenRequest} from "../interfaces/api/AuthentificationInterfaces";
import EpowForm from "../components/molecules/form/EpowForm";
import LoginLayout from "../layout/LoginLayout";
import TextLink from "../components/atoms/TextLink";
import {ApiError} from "../interfaces/ErrorInterfaces";
import Logo from "../components/atoms/logo/Logo";
import {LOGO_DARK} from "../constants/Assets";

const ResetPasswordView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const search = useLocation().search
  const token = new URLSearchParams(search).get("token")

  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const [tokenExpired, setTokenExpired] = React.useState<boolean>(false)

  const passwordSchema = YupObject().shape({
    password: yupRequiredValidPassword,
    confirmPassword: yupRequiredValidPassword
  })

  const onPasswordIconClick = () => {
    setShowPassword(!showPassword)
  }


  const onResetPassword = (passwordInfo : PasswordTokenRequest) => {
    authentificationService.resetPassword({...passwordInfo, resetToken: token})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_reset_password"}))
        navigate(LOGIN_PATH)
      })
      .catch((err: ApiError) => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_send_mail_reset_password"}))
        if (err.status === 404) {
          setTokenExpired(true)
        }
      })
  }

  return (
    <LoginLayout>
      <Col lg={12} className="d-flex flex-column justify-content-center align-items-center p-5">
        <Col xl={16} lg={20}>
          <div className="d-flex flex-column justify-content-start text-start mb-5">
            <Logo imageSource={LOGO_DARK} width={LogoSizeType.XXL} alt="SOFTOURING" className="m-auto mb-5" />
            <Title title={intl.formatMessage({id: "new_password_title"})} className="mb-1 text-start"/>
            <Label className="epow-label" style={{ whiteSpace: "pre-line" }}>
              <FormattedMessage id="new_password_subtitle"/>
            </Label>
          </div>
          <Formik
            initialValues={{password: "", confirmPassword: ""}}
            validationSchema={passwordSchema}
            onSubmit={onResetPassword}
          >
            {({errors, touched, values, setFieldValue}) => (
              <EpowForm className="d-flex flex-column justify-content-center">
                <FormInput
                  id="password"
                  label="form_login_password"
                  type="password"
                  placeholder={intl.formatMessage({id: "password_placeholder"})}
                  value={values?.password}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  required
                  error={errors.password}
                  touched={touched.password}
                />
                <FormInput
                  id="confirmPassword"
                  label="form_login_confirm_password"
                  type={showPassword ? "text" : "password"}
                  placeholder={intl.formatMessage({id: "password_placeholder"})}
                  value={values?.confirmPassword}
                  onChange={(e) => setFieldValue("confirmPassword", e.target.value)}
                  required
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  icon="Eye"
                  iconPlacement={IconPlacement.RIGHT}
                  onIconClick={() => onPasswordIconClick()}
                />
                {tokenExpired && (
                  <Label className="alert-warning">
                    <FormattedMessage id="token_expired"/>
                    <TextLink to={FORGOTTEN_PASSWORD_PATH} className="ms-2 epow-text-link" >
                      <FormattedMessage id="login_reset_password" />
                    </TextLink>
                  </Label>
                )}
                <Button type="submit" size={SizeType.MD}>
                  <FormattedMessage id="reset_password"/>
                </Button>
              </EpowForm>
            )}
          </Formik>
        </Col>
      </Col>
    </LoginLayout>
  )
}

export default ResetPasswordView
