import React, {FunctionComponent} from "react";
import {useEditorState} from "react-simple-wysiwyg";
import ColorDropdown from "./ColorDropdown";
import {EDITOR_COLORS} from "../../../../constants/editor/EditorColorConstants";
import {ColorOption} from "../../../../interfaces/EditorInterfaces";

const EditorColorPicker: FunctionComponent = ({
  ...props
}) => {
  const editorState = useEditorState();
  const {$el, $selection, htmlMode} = editorState;
  const items: ColorOption[] = Object.entries(EDITOR_COLORS).map(([key, value]) => ({colorName: key, colorValue: value}))

  const activeIndex = items.findIndex(
    (item) => item[1] === "formatBlock" && $selection?.nodeName === item[2],
  );

  if (htmlMode) {
    return null;
  }

  function onChange(selectedIndex: number) {
    const {colorValue} = items[selectedIndex];

    if (document.activeElement !== $el) {
      $el.focus();
    }

    const prefix = `<span style="color:${colorValue}">`
    const wrappedselection = `${prefix + (window.getSelection() as unknown as string)}</span>`;

    document.execCommand("insertHTML", false, wrappedselection);
  }

  return (
    <ColorDropdown
      {...props}
      onChange={onChange}
      title={"Couleur"}
      items={items}
      selected={activeIndex}
    />
  );
}

export default EditorColorPicker;
