import React, {FunctionComponent} from "react";
import {useParams} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import StaffingValidationCard from "../../components/molecules/card/staffing/StaffingValidationCard";


const StaffingValidationView: FunctionComponent = () => {
  const {staffingToken} = useParams();


  return (
    <Layout>
      <div className="d-flex flex-column epow-content-body">
          <StaffingValidationCard staffingToken={staffingToken} />
      </div>
    </Layout>
  )
}

export default StaffingValidationView;
