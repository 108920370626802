import {Tour} from "../../../../interfaces/TourInterfaces";
import React, {FunctionComponent, useState} from "react";
import Title from "../../../atoms/Title";
import CreateTourForm from "../../form/CreateTourForm";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import TourCard from "./TourCard";
import {Col, Row} from "reactstrap";
import useLoading from "../../../../hooks/useLoading";

interface GeneralInfoTourCardProps {
  className?: string,
  tour?: Tour,
  title: string,
  getTour?: () => void
}

const GeneralInfoTourCard: FunctionComponent<GeneralInfoTourCardProps> = ({
  className = "",
  tour,
  title,
  getTour
}) => {
  const [editInfo, setEditInfo] = useState<boolean>(false)

  const handleValidate = () => {
    getTour && getTour()
    setEditInfo(false)
  }
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <div className={className}>
      <div className="mb-3">
        <Title title={title} size={"h5"}/>
      </div>
      {editInfo ? (
        <Row>
          <Col xs={24} md={16} xl={12} xxl={10}>
            <CreateTourForm id="editTourForm" initialTour={tour} onValidate={handleValidate} startLoading={startLoading} stopLoading={stopLoading}/>
            <div>
              <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended me-2">
                <FormattedMessage id="cancel_button"/>
              </Button>
              <Button loading={loading} form="editTourForm" type="submit" size={SizeType.MD} className="extended">
                <FormattedMessage id="save_button"/>
              </Button>
            </div>
          </Col>
        </Row>
      ) : (
        <TourCard tour={tour} editInfo={editInfo} setEditInfo={setEditInfo}/>
      )}
    </div>
  )
}

export default GeneralInfoTourCard
