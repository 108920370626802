import React, {FunctionComponent} from "react";
import {Contact} from "../../../../interfaces/ContactInterfaces";
import GenericModal from "../GenericModal";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../../utils/toastUtils";
import {contactService} from "../../../../services/ContactService";

interface ModalDeleteContactProps {
  className?: string,
  modalOpen: boolean,
  setModalOpen: (open: boolean) => void,
  getContacts: () => void,
  contact: Contact
}

const ModalDeleteContact: FunctionComponent<ModalDeleteContactProps> = ({
  className = "",
  modalOpen,
  setModalOpen,
  getContacts,
  contact,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const deleteContact = (contact: Contact) => {
    contactService.deleteContact(contact.id)
      .then(() => {
        getContacts();
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_contact"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_contact"}))
      })
      .finally(() => {
        setModalOpen(false)
      })
  }

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({id: "modal_title_delete_access_platform"})}
      open={modalOpen}
      onOpen={setModalOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: validateButton,
          action: () => deleteContact(contact)
        },
        secondaryAction: {
          buttonLabel: cancelButton,
          action: () => setModalOpen(false)
        }
      }}
    >
      {intl.formatMessage({id: "modal_body_delete_access_platform"}, {lastName: contact.identity?.lastName, firstName: contact.identity?.firstName})}
    </GenericModal>
  )
}

export default ModalDeleteContact;
