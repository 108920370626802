import {SearchFilter} from "./api/FiltersInterface";
import {Data} from "./TableInterfaces";
import {Service} from "./ServiceInterfaces";
import {OrderItem} from "./OrderItemInterfaces";
import {Purchase} from "./PurchaseInterfaces";
import {IconName} from "../components/icon/IconsMap";
import {DocumentDTO} from "./FileInterfaces";
import {StateMap} from "../constants/workflow/StateConstants";
import {ColorType} from "../types/bootstrap/BootstrapType";
import { FileType } from "constants/FileConstants";

export interface Booking {
  id?: string,
  orderItem?: OrderItem,
  service?: Service,
  purchase?: Purchase,
  reference?: string,
  quantity?: number,
  date?: string,
  hour?: string,
  status?: string,
  isBilled?: boolean,
  isPaid?: boolean,
  confirmationBookingDate?: string,
  paymentAlertDate?: string,
  invoiceDate?: string,
  paymentDate?: string,
  comment?: Comment,
  pax?: number,
  price?: number,
  totalExcludingVAT?: number,
  amountVAT?: number,
  totalPrice?: number
}

export interface BookingShortDto extends Data {
  serviceName?: string
  status?: BookingStatus
  quantity?: number
  pax?: number
}

export interface BookingTableDto extends Data {
  orderCode: string,
  orderRef: string,
  orderId: string,
  orderCustomerName: string,
  orderItemName: string,
  orderItemId: string,
  date?: string,
  hour?: string
  orderPax?: number
  pax?: number
  quantity?: number
  purchasePrice?: number,
  serviceName: string,
  serviceId: string,
  defaultSupplierName: string
  defaultSupplierId: string,
  createdFromOrderUpdate: boolean,
  booking?: Booking,
  isBilled?: boolean,
  isPaid?: boolean,
  documentCount?: number
  supportingDocuments?: DocumentDTO[]
  billDocuments?: DocumentDTO[]
  paymentDocuments?: DocumentDTO[]
  purchase?: Purchase,
  invoiceDate?: string
  paymentDate?: string
  purchaseId?: string
  reference?: string
}

export interface BookingSearchObject extends SearchFilter {
  supplierIds?: string[]
  serviceIds?: string[]
  customerIds?: string[]
  orderIds?: string[]
  startDate?: string
  endDate?: string
  query?: string
  status?: string[]
}

export const enum BookingStatus {
  TO_DO = "TO_DO",
  OPEN = "OPEN",
  PRE_BOOKED = "PRE_BOOKED",
  DOCUMENT_RECEIVED = "DOCUMENT_RECEIVED",
  CLOSED = "CLOSED",
  TO_UPDATE = "TO_UPDATE",
  TO_CANCEL = "TO_CANCEL",
  CANCELLED = "CANCELLED"
}

export const BookingStatusMap: StateMap = {
  TO_DO: {
    id: BookingStatus.TO_DO,
    label: "workflow_booking_to_do",
    color: ColorType.SECONDARY,
  },
  OPEN: {
    id: BookingStatus.OPEN,
    label: "workflow_booking_open",
    color: ColorType.SECONDARY,
  },
  PRE_BOOKED: {
    id: BookingStatus.PRE_BOOKED,
    label: "workflow_booking_pre_booked",
    color: ColorType.SECONDARY,
  },
  DOCUMENT_RECEIVED: {
    id: BookingStatus.DOCUMENT_RECEIVED,
    label: "workflow_booking_document_received",
    color: ColorType.PRIMARY,
  },
  CLOSED: {
    id: BookingStatus.CLOSED,
    label: "workflow_booking_closed",
    color: ColorType.PRIMARY,
  },
  TO_UPDATE: {
    id: BookingStatus.TO_UPDATE,
    label: "workflow_booking_to_update",
    color: ColorType.DANGER,
  },
  TO_CANCEL: {
    id: BookingStatus.TO_CANCEL,
    label: "workflow_booking_to_cancel",
    color: ColorType.DANGER,
  },
  CANCELLED: {
    id: BookingStatus.CANCELLED,
    label: "workflow_booking_cancelled",
    color: ColorType.GRAY,
  }
}

interface BookingWarning {
  warningDelay: number,
  warning: boolean
}

export interface BookingDocumentData {
  id?: string,
  isBilled?: boolean,
  isPaid?: boolean,
  orderCustomerName?: string,
  documentCount?: number,
  supportingDocuments?: DocumentDTO[],
  billDocuments?: DocumentDTO[],
  paymentDocuments?: DocumentDTO[],
  purchaseNumber?: string,
  serviceName?: string,
  defaultSupplierName?: string,
  quantity?: number,
  pax?: number,
  invoiceDate?: string,
  paymentDate?: string,
  reference?: string,
  orderCode?: string,
  orderRef?: string,
  date?: string,
  hour?: string,
  status?: string,
}

export interface BookingItem extends Data {
  orderCode?: string,
  orderRef?: string,
  orderCustomerName?: string,
  date?: string,
  hour?: string
  quantity?: number
  pax?: number
  orderItemPax?: number
  serviceName?: string,
  defaultSupplierName?: string
  status?: string,
  isBilled?: boolean,
  isPaid?: boolean,
  bookingWarning?: BookingWarning
  documentCount?: number
  supportingDocuments?: DocumentDTO[]
  billDocuments?: DocumentDTO[]
  paymentDocuments?: DocumentDTO[]
  purchaseNumber?: string,
  invoiceDate?: string
  paymentDate?: string
  purchaseId?: string
  reference?: string
}

export interface BookingByStatusResponse {
  requiredActionsBookings: BookingItem[],
  openBookings: BookingItem[],
  preBookedBookings: BookingItem[],
  confirmedBookings: BookingItem[],
  paymentBookings: BookingItem[],
}

export interface BookingPriceResponse {
  quantity: number,
  unitPrice: number
}

export interface BookingHeader {
  id: string,
  title: string,
  barClassName: string,
  statusBadge?: boolean,
  warningLabel?: string,
  completionIndicators?: boolean
  hideIfEmpty?: boolean
  iconName?: IconName
  iconClickAction?: () => void
}

export interface BookingDocumentFormData {
  isConfirmed?: boolean,
  isBilled?: boolean,
  isPaid?: boolean,
  invoiceDate: string,
  paymentDate: string,
  editInvoiceDate?: boolean
  editPaymentDate?: boolean
  isSendUpdateEmail?: string
  updateEmailContent?: string
  reference?: string
}

export interface BookingFileRequest {
  fileType: FileType
}

export interface BookingDocumentFormCategory {
  title: string,
  addButtonLabel: string,
  addButtonIcon: IconName,
  idCheckbox: "isPaid" | "isBilled" | "isConfirmed",
  hasDate?: boolean,
  idDate?: string
  dateLabel?: string
  idFile: string
  fileType?: FileType
  isSupporting?:boolean
  idDocument: string
  fileLabel: string
  editDateLabel?: string
  disableWhenUnpaid?: boolean
  displayFileType?: boolean
  shouldDisplayExtraButton?: (isPaid: boolean) => boolean
  extraButtonLabel?: string
  extraButtonIcon?: IconName
  extraButtonAction?: () => void
}

export interface DefaultPendingBookingState {
  bookingId?: string
  displayPending?: boolean
  displayClosed?: boolean
}

export interface BookingToDoDeleteRequest {
  bookingIds?: string[],
  orderItemIds?: string[]
}
