import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {SizeType, ColorType} from "../../../../../types/bootstrap/BootstrapType";
import Button from "../../../../atoms/Button";

interface StaffingFormGeneralMessageProps {
  className?: string,
  onEditGeneralMessage: (message: string) => void,
}

const StaffingFormGeneralMessage: FunctionComponent<StaffingFormGeneralMessageProps> = ({
  className = "",
  onEditGeneralMessage,
}) => {
  const intl = useIntl()
  const [editGeneralMessage, setEditGeneralMessage] = useState<boolean>(false);
  const [generalMessage, setGeneralMessage] = useState<string>("");

  const handleSave = () => {
    onEditGeneralMessage(generalMessage)
    setEditGeneralMessage(false)
  }

  return (
    <div className={`d-flex flex-column ${className ?? ""}`}>
      {!editGeneralMessage && (
        <div className="d-flex w-100">
          <div onClick={() => setEditGeneralMessage(true)} className="ms-auto epow-text-link cursor-pointer">
            <FormattedMessage id={"staffing_edit_general_message"} />
          </div>
        </div>
      )}
      {editGeneralMessage && (
        <>
          <label><FormattedMessage id="staffing_general_message" /></label>
          <textarea
            className="w-100 p-2 mb-1 border br-sm"
            onChange={(event) => setGeneralMessage(event.target.value)}
            placeholder={intl.formatMessage({id: "staffing_message_placeholder"})}
          />

          <div className="d-flex ms-auto">
            <div onClick={() => setEditGeneralMessage(false)} className="ms-auto me-2 epow-text-link cursor-pointer">
              <FormattedMessage id={"cancel_button"} />
            </div>

            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={handleSave} >
              <FormattedMessage id={"save_button"} />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default StaffingFormGeneralMessage;
