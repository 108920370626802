import FrenchMessages from "./FrenchMessages"
import EnglishMessages from "./EnglishMessages"

interface MessageMap {
  [key: string]: Record<string, string>;
}

const Messages: MessageMap = {
  fr: FrenchMessages,
  en: EnglishMessages
}

export default Messages
