import {
  Supplier,
  SupplierDetails,
  SupplierFields,
  SupplierGeneralInfo,
  SupplierRequest
} from "../interfaces/SupplierInterfaces";
import {LineContactCardProps} from "../interfaces/ContactInterfaces";
import React from "react";

function buildSupplierFields(supplier: Supplier): SupplierFields {
  if (!supplier) return undefined;
  return {
    id: supplier.id,
    name: supplier.name,
    mainLabel: supplier.address?.mainLabel,
    additionalInformation: supplier.address?.additionalInformation,
    service: supplier.address?.service,
    zipCode: supplier.address?.zipCode,
    city: supplier.address?.city,
    country: supplier.address?.country,
    street: supplier.address?.street,
    houseNumber: supplier.address?.houseNumber,
    latitude: supplier.address?.latitude,
    longitude: supplier.address?.longitude,
  };
}

function buildSupplierRequest(supplierGeneralInfo: SupplierGeneralInfo): SupplierRequest {
  return {
    name: supplierGeneralInfo?.name,
    entityEmail: supplierGeneralInfo?.entityEmail,
    entityPhone: supplierGeneralInfo?.entityPhone,
    address: {
      mainLabel: supplierGeneralInfo?.mainLabel,
      additionalInformation: supplierGeneralInfo?.additionalInformation,
      service: supplierGeneralInfo?.service,
      zipCode: supplierGeneralInfo?.zipCode,
      city: supplierGeneralInfo?.city,
      country: supplierGeneralInfo?.country,
    },
  };
}

function buildSupplierLineContact(supplier: SupplierDetails): LineContactCardProps[] {
  const identity = supplier?.mainContact?.identity
  const address = supplier?.address

  return [
    {
      title: "address",
      customContent:
        <div className="d-flex flex-column">
          <span>{address?.mainLabel}</span>
          <span>{address?.additionalInformation}</span>
          <span>{address?.zipCode} {address?.city}</span>
        </div>,
      icon: "LocationDot",
    },
    {
      title: "email",
      content: supplier?.entityEmail || identity?.email,
      icon: "Email",
      href: "mailto",
    },
    {
      title: "phoneNumber",
      content: supplier?.entityPhone || identity?.phoneNumber,
      icon: "Phone",
      href: "tel",
    },
  ];
}

export const supplierUtils = {
  buildSupplierFields,
  buildSupplierRequest,
  buildSupplierLineContact
}