import React, {FunctionComponent} from "react";
import {SortOptions} from "../../../interfaces/api/PaginationInterface";
import {BasicTableHeader} from "../../../interfaces/TableInterfaces";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import {IconName} from "../../icon/IconsMap";

interface SortButtonProps {
  column: BasicTableHeader,
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
}

const SortButton: FunctionComponent<SortButtonProps> = ({
  column,
  sortOptions= {sortBy: ""},
  onSortOptions,
}) => {

  const changeSortOptions = () => {
    if (sortOptions.sortBy !== column?.id) {
      onSortOptions({sortBy: column?.id, descending: false, ignoreCase: column.ignoreCase})

    } else if (sortOptions.descending !== true) {
      onSortOptions({sortBy: column?.id, descending: true, ignoreCase: column.ignoreCase})

    } else {
      onSortOptions({sortBy: "", descending: null})
    }
  }

  const getSortIconName = (): IconName => {
    const isSorted = sortOptions.sortBy === column?.id
    if(!isSorted){
      return "ArrowsUpDown"
    }

    return sortOptions.descending ? "ArrowDown" : "ArrowUp"
  }

  return (
    (column?.sortable === true) &&
    <div className="d-flex" onClick={changeSortOptions}>
      <Icon name={getSortIconName()} size={IconSizeType.XS} />
    </div>
  )
}

export default SortButton;
