import {ActivityCustomerCatalog} from "../../../interfaces/ActivityInterfaces";
import React, {FunctionComponent, useState} from "react";
import Accordion from "../accordion/Accordion";
import {FormattedMessage, useIntl} from "react-intl";
import ActivitySellingPriceCard from "../card/activity/ActivitySellingPriceCard";
import Icon from "../../icon/Icon";
import {Col, Label, Row} from "reactstrap";
import ActivityResourceRateCard from "../card/activity/ActivityResourceRateCard";
import {CUSTOMERS_PATH} from "../../../constants/routes/RoutePaths";
import FieldLabel from "../../atoms/FieldLabel";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Panel from "../panel/Panel";
import CreateActivityCustomerForm from "../form/CreateActivityCustomerForm";
import GenericModalDelete from "../modal/GenericModalDelete";
import {activityService} from "../../../services/ActivityService";
import {optionUtils} from "../../../utils/optionUtils";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";

interface ActivityCustomerListProps {
  className?: string;
  activitiesCustomerCatalog?: ActivityCustomerCatalog[];
  onValidate?: () => void;
  activityDescription?: string;
}

const ActivityCustomerList: FunctionComponent<ActivityCustomerListProps> = ({
  className = "",
  activitiesCustomerCatalog,
  onValidate,
  activityDescription
}) => {
  const intl = useIntl()
  const [lateralPanel, setLateralPanel] = useState<boolean>(false)
  const [selectedActivityCustomerCatalog, setSelectedActivityCustomerCatalog] = useState<ActivityCustomerCatalog>(null)
  const [activityCustomerIdToDelete, setActivityCustomerIdToDelete] = useState<string>(undefined)

  const setModalOpen = (value: boolean) => {
    if (!value) {
      setActivityCustomerIdToDelete(undefined)
    }
  }

  const handleModalDeleteActivityCustomer = () => {
    setActivityCustomerIdToDelete(undefined)
    onValidate()
  }

  return (
    <div className={`d-flex flex-column gap-3 ${className}`}>
      {activitiesCustomerCatalog?.length > 0 ? activitiesCustomerCatalog.map((activityCustomerCatalog, key) =>
        <Accordion
          className="secondary"
          title={activityCustomerCatalog?.customer?.name}
          titleHref={`${CUSTOMERS_PATH}/${activityCustomerCatalog?.customer?.id}`}
          key={key}
        >
          <div className="d-flex flex-column gap-2">
            <Row className="w-100 mb-3">
              <Col xs={12} sm={8}>
                <FieldLabel
                  verticalDisplay
                  label={intl.formatMessage({id: "activity_customer_billing_name"})}
                  value={activityCustomerCatalog?.billingName}
                />
              </Col>
              <Col xs={12} sm={8}>
                <FieldLabel
                  verticalDisplay
                  label={intl.formatMessage({id: "activity_customer_pax_max_customer"})}
                  value={activityCustomerCatalog?.paxMaxCustomer}
                />
              </Col>
              <Col xs={10} sm={7}>
                <FieldLabel
                    verticalDisplay
                    label={intl.formatMessage({id: "activity_ticket_tour_leader"})}
                    value={optionUtils.translateOption(intl, YES_NO_OPTIONS, activityCustomerCatalog?.bookTicketTourLeader)}
                />
              </Col>
              <Col xs={4} sm={1}>
                <div className="d-flex justify-content-end align-items-center h-100">
                  <Icon
                    name="Pen"
                    size={IconSizeType.XS}
                    className="pe-2 m-1 cursor-pointer"
                    onClick={() => {
                      setSelectedActivityCustomerCatalog(activityCustomerCatalog)
                      setLateralPanel(true)
                    }}
                  />
                  <Icon
                    name="Trash"
                    size={IconSizeType.XS}
                    className="pe-2 m-1 cursor-pointer"
                    onClick={() => {
                      setActivityCustomerIdToDelete(activityCustomerCatalog?.activityCustomerId)
                      setModalOpen(true)
                    }}
                  />
                </div>
              </Col>
            </Row>
            <ActivitySellingPriceCard
              title={intl.formatMessage({id: "activity_informations_title"})}
              activityCustomerId={activityCustomerCatalog?.activityCustomerId}
              activitiesSellingPriceCatalog={activityCustomerCatalog?.activitySellingPriceCatalogs}
              onValidate={onValidate}
              activityDescription={activityDescription}
            />
            <ActivityResourceRateCard
              title={intl.formatMessage({id: "activity_resource_price_title"})}
              activityCustomerId={activityCustomerCatalog?.activityCustomerId}
              activitiesResourceCatalog={activityCustomerCatalog?.activityResourceCatalogs}
              onValidate={onValidate}
            />
            <GenericModalDelete
              titleId="activity_customer_delete_title"
              open={activityCustomerIdToDelete === activityCustomerCatalog?.activityCustomerId}
              onValidate={handleModalDeleteActivityCustomer}
              setOpen={setModalOpen}
              idEntity={activityCustomerCatalog?.activityCustomerId}
              onDelete={activityService.deleteActivityCustomer}
              successToastMessageId="activity_customer_delete_success"
              errorToastMessageId="activity_customer_delete_failure"
              bodyDeleteMessage={intl.formatMessage({id: "activity_customer_delete_body"}, {client: activityCustomerCatalog?.customer?.name})} />
          </div>
        </Accordion>
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2"/>
          <FormattedMessage id="activity_customer_not_found"/>
        </Label>
      )}

      {lateralPanel && (
        <Panel
          formId="createActivityCustomerForm"
          title="update_customer"
          open={lateralPanel}
          onCancel={() => setLateralPanel(false)}
        >
          <CreateActivityCustomerForm
            id="createActivityCustomerForm"
            customerId={selectedActivityCustomerCatalog.customer.id}
            activityCustomerId={selectedActivityCustomerCatalog.activityCustomerId}
            onValidate={() => {
              setLateralPanel(false)
              onValidate()
            }}
            numberPaxMax={selectedActivityCustomerCatalog.paxMaxCustomer}
            billingName={selectedActivityCustomerCatalog.billingName}
            existingCustomerIds={activitiesCustomerCatalog?.map(a => a?.customer?.id)}
            bookTicketTourLeader={selectedActivityCustomerCatalog.bookTicketTourLeader}
          />
        </Panel>
      )}
    </div>
  )
}

export default ActivityCustomerList
