import React, {FunctionComponent} from "react";
import Card from "../../atoms/Card";
import {dateUtils} from "../../../utils/dateUtils";
import {orderUtils} from "../../../utils/orderUtils";
import {DashboardActivity} from "../../../interfaces/ActivityInterfaces";
import Button from "../../atoms/Button";
import {FormattedMessage} from "react-intl";

interface ActivityProps {
  activity: DashboardActivity,
  onConfirm?: (activityStaffingId: string) => void,
  onRefuse?: (activityStaffingId: string) => void,
  className?: string,
  onClickActivity?: (activity: DashboardActivity) => void,
}

const StaffingRequestActivity: FunctionComponent<ActivityProps> = ({
  activity,
  onConfirm,
  onRefuse,
  className = "",
  onClickActivity = () => null
}) => (
  <Card
    key={`${activity.activityName}-${activity.rendezVousDate}-${activity.rendezVousTime}`}
    className={`next-task mt-2 mb-3 d-flex flex-sm-row flex-column justify-content-between gap-1 cursor-pointer ${className}`}
    onClick={() => onClickActivity(activity)}
  >
    <div>
      <span
        className="d-block fs-10">{activity.rendezVousPlace} <span
        className="fs-20">|</span> {dateUtils.formatToLocalDateString(dateUtils.convertStringToObjectDate(activity.rendezVousDate))} - {dateUtils.formatLocalTime(activity.rendezVousTime)}</span>
        <span>
      <span
        className="fs-10 color-gray">{orderUtils.getOrderRefDisplay(activity.orderRef, activity.clientRef, activity.clientName)} </span>
      <span className="fs-12 fw-bold">{activity.activityName}</span>
      </span>
    </div>
    <div className="align-self-center">
      {onConfirm && <Button  onClick={(e: Event): void => { e.stopPropagation(); onConfirm(activity.activityStaffingId); }} className="me-2" color="primary">
        <FormattedMessage id="staffing_available"/>
      </Button>}
      {onRefuse && <Button onClick={(e: Event): void => { e.stopPropagation(); onRefuse(activity.activityStaffingId); }} color="secondary">
        <FormattedMessage id="staffing_not_available"/>
      </Button>}
    </div>
  </Card>)


export default StaffingRequestActivity;
