import React, {FunctionComponent} from "react";
import {OrderList} from "../../../../interfaces/OrderInterfaces";
import {useIntl} from "react-intl";
import GenericModal from "../GenericModal";
import {orderService} from "../../../../services/OrderService";
import {toastUtils} from "../../../../utils/toastUtils";

interface ModalTransitionOrdersToPendingProps {
  open: boolean,
  setOpen: (open: boolean) => void,
  refresh: () => void,
  orders: OrderList[],
}

const ModalTransitionOrdersToPending: FunctionComponent<ModalTransitionOrdersToPendingProps> = ({
  open,
  setOpen,
  refresh,
  orders
}) => {
  const intl = useIntl()

  const transitionOrdersTopending = () => {
    orderService.transitionToPending(orders.map(o => o.id))
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_validate_orders"}))
        setOpen(false)
        refresh()
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_validate_orders"})))
  }

  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_title_validate_orders"})}
      open={open}
      closable
      onOpen={setOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: () => {
            transitionOrdersTopending()
          },
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            setOpen(false)
          }
        }
      }}
    >
      <div className="d-flex flex-column">
        {intl.formatMessage({id: "modal_body_validate_orders"})}
      </div>
    </GenericModal>
  )
}

export default ModalTransitionOrdersToPending;
