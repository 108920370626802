import {FormikErrors, FormikTouched} from "formik";
import React, {FunctionComponent, useEffect} from "react";
import {UNIT_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {Activity} from "../../../../interfaces/ActivityInterfaces";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import {Quote} from "../../../../interfaces/QuoteInterfaces";
import {QuoteItemRequest} from "../../../../interfaces/QuoteItemInterfaces";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import FormInput from "../../input/FormInput";
import FormRadio from "../../input/FormRadio";
import {numberUtils} from "../../../../utils/numberUtils";
import PriceInput from "../../input/PriceInput";
import {Col, Row} from "reactstrap";
import {ORDER_ITEM_TYPE, UNIT_TYPE} from "../../../../interfaces/OrderItemInterfaces";

interface CreateQuoteItemFormStepTwoProps {
  className?: string,
  errors: FormikErrors<QuoteItemRequest>,
  touched: FormikTouched<QuoteItemRequest>,
  values: QuoteItemRequest,
  setFieldValue: FormikFieldSetter,
  quote?: Quote,
  selectedActivity: Activity,
  setSelectedActivity: (activity: Activity) => void,
  selectedService: Service,
  setSelectedService: (service: Service) => void,
}

const CreateQuoteItemFormStepTwo: FunctionComponent<CreateQuoteItemFormStepTwoProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  selectedActivity,
  selectedService,
}) => {
  const paxMax = values.type === ORDER_ITEM_TYPE.ACTIVITY ? selectedActivity?.numberPaxMax : selectedService?.numberPaxMax

  const onChangesellPrice = (value: number) => {
    setFieldValue("sellPrice", value)
    setFieldValue("sellPriceTVA", value * 0.20)
    setFieldValue("sellPriceTTC", value * 1.20)
  }

  const onChangePaxPrice = (value: number) => {
    setFieldValue("paxPrice", value)
    onChangesellPrice(values?.quantity * value)
  }

  const onChangeQuantity = (value: number) => {
    setFieldValue("quantity", value)
    onChangesellPrice(value * values?.paxPrice)
  }

  const updateNumberOfResource = (pax: number) => {
    return paxMax ? Math.ceil(pax / (paxMax)) : 0
  }

  const updatePaxRelatedFields = (value: number) => {
    const requiredResources = updateNumberOfResource(value);
    if (values.unitType === UNIT_TYPE.PAX) {
      onChangeQuantity(value)
    } else {
      onChangeQuantity(requiredResources)
    }
  }

  const onChangePax = (value: number) => {
    setFieldValue("pax", value)
    updatePaxRelatedFields(value)
  }

  const onChangeUnitTypePrice = (value: string) => {
    if (value === UNIT_TYPE.PAX && values?.unitType === UNIT_TYPE.FIXED_RATE) {
      const newQuantity = (paxMax ?? values?.pax) * values?.quantity
      setFieldValue("quantity", newQuantity)
      onChangesellPrice(newQuantity * values?.paxPrice)

    } else if (values?.unitType === UNIT_TYPE.PAX && value === UNIT_TYPE.FIXED_RATE) {
      const quantity = Math.ceil(values?.quantity / (paxMax ?? values?.pax));
      setFieldValue("quantity", quantity)
      onChangesellPrice(quantity * values?.paxPrice)
    }

    setFieldValue("unitType", value)
  }

  useEffect(() => {
    if(!values?.id) {
      onChangePax(values.pax)
    }
  }, [])

  return (
    <div className={className}>

      <Row>
        <Col xs={24} sm={12}>
          <FormInput
            id="pax"
            label="quote_item_pax"
            type="number"
            value={values?.pax}
            onChange={(e) => onChangePax(Number(e.target.value))}
            required
            error={errors.pax}
            touched={touched.pax}
          />
        </Col>
      </Row>

      <FormRadio
        id="unitType"
        label="quote_item_unit_type"
        value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
        options={UNIT_TYPE_OPTIONS}
        onChange={(e) => onChangeUnitTypePrice(e.value)}
        required
        error={errors.unitType}
        touched={touched.unitType}
      />

      <FormInput
        id="quantity"
        label="quote_item_quantity"
        type="number"
        value={values?.quantity}
        onChange={(e) => onChangeQuantity(Number(e.target.value))}
        required
        error={errors.quantity}
        touched={touched.quantity}
      />

      <PriceInput
        id="paxPrice"
        label="quote_item_pax_price"
        value={values?.paxPrice}
        onChange={(e) => onChangePaxPrice(Number(e.target.value))}
        error={errors.paxPrice}
        touched={touched.paxPrice}
        required
      />

      <FormInput
        id="sellPrice"
        label="quote_item_sell_price"
        type="number"
        value={numberUtils.formatPrice(values?.sellPrice)}
        onChange={(e) => onChangesellPrice(Number(e.target?.value))}
        error={errors.sellPrice}
        touched={touched.sellPrice}
        required
        disabled
      />

      <FormInput
        id="sellPriceTVA"
        label="quote_item_sell_price_tva"
        type="number"
        value={numberUtils.formatPrice(values?.sellPrice * 0.20)}
        disabled
      />

      <FormInput
        id="total"
        label="quote_item_sell_price_total"
        type="number"
        value={numberUtils.formatPrice(values?.sellPrice * 1.20)}
        disabled
      />

      <FormRadio
        id="disbursements"
        label="quote_item_disbursements"
        value={YES_NO_OPTIONS.find((option) => option?.value === values?.disbursements?.toString())}
        options={YES_NO_OPTIONS}
        onChange={(e) => setFieldValue("disbursements", e.value)}
        required
        error={errors.disbursements}
        touched={touched.disbursements}
      />
    </div>
  )
}

export default CreateQuoteItemFormStepTwo;
