import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ActivityStaffingStatus} from "../../../../constants/ActivityStaffingConstants";
import {StaffedResource} from "../../../../interfaces/ActivityStaffingInterface";
import {orderItemService} from "../../../../services/OrderItemService";
import {ColorType, IconSizeType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import FieldLabel from "../../../atoms/FieldLabel";
import Icon from "../../../icon/Icon";
import StaffingFormResourceSelector from "./StaffingFormResourceSelector";
import {ResourceForStaffingDto, ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";

interface StaffingReplaceFormProps {
  className?: string,
  onFormOpen: (open: boolean) => void,
  orderItemId: string,
  orderItemName: string,
  resourceToReplace: StaffedResource,
  onReplace: () => void,
}

const StaffingReplaceForm: FunctionComponent<StaffingReplaceFormProps> = ({
  className = "",
  onFormOpen,
  orderItemId,
  orderItemName,
  resourceToReplace,
  onReplace,
}) => {
  const intl = useIntl()
  const [selectedResource, setSelectedResource] = useState<ResourceForStaffingDto>(null);

  const onSelect = (resource: ResourceForStaffingDto) => {
    setSelectedResource(resource)
  }

  const deselectReplacement = () => {
    setSelectedResource(null)
  }

  const replaceResource = () => {
    const body: ResourceStaffingRequest = {
      resourceId: selectedResource.id,
      status: ActivityStaffingStatus.CONFIRMED,
    }

    orderItemService.updateStaffingForOrderItem(orderItemId, resourceToReplace.staffingId, body)
      .then(() => {
        onFormOpen(false)
        onReplace()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      })
  }

  return (
    <div className={`d-flex flex-column flex-shrink w-100 ${className}`}>

      <div className="d-flex flex-column mb-1">
        <FieldLabel
          label={intl.formatMessage({id: "order_item_activity"})}
          value={orderItemName}
        />
        <FieldLabel
          label={intl.formatMessage({id: "staffing_request_to_replace"})}
          value={`${resourceToReplace?.firstName} ${resourceToReplace?.lastName}`}
        />
      </div>

      {selectedResource && (
        <div>
          <div className="bold mb-1"><FormattedMessage id="staffing_request_replacement" /> :</div>

          <div className="d-flex">
            <Icon className="m-0 me-2" name="User" size={IconSizeType.XS} />
            <div>{`${selectedResource?.firstName} ${selectedResource?.lastName}`}</div>
            <Icon className="cursor-pointer m-0 ms-auto" name="Trash" size={IconSizeType.XS} onClick={deselectReplacement} />
          </div>
        </div>
      )}

      <StaffingFormResourceSelector
        className="mt-3"
        orderItemId={orderItemId}
        selectedResources={[selectedResource]}
        isDirectStaffing={true}
        onSelect={onSelect}
      />

      <div className="d-flex justify-content-end mt-3">
        <Button size={SizeType.MD} onClick={() => onFormOpen(false)} color={ColorType.SECONDARY} className="extended me-2">
          <FormattedMessage id="cancel_button" />
        </Button>

        <Button disabled={!resourceToReplace} size={SizeType.MD} onClick={replaceResource} className="extended">
          <FormattedMessage id={"validate_button"} />
        </Button>
      </div>

    </div>
  )
}

export default StaffingReplaceForm;
