import React, {FunctionComponent} from "react";
import {ToggleListElement} from "../../../../interfaces/inputs/ToggleListInterfaces";
import {Col, Input, Row} from "reactstrap";
import Icon from "../../../icon/Icon";
import {ColorType, IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {IconName} from "../../../icon/IconsMap";
import TextLink from "../../../atoms/TextLink";
import {FormattedMessage} from "react-intl";

interface ToggleListColumnProps {
  className?: string,
  elements: ToggleListElement[]
  setSelected: (element: ToggleListElement, selected: boolean) => void,
  selectAll: (selected: boolean) => void,
  iconName: IconName,
  iconColor: ColorType,
  onClick: (elements: ToggleListElement[]) => void,
  onSeeMore: (element: ToggleListElement) => void,
}

const ToggleListColumn: FunctionComponent<ToggleListColumnProps> = ({
  className = "",
  elements,
  onClick,
  onSeeMore,
  iconName,
  iconColor,
  setSelected,
  selectAll,
}) => {

  const switchAll = () => {
    const selectedElements = elements.filter(element => element.selected)
    onClick(selectedElements)
  }

  return (
    <div className={className}>
      <div className="d-none d-md-flex">
        <Input
          className="ms-2 m-0"
          type="checkbox"
          checked={elements.length > 0 && elements.every(element => element.selected)}
          onChange={(value) => selectAll(value.target.checked)}
        />
        <div className="ms-auto me-2">
          <Icon name={iconName} size={IconSizeType.XS} color={iconColor} className="me-2 mb-1 cursor-pointer" onClick={() => switchAll()} />
        </div>
      </div>
      <hr className="mt-0 mb-2 d-none d-md-block" />
      {elements?.map(element => (
        <Row key={element.id} className="toggle-list-row">
          <Col xs={0} md={2}>
            <Input
              className="m-0 d-none d-md-block"
              type="checkbox"
              checked={element.selected}
              onChange={(value) => setSelected(element, value.target.checked)}
            />
          </Col>

          <Col xs={22} md={20}>
            {element.description ? (
              <span onClick={() => onSeeMore(element)} className="cursor-pointer">
                {element.name} - {<TextLink to="#" className="color-secondary"><FormattedMessage id="activity_more_info" /></TextLink>}
              </span>
            ) : (
              <span >{element.name}</span>
            )}
          </Col>

          <Col xs={2}>
            <Icon name={iconName} size={IconSizeType.XS} color={iconColor} className="me-2 float-end cursor-pointer" onClick={() => onClick([element])} />
          </Col>
        </Row>
      ))}
    </div>
  )
}

export default ToggleListColumn;
