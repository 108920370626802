import Button from "components/atoms/Button";
import SupplierBankAccountList from "components/molecules/list/SupplierBankAccountList";
import Panel from "components/molecules/panel/Panel";
import {STATUS_NOT_FOUND} from "constants/HttpStatusConstants";
import {BankAccount} from "interfaces/BankAccountInterface";
import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {bankAccountService} from "services/BankAccountService";
import {ColorType} from "types/bootstrap/BootstrapType";
import {toastUtils} from "utils/toastUtils";
import CreateBankAccountForm from "../../form/CreateBankAccountForm";

interface SupplierBankAccountsCardProps {
  className?: string;
  supplierId?: string;
}

const SupplierBankAccountsCard: FunctionComponent<
  SupplierBankAccountsCardProps
> = ({ className = "", supplierId }) => {
  const intl = useIntl();
  const [supplierBankAccounts, setSupplierBankAccounts] = useState<
    BankAccount[]
  >([]);
  const [lateralPanelSupplierBankAccount, setLateralPanelSupplierBankAccount] =
    useState<boolean>(false);

  const getSupplierBankAccounts = () => {
    bankAccountService
      .getSupplierBankAccounts(supplierId)
      .then((bankAccounts: BankAccount[]) => {
        setSupplierBankAccounts(bankAccounts);
      })
      .catch((error: Response) => {
        if (error.status !== STATUS_NOT_FOUND) {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_get_bank_accounts" })
          );
        }
      });
  };

  const onCreate = (bankAccount: BankAccount) => {
    setLateralPanelSupplierBankAccount(false);
    
    const bankAccountsUpdated = [...supplierBankAccounts, bankAccount];
    setSupplierBankAccounts(bankAccountsUpdated);
  };

  const onUpdate = (bankAccount: BankAccount) => {
    setLateralPanelSupplierBankAccount(false);

    const bankAccountsUpdated = supplierBankAccounts.map(
      (supplierBankAccount) =>
        supplierBankAccount.id === bankAccount.id
          ? bankAccount
          : supplierBankAccount
    );

    setSupplierBankAccounts(bankAccountsUpdated);
  };

  const onDelete = (bankAccountId: string) => {
    const bankAccountsUpdated = supplierBankAccounts.filter(
      (bankAccount) => bankAccount.id != bankAccountId
    );

    setSupplierBankAccounts(bankAccountsUpdated);
  };

  const onUpdateDefaultBankAccount = (bankAccountId: string) => {
    const bankAccountsWithNewDefault = supplierBankAccounts.map(
      (bankAccount) => ({
        ...bankAccount,
        isDefault: bankAccount.id === bankAccountId,
      })
    );

    bankAccountsWithNewDefault.sort(
      (a, b) => Number(b.isDefault) - Number(a.isDefault)
    );

    setSupplierBankAccounts(bankAccountsWithNewDefault);
  };

  useEffect(() => {
    getSupplierBankAccounts();
  }, []);

  return (
    <>
      <SupplierBankAccountList
        className={`${className} mb-3`}
        supplierBankAccounts={supplierBankAccounts}
        supplierId={supplierId}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onUpdateDefaultBankAccount={onUpdateDefaultBankAccount}
      />
      <div>
        <Button
          color={ColorType.SECONDARY}
          onClick={() => setLateralPanelSupplierBankAccount(true)}
          className="extended"
        >
          <FormattedMessage id="add_bank_account" />
        </Button>
      </div>
      {lateralPanelSupplierBankAccount && (
        <Panel
          formId="createBankAccountForm"
          title="add_bank_account"
          open={lateralPanelSupplierBankAccount}
          onCancel={() => setLateralPanelSupplierBankAccount(false)}
        >
          <CreateBankAccountForm
            id="createBankAccountForm"
            supplierId={supplierId}
            onCreate={onCreate}
          />
        </Panel>
      )}
    </>
  );
};

export default SupplierBankAccountsCard;
