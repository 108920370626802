import {API_RESOURCE_STAFFING_PATH} from "../constants/routes/RoutePaths"
import {ResourceForStaffingDto, ResourceStaffingSearchParams} from "../interfaces/ResourceStaffingInterfaces"
import {queryUtils} from "../utils/queryUtils"
import {httpService} from "./HttpService"
import {ResourceAvailabilityType} from "../constants/ResourceConstants";

function getResourcesForStaffing(orderItemId: string, filter?: ResourceStaffingSearchParams): Promise<ResourceForStaffingDto[]> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get<ResourceForStaffingDto[]>(`${API_RESOURCE_STAFFING_PATH}/${orderItemId}${queryParams}`)
}

function getAllResourcesForStaffing(filter?: ResourceStaffingSearchParams): Promise<ResourceForStaffingDto[]> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get<ResourceForStaffingDto[]>(`${API_RESOURCE_STAFFING_PATH}${queryParams}`)
}

function getHighestAvailability(filter?: ResourceStaffingSearchParams): Promise<ResourceAvailabilityType> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get<ResourceAvailabilityType>(`${API_RESOURCE_STAFFING_PATH}/availability${queryParams}`)
}

export const resourceStaffingService = Object.freeze({
  getResourcesForStaffing,
  getAllResourcesForStaffing,
  getHighestAvailability,
})
