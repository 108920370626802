import React, {ChangeEvent, FunctionComponent, useEffect, useRef} from "react";
import Panel from "../../panel/Panel";
import FieldLabel from "../../../atoms/FieldLabel";
import {dateUtils} from "../../../../utils/dateUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {OrderItemDetailsResponse, OrderItemResponse} from "../../../../interfaces/OrderItemInterfaces";
import {bookingService} from "../../../../services/BookingService";
import {fileUtils} from "../../../../utils/fileUtils";
import {orderUtils} from "../../../../utils/orderUtils";
import IconLabelCard from "../../../atoms/IconLabelCard";
import {Col, Row} from "reactstrap";
import OtherActivities from "./OtherActivities";
import ActivityPanelDocumentsList from "../../list/ActivityPanelDocumentsList";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import {toastUtils} from "../../../../utils/toastUtils";
import {FileType} from "../../../../constants/FileConstants";
import {getProfilePicture} from "../../../../constants/routes/RoutePaths";
import Avatar from "../../images/Avatar";
import ResourceCircle from "../../circle/ResourceCircle";
import { SimpleStaffing } from "interfaces/ActivityStaffingInterface";

interface ActivityPanelProps {
  className?: string,
  open: boolean,
  setOpen: (open: boolean) => void,
  orderItemId: string,
  profile: PROFILE,
}

const ActivityPanel: FunctionComponent<ActivityPanelProps> = ({
  className = "",
  open,
  setOpen,
  orderItemId,
  profile,
}) => {
  const intl = useIntl();
  const [selectedOrderItem, setSelectedOrderItem] = React.useState<OrderItemDetailsResponse>(null)
  const [selectedLinkedOrderItems, setSelectedLinkedOrderItems] = React.useState<OrderItemResponse[]>([])
  const hiddenVoucherInput = useRef<HTMLInputElement>(null);

  const loadOrderItem = (item: OrderItemDetailsResponse) => {
    orderItemService?.getOrderItemsPage({pageSize: 1000, sortOptions: [{sortBy: "rendezVousHour", descending: false}]}, {
      notificationForActivity: true,
      startDate: item.rendezVousDate,
      endDate: item.rendezVousDate,
      customersIds: [item.customerId],
      checkHasStaffing: true,
      orderIds: [item.order.id]
    }).then((response) => {
      setSelectedOrderItem(item)
      setSelectedLinkedOrderItems(response.content.filter((orderItem) => orderItem.id !== item.id))
    }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "order_item_fetch_error"})))
  }

  const getOrderItem = () => {
    if (profile === PROFILE.RESOURCE) {
      orderItemService?.getOrderItemForResource(orderItemId).then((res) => {
        loadOrderItem(res)
      }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "order_item_fetch_error"})));
    } else {
      orderItemService?.getOrderItem(orderItemId).then((res) => {
        loadOrderItem(res)
      }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "order_item_fetch_error"})));
    }
  }

  useEffect(() => {
    if (orderItemId) {
      getOrderItem()
    }
  }, [orderItemId])


  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = bookingService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  const onChangeOrderItemFile = (files: FileList, fileType: FileType) => {
    orderItemService?.addFiles(orderItemId, files, {fileType})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_add_order_item_file"}));
        getOrderItem();
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_booking_document"}))
      });
  };

  const groupedKeys = selectedOrderItem?.parallelStaffings?.reduce((group: {[key: string]: SimpleStaffing[]}, item:SimpleStaffing) => {
    if (!group[item?.activityName]) {
     group[item?.activityName] = [];
    }
    group[item?.activityName].push(item);
    return group;
   }, {});

  return (
    <div className={className}>
      {open && selectedOrderItem && <Panel
        title={`${selectedOrderItem?.name}`}
        cancelButtonLabel="close"
        displayValidateButton={false}
        open={open}
        onCancel={() => setOpen(false)}>
        <div className="d-flex flex-column w-100 fs-14">
          <FieldLabel
            className="mb-2"
            defaultDisplayClassName="text-muted"
            labelBold={false}
            value={orderUtils.getOrderRefDisplay(selectedOrderItem?.orderNumber, selectedOrderItem?.order?.customerReference, selectedOrderItem?.customerName)}
            label={intl.formatMessage({id: "booking_form_client_order"})} />
          <FieldLabel
            className="mb-2"
            defaultDisplayClassName="text-muted"
            labelBold={false}
            value={selectedOrderItem?.order?.orderComment}
            label={intl.formatMessage({id: "dashboard_panel_activity_comment"})} />
          <FieldLabel
            className="mb-2"
            defaultDisplayClassName="text-muted"
            labelBold={false}
            value={selectedOrderItem?.rendezVousPlace}
            label={intl.formatMessage({id: "dashboard_panel_activity_rendez_vous_place"})} />

          <FieldLabel
            className="mb-2"
            defaultDisplayClassName="text-muted"
            labelBold={false}
            value={dateUtils.formatLocalTime(selectedOrderItem?.departureHour)}
            label={intl.formatMessage({id: "dashboard_panel_activity_departure_hour"})} />

          <div className="d-flex gap-3 mb-2">
            <IconLabelCard
              iconName="Calendar"
              label={`${dateUtils.formatToLocalDateString(dateUtils?.parseDate(selectedOrderItem?.rendezVousDate))} | ${dateUtils?.formatLocalTime(selectedOrderItem?.rendezVousHour)}`}
              hasBackground
            />
            <IconLabelCard iconName="User" label={selectedOrderItem?.pax?.toString()} hasBackground />
            <IconLabelCard iconName="Alarm" label={dateUtils?.formatDuration(selectedOrderItem?.duration)} hasBackground />
          </div>

          <Row className="mb-2">
            <Col className="col-24 col-md-auto">
              <div className="d-flex flex-column">
                <span className="text-muted">{intl.formatMessage({id: "dashboard_panel_activity_staffing"})}</span>
              </div>

              {selectedOrderItem?.simpleStaffingList?.map(simpleStaffing => (
                <div key={simpleStaffing?.fullName} className="d-flex align-items-center">
                  <div className={"d-flex align-items-center p-2"}>
                    <ResourceCircle resourceType={simpleStaffing.resourceType} className="me-2" />
                    <Avatar url={getProfilePicture(simpleStaffing?.resourceId)}  width={24} height={24} className="m-0 me-2"/>
                    <span>{simpleStaffing?.fullName}</span>
                  </div>
                  <span>{simpleStaffing?.phoneNumber}</span>
                </div>
              )
              ) ?? ""}
            </Col>
            <Col className="col-md-auto ms-2">
              <div className="d-flex flex-column">
                <span className="text-muted">{intl.formatMessage({id: "dashboard_panel_activity_tour_leader"})}</span>
              </div>

              <div className="d-flex align-items-center">
                <IconLabelCard iconName="TourLeader" className="ps-0" label={selectedOrderItem?.tourLeaderName || "-"} />
                <span>{selectedOrderItem?.tourLeaderPhoneNumber}</span>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-column mb-2">
            <span className="text-muted"><FormattedMessage id="dashboard_panel_activity_documents" /></span>
            {selectedOrderItem?.documents?.length > 0 ? selectedOrderItem?.documents?.map(document => {
              const fullName = `${document?.name}.${document?.extension}`
              return (
                <div key={document?.fileId} className="d-flex justify-content-between align-items-center mb-2">
                  <div>
                    <span
                      className="epow-text-link cursor-pointer me-3"
                      onClick={() => downloadDocument(document?.fileId, document?.name, document?.extension)}>
                      {fullName}
                    </span>
                  </div>
                </div>
              )
            })
              : <span className="bold">-</span>
            }
            {profile !== PROFILE.TOUR_LEADER &&
            <div className="d-flex">
              <div onClick={() => hiddenVoucherInput?.current?.click()} className="epow-text-link cursor-pointer">
                <FormattedMessage id={"order_item_add_voucher"}/>
              </div>
              <input
                className="d-none"
                type="file"
                ref={hiddenVoucherInput}
                multiple
                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeOrderItemFile(e?.target?.files, FileType.VOUCHER)}/>
            </div>}
          </div>

          <FieldLabel
            verticalDisplay
            className="mb-2"
            valueBold={false}
            value={selectedOrderItem?.description}
            label={intl.formatMessage({id: "dashboard_panel_activity_description"})} />

          {selectedLinkedOrderItems?.length > 0 && <div>
            <p className="fs-16 text-muted"><FormattedMessage id="dashboard_for_your_information" /></p>

            {selectedLinkedOrderItems?.map((item) => (
              <OtherActivities key={item?.id} item={item} />
            ))}
          </div>}


          <div className="d-flex flex-column">
            <span className={"text-muted"}>{intl.formatMessage({id: "dashboard_panel_activity_additional_infos"})}</span>
            <ActivityPanelDocumentsList documents={selectedOrderItem?.additionalDocuments} />
          </div>

          <Row className="mb-2">
            <Col className="col-24 col-md">
              <div className="d-flex flex-column">
                <span className="text-muted">{intl.formatMessage({id: "dashboard_panel_staffing_colleague"})}</span>
              </div>
              {Object.keys(groupedKeys).map(activityName => (
                  <div key={activityName} className="activity-group">
                    <span className="bold">{activityName}</span>
                    {groupedKeys[activityName].map(item => (
                      <div key={item.resourceId} className="d-flex align-items-center">
                        <div className="d-flex align-items-center p-2">
                          <Avatar url={getProfilePicture(item.resourceId)} width={24} height={24} className="m-0 me-2"/>
                          <span>{item.fullName}</span>
                        </div>
                        <span>{item.phoneNumber}</span>
                      </div>
                    ))}
                  </div>
                ))}
            </Col>
          </Row>
        </div>
      </Panel>
      }
    </div>
  );
}

export default ActivityPanel
