import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import { Quote } from "interfaces/QuoteInterfaces";
import { quoteService } from "services/QuoteService";
import {useIntl} from "react-intl";
import {toastUtils} from "../../utils/toastUtils";
import Layout from "../../components/layout/Layout";
import QuoteHeader from "../../components/molecules/header/QuoteHeader";
import QuoteInfosCard from "../../components/molecules/card/quote/QuoteInfosCard";
import {QuoteItem} from "../../interfaces/QuoteItemInterfaces";

const QuoteView: FunctionComponent<RouteProps> = () => {
  const params = useParams();
  const intl = useIntl()
  const [quote, setQuote] = useState<Quote>(null)
  const [quoteItems, setQuoteItems] = useState<QuoteItem[]>(quote?.quoteItems ?? [])

  const refeshQuoteItems = () => {
    quoteService.getQuoteById(params.id)
      .then((quote) => {
        setQuoteItems(quote?.quoteItems)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_get"}))
      })
  }

  const getQuoteById = () => {
    quoteService.getQuoteById(params.id)
      .then((quote) => {
        setQuote(quote)
        setQuoteItems(quote?.quoteItems)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_quote_get"}))
      })
  }

  useEffect(() => {
    getQuoteById()
  }, [])

  return (
    <Layout>
      {quote && (
        <div className="epow-content-body">
          <QuoteHeader className="mb-2" quote={quote} onChangeQuote={setQuote} onChangeQuoteItems={setQuoteItems} onDelete={getQuoteById}/>
          <QuoteInfosCard quote={quote} quoteItems={quoteItems} refeshQuoteItems={refeshQuoteItems} />
        </div>
      )}
    </Layout>
  )
}

export default QuoteView
