import {resourceService} from "../../services/ResourceService";
import {toastUtils} from "../../utils/toastUtils";
import {RESOURCES_PATH} from "../../constants/routes/RoutePaths";
import {WorkflowStatesEnum} from "../../constants/workflow/WorkflowStates";
import {Resource} from "../../interfaces/ResourceInterfaces";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";

interface useRessourceWorkflowInstance {
  handleDeleteButton: () => void,
  handleReactivateButton: () => void,
  handleDeactivateButton: () => void,
  handleActivateButton: () => void,
  handleValidateButton: () => void,
}

function useResourceWorkflow(resource: Resource, onChangeResource: (resource: Resource) => void): useRessourceWorkflowInstance {

  const intl = useIntl();
  const navigate = useNavigate();
  const handleDeleteButton = () => {
    resourceService.deleteResource(resource.id).then(() => {
      toastUtils.infoToast(intl.formatMessage({id: "workflow_delete_info"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
      navigate(RESOURCES_PATH)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_delete_failure"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
    })
  }

  const handleActivateButton = () => {
    resourceService.updateResource(resource.id, {...resource, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Resource) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
        onChangeResource(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
    })
  }

  const handleDeactivateButton = () => {
    resourceService.updateResource(resource.id, {...resource, currentState: WorkflowStatesEnum.INACTIVE})
      .then((response: Resource) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_deactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
        onChangeResource(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_deactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
    })
  }

  const handleReactivateButton = () => {
    resourceService.updateResource(resource.id, {...resource, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Resource) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_reactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
        onChangeResource(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_reactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_resource"}) }))
    })
  }

  const handleValidateButton = () => {
    resourceService.validate()
      .then((response: Resource) => {
        localStorage.setItem("profileValidated", "true")
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info_self"}))
        onChangeResource(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure_self"}))
    })
  }


  return {
    handleDeleteButton,
    handleReactivateButton,
    handleDeactivateButton,
    handleActivateButton,
    handleValidateButton
  }
}

export default useResourceWorkflow;
