import {ReccurenceOption} from "../interfaces/BlackDateInterface";


export const BLACK_DATE_RECURRENCE = {
    EACH_DAY_OF_WEEK: "EACH_DAY_OF_WEEK"
} as const


export type BlackDateRecurrence = typeof BLACK_DATE_RECURRENCE[keyof typeof BLACK_DATE_RECURRENCE];

export const RECURRENCE_OPTIONS: (ReccurenceOption)[] = [
    {value: "EACH_MONDAY", label: "each_monday", type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK, temporalValues: ["2"]},
    {value: "EACH_TUESDAY", label: "each_tuesday", type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK, temporalValues: ["3"]},
    {
        value: "EACH_WEDNESDAY",
        label: "each_wednesday",
        type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK,
        temporalValues: ["4"]
    },
    {
        value: "EACH_THURSDAY",
        label: "each_thursday",
        type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK,
        temporalValues: ["5"]
    },
    {value: "EACH_FRIDAY", label: "each_friday", type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK, temporalValues: ["6"]},
    {
        value: "EACH_SATURDAY",
        label: "each_saturday",
        type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK,
        temporalValues: ["7"]
    },
    {value: "EACH_SUNDAY", label: "each_sunday", type: BLACK_DATE_RECURRENCE.EACH_DAY_OF_WEEK, temporalValues: ["1"]},
]
