import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {Customer} from "../interfaces/CustomerInterfaces";
import {customerService} from "../services/CustomerService";
import {toastUtils} from "../utils/toastUtils";
import TabList from "../components/molecules/tab/TabList";
import {customerTab} from "../constants/tabs/TabListConstants";
import {FormattedMessage, useIntl} from "react-intl";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import {CUSTOMERS_PATH, ORDERS_PATH, TOUR_LEADERS_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsEnum, WorkflowStateMap, WorkflowStatesEnum, } from "../constants/workflow/WorkflowStates";
import Badge from "../components/atoms/Badge";
import PageTitle from "../components/molecules/header/PageTitle";
import TextLink from "../components/atoms/TextLink";
import {ORDER_STATUS_PENDING} from "../interfaces/OrderInterfaces";

const CustomerView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {id} = useParams();

  const [customer, setCustomer] = useState<Customer>(null);

  const getCustomerById = () => {
    customerService
      .getCustomerById(id)
      .then((customer) => {
        setCustomer(customer);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage({id: "error_toast_get_one_customer"})
        );
      });
  };

  const handleDeleteButton = () => {
    customerService
      .deleteCustomer(customer.id)
      .then(() => {
        toastUtils.infoToast(
          intl.formatMessage(
            {id: "workflow_delete_info"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
        navigate(CUSTOMERS_PATH);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            {id: "workflow_delete_failure"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
      });
  };

  const handleActivateButton = () => {
    customerService
      .updateCustomer(customer.id, {
        ...customer,
        currentState: WorkflowStatesEnum.ACTIVE,
      })
      .then((response: Customer) => {
        toastUtils.infoToast(
          intl.formatMessage(
            {id: "workflow_validate_info"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
        setCustomer(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            {id: "workflow_validate_failure"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
      });
  };

  const handleDeactivateButton = () => {
    customerService
      .updateCustomer(customer.id, {
        ...customer,
        currentState: WorkflowStatesEnum.INACTIVE,
      })
      .then((response: Customer) => {
        toastUtils.infoToast(
          intl.formatMessage(
            {id: "workflow_deactivate_info"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
        setCustomer(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            {id: "workflow_deactivate_failure"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
      });
  };

  const handleReactivateButton = () => {
    customerService
      .updateCustomer(customer.id, {
        ...customer,
        currentState: WorkflowStatesEnum.ACTIVE,
      })
      .then((response: Customer) => {
        toastUtils.infoToast(
          intl.formatMessage(
            {id: "workflow_reactivate_info"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
        setCustomer(response);
      })
      .catch(() => {
        toastUtils.errorToast(
          intl.formatMessage(
            {id: "workflow_reactivate_failure"},
            {entity: intl.formatMessage({id: "workflow_entity_client"})}
          )
        );
      });
  };

  useEffect(() => {
    getCustomerById();
  }, []);

  return (
    <Layout>
      {customer && (
        <div className="epow-content-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <PageTitle
                pageTitle={customer.name}
                className="me-4"
                breadcrumbItems={[{
                  title: intl.formatMessage({id: "customer_title"}),
                  to: CUSTOMERS_PATH
                }]}
              />
              <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
              <Badge pill color={WorkflowStateMap[customer.currentState]?.color} >
                {intl.formatMessage({id: WorkflowStateMap[customer.currentState]?.label || "STATUS_DEFAULT"})}
              </Badge>
            </div>
            <WorkflowButtonList
              currentState={customer.currentState}
              entityLabel={intl.formatMessage({id: "workflow_entity_client"})}
              actions={{
                [WorkflowActionsEnum.DELETE]: handleDeleteButton,
                [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
                [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
                [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton,
              }}
            />
          </div>
          <div className="d-flex mb-3">
            <TextLink className="header-indicator-link" to={`${ORDERS_PATH}?customerId=${customer.id}&status=${ORDER_STATUS_PENDING.join(",")}`}>
              <span className="fs-5 bold me-3">{customer.orderCount || 0}</span> <span>{intl.formatMessage({id: "orders_title"})}</span>
            </TextLink>
            <TextLink className="header-indicator-link ms-2" to={`${TOUR_LEADERS_PATH}?customerId=${customer.id}`}>
              <span className="fs-5 bold me-3">{customer.tourLeaderCount || 0}</span> <span>{intl.formatMessage({id: "tour_leader_title"})}</span>
            </TextLink>
          </div>
          <TabList
            items={customerTab(customer, setCustomer)}
          />
        </div>
      )}
    </Layout>
  );
};

export default CustomerView;
