import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import Card from "../../atoms/Card";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import BackgroundIcon from "../../icon/BackgroundIcon";
import {IconName} from "../../icon/IconsMap";

interface Task {
  icon: IconName;
  number: string;
  title: string;
  onClick: () => void,
}

interface NextTasksProps {
  tasks: Task[]
}

const NextTasks: FunctionComponent<NextTasksProps> = ({tasks = []}) => (
  <div className="epow-dashboard-next-tasks">
    <h3 className="fs-16"><FormattedMessage id="dashboard_next_tasks"/></h3>
    <div className="d-flex flex-row flex-wrap next-task-wrapper">
      {tasks.map(task => (
        <Card key={task.title} className="next-task me-3 mt-2 mb-3 cursor-pointer" onClick={task.onClick}>
          <BackgroundIcon
            className="d-flex align-item-center rounded-circle bg-theme-light border-light border mb-2 mt-1"
            iconName={task.icon}
            size={42}
            color="secondary"
            iconSize={IconSizeType.SM}/>
          <h4 className="fw-bold mt-2">{task.number}</h4>
          <span className="fs-12 color-gray">{task.title}</span>
        </Card>
      ))}
    </div>
  </div>)


export default NextTasks;
