import {MutableRefObject, useEffect, useRef, useState} from "react";

interface OuterClickProps {
  ref?: MutableRefObject<HTMLHeadingElement>,
  isComponentVisible?: boolean,
  setIsComponentVisible?: (isVisible?: boolean) => void
}

function useOuterClick(initialIsVisible: boolean): OuterClickProps {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(initialIsVisible);
  const ref = useRef<HTMLHeadingElement>(null);

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export default useOuterClick;