import React, {FunctionComponent, useState} from "react";
import {SupplierServiceCatalog} from "../../../interfaces/SupplierInterfaces";
import {Col, Row} from "reactstrap";
import FieldLabel from "../../atoms/FieldLabel";
import {dateUtils} from "../../../utils/dateUtils";
import {optionUtils} from "../../../utils/optionUtils";
import {UNIT_TYPE_OPTIONS} from "../../../constants/OptionConstants";
import {numberUtils} from "../../../utils/numberUtils";
import {FormattedMessage, useIntl} from "react-intl";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {supplierServiceService} from "../../../services/SupplierServiceService";
import GenericModalDelete from "../modal/GenericModalDelete";

interface SupplierServiceCatalogListProps {
  className?: string,
  supplierServiceCatalogs?: SupplierServiceCatalog[],
  setLateralPanelSupplierServiceCatalog?: (open?: boolean) => void,
  setInitialSupplierServiceCatalog?: (supplierServiceCatalog?: SupplierServiceCatalog) => void,
  onValidate?: () => void
}

const SupplierServiceCatalogList:FunctionComponent<SupplierServiceCatalogListProps> = ({
  className = "",
  supplierServiceCatalogs,
  setLateralPanelSupplierServiceCatalog,
  setInitialSupplierServiceCatalog,
  onValidate = () => null
}) => {
  const intl = useIntl()
  const [openModalDeleteInfoCatalog, setOpenModalDeleteInfoCatalog] = useState<boolean>(false)

  const handleModalDeleteInfoCatalog = () => {
    setOpenModalDeleteInfoCatalog(false)
    onValidate()
  }

  return (
    <div className={className}>
      {supplierServiceCatalogs?.length > 0 && supplierServiceCatalogs.map((supplierServiceCatalog, key) =>
        <div key={key} className="d-flex flex-row justify-content-between mb-2">
          <Row className="w-100">
            <Col xs={24} md={6}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_start_date_validity"})}
                value={dateUtils.formatToLocalDateString(dateUtils.convertStringToObjectDate(supplierServiceCatalog?.startDateValidity))}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_type"})}
                value={optionUtils.translateOption(intl, UNIT_TYPE_OPTIONS, supplierServiceCatalog?.unitType)}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_number_pax"})}
                value={supplierServiceCatalog?.paxMax}
              />
            </Col>
            <Col xs={24} sm={8} md={6}>
              <FieldLabel
                verticalDisplay
                label={intl.formatMessage({id: "service_unit_price"})}
                value={numberUtils.formatPrice(supplierServiceCatalog?.unitPrice)}
              />
            </Col>
          </Row>
          <div className="mb-2 d-flex flex-column">
            <span className="text-muted"><FormattedMessage id="action_button"/></span>
            <div className="d-flex flex-row">
              <Icon
                name="Pen"
                size={IconSizeType.XS}
                className="cursor-pointer pe-2"
                onClick={() => {
                  setLateralPanelSupplierServiceCatalog(true)
                  setInitialSupplierServiceCatalog(supplierServiceCatalog)
                }}
              />
              <Icon
                name="Trash"
                size={IconSizeType.XS}
                className="cursor-pointer pe-2"
                onClick={() => setOpenModalDeleteInfoCatalog(true)}
              />
            </div>
          </div>
          <GenericModalDelete
            titleId="modal_title_delete_supplier_service_catalog"
            open={openModalDeleteInfoCatalog}
            onValidate={handleModalDeleteInfoCatalog}
            setOpen={setOpenModalDeleteInfoCatalog}
            idEntity={supplierServiceCatalog?.id}
            onDelete={supplierServiceService.deleteSupplierServiceCatalog}
            successToastMessageId="success_toast_delete_supplier_service_catalog"
            errorToastMessageId="error_toast_delete_supplier_service_catalog"
            bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_supplier_service_catalog"})}
          />
        </div>
      )}
    </div>
  )
};

export default SupplierServiceCatalogList
