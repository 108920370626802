import React, {FunctionComponent} from "react";
import {Label} from "reactstrap";

interface CheckboxFilterProps {
  className?: string,
  title?: string,
  value: boolean,
  id?: string,
  onChange: (value: boolean) => void;
}

const CheckboxFilter: FunctionComponent<CheckboxFilterProps> = ({
  className = "",
  title,
  value,
  id,
  onChange,
}) => {
  return (
    <div className={`epow-filter checkbox epow-checkbox ${className}`}>
      <input className="me-2" id={id} type="checkbox" checked={value} onClick={() => onChange(!value)} />
      {title && <Label htmlFor={id} className="epow-label">{title}</Label>}
    </div>
  )
}

export default CheckboxFilter;
