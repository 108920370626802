import React, {forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Column, TableInstance, useTable, Row, usePagination, useSortBy} from "react-table";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {PageResponse, PaginationQueryParams, SortOptions} from "../../../interfaces/api/PaginationInterface";
import {Data} from "../../../interfaces/TableInterfaces";
import Pagination from "../pagination/Pagination";
import {PagedTableRef} from "./PagedTableWithRowSelection";
import TableBody from "./TableBody";
import usePagedTable from "./usePagedTable";

interface PagedTableProps {
  columns: Array<Column>,
  isSortable?: boolean,
  className?: string,
  labelNoResult?: string,
  rowProps?: (row: Row) => Object,
  fetchData?: (params: PaginationQueryParams, filterParams?: SearchFilter) => Promise<PageResponse<Data>>,
  filters?: Object,
  defaultSortBy?: Array<SortOptions>,
  mandatorySortBy?: Array<SortOptions>,
}

const PagedTable: ForwardRefRenderFunction<PagedTableRef, PagedTableProps> = ({
  columns,
  fetchData,
  className = "",
  labelNoResult = "no_row",
  rowProps = () => ({}),
  filters,
  defaultSortBy = [],
  mandatorySortBy = [],
}, ref) => {
  const [pageSize, setPageSize] = useState<number>(50)

  const {
    data,
    total,
    page,
    sortOptions,
    onChangePage,
    onChangeSortOptions,
    refresh,
  } = usePagedTable({
    fetchData,
    options: {
      defaultSortBy,
      mandatorySortBy,
      filters,
      pageSize
    }
  })

  useImperativeHandle(ref, () => ({
    refresh
  }));


  const tableInstance: TableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize
      }
    },
    useSortBy,
    usePagination,
  )

  if (data.length === 0) {
    return (<div className="mt-3"><FormattedMessage id={labelNoResult} /></div>)
  }

  return (
    <>
      <TableBody
        tableInstance={tableInstance}
        className={className}
        rowProps={rowProps}
        sortOptions={sortOptions}
        setSortOptions={onChangeSortOptions}
      />
      <Pagination page={page} setPage={onChangePage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </>
  )
}

export default forwardRef(PagedTable);
