import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import FormInput from "../input/FormInput";
import {toastUtils} from "../../../utils/toastUtils";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import EpowForm from "./EpowForm";
import {InvoiceFormData} from "../../../interfaces/InvoiceInterfaces";
import {INVOICE_INITIAL_VALUES} from "../../../constants/InvoiceConstants";
import {CreateInvoiceSchema} from "../../../constants/validation/InvoiceValidationSchemas";
import {customerService} from "../../../services/CustomerService";
import {invoiceService} from "../../../services/InvoiceService";
import {useNavigate} from "react-router-dom";
import {INVOICES_PATH} from "../../../constants/routes/RoutePaths";
import {LoadingComponent} from "../../../constants/menu/Layout";

interface CreateInvoiceFormProps extends LoadingComponent  {
  id?: string,
  className?: string,
}

const CreateInvoiceForm: FunctionComponent<CreateInvoiceFormProps> = ({
  id,
  className = "",
  startLoading,
  stopLoading,
  closeForm = () => null,
}) => {

  const intl = useIntl();
  const navigate = useNavigate();
  const onSubmit = (formData: InvoiceFormData) => {
    startLoading?.();
    invoiceService.createManualInvoice(formData)
      .then((invoice) => {
        navigate(`${INVOICES_PATH}/${invoice.id}`);
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_invoice"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_invoice"}))
      }).finally(() => {
        stopLoading?.()
        closeForm()
      })
  }

  return (
    <Formik initialValues={INVOICE_INITIAL_VALUES} validationSchema={CreateInvoiceSchema} onSubmit={onSubmit}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormAutocompleteSelectPageable
            id="billingCustomerId"
            label={intl.formatMessage({id: "invoice_customer"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => setFieldValue("billingCustomerId", value)}
            value={values?.billingCustomerId}
            fetchData={customerService.getCustomersPage}
            filterFieldName="fullName"
            filterFieldSearch="name"
            required
            error={errors?.billingCustomerId}
            touched={touched?.billingCustomerId}
          />

          <FormInput
            id="date"
            label="invoice_date"
            type="date"
            required
            value={values?.date}
            onChange={(e) => setFieldValue("date", e.target?.value)}
            error={errors?.date}
            touched={touched?.date}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateInvoiceForm;
