import React, {FunctionComponent} from "react";
import {ResourceStaffingSearchParams} from "../../../../interfaces/ResourceStaffingInterfaces";
import {useIntl} from "react-intl";
import CheckboxFilter from "../CheckboxFilter";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";

interface ModalBulkStaffingFiltersProps {
  searchObject: ResourceStaffingSearchParams,
  setSearchObject: (searchObject: ResourceStaffingSearchParams) => void,
  selectedResourceTypes?: ResourceType[],
}

const ModalBulkStaffingFilters: FunctionComponent<ModalBulkStaffingFiltersProps> = ({
  searchObject,
  setSearchObject,
  selectedResourceTypes
}) => {
  const intl = useIntl()
  const onSearchFieldUpdate = (field: string, value: string | boolean) => {
    const updated = {...searchObject, [field]: value}
    setSearchObject(updated)
  }

  return (
    <>
      <div className="d-flex gap-3 mb-2">
        <div className="bold">
          <span className="me-2">{intl.formatMessage({id: "resource_filter_staffing_to_type"})}</span>
          {selectedResourceTypes?.map((type) => type.label).join(", ") || intl.formatMessage({id: "resource_filter_staffing_to_type_all"})}
        </div>
      </div>

      <div className="d-flex gap-3 mb-2">
        <div className="bold">
          {intl.formatMessage({id: "resource_filter_criteria"})}
        </div>
        <CheckboxFilter
          title={intl.formatMessage({id: "resource_filter_on_public_type"})}
          value={searchObject.filterOnPublicType}
          onChange={(value) => onSearchFieldUpdate("filterOnPublicType", value)}
        />
        <CheckboxFilter
          title={intl.formatMessage({id: "resource_filter_on_kids"})}
          value={searchObject.filterOnKids}
          onChange={(value) => onSearchFieldUpdate("filterOnKids", value)}
        />
      </div>
    </>
  )
}

export default ModalBulkStaffingFilters;
