import React, {FunctionComponent, useMemo} from "react";
import {customerService} from "../../../services/CustomerService";
import {tableUtils} from "../../../utils/tableUtils";
import {CUSTOMER_TABLE} from "../../../constants/Table";
import PagedTable from "../table/PagedTable";
import Button from "../../atoms/Button";
import {useIntl} from "react-intl";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import {CustomerSearchObject} from "../../../interfaces/CustomerInterfaces";
import {ColorType} from "../../../types/bootstrap/BootstrapType";

interface CustomerListProps {
  className?: string;
  searchObject?: CustomerSearchObject;
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const CustomerList: FunctionComponent<CustomerListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl();

  const columns = useMemo(() => tableUtils.getColumns(CUSTOMER_TABLE(intl)), [intl]);

  return (
    <>
      <FilterGroup>
        <TextFilter
          icon="Search"
          className="me-3 main-filter"
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          title={intl.formatMessage({id: "customer_list_search_label"})}
          placeholder={intl.formatMessage({id: "customer_list_search_placeholder"})}
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PagedTable
        columns={columns}
        labelNoResult="customer_no_result"
        className={`${className} table`}
        fetchData={customerService.getCustomersPage}
        filters={searchObject}
        defaultSortBy={[{sortBy: "name"}]}
      />
    </>
  );
};

export default CustomerList;
