import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {PurchaseSearchObject} from "../../../interfaces/PurchaseInterfaces";
import usePaginatedData from "../../../hooks/usePaginatedData";
import {purchaseService} from "../../../services/PurchaseService";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import DateFilter from "../filters/DateFilter";
import PurchaseTable from "../table/purchase/PurchaseTable";
import Pagination from "../pagination/Pagination";
import {supplierService} from "../../../services/SupplierService";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";

interface PurchaseListProps {
  className?: string
  searchObject?: PurchaseSearchObject,
  onReset: () => void,
  updateSearchField: (field: string, value: string) => void
}

const PurchaseList: FunctionComponent<PurchaseListProps> = ({
  className = "",
  searchObject,
  onReset,
  updateSearchField
}) => {
  const intl = useIntl()


  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total
  } = usePaginatedData(purchaseService.getPurchases, searchObject)


  return (
    <div className={`${className} mb-5`}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "purchase_booking_filter_title"})}
          value={searchObject.customerOrPurchase}
          onChange={(value) => updateSearchField("customerOrPurchase", value)}
          placeholder={intl.formatMessage({id: "purchase_filter_customer_order_placeholder"})}
        />
        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("supplierId", value)}
          title={intl.formatMessage({id: "SUPPLIER"})}
          value={searchObject?.supplierId}
          fetchData={supplierService.getSuppliersPaginated}
          filterFieldName="name"
        />
        <DateFilter
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "order_filter_period_start"})}
        />
        <DateFilter
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "order_filter_period_end"})}
        />
        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PurchaseTable purchases={data} sortOptions={sortOptions} onSortOptions={setSortOptions}/>
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default PurchaseList
