import {toastUtils} from "../../utils/toastUtils";
import {TOUR_LEADERS_PATH} from "../../constants/routes/RoutePaths";
import {WorkflowStatesEnum} from "../../constants/workflow/WorkflowStates";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {TourLeader} from "../../interfaces/TourLeaderInterfaces";
import {tourLeaderService} from "../../services/TourLeaderService";

interface useRessourceWorkflowInstance {
  handleDeleteButton: () => void,
  handleReactivateButton: () => void,
  handleDeactivateButton: () => void,
  handleActivateButton: () => void,
}

function useTourLeaderWorkflow(tourLeader: TourLeader, onChangeTourLeader: (tourLeader: TourLeader) => void): useRessourceWorkflowInstance {

  const intl = useIntl();
  const navigate = useNavigate();


  const handleDeleteButton = () => {
    tourLeaderService.deleteTourLeader(tourLeader.id).then(() => {
      toastUtils.infoToast(intl.formatMessage({id: "workflow_delete_info"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
      navigate(TOUR_LEADERS_PATH)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_delete_failure"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
    })
  }

  const handleActivateButton = () => {
    tourLeaderService.updateTourLeader(tourLeader.id, {...tourLeader, currentState: WorkflowStatesEnum.ACTIVE})
      .then((tourLeader) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
        onChangeTourLeader(tourLeader)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
    })
  }

  const handleDeactivateButton = () => {
    tourLeaderService.updateTourLeader(tourLeader.id, {...tourLeader, currentState: WorkflowStatesEnum.INACTIVE})
      .then((tourLeader) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_deactivate_info"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
        onChangeTourLeader(tourLeader)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_deactivate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
    })
  }

  const handleReactivateButton = () => {
    tourLeaderService.updateTourLeader(tourLeader.id, {...tourLeader, currentState: WorkflowStatesEnum.ACTIVE})
      .then((tourLeader) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_reactivate_info"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
        onChangeTourLeader(tourLeader)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_reactivate_failure"}, {entity: intl.formatMessage({id: "workflow_entity_tour_leader"})}))
    })
  }

  return {
    handleDeleteButton,
    handleReactivateButton,
    handleDeactivateButton,
    handleActivateButton
  }
}

export default useTourLeaderWorkflow;
