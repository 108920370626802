import React, {FunctionComponent, useEffect, useState} from "react";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ResourceCalendarInfos, ResourceCalendarSearchFilter} from "../../../interfaces/ResourceCalendarInterfaces";
import {useIntl} from "react-intl";
import {dateUtils} from "../../../utils/dateUtils";
import ResourceCalendarFilters from "../filters/resourcecalendar/ResourceCalendarFilters";
import FilterGroup from "../filters/FilterGroup";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {resourceCalendarService} from "../../../services/resourceCalendarService";
import ResourceCalendarActivityCell from "./ResourceCalendarActivityCell";
import ResourceCalendarResourceCell from "./ResourceCalendarResourceCell";
import ResourceCalendarCell from "./ResourceCalendarCell";
import {ResourceAvailability} from "../../../constants/ResourceConstants";

interface ResourceCalendarProps {
  className?: string,
}

const ResourceCalendar: FunctionComponent<ResourceCalendarProps> = ({
  className = "",
}) => {
  const intl = useIntl();
  const [data, setData] = useState<ResourceCalendarInfos>()

  const initialState = {
    activityLimit: "30",
    resourceLimit: "30",
    from: dateUtils.formatDateYYYYMMDD(new Date()),
    to: "",
    orderId: "",
    tourId: "",
    availability: ResourceAvailability.HIGH,
    activityIds: [],
    activityAreaIds: [],
    resourceIds: [],
  };

  const {
    searchObject,
    onReset,
    reloadSearch,
    updateSearchField
  } = useSearchFilter<ResourceCalendarSearchFilter>(initialState)

  useEffect(() => {
    resourceCalendarService.getResourceCalendar(searchObject)
      .then(r => setData(r))
      .catch(() => intl.formatMessage({id: "error_toast_calendar"}))
  }, [searchObject])

  return (
    <div className={className} >

      <FilterGroup>
        <ResourceCalendarFilters searchObject={searchObject} updateSearchField={updateSearchField} activityIds={data?.activities.map(activity => activity.id)} />
        <div className="d-flex ms-2">
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <div className="resource-calendar mt-4">
        <div className="resource-calendar-column">
          {data?.activities.map(activity => (
            <ResourceCalendarActivityCell key={`${activity.orderItemId}-${activity.id}`} activity={activity} />
          ))}
        </div>

        {data?.resources.map(resource => (
          <div key={resource.id} className="resource-calendar-column">
            <ResourceCalendarResourceCell resource={resource} year={dateUtils.parseDate(searchObject.from)?.getFullYear()} />

            {data?.activities.map(activity => (
              <ResourceCalendarCell
                key={`${resource.id}-${activity.orderItemId}-${activity.id}`}
                activity={activity}
                resource={resource}
                onRefresh={reloadSearch}
              />
            ))}
          </div>
        ))}
      </div>
    </div >
  )
}

export default ResourceCalendar;
