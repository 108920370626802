import React, {FunctionComponent, useMemo} from "react";
import CellUnavailableCalendar from "./cell/CellUnavailableCalendar";
import CellAvailableCalendar from "./cell/CellAvailableCalendar";
import CellStaffCalendar from "./cell/CellStaffCalendar";
import {AvailabilityCalendar} from "../../../../interfaces/CalendarInterface";
import {StaffingCalendarSearchFilter} from "../../staffingcalendar/StaffingCalendar";

interface CellCalendarProps {
    availability: AvailabilityCalendar,
    availabilityType: string,
    className?: string,
    date: string,
    resourceId: string,
    searchObject: StaffingCalendarSearchFilter,
    onReload: () => void,
}

const CellAvailabilityCalendar: FunctionComponent<CellCalendarProps> = ({availability, availabilityType, className, date, resourceId, searchObject, onReload}) => {

    const commonProps = {
        searchObject,
        availabilityType,
        onReload,
    }

    const element = useMemo(() => {
        if (!availability || availability.available) {
            return (<CellAvailableCalendar {...commonProps} availability={availability} date={date} resourceId={resourceId}/>)
        }
        if(availability.staffedActivity != null) {
            return (<CellStaffCalendar {...commonProps} availability={availability} />)
        }
        return (<CellUnavailableCalendar {...commonProps} />)

    }, [availability])

    return <div className={className}>
        {element}
    </div>
}

export default CellAvailabilityCalendar;
