import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {RULE_TABLE} from "../../../constants/Table";
import {Rule} from "../../../interfaces/RuleInterface";
import {ruleService} from "../../../services/RuleService";
import {tableUtils} from "../../../utils/tableUtils";
import {toastUtils} from "../../../utils/toastUtils";
import Button from "../../atoms/Button";
import CreateRuleForm from "../form/CreateRuleForm";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";

interface RuleListProps {
  className?: string;
}

const RuleList: FunctionComponent<RuleListProps> = ({
  className = ""
}) => {
  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const [selectedRule, setSelectedRule] = useState<Rule>(null)
  const [ruleFormOpen, setRuleFormOpen] = useState<boolean>(false)

  const onRuleEditionSelection = (rule: Rule) => {
    setSelectedRule(rule)
    setRuleFormOpen(true)
  }

  const toggleRuleActivation = (id: string, activated: boolean) => {
    ruleService.toggleRuleActivation(id, activated)
      .then((isActivated: boolean) => {
        const toastMessage = isActivated ? "success_toast_activate_rule" : "succes_toast_deactivate_rule"
        toastUtils.successToast(intl.formatMessage({id: toastMessage}))
        ref.current.refresh()
      })
      .catch(() => {
        const toastMessage = activated ? "error_toast_activate_rule" : "error_toast_deactivate_rule"
        toastUtils.errorToast(intl.formatMessage({id: toastMessage}))
      })
  }

  const columns = useMemo(() => tableUtils.getColumns(
    RULE_TABLE(onRuleEditionSelection, toggleRuleActivation, intl)
  ), [intl]);

  const onCreateRule = () => {
    setRuleFormOpen(false)
    ref.current.refresh()
  }

  return (
    <>
      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="customer_no_result"
        className={`${className} table`}
        fetchData={ruleService.getRules}
        filters={{}}
        defaultSortBy={[{sortBy: "id"}]}
      />
      <Button onClick={() => setRuleFormOpen(true)}>
        {intl.formatMessage({id: "create_rule"})}
      </Button>
      {ruleFormOpen && (
        <Panel
          formId="createRuleForm"
          title={selectedRule ? "edit_rule" : "create_rule"}
          open={ruleFormOpen}
          onCancel={() => setRuleFormOpen(false)}
        >
          <CreateRuleForm
            id="createRuleForm"
            initialRule={selectedRule}
            onCreateRule={onCreateRule}
          />
        </Panel>
      )}
    </>
  )
}

export default RuleList;
