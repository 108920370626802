import React, {FunctionComponent} from "react";
import {Supplier, SupplierGeneralInfo, SupplierRequest,} from "../../../interfaces/SupplierInterfaces";
import {useNavigate} from "react-router-dom";
import {supplierService} from "../../../services/SupplierService";
import {SUPPLIERS_PATH} from "../../../constants/routes/RoutePaths";
import {toastUtils} from "../../../utils/toastUtils";
import {Formik} from "formik";
import {SUPPLIER_INITIAL_VALUES} from "../../../constants/SupplierConstants";
import {CreateSupplierSchema} from "../../../constants/validation/SupplierValidationSchemas";
import FormInput from "../input/FormInput";
import {useIntl} from "react-intl";
import {supplierUtils} from "../../../utils/supplierUtils";
import EpowForm from "./EpowForm";
import AddressForm from "./address/AddressForm";
import {LoadingComponent} from "../../../constants/menu/Layout";

interface CreateSupplierFormProps extends LoadingComponent  {
  id?: string;
  className?: string;
  supplierGeneralInfo?: SupplierGeneralInfo;
  setSupplier?: (supplier: Supplier) => void;
  setEditInfo?: (editInfo: boolean) => void;
}

const CreateSupplierForm: FunctionComponent<CreateSupplierFormProps> = ({
  id,
  className = "",
  supplierGeneralInfo,
  setSupplier,
  setEditInfo,
  startLoading,
  stopLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const supplierId = supplierGeneralInfo?.supplierId;


  const handleSubmitSupplier = (
    submittedSupplierGeneralInfo: SupplierGeneralInfo
  ) => {
    const supplierRequest: SupplierRequest =
      supplierUtils.buildSupplierRequest(submittedSupplierGeneralInfo);
    startLoading?.()
    if (supplierId) {
      supplierService
        .updateSupplierGeneralInfo(supplierId, supplierRequest)
        .then((supplier: Supplier) => {
          toastUtils.successToast(
            intl.formatMessage({ id: "success_update_supplier" })
          );
          setEditInfo(false);
          setSupplier(supplier);
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_toast_update_supplier" })
          );
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      supplierService
        .createSupplier(supplierRequest)
        .then((supplier) => {
          toastUtils.successToast(
            intl.formatMessage({ id: "success_create_supplier" })
          );
          navigate(`${SUPPLIERS_PATH}/${supplier?.id}`);
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_toast_create_supplier" })
          );
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  };

  return (
    <Formik
      initialValues={supplierGeneralInfo || SUPPLIER_INITIAL_VALUES}
      validationSchema={CreateSupplierSchema}
      onSubmit={(value) => {
        handleSubmitSupplier(value);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="name"
            label="supplier_name_field"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />
          <AddressForm
            values={values}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
          />
          <FormInput
            id="entityEmail"
            label="supplier_email"
            value={values?.entityEmail}
            onChange={(e) => setFieldValue("entityEmail", e.target.value)}
            error={errors.entityEmail}
            touched={touched.entityEmail}
          />
          <FormInput
            id="entityPhone"
            label="supplier_phone"
            value={values?.entityPhone}
            onChange={(e) => setFieldValue("entityPhone", e.target.value)}
            error={errors.entityPhone}
            touched={touched.entityPhone}
          />

        </EpowForm>
      )}
    </Formik>
  );
};

export default CreateSupplierForm;
