export type SizeTypeValues =
  "xs" |
  "sm" |
  "md" |
  "lg" |
  "xl";

export const enum SizeType {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl"
}


export type PositionTypeValues =
  "right" |
  "left";


export const enum PositionType {
  RIGHT = "right",
  LEFT = "left",
}

export type StickBlocPositionTypeValues =
  "top" |
  "bottom";

export const enum StickBlocPositionType {
  TOP = "top",
  BOTTOM = "bottom",
}

export type ColorTypeValues =
  "primary" |
  "secondary" |
  "tertiary" |
  "warning" |
  "danger" |
  "light" |
  "dark" |
  "green" |
  "gray" |
  "dark-gray" |
  "success"

export const enum ColorType {
  GREEN = "green",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  TERTIARY = "tertiary",
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
  LIGHT = "light",
  GRAY = "gray",
  DARK_GRAY = "dark-gray",
  DARK = "dark",
}

export type ButtonTypeValues =
  "submit" |
  "button";

export const enum ButtonType {
  SUBMIT = "submit",
  BUTTON = "button",
}

export type IconSizeTypeValues =
  "0" |
  "1" |
  "2" |
  "3" |
  "4" |
  "5" |
  "6";

export const enum IconSizeType {
  XXS = "0",
  XS = "1",
  SM = "2",
  MD = "3",
  LG = "4",
  XL = "5",
  XXL = "6",
}

export const enum Placement {
  TOP = "top",
  LEFT = "left",
  RIGHT = "right",
  BOTTOM = "bottom",
}

export const enum IconPlacement {
  LEFT,
  RIGHT,
}

export const enum LogoSizeType {
  XS = 20,
  SM = 40,
  MD = 60,
  LG = 80,
  XL = 100,
  XXL = 140
}

export type TitleSizeTypeValues =
  "h1" |
  "h2" |
  "h3" |
  "h4" |
  "h5" |
  "h6";


export const enum TitleSizeType {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6"
}
