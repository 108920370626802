import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import Messages from "./constants/messages/Messages"
import { IntlProvider } from "react-intl"
import {BrowserRouter} from "react-router-dom"
import { Settings } from "luxon";
import {languageUtils} from "./utils/languageUtils";

let language: string = languageUtils.getNavigatorLanguage()
let messages = Messages[language]
if (!messages) {
  language = "en"
  messages = Messages["en"]
}

Settings.defaultLocale = language;

const root = ReactDOM.createRoot(document.getElementById("root"));
document.documentElement.setAttribute("translate", "no");
root.render(
  <React.StrictMode>
    <IntlProvider locale={language} messages={Messages[language]}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>
);
