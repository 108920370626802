import {PurchaseBookingRequest, PurchasesFromBookingsRequest, PurchaseRequest} from "../interfaces/PurchaseInterfaces";

export const PURCHASE_ORDER_INITIAL_VALUES : PurchaseRequest = {
  supplierId: ""
}

export const PURCHASE_FROM_BOOKINGS_INITIAL_VALUES : PurchasesFromBookingsRequest = {
  bookings: []
}

export const PURCHASE_BOOKING_INITIAL_VALUES : PurchaseBookingRequest = {
  serviceId: "",
  orderId: "",
  orderItemId: "",
  quantity: undefined,
  price: undefined,
}