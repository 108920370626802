import React, {FunctionComponent} from "react";
import Tooltip from "./Tooltip";
import Icon from "../icon/Icon";
import {ColorType, IconSizeType} from "../../types/bootstrap/BootstrapType";
import {IconName} from "../icon/IconsMap";

interface PopoverProps {
  className?: string,
  text?: string,
  iconName?: IconName
  size?: IconSizeType
  color?: ColorType
  iconClassName?: string
}

const Popover : FunctionComponent<PopoverProps> = ({
  className = "",
  text,
  iconName = "InfoCircle",
  iconClassName = "",
  size = IconSizeType.SM,
  color
}) => {
  return (
    <Tooltip text={text} className={className}>
      <Icon color={color} name={iconName} size={size} className={iconClassName}/>
    </Tooltip>
  )
}

export default Popover
