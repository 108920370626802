import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {ResourceCalendarColumn, ResourceCalendarResourceStatus, ResourceCalendarRow} from "../../../interfaces/ResourceCalendarInterfaces";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Portal from "../../atoms/Portal";
import Icon from "../../icon/Icon";
import ResourceCalendarCellPanel from "../panel/resourceCalendar/ResourceCalendarCellPanel";

interface ResourceCalendarCellProps {
  className?: string,
  activity: ResourceCalendarRow,
  resource: ResourceCalendarColumn,
  onRefresh: () => void,
}

const ResourceCalendarCell: FunctionComponent<ResourceCalendarCellProps> = ({
  className = "",
  activity,
  resource,
  onRefresh,
}) => {
  const intl = useIntl()
  const [panelOpen, setPanelOpen] = React.useState<boolean>(false)

  const staffing = resource.staffing.find(s => s.activityId === activity.id && s.orderItemId === activity.orderItemId)
  const hasStatus = staffing?.status && staffing?.status !== ResourceCalendarResourceStatus.EMPTY
  const clickableStates = [ResourceCalendarResourceStatus.AVAILABLE, ResourceCalendarResourceStatus.SENT, ResourceCalendarResourceStatus.FREE]

  const getText = (): string => {
    if (!hasStatus) {
      return ""
    } else {
      return intl.formatMessage({id: `resource_calendar_${staffing.status}`})
    }
  }

  const getClassName = () => {
    const isClickable = clickableStates.includes(staffing?.status)
    const backgroundColor = `background-${staffing?.status ?? ResourceCalendarResourceStatus.EMPTY}`
    const cursor = `${isClickable && "cursor-pointer"}`
    const inline = `${!isClickable && "cell-inline"}`

    return `resource-calendar-cell ${backgroundColor} ${cursor} ${inline} ${className}`
  }

  const handleClick = () => {
    if (clickableStates.includes(staffing?.status)) {
      setPanelOpen(true)
    }
  }

  return (
    <>
      <div className={getClassName()} onClick={() => handleClick()}>
        {hasStatus && (
          <div className="icon-group">
            <Icon className="cell-icon" name="Circle" />

            {clickableStates.includes(staffing?.status) && (
              <Icon className="float-end" color={ColorType.SECONDARY} name="ArrowRight" />
            )}
          </div>
        )}

        <span className="cell-text bold">
          {getText()}
        </span>
      </div>

      {panelOpen && (
        <Portal>
          <ResourceCalendarCellPanel
            open={panelOpen}
            setOpen={setPanelOpen}
            resource={resource}
            activity={activity}
            status={staffing.status}
            onReload={() => onRefresh()}
          />
        </Portal>
      )}
    </>
  )
}

export default ResourceCalendarCell;
