import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import {Column} from "react-table";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ActivityArea, ActivityAreaSearchObject} from "../../../interfaces/ActivityInterfaces";
import {activityAreaService} from "../../../services/ActivityAreaService";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../utils/toastUtils";
import Button from "../../atoms/Button";
import Icon from "../../icon/Icon";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import ActivityAreaForm from "../form/admin/ActivityAreaForm";
import Panel from "../panel/Panel";
import PagedTable from "../table/PagedTable";
import {PagedTableRef} from "../table/PagedTableWithRowSelection";

interface ActivityAreaListProps {
  className?: string,
}

const ActivityAreaList: FunctionComponent<ActivityAreaListProps> = ({
  className = "",
}) => {

  const ref = useRef<PagedTableRef>()
  const intl = useIntl();
  const {searchObject, updateSearchField, onReset} = useSearchFilter<ActivityAreaSearchObject>({})
  const [selectedActivityArea, setSelectedActivityArea] = useState<ActivityArea>(null)
  const [activityAreaFormOpen, setActivityAreaFormOpen] = useState<boolean>(false)

  const onActivityAreaSelection = (activityArea: ActivityArea) => {
    setSelectedActivityArea(activityArea)
    setActivityAreaFormOpen(true)
  }

  const onActivityAreaCreate = () => {
    setActivityAreaFormOpen(false)
    ref.current.refresh()
    setSelectedActivityArea(null)
  }

  const handleDeleteActivityArea = (area: ActivityArea) => {
    activityAreaService.deleteActivityArea(area.id)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_edit_activity_area"}))
        onActivityAreaCreate()
      })
      .catch((e: Error) => {
        toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_edit_activity_area"})} : ${e.message ?? ""} `)
      })
  }

  const columns: Column[] = useMemo(() => [
    {
      Header: intl.formatMessage({id: "activity_area_label"}), accessor: "label", sortable: true,
    },
    {
      Header: intl.formatMessage({id: "table_actions"}), accessor: "id", sortable: false, width: "5%", Cell: ({row}) => (
        <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => onActivityAreaSelection(row.original as ActivityArea)} />
          <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleDeleteActivityArea(row.original as ActivityArea)} />
        </div>
      )
    },
  ], [intl]);

  return (
    <div className={className}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter col-3"
          title={intl.formatMessage({id: "activity_area_label"})}
          value={searchObject?.label}
          onChange={(value) => updateSearchField("label", value)}
          placeholder={intl.formatMessage({id: "activity_area_label"})}
        />
        <Button className="ms-2" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
      </FilterGroup>

      <PagedTable
        ref={ref}
        columns={columns}
        labelNoResult="activity_area_no_result"
        className={`${className} table`}
        fetchData={activityAreaService.getActivityAreasPage}
        filters={searchObject}
        defaultSortBy={[{sortBy: "label", descending: true}]}
      />
      <Button onClick={() => setActivityAreaFormOpen(true)}>
        {intl.formatMessage({id: "activity_area_create"})}
      </Button>
      {activityAreaFormOpen && (
        <Panel
          formId="createActivityAreaForm"
          title={selectedActivityArea ? "activity_area_edit" : "activity_area_create"}
          open={activityAreaFormOpen}
          onCancel={() => {
            setActivityAreaFormOpen(false)
            setSelectedActivityArea(null)
          }}
        >
          <ActivityAreaForm
            id="createActivityAreaForm"
            initialActivityArea={selectedActivityArea}
            onCreateActivityArea={onActivityAreaCreate}
          />
        </Panel>
      )}
    </div>
  )
}

export default ActivityAreaList;
