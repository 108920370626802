import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {OrderItemSearchObject, OrderItemStaffingStatus} from "../../../interfaces/OrderItemInterfaces";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import FilterGroup from "../filters/FilterGroup";
import StaffingCompletedFilters from "../filters/staffing/StaffingCompletedFilters";
import StaffingOngoingFilters from "../filters/staffing/StaffingOngoingFilters";
import StaffingConfirmedTable from "../table/staffing/StaffingConfirmedTable";
import StaffingPendingTable from "../table/staffing/StaffingPendingTable";
import StaffingTable from "../table/staffing/StaffingTable";
import {ResourceType} from "../../../interfaces/ResourceTypeInterfaces";

interface StaffingListProps {
  className?: string,
  searchObject: OrderItemSearchObject,
  onReset: () => void,
  updateSearchField: (field: string, value: unknown) => void,
}

const StaffingList: FunctionComponent<StaffingListProps> = ({
  className = "",
  searchObject,
  onReset,
  updateSearchField,
}) => {
  const intl = useIntl()
  const [selectedResourceTypes, setSelectedResourceTypes] = useState<ResourceType[]>([])

  return (
    <div className={`mb-5 ${className ?? ""}`} >

      <FilterGroup>
        <div>

          {searchObject.staffingStatus === OrderItemStaffingStatus.STAFFED ? (
            <StaffingCompletedFilters searchObject={searchObject} updateSearchField={updateSearchField} />
          ) : (
            <StaffingOngoingFilters searchObject={searchObject} updateSearchField={updateSearchField} setSelectedResourceTypes={setSelectedResourceTypes} />
          )}
        </div>
        <div className="d-flex ms-2">
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      {searchObject.staffingStatus === OrderItemStaffingStatus.TO_STAFF && (
        <StaffingTable searchObject={searchObject} className="mb-4" selectedResourceTypes={selectedResourceTypes}/>
      )}
      {searchObject.staffingStatus === OrderItemStaffingStatus.DISPONIBILITY_REQUEST_SENT && (
        <StaffingPendingTable searchObject={searchObject} className="mb-4" selectedResourceTypes={selectedResourceTypes}/>
      )}
      {searchObject.staffingStatus === OrderItemStaffingStatus.STAFFED && (
        <StaffingConfirmedTable searchObject={searchObject} className="mb-4" />
      )}

    </div >
  )
}

export default StaffingList;
