import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import FormInput from "../input/FormInput";
import {ACTIVITY_INITIAL_VALUES} from "../../../constants/ActivityConstants";
import {CreateActivitySchema} from "../../../constants/validation/ActivityValidationSchemas";
import {Activity, ActivityFormData} from "../../../interfaces/ActivityInterfaces";
import {activityService} from "../../../services/ActivityService";
import {toastUtils} from "../../../utils/toastUtils";
import {ACTIVITIES_PATH} from "../../../constants/routes/RoutePaths";
import {UNIT_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormRadio from "../input/FormRadio";
import {dateUtils} from "../../../utils/dateUtils";
import {activityAreaService} from "../../../services/ActivityAreaService";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import PriceInput from "../input/PriceInput";
import EpowForm from "./EpowForm";
import {Col, Row} from "reactstrap";
import {LoadingComponent} from "../../../constants/menu/Layout";

interface CreateActivityFormProps extends LoadingComponent  {
  id?: string,
  className?: string,
  activityInitial?: Activity,
  setEditInfo?: (editInfo: boolean) => void,
  setActivity?: (activity: Activity) => void
}

const CreateActivityForm: FunctionComponent<CreateActivityFormProps> = ({
  id,
  className = "",
  activityInitial,
  setEditInfo,
  setActivity,
  startLoading,
  stopLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const activityFormData: ActivityFormData = activityService.buildActivityFormData(activityInitial);
  const [reference, setReference] = useState<string>("")

  const handleSubmitActivity = (activityFormData: ActivityFormData) => {
    startLoading?.()
    if (activityInitial?.id) {
      const activityRequest = {
        ...activityInitial,
        ...activityFormData,
        duration: dateUtils.convertTimeToSeconds(activityFormData.duration),
        price: parseFloat(activityFormData.price),
      };
      activityService.updateActivity(activityInitial.id, activityRequest)
        .then((updatedActivity) => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_activity"}))
          setEditInfo(false)
          setActivity(updatedActivity)
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_activity"}))
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      activityService.createActivity(activityFormData)
        .then((activity) => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_activity"}))
          navigate(`${ACTIVITIES_PATH}/${activity?.id}`)
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_activity"}))
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  }

  const getReference = (serviceName: string) => {
    if (serviceName?.length >= 3) {
      activityService.getReference(serviceName)
        .then((response) => {
          setReference(response.reference)
          if (activityInitial) {
            activityInitial.reference = response.reference
          }
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_reference"}))
        })
    }
  }

  useEffect(() => {
    if (activityInitial) {
      setReference(activityInitial.reference)
    }
  }, [])

  return (
    <Formik initialValues={activityFormData || ACTIVITY_INITIAL_VALUES} validationSchema={CreateActivitySchema(!!activityInitial)} onSubmit={handleSubmitActivity}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          {
            activityInitial ? (
              <Row>
                <Col xs={24} md={12}>
                  <div className="d-flex flex-column mb-3">
                    <FormInput
                      id="name"
                      label="activity_name_field"
                      value={values?.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value)
                        if(!activityInitial?.id) {
                          getReference(e.target.value)
                          setFieldValue("reference", reference)
                        }
                      }}
                      required
                      error={errors.name}
                      touched={touched.name}
                    />
                    <FormInput
                      id="reference"
                      label="activity_reference_field"
                      value={reference}
                      required
                      disabled
                      error={errors.reference}
                      touched={touched.reference}
                    />
                    <FormInput
                      id="billingName"
                      label="activity_billing_name"
                      value={values?.billingName}
                      onChange={(e) => setFieldValue("billingName", e.target.value)}
                      required
                      error={errors.billingName}
                      touched={touched.billingName}
                    />
                    <FormInput
                      id="duration"
                      label="activity_duration"
                      type="time"
                      step="60"
                      value={dateUtils.getTimeWithoutSeconds(values?.duration)}
                      onChange={(e) => setFieldValue("duration", e.target.value)}
                      required
                      error={errors.duration}
                      touched={touched.duration}
                    />
                    <FormInput
                      id="description"
                      label="activity_description"
                      type="textarea"
                      rows="5"
                      value={values?.description}
                      onChange={(e) => setFieldValue("description", e.target.value)}
                      error={errors.description}
                      touched={touched.description}
                    />
                  </div>
                </Col>
                <Col xs={24} md={12}>
                  <div className="d-flex flex-column mb-3">
                    <FormInput
                      id="numberPaxMax"
                      label="activity_number_pax_max"
                      type="number"
                      value={values?.numberPaxMax}
                      onChange={(e) => setFieldValue("numberPaxMax", e.target.value)}
                      required
                      error={errors.numberPaxMax}
                      touched={touched.numberPaxMax}
                    />
                    <FormAutocompleteSelectPageable
                      id="activityAreaId"
                      label={intl.formatMessage({id: "activity_zone"})}
                      value={values.activityAreaId}
                      onChange={(value) => setFieldValue("activityAreaId", value)}
                      required
                      error={errors.activityAreaId}
                      touched={touched.activityAreaId}
                      fetchData={activityAreaService.getActivityAreasPage}
                      filterFieldName="label"
                    />
                    <FormRadio
                      id="showActivityTourLeader"
                      label="order_item_activity_ops"
                      value={YES_NO_OPTIONS.find((option) => option?.value === values?.showActivityTourLeader?.toString())}
                      options={YES_NO_OPTIONS}
                      onChange={(e) => setFieldValue("showActivityTourLeader", e.value)}
                      required
                      error={errors.showActivityTourLeader}
                      touched={touched.showActivityTourLeader}
                    />
                    <FormRadio
                      id="forKids"
                      label="activity_is_for_kids"
                      value={YES_NO_OPTIONS.find((option) => option?.value === values?.forKids?.toString())}
                      options={YES_NO_OPTIONS}
                      onChange={(option?: Option<string>) => setFieldValue("forKids", option?.value)}
                      required
                      error={errors.forKids}
                      touched={touched.forKids}
                    />
                    <Row>
                      <Col xs={24} md={14}>
                        <FormRadio
                          id="staffingManagement"
                          label="activity_is_staffing_management"
                          value={YES_NO_OPTIONS.find((option) => option?.value === values?.staffingManagement?.toString())}
                          options={YES_NO_OPTIONS}
                          onChange={(option?: Option<string>) => setFieldValue("staffingManagement", option?.value)}
                          required
                          error={errors.staffingManagement}
                          touched={touched.staffingManagement}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={24} md={14}>
                        <FormRadio
                          id="unitType"
                          label="service_default_sell_unit"
                          value={UNIT_TYPE_OPTIONS.find((option) => option?.value === values?.unitType?.toString())}
                          options={UNIT_TYPE_OPTIONS}
                          onChange={(e) => setFieldValue("unitType", e.value)}
                          required
                          error={errors.unitType}
                          touched={touched.unitType}
                        />
                      </Col>
                      <Col xs={24} md={10}>
                        <PriceInput
                          id="price"
                          label="activity_price"
                          value={values?.price}
                          onChange={(e) => setFieldValue("price", e.target.value)}
                          required
                          error={errors.price}
                          touched={touched.price}
                        />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <div>
                <FormInput
                  id="name"
                  label="activity_name_field"
                  value={values?.name}
                  onChange={(e) => {
                    setFieldValue("name", e.target.value)
                    getReference(e.target.value)
                    setFieldValue("reference", reference)
                  }}
                  required
                  error={errors.name}
                  touched={touched.name}
                />
                <FormInput
                  id="reference"
                  label="activity_reference_field"
                  value={reference}
                  required
                  disabled
                  error={errors.reference}
                  touched={touched.reference}
                />
              </div>
            )
          }
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateActivityForm
