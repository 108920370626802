import * as Yup from "yup";
import {getRequiredStringForCondition, yupRequiredArray, yupRequiredString} from "../yup/Validators";
import {EU_CUSTOMER} from "../CustomerConstants";

export const CreateCustomerSchema = Yup.object().shape({
  name: yupRequiredString,
  code: yupRequiredString,
  mainLabel: yupRequiredString,
  zipCode: yupRequiredString,
  city: yupRequiredString,
  country: yupRequiredString
});

const isEUCustomer = (area) => area === EU_CUSTOMER

export const CreateInvoiceOperationCustomerSchema = Yup.object().shape({
  geographicalZone: yupRequiredString,
  numberIntraVAT: getRequiredStringForCondition("geographicalZone", isEUCustomer),
  invoiceForCustomer: yupRequiredString,
  billingServices: yupRequiredArray(1),
  defaultBillingServiceId: yupRequiredString,
  disbursement: yupRequiredString,
  isVoucherManagement: yupRequiredString,
  isReservationManagement: yupRequiredString,
  numberWeeksViewed: Yup.number().required("mandatory_field"),
  notificationForActivity: yupRequiredString,
  notificationForReplacement: yupRequiredString,
  publicType: yupRequiredString,
})