import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {RouteProps} from "react-router-dom";
import Layout from "../components/layout/Layout";
import TabList from "../components/molecules/tab/TabList";
import {adminTab} from "../constants/tabs/TabListConstants";
import PageTitle from "../components/molecules/header/PageTitle";

const AdminView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column admin-view">
        <div className="mb-3">
          <PageTitle pageTitle={intl.formatMessage({ id: "admin_title" })} />
        </div>
        <TabList items={adminTab} />
      </div>
    </Layout>
  )
}

export default AdminView;