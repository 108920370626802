import React, {FunctionComponent, ReactElement} from "react";
import {Card, CardBody, CardTitle} from "reactstrap";
import Button from "../../atoms/Button";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import {LineContactCardProps} from "../../../interfaces/ContactInterfaces";
import Icon from "../../icon/Icon";
import FieldLabel from "../../atoms/FieldLabel";
import {Address} from "../../../interfaces/AddressInterfaces";
import {IconName} from "../../icon/IconsMap";

interface ContactCardProps {
  className?: string,
  firstName?: string,
  lastName?: string,
  address?: Address,
  hasAccount?: boolean,
  customerName?: string,
  buttonCallback?: () => void,
  lineContact?: LineContactCardProps[]
}

const ContactCard: FunctionComponent<ContactCardProps> = ({
  className = "",
  firstName = "",
  lastName = "",
  lineContact,
  hasAccount,
  buttonCallback,
}) => {
  const intl = useIntl()


  const LineInfoContact = (line: { title: string, content: string, icon: IconName, href: string, customContent: ReactElement }, key: number) => (
    <div className="line-info-contact m-1" key={key}>
      <div className="text-truncate">
        <FieldLabel
          className="mb-2"
          verticalDisplay
          label={intl.formatMessage({id : line.title})}
          href={line.href && `${line.href}:${line.content}`}
          external
          value={line.customContent || line.content}
        />
      </div>
      <div className="ms-1">
        <Icon name={line.icon} size={IconSizeType.MD}/>
      </div>
    </div>
  )


  return (
    <div className={className}>
      <Card className="epow-contact-card">
        <CardBody>
          <CardTitle>
            <div className="d-flex flex-row align-items-center">
              <div className="m-2">
                <Icon name="UserCircle" size={IconSizeType.LG}/>
              </div>
              <div className="px-2 d-flex flex-column">
                <span className="fs-5 bold">{firstName} {lastName}</span>
                { hasAccount !== undefined && (
                  <span className="text-muted">{hasAccount ? intl.formatMessage({id: "has_account"}) : intl.formatMessage({id: "has_no_account"})}</span>
                )}
              </div>
            </div>
          </CardTitle>
          {lineContact && (
            <div>
              <div className="d-flex flex-column">
                {lineContact.map((line: LineContactCardProps, key) => (
                  <LineInfoContact key={key} title={line.title} content={line.content} icon={line.icon} href={line.href} customContent={line.customContent} />
                ))}
              </div>
            </div>
          )}
          {(hasAccount !== undefined && !hasAccount) &&
            <Button color={ColorType.SECONDARY} className="epow-button w-100"
                    onClick={buttonCallback}>
              {intl.formatMessage({id: "invite"})}
            </Button>
          }
        </CardBody>
      </Card>
    </div>
  )
}

export default ContactCard
