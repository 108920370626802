import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";
import {InputType} from "reactstrap/types/lib/Input";
import React, {FC, useCallback, useState} from "react";
import {FormGroup, Label} from "reactstrap";
import {FormattedMessage, useIntl} from "react-intl";
import Popover from "../../atoms/Popover";
import ErrorField from "./ErrorField";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {AddressInputInterface, Address} from "../../../interfaces/AddressInterfaces";
import {addressService} from "../../../services/AddressService";
import TextInput from "components/atoms/input/TextInput";
import {debounce} from "lodash";

interface AddressInputProps {
  id?: string,
  className?: string,
  label?: string,
  placeholder?: string,
  address?: string,
  required?: boolean,
  error?: FormikError
  touched?: FormikTouchedType,
  type?: InputType,
  popover?: string,
  onChange?: (address?: string) => void,
  onSelectAddress?: (address: Address) => void,
  typeSearch?: string
}

const AddressInput: FC<AddressInputProps> = ({
  id,
  className = "",
  label,
  placeholder,
  address,
  required,
  error,
  touched,
  type = "text",
  popover,
  onChange,
  onSelectAddress,
  typeSearch
}) => {
  const intl = useIntl()
  const [selection, setSelection] = useState<AddressInputInterface[]>([])
  const [addressOptions, setAddressOptions] = useState<AddressInputInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [fieldSelect, setFieldSelect] = useState<boolean>(true)
  const [defaultValue, setDefaultValue] = useState<string>(address)
  const [query, setQuery] = useState<string>("")

  const handleSelectAddress = (listAddress: Address[]): void => {
    if (listAddress.length > 0 && onSelectAddress) {
      onSelectAddress({ ...listAddress[0]})
    }
  }

  const buildAddressLabel = useCallback((address?: AddressInputInterface) => {
    if (selection?.length > 0 && address) {
      return addressService.buildMainLabel(address?.address)
    }
    return address?.option ? address.option?.label : addressService.mapAddressToLabel(address?.address)
  }, [selection])

  const ADDRESS_NOT_FOUND_OPTION = {
    option: {
      value: "otherAddress",
      label: intl.formatMessage({ id: "address_not_found" })
    }
  }

  const onSearch = useCallback(
    debounce(
      async (query: string) => {
        setQuery(query)
        if (query.length >= 3) {
          setAddressOptions([])
          setIsLoading(true)
          try {
            const listAddress: Address[] = await addressService.searchAddress(query, typeSearch) || []
            const addressOptions: AddressInputInterface[] = listAddress.map(address => ({address: address}))
            setAddressOptions([...addressOptions, ADDRESS_NOT_FOUND_OPTION])
          }
          catch {
            setAddressOptions([ADDRESS_NOT_FOUND_OPTION])
          }
          setIsLoading(false)
        }
      }, 400
    ),
  [isLoading, addressOptions])

  const handleNotFound = (): void => {
    setFieldSelect(false)
    onChange(query)
  }

  return (
    <FormGroup className={className}>
      <div className="d-flex flex-row epow-label">
        {label && (
          <Label htmlFor={id}>
            <FormattedMessage id={label}/>
            {required && <span aria-label="Champ obligatoire" className="required epow-danger ms-1 me-1">*</span>}
          </Label>
        )}
        {popover && (
          <div className="ms-2">
            <Popover text={popover}/>
          </div>
        )}
      </div>
      {fieldSelect ? (
        <AsyncTypeahead
          id={id}
          className={`${!!error && !!touched ? "epow-error" : ""}`}
          placeholder={intl.formatMessage({id: placeholder})}
          onChange={(address: AddressInputInterface[]) => {
            if (JSON.stringify(address[0]) === JSON.stringify(ADDRESS_NOT_FOUND_OPTION)) {
              handleNotFound()
            } else {
              setDefaultValue(undefined)
              setSelection(address)
              const listAddress: Address[] = address.filter(a => a.address).map(a => a.address)
              handleSelectAddress(listAddress)
            }
          }}
          selected={selection}
          defaultInputValue={defaultValue}
          options={addressOptions}
          labelKey={buildAddressLabel}
          isLoading={isLoading}
          onSearch={onSearch}
          searchText={intl.formatMessage({id: "search_text"})}
          filterBy={() => true}
        />
      ) : (
        <TextInput
          id={id}
          className={`${!!error && !!touched ? "epow-input-error" : ""}`}
          value={address}
          type={type}
          placeholder={intl.formatMessage({id: placeholder})}
          onChange={(e) => onChange(e.target.value)}
        />
      )}

      <ErrorField error={error} touched={touched}/>
    </FormGroup>
  )
}

export default AddressInput
