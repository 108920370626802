import {TourLeader} from "../../../../interfaces/TourLeaderInterfaces";
import React, {FunctionComponent} from "react";
import GeneralInfoTourLeaderCard from "./GeneralInfoTourLeaderCard";
import {useIntl} from "react-intl";
import CustomersRepresentedByTourLeaderCard from "./CustomersRepresentedByTourLeaderCard";
import MainTourLeaderContactCard from "./MainTourLeaderContactCard";

interface DetailsTourLeaderCardProps {
  className?: string,
  tourLeader?: TourLeader,
  setTourLeader?: (tourLeader?: TourLeader) => void
}

const DetailsTourLeaderCard: FunctionComponent<DetailsTourLeaderCardProps> = ({
  className = "",
  tourLeader,
  setTourLeader,
}) => {
  const intl = useIntl()
  return (
    <div className={`d-flex flex-row flex-wrap gap-3 ${className}`}>
      <GeneralInfoTourLeaderCard tourLeader={tourLeader} setTourLeader={setTourLeader} title={intl.formatMessage({id: "tour_leader_general_info"})}/>
      <CustomersRepresentedByTourLeaderCard tourLeader={tourLeader} title={intl.formatMessage({id: "customers_represented_by_tour_leader"})}/>
      <MainTourLeaderContactCard onChangeTourLeader={setTourLeader} tourLeader={tourLeader} title={intl.formatMessage({id: "main_contact"})}/>
    </div>
  )
}

export default DetailsTourLeaderCard
