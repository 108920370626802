import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {toastUtils} from "../utils/toastUtils";
import { Order } from "interfaces/OrderInterfaces";
import { orderService } from "services/OrderService";
import OrderHeader from "../components/molecules/header/OrderHeader";
import OrderInfosCard from "../components/molecules/card/order/OrderInfosCard";
import {OrderItem} from "../interfaces/OrderItemInterfaces";
import {useIntl} from "react-intl";


interface OrderViewProps extends RouteProps {
  title?: string
  path?: string
}

const OrderView: FunctionComponent<OrderViewProps> = ({title, path}) => {
  const params = useParams();
  const intl = useIntl()
  const [order, setOrder] = useState<Order>(null)
  const [orderItems, setOrderItems] = useState<OrderItem[]>(order?.items ?? [])

  const getOrderById = () => {
    orderService.getOrderById(params.id)
      .then((order) => {
        setOrder(order)
        setOrderItems(order?.items)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_get"}))
      })
  }

  useEffect(() => {
    getOrderById()
  }, [])

  return (
    <Layout>
      {order && (
        <div className="epow-content-body">
          <OrderHeader
            className="mb-2"
            order={order}
            setOrder={setOrder}
            setOrderItems={setOrderItems}
            onDelete={getOrderById}
            title={title}
            path={path}/>
          <OrderInfosCard
            order={order}
            orderItems={orderItems}
            refeshOrderItems={getOrderById} />
        </div>
      )}
    </Layout>
  )
}

export default OrderView
