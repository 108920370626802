import React, {FunctionComponent} from "react";
import {Option} from "../../interfaces/inputs/OptionInterfaces";
import Card from "../atoms/Card";
import FieldLabel from "../atoms/FieldLabel";
import {FormattedMessage, useIntl} from "react-intl";
import Badge from "../atoms/Badge";
import {OrderStateMap} from "../../interfaces/OrderInterfaces";
import {FormGroup, Label} from "reactstrap";
import AsyncAutocompleteSelect from "./filters/AsyncAutocompleteSelect";
import {tourLeaderService} from "../../services/TourLeaderService";
import {WorkflowStatesEnum} from "../../constants/workflow/WorkflowStates";
import TextInput from "../atoms/input/TextInput";
import FormRadioLight from "./input/FormRadioLight";
import {YES_NO_OPTIONS} from "../../constants/OptionConstants";
import {optionUtils} from "../../utils/optionUtils";
import {OrderFormTourLeader} from "../../views/OrderFormListView";

interface OrderFormListItemProps {
  orderFormTourLeader: OrderFormTourLeader,
  orderNumber: string,
  onChange: (orderFormTourLeader: OrderFormTourLeader) => void
}

const OrderFormListItem: FunctionComponent<OrderFormListItemProps> = ({
                                                                        orderFormTourLeader,
                                                                        orderNumber,
                                                                        onChange
                                                                      }) => {

  const intl = useIntl();

  const onChangeTourLeader = (value: Option<string>) => {

    onChange({
      ...orderFormTourLeader,
      tourLeaderId: value?.value,
      fullName: value?.label,
      touched: true
    })
  };

  const onChangePaxConfirmed = (paxConfirmed: boolean) => {
    onChange({
      ...orderFormTourLeader,
      paxConfirmed,
      touched: true
    })
  };

  const onChangeDefaultPax = (defaultPax: string) => {
    onChange({
      ...orderFormTourLeader,
      defaultPax: parseInt(defaultPax) ?? 0,
      touched: true
    })
  }


  return <Card key={orderNumber} className="mb-3">
    <div className="d-flex flex-row justify-content-between">
      <div>
        <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_filter_order_number"})} value={orderNumber}/>
        <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_customer_ref"})}
                    value={orderFormTourLeader.customerReference}/>
        <div className="mb-1">
          <span className="me-1 text-muted"><FormattedMessage id="order_status"/> :</span>
          <Badge pill color={OrderStateMap[orderFormTourLeader.orderStatus]?.color}>
            {intl.formatMessage({id: OrderStateMap[orderFormTourLeader.orderStatus]?.label})}
          </Badge>
        </div>
      </div>

      <div className="d-flex flex-row">
          <FormGroup className="d-flex flex-row me-3">
            <div>
            <Label className="epow-label bold">
              {intl.formatMessage({id: "order_tour_leader"})}
            </Label>
            <AsyncAutocompleteSelect
              onChange={onChangeTourLeader}
              value={orderFormTourLeader ? {
                value: orderFormTourLeader.tourLeaderId,
                label: orderFormTourLeader.fullName
              } : null}
              fetchData={(query) => tourLeaderService.getTourLeaderPage({
                page: 0,
                pageSize: 100
              }, {
                nameSurname: query,
                customerId: orderFormTourLeader?.customerId,
                currentState: WorkflowStatesEnum.ACTIVE
              })}
              filterFieldName="fullName"/>
            </div>

            <div>
              <Label className="epow-label bold">
                {intl.formatMessage({id: "order_default_pax"})}
              </Label>
              <TextInput
                type="number"
                className="mx-2"
                id="defaultPax"
                onChange={(value) => onChangeDefaultPax(value.target.value)}
                value={orderFormTourLeader.defaultPax}
              />
            </div>

            <FormRadioLight
              bold
              label="order_item_pax_confirmed"
              id="paxConfirmed"
              value={YES_NO_OPTIONS.find(option => option.value === orderFormTourLeader?.paxConfirmed?.toString())}
              options={YES_NO_OPTIONS}
              onChange={(option) => onChangePaxConfirmed(optionUtils.getBoolean(option.value))}
            />
          </FormGroup>
      </div>
    </div>

  </Card>
}


export default OrderFormListItem;
