import React, {FunctionComponent, ReactNode, useEffect, useState} from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { IconSizeType } from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import TextLink from "../../atoms/TextLink";
import {IconName} from "../../icon/IconsMap";

interface AccordionProps {
  title?: string;
  titleNode?: ReactNode;
  titleHref?: string;
  fullClickable?;
  header?: ReactNode;
  children: ReactNode;
  className?: string;
  startOpen?: boolean;
  iconClose?: IconName;
  iconOpen?: IconName;
  iconStar?: ReactNode;
  displayStar?: boolean;
}

const Accordion: FunctionComponent<AccordionProps> = ({
  title,
  titleNode,
  titleHref,
  fullClickable = false,
  header,
  children,
  className = "",
  startOpen,
  iconClose = "ChevronDown",
  iconOpen = "ChevronUp",
  displayStar,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(startOpen);

  useEffect(() => {
    setIsOpen(startOpen);
  }, [startOpen]);

  return (
    <Card className={`epow-accordion ${className}`}>
      <CardHeader
        role={fullClickable ? "button" : ""}
        aria-expanded={isOpen}
        onClick={fullClickable ? () => setIsOpen(!isOpen) : undefined}
        className="d-flex align-items-center justify-content-between"
      >
        {title && (
          <div className="d-flex align-items-center">
            <span className="epow-info mb-0">
              {titleHref ? (
                <TextLink to={titleHref}><>{title}</></TextLink>
              ) : (
                <>{titleNode ? titleNode : title}</>
              )}
            </span>

            {displayStar && (
              <Icon className="ms-2" name="StarFill" size={IconSizeType.XS} />
            )}
          </div>
        )}
        {header}
        <div className="d-flex align-items-center">
          <Icon
            className={fullClickable ? "" : "cursor-pointer"}
            size={IconSizeType.SM}
            name={isOpen ? iconOpen : iconClose}
            onClick={fullClickable ? undefined : () => setIsOpen(!isOpen)}
          />
        </div>
      </CardHeader>
      <CardBody className={`${isOpen ? "" : "d-none"}`}>{children}</CardBody>
    </Card>

  );
};

export default Accordion;
