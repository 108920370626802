import React, {FunctionComponent} from "react";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import DashboardPriorityItem from "./DashboardPriorityItem";

export interface Priority {
  id: string,
  title: string,
  url?: string,
  content: JSX.Element,
  type: string,
  removeOnResolve?: boolean
}

interface DashboardPriorityProps {
  className: string;

  priorities: Priority[]
  onRemoveWarningPriority?: (priority: Priority) => void
  onRemoveDangerPriority?: (priority: Priority) => void

}

const DashboardPriority: FunctionComponent<DashboardPriorityProps> = ({
  className= "",
  priorities = [],
  onRemoveWarningPriority,
  onRemoveDangerPriority
}) => (
  <div className={`epow-dashboard-priority ${className}`}>
    <h3 className="fs-16 fw-bold mb-3 flex-row d-flex align-items-center">
      <div className="rounded-circle border border-danger d-flex align-items-center justify-content-center me-1"
           style={{width: 15, height: 15}}>
        <Icon name="Exclamation" size={IconSizeType.XXS} color="danger"/>
      </div>
      <FormattedMessage id="dashboard_priority_title"/>
    </h3>

    {priorities.filter(priority => priority.type === "danger").map(priority =>
      <DashboardPriorityItem
        key={priority.id}
        priority={priority}
        onRemovePriority={onRemoveDangerPriority}
        type="danger"
      />)}

    {priorities.filter(priority => priority.type === "warning").map(priority =>
      <DashboardPriorityItem
        key={priority.id}
        priority={priority}
        onRemovePriority={onRemoveWarningPriority}
        type="warning"
      />)}
  </div>);


export default DashboardPriority;
