import React, {FunctionComponent} from "react";
import Layout from "../components/layout/Layout";
import {TourLeader} from "../interfaces/TourLeaderInterfaces";
import {TOUR_LEADERS_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsType, WorkflowStateMap,} from "../constants/workflow/WorkflowStates";
import {FormattedMessage, useIntl} from "react-intl";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import TabList from "../components/molecules/tab/TabList";
import {tourLeaderTab} from "../constants/tabs/TabListConstants";
import PageTitle from "../components/molecules/header/PageTitle";

interface TourLeaderViewTemplateProps {
  onChangeTourLeader: (resource: TourLeader) => void,
  onRefresh?: () => void,
  tourLeader?: TourLeader,
  workflowActions?: Partial<Record<WorkflowActionsType, () => void>>

}

const TourLeaderTemplateView: FunctionComponent<TourLeaderViewTemplateProps> = ({
  tourLeader,
  onRefresh,
  workflowActions,
                                                                                }) => {
  const intl = useIntl()

  return (
    <Layout>
      {tourLeader && (
        <div className="epow-content-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <PageTitle
                pageTitle={`${tourLeader?.identity?.firstName} ${tourLeader?.identity?.lastName}`}
                className="me-4"
                breadcrumbItems={[{
                  title: intl.formatMessage({id: "tour_leader_title"}),
                  to: TOUR_LEADERS_PATH
                }]}
              />
              <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
              <Badge pill color={WorkflowStateMap[tourLeader?.currentState]?.color} className="ms-2 mb-1">
                {intl.formatMessage({id: WorkflowStateMap[tourLeader?.currentState]?.label || "STATUS_DEFAULT"})}
              </Badge>
            </div>
            <WorkflowButtonList
              currentState={tourLeader.currentState}
              entityLabel={intl.formatMessage({id: "workflow_entity_tour_leader"})}
              actions={workflowActions}
            />
          </div>
          <TabList items={tourLeaderTab(tourLeader, onRefresh)}/>
        </div>
      )}
    </Layout>
  )
}

export default TourLeaderTemplateView
