import {UseTableCellProps} from "react-table"

export interface Data {
  id?: string,
  billed?: boolean,
}

export type StringCall = () => string

export interface TableColumn<T extends Object> {
  Header: string,
  accessor: string | StringCall,
  width?: string,
  Cell?: ({row}: UseTableCellProps<T>) => JSX.Element,
  sortable?: boolean,
  ignoreCase?: boolean,
}

export interface Table<T extends Object> {
  [columnName: string]: TableColumn<T>
}

export interface BasicTableHeader {
  id: string,
  sortable?: boolean,
  ignoreCase?: boolean,
}

export interface CustomTableColumn {
  className?: string,
  title: string,
  accessor?: string,
  sortable?: boolean,
  Cell?: JSX.Element,
}

export const CustomTableType = {
  BOOKING: "booking",
  DETAILS_TABLE: "details-table",
  STAFFING: "staffing",
  ACTIVITY_BILLING: "activity-billing",
  ACTIVITY_BILLING_OVERVIEW: "activity-billing-overview",
  DOCUMENTS_TABLE: "documents-table",
} as const

export type CustomTableEnum = typeof CustomTableType[keyof typeof CustomTableType]
