import {TourLeader} from "../../../../interfaces/TourLeaderInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import FieldLabel from "../../../atoms/FieldLabel";

interface TourLeaderCardProps {
  className?: string,
  tourLeader?: TourLeader,
  editInfo?: boolean,
  setEditInfo?: (editInfo: boolean) => void
}

const TourLeaderCard: FunctionComponent<TourLeaderCardProps> = ({
  className = "",
  tourLeader,
  editInfo,
  setEditInfo
}) => {
  const intl = useIntl();
  const identity = tourLeader?.identity;
  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-4">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "tour_leader_first_name_field"})}
          value={identity?.firstName}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "tour_leader_last_name_field"})}
          value={identity?.lastName}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "phoneNumber"})}
          value={identity?.phoneNumber}
          href={`tel:${identity.phoneNumber}`}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "contact_other_phone_number_field"})}
          value={identity?.otherPhoneNumber}
          href={`tel:${identity.otherPhoneNumber}`}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "email"})}
          value={identity?.email}
          href={`tel:${identity?.email}`}
        />
      </div>
      <Button color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended">
        <FormattedMessage id="edit_button"/>
      </Button>
    </div>
  )
}

export default TourLeaderCard