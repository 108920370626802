import {httpService} from "./HttpService";
import {
  Quote, QuoteConvertRequest,
  QuoteField,
  QuoteRequest,
  QuoteSearchObject,
} from "../interfaces/QuoteInterfaces";
import {API_QUOTES_PATH} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";



function createQuote (item: QuoteRequest): Promise<Quote> {
  return httpService.post<Quote>(API_QUOTES_PATH, item)
}

function getQuoteById(id: string): Promise<Quote> {
  return httpService.get<Quote>(`${API_QUOTES_PATH}/${id}`)
}

function getQuotesPage(params: PaginationQueryParams, filterParams: QuoteSearchObject): Promise<PageResponse<Quote>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get<PageResponse<Quote>>(`${API_QUOTES_PATH}${queryParams}`)
}

function getTravelBook(quoteId: string): Promise<Blob> {
  return httpService.getReadableStream(`${API_QUOTES_PATH}/${quoteId}/travelBook`);
}

function updateQuote (id: string, item: QuoteRequest): Promise<Quote> {
  return httpService.put<Quote>(`${API_QUOTES_PATH}/${id}`, item)
}

function duplicateQuote (id: string): Promise<Quote> {
  return httpService.post<Quote>(`${API_QUOTES_PATH}/${id}/duplicate`, {})
}

function deleteQuote (id: string): Promise<Response> {
  return httpService.delete(`${API_QUOTES_PATH}/${id}`)
}

function download(id: string) {
  return httpService.getReadableStream(`${API_QUOTES_PATH}/${id}/download`)
}

function transitionTo (id: string, status: string, formData?: FormData): Promise<Quote> {
  return httpService.putFormData<Quote>(`${API_QUOTES_PATH}/${id}/transitions?status=${status}`, formData)
}

function transitionToOrder(id: string, {orderDate, customerId, customerRef}: QuoteConvertRequest): Promise<Quote> {
  return httpService.post<Quote>(`${API_QUOTES_PATH}/${id}/convert`, {orderDate, customerId, customerRef})
}


function buildQuoteRequest (quotesFields: QuoteField): QuoteRequest {

  if(!quotesFields) {
    return null
  }

  return {
    customerId: quotesFields.customerId,
    tourId: quotesFields.tourId,
    customerRef: quotesFields.customerRef,
    emailContact: quotesFields.emailContact,
    rendezVousDate: quotesFields.rendezVousDate,
    creationDate: quotesFields.creationDate,
    endOfValidity: quotesFields.endOfValidity,
    pax: quotesFields.pax,
    geographicalZone: quotesFields.geographicalZone,
  }
}

export const quoteService = {
  createQuote,
  deleteQuote,
  buildQuoteRequest,
  getQuoteById,
  getQuotesPage,
  getTravelBook,
  updateQuote,
  transitionTo,
  transitionToOrder,
  download,
  duplicateQuote
}
