import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Title from "../../../atoms/Title";
import CreateServiceForm from "../../form/CreateServiceForm";
import ServiceCard from "./ServiceCard";
import useLoading from "../../../../hooks/useLoading";

interface GeneralInfoServiceCardProps {
  className?: string,
  service: Service,
  title: string,
  setService?: (service: Service) => void,
}

const GeneralInfoServiceCard: FunctionComponent<GeneralInfoServiceCardProps> = ({
  className = "",
  service,
  title,
  setService,
}) => {
  const [editInfos, setEditInfos] = useState<boolean>(false)
  const {loading, stopLoading, startLoading} = useLoading()

  return (
    <div className={className}>
      <div className="mb-3">
        <Title title={title} size={"h5"} />
      </div>
      {editInfos ? (
        <>
          <CreateServiceForm
            id="editServiceForm"
            service={service}
            setService={setService}
            setEditInfos={setEditInfos}
            stopLoading={stopLoading}
            startLoading={startLoading}
          />
          <div>
            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfos(false)} className="extended me-2">
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button loading={loading} form={"editServiceForm"} type="submit" size={SizeType.MD} className="extended">
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <>
          <ServiceCard service={service} />
          <div>
            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfos(true)} className="extended">
              <FormattedMessage id="edit_button" />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default GeneralInfoServiceCard;
