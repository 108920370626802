import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {DocumentDTO, DocumentSearchObject, FormDocument} from "../../../interfaces/FileInterfaces";
import {fileService} from "../../../services/FileService";
import {activityService} from "../../../services/ActivityService";
import useSearchFilter from "../../../hooks/useSearchFilter";
import SelectFilter from "../filters/SelectFilter";
import {USER_PROFILE} from "../../../constants/user/UserConstants";
import GenericModalDelete from "../modal/GenericModalDelete";
import Panel from "../panel/Panel";
import UpsertDocumentForm from "../form/UpsertDocumentForm";
import {PROFILE} from "../../../interfaces/UserInterfaces";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import DocumentTable from "../table/document/DocumentTable";
import DocumentTableRowAdmin from "../table/document/DocumentTableRowAdmin";
import {FileTheme} from "../../../constants/FileConstants";

interface ExternalProfileDocumentListProps {
  className?: string,
  defaultSearchObject?: DocumentSearchObject,
  customerId?: string,
  activityId?: string,
  activityName?: string,
}

const InternalProfileDocumentList: FunctionComponent<ExternalProfileDocumentListProps> = ({
  className = "",
  defaultSearchObject,
  customerId,
  activityId,
  activityName,
}) => {
  const intl = useIntl();
  const initialSearchObject: DocumentSearchObject = defaultSearchObject ?? {name: ""}

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>()
  const [showUpsertPanel, setShowUpsertPanel] = useState<boolean>(false)
  const [formDocument, setFormDocument] = useState<FormDocument>({})

  const {searchObject, onReset, updateSearchField, reloadSearch} = useSearchFilter<DocumentSearchObject>(initialSearchObject)

  const editDocument = (document: DocumentDTO) => {
    const formDocument: FormDocument = {
      fileId: document.fileId,
      profiles: document.profiles?.split(",").map((profile) => PROFILE[profile]),
      activities: document.activities?.map((activity) => ({
        value: activity.id,
        label: activity.name,
      })),
      attachment: {name: document.name, extension: document.extension},
      attachmentFile: undefined,
      theme: document.theme,
      activityAreas: document.activityAreas?.map((activityArea) => ({
        value: activityArea.id,
        label: activityArea.label,
      })),
      customerId: document.customerId,
    }

    setFormDocument(formDocument);
    setShowUpsertPanel(true);
  }

  const deleteDocument = (documentId: string) => {
    setSelectedDocumentId(documentId);
    setOpenDeleteModal(true);
  }

  const doAfterDelete = () => {
    setOpenDeleteModal(false);
    reloadSearch();
  }

  const doAfterUpsert = () => {
    setFormDocument(null);
    setShowUpsertPanel(false);
    reloadSearch()
  }

  const openDocumentCreation = () => {
    setFormDocument(null);
    setShowUpsertPanel(true);
  }

  return (
    <div className={`w-100 ${className ?? ""}`}>
      <div className="d-flex flex-row-reverse align-items-end flex-wrap">
        <Button className="btn-header ms-auto" onClick={() => openDocumentCreation()}>
          {intl.formatMessage({id: "documentary_base_add_document"})}
        </Button>
        <FilterGroup className="d-flex gap-2 me-auto">
          <TextFilter
            className="main-filter"
            title={intl.formatMessage({id: "documentary_base_filter_name"})}
            value={searchObject?.name}
            onChange={(value) => updateSearchField("name", value)}
            placeholder={intl.formatMessage({id: "documentary_base_filter_name_placeholder"})}
          />

          <AutocompleteFilterPageable
            onChange={(value) => updateSearchField("activityId", value)}
            title={intl.formatMessage({id: "documentary_base_filter_activity"})}
            value={searchObject?.activityId}
            fetchData={ activityService.getActivitiesPage}
            filterFieldName="name"
          />

          <SelectFilter
            options={Object.values(USER_PROFILE).map(entry => ({label: intl.formatMessage({id: entry.label}), value: entry.id}))}
            onChange={(value) => updateSearchField("profile", value)}
            title={intl.formatMessage({id: "documentary_base_filter_profile"})}
            value={searchObject?.profile}
            isClearable
          />

          <SelectFilter
            options={Object.values(FileTheme).map(entry => ({label: intl.formatMessage({id: `FILE_THEME_${entry}`}), value: entry}))}
            onChange={(value) => updateSearchField("theme", value)}
            title={intl.formatMessage({id: "documentary_base_filter_theme"})}
            value={searchObject?.theme}
            isClearable
          />

          <Button className="w-auto" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </FilterGroup>
      </div>
      <DocumentTable
        isAdmin
        searchObject={searchObject}
        DocumentRowComponent={({document}) =>
          <DocumentTableRowAdmin document={document} editDocument={editDocument} deleteDocument={deleteDocument} />
        }
      />

      <GenericModalDelete
        titleId="modal_title_delete_document"
        open={openDeleteModal}
        onValidate={doAfterDelete}
        setOpen={setOpenDeleteModal}
        idEntity={selectedDocumentId}
        onDelete={fileService.deleteDocumentById}
        successToastMessageId="success_delete_document"
        errorToastMessageId="error_toast_delete_document"
        bodyDeleteMessage={intl.formatMessage({id: "modal_body_delete_document"})}
      />

      {showUpsertPanel && (
        <Panel
          formId="createDocumentForm"
          className="border border-info"
          title={formDocument ? "documentary_base_form_edit_title" : "documentary_base_form_add_title"}
          open={showUpsertPanel}
          onCancel={() => setShowUpsertPanel(false)}
        >
          <UpsertDocumentForm
            formId="createDocumentForm"
            doAfterUpsert={doAfterUpsert}
            initialDocument={formDocument || {customerId: customerId, activities: activityId ? [{value: activityId, label: activityName}] : []}}
            linkedToActivity={formDocument?.activities?.length > 0 || activityId !== undefined}
            linkedToActivityArea={formDocument?.activityAreas?.length > 0}
            linkedToCustomer={!!formDocument?.customerId || customerId !== undefined}
          />
        </Panel>
      )}
    </div>
  )
}

export default InternalProfileDocumentList
