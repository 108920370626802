import { Seniority } from "../interfaces/ResourceInterfaces";
import { dateUtils } from "./dateUtils";

const calculateSeniorityFromFirstMissionDate = (
  firstMission: string
): Seniority => {
  if (!firstMission) return null;

  const firstMissionDate = new Date(firstMission);
  const seniorityInMonths = dateUtils.getNumberOfMonthsBetweenTwoDates(
    firstMissionDate,
    new Date()
  );
  return {
    years: Math.floor(seniorityInMonths / 12),
    months: seniorityInMonths % 12,
  };
};

export const seniorityUtils = {
  calculateSeniorityFromFirstMissionDate
}