import {API_RESOURCE_CALENDAR_PATH} from "../constants/routes/RoutePaths";
import {ResourceCalendarInfos, ResourceCalendarResource, ResourceCalendarSearchFilter} from "../interfaces/ResourceCalendarInterfaces";
import {PageResponse} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {httpService} from "./HttpService";

function getResourceCalendar(filter?: ResourceCalendarSearchFilter): Promise<ResourceCalendarInfos> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get(`${API_RESOURCE_CALENDAR_PATH}/${queryParams}`);
}

function getResourcesPaged(filter: ResourceCalendarSearchFilter): Promise<PageResponse<ResourceCalendarResource>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams({}, filter)
  return httpService.get(`${API_RESOURCE_CALENDAR_PATH}/resources/${queryParams}`)
    .then((r: ResourceCalendarResource[]) => ({content: r}));
}

export const resourceCalendarService = Object.freeze({
  getResourceCalendar,
  getResourcesPaged,
})