import React, {FunctionComponent, useMemo, useRef, useState} from "react";
import {tableUtils} from "../../../utils/tableUtils";
import {useIntl} from "react-intl";
import Button from "../../atoms/Button";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import DateFilter from "../filters/DateFilter";
import {PageResponse, PaginationQueryParams} from "../../../interfaces/api/PaginationInterface";
import {orderItemService} from "../../../services/OrderItemService";
import {ORDER_ITEM_TABLE} from "../../../constants/Table";
import {customerService} from "../../../services/CustomerService";
import {OrderItemResponse, OrderItemSearchObject, OrderItemState} from "../../../interfaces/OrderItemInterfaces";
import PagedTableWithRowSelection, {PagedTableRef} from "../table/PagedTableWithRowSelection";
import {invoiceService} from "../../../services/InvoiceService";
import {Row} from "react-table";
import {Data} from "../../../interfaces/TableInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import {dateUtils} from "../../../utils/dateUtils";
import ModalGenerateInvoice from "../modal/ModalGenerateInvoice";
import {useNavigate} from "react-router-dom";
import {INVOICES_PATH} from "../../../constants/routes/RoutePaths";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {Customer, CustomerSearchObject} from "../../../interfaces/CustomerInterfaces";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";

interface BillingListProps {
  className?: string
}

const PreBillingList: FunctionComponent<BillingListProps> = ({
  className = ""
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const initialSearchState: OrderItemSearchObject = useMemo(() => ({
    codeOrReference: "",
    customersIds: [],
    billingServicesIds: [],
    status: [OrderItemState.VALIDATED, OrderItemState.CANCELLED],
    billed: false,
    startDate: undefined,
    endDate: undefined,
  }), [])

  const {searchObject, onReset, updateSearchField} = useSearchFilter<OrderItemSearchObject>(
    initialSearchState,
    {
      mutateInitialState: (params: URLSearchParams) => {
        // If there's no params, then we add start/end date to default filters!
        if (Array.from(params.keys()).length === 0) {
          return {
            ...initialSearchState,
            startDate: dateUtils.formatDateYYYYMMDD(dateUtils.firstDayOfLastMonth()),
            endDate: dateUtils.formatDateYYYYMMDD(dateUtils.lastDayOfLastMonth()),
          }
        }
        return initialSearchState
      }
    })

  const [openPreinvoiceModal, setOpenPreinvoiceModal] = useState(false)
  const [selectedDatas, setSelectedDatas] = useState<OrderItemResponse[]>([])
  const [billingCustomersBool, setBillingCustomersBool] = useState<boolean>(false);

  const ref = useRef<PagedTableRef>()

  const getBillingCustomers = useMemo(() => (page: PaginationQueryParams, filter: CustomerSearchObject): Promise<PageResponse<Customer>> => {
    return customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE, orderCustomerId: searchObject?.customersIds?.toString()})
  }, [billingCustomersBool]);

  const columns = useMemo(() => tableUtils.getColumns(ORDER_ITEM_TABLE(intl)), []);
  const getOrders = (query: PaginationQueryParams, filter: OrderItemSearchObject) => {
    return orderItemService.getOrderItemsPage(query, filter)
  }

  const onValidate = () =>
    invoiceService.createInvoice(selectedDatas.map(data => data.id))
      .then(() => {
        ref.current?.refresh()
        setSelectedDatas([])
        toastUtils.successToast(intl.formatMessage({id:"billing_generate_preinvoice_success"}))
        navigate(`${INVOICES_PATH}`)
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "billing_generate_preinvoice_error"})))
      .finally(() => {
        setOpenPreinvoiceModal(false)
      });

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          value={searchObject?.codeOrReference}
          onChange={(value) => updateSearchField("codeOrReference", value)}
          title={intl.formatMessage({id: "order_item_order_number"})}
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("customersIds", value)}
          title={intl.formatMessage({id: "dropdown_header_order_customer"})}
          value={searchObject?.customersIds?.toString()}
          fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          onBlur={() => setBillingCustomersBool(!billingCustomersBool)}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />

        <AutocompleteFilterPageable
          onChange={(value) => updateSearchField("billingServicesIds", value)}
          title={intl.formatMessage({id: "dropdown_header_billing_customer"})}
          value={searchObject?.billingServicesIds?.toString()}
          fetchData={getBillingCustomers}
          filterFieldName="fullName"
          filterFieldSearch="name"
        />

        <DateFilter
          value={searchObject?.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "billing_from"})}
        />

        <DateFilter
          value={searchObject?.endDate} onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "billing_to"})}
        />

        <Button
          onClick={() => onReset()}
          color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}
        </Button>
      </FilterGroup>

      <ModalGenerateInvoice
        datas={selectedDatas}
        onValidate={onValidate}
        onCancel={() => setOpenPreinvoiceModal(false)}
        open={openPreinvoiceModal}
      />

      <PagedTableWithRowSelection
        ref={ref}
        columns={columns}
        labelNoResult="billing_no_result"
        className={`${className} table`}
        filters={searchObject}
        mandatorySortBy={[{sortBy: "rendezVousDate", descending: true}]}
        fetchData={getOrders}
        rowSelectActionTitle="billing_create_invoice"
        onRowSelectAction={(selectedRows: Array<Row<Data>>) => {
          setSelectedDatas(selectedRows.map(i => i.original as OrderItemResponse))
          setOpenPreinvoiceModal(true)
        }}
      />
    </>
  )
}

export default PreBillingList;
