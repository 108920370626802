import React, {FunctionComponent, useState} from "react";
import {RouteProps, useSearchParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import BookingList from "../components/molecules/list/BookingList";
import {useIntl} from "react-intl";
import PageTitle from "../components/molecules/header/PageTitle";
import HeaderButtonGroup, {HeaderStateButton} from "../components/molecules/header/HeaderButtonGroup";
import BookingPendingList from "../components/molecules/list/BookingPendingList";
import {BookingStatus, DefaultPendingBookingState} from "../interfaces/BookingInterfaces";

const BookingListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const [params] = useSearchParams();
  const [pendingBookingListState, setPendingBookingListState] = useState<DefaultPendingBookingState>({
    bookingId: params.get("bookingId"),
    displayPending: !!params.get("displayPending"),
    displayClosed: !!params.get("displayClosed"),
  })

  const stateFilterButtons: HeaderStateButton[] = [
    {
      title: intl.formatMessage({id: "booking_to_do"}),
      onclick: () => setPendingBookingListState({...pendingBookingListState, displayClosed: false, displayPending: false}),
      active: pendingBookingListState?.displayPending === false && pendingBookingListState?.displayClosed === false
    },
    {
      title: intl.formatMessage({id: "booking_pending"}),
      onclick: () => setPendingBookingListState({bookingId: "", displayClosed: false, displayPending: true}),
      active: pendingBookingListState?.displayPending === true
    },
    {
      title: intl.formatMessage({id: "purchase_header_close"}),
      onclick: () => setPendingBookingListState({bookingId: "", displayClosed: true, displayPending: false}),
      active: pendingBookingListState?.displayClosed === true
    },
  ]

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "bookings_title"})}></PageTitle>
          <HeaderButtonGroup className="d-flex flex-1 justify-content-center me-5" buttonList={stateFilterButtons}/>
        </div>
        {pendingBookingListState?.displayPending ?
          <BookingPendingList defaultPendingBookingToOpenId={pendingBookingListState?.bookingId}/>
          : pendingBookingListState?.displayClosed ?
            <BookingList defaultPendingBookingToOpenId={pendingBookingListState?.bookingId} status={BookingStatus.CLOSED}/> :
            <BookingList status={BookingStatus.TO_DO}/>
        }
      </div>
    </Layout>
  );
}

export default BookingListView
