import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import {ORDERS_PATH} from "../../../../constants/routes/RoutePaths";
import {OrderItemDayStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {dateUtils} from "../../../../utils/dateUtils";
import TextLink from "../../../atoms/TextLink";
import ResourceCircle from "../../circle/ResourceCircle";

interface StaffingConfirmedTableRowProps {
  className?: string,
  orderItem: OrderItemDayStaffingPageItemDto,
  onRowClick?: (orderItemId: string) => void,
}

const StaffingConfirmedTableRow: FunctionComponent<StaffingConfirmedTableRowProps> = ({
  className = "",
  orderItem,
  onRowClick,
}) => {
  return (
    <div onClick={() => onRowClick(orderItem.id)} className={`staffing-pending-row ${className ?? ""}`}>
      <Col>
        <div className="row">
          <ResourceCircle  className="me-2" resourceType={orderItem?.resourceType} />
          {orderItem?.resourceName}
        </div>
      </Col>

      <Col>
        {orderItem?.activityName}
      </Col>

      <Col className="bold">
        {`${dateUtils.formatToLocalDateString(dateUtils.parseDate(orderItem.rendezVousDate))} - ${dateUtils.formatLocalTime(orderItem.rendezVousHour)}`}
      </Col>

      <Col>
        {dateUtils.formatDuration(orderItem?.duration)}
      </Col>

      <Col>
        <TextLink to={`${ORDERS_PATH}/${orderItem?.orderId}#${orderItem?.orderItemId}`}><>{orderItem.customerReference}</></TextLink>
      </Col>
    </div>
  )
}

export default StaffingConfirmedTableRow;
