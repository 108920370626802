import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Formik} from "formik";
import {Row} from "reactstrap";
import FormInput from "../input/FormInput";
import {CommentRequest} from "../../../interfaces/CommentInterface";
import {CreateCommentSchema} from "../../../constants/validation/CommentValidationScheme";
import Button from "../../atoms/Button";
import {SizeType} from "../../../types/bootstrap/BootstrapType";
import EpowForm from "./EpowForm";

interface CreateCommentFormProps {
  formId?: string,
  className?: string,
  labelRef?: string,
  initialValue?: string,
  submitCallback?: (commentRequest: CommentRequest) => void
}

const CreateCommentForm: FunctionComponent<CreateCommentFormProps> = ({
  formId,
  className = "",
  labelRef = "",
  initialValue,
  submitCallback
}) => {
  const intl = useIntl()

  const COMMENT_INITIAL_VALUE: CommentRequest = {
    content: initialValue
  }

  return (
    <>
      <Formik initialValues={COMMENT_INITIAL_VALUE} validationSchema={CreateCommentSchema} onSubmit={submitCallback}>
        {({ errors, touched, values, setFieldValue}) => (
          <EpowForm id={formId} className={`${className}`} >
            <Row>
              <FormInput
                id="comment"
                type="textarea"
                label={labelRef && intl.formatMessage({id: labelRef})}
                value={values.content}
                onChange={(e) => setFieldValue("content", e.target?.value)}
                required
                error={errors.content}
                touched={touched.content}
                rows="5"
              />
            </Row>
          </EpowForm>
        )}
      </Formik>
      <div>
        <Button form={formId} type="submit" size={SizeType.MD} className="extended">
          <FormattedMessage id="save_button"/>
        </Button>
      </div>
    </>
  )

}
export default CreateCommentForm