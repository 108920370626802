import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Label} from "reactstrap";
import {InputType} from "reactstrap/types/lib/Input";
import TextInput from "../../../atoms/input/TextInput";

interface OrderImportCardFieldProps {
  className?: string,
  value: string,
  error: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  type?: InputType,
}

const OrderImportCardField: FunctionComponent<OrderImportCardFieldProps> = ({
  className = "",
  value,
  error,
  onChange,
  type = "text",
}) => {
  return (
    <div className={`${className}`}>
      <TextInput
        type={type}
        value={value}
        invalid={!!error}
        onChange={onChange}
      />
      <div className="epow-error">
        {error && <Label><FormattedMessage id={error} /></Label>}
      </div>
    </div>
  )
}

export default OrderImportCardField;