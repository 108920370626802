import React, {FunctionComponent} from "react";

interface FilterTitleProps {
  className?: string,
  text: string,
}

const FilterLabel: FunctionComponent<FilterTitleProps> = ({
  className = "",
  text,
}) => {

  return (
    <div className={className}>
      {text}
    </div>
  )
}

export default FilterLabel;