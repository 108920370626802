import {Activity} from "../../../../interfaces/ActivityInterfaces";
import React, {FunctionComponent, useState} from "react";
import {Col} from "reactstrap";
import CreateActivityForm from "../../form/CreateActivityForm";
import Button from "../../../atoms/Button";
import {ColorType, SizeType, TitleSizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import ActivityCard from "./ActivityCard";
import Title from "../../../atoms/Title";
import useLoading from "../../../../hooks/useLoading";

interface GeneralInfoActivityCardProps {
  className?: string,
  title: string,
  activity: Activity,
  setActivity?: (activity: Activity) => void
}

const GeneralInfoActivityCard: FunctionComponent<GeneralInfoActivityCardProps> = ({
  className = "",
  title,
  activity,
  setActivity
}) => {
  const [editInfos, setEditInfos] = useState<boolean>(false)
  const {loading, stopLoading, startLoading} = useLoading()

  return (
    <Col className={className}>
      <div className="mb-3">
        <Title title={title} size={TitleSizeType.H5}/>
      </div>
      {editInfos ? (
        <>
          <CreateActivityForm
            id="editActivityForm"
            activityInitial={activity}
            setEditInfo={setEditInfos}
            setActivity={setActivity}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />

        </>
      ) : (
        <>
          <ActivityCard activity={activity} />
          <div>
            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfos(!editInfos)} className="extended" loading={loading}>
              <FormattedMessage id="edit_button" />
            </Button>
          </div>
        </>
      )}

      <div className={editInfos ? "d-flex" : "d-none"}>
        <Button form="editActivityForm" type="submit" size={SizeType.MD} className="extended me-2">
          <FormattedMessage id="save_button" />
        </Button>
        <Button type="button" size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfos(false)} className="extended">
          <FormattedMessage id="cancel_button" />
        </Button>
      </div>
    </Col>
  )
}

export default GeneralInfoActivityCard
