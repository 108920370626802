import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import {ModalButtonModalProps} from "../button/ModalButton";

interface ModalDeletePictureProps extends ModalButtonModalProps {
  className?: string,
  onDeleteFile: () => void,
  deleteMessage: string
}

const ModalDeletePicture: FunctionComponent<ModalDeletePictureProps> = ({
  className = "",
  onDeleteFile,
  modalOpen,
  setModalOpen,
  deleteMessage
}) => {
  const intl = useIntl()

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({id: "picture_modal_title"})}
      open={modalOpen}
      onOpen={setModalOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: onDeleteFile
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => setModalOpen(false)
        }
      }}
    >
      <div className="d-flex flex-column">
        <FormattedMessage id={deleteMessage} />
      </div>
    </GenericModal>
  )
}

export default ModalDeletePicture;
