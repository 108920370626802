import React, {FunctionComponent} from "react";
import Icon from "../../icon/Icon";

interface ActionButtonBillingProps {
  onClickUpdate?: () => void;
  onClickDelete?: () => void;
}

const ActionButtonBilling: FunctionComponent<ActionButtonBillingProps> = ({
  onClickUpdate,
  // onClickDelete
}) =>
  (<>
    <Icon name="Pen" className="cursor-pointer" onClick={onClickUpdate}/>
    {/* <Icon name="Trash" className="ms-1 cursor-pointer" onClick={onClickDelete}/> */}
  </>)


export default ActionButtonBilling;
