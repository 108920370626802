import React, {FunctionComponent, useMemo, useState} from "react";
import {RouteProps, useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import {OrderCsvItem} from "../interfaces/OrderInterfaces";
import {orderService} from "../services/OrderService";
import Button from "../components/atoms/Button";
import {Input, Label} from "reactstrap";
import {toastUtils} from "../utils/toastUtils";
import {ORDERS_PATH} from "../constants/routes/RoutePaths";
import OrderImportCard from "../components/molecules/card/order/OrderImportCard";
import {customerService} from "../services/CustomerService";
import {Option} from "../interfaces/inputs/OptionInterfaces";
import PageTitle from "../components/molecules/header/PageTitle";
import {ApiError} from "../interfaces/ErrorInterfaces";
import {fileUtils} from "../utils/fileUtils";

const OrderCSVView: FunctionComponent<RouteProps> = () => {
  const [csvOrderItem, setCsvOrderItem] = useState<OrderCsvItem[]>(null)

  const [customerOptions, setCustomerOptions] = useState<Option<string>[]>([])
  const [tourOptionsForCustomer, setTourOptionsForCustomer] = useState<Map<string, Option<string>[]>>(new Map())

  const intl = useIntl()
  const navigate = useNavigate()

  useMemo(() => {
    customerService.getCustomerWithTours()
      .then(response => {
        const customersOptions = response.map(customer => ({label: customer.name, value: customer.id}));

        const toursByCustomer: Map<string, Option<string>[]> = new Map()
        // Build tour options for each customer
        response.map((customer) => {
          toursByCustomer[customer.id] = customer.tours.map(tour => ({label: tour.tourName, value: tour.id}))
        });

        setCustomerOptions(customersOptions)
        setTourOptionsForCustomer(toursByCustomer)
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "order_csv_import_error"}))
      })
  }, [])


  const sendCsv = (file: File) => {
    if (file) {
      orderService.sendCsv(file).then(response => {
        setCsvOrderItem(response)
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "order_csv_import_error"}))
      })
    }
  }

  const handleOrdersCsvImport = (e: React.FormEvent<HTMLInputElement>) => {
    const selectedFile = (e.target as HTMLInputElement).files[0];
    sendCsv(selectedFile)
  };

  const sendOrders = () => {
    orderService.importOrders(csvOrderItem)
      .then((response: OrderCsvItem[]) => {
        if (response.length > 0) {
          toastUtils.errorToast(intl.formatMessage({id: "order_csv_error"}))
          setCsvOrderItem(response)
        } else {
          toastUtils.successToast(intl.formatMessage({id: "order_csv_success"}))
          navigate(ORDERS_PATH)
        }
      }).catch((error: ApiError) => {
      toastUtils.errorToast(error.message)
    })
  }

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <PageTitle
          pageTitle={intl.formatMessage({id: "order_csv_title"})}
          className="me-4"
          breadcrumbItems={[{
            title: intl.formatMessage({id: "orders_title"}),
            to: ORDERS_PATH
          }]}
        />
        <div className="d-flex align-content-center align-self-center col-12 my-4">
          <Input type="file" accept=".csv" onChange={handleOrdersCsvImport} />
        </div>

        <div className="epow-text-link fs-6 cursor-pointer " onClick={() => {
          const templateCsv = orderService.getTemplateCsv();
          fileUtils.downloadFile(templateCsv, {extension: "csv", name: "template_import_order"}, intl)
        }}>
          <FormattedMessage id="order_csv_template"/>
        </div>

        {csvOrderItem && csvOrderItem.length > 0 && <div className="d-flex flex-column  w-100">

          <div className="d-flex w-100">
            <div className="col-1"></div>
            <Label className="col-5 text-center"><FormattedMessage id="order_customer" /></Label>
            <Label className="col-5 text-center"><FormattedMessage id="order_tour" /></Label>
            <Label className="col-4 text-center"><FormattedMessage id="order_referenceClient" /></Label>
            <Label className="col-4 text-center"><FormattedMessage id="order_dayZero" /></Label>
            <Label className="col-4 text-center"><FormattedMessage id="order_date" /></Label>
            <div className="col-1"></div>
          </div>

          {csvOrderItem?.map((item, index) =>
            <OrderImportCard
              key={index}
              item={item}
              itemList={csvOrderItem}
              setItemList={setCsvOrderItem}
              customerOptions={customerOptions}
              tourOptionsForCustomer={tourOptionsForCustomer}
            />
          )}

          <div className="d-flex w-100 justify-content-end align-content-center p-3">
            <Button onClick={sendOrders}>{intl.formatMessage({id: "create_orders"})}</Button>
          </div>
        </div>}
      </div>
    </Layout>
  )
}

export default OrderCSVView
