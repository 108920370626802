import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import usePaginatedData from "../../../hooks/usePaginatedData";
import {OrderSearchObject} from "../../../interfaces/OrderInterfaces";
import {customerService} from "../../../services/CustomerService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import Pagination from "../pagination/Pagination";
import OrderTable from "../table/order/OrderTable";
import {ResourceDisplayOption} from "../../../constants/ResourceDisplayOptionConstant";

interface CustomerOrderListProps {
  className?: string,
  resourceDisplayOption: ResourceDisplayOption,
  searchObject: OrderSearchObject,
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void
}

const CustomerOrderList: FunctionComponent<CustomerOrderListProps> = ({
  className = "",
  resourceDisplayOption,
  searchObject,
  updateSearchField,
  onReset,
}) => {
  const intl= useIntl()
  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    totalElements,
  } = usePaginatedData((params, filter) => customerService.getCustomersOrderPage(params, filter), searchObject)

  return (
    <div className={`mb-5 ${className ?? ""}`}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "order_filter_code_title"})}
          value={searchObject.customerOrCode}
          onChange={(value) => updateSearchField("customerOrCode", value)}
          placeholder={intl.formatMessage({id: "order_filter_code_placeholder"})}
        />
        <DateFilter
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "order_filter_period_start"})}
        />
        <DateFilter
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "order_filter_period_end"})}
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <OrderTable resourceDisplayOption={resourceDisplayOption} orders={data} total={totalElements} sortOptions={sortOptions} onSortOptions={setSortOptions} isCustomer />
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default CustomerOrderList;
