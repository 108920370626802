import {API_LANGUAGES_PATH} from "../constants/routes/RoutePaths";
import {Language, LanguageSearchObject} from "../interfaces/LanguageInterface";
import {httpService} from "./HttpService";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";

function getLanguages(): Promise<Language[]> {
  return httpService.get<Language[]>(`${API_LANGUAGES_PATH}`);
}

function getLanguagesPage(params: PaginationQueryParams, filters: LanguageSearchObject): Promise<PageResponse<Language>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<Language>>(`${API_LANGUAGES_PATH}/page${queryParams}`)
}


export const languageService = {
  getLanguages,
  getLanguagesPage
}
