import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {TOUR_LEADER_DETAILS_VALUES} from "../../../constants/TourLeaderConstants";
import {EditDetailsTourLeaderSchema} from "../../../constants/validation/TourLeaderValidationSchemas";
import FormInput from "../input/FormInput";
import {TourLeader, TourLeaderFormFields, TourLeaderRequest} from "../../../interfaces/TourLeaderInterfaces";
import {tourLeaderService} from "../../../services/TourLeaderService";
import {toastUtils} from "../../../utils/toastUtils";
import {FormattedMessage, useIntl} from "react-intl";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormRadio from "../input/FormRadio";
import {YES_NO_OPTIONS} from "../../../constants/OptionConstants";
import EpowForm from "./EpowForm";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import {customerService} from "../../../services/CustomerService";
import {profileUtils} from "../../../utils/profileUtils";
import {ApiError} from "../../../interfaces/ErrorInterfaces";

interface EditDetailsTourLeaderFormProps {
  id?: string,
  className?: string,
  originalTourLeader?: TourLeader,
  setEditInfo?: (editInfo: boolean) => void,
  setTourLeader?: (tourLeader?: TourLeader) => void
}

const EditDetailsTourLeaderForm: FunctionComponent<EditDetailsTourLeaderFormProps> = ({
  id,
  className = "",
  originalTourLeader,
  setEditInfo,
  setTourLeader
}) => {
  const intl = useIntl()
  const tourLeaderId = originalTourLeader?.id
  const initialTourLeader = tourLeaderService.buildTourLeaderFields(originalTourLeader)

  const handleSubmitTourLeader = (tourLeader: TourLeaderFormFields) => {
    const request: TourLeaderRequest = {
      id: tourLeader?.id,
      identity: tourLeader?.identity,
      customersId: tourLeader.customersOptions?.map(o => o.value) ?? [],
      hasAccessPlatform: tourLeader?.hasAccessPlatform
    }

    if (tourLeaderId) {

      if(!profileUtils.isTourLeader()) {
      tourLeaderService.updateTourLeader(tourLeaderId, {...originalTourLeader, ...request})
        .then((tourLeader) => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_update_tour_leader"}))
          setEditInfo(false)
          setTourLeader(tourLeader)
        })
        .catch((error: ApiError) => {
          if (error?.message) {
            toastUtils.errorToast(error.message)
          } else {
            toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_tour_leader"}))
          }
        })
      } else {
        tourLeaderService.updateSelfTourLeader({...originalTourLeader, ...request})
          .then((tourLeader) => {
            toastUtils.successToast(intl.formatMessage({id: "success_toast_update_self_tour_leader"}))
            setEditInfo(false)
            setTourLeader(tourLeader)
          })
          .catch(() => {
            toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_tour_leader"}))
          })
      }
    }
  }

  return (
    <Formik initialValues={initialTourLeader || TOUR_LEADER_DETAILS_VALUES} validationSchema={EditDetailsTourLeaderSchema} onSubmit={(value) => handleSubmitTourLeader(value)}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="firstName"
            label="tour_leader_first_name_field"
            value={values?.identity?.firstName}
            onChange={(e) => setFieldValue("identity.firstName", e.target.value)}
            required
            error={errors.identity?.firstName}
            touched={touched.identity?.firstName}
          />
          <FormInput
            id="lastName"
            label="tour_leader_last_name_field"
            value={values?.identity?.lastName}
            onChange={(e) => setFieldValue("identity.lastName", e.target.value)}
            required
            error={errors.identity?.lastName}
            touched={touched.identity?.lastName}
          />
          <FormInput
            id="phoneNumber"
            label="contact_phone_number_field"
            value={values?.identity?.phoneNumber}
            onChange={(e) => setFieldValue("identity.phoneNumber", e.target.value)}
            required
            error={errors.identity?.phoneNumber}
            touched={touched.identity?.phoneNumber}
          />
          <FormInput
            id="otherPhoneNumber"
            label="contact_other_phone_number_field"
            value={values?.identity?.otherPhoneNumber}
            onChange={(e) => setFieldValue("identity.otherPhoneNumber", e.target.value)}
            error={errors.identity?.otherPhoneNumber}
            touched={touched.identity?.otherPhoneNumber}
          />
          <FormInput
            id="email"
            label="tour_leader_email_field"
            value={values?.identity?.email}
            onChange={(e) => setFieldValue("identity.email", e.target.value)}
            required
            error={errors.identity?.email}
            touched={touched.identity?.email}
          />
          <FormAutocompleteMultiselect
            id="customersId"
            label={intl.formatMessage({id: "customer_represented_by_tour_leader_field"})}
            values={values.customersOptions}
            onChange={(selectedOptions) => setFieldValue("customersOptions", selectedOptions)}
            fetchData={(inputValue) => customerService.getActiveCustomers({}, {name: inputValue})}
            filterFieldName="fullName"
            error={errors?.customersOptions}
            touched={touched?.customersOptions}
          />
          {profileUtils.isInterne() &&
            <>
              <FormRadio
                id="hasAccessPlatform"
                label="contact_has_access_platform_field"
                value={YES_NO_OPTIONS.find((option) => option?.value === values?.hasAccessPlatform)}
                options={YES_NO_OPTIONS}
                onChange={(option?: Option<string>) => setFieldValue("hasAccessPlatform", option?.value)}
                error={errors.hasAccessPlatform}
                touched={touched.hasAccessPlatform}
              />
              {values?.hasAccessPlatform === "true" && (
                <FormattedMessage id="info_message_init_password"/>
              )}
            </>
          }
        </EpowForm>
      )}
    </Formik>
  )
}

export default EditDetailsTourLeaderForm
