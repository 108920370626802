import {Option} from "../interfaces/inputs/OptionInterfaces";

export const COUNTRY_OPTIONS: Option<string>[] = [
  { value: "AF", label: "Afghanistan"},
  { value: "ZA", label: "Afrique du Sud"},
  { value: "AX", label: "Åland, Îles"},
  { value: "AL", label: "Albanie"},
  { value: "DZ", label: "Algérie"},
  { value: "DE", label: "Allemagne"},
  { value: "AD", label: "Andorre"},
  { value: "AO", label: "Angola"},
  { value: "AI", label: "Anguilla"},
  { value: "AQ", label: "Antarctique"},
  { value: "AG", label: "Antigua et Barbuda"},
  { value: "SA", label: "Arabie Saoudite"},
  { value: "AR", label: "Argentine"},
  { value: "AM", label: "Arménie"},
  { value: "AW", label: "Aruba"},
  { value: "AU", label: "Australie"},
  { value: "AT", label: "Autriche"},
  { value: "AZ", label: "Azerbaïdjan"},
  { value: "BS", label: "Bahamas"},
  { value: "BH", label: "Bahrein"},
  { value: "BD", label: "Bangladesh"},
  { value: "BB", label: "Barbade"},
  { value: "BY", label: "Bélarus"},
  { value: "BE", label: "Belgique"},
  { value: "BZ", label: "Bélize"},
  { value: "BJ", label: "Bénin"},
  { value: "BM", label: "Bermudes"},
  { value: "BT", label: "Bhoutan"},
  { value: "BO", label: "Bolivie (État plurinational de)"},
  { value: "BQ", label: "Bonaire, Saint-Eustache et Saba"},
  { value: "BA", label: "Bosnie-Herzégovine"},
  { value: "BW", label: "Botswana"},
  { value: "BV", label: "Bouvet, Ile"},
  { value: "BR", label: "Brésil"},
  { value: "BN", label: "Brunéi Darussalam"},
  { value: "BG", label: "Bulgarie"},
  { value: "BF", label: "Burkina Faso"},
  { value: "BI", label: "Burundi"},
  { value: "CV", label: "Cabo Verde"},
  { value: "KY", label: "Caïmans, Iles"},
  { value: "KH", label: "Cambodge"},
  { value: "CM", label: "Cameroun"},
  { value: "CA", label: "Canada"},
  { value: "CL", label: "Chili"},
  { value: "CN", label: "Chine"},
  { value: "CX", label: "Christmas, île"},
  { value: "CY", label: "Chypre"},
  { value: "CC", label: "Cocos/Keeling (Îles)"},
  { value: "CO", label: "Colombie"},
  { value: "KM", label: "Comores"},
  { value: "CG", label: "Congo"},
  { value: "CD", label: "Congo, République démocratique du"},
  { value: "CK", label: "Cook, Iles"},
  { value: "KR", label: "Corée, République de"},
  { value: "KP", label: "Corée, République populaire démocratique de"},
  { value: "CR", label: "Costa Rica"},
  { value: "CI", label: "Côte d'Ivoire"},
  { value: "HR", label: "Croatie"},
  { value: "CU", label: "Cuba"},
  { value: "CW", label: "Curaçao"},
  { value: "DK", label: "Danemark"},
  { value: "DJ", label: "Djibouti"},
  { value: "DO", label: "Dominicaine, République"},
  { value: "DM", label: "Dominique"},
  { value: "EG", label: "Egypte"},
  { value: "SV", label: "El Salvador"},
  { value: "AE", label: "Emirats arabes unis"},
  { value: "EC", label: "Equateur"},
  { value: "ER", label: "Erythrée"},
  { value: "ES", label: "Espagne"},
  { value: "EE", label: "Estonie"},
  { value: "US", label: "Etats-Unis d'Amérique"},
  { value: "ET", label: "Ethiopie"},
  { value: "FK", label: "Falkland/Malouines (Îles)"},
  { value: "FO", label: "Féroé, îles"},
  { value: "FJ", label: "Fidji"},
  { value: "FI", label: "Finlande"},
  { value: "FR", label: "France"},
  { value: "GA", label: "Gabon"},
  { value: "GM", label: "Gambie"},
  { value: "GE", label: "Géorgie"},
  { value: "GS", label: "Géorgie du sud et les îles Sandwich du sud"},
  { value: "GH", label: "Ghana"},
  { value: "GI", label: "Gibraltar"},
  { value: "GR", label: "Grèce"},
  { value: "GD", label: "Grenade"},
  { value: "GL", label: "Groenland"},
  { value: "GP", label: "Guadeloupe"},
  { value: "GU", label: "Guam"},
  { value: "GT", label: "Guatemala"},
  { value: "GG", label: "Guernesey"},
  { value: "GN", label: "Guinée"},
  { value: "GW", label: "Guinée-Bissau"},
  { value: "GQ", label: "Guinée équatoriale"},
  { value: "GY", label: "Guyana"},
  { value: "GF", label: "Guyane française"},
  { value: "HT", label: "Haïti"},
  { value: "HM", label: "Heard, Ile et MacDonald, îles"},
  { value: "HN", label: "Honduras"},
  { value: "HK", label: "Hong Kong"},
  { value: "HU", label: "Hongrie"},
  { value: "IM", label: "Île de Man"},
  { value: "UM", label: "Îles mineures éloignées des Etats-Unis"},
  { value: "VG", label: "Îles vierges britanniques"},
  { value: "VI", label: "Îles vierges des Etats-Unis"},
  { value: "IN", label: "Inde"},
  { value: "IO", label: "Indien (Territoire britannique de l'océan)"},
  { value: "ID", label: "Indonésie"},
  { value: "IR", label: "Iran"},
  { value: "IQ", label: "Iraq"},
  { value: "IE", label: "Irlande"},
  { value: "IS", label: "Islande"},
  { value: "IL", label: "Israël"},
  { value: "IT", label: "Italie"},
  { value: "JM", label: "Jamaïque"},
  { value: "JP", label: "Japon"},
  { value: "JE", label: "Jersey"},
  { value: "JO", label: "Jordanie"},
  { value: "KZ", label: "Kazakhstan"},
  { value: "KE", label: "Kenya"},
  { value: "KG", label: "Kirghizistan"},
  { value: "KI", label: "Kiribati"},
  { value: "KW", label: "Koweït"},
  { value: "LA", label: "Lao, République démocratique populaire"},
  { value: "LS", label: "Lesotho" },
  { value: "LV", label: "Lettonie" },
  { value: "LB", label: "Liban" },
  { value: "LR", label: "Libéria" },
  { value: "LY", label: "Libye" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lituanie" },
  { value: "LU", label: "Luxembourg" },
  { value: "MO", label: "Macao" },
  { value: "MK", label: "Macédoine, l'ex-République yougoslave de" },
  { value: "MG", label: "Madagascar" },
  { value: "MY", label: "Malaisie" },
  { value: "MW", label: "Malawi" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malte" },
  { value: "MP", label: "Mariannes du nord, Iles" },
  { value: "MA", label: "Maroc" },
  { value: "MH", label: "Marshall, Iles" },
  { value: "MQ", label: "Martinique" },
  { value: "MU", label: "Maurice" },
  { value: "MR", label: "Mauritanie" },
  { value: "YT", label: "Mayotte" },
  { value: "MX", label: "Mexique" },
  { value: "FM", label: "Micronésie, Etats Fédérés de" },
  { value: "MD", label: "Moldova, République de" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolie" },
  { value: "ME", label: "Monténégro" },
  { value: "MS", label: "Montserrat" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibie" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Népal" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigéria" },
  { value: "NU", label: "Niue" },
  { value: "NF", label: "Norfolk, Ile" },
  { value: "NO", label: "Norvège" },
  { value: "NC", label: "Nouvelle-Calédonie" },
  { value: "NZ", label: "Nouvelle-Zélande" },
  { value: "OM", label: "Oman" },
  { value: "UG", label: "Ouganda" },
  { value: "UZ", label: "Ouzbékistan" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palaos" },
  { value: "PS", label: "Palestine, Etat de" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papouasie-Nouvelle-Guinée" },
  { value: "PY", label: "Paraguay" },
  { value: "NL", label: "Pays-Bas" },
  { value: "PE", label: "Pérou" },
  { value: "PH", label: "Philippines" },
  { value: "PN", label: "Pitcairn" },
  { value: "PL", label: "Pologne" },
  { value: "PF", label: "Polynésie française" },
  { value: "PR", label: "Porto Rico" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "SY", label: "République arabe syrienne" },
  { value: "CF", label: "République centrafricaine" },
  { value: "RE", label: "Réunion" },
  { value: "RO", label: "Roumanie" },
  { value: "GB", label: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord" },
  { value: "RU", label: "Russie, Fédération de" },
  { value: "RW", label: "Rwanda" },
  { value: "EH", label: "Sahara occidental" },
  { value: "BL", label: "Saint-Barthélemy" },
  { value: "KN", label: "Saint-Kitts-et-Nevis" },
  { value: "SM", label: "Saint-Marin" },
  { value: "MF", label: "Saint-Martin (partie française)" },
  { value: "SX", label: "Saint-Martin (partie néerlandaise)" },
  { value: "PM", label: "Saint-Pierre-et-Miquelon" },
  { value: "VA", label: "Saint-Siège" },
  { value: "VC", label: "Saint-Vincent-et-les-Grenadines" },
  { value: "SH", label: "Sainte-Hélène, Ascension et Tristan da Cunha" },
  { value: "LC", label: "Sainte-Lucie" },
  { value: "SB", label: "Salomon, Iles" },
  { value: "WS", label: "Samoa" },
  { value: "AS", label: "Samoa américaines" },
  { value: "ST", label: "Sao Tomé-et-Principe" },
  { value: "SN", label: "Sénégal" },
  { value: "RS", label: "Serbie" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapour" },
  { value: "SK", label: "Slovaquie" },
  { value: "SI", label: "Slovénie" },
  { value: "SO", label: "Somalie" },
  { value: "SD", label: "Soudan" },
  { value: "SS", label: "Soudan du Sud" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SE", label: "Suède" },
  { value: "CH", label: "Suisse" },
  { value: "SR", label: "Suriname" },
  { value: "SJ", label: "Svalbard et île Jan Mayen" },
  { value: "SZ", label: "Swaziland" },
  { value: "TJ", label: "Tadjikistan" },
  { value: "TW", label: "Taïwan, Province de Chine" },
  { value: "TZ", label: "Tanzanie, République unie de" },
  { value: "TD", label: "Tchad" },
  { value: "CZ", label: "Tchèque, République" },
  { value: "TF", label: "Terres australes françaises" },
  { value: "TH", label: "Thaïlande" },
  { value: "TL", label: "Timor-Leste" },
  { value: "TG", label: "Togo" },
  { value: "TK", label: "Tokelau" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinité-et-Tobago" },
  { value: "TN", label: "Tunisie" },
  { value: "TM", label: "Turkménistan" },
  { value: "TC", label: "Turks-et-Caïcos (Îles)" },
  { value: "TR", label: "Turquie" },
  { value: "TV", label: "Tuvalu" },
  { value: "UA", label: "Ukraine" },
  { value: "UY", label: "Uruguay" },
  { value: "VU", label: "Vanuatu" },
  { value: "VE", label: "Venezuela (République bolivarienne du)" },
  { value: "VN", label: "Viet Nam" },
  { value: "WF", label: "Wallis et Futuna" },
  { value: "YE", label: "Yémen" },
  { value: "ZM", label: "Zambie" },
  { value: "ZW", label: "Zimbabwe" },
]