import React, {FunctionComponent, useMemo, useState} from "react";
import {SortOptions} from "../../../../interfaces/api/PaginationInterface";
import {Order, OrderList} from "../../../../interfaces/OrderInterfaces";
import OrderTableRow from "./OrderTableRow";
import OrderTableRowDetails from "./OrderTableRowDetails";
import CustomTable, {CustomTableRef} from "../CustomTable";
import {CustomTableColumn, CustomTableType, Data} from "../../../../interfaces/TableInterfaces";
import {FormattedMessage, useIntl} from "react-intl";
import Toggle from "../../../atoms/Toggle";
import UpdateTourLeaderOrderForm from "../../form/UpdateTourLeaderOrderForm";
import Panel from "../../panel/Panel";
import {Input} from "reactstrap";
import ModalButton from "../../button/ModalButton";
import ModalTransitionOrdersToPending from "../../modal/order/ModalTransitionOrdersToPending";
import {ResourceDisplayOption} from "../../../../constants/ResourceDisplayOptionConstant";

interface OrderTableProps {
  className?: string,
  orders: OrderList[],
  sortOptions: SortOptions,
  onSortOptions: (sortOptions: SortOptions) => void,
  refresh?: () => void,
  total?: number,
  isCustomer?: boolean,
  canEdit?: boolean,
  itemPrefixPath?: string,
  isSelectable?: boolean,
  resourceDisplayOption?: ResourceDisplayOption
}

const OrderTable: FunctionComponent<OrderTableProps> = ({
  className = "",
  orders,
  sortOptions,
  onSortOptions,
  total,
  refresh,
  canEdit = false,
  isCustomer = false,
  itemPrefixPath,
  isSelectable,
  resourceDisplayOption
}) => {
  const intl = useIntl()
  const [selectedOrder, setSelectedOrder] = useState<OrderList>(null)
  const [ordersToModify, setOrdersToModify] = useState<OrderList[]>([])
  const showPanel = Boolean(selectedOrder);
  const ref = React.useRef<CustomTableRef>();

  const columns: CustomTableColumn[] = useMemo(() => isCustomer ?
    [
      {title: "order_customer_ref", accessor: "customerReference", sortable: true},
      {title: "order_dayZero", accessor: "beginTourDate", sortable: true},
      {title: "order_tour_leader", accessor: "tourLeader", sortable: false},
      {title: "order_activities", accessor: "items.length", sortable: false},
      {title: "order_price", accessor: "", sortable: false},
      {title: "order_code", accessor: "orderNumber", sortable: true},
    ] : [
      {title: "order_customer", accessor: "customer.name", sortable: true},
      {title: "order_customer_ref", accessor: "customerReference", sortable: true},
      {title: "order_dayZero", accessor: "beginTourDate", sortable: true},
      {title: "order_tour_leader", accessor: "tourLeader", sortable: false},
      {title: "order_activities", accessor: "items.length", sortable: false},
      {title: "order_code", accessor: "orderNumber", sortable: true},
      {title: "order_status", accessor: "orderStatus", sortable: true},
      {title: "table_actions", accessor: "actions", sortable: false},
    ], [])

  const toogleOrderToModify = (element: OrderList) => {
    const index = ordersToModify.indexOf(element);
    const copy = [...ordersToModify]
    if (index >= 0) {
      copy.splice(index)
    } else {
      copy.push(element)
    }
    setOrdersToModify(copy)
  }

  const buildOrderRow = (data: Data, open: boolean, rest) => {
    const order = data as Order;
    return (
      <div>
        <OrderTableRow
          itemPrefixPath={itemPrefixPath}
          order={order}
          isCustomer={isCustomer}
          canEdit={canEdit}
          hasCheckbox={!isCustomer && isSelectable}
          onSelectOrder={setSelectedOrder}
          toogleOrderToModify={toogleOrderToModify}
          orderIsToModify={ordersToModify?.some(entry => entry.id === order.id)}
          {...rest}
        />
        {open && (
          <div className="epow-custom-table-row-details">
            {order.orderComment &&
              <span><b><FormattedMessage id="order_comment" /></b> : {order.orderComment}</span>
            }
            <OrderTableRowDetails order={order} isCustomer={isCustomer}  resourceDisplayOption={resourceDisplayOption}/>
          </div>
        )}
      </div>);
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
        <div className="me-3 bold">
          <FormattedMessage id="order_total_number" values={{total}} />
        </div>
        <div className="d-flex align-items-center bold">
          <FormattedMessage id="table_see_all_details" />
          <Toggle className="ms-2" id="order_all_details" onChange={(checked) => ref.current?.toggleAllRows(checked)} />
        </div>
      </div>

      {showPanel &&
        <Panel
          formId="UpdateTourLeaderOrderForm"
          className="border border-info"
          title={"order_update_tour_leader"}
          open={showPanel}
          onCancel={() => setSelectedOrder(null)}
        >
          <UpdateTourLeaderOrderForm
            id="UpdateTourLeaderOrderForm"
            order={selectedOrder}
            afterSubmit={() => {
              refresh()
              setSelectedOrder(null)
            }} />
        </Panel>}

      {isSelectable && (
        <div>
          <Input
            className="ms-2"
            type="checkbox"
            checked={ordersToModify?.length > 0}
            onChange={() => ordersToModify?.length > 0 ? setOrdersToModify([]) : setOrdersToModify(orders)}
          />
        </div>
      )}
      <CustomTable
        ref={ref}
        onSortOptions={onSortOptions}
        sortOptions={sortOptions}
        className={`${className}`}
        type={CustomTableType.DETAILS_TABLE}
        datas={orders}
        columns={columns}
        RowComponent={({data, open, ...rest}) => buildOrderRow(data, open, rest)} />

      {ordersToModify?.length > 0 && (
        <div className="d-flex mb-2 align-items-center">
          <div className="ms-auto me-2 italic">
            <FormattedMessage id="staffing_selected_number" values={{selectedNumber: ordersToModify.length}} />
          </div>
          <ModalButton title={intl.formatMessage({id: "validate_button"})} >
            {({modalOpen, setModalOpen}) => (
              <ModalTransitionOrdersToPending open={modalOpen} setOpen={setModalOpen} orders={ordersToModify} refresh={refresh} />
            )}
          </ModalButton>
        </div>
      )}
    </>
  )
}

export default OrderTable;
