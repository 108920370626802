import {Formik} from "formik";
import React, {FunctionComponent, useMemo, useState} from "react";
import {Row} from "reactstrap";
import FormMultiSelect from "../input/FormMultiSelect";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import FormFileUpload from "../input/FormFileUpload";
import {fileUtils} from "../../../utils/fileUtils";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {FormDocument} from "../../../interfaces/FileInterfaces";
import {DOCUMENT_FORM_INITIAL_VALUES, FileTheme} from "../../../constants/FileConstants";
import {fileService} from "../../../services/FileService";
import {activityService} from "../../../services/ActivityService";
import {createDocumentSchema} from "../../../constants/validation/DocumentValidationSchema";
import {USER_PROFILE} from "../../../constants/user/UserConstants";
import EpowForm from "./EpowForm";
import FormAutocompleteMultiselect from "../input/FormAutocompleteMultiSelect";
import FormSelect from "../input/FormSelect";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {customerService} from "../../../services/CustomerService";
import {activityAreaService} from "../../../services/ActivityAreaService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import CheckboxFilter from "../filters/CheckboxFilter";

interface UpsertDocumentFormProps {
  className?: string;
  formId?: string;
  initialDocument?: FormDocument;
  linkedToActivity?: boolean;
  linkedToActivityArea?: boolean;
  linkedToCustomer?: boolean;
  doAfterUpsert?: () => void;
}

const UpsertDocumentForm: FunctionComponent<UpsertDocumentFormProps> = ({
  className = "",
  formId,
  initialDocument,
  linkedToActivity = false,
  linkedToActivityArea = false,
  linkedToCustomer= false,
  doAfterUpsert = () => null
}) => {
  const intl = useIntl();
  const [displayActivitiesField, setDisplayActivitiesField] = useState<boolean>(linkedToActivity)
  const [displayActivityAreaField, setDisplayActivityAreaField] = useState<boolean>(linkedToActivityArea)
  const [displayCustomerField, setDisplayCustomerField] = useState<boolean>(linkedToCustomer)

  const profileOptions: Option<string>[] = useMemo(() =>
    Object.values(USER_PROFILE).map(entry => ({label: intl.formatMessage({id: entry.label}), value: entry.id})
    ), [])

  const themeOptions: Option<string>[] = useMemo(() =>
    Object.values(FileTheme).map((entry: FileTheme) => ({label: intl.formatMessage({id: `FILE_THEME_${entry}`}), value: entry})
    ), [])

  const editDocument = (submittedDocument: FormDocument) => {
    fileService.editDocument(initialDocument.fileId, submittedDocument)
      .then(() => {
        doAfterUpsert();
        toastUtils.successToast(intl.formatMessage({id: "documentary_base_form_edit_success"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "documentary_base_form_edit_failure"}));
      })
  }

  const createDocument = (submittedDocument: FormDocument) => {
    fileService.createDocument(submittedDocument)
      .then(() => {
        doAfterUpsert();
        toastUtils.successToast(intl.formatMessage({id: "documentary_base_form_create_success"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "documentary_base_form_create_failure"}));
      })
  }

  return (
    <Formik
      initialValues={initialDocument || DOCUMENT_FORM_INITIAL_VALUES}
      onSubmit={(values) => initialDocument?.fileId ? editDocument(values) : createDocument(values)}
      validationSchema={initialDocument ? undefined : createDocumentSchema}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={formId} className={className}>
          <Row className="m-4">
            <FormFileUpload
              id="attachmentFile"
              label="rule_attachment"
              file={values?.attachmentFile}
              fileName={fileUtils.getCompleteFileName(initialDocument?.attachment)}
              onChange={(file: File) => setFieldValue("attachmentFile", file)}
              required
              error={errors.attachmentFile}
              touched={touched.attachmentFile}
              locked={initialDocument?.fileId !== undefined}
            />
            <FormSelect
              id="theme"
              label="documentary_base_form_theme"
              options={themeOptions}
              value={themeOptions.find(o => o.value === values.theme)}
              onChange={(option: Option<string>) => setFieldValue("theme", option.value)}
              isSearchable
              isClearable
            />
            <FormMultiSelect
              id="profiles"
              label="documentary_base_form_profiles"
              options={profileOptions}
              values={profileOptions.filter(option => values?.profiles?.map(entry => String(entry)).includes(option.value))}
              onChange={(option: Option<string>[]) => setFieldValue("profiles", option.map(x => x.value))}
              isSearchable
              isClearable
            />
            <CheckboxFilter
              className="epow-toggle-checkbox mt-2"
              title={intl.formatMessage({id: "documentary_base_checkbox_activities"})}
              value={displayActivitiesField}
              id="displayActivitiesField"
              onChange={(checked) => setDisplayActivitiesField(checked)}
            />
            {displayActivitiesField && (
              <FormAutocompleteMultiselect
                id="activities"
                placeholder={intl.formatMessage({id: "activity_placeholder"})}
                filterFieldName="name"
                values={values?.activities}
                fetchData={(inputValue) => activityService.getActivitiesPage({pageSize: 50, page: 0}, {name: inputValue})}
                onChange={(options: Option<string>[]) => setFieldValue("activities", options)}
              />
            )}
            <CheckboxFilter
              className="epow-toggle-checkbox mt-2"
              title={intl.formatMessage({id: "documentary_base_checkbox_activityArea"})}
              value={displayActivityAreaField}
              id="displayActivityAreaField"
              onChange={(checked) => setDisplayActivityAreaField(checked)}
            />
            {displayActivityAreaField && (
              <FormAutocompleteMultiselect
                id="activityAreas"
                placeholder={intl.formatMessage({id: "activity_zone_placeholder"})}
                values={values?.activityAreas}
                onChange={(options: Option<string>[]) => setFieldValue("activityAreas", options)}
                fetchData={(inputValue) => activityAreaService.getActivityAreasPage({pageSize: 50, page: 0}, {id: inputValue})}
                filterFieldName="label"
              />
            )}
            <CheckboxFilter
              className="epow-toggle-checkbox mt-2"
              title={intl.formatMessage({id: "documentary_base_checkbox_customer"})}
              value={displayCustomerField}
              id="displayCustomerField"
              onChange={(checked) => setDisplayCustomerField(checked)}
            />
            {displayCustomerField && (
              <FormAutocompleteSelectPageable
                id="customerId"
                placeholder={intl.formatMessage({id: "customer_placeholder"})}
                onChange={(value) => setFieldValue("customerId", value)}
                value={values?.customerId}
                fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE, id: values?.customerId})}
                filterFieldName="name"
                error={errors?.customerId}
                touched={touched?.customerId}
              />
            )}
          </Row>
        </EpowForm>
      )}
    </Formik>
  )
}

export default UpsertDocumentForm;
