import {QuoteField} from "../interfaces/QuoteInterfaces";
import {dateUtils} from "../utils/dateUtils";

export const QUOTE_INITIAL_VALUES: QuoteField = {
  customerId: "",
  tourId: "",
  customerRef: "",
  emailContact: "",
  rendezVousDate: undefined,
  creationDate: dateUtils.formatDateYYYYMMDD(new Date()),
  endOfValidity: undefined,
  pax: 0,
  geographicalZone: undefined,
}


export const WorkflowActionsQuotesQuoteEnum = {
  DRAFT: "DRAFT",
  WIN: "WIN",
  LOOSE: "LOOSE",
} as const
