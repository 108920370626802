import * as Yup from "yup";
import {yupCorrectEmail, yupRequiredDate, yupRequiredString} from "../yup/Validators";

export const CreateQuoteSchema = Yup.object().shape({
  customerId: Yup.string(),
  pax: Yup.number(),
  emailContact: yupCorrectEmail.when("customerId", {is: (customerId: string) => !customerId, then: yupRequiredString}),
  tourId: Yup.string(),
  customerRef: Yup.string().when("emailContact", {is: (emailContact: string) => !emailContact, then: yupRequiredString}),
  creationDate: yupRequiredDate,
  endOfValidity: yupRequiredDate.when("creationDate", (creationDate: string) => yupRequiredDate.min(creationDate, "quote_after_creation_date")),
  rendezVousDate: yupRequiredDate.when("creationDate", (creationDate: string) => yupRequiredDate.min(creationDate, "quote_after_creation_date")),
}, [["customerId", "emailContact"],
  ["emailContact", "customerRef"]]);
