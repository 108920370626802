import React, {FunctionComponent, useEffect, useState} from "react";
import {orderItemService} from "../../../services/OrderItemService";
import {StaffedResource} from "../../../interfaces/ActivityStaffingInterface";
import {ResourceCalendarRow} from "../../../interfaces/ResourceCalendarInterfaces";
import {ActivityStaffingStatus} from "../../../constants/ActivityStaffingConstants";
import {useIntl} from "react-intl";

interface ResourceCalendarTooltipProps {
  className?: string,
  activity: ResourceCalendarRow,
}

const ResourceCalendarTooltip: FunctionComponent<ResourceCalendarTooltipProps> = ({
  className = "",
  activity
}) => {
  const intl = useIntl()
  const [resourcesStaffed, setResourcesStaffed] = useState<StaffedResource[]>()

  const getResourcesStaffed = () => {
    orderItemService.getOrderItemStaffing(activity.orderItemId)
      .then(response => {
        const staffed = response.filter(resource => resource.status === ActivityStaffingStatus.CONFIRMED)
        setResourcesStaffed(staffed)
      })
      .catch(() => intl.formatMessage({id: "error_toast_calendar"}))
  }

  useEffect(() => {
    getResourcesStaffed()
  }, [])

  return (
    <div className={className}>
      {resourcesStaffed?.length === 0 && (
        <span>{intl.formatMessage({id: "calendar_day_panel_to_staff_tooltip_no_available"})}</span>
      )}
      {resourcesStaffed?.map(resource => <div key={resource.resourceId}>
        <span>{`${resource.firstName} ${resource.lastName.charAt(0)}`}</span>
      </div>)}
    </div>
  )
}

export default ResourceCalendarTooltip;
