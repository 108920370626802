import Title from "components/atoms/Title";
import FileDropZone from "components/atoms/input/FileDropZone";
import Icon from "components/icon/Icon";
import GenericModalDelete from "components/molecules/modal/GenericModalDelete";
import { DocumentDTO } from "interfaces/FileInterfaces";
import { Purchase } from "interfaces/PurchaseInterfaces";
import React, { ChangeEvent, DragEvent, FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { bookingService } from "services/BookingService";
import { IconSizeType } from "types/bootstrap/BootstrapType";
import { fileUtils } from "utils/fileUtils";

interface PurchaseUploadCardProps {
    purchase: Purchase;
    title?: string;
    noDocumentFound?: string;
    uploadFiles: (files: FileList) => void;
    updateFiles: () => void;
    onDelete: (fileId: string) => Promise<Response>
}

const PurchaseUploadCard: FunctionComponent<PurchaseUploadCardProps> = ({
    purchase,
    title,
    noDocumentFound,
    uploadFiles,
    updateFiles,
    onDelete,
}) => {
    const intl = useIntl()
    const [selectedDocument, setSelectedDocument] = useState<DocumentDTO>()
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)

    const downloadDocument = (fileId: string, name: string, extension: string) => {
        const filePromise: Promise<Blob> = bookingService.getDocumentById(fileId);
        fileUtils.downloadFile(filePromise, { name, extension }, intl);
    }

    const onDeleteDocument = (document: DocumentDTO) => {
        setSelectedDocument(document);
        setOpenDeleteModal(true);
    };

    const doAfterDelete = () => {
        setOpenDeleteModal(false);
        updateFiles();
    }

    return (
        <div className="d-flex flex-column mt-4 ms-3">
            <Title title={`${intl.formatMessage({ id: title })} : `} size="h5" />
            <div className="d-flex gap-1 flex-wrap">
                {(!purchase?.purchaseDocuments || purchase?.purchaseDocuments?.length === 0) && <span>{intl.formatMessage({ id: noDocumentFound })}</span>}
            </div>
            {
                purchase?.purchaseDocuments?.map(purchaseDocuments => {
                    const fullName = fileUtils.getCompleteFileName({ name: purchaseDocuments?.name, extension: purchaseDocuments?.extension });
                    return (
                        <div key={purchaseDocuments?.fileId} className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <span
                                    className="epow-text-link cursor-pointer me-3"
                                    onClick={() => downloadDocument(purchaseDocuments?.fileId, purchaseDocuments?.name, purchaseDocuments?.extension)} >
                                    {fullName}
                                </span>
                            </div>
                            <Icon name="Trash" size={IconSizeType.XS} className="cursor-pointer m-0" onClick={() => onDeleteDocument(purchaseDocuments)} />
                        </div>
                    )
                })
            }
            <div className="mt-3">
                <FileDropZone
                    label="documentary_base_add_document"
                    handleInputChange={(e: ChangeEvent<HTMLInputElement>) => uploadFiles(e.target.files)}
                    handleDrop={(e: DragEvent<HTMLDivElement>) => uploadFiles(e.dataTransfer.files)}
                    id="fileDropZone"
                />
            </div>
            <GenericModalDelete
                titleId="modal_title_delete_document"
                open={openDeleteModal}
                onValidate={doAfterDelete}
                setOpen={setOpenDeleteModal}
                idEntity={selectedDocument?.fileId}
                onDelete={onDelete}
                successToastMessageId="success_delete_document"
                errorToastMessageId="error_toast_delete_document"
                bodyDeleteMessage={intl.formatMessage({ id: "modal_body_delete_document" })}
            />
        </div>

    );
};

export default PurchaseUploadCard;
