import React, {FunctionComponent} from "react";
import Layout from "../../components/layout/Layout";
import ActivityBillingOverviewList from "../../components/molecules/list/ActivityBillingOverviewList";

const BillingOverviewView: FunctionComponent = () => {
  return (
    <Layout>
      <div className="d-flex flex-column epow-content-body">
        <ActivityBillingOverviewList />
      </div>
    </Layout>
  )
}

export default BillingOverviewView;
