import React, {FunctionComponent} from "react";
import {dateUtils} from "../../../utils/dateUtils";
import IconLabelCard from "../../atoms/IconLabelCard";

interface BookingItemInformationProps {
  date: Date
  hour: string
  pax: number
}

const BookingItemInformation: FunctionComponent<BookingItemInformationProps> = ({date, hour, pax}) => (
  <div className="d-flex booking-info-date-pax gap-3">
    <IconLabelCard iconName="Calendar" label={`${dateUtils.dateToLocaleDate(new Date(date))} - ${dateUtils.formatLocalTime(hour)}`} hasBackground/>
    <IconLabelCard iconName="User" label={pax?.toString()} hasBackground/>
  </div>
)


export default BookingItemInformation;
