import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {OrderItemStaffingPageItemDto} from "../../../../../interfaces/OrderItemInterfaces";
import ModalButton from "../../../button/ModalButton";
import ModalBulkStaffing from "../../../modal/staffing/ModalBulkStaffing";
import Button from "../../../../atoms/Button";
import {ColorType, SizeType} from "../../../../../types/bootstrap/BootstrapType";
import Panel from "../../../panel/Panel";
import MultipleStaffingForm from "../../../form/staffing/MultipleStaffingForm";
import {ResourceType} from "../../../../../interfaces/ResourceTypeInterfaces";

interface StaffingTableBulkRequestButtonProps {
  className?: string,
  selectedStaffing: string[],
  orderItems: OrderItemStaffingPageItemDto[],
  onStaffing: () => void,
  selectedResourceTypes?: ResourceType[],
}

const StaffingTableBulkRequestButton: FunctionComponent<StaffingTableBulkRequestButtonProps> = ({
  className = "",
  selectedStaffing,
  orderItems,
  onStaffing,
  selectedResourceTypes
}) => {
  const intl = useIntl()

  const [formOpen, setFormOpen] = useState<boolean>(false)

  const openForm = () => {
    setFormOpen(true)
  }

  const onMultipleStaffing = () => {
    setFormOpen(false)
    onStaffing()
  }

  return (
    <div className={className}>
      {selectedStaffing.length > 0 && (
        <div className="d-flex align-items-center">
          <div className="me-2 italic">
            <FormattedMessage id="staffing_selected_number" values={{selectedNumber: selectedStaffing.length}} />
          </div>

          <Button className="me-1" onClick={() => openForm()} size={SizeType.XS} color={ColorType.SECONDARY}>
            <FormattedMessage id="staffing_send_request_multiple" />
          </Button>

          {formOpen && (
            <Panel
              title="staffing_title"
              open={formOpen}
              onCancel={() => setFormOpen(false)}
              displayButton={false}
            >
              <MultipleStaffingForm className="w-100" orderItemIds={selectedStaffing} onStaffing={onMultipleStaffing} setFormOpen={setFormOpen} />
            </Panel>
          )}

          <ModalButton title={intl.formatMessage({id: "staffing_send_request"})} >
            {({modalOpen, setModalOpen}) => (
              <ModalBulkStaffing
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                selectedStaffing={selectedStaffing}
                orderItems={orderItems}
                onStaffing={onStaffing}
                selectedResourceTypes={selectedResourceTypes}
              />
            )}
          </ModalButton>
        </div>
      )}

    </div>
  )
}

export default StaffingTableBulkRequestButton;
