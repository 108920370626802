import React, {FunctionComponent, useEffect, useState} from "react";
import BookingHeaderCard from "../card/booking/BookingHeaderCard";
import {Col, Container, Row} from "reactstrap";
import {getBookingHeaders} from "../../../constants/booking/Booking";
import BookingItemCard from "../card/booking/BookingItemCard";
import {BookingByStatusResponse, BookingItem, BookingSearchObject} from "../../../interfaces/BookingInterfaces";
import {bookingService} from "../../../services/BookingService";
import useSearchFilter from "../../../hooks/useSearchFilter";
import {toastUtils} from "../../../utils/toastUtils";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {supplierService} from "../../../services/SupplierService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {serviceService} from "../../../services/ServiceService";
import DateFilter from "../filters/DateFilter";
import FilterGroup from "../filters/FilterGroup";
import {useIntl} from "react-intl";
import {ApiError} from "../../../interfaces/ErrorInterfaces";
import TextFilter from "../filters/TextFilter";
import ModalItemsToPay from "../modal/ModalItemsToPay";
import {fileUtils} from "../../../utils/fileUtils";

interface BookingPendingListProps {
  defaultPendingBookingToOpenId?: string
}

const BookingPendingList: FunctionComponent<BookingPendingListProps> = ({defaultPendingBookingToOpenId = ""}) => {

  const initialSearchState: BookingSearchObject = {
    supplierIds: [],
    serviceIds: [],
    orderIds: [],
    customerIds: [],
    startDate: "",
    endDate: ""
  }
  const intl = useIntl();
  const {searchObject, updateSearchField, reloadSearch} = useSearchFilter<BookingSearchObject>(initialSearchState)

  const [bookingByStatusResponse, setBookingByStatusResponse] = useState<BookingByStatusResponse>();
  const [payModalOpened, setPayModalOpened] = useState<boolean>(false);

  const getBookingsByStatus = () => {
    bookingService.getBookingsByStatus(searchObject)
      .then(response => {
        setBookingByStatusResponse(response)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  useEffect(() => {
    getBookingsByStatus()
  }, [searchObject]);

  const payBookings = () => {
    bookingService.generateSepaFile(bookingByStatusResponse.paymentBookings?.map(item => item.id))
      .then((file) => {
        toastUtils.successToast(intl.formatMessage({id: "booking_pay_modal_success"}))
        fileUtils.saveFile(file, "bookings_sepa.xml")
        reloadSearch()
        setPayModalOpened(false)
      }).catch((error: ApiError) => {
      if (error && error.message) {
        toastUtils.errorToast(error.message)
      } else {
        toastUtils.errorToast(intl.formatMessage({id: "booking_pay_modal_failure"}))
      }
    })
  }

  const BOOKING_HEADERS = getBookingHeaders(() => setPayModalOpened(true))
  return (
    <div>
      <FilterGroup className="d-flex gap-2 mb-5">

        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "booking_filter_order"})}
          value={searchObject.query}
          onChange={(value) => updateSearchField("query", value)}
          placeholder={intl.formatMessage({id: "booking_filter_order_placeholder"})}
        />

        <AutocompleteMultiselect
          title={intl.formatMessage({id: "booking_filter_supplier"})}
          values={searchObject.supplierIds}
          onChange={(values) => updateSearchField("supplierIds", values)}
          fetchData={(page, filters) => supplierService.getSuppliersPaginated(page, {
            ...filters,
            currentState: WorkflowStatesEnum.ACTIVE
          })}
          filterFieldName="name"
          filterFieldSearch="name"
        />
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "booking_filter_service"})}
          values={searchObject.serviceIds}
          onChange={(values) => updateSearchField("serviceIds", values)}
          fetchData={(page, filters) => serviceService.getServicesPage(page, {
            ...filters,
            currentState: WorkflowStatesEnum.ACTIVE
          })}
          filterFieldName="name"
          filterFieldSearch="name"
        />
        <DateFilter onChangeDate={(value) => updateSearchField("startDate", value)}
                    title={intl.formatMessage({id: "filter_from"})}/>
        <DateFilter onChangeDate={(value) => updateSearchField("endDate", value)}
                    title={intl.formatMessage({id: "filter_to"})}/>
      </FilterGroup>
      {bookingByStatusResponse &&
        <Container className="d-flex flex-column">
          <Row className="flex-nowrap">
            {BOOKING_HEADERS.map(header => {
              const items: BookingItem[] = bookingByStatusResponse[header.id];
              if (items.length > 0 || !header.hideIfEmpty) {
                return (
                  <Col key={header.title}>
                    <BookingHeaderCard
                      title={header.title}
                      barClassName={header.barClassName}
                      iconName={header.iconName}
                      iconClickAction={header.iconClickAction}
                      totalElements={items.length}/>
                  </Col>
                )
              }
            })}
          </Row>
          <Row className="flex-nowrap mt-3">
            {BOOKING_HEADERS.map(header => {
              const items: BookingItem[] = bookingByStatusResponse[header.id];
              if (items.length > 0 || !header.hideIfEmpty) {
                return (
                  <Col key={header.id}>
                    {items.map(item =>
                      <BookingItemCard
                        key={item.id}
                        bookingItem={item}
                        statusBadge={header.statusBadge}
                        warningLabel={header.warningLabel}
                        completionIndicators={header.completionIndicators}
                        getBookingsByStatus={getBookingsByStatus}
                        defaultPendingBookingToOpenId={defaultPendingBookingToOpenId}/>
                    )}
                  </Col>
                )
              }
            }
            )}
          </Row>
          <ModalItemsToPay
            opened={payModalOpened}
            setOpen={setPayModalOpened}
            action={payBookings}
            title={intl.formatMessage({id: "booking_pay_modal_title"})}
            textContent={intl.formatMessage({id: "booking_pay_modal_body"})}
          />
      </Container>
      }
    </div>
  );
}

export default BookingPendingList
