import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {DocumentDTO} from "../../../interfaces/FileInterfaces";
import {fileUtils} from "../../../utils/fileUtils";
import {bookingService} from "../../../services/BookingService";

interface ActivityPanelDocumentsListProps {
  className?: string,
  documents: DocumentDTO[],
}

const ActivityPanelDocumentsList: FunctionComponent<ActivityPanelDocumentsListProps> = ({
  className = "",
  documents,
}) => {
  const intl = useIntl()

  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = bookingService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  return (
    <div className={className}>
      {documents?.map(doc => (
        <div key={doc.fileId} className="d-flex justify-content-between align-items-center mb-2">
          <div>
            <span
              className="epow-text-link cursor-pointer me-3"
              onClick={() => downloadDocument(doc.fileId, doc.name, doc.extension)}>
              {doc.name}
            </span>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ActivityPanelDocumentsList;
