import React, {FunctionComponent} from "react";
import {Row, Col} from "reactstrap";
import Layout from "../../components/layout/Layout";
import {InvoiceHeaderProps} from "../../components/molecules/header/InvoiceHeader";
import {Invoice} from "../../interfaces/InvoiceInterfaces";
import BillingInvoiceAdditionalCharges from "./billing-details/BillingInvoiceAdditionalCharges";
import BillingInvoiceOrderDetails from "./billing-details/BillingInvoiceOrderDetails";
import BillingInvoiceSummaryDetails from "./billing-details/BillingInvoiceSummaryDetails";
import useBilling from "./useBIlling";
import BillingInvoiceFilesDetails from "./billing-details/BillingInvoiceFilesDetails";
import {FileType} from "../../constants/FileConstants";
import {useIntl} from "react-intl";

interface CustomerTemplateBillingViewProps {
  fetchInvoice: () => Promise<Invoice>
  HeaderComponent: FunctionComponent<InvoiceHeaderProps>,
  isCustomer?: boolean,
}

const CustomerTemplateBillingView: FunctionComponent<CustomerTemplateBillingViewProps> = ({
  fetchInvoice: fetchInvoiceProps,
  HeaderComponent,
  isCustomer = true,
}) => {
  const intl = useIntl()
  const {
    invoice,
    onChangeInvoice,
    onOpenUpdateModal,
    onDeleteBillingItem,
    onOpenModal,
    onOpenCustomerEditModal
  } = useBilling({fetchInvoice: fetchInvoiceProps})

  return (
    <Layout> {invoice &&
      <div className="epow-content-body epow-purchase-card d-flex flex-column">
        <HeaderComponent
          onClickAdd={onOpenModal}
          onClickContactEdit={onOpenCustomerEditModal}
          onUpdateStatus={onChangeInvoice}
          invoice={invoice}
          status={invoice.status}
          isCustomer={isCustomer}/>

        <Row>
          <Col xs={24} xl={16}>
            <div>
              {invoice.invoiceOrderDetails?.map((invoiceOrder) => (
                <BillingInvoiceOrderDetails
                  key={invoiceOrder.orderId}
                  invoice={invoice}
                  invoiceOrder={invoiceOrder}
                  onDeleteBillingItem={onDeleteBillingItem}
                  onOpenUpdateModal={onOpenUpdateModal}
                />
              ))}
            </div>
            {invoice.additionnalCharges?.length > 0 && (
              <BillingInvoiceAdditionalCharges
                invoice={invoice}
                onDeleteBillingItem={onDeleteBillingItem}
                onOpenUpdateModal={onOpenUpdateModal}
              />
            )}
            {(invoice.invoiceOrderDetails?.length > 0 || invoice.additionnalCharges?.length > 0) &&
              <BillingInvoiceSummaryDetails invoice={invoice} />
            }
          </Col>

          <Col xs={24} xl={8}>
            <BillingInvoiceFilesDetails className="mb-2" invoice={invoice} fileType={FileType.VOUCHER} title={intl.formatMessage({id: "invoice_voucher"})} />
            <BillingInvoiceFilesDetails invoice={invoice} fileType={FileType.TICKET} title={intl.formatMessage({id: "invoice_tickets"})} />
          </Col>
        </Row>
      </div>
    }
    </Layout>
  );
}

export default CustomerTemplateBillingView;
