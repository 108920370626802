import React, { ChangeEvent, DragEvent, FunctionComponent} from "react";
import {useIntl} from "react-intl";

interface FileDropZoneProps {
  id: string
  className?: string
  label: string
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleDrop: (e: DragEvent<HTMLDivElement>) => void
}
const FileDropZone: FunctionComponent<FileDropZoneProps> = ({
        className = "",
        label,
        handleInputChange,
        handleDrop,
        id,
    }) => {
  const intl = useIntl()

  return (
    <div className={`file_drop_zone ${className}`}>
      <div
        onDrop={(event)=> {
          event.preventDefault()
          handleDrop(event)
        }}
        onDragOver={(event) => event.preventDefault()}
        className="p-3 wrapper"
      >
        <input
          type="file"
          onChange={handleInputChange}
          className="d-none"
          id={id}
          multiple
        />
        <label htmlFor={id}
               className="cursor-pointer text-underline"
        >
          {intl.formatMessage({id: label})}
        </label>

      </div>
    </div>
  );
};

export default FileDropZone;
