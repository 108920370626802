import React, {FunctionComponent, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Input} from "reactstrap";
import useListCheckboxSelector from "../../../../hooks/useListCheckboxSelector";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {SearchFilter} from "../../../../interfaces/api/FiltersInterface";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {CustomTableColumn} from "../../../../interfaces/TableInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import Pagination from "../../pagination/Pagination";
import CustomTable, {CustomTableRef} from "../CustomTable";
import StaffingPendingTableRow from "./StaffingPendingTableRow";
import StaffingPendingTableRowStaffing from "./StaffingPendingTableRowStaffing";
import StaffingTableBulkRequestButton from "./buttons/StaffingTableBulkRequestButton";
import Panel from "../../panel/Panel";
import {staffingUtils} from "../../../../utils/staffingUtils";
import Toggle from "../../../atoms/Toggle";
import SingleStaffingForm from "../../form/staffing/SingleStaffingForm";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";

interface StaffingPendingTableProps {
  className?: string,
  searchObject: SearchFilter,
  selectedResourceTypes?: ResourceType[]
}

const StaffingPendingTable: FunctionComponent<StaffingPendingTableProps> = ({
  className = "",
  searchObject,
  selectedResourceTypes
}) => {
  const ref = React.useRef<CustomTableRef>();

  const {
    data: orderItems,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh
  } = usePaginatedData(orderItemService.getOrderItemsStaffingPage, searchObject, "rendezVousDate")

  const {selected: selectedStaffing, handleSelect, handleSelectAll} = useListCheckboxSelector()

  const intl = useIntl()
  const [staffingFormOpen, setStaffingFormOpen] = useState<boolean>(false)
  const [formOrderItem, setFormOrderItem] = useState<OrderItemStaffingPageItemDto>(null)
  const [maxResource, setMaxResource] = useState<number>(0)

  const openStaffingForm = (orderItem: OrderItemStaffingPageItemDto) => {
    setFormOrderItem(orderItem)
    setStaffingFormOpen(true)
    const maxResources = orderItem.orderItemResources?.reduce((acc, orderItemResource) => {
      return acc + orderItemResource.quantity
    }, 0)

    setMaxResource(maxResources)
  }

  const columns: CustomTableColumn[] = useMemo(() => [
    {
      title: "staffing_order_name", accessor: "name", minWidth: "20.8%", className:"col-5", sortable: true, Cell: (<>
        <Input
          className="me-2"
          type="checkbox"
          checked={selectedStaffing.length > 0}
          onChange={() => handleSelectAll(orderItems.map(item => item.id))}
        />
        <span>{intl.formatMessage({id: "booking_service"})}</span>
      </>)
    },
    {title: "staffing_order", accessor: "customerReference", minWidth: "20.8%", className:"col-5"},
    {title: "staffing_date", accessor: "rendezVousDate", sortable: true, className:"col-3"},
    {title: "staffing_staffed", accessor: "numberOfResources" ,sortable: false, className:"col-5"},
    {title: "table_actions", accessor: "", sortable: false, minWidth: "33.5%", className:"col-6"},
  ], [orderItems, selectedStaffing])

  return (
    <div className={`d-flex flex-column ${className}`}>
       <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
        <div className="d-flex align-items-center bold">
          <FormattedMessage id="table_see_all_details" />
          <Toggle className="ms-2" id="staffing_pending_all_details" onChange={(checked) => ref.current?.toggleAllRows(checked)} />
        </div>
      </div>

      <CustomTable
        ref={ref}
        onSortOptions={setSortOptions}
        sortOptions={sortOptions}
        className={`epow-staffing-pending-table ${className}`}
        type="staffing"
        datas={orderItems}
        columns={columns}
        RowComponent={({data, open, ...rest}) => {
          const orderItem = data as OrderItemStaffingPageItemDto
          return (
            <>
              <StaffingPendingTableRow
                orderItem={orderItem}
                open={open}
                handleSelect={handleSelect}
                selectedStaffing={selectedStaffing}
                openStaffingForm={openStaffingForm}
                {...rest}
              />
              {(open && orderItem?.resourceStaffing?.length > 0) && (
                <div className="epow-staffing-pending-table-row-details">
                  {staffingUtils.getSortedResources(orderItem)?.map((resource, index) => (
                    <StaffingPendingTableRowStaffing
                      key={index}
                      resource={resource}
                      onStaffingChange={refresh}
                    />
                  ))}
                </div>
              )}
            </>);
        }
        } />

      {staffingFormOpen && (
        <Panel
          title="staffing_title"
          open={staffingFormOpen}
          onCancel={() => setStaffingFormOpen(false)}
          displayButton={false}
        >
          <SingleStaffingForm className="w-100" hasStaffing={formOrderItem.resourceStaffing?.length > 0} maxResources={maxResource} orderItemId={formOrderItem.id} setFormOpen={setStaffingFormOpen} onStaffing={refresh} />
        </Panel>
      )}

      <StaffingTableBulkRequestButton onStaffing={refresh} className="ms-auto" selectedStaffing={selectedStaffing} orderItems={orderItems} selectedResourceTypes={selectedResourceTypes}/>
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default StaffingPendingTable;
