import * as Yup from "yup";
import {Option} from "../../interfaces/inputs/OptionInterfaces";
import {yupPositiveNumber, yupRequiredDate, yupRequiredString} from "../yup/Validators";

export const CreateOrderSchema = Yup.object().shape({
  customerId: yupRequiredString,
  dayZeroTour: yupRequiredString,
  date: yupRequiredString,
  tourId: Yup.string(),
  tourLeaderId: Yup.string(),
  defaultPax: yupPositiveNumber,
  customerReference: Yup.string().typeError("already_exists_customer_ref"),
});

export const ImportOrderSchema = (customerOptions: Option<string>[], tourOptionsForCustomer: Map<string, Option<string>[]>): Yup.AnyObjectSchema => Yup.object().shape({
  client: yupRequiredString.test(
    "clientExists",
    "order_client_does_not_exist",
    (value: string) => customerOptions.map(option => option.value).includes(value)
  ),
  tour: yupRequiredString.test(
    "tourExists",
    "order_tour_does_not_exist",
    (value: string) => {
      const optionsForClient: Option<string>[] = Object.values(tourOptionsForCustomer).flat()
      return optionsForClient.map(option => option.value).includes(value)
    }
  ).test(
    "tourExistsForClient",
    "order_tour_does_not_exist_with_client",
    (value: string, context: Yup.TestContext) => {
      const client = context.resolve<string>(Yup.ref("client"))
      const optionsForClient: Option<string>[] = tourOptionsForCustomer[client];

      return optionsForClient?.map(option => option.value).includes(value)
    }
  ),
  referenceClient: yupRequiredString,
  date: yupRequiredDate,
  dayZeroTour: yupRequiredDate
  /*
  dayZeroTour: yupRequiredDate.test(
    "dateZeroAfterToday",
    "order_day_zero_before_today",
    (value: Date) => {
      const today = new Date()
      return value >= today
    }
  ).test(
    "dateZeroAfterOrderDate",
    "order_day_zero_before_order_date",
    (value: Date, context: Yup.TestContext) => {
      const orderDate = context.resolve<Date>(Yup.ref("date"))
      return value >= orderDate
    }
  ),
  */

});
