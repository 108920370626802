import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Resource} from "../../../../interfaces/ResourceInterfaces";
import {ResourceForStaffingDto} from "../../../../interfaces/ResourceStaffingInterfaces";
import StaffingSelectorResource from "../../card/staffing/StaffingSelectorResource";
import StaffingFormResourceSelectorFilters from "../../filters/staffing/StaffingFormResourceSelectorFilters";
import StaffingFormBasicResourceSelectorFilters from "./StaffingFormBasicResourceSelectorFilters";

interface StaffingFormResourceSelectorProps {
  className?: string,
  orderItemId?: string,
  maxResources?: number,
  isDirectStaffing: boolean,
  selectedResources: ResourceForStaffingDto[],
  onIsDirectStaffing?: (isDirectStaffing: boolean) => void,
  onSelect: (resource: Resource) => void,
  selectAllResources?: (event: React.ChangeEvent<HTMLInputElement>, resources: ResourceForStaffingDto[]) => void,
  hasStaffing?: boolean,
  hasFilters?: boolean,
  hasDirectStaffing?: boolean,
  initialResourceTypes?: string[]
}

const StaffingFormResourceSelector: FunctionComponent<StaffingFormResourceSelectorProps> = ({
  className = "",
  orderItemId = "",
  maxResources,
  isDirectStaffing,
  selectedResources,
  onIsDirectStaffing = () => null,
  onSelect,
  selectAllResources,
  hasStaffing = false,
  hasFilters = true,
  hasDirectStaffing = true,
  initialResourceTypes
}) => {
  const [resources, setResources] = useState<ResourceForStaffingDto[]>([])
  const usedResources = isDirectStaffing ? resources.filter(r => !selectedResources?.some(sr => sr?.id === r?.id)) : resources

  const onDirectSelect = (resource: Resource) => {
    onIsDirectStaffing(true)
    onSelect(resource)
  }

  return (
    <div className={`flex-shrink d-flex flex-column w-100 ${className ?? ""}`}>

      {hasFilters ? (
        <StaffingFormResourceSelectorFilters onSearchChange={setResources} orderItemId={orderItemId} initialResourceTypes={initialResourceTypes}  />
      ) : (
        <StaffingFormBasicResourceSelectorFilters onSearchChange={setResources} />
      )}

      <div className="d-flex align-items-center">
        {(selectAllResources && !isDirectStaffing) && (
          <input className="me-2" type="checkbox" onChange={(e) => selectAllResources(e, resources)} />
        )}

        <div className="bold">
          <FormattedMessage id={"staffing_resource_name"} />
        </div>

        <div className="bold ms-auto">
          <FormattedMessage id={"table_actions"} />
        </div>
      </div>

      <hr className="my-2" />

      <div className="overflow-auto">
        {usedResources?.length === 0 && (
          <FormattedMessage id={hasStaffing ? "staffing_ongoing_staffing" : "staffing_no_results"} />
        )}

        {usedResources.map(resource => (
          <StaffingSelectorResource
            key={resource.id}
            maxResources={maxResources}
            isDirectStaffing={isDirectStaffing}
            resource={resource}
            onDirectSelect={onDirectSelect}
            onSelect={onSelect}
            selectedResources={selectedResources}
            hasDirectStaffing={hasDirectStaffing}
          />
        ))}
      </div>
    </div>
  )
}

export default StaffingFormResourceSelector;
