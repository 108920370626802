import {TourLeader} from "../../../../interfaces/TourLeaderInterfaces";
import React, {FunctionComponent, useState} from "react";
import {Col} from "reactstrap";
import Title from "../../../atoms/Title";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import TourLeaderCard from "./TourLeaderCard";
import EditDetailsTourLeaderForm from "../../form/EditDetailsTourLeaderForm";

interface GeneralInfoTourLeaderCardProps {
  className?: string,
  tourLeader?: TourLeader,
  setTourLeader?: (tourLeader?: TourLeader) => void,
  title: string
}

const GeneralInfoTourLeaderCard: FunctionComponent<GeneralInfoTourLeaderCardProps> = ({
  className = "",
  tourLeader,
  setTourLeader,
  title
}) => {
  const [editInfo, setEditInfo] = useState<boolean>(false)

  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size="h5" className="mb-3"/>
      {editInfo ? (
        <>
          <EditDetailsTourLeaderForm id="editTourLeaderForm" originalTourLeader={tourLeader} setEditInfo={setEditInfo} setTourLeader={setTourLeader}/>
          <div>
            <Button form={"editTourLeaderForm"} type="submit" size={SizeType.MD} className="extended me-2">
              <FormattedMessage id="save_button"/>
            </Button>
            <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(false)} className="extended">
              <FormattedMessage id="cancel_button"/>
            </Button>
          </div>
        </>
      ) : (
        <TourLeaderCard tourLeader={tourLeader} editInfo={editInfo} setEditInfo={setEditInfo}/>
      )}
    </Col>
  )
}

export default GeneralInfoTourLeaderCard