import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {STATUS_NOT_FOUND} from "../../../../constants/HttpStatusConstants";
import {BankAccount} from "../../../../interfaces/BankAccountInterface";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../utils/toastUtils";
import Button from "../../../atoms/Button";
import CreateBankAccountForm from "../../form/CreateBankAccountForm";
import ResourceBankAccountList from "../../list/ResourceBankAccountList";
import Panel from "../../panel/Panel";

interface ResourceBankAccountContainerProps {
  className?: string;
  resourceId?: string;
  fetchBankAccounts?: () => Promise<BankAccount[]>;
}

const ResourceBankAccountContainer: FunctionComponent<ResourceBankAccountContainerProps> = ({
  className = "",
  resourceId,
  fetchBankAccounts
}) => {
  const intl = useIntl();
  const [resourceBankAccounts, setResourceBankAccounts] = useState<
    BankAccount[]
  >([]);
  const [lateralPanelResourceBankAccount, setLateralPanelResourceBankAccount] =
    useState<boolean>(false);

  const getResourceBankAccounts = () => {
   fetchBankAccounts()
      .then((bankAccounts: BankAccount[]) => {
        setResourceBankAccounts(bankAccounts);
      })
      .catch((error: Response) => {
        if (error.status !== STATUS_NOT_FOUND) {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_get_bank_accounts" })
          );
        }
      });
  };

  const onCreate = (bankAccount: BankAccount) => {
    setLateralPanelResourceBankAccount(false);

    const bankAccountsUpdated = [...resourceBankAccounts, bankAccount];
    setResourceBankAccounts(bankAccountsUpdated);
  };

  const onUpdate = (bankAccount: BankAccount) => {
    setLateralPanelResourceBankAccount(false);

    const bankAccountsUpdated = resourceBankAccounts.map(
      (supplierBankAccount) =>
        supplierBankAccount.id === bankAccount.id
          ? bankAccount
          : supplierBankAccount
    );

    setResourceBankAccounts(bankAccountsUpdated);
  };

  const onDelete = (bankAccountId: string) => {
    const bankAccountsUpdated = resourceBankAccounts.filter(
      (bankAccount) => bankAccount.id != bankAccountId
    );

    setResourceBankAccounts(bankAccountsUpdated);
  };

  const onUpdateDefaultBankAccount = (bankAccountId: string) => {
    const bankAccountsWithNewDefault = resourceBankAccounts.map(
      (bankAccount) => ({
        ...bankAccount,
        isDefault: bankAccount.id === bankAccountId,
      })
    );

    bankAccountsWithNewDefault.sort(
      (a, b) => Number(b.isDefault) - Number(a.isDefault)
    );

    setResourceBankAccounts(bankAccountsWithNewDefault);
  };

  useEffect(() => {
    getResourceBankAccounts();
  }, []);

  return (
    <>
      <ResourceBankAccountList
        className={`${className} mb-3`}
        resourceBankAccounts={resourceBankAccounts}
        resourceId={resourceId}
        onUpdate={onUpdate}
        onDelete={onDelete}
        onUpdateDefaultBankAccount={onUpdateDefaultBankAccount}
      />
      <Button
        color={ColorType.SECONDARY}
        onClick={() => setLateralPanelResourceBankAccount(true)}
        className="extended"
      >
        <FormattedMessage id="add_bank_account" />
      </Button>
      {lateralPanelResourceBankAccount && (
        <Panel
          formId="createBankAccountForm"
          title="add_bank_account"
          open={lateralPanelResourceBankAccount}
          onCancel={() => setLateralPanelResourceBankAccount(false)}
        >
          <CreateBankAccountForm
            id="createBankAccountForm"
            resourceId={resourceId}
            onCreate={onCreate}
          />
        </Panel>
      )}
    </>
  )
}

export default ResourceBankAccountContainer;
