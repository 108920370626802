import React, {FunctionComponent} from "react";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";

interface DocumentTableRowThemeProps {
  theme: string,
  open: boolean,
  onRowClick?: (theme: string) => void,
}

const DocumentTableRowTheme: FunctionComponent<DocumentTableRowThemeProps> = ({
  theme,
  open,
  onRowClick,
}) => {
  const intl = useIntl()
  return (
    <div onClick={() => onRowClick(theme)} className={"epow-custom-table-row-main"}>
      {open ? (
        <Icon className="m-0 me-2 cursor-pointer" size={IconSizeType.XS} name="ChevronUp" />
      ) : (
        <Icon className="m-0 me-2 cursor-pointer" size={IconSizeType.XS} name="ChevronDown" />
      )}
      <div className="text-nowrap">
        {intl.formatMessage({id: `FILE_THEME_${theme !== "null" ? theme : "NONE"}`})}
      </div>
      <hr className="theme-line-decorator" />
    </div>
  )
}

export default DocumentTableRowTheme;
