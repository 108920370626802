import * as Yup from "yup";
import {
  yupRequiredBoolean,
  yupRequiredNumber,
} from "constants/yup/Validators";

export const CreateSupplierSettingsSchema = Yup.object().shape({
  preBookingDelay: yupRequiredNumber,
  paymentDelay: yupRequiredNumber,
  hasVoucher: yupRequiredBoolean,
});
