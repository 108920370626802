import React, {FunctionComponent} from "react";
import Circle from "../../../../atoms/Circle";
import {FormattedMessage} from "react-intl";
import {CircleColor} from "../../../../../constants/CircleConstants";
import CellHeaderAvailability from "./CellHeaderAvailability";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";

interface CellCalendarProps {
    availabilityType: string
}

const CellUnavailableCalendar: FunctionComponent<CellCalendarProps> = ({availabilityType}) => {
    return (
            <div className="staffing-calendar__cell lg epow-bg-red">
                <CellHeaderAvailability availabilityType={availabilityType} arrowColor={ColorType.GRAY} />
                <p className="d-flex flex-row align-items-center">
                    <Circle color={CircleColor.RED} className="me-2"/>
                    <FormattedMessage id="staffing_not_available"/>
                </p>
            </div>);
}

export default CellUnavailableCalendar;
