import React, { FunctionComponent, useState } from "react";
import Title from "../../../atoms/Title";
import Button from "../../../atoms/Button";
import { ColorType, SizeType } from "../../../../types/bootstrap/BootstrapType";
import { FormattedMessage, useIntl } from "react-intl";
import { Col } from "reactstrap";
import { Contact, Identity } from "../../../../interfaces/ContactInterfaces";
import ContactCard from "../ContactCard";
import { contactService } from "../../../../services/ContactService";
import { Supplier } from "interfaces/SupplierInterfaces";
import Panel from "../../panel/Panel";
import {toastUtils} from "../../../../utils/toastUtils";
import {supplierContactService} from "../../../../services/SupplierContactService";
import {getBoolean} from "../../../../utils/booleanUtils";
import ContactForm from "../../form/contact/ContactForm";

interface MainSupplierContactCardProps {
  className?: string;
  title: string;
  supplier?: Supplier;
  setSupplier?: (supplier: Supplier) => void;
}

const MainSupplierContactCard: FunctionComponent<
  MainSupplierContactCardProps
> = ({ className = "", title, supplier, setSupplier = () => null }) => {
  const intl = useIntl()
  const [
    lateralPanelCreateSupplierContact,
    setLateralPanelCreateSupplierContact,
  ] = useState<boolean>(false);

  const mainContact: Contact = supplier?.mainContact;
  const identity: Identity = mainContact?.identity;

  const handleCreateContact = (mainContact: Contact) => {
    setLateralPanelCreateSupplierContact(false);
    setSupplier({ ...supplier, mainContact });
  };

  const displayMainContactCard = () => (
    <ContactCard
      buttonCallback={() => supplierContactService.inviteContactSupplier(supplier.id).then((mainContact) => {
        setSupplier({...supplier, mainContact: mainContact})
      }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_inviting_contact"})))}
      firstName={identity?.firstName}
      lastName={identity?.lastName}
      hasAccount={getBoolean(supplier?.mainContact?.hasAccessToPlatform) || supplier.mainContact.isUserActive}
      lineContact={contactService.buildLineContact(identity)}
    />
  );

  const displayButtonToCreateMainContact = () => (
    <div className="d-flex justify-content-center">
      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        onClick={() => setLateralPanelCreateSupplierContact(true)}
        className="border border-dark w-50"
      >
        <FormattedMessage id="create_contact_button" />
      </Button>
    </div>
  );

  return (
    <>
      <Col className={`d-flex flex-column ${className}`}>
        <Title title={title} size={"h5"} className="mb-3"/>

        {supplier?.mainContact
          ? displayMainContactCard()
          : displayButtonToCreateMainContact()}
      </Col>
      <div
        className={`${
          lateralPanelCreateSupplierContact ? "backdrop" : "d-none"
        }`}
      ></div>
      {lateralPanelCreateSupplierContact && (
        <Panel
          formId="createSupplierContactForm"
          title="add_contact_to_supplier"
          open={lateralPanelCreateSupplierContact}
          onCancel={() => setLateralPanelCreateSupplierContact(false)}
        >
          <ContactForm
            id="createSupplierContactForm"
            entity={supplier}
            isMainContact={true}
            onValidate={handleCreateContact}
            createContactRequest={supplierContactService.createSupplierContact}
          />
        </Panel>
      )}
    </>
  );
};

export default MainSupplierContactCard;
