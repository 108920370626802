import React, {FunctionComponent, useState} from "react";
import {Dropdown as ReactstrapDropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import {ColorTypeValues} from "../../../types/bootstrap/BootstrapType";
import {DropdownOption} from "../../../interfaces/inputs/OptionInterfaces";

interface DropdownProps {
  id: string,
  className?: string,
  color?: ColorTypeValues,
  options: Array<DropdownOption>,
  onToggle?: (toggle: boolean) => void,
  isOpen?: boolean,
  isToggle?: boolean,
  children?: React.ReactElement
}

const Dropdown: FunctionComponent<DropdownProps> = ({
  id,
  className = "",
  color = "",
  options,
  onToggle = () => false,
  isOpen = false,
  isToggle = true,
  children
}) => {
  const [toggle, setToggle] = useState<boolean>(isOpen)
  const onClick = () => {
    setToggle(!toggle);
    onToggle(toggle)
  }

  return options?.length > 0 && (
    <ReactstrapDropdown className={`epow-dropdown ${className}`} isOpen={toggle} toggle={onClick}>
      <DropdownToggle color={color} id={id} caret={isToggle}>
        {children}
      </DropdownToggle>
      <DropdownMenu>
        {options.map(option =>
          <DropdownItem
            key={option?.id}
            {...option}
          >
            {option.label}
          </DropdownItem>
        )}
      </DropdownMenu>
    </ReactstrapDropdown>
  )
}
export default Dropdown;
