import React, {FunctionComponent, useMemo} from "react";
import {SERVICE_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import {serviceService} from "services/ServiceService";
import Button from "../../atoms/Button";
import {ColorType, SizeType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import PagedTable from "../table/PagedTable";
import TextFilter from "../filters/TextFilter";
import FilterGroup from "../filters/FilterGroup";
import {ServiceSearchObject} from "../../../interfaces/ServiceInterfaces";
import {supplierService} from "../../../services/SupplierService";
import {activityService} from "../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";

interface ServiceListProps {
  className?: string,
  searchObject?: ServiceSearchObject,
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const ServiceList: FunctionComponent<ServiceListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl()
  const columns = useMemo(() => tableUtils.getColumns(SERVICE_TABLE(intl)), [intl])

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          icon="Search"
          className="main-filter"
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          title={intl.formatMessage({id: "service_placeholder"})}
          placeholder={intl.formatMessage({id: "service_table_search_placeholder"})}
        />

        <AutocompleteFilterPageable
          id="supplierId"
          title={intl.formatMessage({id: "suppliers_for_service"})}
          placeholder={intl.formatMessage({id: "supplier_placeholder"})}
          onChange={(value) => updateSearchField("supplierId", value)}
          value={searchObject?.supplierId}
          fetchData={(page, filter) => supplierService.getSuppliersPaginated(page, {...filter, state: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />

        <AutocompleteFilterPageable
          id="activityId"
          title={intl.formatMessage({id: "dropdown_header_activity"})}
          placeholder={intl.formatMessage({id: "activity_placeholder"})}
          onChange={(value) => updateSearchField("activityId", value)}
          value={searchObject?.activityId}
          fetchData={(page, filters) => activityService.getActivitiesPage(page, {...filters, status: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />

        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} size={SizeType.MD} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PagedTable
        defaultSortBy={[{sortBy: "id"}]}
        columns={columns}
        labelNoResult="service_no_result"
        className={`${className} table`}
        fetchData={serviceService.getServicesPage}
        filters={searchObject}
      />
    </>
  )
}

export default ServiceList
