import React, {FunctionComponent, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Compressor from "compressorjs";
import {PictureType, PictureTypeProperties} from "../../../constants/FileConstants";
import {fileService} from "../../../services/FileService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../utils/toastUtils";
import ModalButton from "../button/ModalButton";
import ModalDeletePicture from "../modal/ModalDeletePicture";
import Cropper from "./Cropper";


interface ImageUploaderProps {
  className?: string;
  aspect?: number;
  entityId: string;
  fileType: PictureType;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = ({className, entityId, fileType, aspect}) => {
  const intl = useIntl()
  const [image, setImage] = React.useState<string>();
  const [error, setError] = React.useState<string>();

  useEffect(() => {
    if (entityId != undefined) {
      fileService.getPictureById(fileType, entityId).then((image) => {
        setImage(URL.createObjectURL(image))
      }).catch(() => {
        // it's fine if there's no image
      })
    }
  }, [entityId]);

  const uploadFile = (blob) => {
    const formData = new FormData();
    const request = {entityId}

    formData.set("request", new Blob([JSON.stringify(request)], {type: "application/json"}))
    formData.set("picture", blob)
    fileService.uploadPicture(fileType, formData)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "picture_creation_success"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_profile_picture"}))
      })
  }

  const onFileChange = (file: File) => {
    setError(null);
    new Compressor(file, {
      quality: 0.9,
      maxWidth: 700,
      success: (result) => {
        // UPLOAD
        // 1 MB max
        if (result.size > 1048576) {
          setError("file_too_big");
        } else {
          setImage(URL.createObjectURL(result))
          uploadFile(result)
        }
      },
      error: () => {
        toastUtils.errorToast("error_uploading_image")
      }
    })
  }

  const onDeleteFile = () => {
    fileService.deletePictureById(fileType, entityId)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "picture_deletion_success"}))
        setImage(null)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_profile_picture"}))
      })
  }

  return (
    <div className={className}>
      {image &&
        <div className="d-flex flex-column mb-2">
          <img
            src={image}
            width={PictureTypeProperties[fileType].pictureWidth}
            height={PictureTypeProperties[fileType].pictureHeight}
            alt="image"
            loading="lazy"
          />
        </div>
      }

      <div className="d-flex gap-1">
        <Cropper setImageFile={onFileChange} title={intl.formatMessage({id: "edit_button"})} isButton aspect={aspect}/>
        {error && <div><FormattedMessage id={error} /></div>}

        {image && (
          <ModalButton color={ColorType.SECONDARY} title={intl.formatMessage({id: "delete_button"})} >
            {({modalOpen, setModalOpen}) => (
              <ModalDeletePicture
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onDeleteFile={onDeleteFile}
                deleteMessage={PictureTypeProperties[fileType].deleteMessage}
              />
            )}
          </ModalButton>
        )}
      </div>

      <span className="fs-10 italic">
        {intl.formatMessage({id: PictureTypeProperties[fileType].infoMessage})}
      </span>
    </div>
  );
}


export default ImageUploader;
