import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {DocumentSearchObject} from "../../../interfaces/FileInterfaces";
import useSearchFilter from "../../../hooks/useSearchFilter";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import {activityService} from "../../../services/ActivityService";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Button from "../../atoms/Button";
import DocumentTable from "../table/document/DocumentTable";
import DocumentTableRowExternal from "../table/document/DocumentTableRowExternal";

interface ExternalProfileDocumentListProps {
  className?: string,
}

const ExternalProfileDocumentList: FunctionComponent<ExternalProfileDocumentListProps> = ({
  className = "",
}) => {
  const intl = useIntl();

  const initialSearchObject: DocumentSearchObject = {name: ""}
  const {searchObject, onReset, updateSearchField} = useSearchFilter<DocumentSearchObject>(initialSearchObject)

  return (
    <div className={`w-100 ${className ?? ""}`}>
      <FilterGroup className="d-flex row">
        <TextFilter
          className="main-filter col-4"
          title={intl.formatMessage({id: "documentary_base_filter_name"})}
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          placeholder={intl.formatMessage({id: "documentary_base_filter_name_placeholder"})}
        />

        <AutocompleteFilterPageable
          className="col-6"
          onChange={(value) => updateSearchField("activityId", value)}
          title={intl.formatMessage({id: "documentary_base_filter_activity"})}
          value={searchObject?.activityId}
          fetchData={activityService.getActivitiesPage}
          filterFieldName="name"
        />

        <Button className="w-auto" onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
      </FilterGroup>

      <DocumentTable
        searchObject={searchObject}
        DocumentRowComponent={({document}) =>
          <DocumentTableRowExternal document={document} />
        }
      />

    </div>
  )
}

export default ExternalProfileDocumentList;
