import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {EditResourceJobInfoSchema} from "../../../../constants/validation/ResourceValidationSchemas";
import {
  ResourceJobInfo,
  ResourceJobInfoFields, ResourceJobInfoRequest,
  ResourceJobInfoResponse,
} from "../../../../interfaces/ResourceInterfaces";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import {useIntl} from "react-intl";
import {toastUtils} from "../../../../utils/toastUtils";
import FormMultiSelect from "../../input/FormMultiSelect";
import {resourceUtils} from "../../../../utils/resourceUtils";
import {resourceService} from "../../../../services/ResourceService";
import EpowForm from "../EpowForm";
import {PUBLIC_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import FormRadio from "../../input/FormRadio";
import FormAutocompleteMultiselect from "../../input/FormAutocompleteMultiSelect";
import {languageService} from "../../../../services/LanguageService";

interface EditResourceJobInfoFormProps {
  id?: string;
  className?: string;
  initialResourceJobInfo?: ResourceJobInfo;
  setResourceAfterUpdate?: (resourceJobInfo: ResourceJobInfoResponse) => void;
  setEditInfo?: (editInfo: boolean) => void;
  self?: boolean;
}

const EditResourceJobInfoForm: FunctionComponent<EditResourceJobInfoFormProps> = ({
  className = "",
  id,
  initialResourceJobInfo,
  setResourceAfterUpdate = () => null,
  setEditInfo = () => null,
  self
}) => {
  const intl = useIntl();

  const initialResourceJobInfoFields: ResourceJobInfoFields = resourceUtils.buildResourceJobInfoFields(initialResourceJobInfo);
  const resourceFieldsToRequest = (fields: ResourceJobInfoFields): ResourceJobInfoRequest => {
    return {
      ...fields,
      activityAreaIds: fields.activityAreas.map(area => area.value),
      languageIds: fields.languages.map(language => language.value),
    }
  }

  const handleSubmitResource = (submittedResourceJobInfo: ResourceJobInfoFields) => {
    const request = resourceFieldsToRequest(submittedResourceJobInfo);
    const promise = self ?
    () => resourceService.updateSelfResourceJobInfo(request)
    : () => resourceService.updateResourceJobInfo(initialResourceJobInfo.id, request)

    promise()
      .then((resourceJobInfo: ResourceJobInfoResponse) => {
        setResourceAfterUpdate(resourceJobInfo);
        setEditInfo(false);
        toastUtils.successToast(intl.formatMessage({ id: "success_update_resource" }));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({ id: "error_update_resource" }));
      });
  };

  const publicTypeOptions = PUBLIC_TYPE_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))

  return (
    <>
      <Formik
        initialValues={initialResourceJobInfoFields}
        validationSchema={EditResourceJobInfoSchema}
        onSubmit={handleSubmitResource}
      >
        {({errors, touched, values, setFieldValue}) => (
          <EpowForm id={id} className={className}>
            <FormRadio
              id="isNocturnal"
              label="resource_is_nocturnal"
              value={YES_NO_OPTIONS.find((option) => option?.value === values?.isNocturnal?.toString())}
              options={YES_NO_OPTIONS}
              onChange={(option?: Option<string>) => setFieldValue("isNocturnal", option?.value)}
              required
              error={errors.isNocturnal}
              touched={touched.isNocturnal}
            />
            <FormMultiSelect
              id="publicTypes"
              label="resource_public_type"
              values={publicTypeOptions?.filter((option) => values?.publicTypes?.map(e => e.toString()).includes(option.value))}
              options={publicTypeOptions}
              onChange={(option: Option<string>[]) => setFieldValue("publicTypes", option.map((x) => x.value))}
              required
              isSearchable
              isClearable
              error={errors.publicTypes}
              touched={touched.publicTypes}
            />
            <FormAutocompleteMultiselect
              id="activityAreas"
              label={intl.formatMessage({id: "activity_zone"})}
              values={values.activityAreas}
              onChange={(selectedOptions) => setFieldValue("activityAreas", selectedOptions)}
              required
              error={errors?.activityAreas}
              touched={touched?.activityAreas}
              fetchData={(inputValue) => activityAreaService.getActivityAreasPage({},{label: inputValue})}
              filterFieldName="label"
            />
            <FormAutocompleteMultiselect
              id="languages"
              label={intl.formatMessage({id: "spoken_languages"})}
              values={values.languages}
              onChange={(selectedOptions) => setFieldValue("languages", selectedOptions)}
              required
              error={errors?.languages}
              touched={touched?.languages}
              fetchData={(inputValue) => languageService.getLanguagesPage({},{label: inputValue})}
              filterFieldName="label"
            />
          </EpowForm>
        )}
      </Formik>
    </>
  );
};

export default EditResourceJobInfoForm;
