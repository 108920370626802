import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import {toastUtils} from "../../../../utils/toastUtils";
import StaffingForm from "./StaffingForm";
import {ResourceForStaffingDto, ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";

interface SingleStaffingFormProps {
  className?: string,
  orderItemId: string,
  maxResources?: number,
  setFormOpen: (open: boolean) => void,
  onStaffing?: () => void,
  hasStaffing?: boolean,
  initialResourceTypes?: string[];
}

const SingleStaffingForm: FunctionComponent<SingleStaffingFormProps> = ({
  className = "",
  orderItemId,
  maxResources = 10,
  setFormOpen,
  onStaffing = () => null,
  hasStaffing = false,
  initialResourceTypes
}) => {
  const intl = useIntl()

  const sendRequest = (isDirectStaffing: boolean, selectedResources: ResourceForStaffingDto[], resourceRequest: ResourceStaffingRequest[]) => {
    let staffingRequest: Promise<OrderItemStaffingPageItemDto>
    if (isDirectStaffing) {
      const resourcesIds = selectedResources.map(resource => resource.id)
      staffingRequest = orderItemService.sendDirectStaffingForOrderItem(orderItemId, resourcesIds)
    } else {
      staffingRequest = orderItemService.sendStaffingRequestForOrderItem(orderItemId, resourceRequest)
    }

    staffingRequest
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_request"}))
        setFormOpen(false)
        onStaffing()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      })
  }

  return (
    <StaffingForm
      className={className}
      orderItemId={orderItemId}
      maxResources={maxResources}
      setFormOpen={setFormOpen}
      sendRequest={sendRequest}
      hasStaffing={hasStaffing}
      initialResourceTypes={initialResourceTypes}
    />
  )
}

export default SingleStaffingForm;
