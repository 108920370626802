import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import StaffingFormResourceSelector from "./StaffingFormResourceSelector";
import {ResourceForStaffingDto} from "../../../../interfaces/ResourceStaffingInterfaces";
import {getProfilePicture} from "../../../../constants/routes/RoutePaths";
import Avatar from "../../images/Avatar";

interface StaffingFormStepOneProps {
  className?: string,
  orderItemId?: string,
  maxResources: number,
  setIsDirectStaffing: (isDirectStaffing: boolean) => void,
  isDirectStaffing: boolean,
  setSelectedResources: (resources: ResourceForStaffingDto[]) => void,
  selectedResources: ResourceForStaffingDto[],
  hasStaffing: boolean,
  hasFilters?: boolean,
  hasDirectStaffing?: boolean,
  initialResourceTypes?: string[];
}

const StaffingFormStepOne: FunctionComponent<StaffingFormStepOneProps> = ({
  className = "",
  orderItemId = "",
  maxResources,
  isDirectStaffing,
  setIsDirectStaffing,
  setSelectedResources,
  selectedResources,
  hasStaffing = false,
  hasFilters = true,
  hasDirectStaffing = true,
  initialResourceTypes
}) => {

  const deselectDirectStaffing = (resource: ResourceForStaffingDto) => {
    if (selectedResources.length === 1) {
      setIsDirectStaffing(false)
    }

    const selectedResourceIndex = selectedResources.findIndex(r => r.id === resource.id);
    const newList = [...selectedResources]
    newList.splice(selectedResourceIndex, 1)
    setSelectedResources(newList)
  }

  const onSelect = (resource: ResourceForStaffingDto) => {
    const selectedResourceIndex = selectedResources.findIndex(r => r.id === resource.id);

    if (selectedResourceIndex !== -1) {
      const newList = [...selectedResources]
      newList.splice(selectedResourceIndex, 1)

      setSelectedResources(newList)
    } else {
      setSelectedResources([...selectedResources, resource])
    }
  }

  const selectAllResources = (event: React.ChangeEvent<HTMLInputElement>, resources: ResourceForStaffingDto[]) => {
    if (event.target.checked) {
      setSelectedResources(resources)
    } else {
      setSelectedResources([])
    }
  }

  return (
    <div className={className}>

      {isDirectStaffing && (
        <div className="mb-3">
          <div className="bold mb-2">
            <FormattedMessage id={"staffing_request_direct"} />
          </div>
          {selectedResources.map(resource => (
            <div key={resource.id} className="d-flex align-items-center mb-2">

              <Avatar url={getProfilePicture(resource.id)} className="m-0 me-2"/>
              <div className="me-2">
                 {`${resource?.lastName} ${resource?.firstName}`}
              </div>

              <Icon name="Trash" size={IconSizeType.XS} className="m-0 ms-auto cursor-pointer" onClick={() => deselectDirectStaffing(resource)} />
            </div>
          ))}
        </div>
      )}

      <StaffingFormResourceSelector
        orderItemId={orderItemId}
        maxResources={maxResources}
        selectedResources={selectedResources}
        isDirectStaffing={isDirectStaffing}
        onSelect={onSelect}
        selectAllResources={selectAllResources}
        onIsDirectStaffing={setIsDirectStaffing}
        hasStaffing={hasStaffing}
        hasFilters={hasFilters}
        hasDirectStaffing={hasDirectStaffing}
        initialResourceTypes={initialResourceTypes}
      />
    </div>
  )
}

export default StaffingFormStepOne;
