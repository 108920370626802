import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BookingItem} from "../../../../interfaces/BookingInterfaces";
import {User} from "../../../../interfaces/UserInterfaces";
import {supplierService} from "../../../../services/SupplierService";
import {dateUtils} from "../../../../utils/dateUtils";
import BookingItemCard from "../booking/BookingItemCard";

interface DashboardNextServicesProps {
  className?: string,
  user: User
}

const DashboardNextServices: FunctionComponent<DashboardNextServicesProps> = ({
  className = "",
  user,
}) => {
  const intl = useIntl()
  const [bookings, setBookings] = useState<BookingItem[]>([])

  useEffect(() => {
    supplierService.getPurchaseForSupplier(user?.id)
      .then((purchases) => setBookings(purchases))
      .catch(() => intl.formatMessage({id: "message_id"}))
  }, [])

  const getBookingsByDate = () => {
    const bookingsByDate: Dictionary<BookingItem[]> = groupBy(bookings, "date")
    const sortedBookingsByDate = Object.entries(bookingsByDate) ?? [];
    sortedBookingsByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)
    return sortedBookingsByDate;
  }

  const buildDate = (date: string) => {
    const localized = dateUtils.dateToLocaleDate(dateUtils.parseDate(date))
    return capitalize(localized)
  }

  return (
    <div className={className}>
      <h3 className="fs-16"><FormattedMessage id="dashboard_next_services" /></h3>

      {getBookingsByDate().map(([date, bookings]) => (
        <>
          <div className="d-flex align-items-center mb-2">
            {buildDate(date)}
          </div>
          <div className="d-flex gap-2">
            {bookings.map(booking => (
              <BookingItemCard key={booking.id} bookingItem={booking} />
            ))}
          </div>
        </>
      ))}
    </div>
  )
}

export default DashboardNextServices;
