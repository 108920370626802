import {capitalize, Dictionary, groupBy} from "lodash";
import React, {FunctionComponent, useState} from "react";
import {Order} from "../../../../interfaces/OrderInterfaces";
import {OrderItem} from "../../../../interfaces/OrderItemInterfaces";
import OrderItemPreviewCard from "../../card/orderItem/OrderItemPreviewCard";
import {dateUtils} from "../../../../utils/dateUtils";
import {orderService} from "../../../../services/OrderService";
import {toastUtils} from "../../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {ResourceDisplayOption} from "../../../../constants/ResourceDisplayOptionConstant";

interface OrderTableRowDetailsProps {
  order: Order,
  isCustomer?: boolean,
  resourceDisplayOption: ResourceDisplayOption
}

const OrderTableRowDetails: FunctionComponent<OrderTableRowDetailsProps> = ({
  order,
  isCustomer = false,
  resourceDisplayOption
}) => {

  const intl = useIntl();

  const getSortedItemsByDate = (items: OrderItem[]): [string, OrderItem[]][] => {
    const itemsByDate: Dictionary<OrderItem[]> = groupBy(items, "rendezVousDate")
    const sortedItemsByDate = Object.entries(itemsByDate) ?? [];
    sortedItemsByDate.sort((a, b) => dateUtils.parseDate(a[0]) < dateUtils.parseDate(b[0]) ? -1 : 1)

    // sort items for date by hour
    sortedItemsByDate.forEach(([, items]) => {
      items.sort((a, b) => {
        if (a.rendezVousHour < b.rendezVousHour) {
          return -1
        } else if (a.rendezVousHour > b.rendezVousHour) {
          return 1
        } else {
          return a.name.localeCompare(b.name)
        }
      })
    })

    return sortedItemsByDate;
  }

  const [orderItems, setOrderItems] = useState<[string, OrderItem[]][]>(getSortedItemsByDate(order?.items ?? []))

  const buildDate = (date: string) => {
    const localized = dateUtils.dateToLocaleDate(dateUtils.parseDate(date))
    return capitalize(localized)
  }

  const refreshOrderItems = () => {
    orderService.getOrderById(order.id)
      .then((order) => {
        setOrderItems(getSortedItemsByDate(order?.items))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_order_get"}))
      })
  }

  return (
    <>
      {orderItems?.map(([date, orderItemsByDate]) => (
        <div key={date} className="mt-2">
          <div className="epow-custom-table-row-details-date">
            <div className="d-flex align-items-center w-100">
              <hr className="flex-grow-1" />
              <span className="bold">{buildDate(date)}</span>
              <hr className="flex-grow-1" />
            </div>
          </div>
          {orderItemsByDate?.map(orderItem => (
            <OrderItemPreviewCard
              key={orderItem.id}
              orderItem={orderItem}
              isCustomer={isCustomer}
              resourceDisplayOption={resourceDisplayOption}
              onOrderItemChange={refreshOrderItems}
            />
          ))}
        </div>
      ))}
    </>
  )

}

export default OrderTableRowDetails;
