import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {QuoteItem} from "../../../../interfaces/QuoteItemInterfaces";
import FieldLabel from "../../../atoms/FieldLabel";
import {numberUtils} from "../../../../utils/numberUtils";
import {profileUtils} from "../../../../utils/profileUtils";
import QuoteItemPreviewCardHeader from "./QuoteItemPreviewCardHeader";

interface QuoteItemPreviewCardProps {
  className?: string,
  quoteItem: QuoteItem,
  isEditable?: boolean,
  QuoteStatus?: string,
  editQuoteItem?: (QuoteItem: QuoteItem) => void,
  onQuoteItemChange?: () => void,
  isCustomer?: boolean,
}

const QuoteItemPreviewCard: FunctionComponent<QuoteItemPreviewCardProps> = ({
  className = "",
  quoteItem,
  isEditable = false,
  editQuoteItem = () => null,
  onQuoteItemChange = () => null,
}) => {
  const intl = useIntl()

  return (
    <div className={`epow-order-item-preview d-flex flex-column border p-2 w-100 ${className}`} id={quoteItem.id}>
      <QuoteItemPreviewCardHeader
        quoteItem={quoteItem}
        editQuoteItem={editQuoteItem}
        onDelete={onQuoteItemChange}
        isEditable={isEditable}
      />

        <div className="offset-2 col-18 d-flex flex-column">
          {profileUtils.isInterne() &&
            <div className="d-flex flex-row mb-1">
              <FieldLabel className="col-8" label={intl.formatMessage({id: "order_item_sell_price_read"})} value={`${numberUtils.formatPrice(quoteItem?.sellingPriceHT)} €`} />
              <FieldLabel className="col-4" label={intl.formatMessage({id: "order_item_sell_price_tva_read"})} value={"20 %"} />
              <FieldLabel className="col-8" label={intl.formatMessage({id: "order_item_sell_price_total"})} value={`${numberUtils.formatPrice(quoteItem?.sellingPriceTTC)} €`} />
            </div>
          }
          <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_item_place"})} value={quoteItem?.rendezVousPlace} />
          <FieldLabel label={intl.formatMessage({id: "order_item_description"})} value={quoteItem?.description} />
        </div>
      </div>
  )
}

export default QuoteItemPreviewCard;
