import {SupplierGeneralInfo} from "interfaces/SupplierInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType, SizeType} from "types/bootstrap/BootstrapType";
import FieldLabel from "../../../atoms/FieldLabel";
import Button from "../../../atoms/Button";
import {getCountryLabel} from "../../../../utils/countryUtils";
import {Col, Row} from "reactstrap";

interface SupplierCardProps {
  className?: string;
  supplierGeneralInfo?: SupplierGeneralInfo;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
}

const SupplierCard: FunctionComponent<SupplierCardProps> = ({
  className = "",
  supplierGeneralInfo,
  editInfo,
  setEditInfo,
}) => {
  const intl = useIntl()
  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-4">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_name"})}
          value={supplierGeneralInfo?.name}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "address_field"})}
          value={supplierGeneralInfo?.mainLabel}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "additional_address_field"})}
          value={supplierGeneralInfo?.additionalInformation}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "service_field"})}
          value={supplierGeneralInfo?.service}
        />
        <Row className="gap-3">
          <Col sm={24} md={10}>
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id : "zip_code_field"})}
              value={supplierGeneralInfo?.zipCode}
            />
          </Col>
          <Col sm={24} md={12}>
            <FieldLabel
              verticalDisplay
              label={intl.formatMessage({id : "city_field"})}
              value={supplierGeneralInfo?.city}
            />
          </Col>
        </Row>
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "country_field"})}
          value={getCountryLabel(supplierGeneralInfo?.country)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_email"})}
          value={supplierGeneralInfo?.entityEmail}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_phone"})}
          value={supplierGeneralInfo?.entityPhone}
        />
      </div>

      <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended">
        <FormattedMessage id="edit_button"/>
      </Button>
    </div>
  );
};

export default SupplierCard;
