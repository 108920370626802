import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {customerService} from "../../../services/CustomerService";
import {toastUtils} from "../../../utils/toastUtils";
import {CustomerService} from "../../../interfaces/ServiceInterfaces";
import {serviceService} from "../../../services/ServiceService";
import {Formik} from "formik";
import {CUSTOMER_SERVICE_INITIAL_VALUES} from "../../../constants/ServiceConstants";
import {CreateCustomerServiceSchema} from "../../../constants/validation/ServiceValidationSchemas";
import FormAutocompleteSelectPageable from "../input/FormAutocompleteSelectPageable";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import EpowForm from "./EpowForm";

interface CreateCustomerServiceFormProps {
  id?: string,
  className?: string,
  serviceId?: string,
  onValidate?: () => void
  existingCustomerIds?: string[]
}

const CreateCustomerServiceForm: FunctionComponent<CreateCustomerServiceFormProps> = ({
  id,
  className = "",
  serviceId,
  onValidate = () => null,
  existingCustomerIds = [],
}) => {
  const intl = useIntl()

  const handleSubmitCustomerService = (customerService: CustomerService) => {
    serviceService.createCustomerService({...customerService, serviceId: serviceId})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_customer_service"}))
        onValidate()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_customer_service"}))
      })
  }

  return (
    <Formik
      initialValues={CUSTOMER_SERVICE_INITIAL_VALUES}
      validationSchema={CreateCustomerServiceSchema}
      onSubmit={handleSubmitCustomerService}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormAutocompleteSelectPageable
            id="customerId"
            label={intl.formatMessage({id: "customer_field"})}
            placeholder={intl.formatMessage({id: "customer_placeholder"})}
            onChange={(value) => setFieldValue("customerId", value)}
            value={values?.customerId}
            fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE, excludedIds: existingCustomerIds})}
            filterFieldName="name"
            required
            error={errors?.customerId}
            touched={touched?.customerId}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateCustomerServiceForm
