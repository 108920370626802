import React, {FunctionComponent} from "react";
import {RouteProps, useParams} from "react-router-dom";
import TemplateBillingView from "./TemplateBillingView";
import {creditNoteService} from "../../services/CreditNoteService";
import CreditNoteHeader from "../../components/molecules/header/CreditNoteHeader";

const CreditNoteView: FunctionComponent<RouteProps> = () => {

  const {id} = useParams();

  return (
    <TemplateBillingView
      HeaderComponent={(props) => <CreditNoteHeader {...props} />}
      fetchInvoice={() => creditNoteService.getCreditNote(id)}
    />
  );
}

export default CreditNoteView;
