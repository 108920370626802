import React, {FunctionComponent, useState} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import ResourceList from "components/molecules/list/ResourceList";
import useSearchFilter from "../hooks/useSearchFilter";
import {ResourceSearchObject} from "../interfaces/ResourceInterfaces";
import CommonEntityHeader from "../components/molecules/header/CommonEntityHeader";
import Panel from "../components/molecules/panel/Panel";
import CreateResourceForm from "../components/molecules/form/resource/CreateResourceForm";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import useLoading from "../hooks/useLoading";

const ResourceListView: FunctionComponent<RouteProps> = () => {
  const {searchObject, onReset, updateSearchField} = useSearchFilter<ResourceSearchObject>({
    nameSurname: "",
    currentState: WorkflowStatesEnum.ACTIVE
  })
  const intl = useIntl()
  const [creationPanelOpen, setCreationPanelOpen] = useState<boolean>(false);
  const {loading, stopLoading, startLoading} = useLoading()
  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <CommonEntityHeader
          pageTitle={intl.formatMessage({id: "resource_title"})}
          updateSearchField={updateSearchField}
          setCreationPanelOpen={setCreationPanelOpen}
          creationButtonLabel={intl.formatMessage({id: "resource_create"})}
        />

        <ResourceList
          searchObject={searchObject}
          onReset={() => onReset({currentState: searchObject.currentState})}
          updateSearchField={updateSearchField}
        />

        {creationPanelOpen && (
          <Panel
            formId="createResourceForm"
            title="create_new_resource"
            open={creationPanelOpen}
            loading={loading}
            onCancel={() => setCreationPanelOpen(false)}
          >
            <CreateResourceForm
              id="createResourceForm"
              startLoading={startLoading}
              stopLoading={stopLoading}
            />
          </Panel>
        )}

      </div>
    </Layout>
  )
}

export default ResourceListView
