import {Tour} from "../../../interfaces/TourInterfaces";
import React, {FunctionComponent} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {tourService} from "../../../services/TourService";
import {toastUtils} from "../../../utils/toastUtils";
import {Formik} from "formik";
import {TOUR_INITIAL_VALUES} from "../../../constants/TourConstants";
import {CreateTourSchema} from "../../../constants/validation/TourValidationSchemas";
import FormInput from "../input/FormInput";
import {TOURS_PATH} from "../../../constants/routes/RoutePaths";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import {LoadingComponent} from "../../../constants/menu/Layout";

interface CreateTourFormProps extends LoadingComponent  {
  id?: string,
  className?: string,
  initialTour?: Tour,
  onValidate?: () => void
}

const CreateTourForm: FunctionComponent<CreateTourFormProps> = ({
  id,
  className = "",
  initialTour,
  onValidate,
  stopLoading,
  startLoading,
  closeForm = () => null,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()
  const tourId = initialTour?.id

  const handleSubmitTour = (tourFields: Tour) => {
    startLoading?.()
    if (tourId) {
      tourService.updateTour(tourId, tourFields)
        .then(() => {
          onValidate && onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_tour"}))
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    } else {
      tourService.createTour(tourFields)
        .then((tour) => {
          location.pathname.includes(`${TOURS_PATH}/`) ? window.location.href = `${TOURS_PATH}/${tour?.id}` : navigate(`${TOURS_PATH}/${tour?.id}`);
        })
        .catch((e: Error) => {
          toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_create_tour"})} : ${e.message}`)
        }).finally(() => {
          stopLoading?.()
          closeForm()
        })
    }
  }

  return (
    <Formik initialValues={initialTour || TOUR_INITIAL_VALUES} validationSchema={CreateTourSchema} onSubmit={values => {
      handleSubmitTour(values)
    }}>
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`${className}`}>
          <FormInput
            id="tourName"
            label="tour_tour_name_field"
            value={values?.tourName}
            onChange={(e) => setFieldValue("tourName", e.target.value)}
            required
            error={errors.tourName}
            touched={touched.tourName}
          />
          <FormInput
            id="tourCode"
            label="tour_tour_code_field"
            value={values?.tourCode}
            onChange={(e) => setFieldValue("tourCode", e.target.value.toUpperCase())}
            required
            error={errors.tourCode}
            touched={touched.tourCode}
          />
          {initialTour && (
            <FormInput
              id="numberPax"
              label="tour_number_pax"
              type="number"
              value={values?.numberPax}
              onChange={(e) => setFieldValue("numberPax", e.target.value)}
              error={errors.numberPax}
              touched={touched.numberPax}
            />
          )}
        </EpowForm>
      )}
    </Formik>
  )
}
export default CreateTourForm
