import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps} from "react-router-dom";
import {toastUtils} from "utils/toastUtils";
import {tourLeaderService} from "services/TourLeaderService";
import {TourLeader} from "../../interfaces/TourLeaderInterfaces";
import useTourLeaderWorkflow from "../../hooks/resource/useTourLeaderWorkflow";
import {WorkflowActionsEnum} from "../../constants/workflow/WorkflowStates";
import TourLeaderTemplateView from "../TourLeaderViewTemplate";
import {ApiError} from "../../interfaces/ErrorInterfaces";

const TourLeaderSelfView: FunctionComponent<RouteProps> = () => {

  const [tourLeader, setTourLeader] = useState<TourLeader>(null)

  const getTourLeader = () => {
    tourLeaderService.getSelfTourLeader()
      .then((tourLeader) => {
        setTourLeader(tourLeader)
      })
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }

  const {
    handleActivateButton
  } = useTourLeaderWorkflow(tourLeader, (tl) => {
    setTourLeader(tl)
    getTourLeader()
  })


  useEffect(() => {
    getTourLeader()
  }, [])

  return (
    <TourLeaderTemplateView
      onChangeTourLeader={setTourLeader}
      tourLeader={tourLeader}
      onRefresh={getTourLeader}
      workflowActions={{
        [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
      }}/>
  )
}

export default TourLeaderSelfView
