import {httpService} from "./HttpService";
import {API_QUOTE_ITEMS_PATH} from "../constants/routes/RoutePaths";
import {QuoteItemRequest} from "../interfaces/QuoteItemInterfaces";


function deleteQuoteItem (id: string): Promise<Response> {
  return httpService.delete(`${API_QUOTE_ITEMS_PATH}/${id}`)
}

function updateQuoteItem (id: string, newQuoteItem: QuoteItemRequest): Promise<Response> {
  return httpService.put(`${API_QUOTE_ITEMS_PATH}/${id}`, newQuoteItem)
}

function createQuoteItem (newQuoteItem: QuoteItemRequest): Promise<Response> {
  return httpService.post(API_QUOTE_ITEMS_PATH, newQuoteItem)
}

export const quoteItemService = {
  deleteQuoteItem,
  updateQuoteItem
  ,createQuoteItem
}
