import React, {FunctionComponent} from "react"
import {toastUtils} from "../../utils/toastUtils";
import {useIntl} from "react-intl";
import {Guard} from "../../interfaces/routes/RouteInterfaces";
import {Navigate, Outlet, RouteProps} from "react-router-dom";

interface GuardedRouteProps extends RouteProps {
  guards: Guard[];
}

const GuardedRoute: FunctionComponent<GuardedRouteProps> = ({
  guards,
}) => {
  const intl = useIntl()

  const failureGuard: Guard = guards.find(guard => !guard.canAccess())
  if (failureGuard) {
    toastUtils.errorToast(intl.formatMessage({ id: failureGuard.errorLabel }))
    return <Navigate to={failureGuard.fallbackUrl} replace />
  } else {
    return <Outlet />
  }
}

export default GuardedRoute;
