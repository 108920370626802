import React, {FunctionComponent} from "react";
import {PurchaseSearchObject} from "../../../interfaces/PurchaseInterfaces";
import {useIntl} from "react-intl";
import usePaginatedData from "../../../hooks/usePaginatedData";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import {Button, Pagination} from "reactstrap";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import DateFilter from "../filters/DateFilter";
import {supplierPurchaseService} from "../../../services/SupplierPurchaseService";
import SupplierPurchaseTable from "../table/purchase/SupplierPurchaseTable";

interface SupplierPurchaseListProps {
  className?: string,
  searchObject?: PurchaseSearchObject,
  onReset: () => void,
  updateSearchField: (field: string, value: string) => void
}

const SupplierPurchaseList: FunctionComponent<SupplierPurchaseListProps> = ({
  className = "",
  searchObject,
  onReset,
  updateSearchField
}) => {
  const intl = useIntl()

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total
  } = usePaginatedData(supplierPurchaseService.getPurchases, searchObject)

  return (
    <div className={`${className} mb-5`}>
      <FilterGroup className="d-flex gap-2">
        <TextFilter
          className="main-filter"
          title={intl.formatMessage({id: "purchase_booking_filter_title"})}
          value={searchObject.customerOrPurchase}
          onChange={(value) => updateSearchField("customerOrPurchase", value)}
          placeholder={intl.formatMessage({id: "purchase_filter_customer_order_placeholder"})}
        />
        <DateFilter
          value={searchObject.startDate}
          onChangeDate={(value) => updateSearchField("startDate", value)}
          title={intl.formatMessage({id: "order_filter_period_start"})}
        />
        <DateFilter
          value={searchObject.endDate}
          onChangeDate={(value) => updateSearchField("endDate", value)}
          title={intl.formatMessage({id: "order_filter_period_end"})}
        />
        <div>
          <Button onClick={() => onReset()} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <SupplierPurchaseTable purchases={data} sortOptions={sortOptions} onSortOptions={setSortOptions}/>
      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default SupplierPurchaseList;
