import React, {FunctionComponent, useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import FormAutocompleteSelectPageable from "../../../input/FormAutocompleteSelectPageable";
import FormInput from "../../../input/FormInput";
import FormRadio from "../../../input/FormRadio";
import CreateOrderItemRendezVousForm from "../shared/CreateOrderItemRendezVousForm";
import {FormikErrors, FormikTouched} from "formik";
import {YES_NO_OPTIONS} from "../../../../../constants/OptionConstants";
import {Activity} from "../../../../../interfaces/ActivityInterfaces";
import {FormikFieldSetter} from "../../../../../interfaces/FormikInterfaces";
import {Order} from "../../../../../interfaces/OrderInterfaces";
import {OrderItemRequest, OrderItemResourceDTO} from "../../../../../interfaces/OrderItemInterfaces";
import {Service} from "../../../../../interfaces/ServiceInterfaces";
import {activityService} from "../../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../../constants/workflow/WorkflowStates";
import {toastUtils} from "../../../../../utils/toastUtils";
import {ApiError} from "../../../../../interfaces/ErrorInterfaces";
import {Col, Row} from "reactstrap";
import {catalogActivityCustomerService} from "../../../../../services/CatalogActivityCustomerService";
import {SellInformations} from "../../../../../interfaces/CatalogActivityCustomerInterfaces";

interface CreateOrderItemActivityFormStepOneProps {
  className?: string,
  errors: FormikErrors<OrderItemRequest>,
  touched: FormikTouched<OrderItemRequest>,
  values: OrderItemRequest,
  setFieldValue: FormikFieldSetter,
  order?: Order,
  setSelectedActivity: (activity: Activity) => void,
  setSelectedService: (service: Service) => void,
  setValues: (values: OrderItemRequest) => void,
  updateValidationSchema: (orderItemResources : OrderItemResourceDTO[]) => void,
  departureTimeMinuteDelta: number,
  isEditable: boolean,
}

const CreateOrderItemActivityFormStepOne: FunctionComponent<CreateOrderItemActivityFormStepOneProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  setSelectedActivity,
  setSelectedService,
  setValues,
  updateValidationSchema,
  order,
  isEditable,
  departureTimeMinuteDelta,
}) => {
  const intl = useIntl()

  const getInfosForActivity = (activity: Activity) => {
    if(activity) {
      catalogActivityCustomerService.getInfosForActivityCustomer(activity.id, values.billingService, order.beginTourDate)
        .then((sellInformations: SellInformations) => {
          setFieldValue("paxPrice", sellInformations.sellingPrice)
          setFieldValue("description", sellInformations.description)
        })
        .catch((error: ApiError) => toastUtils.errorToast(error.message))
    }
  }

  const updateActivityInfos = (activity: Activity) => {
    setFieldValue("unitType", activity?.unitType)
    getInfosForActivity(activity)
  }


  const updateSelectedActivity = async (activityId): Promise<Activity> => {
    return activityService.getActivity(activityId, order.id)
      .then(activity => {
        setSelectedActivity(activity)
        setFieldValue("name", activity.billingName)
        setFieldValue("title", activity.name)
        return activity
      }).catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
        return {}
      })
  }
  useMemo(() => {
    const fetchData = async () => {
      if (values?.id && values?.activity) {
        await updateSelectedActivity(values?.activity);
      }
    }

    fetchData()
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }, [values?.id])

  const onChangeActivity = async (activityId: string) => {
      const activity = activityId ? await updateSelectedActivity(activityId) : null;

      setSelectedActivity(activity)
      setSelectedService(null)

    const resourceRequirements = activity?.resourceRequirements?.map((requirement) => {
      return { quantity: requirement.quantity, resourceType: requirement.resourceType }
    })

    setValues({
      ...values,
      activity: activity?.id,
      name: activity?.billingName,
      isStaffingManagement: activity?.staffingManagement,
      showActivityTourLeader: activity?.showActivityTourLeader,
      orderItemResources: resourceRequirements
    })

    updateActivityInfos(activity)
    updateValidationSchema(resourceRequirements)
  }

  // useCallback to avoid useless calls when component update
  const fetchActivities = useCallback((page, filter) => {
    return activityService.getActivitiesPage(page, {...filter, status: WorkflowStatesEnum.ACTIVE})
  }, []);

  return (
    <div className={className}>
      <FormAutocompleteSelectPageable
        id="activity"
        label={intl.formatMessage({id: "order_item_activity"})}
        placeholder={isEditable ? values?.title : intl.formatMessage({id: "order_item_type_activity"})}
        value={values?.activity}
        onChange={(value) => onChangeActivity(value)}
        error={errors?.activity}
        touched={touched?.activity}
        required
        fetchData={fetchActivities}
        readOnly={isEditable}
        filterFieldName="name"
      />

      <FormInput
        id="name"
        label="order_item_name"
        value={values?.name}
        onChange={(e) => setFieldValue("name", e.target?.value)}
        required
        error={errors.name}
        touched={touched.name}
      />

      <Row>
        <Col xs={24} md={12}>
          <FormRadio
            id="isBookingManagement"
            label="order_item_is_booking_management"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.isBookingManagement?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("isBookingManagement", e.value)}
            required
            error={errors.isBookingManagement}
            touched={touched.isBookingManagement}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormRadio
            id="showActivityTourLeader"
            label="order_item_activity_ops"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.showActivityTourLeader?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("showActivityTourLeader", e.value)}
            required
            error={errors.showActivityTourLeader}
            touched={touched.showActivityTourLeader}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12}>
          <FormRadio
            id="optional"
            label="order_item_optional"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.optional?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("optional", e.value)}
            required
            error={errors.optional}
            touched={touched.optional}
          />
        </Col>
        <Col xs={24} md={12}>
          <FormRadio
            id="isStaffingManagement"
            label="order_item_is_staffing_management"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.isStaffingManagement?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("isStaffingManagement", e.value)}
            required
            error={errors.isStaffingManagement}
            touched={touched.isStaffingManagement}
          />
        </Col>
      </Row>

      <FormInput
        id="description"
        label="order_item_description"
        type="textarea"
        value={values?.description ?? ""}
        onChange={(e) => setFieldValue("description", e.target?.value)}
        error={errors.description}
        touched={touched.description}
      />

      <CreateOrderItemRendezVousForm
        values={values}
        errors={errors}
        touched={touched}
        departureTimeMinuteDelta={departureTimeMinuteDelta}
        setFieldValue={setFieldValue}
      />


    </div>
  )
}

export default CreateOrderItemActivityFormStepOne;
