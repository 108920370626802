import React, {FunctionComponent} from "react";
import {RouteProps, useNavigate} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Label} from "reactstrap"
import Title from "../components/atoms/Title";
import {Formik} from "formik";
import {object as YupObject} from "yup";
import FormInput from "../components/molecules/input/FormInput";
import {LogoSizeType, SizeType} from "../types/bootstrap/BootstrapType";
import Button from "../components/atoms/Button";
import {authentificationService} from "../services/AuthentificationService";
import {toastUtils} from "../utils/toastUtils";
import {yupRequiredEmail} from "../constants/yup/Validators";
import {LOGIN_PATH} from "../constants/routes/RoutePaths";
import EpowForm from "../components/molecules/form/EpowForm";
import LoginLayout from "../layout/LoginLayout";
import Logo from "../components/atoms/logo/Logo";
import {LOGO_DARK} from "../constants/Assets";

const ForgottenPasswordView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const emailSchema = YupObject().shape({
    email: yupRequiredEmail(3, 50)
  })

  const onSendMailResetPassword = ({email}) => {
    authentificationService.createToken(email)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_send_mail_reset_password"}))
        navigate(LOGIN_PATH)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_send_mail_reset_password"}))
      })
  }

  return (
    <LoginLayout>
      <Col className="d-flex flex-column justify-content-center align-items-center p-5">
        <Col xl={16} lg={20}>
          <div className="d-flex flex-column justify-content-start text-start mb-5">
            <Logo imageSource={LOGO_DARK} width={LogoSizeType.XXL} alt="SOFTOURING" className="m-auto mb-5" />
            <Title title={intl.formatMessage({id: "forgotten_password_title"})} className="mb-1 text-start"/>
            <Label className="epow-label white-space-pre-wrap">
              <FormattedMessage id="forgotten_password_subtitle"/>
            </Label>
          </div>
          <Formik
            initialValues={{email: ""}}
            validationSchema={emailSchema}
            onSubmit={onSendMailResetPassword}
          >
            {({errors, touched, values, setFieldValue}) => (
              <EpowForm className="d-flex flex-column justify-content-center">
                <FormInput
                  id="email"
                  label="form_login_email"
                  placeholder={intl.formatMessage({id: "email_placeholder"})}
                  value={values?.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                  required
                  error={errors.email}
                  touched={touched.email}
                />
                <Button type="submit" size={SizeType.MD}>
                  <FormattedMessage id="send_mail_reset_password"/>
                </Button>
              </EpowForm>
            )}
          </Formik>
        </Col>
      </Col>
    </LoginLayout>
  )
}

export default ForgottenPasswordView
