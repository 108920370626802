import React, {FunctionComponent, useMemo} from "react";
import {ACTIVITY_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import {activityService} from "services/ActivityService";
import PagedTable from "../table/PagedTable";
import {useIntl} from "react-intl";
import Button from "../../atoms/Button";
import FilterGroup from "../filters/FilterGroup";
import TextFilter from "../filters/TextFilter";
import {ActivitySearchObject} from "../../../interfaces/ActivityInterfaces";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {serviceService} from "../../../services/ServiceService";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";

interface ActivityListProps {
  className?: string,
  searchObject?: ActivitySearchObject,
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const ActivityList: FunctionComponent<ActivityListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
 }) => {
  const intl = useIntl();
  const columns = useMemo(() => tableUtils.getColumns(ACTIVITY_TABLE(intl)), [intl]);

  return (
    <>
      <FilterGroup className="gap-2">
        <TextFilter
          icon="Search"
          className="main-filter"
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          title={intl.formatMessage({id: "activity_name_field"})}
          placeholder={intl.formatMessage({id: "activity_table_search_placeholder"})}
        />
        <TextFilter
          value={searchObject?.reference}
          onChange={(value) => updateSearchField("reference", value)}
          title={intl.formatMessage({id: "activity_reference_field"})}
          placeholder={intl.formatMessage({id: "activity_reference_field"})}
        />
        <AutocompleteFilterPageable
          id="serviceId"
          title={intl.formatMessage({id: "dropdown_header_service"})}
          placeholder={intl.formatMessage({id: "service_placeholder"})}
          onChange={(value) => updateSearchField("serviceId", value)}
          value={searchObject?.serviceId}
          fetchData={(page, filter) => serviceService.getServicesPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PagedTable
        columns={columns}
        labelNoResult="activity_no_result"
        className={`${className} table`}
        fetchData={activityService.getActivitiesPage}
        filters={searchObject}
      />
    </>
  )
}

export default ActivityList
