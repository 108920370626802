import React, {FunctionComponent, useEffect, useState} from "react";
import {CustomerServiceCatalog} from "../../../../interfaces/ServiceInterfaces";
import {serviceService} from "../../../../services/ServiceService";
import CustomerServiceList from "../../list/CustomerServiceList";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import Panel from "../../panel/Panel";
import CreateCustomerServiceForm from "../../form/CreateCustomerServiceForm";

interface CustomerServiceCardProps {
  className?: string,
  serviceId?: string
}

const CustomerServiceCard: FunctionComponent<CustomerServiceCardProps> = ({
  className = "",
  serviceId
}) => {
  const [lateralPanelCustomerService, setLateralPanelCustomerService] = useState<boolean>(false)
  const [customerServiceCatalogs, setCustomerServiceCatalogs] = useState<CustomerServiceCatalog[]>([])

  const getCustomerServiceCatalogs = () => {
    if (serviceId) {
      serviceService.getCustomerServices(serviceId)
        .then((customerServiceCatalogs) => {
          setCustomerServiceCatalogs(customerServiceCatalogs)
        })
        .catch(() => {
          setCustomerServiceCatalogs([])
        })
    }
  }

  const handleValidate = () => {
    getCustomerServiceCatalogs()
    setLateralPanelCustomerService(false)
  }

  useEffect(() => {
    getCustomerServiceCatalogs()
  }, [serviceId])

  return (
    <div className={className}>
      <CustomerServiceList
        className="mb-3"
        customerServiceCatalogs={customerServiceCatalogs}
        onValidate={handleValidate}
      />
      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        className="extended"
        onClick={() => setLateralPanelCustomerService(true)}
      >
        <FormattedMessage id="add_customer"/>
      </Button>
      {lateralPanelCustomerService && (
        <Panel
          formId="createCustomerServiceForm"
          title="add_customer"
          open={lateralPanelCustomerService}
          onCancel={() => setLateralPanelCustomerService(false)}
        >
          <CreateCustomerServiceForm
            id="createCustomerServiceForm"
            serviceId={serviceId}
            onValidate={handleValidate}
            existingCustomerIds={customerServiceCatalogs.map(c => c?.customer?.id)}
          />
        </Panel>
      )}
    </div>
  )
}

export default CustomerServiceCard