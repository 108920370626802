import {
  AuthRequest,
  AuthResponse,
  AuthRuleRequest,
  PasswordToken,
  PasswordTokenRequest,
} from "../interfaces/api/AuthentificationInterfaces";
import {httpService} from "./HttpService";
import {API_AUTH_PATH, API_PASSWORD_TOKEN_PATH, API_RULES_PATH} from "../constants/routes/RoutePaths";
import {AuthUser, User} from "../interfaces/UserInterfaces";

function auth({email, password} : AuthRequest): Promise<AuthUser> {
  return httpService.post<AuthUser>(API_AUTH_PATH, {email: email, password: password})
}

function createToken(email: string): Promise<PasswordToken> {
  return httpService.post<PasswordToken>(API_PASSWORD_TOKEN_PATH, {email: email})
}

function resetPassword(passwordTokenRequest: PasswordTokenRequest): Promise<User> {
  return httpService.post<User>(`${API_PASSWORD_TOKEN_PATH}/init`, passwordTokenRequest)
}

const acceptRules = (request: AuthRuleRequest): Promise<AuthResponse> => {
  return httpService.post<AuthResponse>(`${API_RULES_PATH}`, request);
}

export const authentificationService =  Object.freeze({
  auth,
  acceptRules,
  createToken,
  resetPassword
})
