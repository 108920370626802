import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {DashboardActivity} from "../../../interfaces/ActivityInterfaces";
import Activity from "./Activity";

interface NextTasksProps {
  activities: DashboardActivity[],
  onClickActivity?: (orderItemId: string) => void
}

const NextActivities: FunctionComponent<NextTasksProps> = ({activities = [], onClickActivity = () => false}) => (
  <div className="epow-dashboard-next-activities">
    <h3 className="fs-16"><FormattedMessage id="dashboard_next_activities"/></h3>
    <div className="d-flex flex-column flex-wrap">
      {activities.map(activity => <Activity key={activity.orderItemId} activity={activity} onClickActivity={() => onClickActivity(activity.orderItemId)}/>)}
      {activities.length === 0 && <div className="mt-2"><FormattedMessage id="dashboard_no_next_activities"/></div>}
    </div>
  </div>)


export default NextActivities;
