import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import GeneralInfoServiceCard from "./GeneralInfoServiceCard";
import ServiceDefaultSupplierCard from "./ServiceDefaultSupplierCard";
import {Col, Row} from "reactstrap";

interface DetailsServiceCardProps {
  className?: string,
  service: Service,
  setService?: (service: Service) => void,
}

const DetailsServiceCard: FunctionComponent<DetailsServiceCardProps> = ({
  className = "",
  service,
  setService,
}) => {
  const intl = useIntl()
  return (
    <div className={`d-flex ${className}`}>
      <Row className="w-100">
        <Col xs={24} md={12}>
          <GeneralInfoServiceCard service={service} title={intl.formatMessage({id: "service_general_info"})} className="d-flex flex-column" setService={setService} />
        </Col>
        <Col xs={24} md={12}>
          <ServiceDefaultSupplierCard service={service} title={intl.formatMessage({id: "service_default_supplier"})} className="d-flex flex-column" />
        </Col>
      </Row>
    </div>
  )
}

export default DetailsServiceCard;
