import React, {FunctionComponent, useState} from "react";
import Circle from "../../../../atoms/Circle";
import {FormattedMessage} from "react-intl";
import {CircleColor} from "../../../../../constants/CircleConstants";
import CellHeaderAvailability from "./CellHeaderAvailability";
import {AvailabilityCalendar} from "../../../../../interfaces/CalendarInterface";
import Portal from "../../../../atoms/Portal";
import AvailableCalendarStaffingPanel from "../../../panel/staffing/AvailableCalendarStaffingPanel";
import {StaffingCalendarSearchFilter} from "../../../staffingcalendar/StaffingCalendar";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";

interface CellCalendarProps {
  availabilityType: string,
  availability: AvailabilityCalendar,
  date: string,
  resourceId: string,
  searchObject: StaffingCalendarSearchFilter,
  onReload: () => void,
}

const CellAvailableCalendar: FunctionComponent<CellCalendarProps> = ({
  availabilityType,
  availability,
  date,
  resourceId,
  searchObject,
  onReload,
}) => {
  const [panelOpen, setPanelOpen] = useState<boolean>(false)

  return (
    <div>
      <div
        onClick={() => setPanelOpen(true)}
        className={`staffing-calendar__cell lg cursor-pointer ${(availability?.availableOnFilteredQuery && availability?.availabilityConfirmedCount > 0) && "epow-bg-green"}`}>
        <CellHeaderAvailability availabilityType={availabilityType} arrowColor={ColorType.SECONDARY} />
        <p className="d-flex flex-row align-items-center bold">
          <Circle color={CircleColor.GREEN} className="me-2" />


          {!availability?.availableOnFilteredQuery && availability?.availabilityConfirmedCount > 0 &&
            <span className="ms-2">
              <FormattedMessage id="staffing_available_other_confirmed" />
            </span>}

          {availability?.availabilityConfirmedCount === 0 && <FormattedMessage id={"staffing_available"} />}
          {availability?.availableOnFilteredQuery && availability?.availabilityConfirmedCount > 0 &&
            <FormattedMessage id={"staffing_available_confirmed"}
              values={{count: availability?.availabilityConfirmedCount}} />}
        </p>
      </div>

      {panelOpen && (
        <Portal>
          <AvailableCalendarStaffingPanel
            open={panelOpen}
            setOpen={setPanelOpen}
            date={date}
            resourceId={resourceId}
            availabilityType={availabilityType}
            searchObject={searchObject}
            onReload={onReload}
          />
        </Portal>
      )}
    </div>);
}

export default CellAvailableCalendar;
