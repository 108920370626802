import {httpService} from "./HttpService";
import {API_NOTIFICATIONS_PATH} from "../constants/routes/RoutePaths";
import {Dashboard} from "../interfaces/ActivityInterfaces";
import {DASHBOARD_ROUTE} from "../constants/routes/ApiRoutes";

function getDashboard(): Promise<Dashboard> {
  return httpService.get<Dashboard>(DASHBOARD_ROUTE);
}

function deleteNotification(id: string): Promise<Response> {
  return httpService.delete(`${API_NOTIFICATIONS_PATH}/${id}`);
}

export const notificationService = {
  getDashboard,
  deleteNotification
};
