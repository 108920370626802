import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal from "./GenericModal";
import RichTextInput from "../input/RichTextInput";
import {Input} from "reactstrap";
import {Quote} from "../../../interfaces/QuoteInterfaces";
import FormMultipleFileUpload from "../input/FormMultipleFileUpload";

interface ModalDeleteDocumentProps {
  open: boolean,
  quote: Quote,
  onOpen: (open: boolean) => void,
  sendQuote: (template: string, files: File[]) => void,
  onClose: () => void,
}

const GenericModalSendQuote: FunctionComponent<ModalDeleteDocumentProps> = ({
  open,
  onOpen,
  quote,
  sendQuote,
  onClose
}) => {
  const intl = useIntl()

  const [template, setTemplate] = useState<string>("");
  const [sendMail, setSendMail] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const initialTemplate = `
<p>${intl.formatMessage({id: "quote_send_hello"}, {name: quote.contactName ? ` ${quote.contactName}` : ""})}</p>
<br/>

<p>${intl.formatMessage({id: "quote_send_body_1"})}</p>
<p>${intl.formatMessage({id: "quote_send_body_2"})}</p>
<p>${intl.formatMessage({id: "quote_send_body_3"})}</p>
<p>${intl.formatMessage({id: "quote_send_body_4"}, {endOdfValidity: Math.ceil((new Date(quote.endOfValidityDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24))})}</p>
<p>${intl.formatMessage({id: "quote_send_body_5"})}</p>

<br/>
<br/>
<p>${intl.formatMessage({id: "quote_send_greetings"})}</p>
<br/>
<p>Paris on the Way</p>
`
    setTemplate(initialTemplate)
  }, [])

  return (
    <GenericModal
      title={intl.formatMessage({id: "modal_body_send_title"})}
      open={open}
      closable
      onOpen={onOpen}
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: () => {
            sendQuote(sendMail ? template : "", files)
          },
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => {
            onClose()
          }
        }
      }}
    >
      <div className="d-flex flex-column p-3">
        {intl.formatMessage({id: "modal_body_send_quote"})}

        <div className="d-flex mb-2 mt-3">
          <Input
            id="sendEmail"
            className="cursor-pointer me-2"
            type="checkbox"
            checked={sendMail}
            onChange={() => setSendMail(prev => !prev)}
          />
          <FormattedMessage id="quote_send_mail" />
        </div>
        {sendMail &&
          <>
            <RichTextInput value={template} onChange={e => setTemplate(e.target.value)} />
            {error && <div className="epow-error">{error}</div>}
            <FormMultipleFileUpload
              id="files"
              label="quote_send_pj"
              files={files}
              accept={[".jpg", ".jpeg", ".pdf"]}
              onChange={(files: File[]) => {
                if (files.length > 2) {
                  setError(intl.formatMessage({id: "max_files_count"}, {max: 2}))
                } else {
                  setError("")
                  setFiles(files)
                }
              }}
            />
          </>
        }

      </div>
    </GenericModal>
  )
}

export default GenericModalSendQuote
