import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {OrderItem} from "../../../../interfaces/OrderItemInterfaces";
import FieldLabel from "../../../atoms/FieldLabel";
import OrderItemPreviewCardHeader from "./OrderItemPreviewCardHeader";
import {numberUtils} from "../../../../utils/numberUtils";
import {profileUtils} from "../../../../utils/profileUtils";
import OrderItemPreviewCardStaffing from "./previewCard/OrderitemPreviewCardStaffing";
import OrderItemPreviewCardBooking from "./previewCard/OrderItemPreviewCardBooking";
import OrderItemPreviewCardDocuments from "./previewCard/OrderItemPreviewCardDocuments";
import {authUtils} from "../../../../utils/authUtils";
import {PROFILE} from "../../../../interfaces/UserInterfaces";
import {fileUtils} from "../../../../utils/fileUtils";
import {fileService} from "../../../../services/FileService";
import {ResourceDisplayOption} from "../../../../constants/ResourceDisplayOptionConstant";

interface OrderItemPreviewCardProps {
  className?: string,
  orderItem: OrderItem,
  isEditable?: boolean,
  orderStatus?: string,
  editOrderItem?: (orderItem: OrderItem) => void,
  setIsEditable?: (isEditable: boolean) => void,
  onOrderItemChange?: () => void,
  isCustomer?: boolean,
  resourceDisplayOption?: ResourceDisplayOption
}

const OrderItemPreviewCard: FunctionComponent<OrderItemPreviewCardProps> = ({
  className = "",
  orderItem,
  isEditable = false,
  orderStatus = "",
  editOrderItem = () => null,
  setIsEditable = () => null,
  onOrderItemChange = () => null,
  isCustomer = false,
  resourceDisplayOption
}) => {
  const intl = useIntl()
  const profile = authUtils.getProfile();
  const shouldDisplayBookingCard = orderItem.bookingManagement && (profile === PROFILE.ADMIN || profile === PROFILE.INTERN);
  const shouldDisplayAdditionalInformation = authUtils.getProfile() === PROFILE.TOUR_LEADER && orderItem.additionalDocuments?.length > 0;
  const downloadDocument = (fileId: string, name: string, extension: string) => {
    const filePromise: Promise<Blob> = fileService.getDocumentById(fileId);
    fileUtils.downloadFile(filePromise, {name, extension}, intl);
  }

  React.useEffect(() => {
    // needed to navigate to hash after component is loaded
    if (window.location.hash.includes(orderItem.id)) {
      const node = document.getElementById(orderItem.id)
      node?.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <div className={`epow-order-item-preview d-flex flex-column p-2 w-100 ${className}${orderItem.noAvailableSupplier ? " no-supplier" : " border" }`} id={orderItem.id}>
      <OrderItemPreviewCardHeader
        orderItem={orderItem}
        editOrderItem={editOrderItem}
        onDelete={onOrderItemChange}
        isEditable={isEditable}
        setIsEditable={setIsEditable}
        orderStatus={orderStatus}
      />

      <div className="d-flex mb-2">
        <div className="col-6 px-2">
          <div className="d-flex flex-column">
            {(profileUtils.isInterne() || profile === PROFILE.TOUR_LEADER || profile === PROFILE.CLIENT) && orderItem.staffingManagement && (
              <OrderItemPreviewCardStaffing resourceDisplayOption={resourceDisplayOption} orderItem={orderItem} isCustomer={isCustomer} onOrderItemChange={onOrderItemChange} />
            )}
            <OrderItemPreviewCardDocuments downloadDocument={downloadDocument} orderItem={orderItem} onOrderItemChange={onOrderItemChange} />
          </div>
        </div>

        <div className={`${shouldDisplayBookingCard || shouldDisplayAdditionalInformation ? "col-12" : "col-18"} d-flex flex-column`}>
          {profileUtils.canViewCustomer() &&
            <div className="d-flex flex-row mb-1">
              <FieldLabel className="col-8" label={intl.formatMessage({id: "order_item_pax_price"})} value={`${numberUtils.formatPrice(orderItem?.paxPrice)} €`} />
            </div>
          }
          <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_item_place"})} value={orderItem?.rendezVousPlace} />
          <FieldLabel className="white-space-pre-wrap" label={intl.formatMessage({id: "order_item_description"})} value={orderItem?.description} />
        </div>

        {shouldDisplayBookingCard && (
          <div className="col-6 px-2 d-flex flex-column">
            <OrderItemPreviewCardBooking orderItem={orderItem} onOrderItemChange={onOrderItemChange} />
          </div>
        )}

        {shouldDisplayAdditionalInformation && (
          <div className="d-flex flex-column">
            <span className="bold">{`${intl.formatMessage({id: "dashboard_panel_activity_additional_infos"})} : `}</span>
            {orderItem?.additionalDocuments?.map(document => {
              const fullName = fileUtils.getCompleteFileName({name: document.name, extension: document.extension});
              return (
                <div key={document.fileId}>
                  <span
                    className="epow-text-link cursor-pointer"
                    onClick={() => downloadDocument(document.fileId, document.name, document.extension)} >
                    {fullName}
                  </span>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderItemPreviewCard;
