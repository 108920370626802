import React, {useState} from "react";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import Select from "../../atoms/input/Select";
import {useIntl} from "react-intl";

interface MonthYearSelectorProps {
  onMonthYearSelected: (month: number, year: number) => void;
  className?: string,
}

const MonthYearSelector: React.FC<MonthYearSelectorProps> = ({onMonthYearSelected, className}) => {
  const intl = useIntl()
  const [selectedMonth, setSelectedMonth] = useState<Option<number>>(null);
  const [selectedYear, setSelectedYear] = useState<Option<number>>(null);

  const handleMonthChange = (monthOption: Option<number>) => {
    setSelectedMonth(monthOption);
    if (selectedYear.value !== 0) {
      onMonthYearSelected(monthOption.value, selectedYear.value);
    }
  };

  const handleYearChange = (yearOption: Option<number>) => {
    setSelectedYear(yearOption);
    if (selectedMonth.value !== 0) {
      onMonthYearSelected(selectedMonth.value, yearOption.value);
    }
  };

  const monthOptions = [
    {value: 0, label: intl.formatMessage({id: "month_january"})},
    {value: 1, label: intl.formatMessage({id: "month_february"})},
    {value: 2, label: intl.formatMessage({id: "month_march"})},
    {value: 3, label: intl.formatMessage({id: "month_april"})},
    {value: 4, label: intl.formatMessage({id: "month_may"})},
    {value: 5, label: intl.formatMessage({id: "month_june"})},
    {value: 6, label: intl.formatMessage({id: "month_july"})},
    {value: 7, label: intl.formatMessage({id: "month_august"})},
    {value: 8, label: intl.formatMessage({id: "month_september"})},
    {value: 9, label: intl.formatMessage({id: "month_october"})},
    {value: 10, label: intl.formatMessage({id: "month_november"})},
    {value: 11, label: intl.formatMessage({id: "month_december"})}
  ];

  const getYearsOptions = (): Option<number>[] => {
    const currentYear = new Date().getFullYear();
    const startYear = 2000;
    return Array.from(
      {length: currentYear - startYear + 1},
      (_, index) => ({value: startYear + index, label: `${startYear + index}`})
    );
  }

  return (
    <div className={`d-flex ${className ?? ""}`}>
      <Select
        value={selectedMonth}
        onChange={handleMonthChange}
        options={monthOptions}
      />

      <Select
        value={selectedYear}
        onChange={handleYearChange}
        options={getYearsOptions()}
      />
    </div>
  );
};

export default MonthYearSelector;