import Button from "components/atoms/Button";
import Title from "components/atoms/Title";
import CreateSupplierForm from "components/molecules/form/CreateSupplierForm";
import {Supplier, SupplierGeneralInfo} from "interfaces/SupplierInterfaces";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Col} from "reactstrap";
import {ColorType, SizeType} from "types/bootstrap/BootstrapType";
import SupplierCard from "./SupplierCard";
import useLoading from "../../../../hooks/useLoading";

interface GeneralInfoSupplierCardProps {
  className?: string;
  supplierGeneralInfo?: SupplierGeneralInfo;
  setSupplier?: (supplier: Supplier) => void;
  title: string;
}

const GeneralInfoSupplierCard: FunctionComponent<GeneralInfoSupplierCardProps> = ({
  className = "",
  supplierGeneralInfo,
  setSupplier,
  title
}) => {
  const [editInfo, setEditInfo] = useState<boolean>(false);
  const {loading, stopLoading, startLoading} = useLoading()

  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size={"h5"} className="mb-3"/>
      {editInfo ? (
        <>
          <CreateSupplierForm
            id="editSupplierForm"
            supplierGeneralInfo={supplierGeneralInfo}
            setSupplier={setSupplier}
            setEditInfo={setEditInfo}
            startLoading={startLoading}
            stopLoading={stopLoading}
          />
          <div>
            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditInfo(false)}
              className="extended me-2"
            >
              <FormattedMessage id="cancel_button" />
            </Button>
            <Button
              form={"editSupplierForm"}
              type="submit"
              size={SizeType.MD}
              className="extended"
              loading={loading}
            >
              <FormattedMessage id="save_button" />
            </Button>
          </div>
        </>
      ) : (
        <SupplierCard
          supplierGeneralInfo={supplierGeneralInfo}
          editInfo={editInfo}
          setEditInfo={setEditInfo}
        />
      )}
    </Col>
  );
};

export default GeneralInfoSupplierCard;
