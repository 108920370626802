import * as Yup from "yup";
import { yupRequiredString } from "../yup/Validators";

export const CreateStructureSchema = Yup.object().shape({
  name: yupRequiredString,
  mainLabel: yupRequiredString,
  zipCode: yupRequiredString,
  city: yupRequiredString,
  country: yupRequiredString,
  phoneNumber: yupRequiredString,
  email: yupRequiredString
});
