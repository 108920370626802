import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {ResourceForStaffingDto, ResourceStaffingSearchParams} from "../../../../interfaces/ResourceStaffingInterfaces";
import {resourceStaffingService} from "../../../../services/ResourceStaffingService";
import {toastUtils} from "../../../../utils/toastUtils";
import TextFilter from "../../filters/TextFilter";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import {ActivityArea} from "../../../../interfaces/ActivityInterfaces";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import SelectFilter from "../../filters/SelectFilter";
import AutocompleteMultiselect from "../../filters/AutocompleteMultiselect";
import {resourceTypeService} from "../../../../services/ResourceTypeService";

interface StaffingFormBasicResourceSelectorFiltersProps {
  onSearchChange?: (resources: ResourceForStaffingDto[]) => void,
  initialResourceTypes?: string[];
}

const StaffingFormBasicResourceSelectorFilters: FunctionComponent<StaffingFormBasicResourceSelectorFiltersProps> = ({
  onSearchChange,
  initialResourceTypes
}) => {

  const [activityAreaOptions, setActivityAreaOptions] = useState<Option<string>[]>([]);
  const intl = useIntl()
  const initialSearchObject: ResourceStaffingSearchParams = {
    filterOnMatchingActivities: false,
    filterOnKids: false,
    filterOnPublicType: false,
    availability: ""
  }

  const [searchObject, setSearchObject] = useState<ResourceStaffingSearchParams>(initialSearchObject)

  const fetchResources = (searchObject: ResourceStaffingSearchParams) => {
    resourceStaffingService.getAllResourcesForStaffing(searchObject)
      .then(response => {
        response.sort((a, b) => {
          return a.firstName[0].localeCompare(b.firstName[0]);
        });
        onSearchChange(response)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_resources"}))
      })
  }

  const onSearchFieldUpdate = (field: string, value: string | boolean | string[]) => {
    setSearchObject((previousState) => {
      const updatedSearchObject: ResourceStaffingSearchParams = {...previousState, [field]: value}
      fetchResources(updatedSearchObject)
      return updatedSearchObject
    })
  }


  const getActivityAreaOptions = () => {
    activityAreaService.getActivityAreas()
      .then((activityAreas: ActivityArea[]) => {
        const activityAreaOptions: Option<string>[] = activityAreas.map((area) => ({ value: area.id, label: area.label }));
        setActivityAreaOptions(activityAreaOptions);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({ id: "error_toast_get_activity_area" }));
      });
  };

  useEffect(() => {
    getActivityAreaOptions()
    fetchResources(initialSearchObject)
  }, [])

  return (
    <>
      <div className="d-flex gap-2 mb-2">
        <TextFilter
          className="main-filter"
          value={searchObject?.nameSurname ?? ""}
          onChange={(value) => onSearchFieldUpdate("nameSurname", value)}
          title={intl.formatMessage({id: "resource_name_filter"})}
        />
        <SelectFilter
          options={activityAreaOptions.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={(value) => onSearchFieldUpdate("activityArea", value)}
          title={intl.formatMessage({id: "activity_zone"})}
          value={searchObject.activityArea}
          isClearable
        />
      </div>
      <div className="d-flex flex-row mb-1">
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypes ?? []}
          onChange={(values) => onSearchFieldUpdate("resourceTypes", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2 col"
          classNameLabel="mb-1 fw-bold"
          filterOption={(option: Option<string>) => initialResourceTypes === undefined || initialResourceTypes?.length == 0 || initialResourceTypes.includes(option.value)}
        />
      </div>
    </>
  )

}

export default StaffingFormBasicResourceSelectorFilters;
