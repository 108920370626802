import {TabPaneItem} from "../../../interfaces/TabInterfaces";
import React, {FunctionComponent, useState} from "react";
import TabPane from "../../atoms/TabPane";
import Dropdown from "../../atoms/input/Dropdown";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import {DropdownOption} from "../../../interfaces/inputs/OptionInterfaces";
import {useIntl} from "react-intl";

interface TabListProps {
  className?: string,
  items?: Array<TabPaneItem>
}

const TabList: FunctionComponent<TabListProps> = ({
  className = "",
  items
}) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<number>(0)
  const tabItemToDropdownOption = (tabItem: TabPaneItem, itemIndex: number) : DropdownOption => {
    return {
      id: tabItem.label,
      label: intl.formatMessage({id: tabItem.label}),
      onClick: () => setSelected(itemIndex)
    } as DropdownOption
  }

  return (
    <div>
      {items && (
        <>
          <div className={`d-none d-md-flex align-items-center mb-3 ${className}`} >
            <div className="epow-tablist d-flex flex-row">
              {items.map((item, index) =>
                <TabPane
                  key={index}
                  tab={item}
                  onClick={() => setSelected(index)}
                  active={index === selected}
                />
              )}
            </div>
          </div>
          <div className={`epow-tablist d-flex d-md-none align-items-center justify-content-center mb-3 ${className}`}>
            <Dropdown isToggle={false} id="tablist" options={items.map((item, index) => tabItemToDropdownOption(item, index))} >
              <div className="d-flex flex-row align-items-center">
                {intl.formatMessage({id: items.find((item, index) => index === selected).label})}
                <Icon className="ms-2" size={IconSizeType.XS} name="ChevronDown" />
              </div>
            </Dropdown>
          </div>

          {(items[selected] || items[0])?.component && React.cloneElement((items[selected] || items[0])?.component, {setSelected})}
        </>
      )}
    </div>
  )
}

export default TabList

