import * as Yup from "yup";
import {
  yupPositiveNumber,
  yupRequiredArray,
  yupRequiredBoolean,
  yupRequiredPositiveNumber,
  yupRequiredString,
} from "../yup/Validators";
import {AnyObject} from "yup/es/types";

export function CreateActivitySchema (isEdition?: boolean): Yup.ObjectSchema<AnyObject> {
  return (
    isEdition ? (
      Yup.object().shape({
        name: yupRequiredString,
        reference: yupRequiredString,
        billingName: yupRequiredString,
        duration: yupRequiredString,
        numberPaxMax: yupRequiredPositiveNumber,
        activityAreaId: yupRequiredString,
        staffingManagement: yupRequiredBoolean,
        price: yupRequiredPositiveNumber,
        unitType: yupRequiredString,
        showActivityTourLeader: yupRequiredBoolean,
      })
    ) : (
      Yup.object().shape({
        name: yupRequiredString,
        reference: yupRequiredString
      })
    )
  )
}

export const CreateActivityServicesSchema = Yup.object().shape({
  activityServicesId: yupRequiredArray(1)
})

export const CreateActivityCustomerSchema = Yup.object().shape({
  customerId: yupRequiredString,
  paxMaxCustomer: yupRequiredPositiveNumber,
  billingName: yupRequiredString,
  bookTicketTourLeader: yupRequiredBoolean,
})

export const CreateActivityResourceCustomerSchema = Yup.object().shape({
  startDateValidity: yupRequiredString,
  juniorEmployeeRate: yupPositiveNumber.nullable(),
  juniorIndependentRate: yupPositiveNumber.nullable(),
  seniorEmployeeRate: yupPositiveNumber.nullable(),
  seniorIndependentRate: yupPositiveNumber.nullable(),
})

export const CreateActivitySellingPriceCustomerSchema = Yup.object().shape({
  unitType: yupRequiredString,
  startDateValidity: yupRequiredString,
  sellingPrice: yupRequiredPositiveNumber
})
