import {Dictionary, groupBy} from "lodash";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import useListCheckboxSelector from "../../../../hooks/useListCheckboxSelector";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {SearchFilter} from "../../../../interfaces/api/FiltersInterface";
import {OrderItemStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {orderItemService} from "../../../../services/OrderItemService";
import StaffingTableBulkRequestButton from "./buttons/StaffingTableBulkRequestButton";
import TableHeader from "../order/TableHeader";
import StaffingTableRow from "./StaffingTableRow";
import Pagination from "../../pagination/Pagination";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";

interface StaffingTableProps {
  className?: string,
  searchObject: SearchFilter,
  selectedResourceTypes?: ResourceType[]
}

const StaffingTable: FunctionComponent<StaffingTableProps> = ({
  className = "",
  searchObject,
  selectedResourceTypes
}) => {

  const {
    data: orderItems,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
    refresh,
  } = usePaginatedData(orderItemService.getOrderItemsStaffingPage, searchObject)

  const {selected: selectedStaffing, handleSelect, handleSelectAll} = useListCheckboxSelector()

  const intl = useIntl()
  const ordersItemsByOrder: Dictionary<OrderItemStaffingPageItemDto[]> = groupBy(orderItems, "order.id")

  const headerProps = {
    sortOptions,
    onSortOptions: setSortOptions,
    className: "epow-staffing-table-header",
  }

  return (
    <div className={`epow-staffing-table ${className ?? ""}`}>
      <div className="epow-staffing-table-header-row">
        <TableHeader title={intl.formatMessage({id: "staffing_activity"})} accessor={"orderNumber"} {...headerProps}
          Cell={
            <>
              <input
                className="me-2"
                type="checkbox"
                checked={selectedStaffing.length > 0}
                onChange={() => handleSelectAll(orderItems.map(item => item.id))}
              />
              <span>{intl.formatMessage({id: "staffing_activity"})}</span>
            </>} />
        <TableHeader title={intl.formatMessage({id: "staffing_date_hour"})} accessor={"customerReference"} {...headerProps} />
        <TableHeader title={intl.formatMessage({id: "staffing_place"})} accessor={"rendezVousPlace"} {...headerProps} />
        <TableHeader title={intl.formatMessage({id: "staffing_resources"})} accessor={"tourLeader"} {...headerProps} />
        <TableHeader title={intl.formatMessage({id: "table_actions"})} accessor={"actions"} {...headerProps} />
      </div>

      {Object.entries(ordersItemsByOrder).map(([orderId, orderItems]) => (
        <StaffingTableRow
          key={orderId}
          order={orderItems[0].order}
          orderItems={orderItems}
          selectedStaffing={selectedStaffing}
          handleStaffingSelect={handleSelect}
          onStaffing={refresh}
        />
      ))}

      <StaffingTableBulkRequestButton onStaffing={refresh} className="ms-auto mb-1" selectedStaffing={selectedStaffing} orderItems={orderItems} selectedResourceTypes={selectedResourceTypes}/>

      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default StaffingTable;
