import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DashboardActivity} from "../../../../interfaces/ActivityInterfaces";
import {activityStaffingService} from "../../../../services/ActivityStaffingService";
import {toastUtils} from "../../../../utils/toastUtils";
import Activity from "../../dashboard/Activity";

interface StaffingValidationCardProps {
  className?: string,
  staffingToken: string,
}

const StaffingValidationCard: FunctionComponent<StaffingValidationCardProps> = ({
  className = "",
  staffingToken,
}) => {

  const intl = useIntl()
  const [activity, setActivity] = useState<DashboardActivity>(null)

  useEffect(() => {
    if (staffingToken) {
      activityStaffingService.validateStaffing(staffingToken)
        .then((response) => {
          const orderItem = response.orderItem;
          const orderItemActivity: DashboardActivity = {
            orderItemId: orderItem.id,
            rendezVousPlace: orderItem.rendezVousPlace,
            rendezVousDate: orderItem.rendezVousDate,
            rendezVousTime: orderItem.rendezVousHour,
            activityName: orderItem.name,
            clientName: orderItem.order.customerName,
            clientRef: orderItem.order.customerReference,
            orderRef: null,
          }
          setActivity(orderItemActivity)
          toastUtils.successToast(intl.formatMessage({id: "success_toast_staffing_validation"}))
        })
        .catch(() => {
          setActivity(null)
        })
    }
  }, [])

  if(!activity) {
    return (
      <div className={`d-flex flex-column mt-5 ${className}`}>

      <h5 className="mb-3">
        <FormattedMessage id="staffing_request_cancelled" />
      </h5>
    </div>
    )
  }

  return (
    <div className={`d-flex flex-column mt-5 ${className}`}>

      <h5 className="mb-3">
        <FormattedMessage id="staffing_request_validated" values={{name: activity?.activityName}} />
      </h5>

      <Activity activity={activity} />
    </div>
  )
}

export default StaffingValidationCard;
