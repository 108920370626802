import * as Yup from "yup"
import {yupRequiredPositiveNumber} from "../../yup/Validators"

export const AdminConfigurationSchema = Yup.object().shape({
  dashboardBookingPendingWindow: yupRequiredPositiveNumber,
  dashboardBookingTodoWindow: yupRequiredPositiveNumber,
  dashboardStaffingWindow: yupRequiredPositiveNumber,
  bookingPreBookingDelay: yupRequiredPositiveNumber,
  bookingServiceDelay: yupRequiredPositiveNumber,
  departureTimeMinuteDelta: yupRequiredPositiveNumber,
})
