import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Col, Row} from "reactstrap";
import {FileType} from "../../../../constants/FileConstants";
import {EditResourceLegalInfoSchema} from "../../../../constants/validation/ResourceValidationSchemas";
import {ResourceLegalInfo} from "../../../../interfaces/ResourceInterfaces";
import {resourceService} from "../../../../services/ResourceService";
import {dateUtils} from "../../../../utils/dateUtils";
import {fileUtils} from "../../../../utils/fileUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import FormFileUpload from "../../input/FormFileUpload";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";

interface EditResourceLegalInfoFormProps {
  className?: string;
  id?: string;
  initialResourceLegalInfo?: ResourceLegalInfo;
  setResourceAfterUpdate?: (resourceLegalInfo: ResourceLegalInfo) => void;
  setEditInfo?: (editInfo: boolean) => void;
  self: boolean
}

const EditResourceLegalInfoForm: FunctionComponent<EditResourceLegalInfoFormProps> = ({
  className = "",
  id,
  initialResourceLegalInfo,
  setResourceAfterUpdate,
  setEditInfo,
  self
}) => {
  const intl = useIntl();

  const updateResourceInfos = (resourceLegalInfo, liabilityCard, urssafCard) => {
    setResourceAfterUpdate({
      ...resourceLegalInfo,
      liabilityCard: liabilityCard ?? initialResourceLegalInfo.liabilityCard,
      urssafCard: urssafCard ?? initialResourceLegalInfo.urssafCard
    });
    setEditInfo(false);

    toastUtils.successToast(intl.formatMessage({id: "success_update_resource"}));
  }

  const handleSubmitResource = (submittedResourceLegalInfo: ResourceLegalInfo) => {
    resourceService.updateResourceLegalInfo(initialResourceLegalInfo.id, submittedResourceLegalInfo)
      .then(async (resourceLegalInfo: ResourceLegalInfo) => {
        let uploadedLiabilityCard = null;
        let uploadedUrssafCard = null;

        if (submittedResourceLegalInfo.liabilityCardFile) {
          uploadedLiabilityCard = await resourceService.uploadCard(
            initialResourceLegalInfo.id,
            submittedResourceLegalInfo.liabilityCardFile,
            FileType.LIABILITY_CARD
          )
        }

        if (submittedResourceLegalInfo.urssafCardFile) {
          uploadedUrssafCard = await resourceService.uploadCard(
            initialResourceLegalInfo.id,
            submittedResourceLegalInfo.urssafCardFile,
            FileType.URSSAF_CARD
          )
        }

        updateResourceInfos(resourceLegalInfo, uploadedLiabilityCard, uploadedUrssafCard);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_update_resource"}));
      })

  }

  const handleSubmitSelf = (submittedResourceLegalInfo: ResourceLegalInfo) => {
    resourceService.updateSelfResourceLegalInfo(submittedResourceLegalInfo)
      .then(async (resourceLegalInfo: ResourceLegalInfo) => {
        let uploadedLiabilityCard = null;
        let uploadedUrssafCard = null;

        if (submittedResourceLegalInfo.liabilityCardFile) {
          uploadedLiabilityCard = await resourceService.uploadSelfCard(submittedResourceLegalInfo.liabilityCardFile, FileType.LIABILITY_CARD)
        }

        if (submittedResourceLegalInfo.urssafCardFile) {
          uploadedUrssafCard = await resourceService.uploadSelfCard(submittedResourceLegalInfo.urssafCardFile, FileType.URSSAF_CARD)
        }

        updateResourceInfos(resourceLegalInfo, uploadedLiabilityCard, uploadedUrssafCard);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_update_resource"}));
      })

  }

  const getFormattedDate = (dateValue: string) => {
    return dateValue ? dateUtils.formatDateYYYYMMDD(new Date(dateValue)) : null;
  }

  return (
    <>
      <Formik
        initialValues={initialResourceLegalInfo}
        validationSchema={() => EditResourceLegalInfoSchema()}
        onSubmit={(value) => self ? handleSubmitSelf(value) : handleSubmitResource(value)}
      >
        {({errors, touched, values, setFieldValue}) => (
          <EpowForm id={id} className={className}>
            <Row>
              <Col xs={24} md={12} xl={8}>
                <FormFileUpload
                  id="liabilityCardFile"
                  label="resource_public_liabiliy_card_field"
                  file={values?.liabilityCardFile}
                  fileName={fileUtils.getCompleteFileName(initialResourceLegalInfo?.liabilityCard)}
                  required
                  accept={[".jpg", ".jpeg", ".pdf"]}
                  onChange={(file: File) => setFieldValue("liabilityCardFile", file)}
                  error={errors.liabilityCardFile}
                  touched={touched.liabilityCardFile}
                />

                <FormInput
                  id="rcpCoverStartDate"
                  label="resource_rcp_cover_start_date"
                  type="date"
                  required
                  value={getFormattedDate(values?.rcpCoverStartDate)}
                  onChange={(e) => setFieldValue("rcpCoverStartDate", e.target?.value)}
                  error={errors.rcpCoverStartDate}
                  touched={touched.rcpCoverStartDate}
                />

                <FormInput
                  id="rcpValidityDate"
                  label="resource_rcp_validity_date"
                  type="date"
                  required
                  value={getFormattedDate(values?.rcpValidityDate)}
                  onChange={(e) => setFieldValue("rcpValidityDate", e.target?.value)}
                  error={errors.rcpValidityDate}
                  touched={touched.rcpValidityDate}
                />
              </Col>

              <Col xs={24} md={12} xl={8}>
                <FormFileUpload
                  id="urssafCard"
                  label="resource_urssaf_card"
                  file={values?.urssafCardFile}
                  fileName={fileUtils.getCompleteFileName(initialResourceLegalInfo?.urssafCard)}
                  required
                  accept={[".jpg", ".jpeg", ".pdf"]}
                  onChange={(file: File) => setFieldValue("urssafCardFile", file)}
                  error={errors?.urssafCardFile}
                  touched={touched?.urssafCardFile}
                />

                <FormInput
                  id="urssafSituationDate"
                  label="resource_urssaf_situation_date"
                  type="date"
                  required
                  value={getFormattedDate(values?.urssafSituationDate)}
                  onChange={(e) => setFieldValue("urssafSituationDate", e.target?.value)}
                  error={errors.urssafSituationDate}
                  touched={touched.urssafSituationDate}
                />
              </Col>
            </Row>
          </EpowForm>
        )}
      </Formik>
    </>
  )
}

export default EditResourceLegalInfoForm;

