import React, {FunctionComponent, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {Formik} from "formik";
import EpowForm from "../EpowForm";
import FormInput from "../../input/FormInput";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {toastUtils} from "../../../../utils/toastUtils";
import {
  ActivityResourceRequirement,
  ActivityResourceRequirementForm
} from "../../../../interfaces/ActivityResourceRequirementInterfaces";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import FormSelect from "../../input/FormSelect";
import {numberUtils} from "../../../../utils/numberUtils";
import {activityResourceRequirementService} from "../../../../services/ActivityResourceRequirementService";
import {UpsertRequirementSchema} from "../../../../constants/validation/ActivityResourceRequiredSchemas";

interface UpsertActivityResourceRequirementFormProps {
  id?: string;
  activityId?: string;
  className?: string;
  initialRequirement?: ActivityResourceRequirement;
  onValidate: (requirement: ActivityResourceRequirement) => void;
}

const UpsertActivityResourceRequirementForm: FunctionComponent<UpsertActivityResourceRequirementFormProps> = ({
  id,
  activityId,
  className = "",
  initialRequirement,
  onValidate,
}) => {
  const [resourceTypes, setResourceTypes] = useState<Option<string>[]>([])
  const intl = useIntl()

  useMemo(() => {
    resourceTypeService.getResourceTypes()
      .then((resourceTypes) => {
        setResourceTypes(resourceTypes.map((type) => { return { value: type.id, label: type.label }}))
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_fetch_resource_types"}));
    })}, []
  )

  const requirementForm: ActivityResourceRequirementForm = {
    quantity: initialRequirement?.quantity,
    unitPrice: initialRequirement?.unitPrice,
    resourceTypeId: initialRequirement?.resourceType.id
  }

  const upsertRequirement = (request: ActivityResourceRequirementForm) => {
    if (initialRequirement?.id) {
      activityResourceRequirementService.updateRequirementForActivity(initialRequirement?.id, activityId, request)
        .then((requirement: ActivityResourceRequirement) => {
          onValidate(requirement);
          toastUtils.successToast(intl.formatMessage({id: "activity_resource_requirement_edit_success"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "activity_resource_requirement_edit_error"}));
        });
    } else {
      activityResourceRequirementService.createRequirementForActivity(activityId, request)
        .then((requirement: ActivityResourceRequirement) => {
          onValidate(requirement);
          toastUtils.successToast(intl.formatMessage({id: "activity_resource_requirement_create_success"}));
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "activity_resource_requirement_create_error"}));
        });
    }
  };

  return (
    <Formik
      initialValues={requirementForm}
      validationSchema={UpsertRequirementSchema}
      onSubmit={(value) => {
        upsertRequirement(value);
      }}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormSelect
            id="resourceType"
            label="resource_type"
            value={resourceTypes.find((option) => option?.value === values?.resourceTypeId)}
            onChange={(option?: Option<string>) => setFieldValue("resourceTypeId", option?.value)}
            options={resourceTypes}
            required
            isClearable
            error={errors.resourceTypeId}
            touched={touched.resourceTypeId}
          />
          <FormInput
            id="quantity"
            label="activity_resource_requirement_quantity"
            value={values?.quantity}
            type="number"
            minValue={1}
            onChange={(e) => setFieldValue("quantity", e.target.value)}
            required
            error={errors.quantity}
            touched={touched.quantity}
          />
          <FormInput
            id="unitPrice"
            label="activity_resource_requirement_unit_price"
            value={values?.unitPrice}
            type="number"
            minValue={0}
            onChange={(e) => setFieldValue("unitPrice", e.target.value)}
            onBlur={(e) => setFieldValue("unitPrice", numberUtils.numberFormatField(e.target.value))}
            step="0.01"
            required
            error={errors.unitPrice}
            touched={touched.unitPrice}
          />
        </EpowForm>
      )}
    </Formik>
  );
}

export default UpsertActivityResourceRequirementForm;
