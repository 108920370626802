import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Column} from "react-table";
import {Contact, Identity} from "../../../../interfaces/ContactInterfaces";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import TextLink from "../../../atoms/TextLink";
import Icon from "../../../icon/Icon";
import Table from "../Table";

interface ContactTableProps {
  className?: string,
  data: Contact[],
  handleUpdateAccess: (contact: Contact) => void,
  handleUpdateMainContact: (contact: Contact) => void,
  handleEditContact: (contact: Contact) => void,
  handleDeleteContact: (contact: Contact) => void,
}

const ContactTable: FunctionComponent<ContactTableProps> = ({
  className = "",
  data,
  handleUpdateAccess,
  handleUpdateMainContact,
  handleEditContact,
  handleDeleteContact,
}) => {
  const intl = useIntl();

  const columns: Column[] = [
    {
      Header: intl.formatMessage({id: "contact_table_name"}),
      accessor: "name",
      Cell: ({row}: {row: {original: Contact}}) => {
        const identity: Identity = row.original.identity
        return <span>
          {identity?.firstName} {identity?.lastName} {row.original.isMainContact && <Icon name="StarFill" size={IconSizeType.XS} className={"pe-2"} />}
        </span>
      }
    },
    {
      Header: intl.formatMessage({id: "contact_table_job"}),
      accessor: "identity.job"
    },
    {
      Header: intl.formatMessage({id: "contact_table_phone"}),
      accessor: "identity.phoneNumber",
      Cell: ({row}: {row: {original: Contact}}) => {
        return <TextLink to={`tel:${row.original.identity.phoneNumber}`} external><>{row.original.identity.phoneNumber}</></TextLink>
      },
    },
    {
      Header: intl.formatMessage({id: "contact_table_email"}),
      accessor: "identity.email",
      Cell: ({row}: {row: {original: Contact}}) => {
        return <TextLink to={`mailto:${row.original.identity.email}`} external><>{row.original.identity.email}</></TextLink>
      },
    },
    {
      Header: intl.formatMessage({id: "contact_table_platform_access"}),
      accessor: (contact?: Contact) => {return contact?.hasAccessToPlatform ? "Oui" : "Non"}
    },
    {
      Header: "",
      accessor: "actions",
      width: "15%",
      Cell: ({row}: {row: {original: Contact}}) => {
        const contact: Contact = row?.original
        return <div className="float-end">
          {!contact?.hasAccessToPlatform && <Icon name="UserPlus" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleUpdateAccess(contact)} />}
          {!row.original.isMainContact && <Icon name="Star" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleUpdateMainContact(contact)} />}
          <Icon name="Pen" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleEditContact(contact)} />
          {!row.original.isMainContact && <Icon name="Trash" size={IconSizeType.XS} className={"pe-2 cursor-pointer"} onClick={() => handleDeleteContact(contact)} />}
        </div>
      },
    }
  ]

  return (
    <Table
      columns={columns}
      data={data}
      labelNoResult="contact_no_result"
      className={`${className} table`}
    />
  )
}

export default ContactTable;
