import { API_VAT_RATE_PATH } from "../constants/routes/RoutePaths"
import {PageResponse} from "../interfaces/api/PaginationInterface";
import { VATRate } from "../interfaces/VATRateInterfaces";
import { httpService } from "./HttpService"

const getVATRates = (): Promise<PageResponse<VATRate>> => {
  return httpService.get<PageResponse<VATRate>>(API_VAT_RATE_PATH);
}

const createVATRate = (vatRate: VATRate): Promise<VATRate> => {
  return httpService.post<VATRate>(API_VAT_RATE_PATH, vatRate);
}

const updateVATRate = (vatRate: VATRate): Promise<VATRate> => {
  return httpService.put<VATRate>(`${API_VAT_RATE_PATH}/${vatRate.id}`, vatRate);
}

const deleteVATRate = (id: string): Promise<Response> => {
  return httpService.delete(`${API_VAT_RATE_PATH}/${id}`);
}

export const vatRateService = {
  getVATRates,
  createVATRate,
  updateVATRate,
  deleteVATRate
}
