import React, {FunctionComponent} from "react";
import {Formik} from "formik";
import {CreateSeasonSchema} from "../../../constants/validation/TourValidationSchemas";
import FormInput from "../input/FormInput";
import {toastUtils} from "../../../utils/toastUtils";
import {useIntl} from "react-intl";
import EpowForm from "./EpowForm";
import {SeasonCreationFormRequest, SeasonCreationRequest} from "../../../interfaces/SeasonInterfaces";
import {seasonService} from "../../../services/SeasonService";
import {dateUtils} from "../../../utils/dateUtils";

interface CreateSeasonFormProps {
  id?: string,
  className?: string,
  tourId?: string,
  onValidate?: () => void
}

const CreateSeasonForm: FunctionComponent<CreateSeasonFormProps> = ({
  id,
  className = "",
  tourId,
  onValidate = () => null,
}) => {
  const intl = useIntl();
  const initialSeason: SeasonCreationFormRequest = {
    name: "",
    startDate: dateUtils.formatDateYYYYMMDD()
  }

  const createSeason = (season: SeasonCreationFormRequest) => {
    const request: SeasonCreationRequest = {
      name: season.name,
      startDate: dateUtils.parseDate(season.startDate),
      tourId
    }
    seasonService.createSeasonForTour(tourId, request)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "season_creation_success"}))
        onValidate()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "season_creation_failure"}))
      })
  }

  return (
    <Formik initialValues={initialSeason} validationSchema={CreateSeasonSchema} onSubmit={(value) => createSeason(value)} >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={`m-4 ${className}`}>
          <FormInput
            id="name"
            label="season_form_name"
            value={values?.name}
            onChange={(e) => setFieldValue("name", e.target.value)}
            required
            error={errors.name}
            touched={touched.name}
          />
          <FormInput
            id="startDate"
            label="season_form_start_date"
            type="date"
            required
            value={values?.startDate}
            onChange={(e) => setFieldValue("startDate", e.target?.value)}
            error={errors?.startDate}
            touched={touched?.startDate}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateSeasonForm
