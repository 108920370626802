import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {BANK_ACCOUNT_INITIAL_VALUES} from "../../../constants/BankAccountConstants";
import {CreateBankAccountSchema} from "../../../constants/validation/BankAccountSchema";
import {BankAccount} from "../../../interfaces/BankAccountInterface";
import {bankAccountService} from "../../../services/BankAccountService";
import {toastUtils} from "../../../utils/toastUtils";
import FormInput from "../input/FormInput";
import EpowForm from "./EpowForm";

interface CreateBankAccountFormProps {
  className?: string;
  id?: string;
  supplierId?: string;
  resourceId?: string;
  initialBankAccount?: BankAccount;
  onCreate?: (bankAccount: BankAccount) => void;
  onUpdate?: (bankAccount: BankAccount) => void;
}

const CreateBankAccountForm: FunctionComponent<CreateBankAccountFormProps> = ({
  className = "",
  id,
  supplierId,
  resourceId,
  initialBankAccount,
  onCreate = () => null,
  onUpdate = () => null
}) => {
  const intl = useIntl();

  const handleSubmitResourceBankAccount = (
    bankAccountSubmitted: BankAccount
  ) => {
    if (initialBankAccount) {
      bankAccountService
        .updateBankAccount(
          initialBankAccount.id,
          bankAccountSubmitted
        )
        .then((bankAccount: BankAccount) => {
          onUpdate(bankAccount);

          toastUtils.successToast(
            intl.formatMessage({ id: "success_update_bank_account" })
          );
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_update_bank_account" })
          );
        });
    } else {
      bankAccountService
        .createBankAccount({...bankAccountSubmitted, resourceId, supplierId})
        .then((bankAccount: BankAccount) => {
          onCreate(bankAccount);

          toastUtils.successToast(
            intl.formatMessage({ id: "success_create_bank_account" })
          );
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_create_bank_account" })
          );
        });
    }
  };

  return (
    <Formik
      initialValues={initialBankAccount || BANK_ACCOUNT_INITIAL_VALUES}
      validationSchema={CreateBankAccountSchema}
      onSubmit={(values) => {
        handleSubmitResourceBankAccount(values);
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="accountName"
            label="bank_account_name"
            value={values?.accountName}
            onChange={(e) => setFieldValue("accountName", e.target.value)}
            required
            error={errors.accountName}
            touched={touched.accountName}
          />
          <FormInput
            id="bankName"
            label="bank_name"
            value={values?.bankName}
            onChange={(e) => setFieldValue("bankName", e.target.value)}
            required
            error={errors.bankName}
            touched={touched.bankName}
          />
          <div className="d-flex">
            <FormInput
              className="col-6 pe-1"
              id="bankCode"
              label="bank_code"
              value={values?.bankCode}
              onChange={(e) => setFieldValue("bankCode", e.target.value)}
              required
              error={errors.bankCode}
              touched={touched.bankCode}
            />
            <FormInput
              className="col-6 px-1"
              id="counterCode"
              label="bank_counter_code"
              value={values?.counterCode}
              onChange={(e) => setFieldValue("counterCode", e.target.value)}
              required
              error={errors.counterCode}
              touched={touched.counterCode}
            />
            <FormInput
              className="col-8 px-1"
              id="accountNumber"
              label="bank_account_number"
              value={values?.accountNumber}
              onChange={(e) => setFieldValue("accountNumber", e.target.value)}
              required
              error={errors.accountNumber}
              touched={touched.accountNumber}
            />
            <FormInput
              className="col-4 ps-1"
              id="ribKey"
              label="bank_rib_key"
              value={values?.ribKey}
              onChange={(e) => setFieldValue("ribKey", e.target.value)}
              required
              error={errors.ribKey}
              touched={touched.ribKey}
            />
          </div>
          <FormInput
            id="ibanCode"
            label="bank_iban_code"
            value={values?.ibanCode}
            onChange={(e) => setFieldValue("ibanCode", e.target.value)}
            required
            error={errors.ibanCode}
            touched={touched.ibanCode}
          />
          <FormInput
            id="bicCode"
            label="bank_bic_code"
            value={values?.bicCode}
            onChange={(e) => setFieldValue("bicCode", e.target.value)}
            required
            error={errors.bicCode}
            touched={touched.bicCode}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreateBankAccountForm;