import React, {FunctionComponent, useEffect} from "react";
import {RouteProps} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import StaffingRequestCollapse from "../../components/molecules/collapse/StaffingRequestCollapse";
import {dateUtils} from "../../utils/dateUtils";
import {ActivityStaffingStatus} from "../../constants/ActivityStaffingConstants";
import {toastUtils} from "../../utils/toastUtils";
import {useIntl} from "react-intl";
import {activityStaffingService} from "../../services/ActivityStaffingService";
import PageTitle from "../../components/molecules/header/PageTitle";

const StaffingRequestView: FunctionComponent<RouteProps> = () => {

  const intl = useIntl();

  const [newRequests, setNewRequests] = React.useState([]);
  const [awaitingRequests, setAwaitingRequests] = React.useState([]);
  const [confirmedRequests, setConfirmedRequests] = React.useState([]);

  const getStaffingRequest = () => {
    activityStaffingService.getOrderItemsStaffingRequest({page: 0, pageSize: 500},
      {
        startDate: dateUtils.formatDateYYYYMMDD(new Date()),
      }
    ).then((response) => {
      const activities = response.content.map((item) => ({
        orderItemId: item.id,
        rendezVousPlace: item.rendezVousPlace,
        rendezVousDate: item.rendezVousDate,
        rendezVousTime: item.rendezVousHour,
        activityName: item.name,
        clientName: item.order.customerName,
        clientRef: item.order.customerReference,
        orderRef: item.order.orderNumber,
        activityStaffingStatus: item.activityStaffingStatus,
        activityStaffingId: item.activityStaffingId,
      }))

      activities.sort((a, b) => {
        return new Date(`${a.rendezVousDate} ${a.rendezVousTime}`) >= new Date(`${b.rendezVousDate} ${b.rendezVousTime}`) ? 1 : -1
      });

      const reducedRequests = {newRequests: [], awaitingRequests: [], confirmedRequests: []}
      activities.forEach(activity => {
          if (activity.activityStaffingStatus === ActivityStaffingStatus.SENT) {
            reducedRequests.newRequests.push(activity)
          } else if (activity.activityStaffingStatus === ActivityStaffingStatus.AVAILABLE) {
            reducedRequests.awaitingRequests.push(activity)
          } else if (activity.activityStaffingStatus === ActivityStaffingStatus.CONFIRMED) {
            reducedRequests.confirmedRequests.push(activity)
          }
        }
      )

      setNewRequests(reducedRequests.newRequests)
      setAwaitingRequests(reducedRequests.awaitingRequests)
      setConfirmedRequests(reducedRequests.confirmedRequests)

    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request_get"}))
    })

  }

  useEffect(() => {
    getStaffingRequest()
  }, []);

  const onConfirm = (activityStaffingId: string) => {
    activityStaffingService.availableOrderItemsStaffingRequest(activityStaffingId)
      .then(() => {
        getStaffingRequest()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      })
  }

  const onRefuse = (activityStaffingId: string) => {
    activityStaffingService.refuseOrderItemsStaffingRequest(activityStaffingId)
      .then(() => {
        getStaffingRequest()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_staffing_request"}))
      })
  }

  return (<Layout>
      <div className="epow-content-body">
        <PageTitle className="mb-3" pageTitle={intl.formatMessage({id: "staffing_request_title"})}></PageTitle>
        <StaffingRequestCollapse
          onConfirm={onConfirm}
          onRefuse={onRefuse}
          className="mt-2"
          title="staffing_request_new_request_title"
          requests={newRequests}
          open={true}
        />
        <StaffingRequestCollapse
          className="mt-2"
          title="staffing_request_awaiting_request_title"
          requests={awaitingRequests}
          open={false}
        />
        <StaffingRequestCollapse
          className="mt-2"
          title="staffing_request_confirmed_request_title"
          requests={confirmedRequests}
          open={false}
        />
      </div>
    </Layout>
  )
}

export default StaffingRequestView;
