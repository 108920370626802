import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import { BankAccount } from "../../../interfaces/BankAccountInterface";
import Icon from "../../icon/Icon";
import BankAccountItem from "../bank-account/BankAccountItem";

interface ResourceBankAccountListProps {
  className?: string;
  resourceBankAccounts?: BankAccount[];
  resourceId?: string;
  onUpdate?: (bankAccount: BankAccount) => void;
  onDelete?: (bankAccountId: string) => void;
  onUpdateDefaultBankAccount?: (bankAccountId: string) => void;
}

const ResourceBankAccountList: FunctionComponent<ResourceBankAccountListProps> = ({
  className = "",
  resourceBankAccounts,
  resourceId,
  onUpdate = () => null,
  onDelete = () => null,
  onUpdateDefaultBankAccount = () => null
}) => {
  return (
    <div className={className}>
      {resourceBankAccounts.length > 0 ? (
        resourceBankAccounts.map((resourceBankAccount, index) => (
          <BankAccountItem
            key={index}
            bankAccount={resourceBankAccount}
            resourceId={resourceId}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onUpdateDefaultBankAccount={onUpdateDefaultBankAccount}
          />
        ))
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2" />
          <FormattedMessage id="bank_account_not_found" />
        </Label>
      )}
    </div>
  )
}

export default ResourceBankAccountList;
