import * as Yup from "yup";
import {getRequiredBooleanForCondition, yupRequiredBoolean, yupRequiredNumber, yupRequiredString} from "../yup/Validators";
import {ORDER_ITEM_TYPE} from "../../interfaces/OrderItemInterfaces";


const isActivity = (type: string) => type === ORDER_ITEM_TYPE.ACTIVITY.toString();

export const QuoteItemCreationFirstStepSchema = Yup.object().shape({
  type: yupRequiredString,
  name: yupRequiredString,
  rendezVousDate: yupRequiredString,
  rendezVousHour: yupRequiredString,
  rendezVousPlace: yupRequiredString,
  optional: getRequiredBooleanForCondition("type", isActivity),
});



export const CreateQuoteItemSchema = QuoteItemCreationFirstStepSchema.shape({
  type: yupRequiredString,
  name: yupRequiredString,
  rendezVousDate: yupRequiredString,
  rendezVousHour: yupRequiredString,
  rendezVousPlace: yupRequiredString,
  paxPrice: yupRequiredNumber,
  optional: getRequiredBooleanForCondition("type", isActivity),
  unitType: yupRequiredString,
  pax: yupRequiredNumber,
  quantity: yupRequiredNumber,
  sellPrice: yupRequiredNumber,
  sellPriceTVA: yupRequiredNumber,
  disbursements: yupRequiredBoolean,
});
