import React, {FunctionComponent} from "react";
import {PurchaseBooking} from "../../../../interfaces/PurchaseInterfaces";
import {Col, Row} from "reactstrap";
import FieldLabel from "../../../atoms/FieldLabel";
import {dateUtils} from "../../../../utils/dateUtils";
import {useIntl} from "react-intl";
import Badge from "../../../atoms/Badge";
import {optionUtils} from "../../../../utils/optionUtils";
import {YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {numberUtils} from "../../../../utils/numberUtils";


interface PurchaseBookingDetailsProps {
  className?: string,
  purchaseBooking: PurchaseBooking,
}

const PurchaseBookingDetails: FunctionComponent<PurchaseBookingDetailsProps> = ({
  className= "",
  purchaseBooking
}) => {
  const intl = useIntl()

  return (
    <div className={className}>
      <Row>
        <Col xs={24} md={12}>
          <FieldLabel
            label={intl.formatMessage({id: "purchase_order_date"})}
            value={`${dateUtils.dateToLocaleDate(dateUtils.parseDate(purchaseBooking?.date))} - ${dateUtils.formatLocalTime(purchaseBooking?.hour)}`}
          />
          <FieldLabel label={intl.formatMessage({id: "purchase_booking_pax"})} value={purchaseBooking?.pax} />
          <FieldLabel label={intl.formatMessage({id: "purchase_booking_comment"})} value={purchaseBooking?.comment?.content} />
        </Col>
        <Col xs={24} md={9}>
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_disbursement"})}
            value={optionUtils.translateOption(intl, YES_NO_OPTIONS, purchaseBooking?.disbursement)}
          />
          <FieldLabel label={intl.formatMessage({id: "purchase_booking_number"})} value={purchaseBooking?.reference} />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_confirmation_date"})}
            value={dateUtils.dateToLocaleDate(dateUtils.parseDate(purchaseBooking?.confirmationBookingDate))}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_payment_alert_date"})}
            value={dateUtils.dateToLocaleDate(dateUtils.parseDate(purchaseBooking?.paymentAlertDate))}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_billed"})}
            value={optionUtils.translateOption(intl, YES_NO_OPTIONS, purchaseBooking?.isBilled)}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_invoice_date"})}
            value={dateUtils.dateToLocaleDate(dateUtils.parseDate(purchaseBooking?.invoiceDate))}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_total_HT"})}
            value={numberUtils.formatPrice(purchaseBooking?.totalExcludingVAT)}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_vat"})}
            value={numberUtils.numberToPercent(purchaseBooking?.vat)}
          />
          <FieldLabel
            label={intl.formatMessage({id: "purchase_booking_total_TTC"})}
            value={numberUtils.formatPrice(purchaseBooking?.totalPrice)}
          />
        </Col>
        <Col xs={24} md={3}>
          <Badge pill className="float-md-end">{intl.formatMessage({id: purchaseBooking?.status ?? "STATUS_DEFAULT"})}</Badge>
        </Col>
      </Row>
    </div>
  )
}

export default PurchaseBookingDetails
