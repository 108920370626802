import React, {ChangeEventHandler, FunctionComponent} from "react";
import {EditorProvider, Editor, Toolbar, BtnUndo, BtnRedo, BtnBold, BtnItalic, BtnUnderline, BtnNumberedList, BtnBulletList, BtnLink, BtnClearFormatting, HtmlButton, BtnStyles, Separator} from "react-simple-wysiwyg";
import EditorColorPicker from "./EditorColorPicker";
import BtnHighlight from "./BtnHighlight";


interface CustomEditorProps {
  value?: string,
  onChange?: ChangeEventHandler<HTMLInputElement>,
}

const CustomEditor: FunctionComponent<CustomEditorProps> = ({
  value,
  onChange,
}) => {
  return (
    <EditorProvider>
      <Editor value={value} onChange={onChange}>
        <Toolbar>
          <BtnUndo />
          <BtnRedo />
          <Separator />
          <BtnBold />
          <BtnItalic />
          <BtnUnderline />
          <Separator />
          <EditorColorPicker />
          <BtnHighlight />
          <Separator />
          <BtnNumberedList />
          <BtnBulletList />
          <Separator />
          <BtnLink />
          <BtnClearFormatting />
          <HtmlButton />
          <Separator />
          <BtnStyles />
        </Toolbar>
      </Editor>
    </EditorProvider>
  )
}

export default CustomEditor;
