import {API_CATALOG_CUSTOMER_SERVICES_PATH} from "../constants/routes/RoutePaths"
import {dateUtils} from "../utils/dateUtils"
import {httpService} from "./HttpService"

function getPriceForCustomerService(serviceId: string, customerId: string, date: string): Promise<number> {
  return httpService.get(`${API_CATALOG_CUSTOMER_SERVICES_PATH}/${serviceId}/${customerId}/price?date=${dateUtils.formatDateYYYYMMDD(dateUtils.parseDate(date))}`)
}

export const catalogCustomerServiceService = Object.freeze({
  getPriceForCustomerService,
})