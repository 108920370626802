import React, {FunctionComponent} from "react";
import {RouteProps} from "react-router-dom";
import {useIntl} from "react-intl";
import Layout from "../components/layout/Layout";
import PageTitle from "../components/molecules/header/PageTitle";
import ExternalProfileDocumentList from "../components/molecules/list/ExternalProfileDocumentList";

const DocumentaryBaseView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()

  return (
    <Layout>
      <div className="epow-content-body">
        <PageTitle pageTitle={intl.formatMessage({id: "documentary_base_title"})} />
        <div className="d-flex w-100 mt-5">
          <ExternalProfileDocumentList />
        </div>
      </div>
    </Layout>
  )
}

export default DocumentaryBaseView
