import React, {FunctionComponent, useEffect, useState} from "react";
import {RouteProps, useNavigate, useParams} from "react-router-dom";
import Layout from "../components/layout/Layout";
import {Tour} from "interfaces/TourInterfaces";
import {tourService} from "services/TourService";
import {toastUtils} from "utils/toastUtils";
import TabList from "../components/molecules/tab/TabList";
import {tourTab} from "../constants/tabs/TabListConstants";
import {FormattedMessage, useIntl} from "react-intl";
import {TOURS_PATH} from "../constants/routes/RoutePaths";
import {WorkflowActionsEnum, WorkflowStateMap, WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import {Customer} from "../interfaces/CustomerInterfaces";
import Badge from "../components/atoms/Badge";
import WorkflowButtonList from "../components/molecules/workflow/WorkflowButtonList";
import PageTitle from "../components/molecules/header/PageTitle";

const TourView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const {id} = useParams();

  const [tour, setTour] = useState<Tour>({})

  const getTour = () => {
    tourService.getTour(id)
      .then((tour) => {
        setTour(tour)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_one_tour"}))
      })
  }

  const handleDeleteButton = () => {
    tourService.deleteTour(tour.id).then(() => {
      toastUtils.infoToast(intl.formatMessage({id: "workflow_delete_info"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
      navigate(TOURS_PATH)
    }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_delete_failure"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
    })
  }

  const handleActivateButton = () => {
    tourService.updateTour(tour.id, {...tour, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Tour) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_validate_info"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
        setTour(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_validate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
    })
  }

  const handleDeactivateButton = () => {
    tourService.updateTour(tour.id, {...tour, currentState: WorkflowStatesEnum.INACTIVE})
      .then((response: Customer) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_deactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
        setTour(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_deactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
    })
  }

  const handleReactivateButton = () => {
    tourService.updateTour(tour.id, {...tour, currentState: WorkflowStatesEnum.ACTIVE})
      .then((response: Customer) => {
        toastUtils.infoToast(intl.formatMessage({id: "workflow_reactivate_info"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
        setTour(response)
      }).catch(() => {
      toastUtils.errorToast(intl.formatMessage({id: "workflow_reactivate_failure"}, { entity: intl.formatMessage({id: "workflow_entity_tour"}) }))
    })
  }

  useEffect(() => {
    getTour()
  }, [])

  return (
    <Layout>
      {tour && (
        <div className="epow-content-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <PageTitle
                pageTitle={tour.tourName}
                className="me-4"
                breadcrumbItems={[{
                  title: intl.formatMessage({id: "tour_title"}),
                  to: TOURS_PATH
                }]}
              />
              <span className="me-1 text-muted"><FormattedMessage id="status" /> :</span>
              <Badge pill color={WorkflowStateMap[tour.currentState]?.color} className="ms-2">
                {intl.formatMessage({id: WorkflowStateMap[tour.currentState]?.label || "STATUS_DEFAULT"})}
              </Badge>
            </div>
            <WorkflowButtonList
              currentState={tour.currentState}
              entityLabel={intl.formatMessage({id: "workflow_entity_tour"})}
              actions={{
                [WorkflowActionsEnum.DELETE]: handleDeleteButton,
                [WorkflowActionsEnum.ACTIVATE]: handleActivateButton,
                [WorkflowActionsEnum.DEACTIVATE]: handleDeactivateButton,
                [WorkflowActionsEnum.REACTIVATE]: handleReactivateButton
              }}
            />
          </div>
          <TabList items={tourTab(tour, getTour)}/>
        </div>
      )}
    </Layout>
  )
}

export default TourView
