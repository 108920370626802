import React from "react";
import Icon from "../components/icon/Icon";
import {toast} from "react-toastify";
import {IconSizeType} from "../types/bootstrap/BootstrapType";
import ToastContent from "../components/atoms/toast/ToastContent";
import {ToastOptions} from "react-toastify/dist/types";

const commonOptions: ToastOptions = {
    autoClose: 5000,
    hideProgressBar: true
};

function successToast(content: string): React.ReactText {
    return toast.success(<ToastContent message={content}/>, {
        ...commonOptions,
        icon: <Icon name="Check" size={IconSizeType.MD}/>,
        className: "pow-toast-success"
    });
}

function warningToast(content: string): React.ReactText {
    return toast.warning(<ToastContent message={content}/>, {
        ...commonOptions,
        icon: <Icon name="QuestionCircle" size={IconSizeType.MD}/>,
        className: "pow-toast-warning"
    });
}

function infoToast(content: string): React.ReactText {
    return toast.info(<ToastContent message={content}/>, {
        ...commonOptions,
        icon: <Icon name="InfoCircle" size={IconSizeType.MD}/>,
        className: "pow-info-toast"
    });
}

function errorToast(content: string): React.ReactText {
    return toast.error(<ToastContent message={content}/>, {
        ...commonOptions,
        icon: <Icon name="Cross" size={IconSizeType.MD}/>,
        className: "pow-error-toast"
    });
}

export const toastUtils = Object.freeze({
    errorToast,
    infoToast,
    successToast,
    warningToast,
})
