import React, {FunctionComponent} from "react";
import Icon from "../icon/Icon";
import {IconName} from "../icon/IconsMap";

interface IconLabelCardProps {
  iconName: IconName;
  label: string;
  hasBackground?: boolean;
  className?: string;
}

const IconLabelCard: FunctionComponent<IconLabelCardProps> = ({
  iconName,
  label,
  hasBackground = false,
  className
}) => {

  return (
    <div className={`${className} d-flex align-items-center p-2 ${hasBackground ? "bg-theme-bg-light" : ""}`}>
      <Icon name={iconName} className="me-2 color-blue-gray"></Icon>
      <span>{label}</span>
    </div>
  )
};

export default IconLabelCard
