import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";
import {Data} from "../../../interfaces/TableInterfaces";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {useIntl} from "react-intl";
import _, {debounce} from "lodash";
import Select, {ActionMeta, InputActionMeta} from "react-select";
import {Label} from "reactstrap";

interface AsyncMultiselectFilterProps {
  className?: string,
  title?: string;
  placeholder?: string;
  values?: string[];
  onChange: (ids: string[]) => void;
  fetchData: (filter?: SearchFilter) => Promise<PageResponse<Data>>,
  filterFieldName: string,
  labelFieldName?: string,
  id?: string,
  labelJoiner?: string,
  entityFilterName?: string,
  closeMenuOnSelect?: boolean,
}

const AsyncMultiselectFilter: FunctionComponent<AsyncMultiselectFilterProps> = ({
  className = "",
  title,
  placeholder,
  values,
  onChange,
  fetchData,
  filterFieldName,
  labelFieldName = filterFieldName,
  entityFilterName = "ids",
  id,
  labelJoiner = " ",
  closeMenuOnSelect = true,
}) => {
  const intl = useIntl()
  const [selectedOptions, setSelectedOptions] = useState<Option<string>[]>([])
  const [fetchedOptions, setFetchedOptions] = useState<Option<string>[]>([])
  const [currentInput, setCurrentInput] = useState<string>("")

  const buildOptions = (response: PageResponse<Data>) => {
    return response?.content?.map(data => {
      const label = labelFieldName.split(",").map(key => _.get(data, key)).join(labelJoiner);
      return {label: label, value: data.id}
    })
  }

  useMemo(() => {
    // get initial values if there are some and we didn't fetch them already
    if (selectedOptions?.length === 0 && values?.length > 0) {
      fetchData({[entityFilterName]: values})
        .then(response => setSelectedOptions(buildOptions(response)))
        .catch(() => null)

    } else if (values.length === 0) {
      // this handles the values reset
      setSelectedOptions([])
    }
  }, [values]);

  const getSelectAllOptions = (inputValue: string) => ({
    value: "<SELECT_ALL>",
    label: intl.formatMessage({id: "filter_all"}, {selected: inputValue ? `(${inputValue})` : ""})
  })

  const loadOptions = (inputValue) => fetchData({[filterFieldName]: inputValue})
    .then((response: PageResponse<Data>) => {
      const options = buildOptions(response)
      setFetchedOptions([getSelectAllOptions(inputValue), ...options])
    })

  const debounceFilter = useCallback(
    debounce((value) => {
      loadOptions(value).catch(() => null)
    }, 200),
    [fetchData]
  );

  const handleInputChange = (value, meta: InputActionMeta) => {
    if (meta.action === "input-change") {
      debounceFilter(value)
      setCurrentInput(value)
    }
  }

  const changeHandler = (values: Option<string>[]) => {
    setSelectedOptions(values)
    setCurrentInput("")
    const ids = values?.map(option => option.value) ?? []
    onChange(ids);
  }

  const relayOnChange = (newValue: Option<string>[], actionMeta: ActionMeta<Option<string>>) => {
    const {action, option} = actionMeta;
    const inputValueBeforeChange = currentInput;

    if (action === "select-option" && option.value === getSelectAllOptions(inputValueBeforeChange).value) {
      changeHandler(fetchedOptions.filter(o => o.value != "<SELECT_ALL>"));
    } else {
      changeHandler(newValue);
    }
  }

  return (
    <div className={`epow-filter ${className}`}>
      {title && <Label className="epow-label">{title}</Label>}

      <Select
        isMulti
        id={id ? id : `autocomplete-${title}`}
        placeholder={placeholder ?? ""}
        value={selectedOptions}
        onChange={relayOnChange}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPlacement="auto"
        onInputChange={handleInputChange}
        inputValue={currentInput}
        options={fetchedOptions}
        onMenuOpen={() => loadOptions("")}
      />
    </div>
  )
}

export default AsyncMultiselectFilter;
