import React, {FunctionComponent} from "react";
import Card from "../../../atoms/Card";
import {FormattedMessage} from "react-intl";
import Icon from "../../../icon/Icon";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import {IconName} from "../../../icon/IconsMap";

interface BookingHeaderCardProps {
  title: string;
  barClassName: string;
  totalElements: number
  iconName?: IconName;
  iconClickAction?: () => void;
}

const BookingHeaderCard: FunctionComponent<BookingHeaderCardProps> = ({
  title,
  barClassName,
  totalElements,
  iconName,
  iconClickAction
}) => (
  <Card className="epow-card-booking-header d-flex justify-content-center">
    <span className={`booking-header-bar bg-theme-${barClassName}`}/>
    <div className="d-flex justify-content-between align-items-center">
      <FormattedMessage id={title}/>
      <div className="d-flex justify-content-between">
        {iconName && <Icon name={iconName} size={IconSizeType.XS} className="pe-2 cursor-pointer" onClick={() => iconClickAction()}/>}
        <div className="d-flex align-items-center justify-content-center rounded-circle border border-3 border-light booking-header-count">
          <span className="fw-bold">{totalElements}</span>
        </div>
      </div>
    </div>
  </Card>
)

export default BookingHeaderCard;
