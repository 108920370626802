import {httpService} from "./HttpService";
import {queryUtils} from "../utils/queryUtils";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {SearchFilter} from "../interfaces/api/FiltersInterface";
import {
  CalendarTableResponse,
  ReportingTableResponse,
  ResourceDayActivitiesSearchParameters
} from "../interfaces/CalendarInterface";
import {OrderItemStaffingPageItemDto} from "../interfaces/OrderItemInterfaces";
import {StaffingCalendarSearchFilter} from "../components/molecules/staffingcalendar/StaffingCalendar";

function getCalendar(params: PaginationQueryParams, filter: StaffingCalendarSearchFilter): Promise<CalendarTableResponse> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<CalendarTableResponse>(`/resource-availability/calendar${queryParams}`)
}

function getStaffingsForResourceOnDay(
  resourceId: string,
  filterParams: ResourceDayActivitiesSearchParameters,
  params: PaginationQueryParams
): Promise<PageResponse<OrderItemStaffingPageItemDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`/resource-availability/resource/${resourceId}/staffing${queryParams}`)
}

function getStaffableOrderitemsOnDay(
  resourceId: string,
  filterParams: ResourceDayActivitiesSearchParameters,
  params: PaginationQueryParams
): Promise<PageResponse<OrderItemStaffingPageItemDto>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filterParams)
  return httpService.get(`/resource-availability/resource/${resourceId}/activities${queryParams}`)
}

function getReporting(params: PaginationQueryParams, filter: SearchFilter): Promise<ReportingTableResponse> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<ReportingTableResponse>(`/resource-availability/reporting${queryParams}`)
}

export const staffingCalendarService = {
  getCalendar,
  getStaffingsForResourceOnDay,
  getStaffableOrderitemsOnDay,
  getReporting
};
