import React, {FunctionComponent, useRef, useState} from "react";
import {RouteProps} from "react-router-dom";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {CalendarData, CalendarTableHeader} from "../../../interfaces/CalendarInterface";
import {FormattedMessage, useIntl} from "react-intl";
import {dateUtils} from "../../../utils/dateUtils";
import useSearchFilter from "../../../hooks/useSearchFilter";
import useLoading from "../../../hooks/useLoading";
import {staffingCalendarService} from "../../../services/StaffingCalendarService";
import HeaderResourceCalendar from "../table/staffingcalendar/HeaderResourceCalendar";
import HeaderCalendar from "../table/staffingcalendar/HeaderCalendar";
import CellResourceCalendar from "../table/staffingcalendar/CellResourceCalendar";
import FilterGroup from "../filters/FilterGroup";
import DateFilter from "../filters/DateFilter";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Toggle from "../../atoms/Toggle";
import CalendarTable, {CalendarTableRef} from "../table/staffingcalendar/CalendarTable";
import AutocompleteFilterPageable from "../filters/AutocompleteFilterPageable";
import {resourceService} from "../../../services/ResourceService";
import {Resource} from "../../../interfaces/ResourceInterfaces";
import {customerService} from "../../../services/CustomerService";
import {orderService} from "../../../services/OrderService";
import {tourService} from "../../../services/TourService";
import {activityService} from "../../../services/ActivityService";
import {activityAreaService} from "../../../services/ActivityAreaService";
import {WorkflowStatesEnum} from "../../../constants/workflow/WorkflowStates";
import {Tour} from "../../../interfaces/TourInterfaces";
import {OrderOption} from "../../../interfaces/OrderInterfaces";
import {orderUtils} from "../../../utils/orderUtils";
import AutocompleteMultiselect from "../filters/AutocompleteMultiselect";
import {resourceTypeService} from "../../../services/ResourceTypeService";

export interface StaffingCalendarSearchFilter extends SearchFilter {
  from?: string,
  to?: string,
  nigthly?: boolean
  tourId?: string,
  customerId?: string,
  orderId?: string,
  activityId?: string,
  activityAreaId?: string,
  resourceId?: string
  resourceTypeIds?: string[]
}

const StaffingCalendar: FunctionComponent<RouteProps> = () => {

  const [datas, setDatas] = useState<CalendarData[]>([])
  const [page, setPage] = useState<number>(0)
  const [last, setLast] = useState<boolean>(false)
  const [dynamicColumns, setDynamicColumns] = useState<CalendarTableHeader[]>([])
  const ref = useRef<CalendarTableRef>(null)

  const intl = useIntl();

  const initialState = {
    from: dateUtils.formatDateYYYYMMDD(new Date()),
    to: dateUtils.formatDateYYYYMMDD(dateUtils.datePlusDays(new Date(), 7)),
    nigthly: false,
    tourId: "",
    customerId: "",
    orderId: "",
    activityId: "",
    activityAreaId: "",
    resourceId: ""
  };

  const {
    searchObject,
    onReset,
    updateSearchField: updateSearchFieldFilter
  } = useSearchFilter<StaffingCalendarSearchFilter>(initialState)

  const {loading, startLoading, stopLoading} = useLoading();

  const nextPage = () => {
    setPage(page + 1)
    startLoading()
    staffingCalendarService.getCalendar({page, pageSize: 20}, searchObject)
      .then((data) => {
        setDatas(prev => [...prev, ...data.content.map(row => ({...row.header, ...row.resourceAvailabilities}))])
        setDynamicColumns(data.headers)
        setLast(data.last)
      }).catch(console.error)
      .finally(stopLoading)
  }


  const fetchDataFirstPage = (filters) => {
    startLoading()
    setPage(0)
    staffingCalendarService.getCalendar({page: 0, pageSize: 20}, filters)
      .then((data) => {
        setDatas(data.content.map(row => ({...row.header, ...row.resourceAvailabilities})))
        setDynamicColumns(data.headers)
        setLast(data.last)
        setPage(1);
        ref?.current?.goTo(0)
      }).catch(console.error)
      .finally(stopLoading)
  }

  const updateSearchField = (field: string, value: boolean | string | string[]) => {
    updateSearchFieldFilter(field, value)
    fetchDataFirstPage({...searchObject, [field]: value})
  }

  const updateDateField = (field: string, value: string, successAction: (value: string) => void) => {
    const dateObject = dateUtils.convertStringToObjectDate(value);
    const isValidDate = dateObject.getDate() <= 31 && dateObject.getMonth() <= 11 && dateObject.getFullYear() >= 1000;

    updateSearchFieldFilter(field, value);
    if (isValidDate) {
      successAction(value);
    }
  }

  const updateEndDate = (value: string) => {
    fetchDataFirstPage({...searchObject, to: value})
  }

  const updateStartDate = (value: string) => {
    const newDate = dateUtils.convertStringToObjectDate(value)
    const currentEndDate = dateUtils.convertStringToObjectDate(searchObject.to)
    let endDateToSet: string = searchObject.to;
    const endMonth = currentEndDate.getMonth()
    const startMonth = newDate.getMonth()

    if (startMonth + 1 !== endMonth) {
      const newEndDate = dateUtils.convertStringToObjectDate(value)
      newEndDate.setDate(newEndDate.getDate() + 7);
      endDateToSet = dateUtils.formatDateYYYYMMDD(newEndDate)
      updateSearchFieldFilter("to", endDateToSet)
    }
    fetchDataFirstPage({...searchObject, from: value, to: endDateToSet})
  }

  const columns = React.useMemo(() => {
    return [{
      accessor: "resource",
      sticky: "left",
      Header: <div className="empty"></div>,
      width: 300,
      Cell: (cell) => <HeaderResourceCalendar cell={cell}/>
    }, ...dynamicColumns.map(header => ({
      Header: <HeaderCalendar header={header}/>,
      accessor: header.date,
      minWidth: 300,
      Cell: (cell) => <CellResourceCalendar cell={cell} accessor={header.date} onReload={() => fetchDataFirstPage(searchObject)} searchObject={searchObject} nightly/>
    }))]
  }, [dynamicColumns]);

  return (
    <div className="pt-2">
      <FilterGroup className="mb-2 d-flex gap-2">
        <DateFilter
          value={searchObject.from} onChangeDate={(value) => updateDateField("from", value, updateStartDate)}
          title={intl.formatMessage({id: "filter_from"})}/>
        <DateFilter
          min={searchObject.from}
          max={dateUtils.formatDateYYYYMMDD(dateUtils.datePlusDays(dateUtils.parseDate(searchObject.from), 30))}
          value={searchObject.to} onChangeDate={(value) => updateDateField("to", value, updateEndDate)}
          title={intl.formatMessage({id: "filter_to"})}/>
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          placeholder={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypeIds ?? []}
          onChange={(values) => updateSearchField("resourceTypeIds", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2"
          classNameLabel="mb-1 fw-bold"
        />
      </FilterGroup>

      <FilterGroup>
        <AutocompleteFilterPageable
          className="me-2"
          title={intl.formatMessage({id: "billing_overview_resource"})}
          value={searchObject?.resourceId}
          onChange={(value) => updateSearchField("resourceId", value)}
          fetchData={(page, filter) => resourceService.getResourcesPage({
            ...page,
            sortOptions: [{sortBy: "identity.firstName", sortDirection: "ASC"}]
          }, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="identity.firstName"
          filterFieldSearch="nameSurname"
          manageOptions={(response: Resource[]) => response.map(option =>
            ({value: option.id, label: `${option.identity?.firstName} ${option.identity?.lastName}`})
          )}
          optionFromValue={(response: Resource[], value: string) => {
            const option = response?.find((resource) => resource.id === value);
            return option ? {
              label: `${option.identity?.firstName} ${option.identity?.lastName}`,
              value: option?.id
            } : null;
          }}
        />

        <AutocompleteFilterPageable
          className="me-2"
          onChange={(value) => updateSearchField("customerId", value)}
          title={intl.formatMessage({id: "staffing_customer"})}
          value={searchObject?.customerId}
          fetchData={(page, filter) => customerService.getCustomersPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />

        <AutocompleteFilterPageable
          className="me-2"
          onChange={(value) => updateSearchField("orderId", value)}
          title={intl.formatMessage({id: "staffing_order"})}
          value={searchObject?.customerId}
          fetchData={orderService.getOrdersOptionPage}
          manageOptions={(response: OrderOption[]) => response.map(order => ({value: order.id, label: `${order.refCustomer || order.orderNumber} - ${order.customerName}`}))}
          optionFromValue={(response: OrderOption[], value: string) => {
            const orderOption = response?.find((order) => order.id === value);
            return orderOption ? {
              label: orderUtils.getOrderRefDisplay(orderOption.orderNumber, orderOption.refCustomer, orderOption.customerName),
              value: orderOption?.id
            } : null;
          }}
          filterFieldName="refCustomer"
          filterFieldSearch="customerOrCode"
        />

        <AutocompleteFilterPageable
          className="me-2"
          onChange={(value) => updateSearchField("tourId", value)}
          title={intl.formatMessage({id: "staffing_tour"})}
          value={searchObject?.customerId}
          fetchData={(page, filter) => tourService.getToursPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          manageOptions={(response: Tour[]) => response.map(option =>
            ({value: option.id, label: `${option.tourCode} - ${option.tourName}`})
          )}
          optionFromValue={(response: Tour[], value: string) => {
              const option = response?.find((tour) => tour.id === value);
              return option ? {
                  label: `${option.tourCode} - ${option.tourName}`,
                  value: option?.id
              } : null;
          }}
          filterFieldName="tourName"
        />

        <AutocompleteFilterPageable
          className="me-2"
          onChange={(value) => updateSearchField("activityId", value)}
          title={intl.formatMessage({id: "staffing_activity"})}
          value={searchObject?.customerId}
          fetchData={(page, filter) => activityService.getActivitiesPage(page, {...filter, status: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />

        <AutocompleteFilterPageable
          className="me-2"
          onChange={(value) => updateSearchField("activityAreaId", value)}
          title={intl.formatMessage({id: "staffing_activity_area"})}
          value={searchObject?.customerId}
          fetchData={activityAreaService.getActivityAreasPage}
          filterFieldName="label"
        />

        <div className="d-flex ms-2">
          <Button onClick={() => {
            onReset()
            fetchDataFirstPage(initialState)
          }}
                  color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <div className={"d-flex flex-column"}>
        <div className="d-flex align-items-center justify-content-end ms-auto mb-3">
          <div className="d-flex align-items-center bold">
            <FormattedMessage id="display_nightly"/>
            <Toggle className="ms-2" id="display_nightly" onChange={(checked) => updateSearchFieldFilter("nightly", checked)}/>
          </div>
        </div>
      </div>

      <div className="table_wrapper">
        <CalendarTable
          ref={ref}
          columns={columns}
          datas={datas}
          className="staffing-calendar"
          hasNextPage={!last}
          isNextPageLoading={loading}
          itemSize={!!searchObject.nightly ? 264 : 176}
          loadNextPage={nextPage}/>
      </div>
    </div>)
}


export default StaffingCalendar;
