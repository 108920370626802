import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import FilterGroup from "../FilterGroup";
import SelectFilter from "../SelectFilter";
import AutocompleteFilterPageable from "../AutocompleteFilterPageable";
import Button from "../../../atoms/Button";
import {ColorType} from "../../../../types/bootstrap/BootstrapType";
import {resourceService} from "../../../../services/ResourceService";
import {StaffingReportingSearchFilter} from "../../staffingcalendar/StaffingReporting";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {Resource} from "../../../../interfaces/ResourceInterfaces";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import AutocompleteMultiselect from "../AutocompleteMultiselect";
import {resourceTypeService} from "../../../../services/ResourceTypeService";

interface StaffingReportingFiltersProps {
  searchObject: StaffingReportingSearchFilter,
  initialState: StaffingReportingSearchFilter,
  updateSearchField: (field: string, value: unknown) => void,
  onReset: () => void,
  fetchDataFirstPage: (searchObject: StaffingReportingSearchFilter) => void,
}

const StaffingReportingFilters: FunctionComponent<StaffingReportingFiltersProps> = ({
  searchObject,
  initialState,
  updateSearchField,
  onReset,
  fetchDataFirstPage,
}) => {
  const intl = useIntl()

  const getYearOptions = () => {
    const start = 2015;
    const end = new Date().getFullYear() + 1;
    const options = [];

    for (let year = start; year <= end; year++) {
      options.push({label: year.toString(), value: year.toString()})
    }

    return options;
  }
  const yearOptions = useMemo(() => getYearOptions(), []);

  return (
    <FilterGroup>
      <SelectFilter
        className="me-2"
        onChange={(value) => updateSearchField("year", value)}
        title={intl.formatMessage({id: "year"})}
        value={searchObject?.year}
        options={yearOptions}
      />

      <AutocompleteFilterPageable
        className="me-2"
        title={intl.formatMessage({id: "billing_overview_resource"})}
        value={searchObject?.resourceId}
        onChange={(value) => updateSearchField("resourceId", value)}
        fetchData={(page, filter) => resourceService.getResourcesPage({
          ...page,
          sortOptions: [{sortBy: "identity.firstName", sortDirection: "ASC"}]
        }, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
        filterFieldName="nameSurname"
        manageOptions={(response: Resource[]) => response.map(option =>
          ({value: option.id, label: `${option.identity?.firstName} - ${option.identity?.lastName}`})
        )}
        optionFromValue={(response: Resource[], value: string) => {
          const option = response?.find((resource) => resource.id === value);
          return option ? {
            label: `${option.identity?.firstName} - ${option.identity?.lastName}`,
            value: option?.id
          } : null;
        }}
      />

      <AutocompleteMultiselect
        title={intl.formatMessage({id: "resource_table_header_area"})}
        placeholder={intl.formatMessage({id: "resource_table_header_area"})}
        values={searchObject.activityAreas ?? []}
        onChange={(values) => updateSearchField("activityAreas", values)}
        fetchData={activityAreaService.getActivityAreasPage}
        filterFieldName="label"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />

      <AutocompleteMultiselect
        title={intl.formatMessage({id: "resource_type"})}
        placeholder={intl.formatMessage({id: "resource_type"})}
        values={searchObject.resourceTypeIds ?? []}
        onChange={(values) => updateSearchField("resourceTypeIds", values)}
        fetchData={resourceTypeService.getResourceTypePage}
        filterFieldName="label"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />

      <div className="d-flex ms-2">
        <Button onClick={() => {
          onReset()
          fetchDataFirstPage(initialState)
        }}
          color={ColorType.SECONDARY}>{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
      </div>
    </FilterGroup>
  )
}

export default StaffingReportingFilters;
