import React, {FunctionComponent, useState} from "react";
import TextLink from "../../atoms/TextLink";
import NavItemContent from "./NavItemContent";
import {IconName} from "../../icon/IconsMap";
import {SubMenu} from "../../../constants/menu/Menu";
import {authUtils} from "../../../utils/authUtils";

interface NavItemProps {
  className?: string,
  label: string,
  icon?: IconName,
  to?: string,
  subMenus?: SubMenu[]
}

const NavItem: FunctionComponent<NavItemProps> = ({
  className = "",
  icon,
  label,
  to="",
  subMenus= []
}) => {
  const currentUrl = window.location.pathname
  const profile = authUtils.getProfile()
  const [subMenusToggled, setSubMenusToggled] = useState<boolean>(!!currentUrl.includes(to));
  const toggleSubMenu = () => {
    setSubMenusToggled(!subMenusToggled)
  }

  return (
    <>
      {subMenus.length > 0 ? (
        <div className={`${className} epow-menu-item d-flex flex-row cursor-pointer`} onClick={toggleSubMenu}>
          <NavItemContent icon={icon} label={label} className="cursor-pointer"/>
        </div>
      ) : (
        <TextLink to={to} className={`${className} epow-menu-item d-flex flex-row cursor-pointer ${window.location.pathname === to ? "active" : ""}`} noUnderline>
          <NavItemContent icon={icon} label={label} className="cursor-pointer"/>
        </TextLink>
        )}
      <div className={`ms-5 ${!subMenusToggled && "d-none"}`}>
        {subMenus.map((subItem : SubMenu) => {
          const regexp = new RegExp(`${subItem.to}(\\/(?:([^?#]*)))?`)
          return (
            subItem.roles.some(role => role === profile) &&
            <TextLink key={subItem.label} to={subItem.to}
                      className={`epow-menu-item sub-item d-flex flex-row cursor-pointer ${regexp.exec(currentUrl) ? "active" : ""}`}
                      noUnderline>
              <NavItemContent icon={subItem.icon} label={subItem.label} className="cursor-pointer"/>
            </TextLink>
          )})}
      </div>
    </>
  )
}


export default NavItem;
