import React, {FunctionComponent, useEffect, useState} from "react";
import {toastUtils} from "../../../../utils/toastUtils";
import {useIntl} from "react-intl";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {Formik} from "formik";
import FormSelect from "../../input/FormSelect";
import {supplierService} from "../../../../services/SupplierService";
import {Supplier} from "../../../../interfaces/SupplierInterfaces";
import {PurchaseRequest} from "../../../../interfaces/PurchaseInterfaces";
import {purchaseService} from "../../../../services/PurchaseService";
import {PURCHASE_ORDER_INITIAL_VALUES} from "../../../../constants/PurchaseConstants";
import {CreatePurchaseOrderSchema} from "../../../../constants/validation/PurchaseValidationSchemas";
import {useNavigate} from "react-router-dom";
import {PURCHASES_PATH} from "../../../../constants/routes/RoutePaths";
import EpowForm from "../EpowForm";

interface CreatePurchaseOrderFormProps {
  id?: string,
  className?: string
}

const CreatePurchaseOrderForm: FunctionComponent<CreatePurchaseOrderFormProps> = ({
  id,
  className = ""
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [supplierOptions, setSupplierOptions] = useState<Option<string>[]>([])

  const getSupplierOptions = () => {
    supplierService.getActiveSuppliers()
      .then((suppliers: Supplier[]) => {
        const supplierOptions = suppliers.map(supplier => {
          return {value: supplier?.id, label: supplier?.name}
        })
        setSupplierOptions(supplierOptions)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_suppliers"}))
      })
  }

  const handleSubmitPurchaseOrder = (purchaseOrder: PurchaseRequest) => {
    purchaseService.createPurchase(purchaseOrder)
      .then((purchase) => {
        navigate(`${PURCHASES_PATH}/${purchase?.id}`)
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_purchase_order"}))
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_create_purchase_order"}))
      })
  }

  useEffect(() => {
    getSupplierOptions()
  }, [])

  return (
    <Formik
      initialValues={PURCHASE_ORDER_INITIAL_VALUES}
      validationSchema={CreatePurchaseOrderSchema}
      onSubmit={handleSubmitPurchaseOrder}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormSelect
            id="supplierId"
            label="purchase_order_supplier"
            value={supplierOptions.find((option) => option?.value === values?.supplierId)}
            onChange={(option?: Option<string>) => setFieldValue("supplierId", option?.value)}
            options={supplierOptions}
            required
            isSearchable
            isClearable
            error={errors?.supplierId}
            touched={touched?.supplierId}
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default CreatePurchaseOrderForm