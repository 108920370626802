import React, {FunctionComponent} from "react";
import ReactSelect from "react-select";
import {SelectProps} from "../../../interfaces/inputs/SelectInterfaces";

const Select: FunctionComponent<SelectProps> = ({
  id,
  className= "",
  value,
  placeholder,
  onChange,
  isSearchable = false,
  isClearable = true,
  isDisabled = false,
  options
}) => {
  return (
    <ReactSelect
      id={id}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      className={`epow-select ${className} ${value?.value ? "selected" : ""}`}
      isSearchable={isSearchable}
      isClearable={isClearable}
      menuPlacement="auto"
      isDisabled={isDisabled}
    />
  )
}


export default Select;
