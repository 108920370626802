import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Col, Label, Spinner} from "reactstrap";
import {UserRule} from "../../../interfaces/RuleInterface";
import {PROFILE, User} from "../../../interfaces/UserInterfaces";
import {ruleService} from "../../../services/RuleService";
import {userRulesService} from "../../../services/UserRulesService";
import {ColorType, SizeType, TitleSizeType} from "../../../types/bootstrap/BootstrapType";
import {fileUtils} from "../../../utils/fileUtils";
import {toastUtils} from "../../../utils/toastUtils";
import Button from "../../atoms/Button";
import Title from "../../atoms/Title";
import Icon from "../../icon/Icon";

interface LoginRulesFormProps {
  user: User,
  profile: PROFILE,
  setStep: (step: number) => void,
  onSubmitRules: (rules: UserRule[]) => void,
}

const LoginRulesForm: FunctionComponent<LoginRulesFormProps> = ({
  user,
  profile,
  setStep,
  onSubmitRules,
}) => {

  const intl = useIntl()

  const [rules, setRules] = useState<UserRule[]>([])

  const getRulesForUser = () => {
    userRulesService.getRulesForUserWithProfile(user.id, profile)
      .then(response => {
        if (response.every(rule => rule.accepted)) {
          onSubmitRules(response)
        } else {
          setRules(response)
        }
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "login_error"}))
      })
  }

  useEffect(() => {
    getRulesForUser()
  }, [])

  const acceptRule = (rule: UserRule) => {
    const newRule = {...rule, accepted: !rule.accepted}
    const newRules = [...rules].map(r => r.id === newRule?.id ? newRule : r)
    setRules(newRules)
  }

  const downloadRuleFile = (rule: UserRule) => {
    fileUtils.downloadFile(ruleService.getFile(rule.id), rule.attachment, intl)
  }

  if (!rules || rules?.length === 0) {
    return (
      <Col className="d-flex flex-column justify-content-center align-items-center">
        <Spinner ></Spinner>
      </Col>
    )
  }

  return (
    <Col className="d-flex flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column px-5">
        <Title title={intl.formatMessage({id: "login_rule_title"})} className="mx-auto mb-1" />
        <Title size={TitleSizeType.H6} title={intl.formatMessage({id: "login_rule_subtitle"})} className="mx-auto mb-4" />

        <div className="d-flex flex-column w-100">
          {rules.map(rule => (
            <div key={rule.id} className="d-flex align-items-start mb-2">
              <div className="row">
                <div className="col-1">
                  <input type="checkbox" checked={rule.accepted} onClick={() => acceptRule(rule)} />
                </div>
                <div className="col d-flex flex-column ps-3">
                  <div className="overflow-hidden epow-login-rule justif">
                    {rule.description}
                  </div>
                  {rule.attachment && (
                    <div className="d-flex">
                      <span className={"epow-text-link epow-login-rule cursor-pointer"} onClick={() => downloadRuleFile(rule)} >
                        <FormattedMessage id="login_rule_download" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex align-items-center justify-content-between mt-3">
          <Label role="button" className="h6 text-underline me-3" onClick={() => setStep(0)}>
            <Icon name="ChevronLeft" className="me-1" />
            <FormattedMessage id="back" />
          </Label>

          <Button
            size={SizeType.MD}
            color={ColorType.SECONDARY}
            onClick={() => onSubmitRules(rules)}
            className="border border-dark"
            disabled={!rules.every(rule => rule.accepted)}
          >
            <FormattedMessage id="login_rule_accept" />
          </Button>
        </div>
      </div>
    </Col>
  )
}

export default LoginRulesForm;
