import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import ActivityCustomerList from "../../list/ActivityCustomerList";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import Panel from "../../panel/Panel";
import CreateActivityCustomerForm from "../../form/CreateActivityCustomerForm";
import {activityService} from "../../../../services/ActivityService";
import {Activity, ActivityCustomerCatalog} from "../../../../interfaces/ActivityInterfaces";

interface ActivityCustomerCardProps {
  className?: string,
  activity?: Activity,
  numberPaxMax?: number,
  billingName?: string
  bookTicketTourLeader?: boolean
}

const ActivityCustomerCard: FunctionComponent<ActivityCustomerCardProps> = ({
  className = "",
  activity,
  numberPaxMax = 0,
  billingName,
  bookTicketTourLeader
}) => {
  const [lateralPanelActivityCustomer, setLateralPanelActivityCustomer] = useState<boolean>(false)
  const [activitiesCustomerCatalog, setActivitiesCustomerCatalog] = useState<ActivityCustomerCatalog[]>([])

  const getActivitiesCustomerCatalog = () => {
    if (activity) {
      activityService.getActivityCustomers(activity.id)
        .then((activityCustomerCatalogs) => {
          setActivitiesCustomerCatalog(activityCustomerCatalogs)
        })
        .catch(() => {
          setActivitiesCustomerCatalog([])
        })
    }
  }

  const handleValidate = () => {
    getActivitiesCustomerCatalog()
    setLateralPanelActivityCustomer(false)
  }

  useEffect(() => {
    getActivitiesCustomerCatalog()
  }, [activity])

  return (
    <div className={className}>
      <ActivityCustomerList activityDescription={activity?.description} className="mb-3" activitiesCustomerCatalog={activitiesCustomerCatalog} onValidate={handleValidate}/>
      <Button
        size={SizeType.MD}
        color={ColorType.SECONDARY}
        className="extended"
        onClick={() => setLateralPanelActivityCustomer(true)}
      >
        <FormattedMessage id="add_customer"/>
      </Button>
      {lateralPanelActivityCustomer && (
        <Panel
          formId="createActivityCustomerForm"
          title="add_customer"
          open={lateralPanelActivityCustomer}
          onCancel={() => setLateralPanelActivityCustomer(false)}
        >
          <CreateActivityCustomerForm
            id="createActivityCustomerForm"
            activityId={activity?.id}
            onValidate={handleValidate}
            numberPaxMax={numberPaxMax}
            bookTicketTourLeader={bookTicketTourLeader}
            billingName={billingName}
            existingCustomerIds={activitiesCustomerCatalog.map(a => a?.customer?.id)}
          />
        </Panel>
      )}
    </div>
  )
}

export default ActivityCustomerCard
