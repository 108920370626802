import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Contact } from "../../../../interfaces/ContactInterfaces";
import { Supplier } from "interfaces/SupplierInterfaces";
import { supplierContactService } from "../../../../services/SupplierContactService";
import { toastUtils } from "../../../../utils/toastUtils";
import ContactList from "./ContactList";

interface SupplierContactListProps {
  className?: string;
  supplier?: Supplier;
}

const SupplierContactList: FunctionComponent<SupplierContactListProps> = ({
  className = "",
  supplier,
}) => {
  const intl = useIntl();
  const [contacts, setContacts] = useState<Contact[]>([]);

  const getSupplierContacts = useCallback((filter?: string) => {
    supplierContactService
      .getAllContactsBySupplierId(supplier?.id, filter ? { filter } : null)
      .then((contacts: Contact[]) => {
        setContacts(contacts);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({ id: "error_toast_get_contacts" }));
      });
  }, [supplier]);

  useEffect(() => {
    getSupplierContacts(null);
  }, []);

  return (
    <div>
      <ContactList
        className={className}
        entity={supplier}
        onUpdate={() => null}
        contacts={contacts}
        refreshData={getSupplierContacts}
        createContactRequest={supplierContactService.createSupplierContact}
        updateMainContactRequest={supplierContactService.updateSupplierContact}
      />
    </div>
  );
};

export default SupplierContactList;
