import React, {FunctionComponent} from "react";

interface ToggleProps {
  className?: string,
  id:string,
  onChange?: (checked: boolean) => void,
}

const Toggle: FunctionComponent<ToggleProps> = ({
  className = "",
  id,
  onChange,
}) => {
  return (
    <div className={`epow-toggle ${className ?? ""}`}>
      <input className="epow-toggle-checkbox" id={id} type="checkbox" onChange={(event) => onChange(event.target.checked)} />

      <label className="epow-toggle-button" htmlFor={id} >
        <span className={"epow-toggle-slider"} />
      </label>
    </div>
  )
}

export default Toggle;
