import {Tour} from "../../../../interfaces/TourInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import FieldLabel from "../../../atoms/FieldLabel";

interface TourCardProps {
  className?: string,
  tour?: Tour,
  editInfo?: boolean,
  setEditInfo?: (editInfo: boolean) => void
}

const TourCard: FunctionComponent<TourCardProps> = ({
  className = "",
  tour,
  editInfo,
  setEditInfo
}) => {
  const intl = useIntl()
  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-3">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({ id: "tour_name" })}
          value={tour?.tourName}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({ id: "tour_code" })}
          value={tour?.tourCode}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({ id: "tour_number_pax" })}
          value={tour?.numberPax}
        />
      </div>
      <div>
        <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)} className="extended">
          <FormattedMessage id="edit_button"/>
        </Button>
      </div>
    </div>
  )
}

export default TourCard