import React, {FunctionComponent} from "react";
import {RouteProps, useParams} from "react-router-dom";
import {invoiceService} from "../../services/InvoiceService";
import InvoiceHeader from "../../components/molecules/header/InvoiceHeader";
import TemplateBillingView from "./TemplateBillingView";
import {profileUtils} from "../../utils/profileUtils";
import CustomerTemplateBillingView from "./CustomerTemplateBillingView";

const BillingView: FunctionComponent<RouteProps> = () => {

  const {id} = useParams();

  return profileUtils.isInterne() ? (
    <TemplateBillingView
      HeaderComponent={(props) => <InvoiceHeader displayStatus {...props} />}
      fetchInvoice={() => invoiceService.getInvoice(id)}
    />
  ) : (
    <CustomerTemplateBillingView
      HeaderComponent={(props) => <InvoiceHeader {...props} />}
      fetchInvoice={() => invoiceService.getInvoice(id)}
    />
  );
}


export default BillingView;
