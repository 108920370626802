import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {ActivityStaffingStatus} from "../../../../../constants/ActivityStaffingConstants";
import {StaffedResource} from "../../../../../interfaces/ActivityStaffingInterface";
import {OrderItem, OrderItemState} from "../../../../../interfaces/OrderItemInterfaces";
import {orderItemService} from "../../../../../services/OrderItemService";
import {ColorType, IconSizeType} from "../../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../../utils/toastUtils";
import Badge from "../../../../atoms/Badge";
import FieldLabel from "../../../../atoms/FieldLabel";
import Icon from "../../../../icon/Icon";
import StaffingReplaceForm from "../../../form/staffing/StaffingReplaceForm";
import GenericModal from "../../../modal/GenericModal";
import Panel from "../../../panel/Panel";
import StaffingTableStaffingSelectorButton from "../../../table/staffing/buttons/StaffingTableStaffingSelectorButton";
import {authUtils} from "../../../../../utils/authUtils";
import {PROFILE} from "../../../../../interfaces/UserInterfaces";
import {profileUtils} from "../../../../../utils/profileUtils";
import {getProfilePicture} from "../../../../../constants/routes/RoutePaths";
import Avatar from "../../../images/Avatar";
import {ResourceDisplayOption} from "../../../../../constants/ResourceDisplayOptionConstant";
import ResourceCircle from "../../../circle/ResourceCircle";

interface OrderItemPreviewCardStaffingProps {
  orderItem: OrderItem,
  isCustomer: boolean,
  onOrderItemChange: () => void,
  resourceDisplayOption?: ResourceDisplayOption
}

const OrderItemPreviewCardStaffing: FunctionComponent<OrderItemPreviewCardStaffingProps> = ({
  orderItem,
  isCustomer,
  onOrderItemChange,
  resourceDisplayOption
}) => {
  const intl = useIntl();
  const staffedResources = orderItem.resourceStaffing?.filter(r => r.status === ActivityStaffingStatus.CONFIRMED);
  const maxResources = orderItem.orderItemResources?.reduce((acc, orderItemResource) => {
    return acc + orderItemResource.quantity
  }, 0)

  const [staffingReplaceFormOpen, setStaffingReplaceFormOpen] = useState<boolean>(false)
  const [staffingDeleteModalOpen, setStaffingDeleteModalOpen] = useState<boolean>(false)
  const [resourceToReplace, setResourceToReplace] = useState<StaffedResource>(null)
  const [resourceToDelete, setResourceToDelete] = useState<StaffedResource>(null)


  const openFormForResourceReplacement = (resource: StaffedResource) => {
    setResourceToReplace(resource)
    setStaffingReplaceFormOpen(true)
  }

  const openStaffingDeleteModal = (resource: StaffedResource) => {
    setResourceToDelete(resource)
    setStaffingDeleteModalOpen(true)
  }

  const handleDeleteStaffing = () => {
    orderItemService.deleteStaffingForOrderItem(resourceToDelete.orderItemId, resourceToDelete.staffingId)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_staffing"}));
        setStaffingDeleteModalOpen(false)
        onOrderItemChange();
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_staffing"}))
      });
  }

  const getResourcesLabel = () => {
    if (isCustomer) {
      return <span className="bold">{`${intl.formatMessage({id: "order_item_resources_staffed"})} : `}</span>
    }
    return (
      <div className="d-flex">
        <span className="bold">{`${intl.formatMessage({id: "order_item_resources_to_staff"})} : `}</span>
        {
          orderItem?.orderItemResources.map((orderItemResource) =>
            <span key={orderItemResource.id} className="d-flex ms-2">
              <span>{orderItemResource.quantity}</span>
              <ResourceCircle resourceType={orderItemResource.resourceType} className="ms-1" />
            </span>
          )
        }
      </div>
    )
  }

  return (
    <div className="d-flex flex-column mb-1">
      {profileUtils.isInterne() ? (
        <>
          {getResourcesLabel()}
          {staffedResources?.map((staffedResource, index) => (
            <div key={index}>
              <Badge color={ColorType.SECONDARY} className="mb-1">
                <div className="d-flex flex-row align-items-center">
                  <ResourceCircle className="me-2" resourceType={staffedResource.resourceType} />
                  <Avatar url={getProfilePicture(staffedResource.resourceId)}  width={24} height={24} className="m-0 me-2"/>
                  {`${staffedResource.firstName} ${staffedResource.lastName}`}
                  <Icon className="ms-2 cursor-pointer"
                      name="Undo"
                      size={IconSizeType.XS}
                      onClick={() => openFormForResourceReplacement(staffedResource)}
                  />
                  <Icon className="ms-2 cursor-pointer"
                      name="Trash"
                      size={IconSizeType.XS}
                      onClick={() => openStaffingDeleteModal(staffedResource)}
                  />
                </div>
              </Badge>
            </div>
          ))}
        </>
      ) : (
        isCustomer ? (
          <>
            <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_item_resources_to_staff"})} value={orderItem?.resourceStaffing?.length} />
            {staffedResources?.map((staffedResource, index) => (
              <div key={index}>
                {resourceDisplayOption === ResourceDisplayOption.DISPLAY_NAME_PHONE || resourceDisplayOption === ResourceDisplayOption.DISPLAY_NAME ? (
                  <Badge color={ColorType.SECONDARY} className="mb-1">
                    {staffedResource.firstName}
                    {resourceDisplayOption === ResourceDisplayOption.DISPLAY_NAME_PHONE ? ` - ${staffedResource.phoneNumber}` : ""}
                  </Badge>
                ) : null}
              </div>

            ))}
          </>
        ) :(
          <>
            <FieldLabel className="mb-1" label={intl.formatMessage({id: "order_item_resources_to_staff"})} value={orderItem?.resourceStaffing?.length} />
            {staffedResources?.map((staffedResource, index) => (
              <div key={index}>
                <Badge color={ColorType.SECONDARY} className="mb-1">
                  <Avatar url={getProfilePicture(staffedResource.resourceId)}  width={24} height={24} className="m-0 me-2"/>
                  {`${staffedResource.firstName} ${staffedResource.lastName[0]} - ${staffedResource.phoneNumber}`}
                </Badge>
              </div>
            ))}
          </>
        )
      )
      }
      {(orderItem.status === OrderItemState.PENDING) && authUtils.getProfile() !== PROFILE.TOUR_LEADER && authUtils.getProfile() !== PROFILE.CLIENT && staffedResources?.length < maxResources && (
        <StaffingTableStaffingSelectorButton
          hasStaffing={orderItem.resourceStaffing.length > 0}
          onStaffing={onOrderItemChange}
          maxResources={maxResources}
          orderItemId={orderItem.id}
          initialResourceTypes={orderItem.orderItemResources?.map((orderItemResource) => orderItemResource.resourceType.id)}
        />
      )}


      {staffingReplaceFormOpen && (
        <Panel
          title="staffing_title"
          open={staffingReplaceFormOpen}
          onCancel={() => setStaffingReplaceFormOpen(false)}
          displayButton={false}
        >
          <StaffingReplaceForm
            onFormOpen={setStaffingReplaceFormOpen}
            orderItemId={orderItem?.id}
            orderItemName={orderItem?.name}
            resourceToReplace={resourceToReplace}
            onReplace={onOrderItemChange}
          />
        </Panel>
      )}

      {staffingDeleteModalOpen && (
        <GenericModal
          className={""}
          title={intl.formatMessage({id: "modal_title_delete_staffing"})}
          open={staffingDeleteModalOpen}
          onOpen={setStaffingDeleteModalOpen}
          closable
          footer={{
            primaryAction: {
              buttonLabel: intl.formatMessage({id: "validate_button"}),
              action: () => handleDeleteStaffing()
            },
            secondaryAction: {
              buttonLabel: intl.formatMessage({id: "cancel_button"}),
              action: () => setStaffingDeleteModalOpen(false)
            }
          }}
        >
          {intl.formatMessage({id: "modal_body_delete_staffing"})}
        </GenericModal>
      )}

    </div>
  )
}

export default OrderItemPreviewCardStaffing;
