import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {Column} from "react-table";
import {IconSizeType} from "../../../../types/bootstrap/BootstrapType";
import Icon from "../../../icon/Icon";
import Table from "../Table";
import {ActivityResourceRequirement} from "../../../../interfaces/ActivityResourceRequirementInterfaces";
import {numberUtils} from "../../../../utils/numberUtils";

interface ActivityResourceRequirementTableProps {
  className?: string,
  data: ActivityResourceRequirement[],
  handleEditRequirement: (requirement: ActivityResourceRequirement) => void,
  handleDeleteRequirement: (requirement: ActivityResourceRequirement) => void,
}

const ActivityResourceRequirementTable: FunctionComponent<ActivityResourceRequirementTableProps> = ({
  className = "",
  data,
  handleEditRequirement,
  handleDeleteRequirement,
}) => {
  const intl = useIntl();

  const columns: Column[] = [
    {
      Header: intl.formatMessage({id: "resource_type"}),
      accessor: "resourceType.label"
    },
    {
      Header: intl.formatMessage({id: "activity_resource_requirement_quantity"}),
      accessor: "quantity"
    },
    {
      Header: intl.formatMessage({id: "activity_resource_requirement_unit_price"}),
      accessor: "unitPrice",
      Cell: ({row}: {row: {original: ActivityResourceRequirement}}) => {
        const requirement: ActivityResourceRequirement = row?.original
        return <span>{`${numberUtils.formatPrice(requirement.unitPrice)} €`}</span>
      }
    },
    {
      Header: "",
      accessor: "actions",
      width: "15%",
      Cell: ({row}: {row: {original: ActivityResourceRequirement}}) => {
        const requirement: ActivityResourceRequirement = row?.original
        return <div className="float-end">
          <Icon name="Pen" size={IconSizeType.XS} className="pe-2 cursor-pointer" onClick={() => handleEditRequirement(requirement)} />
          <Icon name="Trash" size={IconSizeType.XS} className="pe-2 cursor-pointer" onClick={() => handleDeleteRequirement(requirement)} />
        </div>
      },
    }
  ]

  return (
    <Table
      columns={columns}
      data={data}
      labelNoResult="activity_resource_requirement_no_result"
      className={`${className} table`}
    />
  )
}

export default ActivityResourceRequirementTable;
