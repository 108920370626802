import React, {FunctionComponent, useEffect, useState} from "react";
import {ActivityResourceRequirement} from "../../../interfaces/ActivityResourceRequirementInterfaces";
import {activityResourceRequirementService} from "../../../services/ActivityResourceRequirementService";
import {toastUtils} from "../../../utils/toastUtils";
import {FormattedMessage, useIntl} from "react-intl";
import ActivityResourceRequirementTable from "../table/activityresourcerequirement/ActivityResourceRequirementTable";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import Panel from "../panel/Panel";
import UpsertActivityResourceRequirementForm
  from "../form/activityresourcerequirement/UpsertActivityResourceRequirementForm";
import GenericModalDelete from "../modal/GenericModalDelete";

interface ActivityResourceRequirementListProps {
  activityId: string;
  className?: string;
}

const ActivityResourceRequirementList: FunctionComponent<ActivityResourceRequirementListProps> = ({
  activityId,
  className = "",
}) => {
  const intl = useIntl();
  const [resourceRequirements, setResourceRequirements] = useState<ActivityResourceRequirement[]>([])
  const [lateralPanelRequirement, setLateralPanelRequirement] = useState<boolean>(false)
  const [selectedRequirement, setSelectedRequirement] = useState<ActivityResourceRequirement>(undefined)
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false)

  const fetchRequirements = () => {
    activityResourceRequirementService.getRequirementsForActivity(activityId)
      .then((requirements) => {
        setResourceRequirements(requirements);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_activity_resource_requirements"}));
      });
  }

  useEffect(() => {
    fetchRequirements()
  }, []);

  const handleValidate = () => {
    setSelectedRequirement(undefined);
    setLateralPanelRequirement(false);
    setOpenModalDelete(false);
    fetchRequirements();
  }

  const handleEditRequirement = (requirement: ActivityResourceRequirement) => {
    setSelectedRequirement(requirement);
    setLateralPanelRequirement(true);
  }

  const handleCreateRequirement = () => {
    setSelectedRequirement(undefined);
    setLateralPanelRequirement(true);
  }

  const handleDeleteRequirement = (requirement: ActivityResourceRequirement) => {
    setSelectedRequirement(requirement);
    setOpenModalDelete(true);
  }

  return (
    <div className={className}>
      <ActivityResourceRequirementTable
        data={resourceRequirements}
        handleEditRequirement={handleEditRequirement}
        handleDeleteRequirement={handleDeleteRequirement}
      />
      <Button color={ColorType.SECONDARY} onClick={handleCreateRequirement}>
        <FormattedMessage id="add_button" />
      </Button>
      {lateralPanelRequirement && (
        <Panel
          formId="createActivityResourceRequirementForm"
          title={selectedRequirement ? "activity_resource_requirement_edit" : "activity_resource_requirement_create"}
          open={lateralPanelRequirement}
          onCancel={() => setLateralPanelRequirement(false)}
        >
          <UpsertActivityResourceRequirementForm
            id="createActivityResourceRequirementForm"
            activityId={activityId}
            initialRequirement={selectedRequirement}
            onValidate={handleValidate}
          />
        </Panel>
      )}
      <GenericModalDelete
        titleId="modal_title_delete_activity"
        open={openModalDelete}
        onValidate={handleValidate}
        setOpen={setOpenModalDelete}
        idEntity={selectedRequirement?.id}
        onDelete={(requirementId: string) => activityResourceRequirementService.deleteRequirementForActivity(requirementId, activityId)}
        successToastMessageId="activity_resource_requirement_delete_success"
        errorToastMessageId="activity_resource_requirement_delete_error"
        bodyDeleteMessage={intl.formatMessage({id: "activity_resource_requirement_delete_body"})}
      />
    </div>
  );
};

export default ActivityResourceRequirementList;
