import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {PAGE_SIZE_OPTIONS} from "../../../constants/OptionConstants";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import Select from "../../atoms/input/Select";

interface PaginationSizeProps {
  className?: string,
  pageSize: number,
  onPageSizeChange: (pageSize: number) => void,
}

const PaginationSize: FunctionComponent<PaginationSizeProps> = ({
  className = "",
  pageSize,
  onPageSizeChange,
}) => {
  return (
    <div className={`d-flex align-items-center ${className}`}>
      <span className="me-2"><FormattedMessage id="table_size" /></span>
      <Select
        className="w-auto"
        options={PAGE_SIZE_OPTIONS}
        value={{label: pageSize.toString(), value: pageSize}}
        onChange={(option: Option<number>) => onPageSizeChange(option.value)}
        isClearable={false}
      />
    </div>
  )
}

export default PaginationSize;
