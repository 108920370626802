import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import GenericModal, {ActionProps} from "./GenericModal";
import {Purchase, PurchaseStatus} from "../../../interfaces/PurchaseInterfaces";
import {purchaseService} from "../../../services/PurchaseService";
import {toastUtils} from "../../../utils/toastUtils";
import {PURCHASES_PATH} from "../../../constants/routes/RoutePaths";
import {useNavigate} from "react-router-dom";
import {Formik} from "formik";
import EpowForm from "../form/EpowForm";
import {Row} from "reactstrap";
import FormInput from "../input/FormInput";
import * as Yup from "yup";
import {yupRequiredString} from "../../../constants/yup/Validators";

interface ModalGenerateInvoiceProps {
  open: boolean,
  onCancel: () => void,
  onValidate: () => void,
  purchase: Purchase
}

const ModalDeletePurchase: FunctionComponent<ModalGenerateInvoiceProps> = ({
  open,
  onValidate,
  onCancel,
  purchase,
}) => {
  const intl = useIntl()
  const validateButton = intl.formatMessage({id: "validate_button"})
  const cancelButton = intl.formatMessage({id: "cancel_button"})

  const [sendMail, setSendMail] = React.useState<boolean>(false)
  const navigate = useNavigate();

  const deletePurchase = ({message} = {message: ""}) => {
    if (purchase?.id) {
      purchaseService.deletePurchase(purchase.id, message)
        .then(() => {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_delete_purchase"}, {number: purchase.purchaseNumber}))
          navigate(PURCHASES_PATH)
          onValidate()
        })
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "error_toast_delete_purchase"}, {number: purchase.purchaseNumber}))
        })
    }
  }

  const footer = {
    primaryAction: {
      form: "delete-purchase",
      buttonLabel: validateButton,
    } as ActionProps,
    secondaryAction: {
      buttonLabel: cancelButton,
      action: onCancel
    }
  };

  if(!sendMail) {
    footer.primaryAction.action = deletePurchase
  }

  return (
    <GenericModal open={open}
                  className="p-3"
                  title={intl.formatMessage({id:"purchase_delete_modal_title"})}
                  footer={footer}
    >
      <div>
        <p><FormattedMessage id="purchase_delete_modal_info" values={{number: purchase.purchaseNumber}}/></p>

        {purchase.status === PurchaseStatus.SENT &&
          <div className="d-flex flex-row">
            <input className="epow-toggle-checkbox me-2 mb-2" id="epow-toggle" type="checkbox" checked={sendMail}
                   onChange={(event) => setSendMail(event.target.checked)}/>
            <FormattedMessage id="purchase_delete_modal_send_mail"/>
          </div>
        }

        {sendMail && <Formik
          initialValues={{message: ""}}
          validationSchema={Yup.object().shape({
            message: yupRequiredString
          })}
          onSubmit={deletePurchase}>
          {({errors, touched, values, setFieldValue}) => (
            <EpowForm id="delete-purchase">
              <Row>
                <FormInput
                  id="message"
                  type="textarea"
                  label={intl.formatMessage({id: "purchase_delete_modal_message_label"})}
                  value={values.message}
                  onChange={(e) => setFieldValue("message", e.target?.value)}
                  required={sendMail}
                  error={errors.message}
                  touched={touched.message}
                  rows="5"
                />
              </Row>
            </EpowForm>
          )}
        </Formik>}
      </div>
    </GenericModal>
  )
}

export default ModalDeletePurchase
