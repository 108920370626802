import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import ReactSelect, {components, InputActionMeta, MenuListProps} from "react-select";
import {SearchFilter} from "../../../interfaces/api/FiltersInterface";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {Data} from "../../../interfaces/TableInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import {Label} from "reactstrap";
import {debounce, isEmpty} from "lodash";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";

export interface AutocompleteFilterProps {
  className?: string,
  title?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  value?: string;
  totalElements?: number,
  fetchData: (filter: SearchFilter) => Promise<Data[] | PageResponse<Data>>,
  manageOptions?: (datas: Data[]| PageResponse<Data>) => Option<string>[]
  optionFromValue?: (datas: Data[] | PageResponse<Data>, value: string) => Option<string>
  filterFieldName: string,
  filterFieldSearch?: string,
  id?: string,
  pageSize?: number,
  initialFilter?: SearchFilter,
  readOnly?: boolean,
}

const AutocompleteFilter: FunctionComponent<AutocompleteFilterProps> = ({
  className = "",
  title,
  placeholder,
  onChange,
  onBlur,
  value,
  fetchData,
  totalElements,
  pageSize = 50,
  initialFilter,
  filterFieldName,
  filterFieldSearch = filterFieldName,
  id,
  readOnly = false,
  manageOptions = (datas: Data[]) => datas?.map(data => ({label: data[filterFieldName], value: data.id})),
  optionFromValue = (datas: Data[], value: string) => {
    const data = datas?.find(item => value === item.id);
    return data ? {
      label: data[filterFieldName],
      value: data.id
    } : null;
  }
}) => {
  const intl = useIntl()
  const [selectedOption, setSelectedOption] = useState<Option<string>>();
  const [options, setOptions] = useState<Option<string>[]>([]);

  const fetchDataWithFilter = (filter, handler: (datas: Data[]) => void) => {
    if (fetchData) {
      fetchData(filter)
        .then(handler)
        .catch(() => {
          toastUtils.errorToast(intl.formatMessage({id: "autocomplete_error"}))
        })
    }
  }

  const updateOptions = (datas: Data[]) => {
    const newOptions = manageOptions(datas);
    setOptions(newOptions)
  }

  const getOptionFromValue = (value: string) => (datas: Data[]) => {
    const newOptions = manageOptions(datas);
    const selectedOption = optionFromValue(datas, value)
    selectedOption != null && setSelectedOption(selectedOption)
    setOptions(newOptions)
  }

  useEffect(() => {
    //dans le cas d'un reset, clear l'option
    if (!value) {
      setSelectedOption(null)
    }
  }, [value])

  // Default value
  const getOptions = () => {
    if (value) {
      fetchDataWithFilter({ids: [value], id: value}, getOptionFromValue(value))
    } else {
      fetchDataWithFilter({...initialFilter}, updateOptions)
    }
  }

  useEffect(() => {
    if (value || initialFilter) {
      getOptions()
    }
  }, [fetchData])

  const debounceFilter = useCallback(
    debounce((actualFilter: SearchFilter) => {
      fetchDataWithFilter(actualFilter, updateOptions)
    }, 300),
    [fetchData]
  );

  const handleChange = (value: string, meta: InputActionMeta) => {
    if (value !== "" || !isEmpty(meta.prevInputValue)) {
      debounceFilter({[filterFieldSearch]: value})
    }
  };

  const handleOptionSelection = (selected: Option<string>) => {
    setSelectedOption(selected)
    onChange(selected !== null ? selected?.value : "")
  }

  const SelectMenuButton = (props: MenuListProps) => {
    return (
      <components.MenuList {...props} className="d-flex flex-column">
        {props.children}
        {totalElements > pageSize && (
          <div className="d-flex ms-auto pe-3 pb-1 fs-12">
            <span>{intl.formatMessage({id: "autocomplete_results_size"}, {total: totalElements - pageSize})}</span>
          </div>
        )}
      </components.MenuList >
    )
  }

  return (
    <div className={`epow-filter ${className}`}>
      {title && <Label className="epow-label">{title}</Label>}

      <ReactSelect
        onFocus={() => getOptions()}
        id={id ? id : `autocomplete-${title}`}
        value={selectedOption}
        placeholder={placeholder ?? ""}
        onChange={handleOptionSelection}
        onBlur={onBlur}
        isDisabled={readOnly}
        options={options}
        className={`epow-select ${value ? "selected" : ""}`}
        onInputChange={(value, meta) => handleChange(value, meta)}
        filterOption={() => true}
        isClearable
        styles={{
          placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: "#8A9095",
            fontStyle: "normal",
            fontSize: "14px"
          }),
          option: (defaultStyles, props) => ({
            ...defaultStyles,
            color: props.label.startsWith("(Inactif)") ? "red" : defaultStyles.color
          })
      }}
        components={{MenuList: SelectMenuButton}}
      />
    </div>
  )
}

export default AutocompleteFilter;
