import {SupplierSettings} from "interfaces/SupplierInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ColorType} from "types/bootstrap/BootstrapType";
import {YES_NO_OPTIONS} from "constants/OptionConstants";
import {optionUtils} from "utils/optionUtils";
import FieldLabel from "../../../atoms/FieldLabel";
import Button from "../../../atoms/Button";

interface SettingsCardProps {
  className?: string;
  supplierSettings?: SupplierSettings;
  editInfo?: boolean;
  setEditInfo?: (editInfo: boolean) => void;
}

const SettingsCard: FunctionComponent<SettingsCardProps> = ({
  className = "",
  supplierSettings,
  editInfo,
  setEditInfo,
}) => {
  const intl = useIntl();
  return (
    <div className={className}>
      <div className="d-flex flex-column gap-3 mb-4">
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_pre_booking_delay"})}
          value={supplierSettings?.preBookingDelay}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_payment_delay"})}
          value={supplierSettings?.paymentDelay}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_has_voucher"})}
          value={optionUtils.translateOption(intl, YES_NO_OPTIONS, supplierSettings?.hasVoucher)}
        />
        <FieldLabel
          verticalDisplay
          label={intl.formatMessage({id : "supplier_apply_vat"})}
          value={optionUtils.translateOption(intl, YES_NO_OPTIONS, supplierSettings?.applyVat)}
        />
        <FieldLabel
          verticalDisplay
          className="white-space-pre-wrap"
          label={intl.formatMessage({id: "supplier_comment"})}
          value={supplierSettings?.comment}
        />
      </div>

      <Button color={ColorType.SECONDARY} onClick={() => setEditInfo(!editInfo)}>
        <FormattedMessage id="edit_button" />
      </Button>
    </div>
  );
};

export default SettingsCard;
