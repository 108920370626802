import React, { FunctionComponent } from "react";
import {FormattedMessage} from "react-intl";
import Button from "../../atoms/Button";

interface ButtonRowSelectActionProps {
  onClick: () => void,
  numberOfElements: number,
  label: string,
  className?: string,
}

const ButtonRowSelectAction : FunctionComponent<ButtonRowSelectActionProps> = ({
                                                                                 onClick,
                                                                                 numberOfElements,
                                                                                 label,
                                                                                 className}) =>
  (<div className={`d-flex flex-row align-items-center justify-content-end ${className}`}>
    <div className="me-2 italic">
      <FormattedMessage id="button_selected_number" values={{selectedNumber: numberOfElements}}/>
    </div>
    <Button onClick={onClick}>
      <FormattedMessage id={label}/>
    </Button>
  </div>)


export default ButtonRowSelectAction;
