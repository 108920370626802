import React, {FunctionComponent} from "react";
import {ResourceCalendarRow} from "../../../interfaces/ResourceCalendarInterfaces";
import Icon from "../../icon/Icon";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import {ORDERS_PATH} from "../../../constants/routes/RoutePaths";
import {UncontrolledTooltip} from "reactstrap";
import ResourceCalendarTooltip from "./ResourceCalendarTooltip";

interface ResourceCalendarActivityCellProps {
  className?: string,
  activity: ResourceCalendarRow,
}

const ResourceCalendarActivityCell: FunctionComponent<ResourceCalendarActivityCellProps> = ({
  className = "",
  activity,
}) => {

  const redirectToOrderItem = () => {
    window.open(`${ORDERS_PATH}/${activity.orderId}#${activity.orderItemId}`)
  }

  const getDateAndHour = () => {
    const rendezVousDate = dateUtils.formatToLocalDateString(dateUtils.parseDate(activity?.rendezVousDate))
    const hour = `${dateUtils.formatLocalTime(activity?.rendezVousHour)} - ${dateUtils.formatLocalTime(activity?.rendezVousEndHour)}`
    return `${rendezVousDate} | ${hour}`
  }

  return (
    <div className={`resource-calendar-activity-cell ${className}`} onClick={() => redirectToOrderItem()}>

        <div className="activity-cell-text">
          <span className="bold">
            {activity?.name}
          </span>
        </div>

        <div className="d-flex">
          <span className="color-gray activity-cell-text">
            {`${activity?.orderReference} | ${activity?.customerName}`}
          </span>

          <div
            id={`staffed-${activity.orderId}-${activity.id}`}
            className={`d-flex ms-auto ${activity.staffed >= activity.numberToStaff ? "color-success" : "color-danger"}`}
          >
            <span>{activity.staffed}/{activity.numberToStaff}</span>
            <Icon className="m-0 ms-2" name="User" size={IconSizeType.XS} />
            <UncontrolledTooltip className="epow-tooltip" placement="bottom" target={`staffed-${activity.orderId}-${activity.id}`}>
              <ResourceCalendarTooltip activity={activity} />
            </UncontrolledTooltip>
          </div>
        </div>

        <div className="d-flex color-secondary">
          <span className="ms-auto">{getDateAndHour()}</span>
          <Icon className="m-0 ms-2" color={ColorType.SECONDARY} name="Clock" size={IconSizeType.XS} />
        </div>

        <div className="d-flex color-secondary">
          <div className="ms-auto activity-cell-text">{activity.place}</div>
          <Icon className="m-0 ms-2" color={ColorType.SECONDARY} name="Location" size={IconSizeType.XS} />
        </div>
    </div>
  )
}

export default ResourceCalendarActivityCell;
