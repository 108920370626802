import React, {FunctionComponent, useState} from "react";
import Title from "../../../atoms/Title";
import Button from "../../../atoms/Button";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import {FormattedMessage, useIntl} from "react-intl";
import {Col} from "reactstrap";
import {CustomerContact, Identity} from "../../../../interfaces/ContactInterfaces";
import ContactCard from "../ContactCard";
import {contactService} from "../../../../services/ContactService";
import {Customer} from "../../../../interfaces/CustomerInterfaces";
import Panel from "../../panel/Panel";
import {getBoolean} from "../../../../utils/booleanUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import {customerContactService} from "../../../../services/CustomerContactService";
import ContactForm from "../../form/contact/ContactForm";

interface MainCustomerContactCardProps {
  className?: string;
  title: string;
  customer?: Customer;
  setCustomer?: (customer: Customer) => void,
}

const MainCustomerContactCard: FunctionComponent<MainCustomerContactCardProps> = ({
  className = "",
  title,
  customer,
  setCustomer = () => null,
}) => {

  const intl = useIntl();
  const identity: Identity = customer?.mainContact?.identity;
  const [createContact, setCreateContact] = useState<boolean>(false);

  const handleValidate = (mainContact: CustomerContact) => {
    setCreateContact(false);
    setCustomer({...customer, mainContact: mainContact.contact})
  };

  const contactCreationForm = () => {
    return createContact ? (
      <>
        <Panel
          formId="createCustomerContactForm"
          title="create_new_contact"
          open={createContact}
          onCancel={() => setCreateContact(false)}
        >
           <ContactForm 
            id="createCustomerContactForm"
            entity={customer}
            isMainContact
            onValidate={handleValidate}
            createContactRequest={customerContactService.createCustomerContact}
          />
        </Panel>
        <div className="ms-4">
          <Button
            form={"createCustomerContactForm"}
            type="submit"
            size={SizeType.MD}
            className="w-25"
          >
            <FormattedMessage id="save_button" />
          </Button>
          <Button
            size={SizeType.MD}
            color={ColorType.SECONDARY}
            onClick={() => setCreateContact(!createContact)}
            className="border border-dark w-25"
          >
            <FormattedMessage id="cancel_button" />
          </Button>
        </div>
      </>
    ) : (
      <div className="d-flex justify-content-center">
        <Button
          size={SizeType.MD}
          color={ColorType.SECONDARY}
          onClick={() => setCreateContact(!createContact)}
          className="border border-dark w-50"
        >
          <FormattedMessage id="create_contact_button" />
        </Button>
      </div>
    );
  };

  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size={"h5"} className="mb-3"/>
      {customer?.mainContact ? (
        <ContactCard
          buttonCallback={() => customerContactService.inviteContactCustomer(customer.id).then((mainContact) => {
            setCustomer({...customer, mainContact: mainContact})
          }).catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_inviting_contact"})))}

          firstName={identity?.firstName}
          lastName={identity?.lastName}
          hasAccount={getBoolean(customer?.mainContact?.hasAccessToPlatform) || customer?.mainContact?.isUserActive}
          lineContact={contactService.buildLineContact(identity)}
        />
      ) : (
        contactCreationForm()
      )}
    </Col>
  );
};

export default MainCustomerContactCard;
