import React, {FunctionComponent} from "react";
import Icon from "./Icon";
import {ColorTypeValues, IconSizeTypeValues} from "../../types/bootstrap/BootstrapType";
import {IconName} from "./IconsMap";

interface GradientIconProps {
  iconName: IconName;
  size: number;
  iconSize?: IconSizeTypeValues;
  className?: string;
  classNameIcon?: string;
  color?: ColorTypeValues,
  onClick?: () => void,
}

const BackgroundIcon: FunctionComponent<GradientIconProps> = ({
  size,
  className = "",
  iconName,
  classNameIcon,
  iconSize,
  color,
  onClick,
}) =>
  (<div onClick={onClick} className={`rounded-circle d-flex justify-content-center ${className}`} style={{width: size, height: size}}>
    <Icon name={iconName} size={iconSize} color={color} className={classNameIcon}/>
  </div>)


export default BackgroundIcon;
