import * as React from "react"

import {ReactComponent as Cross} from "../../assets/cross.svg";
import {ReactComponent as StarRegular} from "../../assets/star-regular.svg";
import {ReactComponent as Alarm} from "../../assets/alarm.svg";
import {ReactComponent as CashRegister} from "../../assets/cash_register.svg";
import {ReactComponent as AddToCart} from "../../assets/add_to_cart.svg";
import {ReactComponent as Book} from "../../assets/book.svg";
import {ReactComponent as Staffing} from "../../assets/staffing.svg";
import {ReactComponent as TourLeader} from "../../assets/tour_leader.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCartShopping,
  faCheck,
  faBan,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faDownload,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileExport,
  faFileInvoiceDollar,
  faFlag,
  faHome,
  faInfoCircle,
  faLink,
  faLocationDot,
  faPaperclip,
  faPen,
  faPlus,
  faQuestionCircle,
  faSearch,
  faStar,
  faTools,
  faTrash,
  faUser,
  faUserCircle,
  faUserPlus,
  faBars,
  faChevronRight,
  faCalendarDays,
  faExclamation,
  faUndo,
  faArrowsUpDown,
  faUserSlash,
  faUserGroup,
  faPhone,
  faMailBulk,
  faChevronLeft,
  faFileInvoice,
  faDatabase,
  faArrowUpRightFromSquare,
  faTriangleExclamation,
  faDroplet,
  faHighlighter,
  faClock,
  faLocationPin,
  faCircle,
  faCreditCard,
  faCirclePlus,
  faCircleMinus,
  faEnvelope,
  faWarning
} from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faUser as farUser,
  faClock as farClock,
} from "@fortawesome/free-regular-svg-icons";

export const ICONS_MAP = {
  AddToCart: <AddToCart />,
  Alarm: <Alarm/>,
  ArrowDown: <FontAwesomeIcon icon={faArrowDown}/>,
  ArrowLeft: <FontAwesomeIcon icon={faArrowLeft}/>,
  ArrowRight: <FontAwesomeIcon icon={faArrowRight}/>,
  ArrowUp: <FontAwesomeIcon icon={faArrowUp}/>,
  ArrowUpRightFromSquare: <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>,
  ArrowsUpDown: <FontAwesomeIcon icon={faArrowsUpDown}/>,
  Ban: <FontAwesomeIcon icon={faBan}/>,
  Bell: <FontAwesomeIcon icon={faBell}/>,
  Book: <Book />,
  Calendar: <FontAwesomeIcon icon={faCalendarDays}/>,
  CartShopping: <FontAwesomeIcon icon={faCartShopping}/>,
  CashRegister: <CashRegister />,
  CircleMinus: <FontAwesomeIcon icon={faCircleMinus}/>,
  CirclePlus: <FontAwesomeIcon icon={faCirclePlus}/>,
  CheckCircle: <FontAwesomeIcon icon={faCheckCircle}/>,
  ChevronDown: <FontAwesomeIcon icon={faChevronDown}/>,
  ChevronLeft: <FontAwesomeIcon icon={faChevronLeft}/>,
  ChevronRight: <FontAwesomeIcon icon={faChevronRight}/>,
  ChevronUp: <FontAwesomeIcon icon={faChevronUp}/>,
  Check: <FontAwesomeIcon icon={faCheck}/>,
  CreditCard: <FontAwesomeIcon icon={faCreditCard}/>,
  Cross: <Cross/>,
  Database: <FontAwesomeIcon icon={faDatabase}/>,
  DeactivateUser: <FontAwesomeIcon icon={faUserSlash}/>,
  Download: <FontAwesomeIcon icon={faDownload}/>,
  Droplet: <FontAwesomeIcon icon={faDroplet}/>,
  Email: <FontAwesomeIcon icon={faMailBulk}/>,
  Exclamation: <FontAwesomeIcon icon={faExclamation}/>,
  ExclamationTriangle: <FontAwesomeIcon icon={faExclamationTriangle}/>,
  Eye: <FontAwesomeIcon icon={faEye}/>,
  File: <FontAwesomeIcon icon={faFile}/>,
  FileExport: <FontAwesomeIcon icon={faFileExport}/>,
  FileInvoiceDollar: <FontAwesomeIcon icon={faFileInvoiceDollar}/>,
  Flag: <FontAwesomeIcon icon={faFlag}/>,
  Highlight: <FontAwesomeIcon icon={faHighlighter}/>,
  Home: <FontAwesomeIcon icon={faHome}/>,
  InfoCircle: <FontAwesomeIcon icon={faInfoCircle}/>,
  Invoice: <FontAwesomeIcon icon={faFileInvoice}/>,
  Link: <FontAwesomeIcon icon={faLink}/>,
  LocationDot: <FontAwesomeIcon icon={faLocationDot}/>,
  Menu: <FontAwesomeIcon icon={faBars}/>,
  PaperClip: <FontAwesomeIcon icon={faPaperclip}/>,
  Pen: <FontAwesomeIcon icon={faPen}/>,
  Phone: <FontAwesomeIcon icon={faPhone}/>,
  Plus: <FontAwesomeIcon icon={faPlus}/>,
  QuestionCircle: <FontAwesomeIcon icon={faQuestionCircle}/>,
  Search: <FontAwesomeIcon icon={faSearch}/>,
  Star: <StarRegular/>,
  Staffing: <Staffing />,
  StarFill: <FontAwesomeIcon icon={faStar}/>,
  Tools: <FontAwesomeIcon icon={faTools}/>,
  TourLeader: <TourLeader />,
  Trash: <FontAwesomeIcon icon={faTrash}/>,
  TriangleExclamation: <FontAwesomeIcon icon={faTriangleExclamation}/>,
  Undo: <FontAwesomeIcon icon={faUndo}/>,
  User: <FontAwesomeIcon icon={faUser}/>,
  UserCircle: <FontAwesomeIcon icon={faUserCircle}/>,
  UserPlus: <FontAwesomeIcon icon={faUserPlus}/>,
  UserGroup: <FontAwesomeIcon icon={faUserGroup}/>,
  UserRegular: <FontAwesomeIcon icon={farUser}/>,
  Clock: <FontAwesomeIcon icon={faClock}/>,
  ClockRegular: <FontAwesomeIcon icon={farClock}/>,
  Location: <FontAwesomeIcon icon={faLocationPin}/>,
  Circle: <FontAwesomeIcon icon={faCircle}/>,
  Envelope: <FontAwesomeIcon icon={faEnvelope}/>,
  Warning: <FontAwesomeIcon icon={faWarning}/>,
} as const

export type IconName = keyof typeof ICONS_MAP
