import {Data} from "./TableInterfaces";

export interface VATRate extends Data {
  rate: number;
  name: string
}

export const VAT_RATE_INITIAL_VALUES: VATRate = {
  name: "",
  rate: 0
}
