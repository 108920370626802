import React, {FunctionComponent, useState} from "react";
import {FormattedMessage} from "react-intl";
import {ColorType, SizeType} from "../../../../types/bootstrap/BootstrapType";
import Button from "../../../atoms/Button";
import StaffingFormStepOne from "./StaffingFormStepOne";
import StaffingFormStepTwo from "./StaffingFormStepTwo";
import {ResourceForStaffingDto, ResourceStaffingRequest} from "../../../../interfaces/ResourceStaffingInterfaces";

interface StaffingFormProps {
  className?: string,
  orderItemId?: string,
  maxResources?: number,
  setFormOpen: (open: boolean) => void,
  hasStaffing?: boolean,
  hasFilters?: boolean,
  hasDirectStaffing?: boolean,
  sendRequest: (isDirectStaffing: boolean, selectedResources: ResourceForStaffingDto[], resourceRequest: ResourceStaffingRequest[]) => void,
  initialResourceTypes?: string[];
}

const StaffingForm: FunctionComponent<StaffingFormProps> = ({
  className = "",
  orderItemId = "",
  maxResources = 0,
  setFormOpen,
  hasStaffing = false,
  hasFilters = true,
  hasDirectStaffing = true,
  sendRequest,
  initialResourceTypes
}) => {
  const [step, setStep] = useState<number>(0);
  const [isDirectStaffing, setIsDirectStaffing] = useState<boolean>(false);
  const [selectedResources, setSelectedResources] = useState<ResourceForStaffingDto[]>([]);
  const [resourceRequest, setResourceRequest] = useState<ResourceStaffingRequest[]>([]);

  const onCancel = () => {
    return setFormOpen(false);
  }

  const goToNextStep = () => {
    let messageById = {}
    resourceRequest.forEach(r => messageById = {...messageById, [r.resourceId]: r.message})

    const request: ResourceStaffingRequest[] = selectedResources.map(resource => ({resourceId: resource.id, message: messageById[resource.id] ?? ""}))
    setResourceRequest(request)
    setStep(1)
  }

  return (
    <div className={`w-100 d-flex flex-column flex-shrink ${className ?? ""}`}>
      {step === 0 && (
        <>
          <StaffingFormStepOne
            className="d-flex flex-column flex-shrink"
            orderItemId={orderItemId}
            maxResources={maxResources}
            setIsDirectStaffing={setIsDirectStaffing}
            isDirectStaffing={isDirectStaffing}
            setSelectedResources={setSelectedResources}
            selectedResources={selectedResources}
            hasStaffing={hasStaffing}
            hasFilters={hasFilters}
            hasDirectStaffing={hasDirectStaffing}
            initialResourceTypes={initialResourceTypes}
          />

          <div className="d-flex justify-content-end mt-5">
            <Button size={SizeType.MD} onClick={onCancel} color={ColorType.SECONDARY} className="extended me-3">
              <FormattedMessage id="cancel_button" />
            </Button>

            {isDirectStaffing ? (
              <Button size={SizeType.MD} disabled={selectedResources.length === 0} onClick={() => sendRequest(isDirectStaffing, selectedResources, resourceRequest)} className="extended">
                <FormattedMessage id="validate_button" />
              </Button>
            ) : (
              <Button disabled={selectedResources.length === 0} onClick={goToNextStep} className="extended">
                <FormattedMessage id="next_button" />
              </Button>
            )}
          </div>
        </>
      )}

      {step === 1 && (
        <>
          <StaffingFormStepTwo resourceRequests={resourceRequest} setResourceRequests={setResourceRequest} selectedResources={selectedResources} />

          <div className="d-flex justify-content-between mt-5">
            <div>
              <Button onClick={onCancel} color={ColorType.SECONDARY} className="extended me-3">
                <FormattedMessage id="cancel_button" />
              </Button>
            </div>
            <div>
              <Button onClick={() => setStep(0)} color={ColorType.SECONDARY} className="extended me-3">
                <FormattedMessage id="previous_button" />
              </Button>
              <Button type="submit" className="extended" onClick={() => sendRequest(isDirectStaffing, selectedResources, resourceRequest)}>
                <FormattedMessage id="validate_button" />
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default StaffingForm;
