import * as Yup from "yup";
import {reg_mail} from "../RegexConstants";

const requiredError = "mandatory_field";
const maxLengthError = "max_length_error_field";
const invalidEmail = "field_email_invalid";
const positiveNumber = "field_number_positive";
const positiveOrZeroNumber = "field_number_positive_or_zero";
const ibanRegexError = "invalid_iban_field";
const invalidLengthEmail = "login_invalid_length";
const invalidPassword = "password_invalid";

// STRINGS
export const yupRequiredString: Yup.StringSchema = Yup.string().required(requiredError).typeError(requiredError);
export const yupNullableString: Yup.StringSchema = Yup.string().nullable();
export const yupRequireStringMaxLength = (max: number): Yup.StringSchema => Yup.string().required(requiredError).typeError(requiredError).max(max, maxLengthError);
export const yupRequiredStringIban: Yup.StringSchema = Yup.string().matches(/^[A-Z]{2}\d{2}[A-Za-z0-9]{1,30}$/, ibanRegexError);
export const yupRequiredPassword = Yup.string().required(requiredError).typeError(requiredError);
export const yupRequiredValidPassword = Yup.string().required(requiredError).typeError(requiredError).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-_]{8,}$/, invalidPassword);

export const yupCorrectEmail = Yup.string().matches(reg_mail, invalidEmail);
export const yupNullableEmail = yupCorrectEmail.nullable();
export const yupRequiredEmail = (min: number, max: number): Yup.StringSchema =>
  yupCorrectEmail.required(requiredError).typeError(requiredError).min(min, invalidLengthEmail).max(max, invalidLengthEmail);

export const getRequiredStringForCondition = (field: string | string[], condition: unknown): Yup.StringSchema =>
  Yup.string().nullable().when(field, {is: condition, then: yupRequiredString});

// NUMBERS
export const yupRequiredNumber = Yup.number().typeError(requiredError).required(requiredError);
export const yupRequiredDate = Yup.date().required(requiredError).typeError(requiredError);
export const yupRequiredPositiveNumber = Yup.number().typeError(requiredError).required(requiredError).moreThan(0, positiveNumber);
export const yupPositiveNumber = Yup.number().moreThan(0, positiveNumber);
export const yupRequiredPositiveOrZeroNumber = Yup.number().typeError(requiredError).required(requiredError).min(0, positiveOrZeroNumber);
export const getRequiredNumberForCondition = (field: string, condition: unknown): Yup.NumberSchema =>
  Yup.number().nullable().when(field, {is: condition, then: yupRequiredNumber});

export const getRequiredPositiveNumberForCondition = (field: string, condition: unknown): Yup.NumberSchema =>
  Yup.number().nullable().when(field, {is: condition, then: yupRequiredPositiveNumber});

// BOOLEANS
export const yupRequiredBoolean: Yup.BooleanSchema = Yup.boolean().required(requiredError).typeError(requiredError);

export const getRequiredBooleanForCondition = (field: string, condition: unknown): Yup.BooleanSchema =>
  Yup.boolean().nullable().when(field, {is: condition, then: yupRequiredBoolean});

// ARRAYS
export const yupRequiredArray = (min: number): Yup.ArraySchema<Yup.AnySchema> => Yup.array().required(requiredError).min(min, requiredError);

// OBJECTS
export const yupNotRequiredObject = Yup.object().nullable().notRequired();
