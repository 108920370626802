import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {Contact} from "../../../../interfaces/ContactInterfaces";
import {customerContactService} from "../../../../services/CustomerContactService";
import {toastUtils} from "../../../../utils/toastUtils";
import {Customer} from "../../../../interfaces/CustomerInterfaces";
import ContactList from "./ContactList";

interface CustomerContactListProps {
  className?: string;
  customer?: Customer;
}

const CustomerContactList: FunctionComponent<CustomerContactListProps> = ({
  className = "",
  customer,
}) => {
  const intl = useIntl();
  const [contacts, setContacts] = useState<Contact[]>([]);

  const getContactByCustomer = (filter?: string) => {
    customerContactService.getAllContactsByCustomerId(customer?.id, filter ? {filter: filter} : null)
      .then((contacts) => {
        setContacts(contacts);
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_contacts"}));
      });
  };

  useEffect(() => {
    getContactByCustomer(null);
  }, []);

  return (
    <div>
      <ContactList
        className={className}
        entity={customer}
        onUpdate={() => null}
        contacts={contacts}
        refreshData={getContactByCustomer}
        createContactRequest={customerContactService.createCustomerContact}
        updateMainContactRequest={customerContactService.updateCustomerContact}
      />
    </div>
  );
};

export default CustomerContactList;
