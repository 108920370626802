import {Comment, CommentRequest} from "interfaces/CommentInterface";
import {Resource, ResourceContactInfo} from "interfaces/ResourceInterfaces";
import React, {FunctionComponent, useState} from "react";
import {useIntl} from "react-intl";
import {resourceService} from "services/ResourceService";
import {toastUtils} from "utils/toastUtils";
import CommentCard from "../comment/CommentCard";
import MainResourceContactCard from "./MainResourceContactCard";
import UploadAvatar from "../../images/UploadAvatar";
import Title from "../../../atoms/Title";
import {profileUtils} from "../../../../utils/profileUtils";

interface ResourceContactAndCommentCardProps {
  className?: string;
  resource?: Resource;
  setResource?: (resource: Resource) => void;
}

const ResourceContactAndCommentCard: FunctionComponent<ResourceContactAndCommentCardProps> = ({
  className = "",
  resource,
  setResource
}) => {
  const intl = useIntl();
  const [comment, setComment] = useState<Comment>(resource.comment);

  const getResourceContactInfo = (): ResourceContactInfo => ({
    id: resource.id,
    firstName: resource.identity?.firstName,
    lastName: resource.identity?.lastName,
    email: resource.identity?.email,
    phoneNumber: resource.identity?.phoneNumber,
    hasAccessPlatform: resource.hasAccessPlatform,
    isUserActive: resource.isUserActive
  });

  const createComment = async (commentRequest: CommentRequest): Promise<void> => {
    return resourceService.createResourceComment(resource.id, commentRequest)
      .then((createdComment: Comment) => {
        setComment(createdComment);
        setResource({...resource, comment: createdComment});

        toastUtils.successToast(intl.formatMessage({id: "comment_toast_success_creation"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_creation"}));
      });
  };

  const updateComment = async (commentRequest: CommentRequest): Promise<void> => {
    return resourceService.updateResourceComment(resource.id, commentRequest)
      .then((createdComment: Comment) => {
        setComment(createdComment);
        setResource({...resource, comment: createdComment});

        toastUtils.successToast(intl.formatMessage({id: "comment_toast_success_update"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_update"}));
      });
  };

  const deleteComment = (): void => {
    resourceService
      .deleteResourceComment(resource.id)
      .then(() => {
        setComment(null);
        setResource({...resource, comment: null});
        toastUtils.infoToast(intl.formatMessage({id: "comment_toast_success_deletion"}));
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "comment_toast_error_deletion"}));
      });
  };

  return (
    <div>
      <div className="mb-3">
        <Title title={intl.formatMessage({id: "resource_profile_picture"})} size="h5" />
      </div>
      <UploadAvatar className="mb-3"
        resourceId={resource.id}
        onUpload={(blob) => {
          const formData = new FormData();
          formData.set("profilePicture", blob)
          resourceService.uploadProfilePicture(resource.id, formData)
            .catch(() => {
              toastUtils.errorToast(intl.formatMessage({id: "error_profile_picture"}))
            })
        }}
      />
      <MainResourceContactCard
        resourceId={resource.id}
        onChangeResource={setResource}
        className={className}
        resourceContactInfo={getResourceContactInfo()}
        title={intl.formatMessage({id: "main_contact"})}
      />
      {profileUtils.isInterne() && (
        <CommentCard
          className="mt-4 d-flex flex-column"
          comment={comment}
          createComment={createComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
          title={intl.formatMessage({id: "resource_comment"})}
        />
      )}
    </div>
  );
};

export default ResourceContactAndCommentCard;
