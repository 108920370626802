import {httpService} from "./HttpService";
import {API_CREDIT_NOTES_PATH, API_INVOICES_PATH} from "../constants/routes/RoutePaths";
import {
  Invoice,
  InvoiceItemResponse,
  InvoiceSearchObject, InvoiceState
} from "../interfaces/InvoiceInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";

function getInvoices(params?: PaginationQueryParams, filter?: InvoiceSearchObject): Promise<PageResponse<InvoiceItemResponse>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<InvoiceItemResponse>>(`${API_INVOICES_PATH}${queryParams}`);
}

function getCreditNote(id: string): Promise<Invoice> {
  return httpService.get<Invoice>(`${API_CREDIT_NOTES_PATH}/${id}`);
}

function createCreditNote(parentInvoiceId: string, billingItemsIds: string[]): Promise<Invoice> {
  return httpService.post<Invoice>(`${API_CREDIT_NOTES_PATH}`, {
    parentInvoiceId,
    billingItemsIds
  });
}

const downloadCreditNote = (invoiceId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_CREDIT_NOTES_PATH}/${invoiceId}/download`);
}


function manageTransition(invoiceId: string, targetStatus : InvoiceState): Promise<Invoice> {
  return httpService.post<Invoice>(`${API_INVOICES_PATH}/${invoiceId}/transition?target=${targetStatus}`, {});
}

export const creditNoteService = {
  getInvoices,
  manageTransition,
  downloadCreditNote,
  createCreditNote,
  getCreditNote
};
