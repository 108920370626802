import React, {FunctionComponent, useMemo, useState} from "react";
import {RouteProps, useSearchParams} from "react-router-dom";
import Layout from "../../components/layout/Layout";
import {useIntl} from "react-intl";
import {authUtils} from "../../utils/authUtils";
import {PROFILE} from "../../interfaces/UserInterfaces";
import {QuoteSearchObject, QuoteState} from "../../interfaces/QuoteInterfaces";
import {dateUtils} from "../../utils/dateUtils";
import useLoading from "../../hooks/useLoading";
import useSearchFilter from "../../hooks/useSearchFilter";
import HeaderButtonGroup, {HeaderStateButton} from "../../components/molecules/header/HeaderButtonGroup";
import PageTitle from "../../components/molecules/header/PageTitle";
import Button from "../../components/atoms/Button";
import Panel from "../../components/molecules/panel/Panel";
import CreateQuoteForm from "../../components/molecules/form/CreateQuoteForm";
import QuoteList from "../../components/molecules/list/QuoteList";

const QuoteListView: FunctionComponent<RouteProps> = () => {
  const intl = useIntl()
  const [params] = useSearchParams()
  const isCustomer = authUtils.getProfile() === PROFILE.CLIENT;

  const initialSearch: QuoteSearchObject = {
    customerOrCode: "",
    customerId: "",
    startDate: isCustomer ? dateUtils.formatDateYYYYMMDD(new Date()) : "",
    endDate: "",
    status: QuoteState.DRAFT
  }

  const {loading, startLoading, stopLoading} = useLoading()
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const {searchObject, updateSearchField, onReset} = useSearchFilter<QuoteSearchObject>(initialSearch)

  const stateFilterButtons: HeaderStateButton[] = useMemo(() => {
    const statutParam = params.get("status");
    return [
      {
        title: intl.formatMessage({id: "quote_filter_open"}),
        onclick: () => updateSearchField("status", QuoteState.DRAFT),
        active: statutParam === QuoteState.DRAFT
      },
      {
        title: intl.formatMessage({id: "quote_filter_to_handle"}),
        onclick: () => updateSearchField("status", QuoteState.SENT),
        active: statutParam === QuoteState.SENT
      },
      {
        title: intl.formatMessage({id: "quote_filter_to_win"}),
        onclick: () => updateSearchField("status", QuoteState.WIN),
        active: statutParam === QuoteState.WIN
      },
      {
        title: intl.formatMessage({id: "quote_filter_to_loose"}),
        onclick: () => updateSearchField("status", QuoteState.LOOSE),
        active: statutParam === QuoteState.LOOSE
      }]
  }, [intl, params.get("status")])

  return (
    <Layout>
      <div className="epow-content-body d-flex flex-column">
        <div className="d-flex justify-content-between mb-5">
          <PageTitle pageTitle={intl.formatMessage({id: "quotes_title"})}></PageTitle>
          <HeaderButtonGroup buttonList={stateFilterButtons}/>
          <div>
            {(authUtils.getProfile() === PROFILE.INTERN || authUtils.getProfile() === PROFILE.ADMIN) && (
              <Button className="btn-header" onClick={() => setFormOpen(true)}>
                {intl.formatMessage({id: "create_quote"})}
              </Button>
            )}
          </div>
        </div>

        <QuoteList searchObject={searchObject} updateSearchField={updateSearchField} onReset={() => onReset()}/>

        {formOpen && (
          <Panel formId="createQuoteForm" title="create_quote" open={formOpen} loading={loading} onCancel={() => {
            setFormOpen(false)
            stopLoading()
          }}>
            <CreateQuoteForm
              id="createQuoteForm"
              startLoading={startLoading}
              stopLoading={stopLoading}/>
          </Panel>
        )}
      </div>
    </Layout>
  )
}

export default QuoteListView;
