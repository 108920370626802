import React, {FunctionComponent} from "react";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import Title from "../../../atoms/Title";
import ContactCard from "../ContactCard";
import {supplierUtils} from "../../../../utils/supplierUtils";

interface ServiceDefaultSupplierCardProps {
  className?: string;
  service: Service;
  title: string;
}

const ServiceDefaultSupplierCard: FunctionComponent<
  ServiceDefaultSupplierCardProps
> = ({ className = "", service, title }) => {

  return (
    <div className={className}>
      <div className="mb-3">
        <Title title={title} size={"h5"} />
      </div>
      {service?.defaultSupplier ? (
        <div>
          <ContactCard
            firstName={service?.defaultSupplier?.name}
            lineContact={supplierUtils.buildSupplierLineContact(service?.defaultSupplier)}
          />
        </div>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

export default ServiceDefaultSupplierCard;
