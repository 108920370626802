import React, {FunctionComponent} from "react";
import {Cell} from "react-table";
import {ReportingTableResourceBody, ReportingTableResourceCell} from "../../../../interfaces/CalendarInterface";
import {useNavigate} from "react-router-dom";
import {STAFFING_PATH} from "../../../../constants/routes/RoutePaths";
import {OrderItemStaffingStatus} from "../../../../interfaces/OrderItemInterfaces";
import {MONTHS_ABV} from "../../../../constants/StaffingReportingConstants";
import {dateUtils} from "../../../../utils/dateUtils";

interface CellCalendarProps {
    className?: string,
    cell: Cell<ReportingTableResourceCell>,
    accessor: string
    year: number,
}

const CellResourceReporting: FunctionComponent<CellCalendarProps> = ({
                                                                        cell,
                                                                        accessor,
                                                                        className,
                                                                        year,
                                                                    }) => {

    const originalElement = cell.row.original[accessor] as ReportingTableResourceBody;
    const navigate = useNavigate();

    const redirectToStaffing = () => {
      const selectedMonth: number = Object.keys(MONTHS_ABV).indexOf(accessor)

      const firstDay = new Date(year, selectedMonth, 1)
      const lastDay = new Date(year, selectedMonth + 1, 0)

      const startDate = dateUtils.formatDateYYYYMMDD(firstDay)
      const endDate = dateUtils.formatDateYYYYMMDD(lastDay)
      navigate(`${STAFFING_PATH}?staffingStatus=${OrderItemStaffingStatus.STAFFED}&startDate=${startDate}&endDate=${endDate}&resourceIds=${cell.row.original.id}`);
    }

    return (
        <div onClick={() => redirectToStaffing()} className={`staffing-calendar__cell sm d-flex justify-content-center align-items-center ms-1 text-nowrap ${className}`}>
          {originalElement?.hoursWorked ?? 0} h
        </div>);
}

export default CellResourceReporting;
