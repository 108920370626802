import CreateServiceForm from "../../components/molecules/form/CreateServiceForm";
import React, {FunctionComponent} from "react";
import CreateSupplierForm from "../../components/molecules/form/CreateSupplierForm";
import CreateResourceForm from "../../components/molecules/form/resource/CreateResourceForm";
import CreateOrderForm from "../../components/molecules/form/CreateOrderForm";
import CreateTourForm from "../../components/molecules/form/CreateTourForm";
import CreateCustomerForm from "../../components/molecules/form/CreateCustomerForm";
import CreateTourLeaderForm from "../../components/molecules/form/CreateTourLeaderForm";
import CreateActivityForm from "../../components/molecules/form/CreateActivityForm";
import CreateInvoiceForm from "../../components/molecules/form/CreateInvoiceForm";
import CreateUserForm from "../../components/molecules/form/user/CreateUserForm";
import {PROFILE} from "../../interfaces/UserInterfaces";
import CreateQuoteForm from "../../components/molecules/form/CreateQuoteForm";

export const enum MenuCreateEntry {
  CLIENT = "CLIENT",
  RESOURCE = "RESOURCE",
  ORDER = "ORDER",
  SERVICE = "SERVICE",
  TOUR = "TOUR",
  SUPPLIER = "SUPPLIER",
  ACTIVITY = "ACTIVITY",
  USER = "USER",
  TOUR_LEADER = "TOUR_LEADER",
  INVOICE = "INVOICE",
  QUOTE = "QUOTE"
}

export interface LoadingComponent {
  startLoading: () => void,
  stopLoading: () => void,
  closeForm?: () => void,
}

export interface LayoutDropdownItem {
  formId: string;
  titleLabel: string;
  formChild: FunctionComponent<LoadingComponent>
}

export interface MenuCreateEntries {
  [key: string]: LayoutDropdownItem
}

export const MENU_CREATE_ENTRIES: MenuCreateEntries = {
  [MenuCreateEntry.CLIENT]: {
    formId: "createCustomerForm",
    titleLabel: "create_new_customer",
    formChild: (props) => <CreateCustomerForm id="createCustomerForm"{...props}/>
  },
  [MenuCreateEntry.RESOURCE]: {
    formId: "createResourceForm",
    titleLabel: "create_new_resource",
    formChild: (props) => <CreateResourceForm id="createResourceForm" {...props}/>
  },
  [MenuCreateEntry.ORDER]: {
    formId: "createOrderForm",
    titleLabel: "create_order",
    formChild: (props) => <CreateOrderForm id="createOrderForm" {...props}/>
  },
  [MenuCreateEntry.SERVICE]: {
    formId: "createServiceForm",
    titleLabel: "create_new_service",
    formChild: (props) => <CreateServiceForm id="createServiceForm" {...props}/>
  },
  [MenuCreateEntry.TOUR]: {
    formId: "createTourForm",
    titleLabel: "create_new_tour",
    formChild: (props) => <CreateTourForm id="createTourForm" {...props}/>
  },
  [MenuCreateEntry.SUPPLIER]: {
    formId: "createSupplierForm",
    titleLabel: "create_new_supplier",
    formChild: (props) => <CreateSupplierForm id="createSupplierForm" {...props}/>
  },
  [MenuCreateEntry.TOUR_LEADER]: {
    formId: "createTourLeaderForm",
    titleLabel: "create_new_tour_leader",
    formChild: (props) => <CreateTourLeaderForm id="createTourLeaderForm" {...props}/>
  },
  [MenuCreateEntry.ACTIVITY]: {
    formId: "createActivityForm",
    titleLabel: "create_new_activity",
    formChild: (props) => <CreateActivityForm id="createActivityForm" {...props}/>
  },
  [MenuCreateEntry.INVOICE]: {
    formId: "createInvoiceForm",
    titleLabel: "create_new_invoice",
    formChild: (props) => <CreateInvoiceForm id="createInvoiceForm" {...props}/>
  },
  [MenuCreateEntry.USER]: {
    formId: "createUserForm",
    titleLabel: "create_new_user",
    formChild: (props) => <CreateUserForm profiles={[PROFILE.INTERN]} id="createUserForm" {...props}/>
  },
  [MenuCreateEntry.QUOTE]: {
    formId: "createQuoteForm",
    titleLabel: "create_new_quote",
    formChild: (props) => <CreateQuoteForm id="createQuoteForm" {...props}/>
  }

}
