import React, {FunctionComponent, useEffect, useState} from "react";
import TextFilter from "../TextFilter";
import SelectFilter from "../SelectFilter";
import {RESOURCE_STAFFING_AVAILABILITY_OPTIONS} from "../../../../constants/OptionConstants";
import {useIntl} from "react-intl";
import CheckboxFilter from "../CheckboxFilter";
import {ResourceForStaffingDto, ResourceStaffingSearchParams} from "../../../../interfaces/ResourceStaffingInterfaces";
import {ResourceAvailability} from "../../../../constants/ResourceConstants";
import {resourceStaffingService} from "../../../../services/ResourceStaffingService";
import {toastUtils} from "../../../../utils/toastUtils";
import AutocompleteMultiselect from "../AutocompleteMultiselect";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";

interface StaffingFormResourceSelectorFiltersProps {
  onSearchChange?: (resources: ResourceForStaffingDto[]) => void,
  orderItemId: string,
  initialResourceTypes?: string[];
}

const StaffingFormResourceSelectorFilters: FunctionComponent<StaffingFormResourceSelectorFiltersProps> = ({
  onSearchChange,
  orderItemId,
  initialResourceTypes
}) => {
  const intl = useIntl()
  const initialSearchObject: ResourceStaffingSearchParams = {
    orderItemId,
    filterOnMatchingActivities: true,
    filterOnKids: true,
    filterOnPublicType: true,
    availability: ResourceAvailability.HIGH,
  }

  const [searchObject, setSearchObject] = useState<ResourceStaffingSearchParams>(initialSearchObject)

  const fetchResources = (searchObject: ResourceStaffingSearchParams) => {
    resourceStaffingService.getResourcesForStaffing(orderItemId, searchObject)
      .then(response => {
        response.sort((a, b) => {
          return a.firstName[0].localeCompare(b.firstName[0]);
        });
        onSearchChange(response)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_resources"}))
      })
  }

  const onSearchFieldUpdate = (field: string, value: string | boolean | string[]) => {
    setSearchObject((previousState) => {
      const updatedSearchObject: ResourceStaffingSearchParams = {...previousState, [field]: value}
      fetchResources(updatedSearchObject)
      return updatedSearchObject
    })
  }

  useEffect(() => {
    resourceStaffingService.getHighestAvailability(searchObject)
      .then((availability) => {
        onSearchFieldUpdate("availability",  availability === ResourceAvailability.ZONE ? "" : availability )
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_resources"}))
      })
  }, [])

  return (
    <div className="d-flex flex-column mb-2">
      <div className="d-flex flex-row gap-2 mb-1">
        <TextFilter
          className="main-filter"
          value={searchObject?.nameSurname ?? ""}
          onChange={(value) => onSearchFieldUpdate("nameSurname", value)}
          title={intl.formatMessage({id: "resource_name_filter"})}
        />

        <SelectFilter
          options={RESOURCE_STAFFING_AVAILABILITY_OPTIONS.map(o => ({label: intl.formatMessage({id: o.label}), value: o.value}))}
          onChange={(value) => onSearchFieldUpdate("availability", value)}
          title={intl.formatMessage({id: "staffing_availability"})}
          value={searchObject.availability}
          isClearable={false}
        />
      </div>
      <div className="d-flex flex-row mb-1">
        <AutocompleteMultiselect
          title={intl.formatMessage({id: "resource_type"})}
          values={searchObject.resourceTypes ?? []}
          onChange={(values) => onSearchFieldUpdate("resourceTypes", values)}
          fetchData={resourceTypeService.getResourceTypePage}
          filterFieldName="label"
          className="me-2 col"
          classNameLabel="mb-1 fw-bold"
          filterOption={(option: Option<string>) => initialResourceTypes === undefined || initialResourceTypes?.length == 0 || initialResourceTypes.includes(option.value)}
        />
      </div>

      <div className="d-flex flex-row flex-wrap justify-content-between">
        <div className="bold">
          {intl.formatMessage({id: "resource_filter_criteria"})}
        </div>
        <CheckboxFilter
          title={intl.formatMessage({id: "resource_filter_on_activity"})}
          value={searchObject.filterOnMatchingActivities}
          onChange={(value) => onSearchFieldUpdate("filterOnMatchingActivities", value)}
        />
        <CheckboxFilter
          title={intl.formatMessage({id: "resource_filter_on_public_type"})}
          value={searchObject.filterOnPublicType}
          onChange={(value) => onSearchFieldUpdate("filterOnPublicType", value)}
        />
        <CheckboxFilter
          title={intl.formatMessage({id: "resource_filter_on_kids"})}
          value={searchObject.filterOnKids}
          onChange={(value) => onSearchFieldUpdate("filterOnKids", value)}
        />
      </div>
    </div>
  )
}

export default StaffingFormResourceSelectorFilters;
