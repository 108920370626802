import React, {FunctionComponent, useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Button from "../components/atoms/Button";
import Layout from "../components/layout/Layout";
import UserProfileCard from "../components/molecules/card/user/UserProfileCard";
import EditUserProfileForm from "../components/molecules/form/user/EditUserProfileForm";
import PageTitle from "../components/molecules/header/PageTitle";
import {HOME_PATH} from "../constants/routes/RoutePaths";
import {UserSelfProfile} from "../interfaces/UserInterfaces";
import {userService} from "../services/UserService";
import {ColorType, SizeType} from "../types/bootstrap/BootstrapType";
import {toastUtils} from "../utils/toastUtils";

const SelfProfileView: FunctionComponent = () => {
  const intl = useIntl()
  const [me, setMe] = useState<UserSelfProfile>({})
  const [editing, setEditing] = useState<boolean>(false)

  const getUser = () => {
    userService.getMe()
      .then((user) => {
        setMe(user)
      }).catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_get_user"}))
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  const onUserEdited = () => {
    setEditing(false);
    getUser()
  }

  return (
    <Layout>
      <div className="epow-content-body">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <div className="d-flex flex-column align-items-center">
            <PageTitle
              pageTitle={`${me.firstName} ${me.lastName}`}
              className="me-4"
              breadcrumbItems={[{
                title: intl.formatMessage({id: "menu_dashboard"}),
                to: HOME_PATH
              }]}
            />

            {editing ? (
              <div>
                <EditUserProfileForm
                  className="mt-3"
                  user={me}
                  id={"userSelfEditForm"}
                  setEditing={setEditing}
                  onEdit={onUserEdited}
                />
              </div>
            ) : (
              <div className="w-100">
                <div className="w-100 d-flex flex-column mt-2">
                  <UserProfileCard
                    user={me}
                    className="mb-2"
                  />
                </div>
                <Button size={SizeType.MD} color={ColorType.SECONDARY} onClick={() => setEditing(!editing)} className="extended">
                  <FormattedMessage id="edit_button" />
                </Button>
              </div>
            )}

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SelfProfileView;
