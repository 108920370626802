import React, {FunctionComponent, useRef} from "react";
import {UncontrolledTooltip as ReactStrapTooltip} from "reactstrap";
import { Placement } from "../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";

interface TooltipProps {
  text: string
  children: React.ReactElement
  className?: string,
  placement?: Placement
}

const Tooltip : FunctionComponent<TooltipProps> = ({
  text,
  children,
  className = "",
  placement = Placement.BOTTOM
}) => {
  const intl = useIntl()
  const ref = useRef(null)
  const textToUse = intl.messages[text] ? intl.formatMessage({id: text }) : text

  return (
    <>
      <div ref={ref}>
        {children}
      </div>
      <ReactStrapTooltip className={`epow-tooltip ${className}`} target={ref} placement={placement}>
        { textToUse }
      </ReactStrapTooltip>
    </>
  )
}


export default Tooltip;
