import React, {FunctionComponent, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import Compressor from "compressorjs";
import {toastUtils} from "../../../utils/toastUtils";
import {resourceService} from "../../../services/ResourceService";
import Cropper from "./Cropper";

interface AvatarProps {
    className?: string;
    resourceId: string;
    onUpload: (image: File | Blob) => void;
}

const UploadAvatar: FunctionComponent<AvatarProps> = ({className, resourceId, onUpload}) => {
    const intl = useIntl()
    const [image, setImage] = React.useState<string>();
    const [error, setError] = React.useState<string>();

    useEffect(() => {
        if (resourceId != undefined) {
            resourceService.getProfilePicture(resourceId).then((image) => {
                setImage(URL.createObjectURL(image))
            }).catch(() => {
                toastUtils.errorToast("error_loading_image")
            })
        }
    }, [resourceId]);

    const onFileChange = (file: File) => {
        setError(null);
        new Compressor(file, {
            quality: 0.6,
            maxWidth: 300,
            success: (result) => {
                // UPLOAD
                // 1 MB max
                if (result.size > 1048576) {
                    setError("file_too_big");
                } else {
                    setImage(URL.createObjectURL(result))
                    onUpload(result)
                }
            },
            error: () => {
                toastUtils.errorToast("error_uploading_image")
            }
        })
    }

    return (
        <div className={className}>
            <img src={image} width={90} height={90} alt="avatar" className="rounded-circle" loading="lazy" />
            <Cropper setImageFile={onFileChange} title={intl.formatMessage({id: "edit_button"})} aspect={1} />
            {error && <div><FormattedMessage id={error} /></div>}
        </div>
    );
}


export default UploadAvatar;
