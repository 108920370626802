import Icon from "components/icon/Icon";
import { BankAccount } from "interfaces/BankAccountInterface";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Label } from "reactstrap";
import BankAccountItem from "../bank-account/BankAccountItem";

interface SupplierBankAccountListProps {
  className?: string;
  supplierBankAccounts?: BankAccount[];
  supplierId?: string;
  onUpdate?: (bankAccount: BankAccount) => void;
  onDelete?: (bankAccountId: string) => void;
  onUpdateDefaultBankAccount?: (bankAccountId: string) => void;
}

const SupplierBankAccountList: FunctionComponent<
  SupplierBankAccountListProps
> = ({
  className = "",
  supplierBankAccounts,
  supplierId,
  onUpdate = () => null,
  onDelete = () => null,
  onUpdateDefaultBankAccount = () => null,
}) => {

  return (
    <div className={className}>
      {supplierBankAccounts.length > 0 ? (
        supplierBankAccounts.map((supplierBankAccount, index) => (
          <BankAccountItem
            key={index}
            bankAccount={supplierBankAccount}
            supplierId={supplierId}
            onUpdate={onUpdate}
            onDelete={onDelete}
            onUpdateDefaultBankAccount={onUpdateDefaultBankAccount}
          />
        ))
      ) : (
        <Label>
          <Icon name="InfoCircle" className="me-2" />
          <FormattedMessage id="bank_account_not_found" />
        </Label>
      )}
    </div>
  );
};

export default SupplierBankAccountList;
