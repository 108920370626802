import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {BookingShortDto, BookingStatus, BookingStatusMap} from "../../../../../interfaces/BookingInterfaces";
import {OrderItem, UNIT_TYPE} from "../../../../../interfaces/OrderItemInterfaces";
import {orderItemService} from "../../../../../services/OrderItemService";
import {ColorType, SizeType} from "../../../../../types/bootstrap/BootstrapType";
import {toastUtils} from "../../../../../utils/toastUtils";
import Button from "../../../../atoms/Button";

interface OrderItemPreviewCardBookingProps {
  orderItem: OrderItem,
  onOrderItemChange: () => void,
}

const OrderItemPreviewCardBooking: FunctionComponent<OrderItemPreviewCardBookingProps> = ({
  orderItem,
  onOrderItemChange,
}) => {
  const intl = useIntl()

  const createBookingForOrderItem = () => {
    orderItemService.createBookingsForOrderItem(orderItem.id)
      .then((response) => {
        if (response.length > 0) {
          toastUtils.successToast(intl.formatMessage({id: "success_toast_create_booking_from_order_item"}));
        } else {
          toastUtils.infoToast(intl.formatMessage({id: "success_toast_create_booking_from_order_item_none"}));
        }
        onOrderItemChange();
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_booking_document"}))
      });
  }

  const getQuantity = (booking: BookingShortDto) => {
    if (orderItem.unitType === UNIT_TYPE.FIXED_RATE && booking.status === BookingStatus.TO_DO) {
      return booking.pax && ` (PAX : ${booking.pax})`
    }
    return booking.quantity && ` x${booking.quantity}`
  }

  return (
    <div className="d-flex flex-column mb-1">
      <span className="bold">{`${intl.formatMessage({id: "order_item_bookings"})} : `}</span>

      {(!orderItem?.bookings || orderItem?.bookings.length === 0) && (
        <>
          <span>{intl.formatMessage({id: "order_item_no_booking"})}</span>
          <Button
            size={SizeType.XS}
            color={ColorType.SECONDARY}
            onClick={() => createBookingForOrderItem()}
            className="me-2">
            <FormattedMessage id="order_booking_create" />
          </Button>
        </>
      )}

      {orderItem?.bookings?.map((booking: BookingShortDto) => (
        <div key={booking.id} className="d-flex justify-content-between align-items-center">
          <div>
            <span className="me-1">{booking.serviceName}{getQuantity(booking)} -</span>
            <span className={`color-${BookingStatusMap[booking.status]?.color}`}>
              <FormattedMessage id={intl.formatMessage({id: BookingStatusMap[booking.status]?.label || "STATUS_DEFAULT"})} />
            </span>
          </div>
        </div>
      )
      )}

    </div>
  )
}

export default OrderItemPreviewCardBooking;
