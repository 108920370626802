import React, {FunctionComponent, useCallback} from "react";
import {useIntl} from "react-intl";
import {OrderItemSearchObject} from "../../../../interfaces/OrderItemInterfaces";
import {customerService} from "../../../../services/CustomerService";
import AutocompleteFilterPageable from "../AutocompleteFilterPageable";
import AutocompleteMultiselect from "../AutocompleteMultiselect";
import DateFilter from "../DateFilter";
import TextFilter from "../TextFilter";
import {activityService} from "../../../../services/ActivityService";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import {resourceTypeService} from "../../../../services/ResourceTypeService";
import {Option} from "../../../../interfaces/inputs/OptionInterfaces";
import {ResourceType} from "../../../../interfaces/ResourceTypeInterfaces";

interface StaffingOngoingFiltersProps {
  searchObject: OrderItemSearchObject,
  updateSearchField: (field: string, value: unknown) => void
  setSelectedResourceTypes: (labels: ResourceType[]) => void
}

const StaffingOngoingFilters: FunctionComponent<StaffingOngoingFiltersProps> = ({
  searchObject,
  updateSearchField,
  setSelectedResourceTypes
}) => {
  const intl = useIntl()

  const fetchActivities = useCallback((page, filter) =>
    activityService.getActivitiesPage(page, {...filter, status: WorkflowStatesEnum.ACTIVE, activityAreaIds: searchObject.activityAreaIds ?? []})
    , [searchObject.activityAreaIds]);

  const handleResourceTypeChange = (values: string[], selectedOptions: Option<String>[]) => {
    updateSearchField("resourceTypeIds", values)
    setSelectedResourceTypes(selectedOptions.map((option) => { return {id: String(option.value), label: option.label} }))
  }

  return (
    <div className="d-flex gap-2 flex-wrap">
      <TextFilter
        className="flex-1"
        value={searchObject?.codeOrReference ?? ""}
        onChange={(value) => updateSearchField("codeOrReference", value)}
        title={intl.formatMessage({id: "staffing_search_order"})}
        placeholder={intl.formatMessage({id: "staffing_search_order_placeholder"})}
      />

      <AutocompleteFilterPageable
        onChange={(value) => updateSearchField("customersIds", [value])}
        title={intl.formatMessage({id: "staffing_customer"})}
        value={searchObject?.customersIds?.at(0)}
        fetchData={customerService.getCustomersPage}
        filterFieldName="name"
      />

      <AutocompleteMultiselect
        title={intl.formatMessage({id: "staffing_order_item"})}
        placeholder={intl.formatMessage({id: "staffing_order_item"})}
        values={searchObject.activityIds ?? []}
        onChange={(values) => updateSearchField("activityIds", values)}
        fetchData={fetchActivities}
        filterFieldName="name"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />

      <AutocompleteMultiselect
        title={intl.formatMessage({id: "resource_type"})}
        placeholder={intl.formatMessage({id: "resource_type"})}
        values={searchObject.resourceTypeIds ?? []}
        onChange={handleResourceTypeChange}
        fetchData={resourceTypeService.getResourceTypePage}
        filterFieldName="label"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />
      <AutocompleteMultiselect
        title={intl.formatMessage({id: "resource_table_header_area"})}
        placeholder={intl.formatMessage({id: "resource_table_header_area"})}
        values={searchObject.activityAreaIds ?? []}
        onChange={(values) => updateSearchField("activityAreaIds", values)}
        fetchData={activityAreaService.getActivityAreasPage}
        filterFieldName="label"
        className="me-2"
        classNameLabel="mb-1 fw-bold"
      />

      <DateFilter value={searchObject.startDate} onChangeDate={(value) => updateSearchField("startDate", value)} title={intl.formatMessage({id: "filter_from"})} />
      <DateFilter value={searchObject.endDate} onChangeDate={(value) => updateSearchField("endDate", value)} title={intl.formatMessage({id: "filter_to"})} />
    </div>
  )
}

export default StaffingOngoingFilters;
