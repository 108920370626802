import React, {FunctionComponent} from "react";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import Icon from "../../icon/Icon";
import PaginationBox from "./PaginationBox";
import PaginationSize from "./PaginationSize";

interface PaginationProps {
  page: number,
  setPage: (page: number) => void,
  total: number,
  pageSize: number,
  onPageSizeChange: (pageSize: number) => void,
}

const Pagination: FunctionComponent<PaginationProps> = ({
  page,
  total,
  setPage = () => null,
  pageSize,
  onPageSizeChange,
}) => {

  const changePage = (value: number) => {
    if (value < 0 || value > (total - 1)) {
      return
    }

    setPage && setPage(value)
  }

  const changePageSize = (newPageSize: number) => {
    onPageSizeChange(newPageSize)
    changePage(0)
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="epow-pagination d-flex align-items-center">
        <Icon
          name="ArrowLeft"
          size={IconSizeType.SM}
          className="me-3 cursor-pointer"
          onClick={() => changePage(page - 1)}
        />
        {page !== 0 && (
          <>
            <PaginationBox page={0} onClick={changePage} />
            <div className="epow-pagination-spacer">
              ...
            </div>
          </>
        )}
        <PaginationBox page={page} onClick={changePage} mainBox />
        {page !== (total - 1) && (
          <>
            <div className="epow-pagination-spacer">
              ...
            </div>
            <PaginationBox page={total - 1} onClick={changePage} />
          </>
        )}
        <Icon
          name="ArrowRight"
          size={IconSizeType.SM}
          className="ms-3 cursor-pointer"
          onClick={() => changePage(page + 1)}
        />
      </div>
      <PaginationSize className="ms-auto mb-2" pageSize={pageSize} onPageSizeChange={changePageSize} />
    </div>
  )
}

export default Pagination;
