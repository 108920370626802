import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import GenericModal from "./GenericModal";

interface ModalItemsToPayProps {
  className?: string;
  title?: string;
  opened: boolean;
  setOpen?: (open: boolean) => void;
  action?: () => void;
  textContent?: string;
}

const ModalItemsToPay: FunctionComponent<ModalItemsToPayProps> = ({
  className= "",
  title= "",
  opened = false,
  setOpen,
  action = () => null,
  textContent
}) => {
  const intl = useIntl();

  return (
    <GenericModal
      className={className}
      title={title}
      open={opened}
      onOpen={setOpen}
      closable
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({id: "validate_button"}),
          action: action
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({id: "cancel_button"}),
          action: () => setOpen(false)
        }
      }}
    >
      <div className="d-flex flex-column">
        { textContent }
      </div>
    </GenericModal>
  )
}

export default ModalItemsToPay;
