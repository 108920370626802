import {Data} from "./TableInterfaces";
import {SearchFilter} from "./api/FiltersInterface";

export interface ResourceAvailabilityDto extends Data {
  availableAm?: boolean,
  availablePm?: boolean,
  availableNight?: boolean,
  availabilityDate?: string,
  staffedAm?: boolean,
  staffedPm?: boolean,
  staffedNight?: boolean,
}

export interface ResourceAvailabilitySearchObject extends SearchFilter {
  startDate?: string,
  endDate?: string,
}

export interface ResourceAvailabilityDispatcher {
  type: ResourceAvailabilityDispatchType,
  amDate?: string,
  pmDate?: string,
  nightDate?: string,
  allDates?: string[],
}

export const enum ResourceAvailabilityDispatchType {
  ADD_ALL = "addAll",
  REMOVE_ALL = "removeAll",
  ADD_ONE = "addOne",
  REMOVE_ONE = "removeOne",
}
