import React, {FunctionComponent, useState} from "react";
import Button from "../../atoms/Button";
import {ColorType} from "types/bootstrap/BootstrapType";

export interface ModalButtonModalProps {
  modalOpen: boolean,
  setModalOpen: (open: boolean) => void,
}

interface ModalButtonProps {
  className?: string,
  title: string,
  children: React.FunctionComponent<ModalButtonModalProps>,
  color?: ColorType,
}

const ModalButton: FunctionComponent<ModalButtonProps> = ({
  className = "",
  title,
  children,
  color = ColorType.PRIMARY,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <div className={className}>
      <Button color={color} onClick={() => setModalOpen(true)} >
        <span>{title}</span>
      </Button>
      {children({modalOpen, setModalOpen})}
    </div>
  )
}

export default ModalButton;
