import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import {useIntl} from "react-intl";
import {ActivityAreaSchema} from "../../../../constants/validation/ActivityAreaValidationSchema";
import {ActivityArea} from "../../../../interfaces/ActivityInterfaces";
import {activityAreaService} from "../../../../services/ActivityAreaService";
import {toastUtils} from "../../../../utils/toastUtils";
import FormInput from "../../input/FormInput";
import EpowForm from "../EpowForm";

interface ActivityAreaFormProps {
  className?: string,
  id?: string;
  initialActivityArea?: ActivityArea;
  onCreateActivityArea?: () => void;
}

const ActivityAreaForm: FunctionComponent<ActivityAreaFormProps> = ({
  className = "",
  id,
  initialActivityArea = null,
  onCreateActivityArea = () => null,
}) => {
  const intl = useIntl();

  const initialValues: ActivityArea = {
    id: initialActivityArea?.id ?? null,
    label: initialActivityArea?.label ?? "",
  }

  const handleEditActivityArea = (area: ActivityArea) => {
    activityAreaService.updateActivityArea(area.id, area)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_edit_activity_area"}))
        onCreateActivityArea()
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "error_toast_edit_activity_area"}))
      })
  }

  const handleCreateActivityArea = (newArea: ActivityArea) => {
    activityAreaService.createActivityArea(newArea)
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "success_toast_create_activity_area"}))
        onCreateActivityArea()
      })
      .catch((e: Error) => {
        toastUtils.errorToast(`${intl.formatMessage({id: "error_toast_create_activity_area"})} : ${e.message}`)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ActivityAreaSchema}
      onSubmit={(values) => initialActivityArea ? handleEditActivityArea(values) : handleCreateActivityArea(values)}
    >
      {({errors, touched, values, setFieldValue}) => (
        <EpowForm id={id} className={className}>
          <FormInput
            id="label"
            label="activity_area_label"
            value={values?.label}
            onChange={(e) => setFieldValue("label", e.target.value)}
            required
            error={errors.label}
            touched={touched.label}
            rows="5"
          />
        </EpowForm>
      )}
    </Formik>
  )
}

export default ActivityAreaForm;
