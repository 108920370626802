import {isEmpty} from "lodash";
import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {SizeType, ColorType} from "../../../../../types/bootstrap/BootstrapType";
import Button from "../../../../atoms/Button";
import {ResourceForStaffingDto} from "../../../../../interfaces/ResourceStaffingInterfaces";
import {getProfilePicture} from "../../../../../constants/routes/RoutePaths";
import Avatar from "../../../images/Avatar";

interface StaffingFormMessageProps {
  className?: string,
  value: string,
  resource: ResourceForStaffingDto,
  onChangeMessage: (message, resourceId) => void,
}

const StaffingFormMessage: FunctionComponent<StaffingFormMessageProps> = ({
  className = "",
  value,
  resource,
  onChangeMessage,
}) => {
  const intl = useIntl()
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [originalValue, setOriginalValue] = useState<string>("")

  const handleOpen = () => {
    setOriginalValue(value)
    setEditOpen(true)
  }

  const handleCancel = () => {
    onChangeMessage(originalValue, resource.id)
    setEditOpen(false)
  }

  return (
    <div className={`d-flex flex-column ${className ?? ""}`}>
      <div className={`d-flex flex-column p-3 border br-sm ${editOpen ? "" : "mb-2"}`}>
        <div className="d-flex align-items-center">

          <Avatar url={getProfilePicture(resource.id)} className="m-0 me-2"/>
          <div>
            {`${resource?.lastName} ${resource?.firstName}`}
          </div>

          {!editOpen && (
            <div onClick={handleOpen} className="ms-auto text-decoration-underline cursor-pointer">
              <FormattedMessage id={isEmpty(value) ? "staffing_add_message" : "staffing_edit_message"} />
            </div>
          )}
        </div>

        <div>
          {(!editOpen && value) && (
            <div className="mt-2 italic white-space-pre-wrap">
              {value}
            </div>
          )}
        </div>
      </div>

      {editOpen && (
        <>
          <textarea
            className="mt-1 p-2 border br-sm"
            value={value}
            onChange={(event) => onChangeMessage(event.target.value, resource.id)}
            placeholder={intl.formatMessage({id: "staffing_message_placeholder"})}
          />

          <div className="d-flex ms-auto align-items-center mb-2">

            <div onClick={() => handleCancel()} className="ms-auto me-2 text-decoration-underline cursor-pointer">
              <FormattedMessage id={"cancel_button"} />
            </div>

            <Button
              size={SizeType.MD}
              color={ColorType.SECONDARY}
              onClick={() => setEditOpen(false)}
            >
              <FormattedMessage id={"save_button"} />
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default StaffingFormMessage;
