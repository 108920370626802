
import React, {FunctionComponent} from "react";
import {PictureType} from "../../../../constants/FileConstants";
import Title from "../../../atoms/Title";
import ImageUploader from "../../images/ImageUploader";

interface ImageCardProps {
  className?: string,
  aspect? :number
  title: string,
  entityId: string,
  fileType: PictureType,
}

const ImageCard: FunctionComponent<ImageCardProps> = ({
  className = "",
  title,
  entityId,
  fileType,
  aspect
}) => {
  return (
    <div className={className}>

      <div className="mb-3">
        <Title title={title} size={"h5"} />
      </div>

      <ImageUploader entityId={entityId} fileType={fileType} aspect={aspect}/>
    </div>
  )
}

export default ImageCard;
