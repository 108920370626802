import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { BankAccount } from "../../../interfaces/BankAccountInterface";
import { bankAccountService } from "../../../services/BankAccountService";
import { toastUtils } from "../../../utils/toastUtils";
import GenericModal from "./GenericModal";

interface ModalUpdateDefaultBankAccountProps {
  className?: string;
  supplierId?: string;
  resourceId?: string;
  bankAccount?: BankAccount;
  handleUpdateDefaultBankAccount?: (bankAccountId: string) => void;
  openModalUpdateMainBankAccount?: boolean;
  setOpenModalUpdateMainBankAccount?: (open: boolean) => void;
}

const ModalUpdateDefaultBankAccount: FunctionComponent<
  ModalUpdateDefaultBankAccountProps
> = ({
  className = "",
  supplierId,
  resourceId,
  bankAccount,
  handleUpdateDefaultBankAccount,
  openModalUpdateMainBankAccount,
  setOpenModalUpdateMainBankAccount,
}) => {
  const intl = useIntl();

  const updateDefaultBankAccount = (bankAccount: BankAccount) => {
    if (supplierId) {
      bankAccountService
        .updateSupplierDefaultBankAccount(supplierId, bankAccount.id)
        .then(() => {
          handleUpdateDefaultBankAccount(bankAccount.id);
          toastUtils.successToast(
            intl.formatMessage({ id: "success_update_bank_account" })
          );
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_update_bank_account" })
          );
        });
    }
    else {
      bankAccountService
        .updateResourceDefaultBankAccount(resourceId, bankAccount.id)
        .then(() => {
          handleUpdateDefaultBankAccount(bankAccount.id);
          toastUtils.successToast(
            intl.formatMessage({ id: "success_update_bank_account" })
          );
        })
        .catch(() => {
          toastUtils.errorToast(
            intl.formatMessage({ id: "error_update_bank_account" })
          );
        });
    }
  };

  return (
    <GenericModal
      className={className}
      title={intl.formatMessage({
        id: "modal_title_update_default_bank_account",
      })}
      open={openModalUpdateMainBankAccount}
      onOpen={setOpenModalUpdateMainBankAccount}
      closable
      footer={{
        primaryAction: {
          buttonLabel: intl.formatMessage({ id: "validate_button" }),
          action: () => updateDefaultBankAccount(bankAccount),
        },
        secondaryAction: {
          buttonLabel: intl.formatMessage({ id: "cancel_button" }),
          action: () => setOpenModalUpdateMainBankAccount(false),
        },
      }}
    >
      {intl.formatMessage(
        { id: "modal_body_update_default_bank_account" },
        {
          name: bankAccount.accountName,
        }
      )}
    </GenericModal>
  );
};

export default ModalUpdateDefaultBankAccount;
