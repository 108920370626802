import React, {FunctionComponent, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {PageResponse} from "../../../interfaces/api/PaginationInterface";
import {Option} from "../../../interfaces/inputs/OptionInterfaces";
import {Data} from "../../../interfaces/TableInterfaces";
import {toastUtils} from "../../../utils/toastUtils";
import FilterLabel from "./FilterLabel";
import AsyncSelect from "react-select/async";
import {ActionMeta} from "react-select";

export interface AsyncAutocompleteMultiselectProps {
  className?: string,
  title?: string;
  placeholder?: string;
  values?: Option<string>[];
  onChange: (values: Option<string>[]) => void;
  fetchData: (inputValue?: string) => Promise<PageResponse<Data>>,
  filterFieldName: string,
  id?: string,
  closeMenuOnSelect?: boolean,
}

const AsyncAutocompleteMultiselect: FunctionComponent<AsyncAutocompleteMultiselectProps> = ({
  className = "",
  title,
  placeholder,
  values,
  onChange,
  fetchData,
  filterFieldName,
  id,
  closeMenuOnSelect = true,
}) => {
  const intl = useIntl()

  const [options, setOptions] = useState<Option<string>[]>([]);
  const [fetchedOptions, setFetchedOptions] = useState<Option<string>[]>([]);
  const [currentInput, setCurrentInput] = useState<string>("")

  const getSelectAllOptions = (inputValue: string) => ({
    value: "<SELECT_ALL>",
    label: intl.formatMessage({id: "filter_all"}, {selected: inputValue ? `(${inputValue})` : ""})
  })

  const fetchOptions = (defaultFilter: string) => {
    fetchData(defaultFilter)
      .then((response: PageResponse<Data>) => {
        const options = response?.content?.map(data => ({label: data[filterFieldName], value: data.id}))
        setOptions(options.length > 0 ? [getSelectAllOptions(defaultFilter), ...options] : options)
        setFetchedOptions(options)
      })
      .catch(() => {
        toastUtils.errorToast(intl.formatMessage({id: "autocomplete_error"}))
      })
  }

  useEffect(() => {
    fetchOptions("")
  }, [])

  const loadOptions = (inputValue) => fetchData(inputValue)
    .then((response: PageResponse<Data>) => {
      const options = response?.content?.map(data => ({label: data[filterFieldName], value: data.id}));
      setFetchedOptions(options)
      return [getSelectAllOptions(inputValue), ...options]
    })

  const relayOnChange = (newValue: Option<string>[], actionMeta: ActionMeta<Option<string>>) => {
    const {action, option} = actionMeta;
    const inputValueBeforeChange = currentInput;

    if (action === "select-option" && option.value === getSelectAllOptions(inputValueBeforeChange).value) {
      onChange(fetchedOptions);
    } else {
      onChange(newValue);
      if (!closeMenuOnSelect) {
        setCurrentInput(inputValueBeforeChange)
        fetchOptions(currentInput)
      }
    }
  }

  const updateInput = (inputValue: string) => {
    setCurrentInput(inputValue)
  }

  return (
    <div className={` ${className}`}>
      {title && <FilterLabel text={title} />}

      <AsyncSelect
        isMulti
        id={id ? id : `autocomplete-${title}`}
        placeholder={placeholder ?? ""}
        value={values}
        onChange={relayOnChange}
        cacheOptions
        defaultOptions={options}
        loadOptions={loadOptions}
        closeMenuOnSelect={closeMenuOnSelect}
        menuPlacement="auto"
        onInputChange={updateInput}
        inputValue={currentInput}
      />
    </div>
  )
}

export default AsyncAutocompleteMultiselect;
