import React, {FunctionComponent, useMemo} from "react";
import usePaginatedData from "../../../../hooks/usePaginatedData";
import {SearchFilter} from "../../../../interfaces/api/FiltersInterface";
import {OrderItemDayStaffingPageItemDto} from "../../../../interfaces/OrderItemInterfaces";
import {CustomTableColumn} from "../../../../interfaces/TableInterfaces";
import {activityStaffingService} from "../../../../services/ActivityStaffingService";
import Pagination from "../../pagination/Pagination";
import CustomTable, {CustomTableRef} from "../CustomTable";
import StaffingConfirmedTableRow from "./StaffingConfirmedTableRow";

interface StaffingConfirmedTableProps {
  className?: string,
  searchObject: SearchFilter,
}

const StaffingConfirmedTable: FunctionComponent<StaffingConfirmedTableProps> = ({
  className = "",
  searchObject,
}) => {
  const ref = React.useRef<CustomTableRef>();

  const {
    data,
    page,
    setPage,
    pageSize,
    setPageSize,
    sortOptions,
    setSortOptions,
    total,
  } = usePaginatedData(activityStaffingService.getStaffedOnDay, searchObject, "orderItem.rendezVousDate")

  const columns: CustomTableColumn[] = useMemo(() => [
    {title: "staffing_resource", accessor: "resource.identity.firstName", sortable: true},
    {title: "staffing_order_item", accessor: "orderItem.name", sortable: true},
    {title: "staffing_date_hour", accessor: "orderItem.rendezVousDate,orderItem.rendezVousHour", sortable: true},
    {title: "staffing_duration", accessor: "duration"},
    {title: "staffing_customer_reference", accessor: "customerReference", },
  ], [data])

  const rowComponent = (orderItem: OrderItemDayStaffingPageItemDto, rest) => {
    return (
      <StaffingConfirmedTableRow
        orderItem={orderItem}
        {...rest}
      />
    );
  }

  return (
    <div className={`d-flex flex-column ${className}`}>
      <CustomTable
        ref={ref}
        onSortOptions={setSortOptions}
        sortOptions={sortOptions}
        className={`epow-staffing-confirmed-table ${className}`}
        type="staffing"
        datas={data}
        columns={columns}
        RowComponent={({data, ...rest}) => rowComponent(data as OrderItemDayStaffingPageItemDto, rest)} />

      <Pagination page={page} setPage={setPage} pageSize={pageSize} onPageSizeChange={setPageSize} total={total} />
    </div>
  )
}

export default StaffingConfirmedTable;
