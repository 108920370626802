import React, {FunctionComponent} from "react";
import Icon from "../../../../icon/Icon";
import {ColorType} from "../../../../../types/bootstrap/BootstrapType";

interface CellHeaderAvailabilityProps {
    availabilityType: string
    onClickArrow?: () => void
    arrowColor: ColorType,
}

const CellHeaderAvailability: FunctionComponent<CellHeaderAvailabilityProps> = ({availabilityType, onClickArrow, arrowColor}) =>
    (<div className="d-flex justify-content-between pb-1 color-gray bold">
        <span>{availabilityType}</span>
        <span onClick={onClickArrow}><Icon color={arrowColor} name="ArrowRight"/></span>
    </div>)


export default CellHeaderAvailability;
