import React, {FunctionComponent, useMemo} from "react";
import {SUPPLIER_TABLE} from "../../../constants/Table";
import {tableUtils} from "../../../utils/tableUtils";
import {supplierService} from "services/SupplierService";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {useIntl} from "react-intl";
import TextFilter from "../filters/TextFilter";
import FilterGroup from "../filters/FilterGroup";
import PagedTable from "../table/PagedTable";
import {CustomerSearchObject} from "../../../interfaces/CustomerInterfaces";

interface SupplierListProps {
  className?: string,
  searchObject?: CustomerSearchObject,
  updateSearchField: (field: string, value: string | string[]) => void,
  onReset: () => void,
}

const SupplierList: FunctionComponent<SupplierListProps> = ({
  className = "",
  searchObject,
  updateSearchField,
  onReset
}) => {
  const intl = useIntl();
  const columns = useMemo(() => tableUtils.getColumns(SUPPLIER_TABLE(intl)), [intl])

  return (
    <>
      <FilterGroup>
        <TextFilter
          icon="Search"
          className="me-3 main-filter"
          value={searchObject?.name}
          onChange={(value) => updateSearchField("name", value)}
          title={intl.formatMessage({id: "supplier_name_field"})}
          placeholder={intl.formatMessage({id: "supplier_table_search_placeholder"})}
        />
        <div>
          <Button onClick={onReset} color={ColorType.SECONDARY} >{intl.formatMessage({id: "header_menu_clear_search"})}</Button>
        </div>
      </FilterGroup>

      <PagedTable
        columns={columns}
        labelNoResult="supplier_no_result"
        className={`${className} table`}
        fetchData={supplierService.getSuppliersPaginated}
        filters={searchObject}
        defaultSortBy={[{sortBy: "name"}]}
      />
    </>
  )
}

export default SupplierList
