import React, {FunctionComponent} from "react";

interface PanelProps {
  className?: string,
  open?: boolean,
  onCancel?: (isVisible?: boolean) => void,
  title?: string,
  children?: React.ReactElement,
  formId?: string,
  displayButton?: boolean
}

const SideMenuPanel: FunctionComponent<PanelProps> = ({
  className = "",
  open = false,
  children,
}) => {

  return (
      <div className={`epow-side-menu-panel ${className} ${!open ? "d-none" : ""} d-flex flex-column justify-content-between`}>
          <div className="epow-lateral-panel-content d-flex flex-column align-items-center flex-shrink">
            {children}
          </div>
      </div>

  )
}

export default SideMenuPanel
