import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";

interface TextLinkProps {
  className?: string
  to: string
  children: React.ReactNode
  openNewTab?: boolean
  noUnderline?: boolean
  external?: boolean
}

const TextLink: FunctionComponent<TextLinkProps> = ({
  className = "",
  to,
  children,
  openNewTab = false,
  noUnderline = false,
  external = false
}) => {
  if (external) {
    return (
      <a
        href={to}
        className={`epow-text-link ${noUnderline ? "no-underline " : " "} ${className}`}
        target={openNewTab ? "_blank" : "_self"}
        rel="noreferrer"
      >
        {children}
      </a>
    )
  } else {
    return (
      <Link
        to={to}
        className={`epow-text-link ${noUnderline ? "no-underline " : " "} ${className}`}
        target={openNewTab ? "_blank" : ""}
        rel="noreferrer"
      >
        {children}
      </Link>
    )
  }
}


export default TextLink;
