import React, {FunctionComponent} from "react";
import {Col} from "reactstrap";
import Title from "../../../atoms/Title";
import {TourLeader} from "../../../../interfaces/TourLeaderInterfaces";
import {FormattedMessage} from "react-intl";
import TextLink from "../../../atoms/TextLink";
import {CUSTOMERS_PATH} from "../../../../constants/routes/RoutePaths";

interface CustomersRepresentedByTourLeaderCardProps {
  className?: string,
  tourLeader?: TourLeader,
  title?: string
}

const CustomersRepresentedByTourLeaderCard: FunctionComponent<CustomersRepresentedByTourLeaderCardProps> = ({
  className = "",
  tourLeader,
  title
}) => {
  return (
    <Col className={`d-flex flex-column ${className}`}>
      <Title title={title} size="h5" className="mb-3"/>
      {tourLeader && tourLeader?.customers?.length > 0 ? (
        <div className="d-flex flex-column">
          {tourLeader?.customers?.map((customer, index) => (
            <span key={index} className="epow-info">
              <TextLink to={`${CUSTOMERS_PATH}/${customer.id}`}><>{customer.code} - {customer.name}</></TextLink>
            </span>
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-center">
          <FormattedMessage id="customer_represented_by_tour_leader_no_result"/>
        </div>
      )}
    </Col>
  )
}

export default CustomersRepresentedByTourLeaderCard