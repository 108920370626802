import React, {FunctionComponent} from "react";
import {FormattedMessage} from "react-intl";
import {Label} from "reactstrap";
import {FormikError, FormikTouchedType} from "../../../interfaces/ErrorInterfaces";

interface ErrorFieldProps {
  error: FormikError,
  touched: FormikTouchedType,
  isFileInput?: boolean,
  className?: string,
}

const ErrorField: FunctionComponent<ErrorFieldProps> = ({
  error,
  touched,
  isFileInput = false,
  className= "",
}) => (
  <div className={className}>
    {error && (touched || isFileInput) && (
      <div className="epow-error">
        <Label><FormattedMessage id={error?.toString()} /></Label>
      </div>
    )}
  </div>
)


export default ErrorField;
