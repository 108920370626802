import {Guard} from "../../interfaces/routes/RouteInterfaces";
import {HOME_PATH, LOGIN_PATH} from "./RoutePaths";
import {authUtils} from "../../utils/authUtils";
import {profileUtils} from "../../utils/profileUtils";

export const AuthGuard: Guard = {
  fallbackUrl: LOGIN_PATH,
  errorLabel: "guards_auth_error",
  canAccess: authUtils.isConnected
}

export const AdminProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.isAdmin
}

export const ResourceValidatedProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error_resource",
  canAccess: profileUtils.isResourceAndValidated
}

export const RessourceProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.isResource
}

export const ProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: () => (profileUtils.isTourLeader() || profileUtils.isResource())
}

export const InterneProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.isInterne
}

export const InterneOrCustomerProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess:  () => (profileUtils.isInterne() || profileUtils.canViewCustomer())
}

export const CustomerProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.canViewCustomer
}

export const TourProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.canViewTour
}

export const TourLeaderProfileGuard: Guard = {
  fallbackUrl: HOME_PATH,
  errorLabel: "guards_profile_restriction_error",
  canAccess: profileUtils.isTourLeader
}
