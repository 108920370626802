import {FunctionComponent, ReactNode} from "react";
import ReactDOM from "react-dom";

interface PortalProps {
  children: ReactNode,
}

const Portal: FunctionComponent<PortalProps> = ({
  children,
}) => {
  return ReactDOM.createPortal(children, document.body)
}

export default Portal;
