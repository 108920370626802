export const ADRESSE_DATA_GOUV_TYPE = {
  /** Recherche jusqu'au numéro de rue */
  HOUSE_NUMBER: "housenumber",

  /** Recherche jusqu'à la rue */
  STREET: "street",

  /** Recherche jusqu'à la commune */
  MUNICIPALITY: "municipality"
}

export const ADRESSE_DATA_GOUV_LIMIT = 10

export const PAYS_FRANCE = "FR"