import { ResourceType, ResourceTypeSearchObject } from "interfaces/ResourceTypeInterfaces"
import {API_RESOURCES_TYPE_PATH} from "../constants/routes/RoutePaths"
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface"
import {queryUtils} from "../utils/queryUtils"
import {httpService} from "./HttpService"

function getResourceTypes(): Promise<ResourceType[]> {
  return httpService.get<ResourceType[]>(API_RESOURCES_TYPE_PATH)
}

function getResourceTypePage(params: PaginationQueryParams, filters: ResourceTypeSearchObject): Promise<PageResponse<ResourceType>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filters)
  return httpService.get<PageResponse<ResourceType>>(`${API_RESOURCES_TYPE_PATH}/page${queryParams}`)
}

function createResourceType(request: ResourceType): Promise<ResourceType> {
  return httpService.post(API_RESOURCES_TYPE_PATH, request)
}

function updateResourceType(id: string, request: ResourceType): Promise<ResourceType> {
  return httpService.put(`${API_RESOURCES_TYPE_PATH}/${id}`, request)
}

function deleteResourceType(id: string): Promise<Response> {
  return httpService.delete(`${API_RESOURCES_TYPE_PATH}/${id}`)
}

export const resourceTypeService = Object.freeze({
    getResourceTypes,
    getResourceTypePage,
    createResourceType,
    updateResourceType,
    deleteResourceType,
})
