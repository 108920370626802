import {useEffect, useState} from "react";
import {SearchFilter} from "../interfaces/api/FiltersInterface";

import {isEmpty} from "lodash";
import {useSearchParams} from "react-router-dom";

interface UseSearchFilterHook<T> {
  searchObject: T,
  updateSearchField: (field: string, value: boolean | string | string[]) => void,
  onReset: (prefilter?: SearchFilter) => void,
  reloadSearch: () => void
}

interface UseSearchFilterOptions<T> {
  // mutate Initial State based on search params
  // This can be used to set default values for the search filter only if there are no search params
  mutateInitialState?: (params: URLSearchParams) => T
}

function useSearchFilter<T>(initialState: T, {mutateInitialState}: UseSearchFilterOptions<T> = {}): UseSearchFilterHook<T> {
  const [search, setSearch] = useSearchParams({});

  const getInitialState = (): T => {
    const params = new URLSearchParams(search)
    let filterFromParams = {...initialState}

    if (mutateInitialState) {
      filterFromParams = mutateInitialState(params);
    }

    Array.from(params.entries()).forEach(([key, value]) => {
      filterFromParams = {
        ...filterFromParams,
        [key]: value
      }
    })
    return filterFromParams
  }

  const updateSearchParams = (searchObject: T, replace: boolean) => {
    const filters = Object.entries(searchObject).filter(([, value]) => !isEmpty(value))
    const params = new URLSearchParams(filters);
    setSearch(params, {replace});
  }

  const [searchObject, setSearchObject] = useState<T>(getInitialState());

  const onChangeSearchObject = (field: string, value: string | string[]) => {
    setSearchObject((previousState) => {
      const newSearchObect = {...previousState, [field]: value};
      updateSearchParams(newSearchObect, false)
      return newSearchObect
      }
    );
  }

  const onAlterSearchObject = (searchObject) => {
    setSearchObject(() => {
        updateSearchParams(searchObject, false)
        return searchObject
      }
    );
  }

  useEffect(() => {
    updateSearchParams(getInitialState(), true)
  }, []);

  const onReset = (prefilter?: SearchFilter) => {
    if (prefilter) {
      onAlterSearchObject({
        ...initialState,
        ...prefilter
      })
    } else {
      onAlterSearchObject(initialState)
    }
  }

  const reloadSearch = () => {
    onAlterSearchObject({...searchObject})
  }

  return {
    searchObject,
    onReset,
    updateSearchField : (field: string, value: string | string[]) => {
      onChangeSearchObject(field, value);
    },
    reloadSearch};
}

export default useSearchFilter;
