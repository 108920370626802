import {FormikErrors, FormikTouched} from "formik";
import React, {FunctionComponent, useMemo} from "react";
import {useIntl} from "react-intl";
import {ORDER_ITEM_TYPE_OPTIONS, YES_NO_OPTIONS} from "../../../../constants/OptionConstants";
import {Activity, ActivityTableDTO} from "../../../../interfaces/ActivityInterfaces";
import {FormikFieldSetter} from "../../../../interfaces/FormikInterfaces";
import {Quote} from "../../../../interfaces/QuoteInterfaces";
import {QuoteItemRequest} from "../../../../interfaces/QuoteItemInterfaces";
import {Service} from "../../../../interfaces/ServiceInterfaces";
import {activityService} from "../../../../services/ActivityService";
import {serviceService} from "../../../../services/ServiceService";
import {dateUtils} from "../../../../utils/dateUtils";
import {toastUtils} from "../../../../utils/toastUtils";
import FormAutocompleteSelectPageable from "../../input/FormAutocompleteSelectPageable";
import FormInput from "../../input/FormInput";
import FormRadio from "../../input/FormRadio";
import {WorkflowStatesEnum} from "../../../../constants/workflow/WorkflowStates";
import {ApiError} from "../../../../interfaces/ErrorInterfaces";
import {ORDER_ITEM_TYPE} from "../../../../interfaces/OrderItemInterfaces";

interface CreateQuoteItemFormStepOneProps {
  className?: string,
  errors: FormikErrors<QuoteItemRequest>,
  touched: FormikTouched<QuoteItemRequest>,
  values: QuoteItemRequest,
  setFieldValue: FormikFieldSetter,
  quote?: Quote,
  selectedActivity: Activity,
  setSelectedActivity: (activity: Activity) => void,
  selectedService: Service,
  setSelectedService: (service: Service) => void,
}

const CreateQuoteItemFormStepOne: FunctionComponent<CreateQuoteItemFormStepOneProps> = ({
  className = "",
  errors,
  touched,
  values,
  setFieldValue,
  setSelectedActivity,
  setSelectedService,
  quote
}) => {
  const intl = useIntl()

  const updateSelectedActivity = async (activityId): Promise<Activity> => {
    return await activityService.getActivity(activityId, quote.id)
      .then(activity => {
        setSelectedActivity(activity)
        return activity
      }).catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
        return {}
      })
  }

  const updateSelectedService = async (serviceId): Promise<Service> => {
    return await serviceService.getService(serviceId, quote.id)
      .then(service => {
        setSelectedService(service)
        return service
      }).catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
        return {}
      })
  }

  const updatePaxPrice = (activity: Activity) => {
    setFieldValue("unitType", activity?.unitType)
    setFieldValue("paxPrice", activity?.price)
    setFieldValue("sellPrice", activity?.price * values?.quantity)
  }

  const updateServicePaxPrice = (service: Service) => {
    setFieldValue("paxPrice", service?.defaultSellPrice)
    setFieldValue("unitType", service?.defaultUnitType)
    setFieldValue("sellPrice", service?.defaultSellPrice * values?.quantity)
  }

  useMemo(() => {
    const fetchData = async () => {
      if (values?.id) {
        if (values?.activity) {
          await updateSelectedActivity(values?.activity);
        } else if(values?.service) {
          await updateSelectedService(values?.service);
        }
      }
    }

    fetchData()
      .catch((error: ApiError) => {
        toastUtils.errorToast(error.message)
      })
  }, [values?.id])

  const onChangeActivity = async (activityId: string) => {
    const activity: Activity = await updateSelectedActivity(activityId)

    setSelectedActivity(activity)
    setSelectedService(null)
    setFieldValue("activity", activity?.id)
    setFieldValue("name", activity?.name)
    setFieldValue("isStaffingManagement", activity?.staffingManagement?.toString())
    updatePaxPrice(activity)
  }

  const onChangeService = async (serviceId: string) => {
    const service = await updateSelectedService(serviceId)

    setSelectedActivity(null)
    setSelectedService(service)
    setFieldValue("isStaffingManagement", false)
    setFieldValue("service", service?.id)
    setFieldValue("description", service?.description)
    setFieldValue("name", service?.name)
    updateServicePaxPrice(service)

  }

  const onChangeType = (type: string) => {
    setFieldValue("type", type);
  }

  const formatOption = (activity: ActivityTableDTO) => {
    if (activity.currentState === WorkflowStatesEnum.ACTIVE) {
      return {
        label: activity.name,
        value: activity.id
      }
    } else {
      return {
        label: `(Inactif) ${activity.name}`,
        value: activity.id
      }
    }
  }

  return (
    <div className={className}>
      <FormRadio
        id="type"
        label="order_item_type"
        value={ORDER_ITEM_TYPE_OPTIONS.find((option) => option?.value === values?.type?.toString())}
        options={ORDER_ITEM_TYPE_OPTIONS}
        onChange={(e) => onChangeType(e.value)}
        required
        error={errors.type}
        touched={touched.type}
      />

      {values.type === ORDER_ITEM_TYPE.ACTIVITY ? (
        <FormAutocompleteSelectPageable
          id="activity"
          label={intl.formatMessage({id: "quote_item_activity"})}
          placeholder={intl.formatMessage({id: "order_item_type_activity"})}
          value={values?.activity}
          onChange={(value) => onChangeActivity(value)}
          error={errors?.activity}
          touched={touched?.activity}
          required
          fetchData={(page, filter) => activityService.getActivitiesPage(page, {...filter})}
          manageOptions={(datas: ActivityTableDTO[]) => datas?.map(activity => formatOption(activity))}
          filterFieldName="name"
        />
      ) : (
        <FormAutocompleteSelectPageable
          id="service"
          label={intl.formatMessage({id: "quote_item_service"})}
          placeholder={intl.formatMessage({id: "order_item_type_service"})}
          value={values?.service}
          onChange={(value) => onChangeService(value)}
          error={errors?.service}
          touched={touched?.service}
          required
          fetchData={(page, filter) => serviceService.getServicesPage(page, {...filter, currentState: WorkflowStatesEnum.ACTIVE})}
          filterFieldName="name"
        />
      )}

      <FormInput
        id="name"
        label="quote_item_name"
        value={values?.name}
        onChange={(e) => setFieldValue("name", e.target?.value)}
        required
        error={errors.name}
        touched={touched.name}
      />

      {values.type === ORDER_ITEM_TYPE.ACTIVITY && (
        <div className="d-flex">
          <FormRadio
            id="optional"
            label="quote_item_optional"
            value={YES_NO_OPTIONS.find((option) => option?.value === values?.optional?.toString())}
            options={YES_NO_OPTIONS}
            onChange={(e) => setFieldValue("optional", e.value)}
            required
            error={errors.optional}
            touched={touched.optional}
          />

        </div>
      )}

      <FormInput
        id="description"
        label="quote_item_description"
        value={values?.description ?? ""}
        onChange={(e) => setFieldValue("description", e.target?.value)}
        error={errors.description}
        touched={touched.description}
      />

      <FormInput
        id="rendezVousPlace"
        label="quote_item_place"
        value={values?.rendezVousPlace ?? ""}
        onChange={(e) => setFieldValue("rendezVousPlace", e.target?.value)}
        required
        error={errors.rendezVousPlace}
        touched={touched.rendezVousPlace}
      />

      <FormInput
        id="rendezVousDate"
        label="quote_item_date"
        type="date"
        value={values?.rendezVousDate}
        onChange={(e) => setFieldValue("rendezVousDate", e.target.value)}
        required
        error={errors.rendezVousDate}
        touched={touched.rendezVousDate}
      />

      <FormInput
        id="rendezVousHour"
        label="quote_item_hour"
        type="time"
        step="60"
        value={dateUtils.getTimeWithoutSeconds(values?.rendezVousHour)}
        onChange={(e) => setFieldValue("rendezVousHour", e.target.value)}
        required
        error={errors.rendezVousHour}
        touched={touched.rendezVousHour}
      />


    </div>
  )
}

export default CreateQuoteItemFormStepOne;
