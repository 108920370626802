import {Purchase, PurchaseStateMap, PurchaseStatus} from "../../../interfaces/PurchaseInterfaces";
import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Label} from "reactstrap";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import {purchaseService} from "../../../services/PurchaseService";
import PageTitle from "./PageTitle";
import {PURCHASES_PATH} from "../../../constants/routes/RoutePaths";
import {authUtils} from "../../../utils/authUtils";
import {PROFILE} from "../../../interfaces/UserInterfaces";
import ModalDeletePurchase from "../modal/ModalDeletePurchase";
import {fileUtils} from "../../../utils/fileUtils";

interface PurchaseHeaderProps {
  className?: string,
  purchase: Purchase,
  onClickSent: () => void
  openPayModal?: () => void
}

const PurchaseHeader: FunctionComponent<PurchaseHeaderProps> = ({
  className = "",
  purchase,
  onClickSent = () => null,
  openPayModal= () => null
}) => {
  const intl = useIntl()

  const [deleteModal, setDeleteModal] = React.useState(false);

  const downloadExcel = () => {
    if (purchase?.id) {
      fileUtils.downloadFile(purchaseService.download(purchase.id), {
        name: purchase.purchaseNumber,
        extension: "xlsx"
      }, intl)
    }
  }

  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <PageTitle
          pageTitle={purchase?.purchaseNumber}
          className="me-4"
          breadcrumbItems={[{
              title: intl.formatMessage({id: "purchases_title"}),
              to: PURCHASES_PATH
          }]}
        />
        <div>
          <span className="me-1 text-muted"><FormattedMessage id="order_status" /> :</span>
          <Badge pill color={PurchaseStateMap[purchase?.status]?.color} >
            {intl.formatMessage({id: PurchaseStateMap[purchase?.status]?.label})}
          </Badge>
        </div>

        <div className="ms-auto">
          { (purchase.status === PurchaseStatus.SENT && purchase.bookings?.some(booking => !booking.isPaid)) && (
              <Button color={ColorType.SECONDARY} className="extended me-2" onClick={openPayModal}>
                <FormattedMessage id="purchase_pay_bookings"/>
              </Button>
            )
          }
          {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && purchase.canDelete &&
            <Button color={ColorType.SECONDARY} className="extended me-2" onClick={() => setDeleteModal(true)}>
              <FormattedMessage id="purchase_delete_order"/>
            </Button>
          }
          <Button color={ColorType.SECONDARY} className="extended me-2" onClick={downloadExcel}>
            <FormattedMessage id="purchase_download"/>
            <Icon name="Download" className={"ms-2"}/>
          </Button>
          {(authUtils.getProfile() === PROFILE.ADMIN || authUtils.getProfile() === PROFILE.INTERN) && purchase.status === PurchaseStatus.OPEN &&
          <Button color={ColorType.PRIMARY} className="extended" onClick={onClickSent}>
            <FormattedMessage id="purchase_send_order"/>
          </Button>}
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <Label className="me-1"><FormattedMessage id="purchase_order_creation"/></Label>
          {dateUtils.formatDateToLocalDateTime(dateUtils.parseDate(purchase?.purchaseDate), false)}
        </div>
      </div>

      <ModalDeletePurchase
        open={deleteModal}
        onValidate={() => setDeleteModal(false)}
        onCancel={() => setDeleteModal(false)}
        purchase={purchase}/>

    </div>
  )
}

export default PurchaseHeader
