import {
  Supplier,
  SupplierGeneralInfo,
  SupplierSettings,
} from "interfaces/SupplierInterfaces";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import GeneralInfoSupplierCard from "./GeneralInfoSupplierCard";
import MainSupplierContactCard from "./MainSupplierContactCard";
import SettingsSupplierCard from "./SettingsSupplierCard";

interface DetailsSupplierCardProps {
  className?: string;
  supplier?: Supplier;
  setSupplier?: (supplier: Supplier) => void;
}

const DetailsSupplierCard: FunctionComponent<DetailsSupplierCardProps> = ({
  className = "",
  supplier,
  setSupplier,
}) => {
  const intl = useIntl();

  const getSupplierGeneralInfo = (): SupplierGeneralInfo => ({
    supplierId: supplier.id,
    name: supplier.name,
    entityEmail: supplier.entityEmail,
    entityPhone: supplier.entityPhone,
    mainLabel: supplier.address?.mainLabel,
    additionalInformation: supplier.address?.additionalInformation,
    zipCode: supplier.address?.zipCode,
    city: supplier.address?.city,
    country: supplier.address?.country,
  });

  const getSupplierSettingsInfo = (): SupplierSettings => ({
    supplierId: supplier.id,
    preBookingDelay: supplier.preBookingDelay,
    paymentDelay: supplier.paymentDelay,
    hasVoucher: supplier.hasVoucher,
    applyVat: supplier.applyVat,
    comment: supplier.comment,
  });

  return (
    <div className={`d-flex flex-row flex-wrap gap-3 ${className}`}>
      <GeneralInfoSupplierCard
        supplierGeneralInfo={getSupplierGeneralInfo()}
        setSupplier={setSupplier}
        title={intl.formatMessage({ id: "supplier_general_info" })}
      />
      <SettingsSupplierCard
        supplierSettings={getSupplierSettingsInfo()}
        setSupplier={setSupplier}
        title={intl.formatMessage({ id: "supplier_settings_info" })}
      />
      <MainSupplierContactCard
        supplier={supplier}
        setSupplier={setSupplier}
        title={intl.formatMessage({ id: "supplier_main_contact" })}
      />
    </div>
  );
};

export default DetailsSupplierCard;
